<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
    <h6 class="m-0 font-weight-bold text-primary">{{ 'queue-state' | translate }}</h6>
</div>
<div class="card-body">
    <div class="chart-pie pt-4 pb-2">
        <canvas id="queueStatePieChart"></canvas>
    </div>
    <div *ngIf="categories" class="mt-4 text-center small">
        <span *ngFor="let category of categories; let i = index" class="mr-2 no-wrap">
            <i class="fas fa-circle" [ngStyle]="{'color': backgroundColors[i]}"></i> {{ category.fullName }}
        </span>
    </div>
</div>
