export class CategoryGroup {
    id: number;
    name: string;
    locationId: number;
    groupCategoriesIds: number[];

    constructor(id: number, name: string, locationId: number) {
        this.id = id;
        this.name = name;
        this.locationId = locationId;
        this.groupCategoriesIds = [];
    }
}