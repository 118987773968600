import { Component, OnInit, Input } from '@angular/core';
import { Tag } from 'projects/qcloud-models/multimedia-device/tag.model';
import { MultimediaService } from 'projects/qcloud-rest-client/src/lib/multimedia.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Device } from 'projects/qcloud-models/device/device.model';
import { UntypedFormControl } from '@angular/forms';
import { TagDevice } from 'projects/qcloud-models/multimedia-device/tag-device.model';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-tags-table',
  templateUrl: './tags-table.component.html',
  styleUrls: ['./tags-table.component.css']
})
export class TagsTableComponent implements OnInit {

  @Input() ctx: any;
  @Input() tags: Tag[];
  currentTag: Tag = new Tag();
  tagExists: boolean;

  @Input() devices: Device[];
  protected initialDevices: Device[];
  public deviceMultiCtrl: UntypedFormControl;

  constructor(private service: MultimediaService, private toastr: ToastrService, private translate: TranslateService,
    private modalService: MatDialog, private authService: AuthService) { }

  ngOnInit(): void { }

  getTags() {
    this.currentTag = new Tag();
    this.ctx.getTags();
  }

  createTag(tag : Tag) {
    tag.locationId = this.authService.getLocationId();
    this.service.addTag(tag).subscribe(
      res => {
        this.getTags();
      },
      err => {
        this.translate.get('create-tag-error').subscribe((res: string) => {
          let error = res;
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  editTag(tag: Tag) {
    this.service.editTag(tag).subscribe(
      res => {
        this.getTags();
        this.ctx.getTagsByDevice();
      },
      err => {
        this.translate.get('edit-tag-error').subscribe((res: string) => {
          let error = res;
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  onDeleteConfirm(confirmed: boolean) {
    if (confirmed) {
      this.deleteTag();
    }
  }

  deleteTag() {
    this.service.deleteTag(this.currentTag.id).subscribe(
      res => {
        this.getTags();
        this.ctx.getTagsByDevice();
      },
      err => {
        this.translate.get('delete-tag-error').subscribe((res: string) => {
          let error = res;
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  setDeviceTags(tag: Tag) {
    this.currentTag = tag;
    this.initialDevices = new Array<Device>();
    for (let i = 0; i < this.devices.length; i++) {
      if (tag.tagDevices.some(device => device.deviceId == this.devices[i].guid)) {
        this.initialDevices.push(this.devices[i]);
      }
    }
    this.deviceMultiCtrl = new UntypedFormControl(this.initialDevices);
  }

  assignDevices() {
    for (let i = 0; i < this.currentTag.tagDevices.length; i++) {
      if (!this.deviceMultiCtrl.value.some(device => device.guid == this.currentTag.tagDevices[i].deviceId)) {
        this.service.removeTagFromDevice(this.currentTag.tagDevices[i]).subscribe(
          res => {
            this.getTags();
            this.ctx.getTagsByDevice();
          }
        );
      }
    }
    for (let i = 0; i < this.deviceMultiCtrl.value.length; i++) {
      if (!this.currentTag.tagDevices.some(device => device.deviceId == this.deviceMultiCtrl.value[i].guid)) {
        let deviceTag = new TagDevice(this.deviceMultiCtrl.value[i].guid, this.currentTag);
        this.service.addTagToDevice(deviceTag).subscribe(
          res => {
            this.getTags();
            this.ctx.getTagsByDevice();
          }
        );
      }
    }
  }

  openModal(content) {
    this.modalService.open(content);
  }

}
