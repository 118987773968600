export enum ScreenSizeType {
  BIG,
  SMALL,
  SLIM
}

enum ScreenSizeParameters {
  SlimMaxWidth = 500,
  SlimMaxHeigth = 100,
  SmallMaxWidth = 500,
  SmallMaxHeigth = 200,
  SmallMinHeigth = 100
}

export class ScreenSizeConfigurationService {
  public screenSizeType: ScreenSizeType;
  ScreenSizeTypeEnum = ScreenSizeType;

  constructor(){}

  updateScreenSizeType(innerWidth: number, innerHeight: number) : void {
    if( innerWidth <= ScreenSizeParameters.SlimMaxWidth && innerHeight <= ScreenSizeParameters.SlimMaxHeigth) {
      this.screenSizeType = ScreenSizeType.SLIM;
    } else if( innerWidth <= ScreenSizeParameters.SmallMaxWidth &&
      innerHeight >= ScreenSizeParameters.SmallMinHeigth &&
      innerHeight <= ScreenSizeParameters.SmallMaxHeigth) {
      this.screenSizeType = ScreenSizeType.SMALL;
    } else{
      this.screenSizeType = ScreenSizeType.BIG;
    }
  }
}