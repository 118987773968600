import { ActivityTimeCategory } from "../activity-time-category/activity-time-category.model.";
import { CategoryShowInfo } from "./category-short-info";
import { Category } from "./category.model";

export class SystemCategory {
    public id: number;
    public locationId: number;
    public localeCategoryLocationIds: number[];
    public localeCategories: CategoryShowInfo[];
    public systemId: number;
    public fullName: string;
    public shortName: string;
    public categoryTag: string;
    public ticketLimit: number;
    public timePerTicket: number;
    public isBlocked: boolean;
    public activityTimeCategory: ActivityTimeCategory;
    public ticketReservationBlockTimes: any[];
    public isAutomaticallyReset: boolean;
    public blockingMessageActivity: string;
    public blockingMessageManual: string;
    public isActive: boolean;
    public canBeReserved: boolean;
    public eTicketRedirect: boolean;
    public canMobile: boolean;
    public reservationIntervalMins: number;
    public maxServiceTime: number;
    public workingType: number;
    public avgTimeBlock: boolean;
    public avgTimeBlockText: string;
    public smsCategory: boolean;
    public sendSmsOnReservation: boolean;
    public sendSmsOnPrint: boolean;
    public sendSmsOnBeforeCall: boolean;
    public sendSmsOnTicketCall: boolean;
    public conditionalTicket: boolean;
    public conditionalTicketText: string;
    public additionalPrint: boolean;
    public additionalPrintText: string;
    public printedTicketAdditionalText: string;
    public defaultPauseTimeMins: number;
    public isNoteRequired: boolean;
    public ticketAmountToCountAverageWaitingServiceTime: number;
    public canOnHour: boolean;
    public static mapFromCategoryIntoSystemCategory(category: Category): SystemCategory {
        var systemCategory = new SystemCategory();
        systemCategory.id = category.id;
        systemCategory.locationId = category.locationId;
        systemCategory.fullName = category.fullName;
        systemCategory.shortName = category.shortName;
        systemCategory.categoryTag = category.categoryTag;
        systemCategory.ticketLimit = category.ticketLimit;
        systemCategory.timePerTicket = category.timePerTicket;
        systemCategory.isBlocked = category.isBlocked;
        systemCategory.ticketReservationBlockTimes = category.ticketReservationBlockTimes;
        systemCategory.isAutomaticallyReset = category.isAutomaticallyReset;
        systemCategory.blockingMessageActivity = category.blockingMessageActivity;
        systemCategory.blockingMessageManual = category.blockingMessageManual;
        systemCategory.isActive = category.isActive;
        systemCategory.canBeReserved = category.canBeReserved;
        systemCategory.eTicketRedirect = category.eTicketRedirect;
        systemCategory.canMobile = category.canMobile;
        systemCategory.reservationIntervalMins = category.reservationIntervalMins;
        systemCategory.maxServiceTime = category.maxServiceTime;
        systemCategory.workingType = category.workingType;
        systemCategory.avgTimeBlock = category.avgTimeBlock;
        systemCategory.avgTimeBlockText = category.avgTimeBlockText;
        systemCategory.smsCategory = category.smsCategory;
        systemCategory.sendSmsOnReservation = category.sendSmsOnReservation;
        systemCategory.sendSmsOnPrint = category.sendSmsOnPrint;
        systemCategory.sendSmsOnBeforeCall = category.sendSmsOnBeforeCall;
        systemCategory.sendSmsOnTicketCall = category.sendSmsOnTicketCall;
        systemCategory.conditionalTicket = category.conditionalTicket;
        systemCategory.conditionalTicketText = category.conditionalTicketText;
        systemCategory.additionalPrint = category.additionalPrint;
        systemCategory.additionalPrintText = category.additionalPrintText;
        systemCategory.printedTicketAdditionalText = category.printedTicketAdditionalText;
        systemCategory.defaultPauseTimeMins = category.defaultPauseTimeMins;
        systemCategory.isNoteRequired = category.isNoteRequired;
        systemCategory.ticketAmountToCountAverageWaitingServiceTime = category.ticketAmountToCountAverageWaitingServiceTime;
        systemCategory.canOnHour = category.canOnHour;
        return systemCategory;
    }
}
