import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-desks-column',
  templateUrl: './desks-column.component.html',
  styleUrls: ['./desks-column.component.css']
})
export class DesksColumnComponent implements OnInit {

  @Input() desks: number[];

  constructor() { }

  ngOnInit(): void {
  }

  sortByDeskNumber(desks: number[]) {
    return desks.sort();
  }

}
