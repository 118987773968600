import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth/auth.service';
import { Playlist } from 'projects/qcloud-models/multimedia/playlist.model';
import { Observable } from 'rxjs';
import { PlaylistItem } from 'projects/qcloud-models/multimedia/playlistItem.model';
import { PlaylistFile } from 'projects/qcloud-models/multimedia/playlist-file.model';
import { Tag } from 'projects/qcloud-models/multimedia-device/tag.model';
import { TagDevice } from 'projects/qcloud-models/multimedia-device/tag-device.model';
import { Schedule } from 'projects/qcloud-models/multimedia/schedule.model';
import { PlaylistWithWeeklySchedule } from 'projects/qcloud-models/multimedia/playlist-with-weekly-schedulemodel';

@Injectable({
  providedIn: 'root'
})
export class MultimediaService {

  appBase: string;

  public setAppBase(appBase: string) {
    this.appBase = appBase;
  }

  constructor(private http: HttpClient, private authService: AuthService) { }

  getPlaylists(): Observable<Playlist[]> {
    return this.http.get<Playlist[]>(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/multimedia`);
  }

  deletePlaylist(playlistId: number) {
    return this.http.delete(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/multimedia/${playlistId}`);
  }

  getPlaylist(playlistId: number): Observable<Playlist> {
    return this.http.get<Playlist>(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/multimedia/${playlistId}`);
  }

  createPlaylist(name: string): Observable<Playlist> {
    return this.http.post<Playlist>(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/multimedia`, { name: name });
  }

  changePlaylistName(playlistId: number, newName: string) {
    return this.http.put(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/multimedia/${playlistId}`,
      { name: newName });
  }

  getPlaylistItems(playlistId: number): Observable<PlaylistItem[]> {
    return this.http.get<PlaylistItem[]>(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/multimedia/${playlistId}/item`);
  }

  addPlaylistItems(playlistId: number, playlistFiles: PlaylistFile[]) {
    return this.http.post(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/multimedia/${playlistId}/item`,
      playlistFiles);
  }

  changeFileOrder(playlistId: number, itemId: number, itemIdToSwap: number) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/multimedia/${playlistId}/item/${itemId}/${itemIdToSwap}`, {});
  }

  changePlaylistItemTime(playlistId: number, itemId: number, itemTime: number) {
    return this.http.put(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/multimedia/${playlistId}/item/${itemId}/time/${itemTime}`, {});
  }

  deleteItemFromPlaylist(playlistId: number, itemId: number) {
    return this.http.delete(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/multimedia/${playlistId}/item/${itemId}`);
  }

  getTags(): Observable<Tag[]> {
    return this.http.get<Tag[]>(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tag`);
  }

  addTag(tag: Tag) {
    return this.http.post(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tag`, tag);
  }

  editTag(tag: Tag) {
    return this.http.put(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tag/${tag.id}`, tag);
  }

  deleteTag(tagId: number) {
    return this.http.delete(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tag/${tagId}`);
  }

  addTagToDevice(tagDevice: TagDevice) {
    return this.http.post(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tag/device`, tagDevice);
  }

  removeTagFromDevice(tagDevice: TagDevice) {
    return this.http.delete(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tag/${tagDevice.tagId}/device/${tagDevice.deviceId}`);
  }

  getTagsWithDevices(): Observable<Tag[]> {
    return this.http.get<Tag[]>(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tag/tags`);
  }

  getDevicesTags(): Observable<{ [key: string]: Tag[] }> {
    return this.http.get<{ [key: string]: Tag[] }>(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tag/device`);
  }

  createSchedule(name: string): Observable<Schedule> {
    return this.http.post<Schedule>(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/schedule`, { name: name });
  }

  updateSchedule(schedule: Schedule) {
    return this.http.put(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/schedule/${schedule.id}`, schedule);
  }

  getSchedules(): Observable<Schedule[]> {
    return this.http.get<Schedule[]>(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/schedule`);
  }

  getSchedule(scheduleId: number): Observable<Schedule> {
    return this.http.get<Schedule>(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/schedule/${scheduleId}`);
  }

  deleteSchedule(scheduleId: number) {
    return this.http.delete(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/schedule/${scheduleId}`);
  }

  addSchedulePlaylist(scheduleId: number, weeklySchedule: PlaylistWithWeeklySchedule) {
    return this.http.post(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/schedule/${scheduleId}/playlist`,
      weeklySchedule);
  }

  editSchedulePlaylist(scheduleId: number, weeklySchedule: PlaylistWithWeeklySchedule) {
    return this.http.put(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/schedule/${scheduleId}/playlist`,
      weeklySchedule);
  }

  deleteSchedulePlaylist(scheduleId: number, playlistId: number) {
    return this.http.delete(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/schedule/${scheduleId}/playlist/${playlistId}`);
  }

  getDeviceSchedule(deviceGuid: string): Observable<Schedule> {
    return this.http.get<Schedule>(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/schedule/device/${deviceGuid}`);
  }

  getFileUriFromCdn(fileName: string) {
    return `${this.appBase}cdn/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${fileName.substring(1)}`;
  }
}