<div #pagetop class="card shadow mb-4">
    <div class="card-header py-3">
        <div class="row">
            <h5 *ngIf="!isEditForm" class="col-9 m-0 font-weight-bold text-primary">
                {{ 'add-category' | translate }}
            </h5>
            <h5 *ngIf="isEditForm" class="col-9 m-0 font-weight-bold text-primary">
                {{ 'edit-category' | translate }}
            </h5>
            <div class="col-3 text-right">
                <mat-slide-toggle color="primary" [disabled]="isLockedForEditing" [checked]="advancedMode"
                    (change)="advancedMode = !advancedMode">
                    {{ 'advanced-mode' | translate }}
                </mat-slide-toggle>
            </div>
        </div>
    </div>
    <div *ngIf="category" class="card-body">
        <form #form="ngForm" autocomplete="off">
            <div class="form-group">
                <div class="input-group">
                    <div *ngIf="category.systemCategory"
                        class="col-12 text-right font-weight-bold text-primary title-based-on-system-role">
                        {{ 'locale-category.based-on-system-category' | translate }} {{ category.systemCategory.fullName
                        }}
                        <button class="btn mr-2" (click)="changeIsLockedForEditing()"
                            matTooltip="{{ 'change-edit-mode' | translate }}" tooltipClass=""
                            [disabled]="!isLockEnabled">
                            <i *ngIf="isLockedForEditing" class="material-icons">lock_outline</i>
                            <i *ngIf="!isLockedForEditing" class="material-icons">lock_open</i>
                        </button>
                    </div>
                </div>
                <div class="input-group">
                    *<div class="font-weight-bold">{{ 'category-name' | translate }}</div>
                    <button
                        *ngIf="isEditForm && category.systemCategory && category.fullName != category.systemCategory.fullName"
                        (click)="setSystemCategoryValueOnFullName()" [disabled]="isLockedForEditing"
                        class="btn btn-warning btn-circle small-tooltip-icon ml-2 mb-2"
                        matTooltip="{{ 'locale-category.restore-attribute-from-system-category' | translate }}"
                        tooltipClass="tooltip-warning">
                        <i class="fas fa-undo"></i>
                    </button>
                </div>
                <div class="input-group">
                    <input [disabled]="isLockedForEditing" name="fullName" #fullName="ngModel"
                        [(ngModel)]="category.fullName" class="form-control" (ngModelChange)="checkisLockEnabled()"
                        required>
                </div>
                <div *ngIf="fullName.invalid && (fullName.dirty || fullName.touched)" class="text-danger mx-2 my-1">
                    <div *ngIf="fullName.errors.required">{{ 'category-name-required' | translate }}</div>
                </div>
            </div>

            <div class="form-group">
                <div class="input-group">
                    *<div class="font-weight-bold">{{ 'short-name' | translate }}</div>
                    <button
                        *ngIf="isEditForm && category.systemCategory && category.shortName != category.systemCategory.shortName"
                        (click)="setSystemCategoryValueOnShortName()" [disabled]="isLockedForEditing"
                        class="btn btn-warning btn-circle small-tooltip-icon ml-2 mb-2"
                        matTooltip="{{ 'locale-category.restore-attribute-from-system-category' | translate }}"
                        tooltipClass="tooltip-warning">
                        <i class="fas fa-undo"></i>
                    </button>
                </div>
                <div class="input-group">
                    <input [disabled]="isLockedForEditing" name="shortName" #shortName="ngModel"
                        [(ngModel)]="category.shortName" class="form-control" (ngModelChange)="checkisLockEnabled()"
                        required>
                </div>
                <div *ngIf="shortName.invalid && (shortName.dirty || shortName.touched)" class="text-danger mx-2 my-1">
                    <div *ngIf="shortName.errors.required">{{ 'short-name-required' | translate }}</div>
                </div>
            </div>

            <div class="form-group">
                <div class="input-group">
                    *<div class="font-weight-bold">{{ 'tag' | translate }}</div>
                    <button
                        *ngIf="isEditForm && category.systemCategory && category.categoryTag != category.systemCategory.categoryTag"
                        (click)="setSystemCategoryValueOnCategoryTag()" [disabled]="isLockedForEditing"
                        class="btn btn-warning btn-circle small-tooltip-icon ml-2 mb-2"
                        matTooltip="{{ 'locale-category.restore-attribute-from-system-category' | translate }}"
                        tooltipClass="tooltip-warning">
                        <i class="fas fa-undo"></i>
                    </button>
                </div>
                <div class="input-group">
                    <input [disabled]="isLockedForEditing" name="categoryTag" #categoryTag="ngModel"
                        [(ngModel)]="category.categoryTag" class="form-control" maxlength="2"
                        (ngModelChange)="checkisLockEnabled()" required>
                </div>
                <div *ngIf="categoryTag.invalid && (categoryTag.dirty || categoryTag.touched)"
                    class="text-danger mx-2 my-1">
                    <div *ngIf="categoryTag.errors.required">{{ 'tag-required' | translate }}</div>
                </div>
            </div>

            <div class="form-group">
                <span class="font-weight-bold">{{ 'daily-ticket-limit' | translate }} </span>
                <mat-slide-toggle [disabled]="isLockedForEditing" color="primary" [checked]="checkedTicketLimit"
                    (change)="checkedTicketLimit = !checkedTicketLimit"></mat-slide-toggle>
                <button
                    *ngIf="isEditForm && category.systemCategory && category.ticketLimit != category.systemCategory.ticketLimit"
                    (click)="setSystemCategoryValueOnTicketLimit()" [disabled]="isLockedForEditing"
                    class="btn btn-warning btn-circle small-tooltip-icon ml-2 mb-2"
                    matTooltip="{{ 'locale-category.restore-attribute-from-system-category' | translate }}"
                    tooltipClass="tooltip-warning">
                    <i class="fas fa-undo"></i>
                </button>
                <div *ngIf="!checkedTicketLimit">
                    <label>{{ 'default-no-limit' | translate }}</label>
                </div>
                <div *ngIf="checkedTicketLimit">
                    <input [disabled]="isLockedForEditing" name="ticketLimit" #ticketLimit="ngModel"
                        [(ngModel)]="category.ticketLimit" class="form-control" type="number"
                        (ngModelChange)="checkisLockEnabled()" required>
                    <div *ngIf="ticketLimit.invalid && (ticketLimit.dirty || ticketLimit.touched)"
                        class="text-danger mx-2 my-1">
                        <div *ngIf="ticketLimit.errors">{{ 'ticket-limit-error' | translate }}</div>
                    </div>
                </div>
            </div>

            <div class="form-group" *ngIf="advancedMode">
                <span class="font-weight-bold">{{ 'time-per-ticket' | translate }} </span>
                <mat-slide-toggle [disabled]="isLockedForEditing" color="primary" [checked]="checkedTimePerTicket"
                    (change)="checkedTimePerTicket = !checkedTimePerTicket"></mat-slide-toggle>
                <button
                    *ngIf="isEditForm && category.systemCategory && category.timePerTicket != category.systemCategory.timePerTicket"
                    (click)="setSystemCategoryValueOnTimePerTicket()" [disabled]="isLockedForEditing"
                    class="btn btn-warning btn-circle small-tooltip-icon ml-2 mb-2"
                    matTooltip="{{ 'locale-category.restore-attribute-from-system-category' | translate }}"
                    tooltipClass="tooltip-warning">
                    <i class="fas fa-undo"></i>
                </button>
                <div *ngIf="!checkedTimePerTicket">
                    <label>{{ 'default-no-limit' | translate }}</label>
                </div>
                <div *ngIf="checkedTimePerTicket">
                    <input [disabled]="isLockedForEditing" name="timePerTicket" #timePerTicket="ngModel"
                        [(ngModel)]="category.timePerTicket" class="form-control" type="number"
                        (ngModelChange)="checkisLockEnabled()" required>
                    <div *ngIf="timePerTicket.invalid && (timePerTicket.dirty || timePerTicket.touched)"
                        class="text-danger mx-2 my-1">
                        <div *ngIf="timePerTicket.errors">{{ 'time-per-ticket-error' | translate }}</div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="font-weight-bold">{{ 'category-activity' | translate }}
                    <button *ngIf="isEditForm && !isAnyActiveTimeNotEqualToSystemCategeryActiveTime()"
                        (click)="setSystemCategoryValueOnUnblockingTimeDaysValue()" [disabled]="isLockedForEditing"
                        class="btn btn-warning btn-circle small-tooltip-icon ml-2 mb-2"
                        matTooltip="{{ 'locale-category.restore-attribute-from-system-category' | translate }}"
                        tooltipClass="tooltip-warning">
                        <i class="fas fa-undo"></i>
                    </button>
                </div>
                <app-weekly-activity [isReadOnly]="isLockedForEditing"
                    [unblockingTimeDaysValue]="category.unblockingTimeDaysValue" [nightWork]="nightWork"
                    [isUnblockingTimeDaysValueUpdated]="isUnblockingTimeDaysValueUpdated">
                </app-weekly-activity>
            </div>
            <app-category-activity-blocks *ngIf="isEditForm" [isReadOnly]="isLockedForEditing"
                [categoryId]="category.id"></app-category-activity-blocks>

            <div class="form-group">
                <div class="font-weight-bold">{{ 'blocking-category-activity-message' | translate }}
                    <button
                        *ngIf="isEditForm && category.systemCategory && category.blockingMessageActivity != category.systemCategory.blockingMessageActivity"
                        (click)="setSystemCategoryValueOnBlockingMessageActivity()" [disabled]="isLockedForEditing"
                        class="btn btn-warning btn-circle small-tooltip-icon ml-2 mb-2"
                        matTooltip="{{ 'locale-category.restore-attribute-from-system-category' | translate }}"
                        tooltipClass="tooltip-warning">
                        <i class="fas fa-undo"></i>
                    </button>
                </div>
                <div class="input-group">
                    <input [disabled]="isLockedForEditing" name="blockingMessageActivity"
                        #blockingMessageActivity="ngModel" [(ngModel)]="category.blockingMessageActivity"
                        class="form-control" (ngModelChange)="checkisLockEnabled()">
                </div>
            </div>
            <div class="form-group">
                <div class="font-weight-bold">{{ 'manual-blocking-category-message' | translate }}
                    <button
                        *ngIf="isEditForm && category.systemCategory && category.blockingMessageManual != category.systemCategory.blockingMessageManual"
                        (click)="setSystemCategoryValueOnBlockingMessageManual()" [disabled]="isLockedForEditing"
                        class="btn btn-warning btn-circle small-tooltip-icon ml-2 mb-2"
                        matTooltip="{{ 'locale-category.restore-attribute-from-system-category' | translate }}"
                        tooltipClass="tooltip-warning">
                        <i class="fas fa-undo"></i>
                    </button>
                </div>
                <div class="input-group">
                    <input [disabled]="isLockedForEditing" name="blockingMessageManual" #blockingMessageManual="ngModel"
                        [(ngModel)]="category.blockingMessageManual" class="form-control"
                        (ngModelChange)="checkisLockEnabled()">
                </div>
            </div>
            <div class="form-group">
                <div class="item-group">
                    <mat-checkbox [disabled]="isLockedForEditing" name="isAutomaticallyReset"
                        #isAutomaticallyReset="ngModel" [(ngModel)]="category.isAutomaticallyReset" #chkboxes
                        class="font-weight-bold" color="primary" (ngModelChange)="checkisLockEnabled()">{{
                        'automatic-reset' | translate }}
                    </mat-checkbox>
                    <button *ngIf="isEditForm && this.isChangedIsAutomaticallReset()" [disabled]="isLockedForEditing"
                        (click)="setSystemCategoryValueOnIsAutomaticallyReset()"
                        class="btn btn-warning btn-circle small-tooltip-icon ml-2 mb-2"
                        matTooltip="{{ 'locale-category.restore-attribute-from-system-category' | translate }}"
                        tooltipClass="tooltip-warning">
                        <i class="fas fa-undo"></i>
                    </button>
                </div>
            </div>

            <div *ngIf="advancedMode">
                <p class="font-weight-bold text-primary">{{ 'advanced-mode' | translate }}</p>

                <div class="form-group">
                    {{ 'set-max-service-time' | translate }}
                    <input *ngIf="checkedService" [disabled]="isLockedForEditing" name="maxServiceTime"
                        #maxServiceTime="ngModel" [(ngModel)]="category.maxServiceTime"
                        class="input-slider form-control" type="number" required
                        (change)="checkServiceTimeInput(category.maxServiceTime)">({{ 'minutes-shortcut' | translate }})
                    <mat-slide-toggle [disabled]="isLockedForEditing" color="primary" [checked]="checkedService"
                        (change)="checkedService = !checkedService; checkisLockEnabled();"></mat-slide-toggle>
                    <button
                        *ngIf="isEditForm && category.systemCategory && category.maxServiceTime != category.systemCategory.maxServiceTime"
                        (click)="setSystemCategoryValueOnMaxServiceTime()" [disabled]="isLockedForEditing"
                        class="btn btn-warning btn-circle small-tooltip-icon ml-2 mb-2"
                        matTooltip="{{ 'locale-category.restore-attribute-from-system-category' | translate }}"
                        tooltipClass="tooltip-warning">
                        <i class="fas fa-undo"></i>
                    </button>
                    <div *ngIf="!checkedService">
                        <label>{{ 'default-no-limit' | translate }}</label>
                    </div>
                    <div *ngIf="checkedService">
                        <mat-slider [disabled]="isLockedForEditing" [max]="maxService" [min]="minService"
                            color="primary" name="maxServiceTime" #maxServiceTime="ngModel"
                            [(ngModel)]="category.maxServiceTime" (ngModelChange)="checkisLockEnabled()">
                        </mat-slider>
                        <div *ngIf="maxServiceTime.invalid" class="text-danger mb-1">
                            <div *ngIf="maxServiceTime.errors">{{ 'max-service-time-error' | translate }}</div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    {{ 'set-default-pause-time' | translate }}
                    <input *ngIf="checkedPause" [disabled]="isLockedForEditing" name="defaultPauseTimeMins"
                        #defaultPauseTimeMins="ngModel" [(ngModel)]="category.defaultPauseTimeMins"
                        class="input-slider form-control" type="number"
                        (change)="checkPauseTimeInput(category.defaultPauseTimeMins); checkisLockEnabled();"
                        required>(min)
                    <mat-slide-toggle [disabled]="isLockedForEditing" color="primary" [checked]="checkedPause"
                        (change)="checkedPause = !checkedPause">
                    </mat-slide-toggle>
                    <button
                        *ngIf="isEditForm && category.systemCategory && category.defaultPauseTimeMins != category.systemCategory.defaultPauseTimeMins"
                        (click)="setSystemCategoryValueOnDefaultPauseTimeMins()" [disabled]="isLockedForEditing"
                        class="btn btn-warning btn-circle small-tooltip-icon ml-2 mb-2"
                        matTooltip="{{ 'locale-category.restore-attribute-from-system-category' | translate }}"
                        tooltipClass="tooltip-warning">
                        <i class="fas fa-undo"></i>
                    </button>
                    <div *ngIf="!checkedPause">
                        <label>{{ 'default-no-pause' | translate }}</label>
                    </div>
                    <div *ngIf="checkedPause">
                        <mat-slider [disabled]="isLockedForEditing" [max]="maxPause" [min]="minPause" [step]="step"
                            color="primary" name="defaultPauseTimeMins" #defaultPauseTimeMins="ngModel"
                            [(ngModel)]="category.defaultPauseTimeMins" (ngModelChange)="checkisLockEnabled()">
                        </mat-slider>
                        <div *ngIf="defaultPauseTimeMins.invalid" class="text-danger mb-1">
                            <div *ngIf="defaultPauseTimeMins.errors">{{ 'default-pause-time-error' | translate }}</div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="item-group">
                        <mat-checkbox [disabled]="isLockedForEditing" name="isNoteRequired" #isNoteRequired="ngModel"
                            [(ngModel)]="category.isNoteRequired" color="primary"
                            (ngModelChange)="checkisLockEnabled()">{{ 'ticket-note' | translate }}
                        </mat-checkbox>
                        <button
                            *ngIf="isEditForm && category.systemCategory && category.isNoteRequired != category.systemCategory.isNoteRequired"
                            (click)="setSystemCategoryValueOnIsNoteRequired()" [disabled]="isLockedForEditing"
                            class="btn btn-warning btn-circle small-tooltip-icon ml-2 mb-2"
                            matTooltip="{{ 'locale-category.restore-attribute-from-system-category' | translate }}"
                            tooltipClass="tooltip-warning">
                            <i class="fas fa-undo"></i>
                        </button>
                    </div>
                </div>
                <div class="form-group">
                    <div class="item-group">
                        <mat-checkbox *ngIf="locationService.getLocationConstraints().areEnabledTicketsReservations"
                            name="canBeReserved" #canBeReserved="ngModel" [(ngModel)]="category.canBeReserved"
                            color="primary" (ngModelChange)="checkisLockEnabled()">{{ 'can-be-reserve' | translate }}
                        </mat-checkbox>
                        <mat-checkbox *ngIf="!locationService.getLocationConstraints().areEnabledTicketsReservations"
                            disabled matTooltipPosition="right" matTooltipClass="tooltip-orange"
                            matTooltip="{{ 'option-available-after-extending-licence' | translate }}"
                            (ngModelChange)="checkisLockEnabled()">
                            {{ 'can-be-reserve' | translate }}
                        </mat-checkbox>
                        <button *ngIf="isEditForm && category.systemCategory && category.canBeReserved"
                            [disabled]="isLockedForEditing" (click)="setSystemCategoryValueOnCanBeReserved()"
                            class="btn btn-warning btn-circle small-tooltip-icon ml-2 mb-2"
                            matTooltip="{{ 'locale-category.restore-attribute-from-system-category' | translate }}"
                            tooltipClass="tooltip-warning">
                            <i class="fas fa-undo"></i>
                        </button>
                    </div>
                </div>
                <div class="form-group">
                    <div class="item-group">
                        <mat-checkbox *ngIf="locationService.getLocationConstraints().areETicketsEnabled"
                            [disabled]="isLockedForEditing" name="eTicketRedirect" #eTicketRedirect="ngModel"
                            [(ngModel)]="category.eTicketRedirect" color="primary"
                            (ngModelChange)="checkisLockEnabled()">{{ 'eticket-redirect' | translate }}
                        </mat-checkbox>
                        <button
                            *ngIf="isEditForm && category.systemCategory && category.eTicketRedirect != category.systemCategory.eTicketRedirect"
                            (click)="setSystemCategoryValueOnETicketRedirect()" [disabled]="isLockedForEditing"
                            class="btn btn-warning btn-circle small-tooltip-icon ml-2 mb-2"
                            matTooltip="{{ 'locale-category.restore-attribute-from-system-category' | translate }}"
                            tooltipClass="tooltip-warning">
                            <i class="fas fa-undo"></i>
                        </button>

                        <mat-checkbox *ngIf="!locationService.getLocationConstraints().areETicketsEnabled"
                            matTooltipPosition="right" matTooltipClass="tooltip-orange"
                            matTooltip="{{ 'option-available-after-extending-licence' | translate }}"
                            (ngModelChange)="checkisLockEnabled()" disabled>
                            {{ 'eticket-redirect' | translate }}
                        </mat-checkbox>
                    </div>
                </div>

                <div class="form-group">
                    <div class="item-group">
                        <mat-checkbox *ngIf="locationService.getLocationConstraints().isOnHoursEnabled"
                            [disabled]="isLockedForEditing" name="eTicketRedirect" #eTicketRedirect="ngModel"
                            [(ngModel)]="category.canOnHour" color="primary" (ngModelChange)="checkisLockEnabled()">{{
                            'on-hours' | translate }}
                        </mat-checkbox>
                        <button
                            *ngIf="isEditForm && category.systemCategory && category.canOnHour != category.systemCategory.canOnHour"
                            (click)="setSystemCategoryValueOnCanOnHour()" [disabled]="isLockedForEditing"
                            class="btn btn-warning btn-circle small-tooltip-icon ml-2 mb-2"
                            matTooltip="{{ 'locale-category.restore-attribute-from-system-category' | translate }}"
                            tooltipClass="tooltip-warning">
                            <i class="fas fa-undo"></i>
                        </button>
                        <mat-checkbox *ngIf="!locationService.getLocationConstraints().isOnHoursEnabled"
                            matTooltipPosition="right" matTooltipClass="tooltip-orange"
                            matTooltip="{{ 'option-available-after-extending-licence' | translate }}"
                            (ngModelChange)="checkisLockEnabled()" disabled>
                            {{ 'on-hours' | translate }}
                        </mat-checkbox>
                    </div>
                </div>

                <div class="form-group">
                    <div class="item-group">
                        <mat-checkbox *ngIf="locationService.getLocationConstraints().isSmsEnabled"
                            [disabled]="isLockedForEditing" name="smsCategory" #smsCategory="ngModel"
                            [(ngModel)]="category.smsCategory" color="primary"
                            (ngModelChange)="checkisLockEnabled()">Sms
                        </mat-checkbox>
                        <button
                            *ngIf="isEditForm && category.systemCategory && category.smsCategory != category.systemCategory.smsCategory"
                            (click)="setSystemCategoryValueOnSmsCategory()" [disabled]="isLockedForEditing"
                            class="btn btn-warning btn-circle small-tooltip-icon ml-2 mb-2"
                            matTooltip="{{ 'locale-category.restore-attribute-from-system-category' | translate }}"
                            tooltipClass="tooltip-warning">
                            <i class="fas fa-undo"></i>
                        </button>
                        <mat-checkbox *ngIf="!locationService.getLocationConstraints().isSmsEnabled"
                            matTooltipPosition="right" matTooltipClass="tooltip-orange"
                            matTooltip="{{ 'option-available-after-extending-licence' | translate }}"
                            (ngModelChange)="checkisLockEnabled()" disabled>
                            Sms
                        </mat-checkbox>
                        <div *ngIf="category.smsCategory" class="ml-3 form-group">
                            <div class="item-group">
                                <mat-checkbox [disabled]="isLockedForEditing" name="sendSmsOnReservation"
                                    #sendSmsOnReservation="ngModel" [(ngModel)]="category.sendSmsOnReservation"
                                    color="primary" (ngModelChange)="checkisLockEnabled()">
                                    {{ 'sending-sms-on-reservation' | translate }}
                                </mat-checkbox>
                                <button
                                    *ngIf="isEditForm && category.systemCategory && category.sendSmsOnReservation != category.systemCategory.sendSmsOnReservation"
                                    (click)="setSystemCategoryValueOnSendSmsOnReservation()"
                                    [disabled]="isLockedForEditing"
                                    class="btn btn-warning btn-circle small-tooltip-icon ml-2 mb-2"
                                    matTooltip="{{ 'locale-category.restore-attribute-from-system-category' | translate }}"
                                    tooltipClass="tooltip-warning">
                                    <i class="fas fa-undo"></i>
                                </button>
                            </div>
                            <div class="item-group">
                                <mat-checkbox [disabled]="isLockedForEditing" name="sendSmsOnPrint"
                                    #sendSmsOnPrint="ngModel" [(ngModel)]="category.sendSmsOnPrint" color="primary"
                                    (ngModelChange)="checkisLockEnabled()">
                                    {{ 'sending-sms-on-print-ticket' | translate }}
                                </mat-checkbox>
                                <button
                                    *ngIf="isEditForm && category.systemCategory && category.sendSmsOnPrint != category.systemCategory.sendSmsOnPrint"
                                    (click)="setSystemCategoryValueOnSendSmsOnPrint()" [disabled]="isLockedForEditing"
                                    class="btn btn-warning btn-circle small-tooltip-icon ml-2 mb-2"
                                    matTooltip="{{ 'locale-category.restore-attribute-from-system-category' | translate }}"
                                    tooltipClass="tooltip-warning">
                                    <i class="fas fa-undo"></i>
                                </button>
                                <div class="item-group">
                                </div>
                                <mat-checkbox [disabled]="isLockedForEditing" name="sendOnBeforeCall"
                                    #sendSmsOnBeforeCall="ngModel" [(ngModel)]="category.sendSmsOnBeforeCall"
                                    color="primary" (ngModelChange)="checkisLockEnabled()">
                                    {{ 'sending-sms-on-before-ticket-call' | translate }}
                                </mat-checkbox>
                                <button
                                    *ngIf="isEditForm && category.systemCategory && category.sendSmsOnBeforeCall != category.systemCategory.sendSmsOnBeforeCall"
                                    (click)="setSystemCategoryValueOnSendSmsOnBeforeCall()"
                                    [disabled]="isLockedForEditing"
                                    class="btn btn-warning btn-circle small-tooltip-icon ml-2 mb-2"
                                    matTooltip="{{ 'locale-category.restore-attribute-from-system-category' | translate }}"
                                    tooltipClass="tooltip-warning">
                                    <i class="fas fa-undo"></i>
                                </button>
                            </div>
                            <div class="item-group">
                                <mat-checkbox [disabled]="isLockedForEditing" name="sendSmsOnTicketCall"
                                    #sendSmsOnTicketCall="ngModel" [(ngModel)]="category.sendSmsOnTicketCall"
                                    color="primary" (ngModelChange)="checkisLockEnabled()">
                                    {{ 'sending-sms-on-ticket-call' | translate }}
                                </mat-checkbox>
                                <button
                                    *ngIf="isEditForm && category.systemCategory && category.sendSmsOnTicketCall != category.systemCategory.sendSmsOnTicketCall"
                                    (click)="setSystemCategoryValueOnSendSmsOnTicketCall()"
                                    [disabled]="isLockedForEditing"
                                    class="btn btn-warning btn-circle small-tooltip-icon ml-2 mb-2"
                                    matTooltip="{{ 'locale-category.restore-attribute-from-system-category' | translate }}"
                                    tooltipClass="tooltip-warning">
                                    <i class="fas fa-undo"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="item-group">
                        <mat-checkbox *ngIf="!category.conditionalTicket" [disabled]="isLockedForEditing"
                            color="primary" name="avgTimeBlock" #avgTimeBlock="ngModel"
                            [(ngModel)]="category.avgTimeBlock" (ngModelChange)="checkisLockEnabled()">
                            {{ 'smart-block' | translate }}
                        </mat-checkbox>
                        <mat-checkbox *ngIf="category.conditionalTicket" disabled matTooltipPosition="right"
                            matTooltipClass="tooltip-info" matTooltip="{{ 'turn-off-conditional-ticket' | translate }}"
                            (ngModelChange)="checkisLockEnabled()">
                            {{ 'smart-block' | translate }}
                        </mat-checkbox>
                        <button *ngIf="isEditForm && isChangedAvgTimeBlock()" [disabled]="isLockedForEditing"
                            (click)="setSystemCategoryValueAvgTimeBlock()"
                            class="btn btn-warning btn-circle small-tooltip-icon ml-2 mb-2"
                            matTooltip="{{ 'locale-category.restore-attribute-from-system-category' | translate }}"
                            tooltipClass="tooltip-warning">
                            <i class="fas fa-undo"></i>
                        </button>
                    </div>
                    <div *ngIf="category.avgTimeBlock" class="input-group">
                        <input [disabled]="isLockedForEditing" name="avgTimeBlockText" #avgTimeBlockText="ngModel"
                            [(ngModel)]="category.avgTimeBlockText" class="form-control"
                            placeholder="{{ 'smart-block-text' | translate}}" (ngModelChange)="checkisLockEnabled()">
                    </div>
                </div>
                <div class="form-group">
                    <div class="item-group">
                        <mat-checkbox *ngIf="!category.avgTimeBlock" [disabled]="isLockedForEditing" color="primary"
                            name="conditionalTicket" #conditionalTicket="ngModel"
                            [(ngModel)]="category.conditionalTicket" (ngModelChange)="checkisLockEnabled()">
                            {{ 'conditional-ticket' | translate }}
                        </mat-checkbox>
                        <mat-checkbox *ngIf="category.avgTimeBlock" disabled matTooltipPosition="right"
                            matTooltipClass="tooltip-info" matTooltip="{{ 'turn-off-smart-block' | translate }}"
                            (ngModelChange)="checkisLockEnabled()">
                            {{ 'conditional-ticket' | translate }}
                        </mat-checkbox>
                        <button *ngIf="isEditForm && isChangedConditionalTicket()" [disabled]="isLockedForEditing"
                            (click)="setSystemCategoryValueConditionalTicket()"
                            class="btn btn-warning btn-circle small-tooltip-icon ml-2 mb-2"
                            matTooltip="{{ 'locale-category.restore-attribute-from-system-category' | translate }}"
                            tooltipClass="tooltip-warning">
                            <i class="fas fa-undo"></i>
                        </button>
                    </div>
                    <div *ngIf="category.conditionalTicket" class="input-group">
                        <input [disabled]="isLockedForEditing" name="conditionalTicketText"
                            #conditionalTicketText="ngModel" [(ngModel)]="category.conditionalTicketText"
                            class="form-control" placeholder="{{ 'conditional-ticket-text' | translate}}"
                            (ngModelChange)="checkisLockEnabled()">
                    </div>
                </div>

                <div class="form-group">
                    {{ 'additional-text-ticket' | translate }}
                    <button
                        *ngIf="isEditForm && ((category.printedTicketAdditionalText ?? '') != (category.systemCategory?.printedTicketAdditionalText ?? ''))"
                        [disabled]="isLockedForEditing" class="btn btn-warning btn-circle small-tooltip-icon ml-2 mb-2"
                        matTooltip="{{ 'locale-category.restore-attribute-from-system-category' | translate }}"
                        (click)="setSystemCategoryValueOnPrintedTicketAdditionalText()" tooltipClass="tooltip-warning">
                        <i class="fas fa-undo"></i>
                    </button>
                    <div class="input-group">
                        <input [disabled]="isLockedForEditing" name="printedTicketAdditionalText" #prin
                            tedTicketAdditionalText="ngModel" [(ngModel)]="category.printedTicketAdditionalText"
                            class="form-control">
                    </div>
                </div>
                <div class="form-group">
                    <div class="item-group">
                        <mat-checkbox [disabled]="isLockedForEditing" name="canMobile" #canMobile="ngModel"
                            [(ngModel)]="category.canMobile" color="primary" (ngModelChange)="checkisLockEnabled()">{{
                            'mobile-app' | translate }}
                        </mat-checkbox>
                        <button
                            *ngIf="isEditForm && category.systemCategory && category.canMobile != category.systemCategory.canMobile"
                            (click)="setSystemCategoryValueOnCanMobile()" [disabled]="isLockedForEditing"
                            class="btn btn-warning btn-circle small-tooltip-icon ml-2 mb-2"
                            matTooltip="{{ 'locale-category.restore-attribute-from-system-category' | translate }}"
                            tooltipClass="tooltip-warning">
                            <i class="fas fa-undo"></i>
                        </button>
                    </div>
                </div>

                <div class="form-group">
                    {{ 'number-of-recent-tickets-used-to-count-average-service-and-waiting-times' | translate }}
                    <button
                        *ngIf="isEditForm && category.systemCategory && category.ticketAmountToCountAverageWaitingServiceTime != category.systemCategory.ticketAmountToCountAverageWaitingServiceTime"
                        (click)="setSystemCategoryValueOnTicketAmountToCountAverageWaitingServiceTime()"
                        [disabled]="isLockedForEditing" class="btn btn-warning btn-circle small-tooltip-icon ml-2 mb-2"
                        matTooltip="{{ 'locale-category.restore-attribute-from-system-category' | translate }}"
                        tooltipClass="tooltip-warning">
                        <i class="fas fa-undo"></i>
                    </button>
                    <div class="item-group">
                        <input [disabled]="isLockedForEditing" type="number" class="form-control"
                            name="ticketAmountToCountAverageWaitingServiceTime"
                            #ticketAmountToCountAverageWaitingServiceTime="ngModel"
                            [(ngModel)]="category.ticketAmountToCountAverageWaitingServiceTime"
                            (ngModelChange)="checkisLockEnabled()">
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <button class="btn btn-danger m-1" routerLink="/categories">{{ 'back' | translate }}</button>
                <button class="btn btn-primary m-1" type="submit"
                    [disabled]="formSubmitted || form.invalid || isLockedForEditing" (click)="onSubmit()">
                    <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                        aria-hidden="true"></span>{{ 'save-category' | translate }}
                </button>
                <button *ngIf="isEditForm" class="btn btn-info m-1" type="submit"
                    [disabled]="formSubmitted || form.invalid" (click)="newBasedOnExisting();
                    pagetop.scrollIntoView({behavior: 'smooth'})">
                    <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                        aria-hidden="true"></span>{{ 'new-category-based-on-existing' | translate }}
                </button>
            </div>
            <label *ngIf="form.invalid" class="text-danger">*{{ 'complete-required-fields' | translate }}</label>
        </form>
    </div>
</div>