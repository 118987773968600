import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { Tag } from 'projects/qcloud-models/multimedia-device/tag.model';

@Component({
  selector: 'app-assign-tags',
  templateUrl: './assign-tags.component.html',
  styleUrls: ['./assign-tags.component.css'],
  encapsulation : ViewEncapsulation.None
})
export class AssignTagsComponent implements OnInit {

  @Input() tags: Tag[];
  @Input() tagMultiCtrl: UntypedFormControl;
  @Input() title: string;
  public tagMultiFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredTagsMulti: ReplaySubject<Tag[]> = new ReplaySubject<Tag[]>(1);

  protected _onDestroy = new Subject<void>();

  constructor() { }

  ngOnInit(): void {
    // load the initial tag list
    this.filteredTagsMulti.next(this.tags.slice());

    // listen for search field value changes
    this.tagMultiFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterTagsMulti();
    });
  }

  compareTags(tag1: Tag, tag2: Tag): boolean {
    return tag1.name === tag2.name && tag1.id === tag2.id;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredTagsMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.tagMultiCtrl.patchValue(val);
        } else {
          this.tagMultiCtrl.patchValue([]);
        }
      });
  }

  protected filterTagsMulti() {
    if (!this.tags) {
      return;
    }
    // get the search keyword
    let search = this.tagMultiFilterCtrl.value;
    if (!search) {
      this.filteredTagsMulti.next(this.tags.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the Tags
    this.filteredTagsMulti.next(
      this.tags.filter(tag => tag.name.toLowerCase().indexOf(search) > -1)
    );
  }

}
