import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { QCloudRestClientService } from '../../../qcloud-rest-client/src/lib/qcloud-rest-client.service';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { DeskService } from 'projects/qcloud-rest-client/src/lib/desk.service';
import { UserService } from 'projects/qcloud-rest-client/src/lib/user.service';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { DeviceService } from 'projects/qcloud-rest-client/src/lib/device.service';
import { FileService } from 'projects/qcloud-rest-client/src/lib/file.service';
import { QcloudStompClientService } from 'projects/qcloud-stomp-client/src/lib/qcloud-stomp-client.service';
import { MultimediaService } from 'projects/qcloud-rest-client/src/lib/multimedia.service';
import { TranslationService } from 'projects/qcloud-rest-client/src/lib/translation.service';
import { SystemService } from 'projects/qcloud-rest-client/src/lib/system.service';
import { EmailConfigService } from 'projects/qcloud-rest-client/src/lib/email-config.service';
import { ReservationService } from 'projects/qcloud-rest-client/src/lib/reservation.service';
import { AxxonIntelectIntegrationService } from 'projects/qcloud-rest-client/src/lib/axxon-intelect-integration.service';
import { StatisticsService } from 'projects/qcloud-rest-client/src/lib/statistics.service';
import { LicenseService } from 'projects/qcloud-rest-client/src/lib/license.service';
import { ETicketService } from 'projects/qcloud-rest-client/src/lib/eticket.service';
import { EmployeeService } from 'projects/qcloud-rest-client/src/lib/grafik/employee.service';
import { SpecialisationService } from 'projects/qcloud-rest-client/src/lib/grafik/specialisation.service';
import { OfficeService } from 'projects/qcloud-rest-client/src/lib/grafik/office.service';
import { NewsService } from 'projects/qcloud-rest-client/src/lib/grafik/news.service';
import { GrafikFileService } from 'projects/qcloud-rest-client/src/lib/grafik/grafik-file.service';
import { ReportService } from 'projects/qcloud-rest-client/src/lib/report.service';
import { AmmsIntegrationService } from 'projects/qcloud-rest-client/src/lib/amms-integration.service';
import { SmsConfigService } from 'projects/qcloud-rest-client/src/lib/sms-config.service';
import { ConsoleRestService } from 'projects/qcloud-rest-client/src/lib/console-rest.service';
import { TicketService } from 'projects/qcloud-rest-client/src/lib/ticket.service';
import { SurveyService } from 'projects/qcloud-rest-client/src/lib/survey.service';
import { DeviceLayoutClientService } from 'projects/qcloud-rest-client/src/lib/device-layout-client.service';
import { AutoGenerateReportService } from 'projects/qcloud-rest-client/src/lib/autogenerate-report.service';
import { ReservationGroupClientService } from 'projects/qcloud-rest-client/src/lib/reservation-group-client.service';
import { ConfigurationScheduleService } from 'projects/qcloud-rest-client/src/lib/grafik/configurationSchedule.service';
import { OnHoursService } from 'projects/qcloud-rest-client/src/lib/onHours.service';
import { LdapConfigurationService } from '../../../qcloud-rest-client/src/lib/ldap-configuration.service';
import { QcloudRoleService } from '../../../qcloud-rest-client/src/lib/role/qcloud-role.service';
import { TicketNoteService } from '../../../qcloud-rest-client/src/lib/note/ticket-note.service';
import { TicketNoteConfigurationService } from '../../../qcloud-rest-client/src/lib/note/ticket-note-configuration.service';
import { LdapRoleService } from '../../../qcloud-rest-client/src/lib/ldap/ldap-role.service';
import { SystemCategoryService } from '../../../qcloud-rest-client/src/lib/category/system-category.service';
import { SystemDeskService } from '../../../qcloud-rest-client/src/lib/desk/system-desk.service';
import { AuditLogClientService } from 'projects/qcloud-rest-client/src/lib/audit-log-client.service';
import { TicketSystemCategoryQuestionService } from 'projects/qcloud-rest-client/src/lib/category/ticket-system-category-question.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  public appConfig;

  constructor(private http: HttpClient,
    private qCloudRestClientService: QCloudRestClientService,
    private categoryService: CategoryService,
    private systemCategoryService: SystemCategoryService,
    private compositionService: ReservationGroupClientService,
    private deskService: DeskService,
    private systemDeskService: SystemDeskService,
    private userService: UserService,
    private deviceService: DeviceService,
    private locationService: LocationService,
    private fileService: FileService,
    private stompClientService: QcloudStompClientService,
    private multimediaService: MultimediaService,
    private translationService: TranslationService,
    private systemService: SystemService,
    private licenseService: LicenseService,
    private emailConfigServcie: EmailConfigService,
    private statisticsServcie: StatisticsService,
    private reservationService: ReservationService,
    private axxonIntelectIntegrationService: AxxonIntelectIntegrationService,
    private eticketservice: ETicketService,
    private specialitySerivce: SpecialisationService,
    private officeService: OfficeService,
    private newsService: NewsService,
    private grafikFileService: GrafikFileService,
    private employeeService: EmployeeService,
    private reportService: ReportService,
    private ammsIntegrationService: AmmsIntegrationService,
    private smsConfigService: SmsConfigService,
    private consoleRestService: ConsoleRestService,
    private ticketService: TicketService,
    private surveyService: SurveyService,
    private deviceLayoutsService: DeviceLayoutClientService,
    private autoGenerateReportService: AutoGenerateReportService,
    private configurationScheduleService: ConfigurationScheduleService,
    private onHoursService: OnHoursService,
    private ldapConfigurationService: LdapConfigurationService,
    private qcloudRoleService: QcloudRoleService,
    private ticketNoteService: TicketNoteService,
    private ldapRoleService: LdapRoleService,
    private ticketNoteConfigurationService: TicketNoteConfigurationService,
    private auditLogService: AuditLogClientService,
    private ticketSystemCategoryQuestionService: TicketSystemCategoryQuestionService) { }

  public loadAppConfig() {
    return this.http
      .get('/assets/config.json')
      .toPromise()
      .then(data => {
        this.appConfig = data;
        this.qCloudRestClientService.setAppBase(this.appConfig.appBase);
        this.categoryService.setAppBase(this.appConfig.appBase);
        this.compositionService.setAppBase(this.appConfig.appBase);
        this.deskService.setAppBase(this.appConfig.appBase);
        this.systemDeskService.setAppBase(this.appConfig.appBase);
        this.userService.setAppBase(this.appConfig.appBase);
        this.deviceService.setAppBase(this.appConfig.appBase);
        this.locationService.setAppBase(this.appConfig.appBase);
        this.translationService.setAppBase(this.appConfig.appBase);
        this.systemService.setAppBase(this.appConfig.appBase);
        this.licenseService.setAppBase(this.appConfig.appBase);
        this.emailConfigServcie.setAppBase(this.appConfig.appBase);
        this.statisticsServcie.setAppBase(this.appConfig.appBase);
        this.reservationService.setAppBase(this.appConfig.appBase);
        this.axxonIntelectIntegrationService.setAppBase(this.appConfig.appBase);
        this.autoGenerateReportService.setAppBase(this.appConfig.appBase);
        this.ammsIntegrationService.setAppBase(this.appConfig.appBase);
        this.smsConfigService.setAppBase(this.appConfig.appBase);
        this.consoleRestService.setAppBase(this.appConfig.appBase);
        this.ticketService.setAppBase(this.appConfig.appBase);
        this.deviceLayoutsService.setAppBase(this.appConfig.appBase);
        this.fileService.setAppBase(this.appConfig.qds);
        this.multimediaService.setAppBase(this.appConfig.qds);
        this.stompClientService.initStomp(this.appConfig.stompUrl);
        this.eticketservice.setAppBase(this.appConfig.eticketAppBase);
        this.employeeService.setAppBase(this.appConfig.graphicAppBase);
        this.specialitySerivce.setAppBase(this.appConfig.graphicAppBase);
        this.officeService.setAppBase(this.appConfig.graphicAppBase);
        this.newsService.setAppBase(this.appConfig.graphicAppBase);
        this.grafikFileService.setAppBase(this.appConfig.graphicAppBase);
        this.reportService.setAppBase(this.appConfig.reportAppBase);
        this.surveyService.setAppBase(this.appConfig.surveyAppBase);
        this.configurationScheduleService.setAppBase(this.appConfig.graphicAppBase);
        this.onHoursService.setAppBase(this.appConfig.appBase);
        this.ldapConfigurationService.setAppBase(this.appConfig.appBase);
        this.qcloudRoleService.setAppBase(this.appConfig.appBase);
        this.ldapRoleService.setAppBase(this.appConfig.appBase);
        this.ticketNoteService.setAppBase(this.appConfig.appBase);
        this.ticketNoteConfigurationService.setAppBase(this.appConfig.appBase);
        this.systemCategoryService.setAppBase(this.appConfig.appBase);
        this.auditLogService.setAppBase(this.appConfig.logsAppBase)
        this.ticketSystemCategoryQuestionService.setAppBase(this.appConfig.appBase);
      });
  }
}
