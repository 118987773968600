import { Component, OnInit } from '@angular/core';
import { Category } from 'projects/qcloud-models/category/category.model';
import { QcloudConsoleService } from '../../qcloud-console.service';

@Component({
  selector: 'lib-blocked-categories-modal',
  templateUrl: './blocked-categories-modal.component.html',
  styleUrls: ['./blocked-categories-modal.component.css']
})
export class BlockedCategoriesModalComponent implements OnInit {

  categories: Category[];
  private allUnblocked: boolean;
  
  constructor(private consoleService: QcloudConsoleService) {
    this.categories = consoleService.getCategoriesToCallTicket();
    this.allUnblocked = true;
  }
  
  ngOnInit(): void {
  }

  changeAllBlocks()
  {
    if(this.allUnblocked){
      this.categories.forEach(x=>{
        this.consoleService.blockCategory(x.id);
      });
    }else{
      this.categories.forEach(x=>{
        this.consoleService.unblockCategory(x.id);
      });
    }
    this.allUnblocked = !this.allUnblocked;
  }
  changeBlock(category: Category) {
    if (category.isBlocked) {
      this.consoleService.unblockCategory(category.id);
    } else {
      this.consoleService.blockCategory(category.id);
    }
  }

}
