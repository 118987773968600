import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ETicketService {

  appBase: string;

  constructor(private http: HttpClient, private authService: AuthService) { }

  public setAppBase(appBase: string){
    this.appBase = appBase;
  }

  public getCode(): Observable<any> {
    return this.http.get(
        `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/code`);
  }

  public generateNewCode(): Observable<any> {
    return this.http.get(
        `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/code/new`);
  }

  public downloadCode(appBase: string): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.post(
        `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/code/download`,
        {appBase}, httpOptions);
  }
}
