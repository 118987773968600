<div class="card-header py-3">
    <h6 class="m-0 font-weight-bold text-primary">{{ 'daily-queueing' | translate}}</h6>
</div>
<div class="card-body">
    <app-served-categories *ngIf="categoryMultiCtrl" [categoryMultiCtrl]="categoryMultiCtrl" [categories]="categories"
        [title]="title" [noSelectedCategories]="noSelectedCategories" [displayCategories]="false"
        (categoriesToEmit)="changeCategories($event)">
    </app-served-categories>
    <div class="chart-area">
        <canvas id="dailyQueueingChart"></canvas>
    </div>
</div>
