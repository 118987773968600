{{ 'time-zone' | translate }}:
<mat-form-field>
    <mat-select [(ngModel)]="locationData.timeZone">
        <mat-option>
            <ngx-mat-select-search [formControl]="timezoneMultiFilterCtrl"
                placeholderLabel="{{ 'search-timezone' | translate }}"
                noEntriesFoundLabel="{{ 'no-matching-timezone' | translate }}">
            </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let item of filteredTimezonesMulti | async" [value]="item.val">
            {{ item.displayString }}
        </mat-option>
    </mat-select>
</mat-form-field>