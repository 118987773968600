import { Component, OnInit } from '@angular/core';
import { Category } from 'projects/qcloud-models/category/category.model';
import { LocationActivityBlock } from 'projects/qcloud-models/location/location-activity-block.model';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { ReservationVacationService } from '../reservation-vacation.service';

@Component({
  selector: 'app-reservation-vacation-main',
  templateUrl: './reservation-vacation-main.component.html',
  styleUrls: ['./reservation-vacation-main.component.css']
})
export class ReservationVacationMainComponent implements OnInit {

  locationActivityBlocks: LocationActivityBlock[];
  categoryId: number;

  constructor(private reservationVacationService: ReservationVacationService,private locationService: LocationService) {
   }

  ngOnInit(): void {
    this.reservationVacationService.choosenCategory.subscribe((data: Category)=>{
      if(data){
      this.categoryId=data.id;
      this.getLocationActivityBlocks(data.id);
      }
    });
    this.reservationVacationService.edit.subscribe(data=>{
      this.getLocationActivityBlocks(this.categoryId);
    });
    
  }

      getLocationActivityBlocks(categoryId: number){
        this.locationService.getVacationActivity().subscribe(
          res => {
            this.locationActivityBlocks = res.filter(x => x.blockedCategoryIds.find(y => y == categoryId));
            this.locationActivityBlocks.forEach(activityBlock => {
              activityBlock.dateStart = new Date(activityBlock.dateStart);
              activityBlock.dateEnd = new Date(activityBlock.dateEnd);
            });
          }
        );
      }
      edit(activityBlock: LocationActivityBlock){
        this.reservationVacationService.updateEdit(activityBlock);
      }

      delete(activityBlock: LocationActivityBlock){
        this.reservationVacationService.updateDelete(activityBlock);
      }

}
