import { Component, OnInit } from '@angular/core';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { RouteNavigationService } from '../../../shared/route-navigation.service';

@Component({
  selector: 'app-work-schedule',
  templateUrl: './work-schedule.component.html',
  styleUrls: ['./work-schedule.component.css']
})
export class WorkScheduleComponent implements OnInit {

  constructor(private locationService: LocationService,
    private authService: AuthService, public router: RouteNavigationService) { }

  ngOnInit(): void {
    this.locationService.getLocationData(this.authService.getLocationId()).subscribe(
      locData => this.locationService.locationData = locData
    );
  }

  checkConstraints(): boolean {
    return this.locationService.getLocationConstraints()?.isWorkersScheduleEnabled;
  }
}
