import { Component, OnInit } from '@angular/core';
import { Category } from 'projects/qcloud-models/category/category.model';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ReservationGroupClientService } from 'projects/qcloud-rest-client/src/lib/reservation-group-client.service';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { CategoryGroup } from 'projects/qcloud-models/category/category-group.model';

@Component({
  selector: 'app-reservation-group-by-categories',
  templateUrl: './reservation-group-by-categories.component.html',
  styleUrls: ['./reservation-group-by-categories.component.css']
})
export class ReservationGroupByCategoriesComponent implements OnInit {

  categories: Category[];
  categoryGroups: CategoryGroup[];
  newCategoryGroups: CategoryGroup[];
  protected _onDestroy = new Subject<void>();
  constructor(
    private categoryServiceClient: CategoryService,
    private reservationGroupClient: ReservationGroupClientService,
    private toastr: ToastrService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.updateCategoryGroups();
  }

  updateCategoryGroups() {
    this.categoryServiceClient.getCategories(
      (data: Category[]) => {
        this.categories = data.filter(x => x.canBeReserved);
        this.reservationGroupClient.getGroups(groups => {
          let converted = [];
          groups?.forEach(g => {
            var c: CategoryGroup = {
              id: g.id,
              name: g.name,
              locationId: 0,
              groupCategoriesIds: g.categories.map(y => y.id)
            }
            converted.push(c);
          });
          this.categoryGroups = converted;

        }, () => { })
      }, () => { }
    )
  }
  setReservationCategoryGroups(event: any) {
    this.newCategoryGroups = event;
    var data = [];
    event.forEach(group => {
      var d: any = {};
      d.id = group.id,
        d.name = group.name;
      d.categoryIds = group.groupCategoriesIds;
      data.push(d);
    });
    this.reservationGroupClient.setGroups(data, () => {
      this.translate.get('reservation-group-configuration-save-success').subscribe((error: string) => {
        this.translate.get('repeat').subscribe((res: string) => {
          this.toastr.success(res);
          this.updateCategoryGroups();
        });
      });
    }, () => {
      this.translate.get('reservation-group-configuration-save-error').subscribe((error: string) => {
        this.translate.get('repeat').subscribe((res: string) => {
          this.toastr.error(res, error);
        });
      });
    });
  }
}