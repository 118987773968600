import { Injectable } from '@angular/core';
import { RxStomp } from '@stomp/rx-stomp';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QcloudStompClientService {
  private rxStomp: RxStomp;
  constructor() { }

  public initStomp(url: string) {
    this.rxStomp = new RxStomp();
    this.rxStomp.configure({
      brokerURL: url,
      connectHeaders: {
        login: 'webqclouduser',
        passcode: 'qcloud123'
      },
      heartbeatIncoming: 0,
      heartbeatOutgoing: 20000,
      reconnectDelay: 200,
      debug: (msg: string): void => {
        //console.log(new Date(), msg);
      }
    });
    this.rxStomp.activate();
  }

  public getClient(): RxStomp {
    return this.rxStomp;
  }

  public subscribe(route: string, callback: (payload: any) => void): Subscription {
    let headers = {
      'reply-to': '/temp-queue/webstomp',
    }

    let rxJsSubscription = this.rxStomp.watch(route).pipe(map(function (message) {
      // console.log(message);
      return JSON.parse(message.body);
    })).subscribe(function (payload) {
      // console.log(payload);
      callback(payload);
    });
    return rxJsSubscription;
  }

  public publish(route: string, msg: string) {
    this.rxStomp.publish({ destination: route, body: msg });
  }
}
