import { Component, OnInit, Input, ViewChild, TemplateRef, ViewEncapsulation } from '@angular/core';
import { PlaylistWithWeeklySchedule } from 'projects/qcloud-models/multimedia/playlist-with-weekly-schedulemodel';
import { WeeklySchedule } from 'projects/qcloud-models/multimedia/weekly-schedule.model';
import { Playlist } from 'projects/qcloud-models/multimedia/playlist.model';
import { TimeConversionService } from 'projects/qcloud-web/src/app/shared/time-conversion.service';
import { WeekDay } from '@angular/common';
import { WeekService } from 'projects/qcloud-web/src/app/shared/week.service';
import { TagsOperationTypes } from 'projects/qcloud-models/multimedia/tags-operation-types.enum';
import { ColorService } from 'projects/qcloud-web/src/app/shared/color.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-schedule-layout',
  templateUrl: './schedule-layout.component.html',
  styleUrls: ['./schedule-layout.component.css'],
  encapsulation : ViewEncapsulation.None
})
export class ScheduleLayoutComponent implements OnInit {

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  @Input() ctx;
  thematicTags: string = "thematic-tags";
  dayNames = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
  selectedDays = new Array<boolean>(7);
  dateStart: Date;
  dateEnd: Date;
  beginTime: string;
  endTime: string;
  playlistExists: boolean;
  TagsOperationTypes = TagsOperationTypes;
  scheduleSpace: number;

  modalData: {
    playlist: PlaylistWithWeeklySchedule;
    playlists: Playlist[];
  };

  scheduleCtx = this;

  constructor(private modal: MatDialog, private timeConversionService: TimeConversionService,
    private weekService: WeekService, private colorService: ColorService) { }

  ngOnInit(): void {
    this.cleanInputs();
  }

  managePlaylist(playlists: Playlist[], playlist?: PlaylistWithWeeklySchedule): void {
    if (!playlist) {
      this.playlistExists = false;
      playlist = new PlaylistWithWeeklySchedule();
      playlist.weeklySchedule = new WeeklySchedule();
      playlist.weeklySchedule.daysOfWeek = new Array<WeekDay>();
    } else {
      this.playlistExists = true;
      this.updateSelected(playlist.weeklySchedule.daysOfWeek);
      this.dateStart = new Date(playlist.dateStart);
      this.dateEnd = new Date(playlist.dateEnd);
      this.beginTime = this.timeConversionService.dateToTime(playlist.weeklySchedule.beginTime);
      this.endTime = this.timeConversionService.dateToTime(playlist.weeklySchedule.endTime);
    }
    this.modalData = { playlist, playlists };
    var matDialogConfig = new MatDialogConfig();
    matDialogConfig.maxHeight = 900;
    matDialogConfig.maxWidth = 800;
    this.modal.open(this.modalContent, matDialogConfig);
  }

  selectAll() {
    this.updateSelected(this.weekService.selectAll());
  }

  unselectAll() {
    this.updateSelected(this.weekService.unselectAll());
  }

  selectWeekend() {
    this.updateSelected(this.weekService.selectWeekend());
  }

  selectWorkweek() {
    this.updateSelected(this.weekService.selectWorkweek());
  }

  updateSelected(daysOfWeek: WeekDay[]) {
    this.selectedDays = new Array<boolean>(7);
    for (let i = 0; i < daysOfWeek.length; i++) {
      this.selectedDays[daysOfWeek[i]] = true;
    }
  }

  getRange(range: Date[]) {
    this.dateStart = range[0];
    this.dateEnd = range[1];
  }

  getTimeRange(range: string[]) {
    this.beginTime = range[0];
    this.endTime = range[1];
  }

  assignPlaylist(playlist: PlaylistWithWeeklySchedule) {
    playlist.weeklySchedule.daysOfWeek = new Array<number>();
    for (let i = 0; i < this.selectedDays.length; i++) {
      if (this.selectedDays[i]) {
        playlist.weeklySchedule.daysOfWeek.push(i);
      }
    }
    playlist.dateStart = this.timeConversionService.dateToDateWithoutTime(this.dateStart);
    playlist.dateEnd = this.timeConversionService.dateToDateWithoutTime(this.dateEnd);
    playlist.weeklySchedule.beginTime = this.timeConversionService.timeToDate(this.beginTime);
    playlist.weeklySchedule.endTime = this.timeConversionService.timeToDate(this.endTime);
    if (this.playlistExists) {
      this.ctx.editSchedulePlaylist(playlist);
    } else {
      this.ctx.assignPlaylistToSchedule(playlist);
    }
    this.cleanInputs();
  }

  getScheduleSpace() {
    if (this.ctx.editForm == true) {
      return (this.ctx.schedule.size / this.ctx.maxScheduleSize) * 100;
    }
    return 0;
  }

  checkDisabled(playlist: PlaylistWithWeeklySchedule) {
    if (playlist.mediaPlaylistId == undefined || !this.dateStart || !this.dateEnd) {
      return true;
    }
    return false;
  }

  editPlaylistTime(playlist: PlaylistWithWeeklySchedule) {
    this.ctx.editSchedulePlaylist(playlist);
  }

  cleanInputs() {
    this.selectedDays = new Array<boolean>(7);
    this.beginTime = "00:00";
    this.endTime = "23:59";
    this.dateStart = new Date();
    this.dateEnd = new Date();
  }

  setColor(index: number) {
    return this.colorService.getScheduleCalendarColor(index).primary;
  }

  checkIfEmptyTags(){
    return this.ctx.tagMultiCtrl.value.length == 0 && this.ctx.deviceTypeMultiCtrl.value.length == 0
    && this.ctx.locationMultiCtrl.value.length == 0;
  }
}
