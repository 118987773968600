import { DeviceType } from './device-type.enum';
import { Device } from './device.model';
import { Node } from '../categoryTree/node.model';
import { DeviceCategoryTreeValue } from '../categoryTree/device-category-tree-value.model';
import { LayoutType } from './layout-type.enum';
import { DeskService } from 'projects/qcloud-rest-client/src/lib/desk.service';

export class DeviceTable {
    public guid: string;
    public deviceName: string;
    public deviceType: DeviceType;
    public layoutType: LayoutType;
    public isAlive: boolean;
    public categoriesOrDesks: any[];
    public activated: boolean;
    public activationCode: string;

    constructor(device: Device, deskService: DeskService) {
        this.guid = device.guid;
        this.deviceName = device.deviceName;
        this.deviceType = device.deviceType;
        this.layoutType = device.layoutType;
        this.isAlive = null;
        if (device.deviceType == DeviceType.MainDisplay || device.deviceType == DeviceType.TicketDispenser || device.deviceType == DeviceType.CallingDisplay) {
            this.categoriesOrDesks = new Array();
            if (device.layoutType == LayoutType.MultideskDesk || device.layoutType == LayoutType.DesksBasic || device.deviceType == DeviceType.CallingDisplay) {
                device.availableDesks.forEach(desk => {
                    this.categoriesOrDesks.push(desk.deskNumber);
                });
            } else if(device.deviceCategoryTree != null) {
                this.convertTreeToCategoryList(device.deviceCategoryTree.root, this.categoriesOrDesks);
                this.categoriesOrDesks = this.categoriesOrDesks.filter((category, index, self) =>
                    index === self.findIndex((c) => (
                        c.id === category.id
                    ))
                )
            }
        }
        else if (device.deviceType == DeviceType.DeskDisplay || device.deviceType == DeviceType.CustomerSurveyDisplay) {
            this.categoriesOrDesks = new Array();
            deskService.getDesk(device.deskId).subscribe(
                desk => this.categoriesOrDesks.push(desk.deskNumber)
            );
        } else {
            this.categoriesOrDesks = new Array();
        }
        this.activated = device.activated;
        this.activationCode = device.activationCode;
    }

    convertTreeToCategoryList(root: Node<DeviceCategoryTreeValue>, categories: any[]) {
        let i = 0;
        root.nodes.forEach(element => {
            if (element.value.id == -1) {
                this.convertTreeToCategoryList(root.nodes[i], categories)
            } else {
                categories.push({
                    id: element.value.id, fullName: element.value.fullName,
                    categoryTag: element.value.shortName
                });
            }
            ++i;
        });
    }
}
