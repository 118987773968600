<div class="card shadow mb-4">
    <div class="card-header py-3">
        <div class="row">
            <h5 class="col-8 m-0 font-weight-bold text-primary">{{ 'thematic-tags' | translate }}</h5>
            <div class="text-right col-4">
                <button type="button" class="btn btn-primary btn-circle btn-sm" matTooltip="{{ 'create-tag' | translate }}"
                    tooltipClass="tooltip-primary" (click)="tagExists = false" data-toggle="modal" data-target="#updateTagModal">
                    <i class="fas fa-fw fa-plus"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{{ 'name' | translate }}</th>
                        <th>{{ 'options' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let tag of tags; let i = index">
                        <td>
                            {{ i + 1 }}
                        </td>
                        <td>
                            <span class="badge badge-primary">{{ tag.name }}</span>
                        </td>
                        <td>
                            <button class="btn btn-primary btn-circle btn-sm mx-1 mb-1" tooltipClass="tooltip-primary"
                                (click)="setDeviceTags(tag); openModal(assignDevicesModal)" matTooltip="{{ 'assign-devices' | translate }}">
                                <i class="fas fa-hashtag"></i>
                            </button>
                            <button class="btn btn-info btn-circle btn-sm mx-1 mb-1" (click)="currentTag = tag; tagExists = true"
                                matTooltip="{{ 'edit' | translate }}" tooltipClass="tooltip-info"
                                data-toggle="modal" data-target="#updateTagModal">
                                <i class="fas fa-fw fa-edit"></i>
                            </button>
                            <button class="btn btn-danger btn-circle btn-sm mx-1 mb-1" (click)="currentTag = tag"
                                matTooltip="{{ 'delete' | translate }}" tooltipClass="tooltip-danger"
                                data-toggle="modal" data-target="#deleteModal">
                                <i class="fas fa-fw fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<!-- Update Tag Modal-->
<div class="modal fade" id="updateTagModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 *ngIf="!tagExists" class="modal-title" id="exampleModalLabel">{{ 'create-tag' | translate}}</h5>
                <h5 *ngIf="tagExists" class="modal-title" id="exampleModalLabel">{{ 'edit-tag' | translate}}</h5>
                <button class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="input-group">{{ 'tag-name' | translate }}</div>
                <div class="input-group">
                    <input [(ngModel)]="currentTag.name" class="form-control">
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-danger" data-dismiss="modal">{{ 'cancel' | translate }}</button>
                <button *ngIf="!tagExists" class="btn btn-primary" data-dismiss="modal" (click)="createTag(currentTag)">
                    {{ 'create-tag' | translate}}
                </button>
                <button *ngIf="tagExists" class="btn btn-primary" data-dismiss="modal" (click)="editTag(currentTag)">
                    {{ 'edit-tag' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-hidden="true">
  <app-delete-modal [info]="'delete-confirmation'" [confirm]="'delete'" (confirmed)="onDeleteConfirm($event)"></app-delete-modal>
</div>

  <!-- Assing Devices Modal-->
<ng-template #assignDevicesModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{ 'assign-devices' | translate }}</h5>
        <button class="close" mat-dialog-close>
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body" mat-dialog-content>
        <app-assign-devices [deviceMultiCtrl]="deviceMultiCtrl" [devices]="devices"></app-assign-devices>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" (click)="assignDevices()" mat-dialog-close>
            {{ 'assign-devices' | translate}}
        </button>
    </div>
</ng-template>