import { Injectable } from "@angular/core";
import { DeviceLayout } from "projects/qcloud-models/device-layout";
import { DeviceConfiguration } from "projects/qcloud-models/device/device-configuration";
import { DeviceConfigurationService } from "./device-configuration.service";
import { RowStyle } from "projects/qcloud-models/device/row-style";

@Injectable({
    providedIn: 'root'
})
export class DeviceLayoutService {

    private deviceLayouts: DeviceLayout[];
    private rowStyle: RowStyle;
    constructor(private deviceConfigurationService: DeviceConfigurationService) {

    }


    public setDeviceLayouts(value: DeviceLayout[]) {
        this.deviceLayouts = value;
    }

    public getDeviceLayouts() {
        return this.deviceLayouts;
    }

    public getRadius() {
        return this.rowStyle.borderRadius + "%";
    }

    public getRowStyle() {
        return this.rowStyle;
    }
    public setRowStyle(rowStyle: RowStyle) {
        this.rowStyle = rowStyle;
    }
}