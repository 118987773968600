<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="m-0 font-weight-bold text-primary">{{ 'set-sender' | translate }}</h5>
    </div>
    <div class="card-body">

        <div class="form-group">
            {{ 'profile' | translate }} ({{ 'max-11-signs' | translate }})
            <div class="item-group">
                <input class="form-control" name="sender" #sender="ngModel" [(ngModel)]="smsConfig.sender"
                    maxlength="11">
            </div>
        </div>

        <div class="form-group">
            <button class="btn btn-primary m-1" type="send" [disabled]="formSubmitted" (click)="onSubmit()">
                <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                    aria-hidden="true"></span>{{ 'send' | translate }}
            </button>
        </div>
    </div>
</div>
