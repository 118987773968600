export class DeviceCategoryTreeValue {
    id: number;
    fullName: string;
    shortName: string;
    groupTranslations: any;
    isDisplayGroup: boolean;
    displayGroupId: number;
    constructor(id: number, fullName: string, shortName?: string) {

        this.id = id;
        this.fullName = fullName;
        this.shortName = shortName;
        this.groupTranslations = null;
    }
}