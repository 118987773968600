<div *ngIf="locationService.getLocationConstraints().isSmsEnabled" class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="m-0 font-weight-bold text-primary">{{ 'sms-notifications' | translate }}</h5>
    </div>
    <div *ngIf="smsConfig" class="card-body">
        <div class="form-group">
            <div class="input-group">
                <div class="font-weight-bold">{{ 'reservation-sms-text' | translate }}</div>
            </div>
            <div class="input-group mb-2">
                <textarea name="sendSmsOnReservation" #sendSmsOnReservation="ngModel"
                    [(ngModel)]="smsConfig.sendSmsOnReservation" class="form-control">
                </textarea>
            </div>
            <span [ngClass]="{'text-danger': sendSmsOnReservation.value?.length > 160}" class="mr-2">
                {{ sendSmsOnReservation.value?.length }} / 160</span>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnReservation(SmsTags.LocationName)">
                {{ 'location-name' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnReservation(SmsTags.LocationAddress)">
                {{ 'location-address' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnReservation(SmsTags.Category)">
                {{ 'category-name' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnReservation(SmsTags.Date)">
                {{ 'reservation-date' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnReservation(SmsTags.Code)">
                {{ 'confirm-reserverion-code' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mb-2" (click)="updateSendSmsOnReservation(SmsTags.PhoneNumber)">
                {{ 'location-phone-number' | translate }}
            </button>
        </div>

        <!--<div class="form-group">
            <div class="input-group">
                <div class="font-weight-bold">{{ 'edit-reservation-sms-text' | translate }}</div>
            </div>
            <div class="input-group mb-2">
                <textarea name="sendSmsOnEditReservation" #sendSmsOnEditReservation="ngModel"
                    [(ngModel)]="smsConfig.sendSmsOnEditReservation" class="form-control">
                </textarea>
            </div>
            <span [ngClass]="{'text-danger': sendSmsOnEditReservation.value?.length > 160}" class="mr-2">
                {{ sendSmsOnEditReservation.value?.length }} / 160</span>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnEditReservation(SmsTags.LocationName)">
                {{ 'location-name' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnEditReservation(SmsTags.LocationAddress)">
                {{ 'location-address' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnEditReservation(SmsTags.Category)">
                {{ 'category-name' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnEditReservation(SmsTags.Date)">
                {{ 'reservation-date' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnEditReservation(SmsTags.Code)">
                {{ 'confirm-reserverion-code' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mb-2" (click)="updateSendSmsOnEditReservation(SmsTags.PhoneNumber)">
                {{ 'location-phone-number' | translate }}
            </button>
        </div>-->

        <div class="form-group">
            <div class="input-group">
                <div class="font-weight-bold">{{ 'remind-reservation-sms-text' | translate }}</div>
            </div>
            <div class="input-group mb-2">
                <textarea name="sendSmsOnRemindReservation" #sendSmsOnRemindReservation="ngModel"
                    [(ngModel)]="smsConfig.sendSmsOnRemindReservation" class="form-control">
                </textarea>
            </div>
            <span [ngClass]="{'text-danger': sendSmsOnRemindReservation.value?.length > 160}" class="mr-2">
                {{ sendSmsOnRemindReservation.value?.length }} / 160</span>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnRemindReservation(SmsTags.LocationName)">
                {{ 'location-name' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnRemindReservation(SmsTags.LocationAddress)">
                {{ 'location-address' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnRemindReservation(SmsTags.Category)">
                {{ 'category-name' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnRemindReservation(SmsTags.Date)">
                {{ 'reservation-date' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnRemindReservation(SmsTags.Code)">
                {{ 'confirm-reserverion-code' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mb-2" (click)="updateSendSmsOnRemindReservation(SmsTags.PhoneNumber)">
                {{ 'location-phone-number' | translate }}
            </button>
        </div>

        <div class="form-group">
            <div class="input-group">
                <div class="font-weight-bold">{{ 'delete-reservation-sms-text' | translate }}</div>
            </div>
            <div class="input-group mb-2">
                <textarea name="sendSmsOnDeleteReservation" #sendSmsOnDeleteReservation="ngModel"
                    [(ngModel)]="smsConfig.sendSmsOnDeleteReservation" class="form-control">
                </textarea>
            </div>
            <span [ngClass]="{'text-danger': sendSmsOnDeleteReservation.value?.length > 160}" class="mr-2">
                {{ sendSmsOnDeleteReservation.value?.length }} / 160</span>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnDeleteReservation(SmsTags.LocationName)">
                {{ 'location-name' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnDeleteReservation(SmsTags.LocationAddress)">
                {{ 'location-address' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnDeleteReservation(SmsTags.Category)">
                {{ 'category-name' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnDeleteReservation(SmsTags.Date)">
                {{ 'reservation-date' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnDeleteReservation(SmsTags.Code)">
                {{ 'confirm-reserverion-code' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mb-2" (click)="updateSendSmsOnDeleteReservation(SmsTags.PhoneNumber)">
                {{ 'location-phone-number' | translate }}
            </button>
        </div>

        <div class="form-group">
            <div class="input-group">
                <div class="font-weight-bold">{{ 'print-ticket-sms-text' | translate }}</div>
            </div>
            <div class="input-group mb-2">
                <textarea name="sendSmsOnPrint" #sendSmsOnPrint="ngModel"
                    [(ngModel)]="smsConfig.sendSmsOnPrint" class="form-control">
                </textarea>
            </div>
            <span [ngClass]="{'text-danger': sendSmsOnPrint.value?.length > 160}" class="mr-2">
                {{ sendSmsOnPrint.value?.length }} / 160</span>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnPrint(SmsTags.LocationName)">
                {{ 'location-name' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnPrint(SmsTags.LocationAddress)">
                {{ 'location-address' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnPrint(SmsTags.Category)">
                {{ 'category-name' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnPrint(SmsTags.PhoneNumber)">
                {{ 'location-phone-number' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnPrint(SmsTags.QueueState)">
                {{ 'queue-state' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnPrint(SmsTags.TicketCaption)">
                {{ 'ticket-caption' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnPrint(SmsTags.TicketPrintTime)">
                {{ 'ticket-print-time' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mb-2" (click)="updateSendSmsOnPrint(SmsTags.PredictedWaitingTime)">
                {{ 'predicted-waiting-time' | translate }}
            </button>
        </div>

        <div class="form-group">
            <div class="input-group">
                <div class="font-weight-bold">{{ 'before-call-sms-text' | translate }}</div>
            </div>
            <div class="input-group mb-2">
                <textarea name="sendSmsOnBeforeCall" #sendSmsOnBeforeCall="ngModel"
                    [(ngModel)]="smsConfig.sendSmsOnBeforeCall" class="form-control">
                </textarea>
            </div>
            <span [ngClass]="{'text-danger': sendSmsOnBeforeCall.value?.length > 160}" class="mr-2">
                {{ sendSmsOnBeforeCall.value?.length }} / 160</span>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnBeforeCall(SmsTags.LocationName)">
                {{ 'location-name' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnBeforeCall(SmsTags.LocationAddress)">
                {{ 'location-address' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnBeforeCall(SmsTags.Category)">
                {{ 'category-name' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnBeforeCall(SmsTags.PhoneNumber)">
                {{ 'location-phone-number' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnBeforeCall(SmsTags.QueueState)">
                {{ 'queue-state' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnBeforeCall(SmsTags.TicketCaption)">
                {{ 'ticket-caption' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnBeforeCall(SmsTags.TicketPrintTime)">
                {{ 'ticket-print-time' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mb-2" (click)="updateSendSmsOnBeforeCall(SmsTags.PredictedWaitingTime)">
                {{ 'predicted-waiting-time' | translate }}
            </button>
        </div>

        <div class="form-group">
            {{ 'position-of-ticket-in-queue-for-sending-sms' | translate }}
            <div class="item-group">
                <input type="number" class="form-control" name="ticketsBeforeSendSmsOnBeforeCall"
                    #ticketsBeforeSendSmsOnBeforeCall="ngModel"
                    [(ngModel)]="smsConfig.ticketsBeforeSendSmsOnBeforeCall">
            </div>
        </div>

        <div class="form-group">
            <div class="input-group">
                <div class="font-weight-bold">{{ 'on-call-sms-text' | translate }}</div>
            </div>
            <div class="input-group mb-2">
                <textarea name="sendSmsOnTicketCall" #sendSmsOnTicketCall="ngModel"
                    [(ngModel)]="smsConfig.sendSmsOnTicketCall" class="form-control">
                </textarea>
            </div>
            <span [ngClass]="{'text-danger': sendSmsOnTicketCall.value?.length > 160}" class="mr-2">
                {{ sendSmsOnTicketCall.value?.length }} / 160</span>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnCall(SmsTags.LocationName)">
                {{ 'location-name' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnCall(SmsTags.LocationAddress)">
                {{ 'location-address' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnCall(SmsTags.Category)">
                {{ 'category-name' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnCall(SmsTags.PhoneNumber)">
                {{ 'location-phone-number' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnCall(SmsTags.TicketCaption)">
                {{ 'ticket-caption' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mr-2 mb-2" (click)="updateSendSmsOnCall(SmsTags.TicketPrintTime)">
                {{ 'ticket-print-time' | translate }}
            </button>
            <button class="btn btn-primary btn-sm mb-2" (click)="updateSendSmsOnCall(SmsTags.DeskName)">
                {{ 'desk-name' | translate }}
            </button>
        </div>

        <div class="form-group">
            <button class="btn btn-primary m-1" type="submit" [disabled]="formSubmitted" (click)="onSubmit()">
                <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                    aria-hidden="true"></span>{{ 'save' | translate }}
            </button>
        </div>
    </div>
</div>

<app-send-test-sms *ngIf="locationService.getLocationConstraints().isSmsEnabled && authService.isSuperAdmin()">
</app-send-test-sms>

<app-configure-sms-sender *ngIf="locationService.getLocationConstraints().isSmsEnabled && authService.isSuperAdmin()">
</app-configure-sms-sender>

<app-licence-info *ngIf="!locationService.getLocationConstraints().isSmsEnabled"
    [title]="'sms-notifications'">
</app-licence-info>
