import { Component, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Category } from "../../../../../../qcloud-models/category/category.model";
import { QcloudRole } from "../../../../../../qcloud-models/qcloud-role/qcloud-role";
import { AuthService } from "../../../../../../qcloud-rest-client/src/lib/auth/auth.service";
import { QcloudRoleService } from "../../../../../../qcloud-rest-client/src/lib/role/qcloud-role.service";
import { NormalizeCharactersPipe } from "../../../shared/normalize-characters.pipe";
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { LocationData } from "projects/qcloud-models/location/location.model";

@Component({
  selector: 'app-qcloud-role',
  templateUrl: './qcloud-role.component.html',
  styleUrls: ['./qcloud-role.component.css'],
  providers: [NormalizeCharactersPipe]
})
export class QcloudRoleComponent implements OnInit {
  isEditForm: boolean;
  formSubmitted: boolean = false;
  serveETickets: boolean;
  qcloudRole: QcloudRole;
  protected initialCategories: Category[];
  public categoryMultiCtrl: UntypedFormControl;
  categories: Category[];
  servedCategories: string = 'served-categories';
  noSelectedCategories = 'no-selected-categories';
  locations: LocationData[];
  protected initialLoco: LocationData[];
  public locationMultiCtrl: UntypedFormControl;
  userLocations: string = 'user-locations';
  isLockedForEditing: boolean = false;
  enabledByLocationIds: number[];

  constructor(public authService: AuthService, private translate: TranslateService, private toastr: ToastrService,
    private route: ActivatedRoute, private router: Router, public locationService: LocationService,
    public qcloudRoleService: QcloudRoleService, private categoryService: CategoryService) { }

  ngOnInit(): void {
    let qcloudRoleId: number;
    this.serveETickets = this.authService.getServeETicket() == "true";
    this.route.params.subscribe(params => {
      qcloudRoleId = params['id']
      if (qcloudRoleId) {
        this.isEditForm = true;
        this.getQcloudRole(qcloudRoleId);
      } else {
        this.isEditForm = false;
        this.isLockedForEditing = false;
        this.initializeLdapRole();
      }
    });
  }

  initializeLdapRole() {
    this.qcloudRole = new QcloudRole();
    this.qcloudRole.locationIds = [];
    this.qcloudRole.systemId = +this.authService.getSystemId();
    this.qcloudRole.categories = [];

    this.getCategories();
    this.getLocations();
  }

  getCategories() {
    this.categoryService.getCategories((data) => {
      this.categories = data;
      this.initialCategories = new Array<Category>();
      for (let j = 0; j < this.qcloudRole.categories.length; j++) {
        for (let i = 0; i < this.categories.length; i++) {
          if (this.categories[i].id == this.qcloudRole.categories[j].id) {
            this.initialCategories.push(this.categories[i]);
          }
        }
      }
      this.categoryMultiCtrl = new UntypedFormControl(this.initialCategories);
    },
      () => { }
    );
  }

  getQcloudRole(qcloudRoleId: number) {
    this.qcloudRoleService.getQcloudRole(qcloudRoleId).subscribe(
      role => {
        this.qcloudRole = role;
        this.qcloudRole.rolePermissionLevel = Number(this.qcloudRole.rolePermissionLevel);
        this.getCategories();
        this.getLocations();
      },
      error => {
        this.router.navigate(['/qcloudRoles']);
      }
    );
  }

  getLocations() {
    this.locationService.getAll().subscribe(
      locations => {
        this.locations = locations;
        this.assignDefaultLocation();
      }
    );
  }

  assignDefaultLocation() {
    var mainRoleLocationIds = this.authService.getLocationId();
    if (this.locations.length > 1) {
      this.initialLoco = new Array<LocationData>();
      for (let j = 0; j < this.qcloudRole.locationIds.length; j++) {
        for (let i = 0; i < this.locations.length; i++) {
          if (mainRoleLocationIds[j] == this.locations[i].id) {
            this.initialLoco.push(this.locations[i]);
          }
        }
      }
      this.locationMultiCtrl = new UntypedFormControl(this.initialLoco);
    }
    else {
      this.qcloudRole.locationIds.push(this.locations[0].id);
    }
  }

  onSubmit(categories: Category[]) {
    this.qcloudRole.categories = categories;
    if (this.locationMultiCtrl) {
      var roleLIds: number[] = [];
      roleLIds.push(this.authService.getLocationId());
      this.qcloudRole.locationIds = roleLIds;
    }
    if (this.isEditForm) {
      this.editLdapRole();
    } else {
      this.addQcloudRole();
    }
  }
  addQcloudRole() {
    this.qcloudRoleService.addQcloudRole(this.qcloudRole).subscribe({
      next: () => {
        this.formSubmitted = true;
        this.router.navigate(['/qcloudRoles']);
      },
      error: err => {
        var errorMessage = "qcloud.role-add-error"
        this.formSubmitted = false;
        this.translate.get(errorMessage).subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    }
    );

  }

  editLdapRole() {
    this.qcloudRoleService.updateQcloudRole(this.qcloudRole).subscribe({
      next: () => {
        this.formSubmitted = true;
        this.router.navigate(['/qcloudRoles']);
      },
      error: err => {
        var errorMessage = "qcloud.role-update-error"
        this.formSubmitted = false;
        this.translate.get(errorMessage).subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    });
  }

  newBasedOnExisting() {
    this.isEditForm = false;
  }

  toastrShowInfo(msg: string) {
    this.toastr.info(msg);
  }
  isLocationEnable(locationId: number) {
    var res = this.enabledByLocationIds.some(l => l === locationId);
    return res;
  }
}