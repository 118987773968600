import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LocationData } from 'projects/qcloud-models/location/location.model';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { ETicketService } from 'projects/qcloud-rest-client/src/lib/eticket.service';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { ConfigurationService } from 'projects/qcloud-web/src/app/configuration.service';
import { jsPDF } from 'jspdf';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.css']
})
export class QrCodeComponent implements OnInit {

  areETicketsEnabled: boolean;
  elementType: string;
  correctionLevel: string;
  code: string;
  locationData: LocationData;
  @ViewChild('pdf', {static: false}) pdf: ElementRef;

  constructor(private eticketService: ETicketService, private configurationService: ConfigurationService,
    private authService: AuthService, private locationService: LocationService) { }

  ngOnInit(): void {
    this.locationService.getLocationData(this.locationService.locationData.id).subscribe(
      res => this.locationData = res,
      () => { }
    );

    this.areETicketsEnabled = this.locationService.getLocationConstraints().areETicketsEnabled;
    if (this.areETicketsEnabled) {
      this.eticketService.getCode().subscribe(
        (data) => this.createCode(data),
        () => { }
      );
    }

    this.correctionLevel = 'H';
    this.elementType = "img";
  }

  generateNewCode() {
    this.eticketService.generateNewCode().subscribe(
      (data) => this.createCode(data),
      () => { }
    );
  }

  createCode(data: any) {
    let appBase = this.configurationService.appConfig.appBase;
    let systemId = this.authService.getSystemId();
    let locationId = this.authService.getLocationId();
    this.code = `${appBase}wpa?locationCode=${data.code}&systemId=${systemId}&locationId=${locationId}`;
  }

  downloadAsPDF() {
    var doc = new jsPDF();
    const pdfToGenerate = this.pdf.nativeElement;
    doc.html(pdfToGenerate.innerHTML, {
      callback: function (doc) {
        doc.save('KodQR.pdf');
      }
    });
  }

  editLocation() {
    this.locationService.editLocationData(this.locationData).subscribe(
      res => {
        this.locationService.locationData = res;
      },
      err => { }
    );
  }
}
