import { Component, OnInit } from '@angular/core';
import { QCloudRestClientService } from 'projects/qcloud-rest-client/src/public-api';
import { TokenResponse } from 'projects/qcloud-models/user/token-response';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(public qcloudRestClientService: QCloudRestClientService, private router: Router,
    private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.resetForm();
  }

  resetForm(form?:NgForm) {
    if(form != null) {
      form.resetForm();
    }
    this.qcloudRestClientService.loginForm = {
      username : '',
      password : ''
    }
  }

  onSubmit() {
    this.qcloudRestClientService.getAuthTokenForUser(this.callback, this.onError);
  }

  private callback = (data: TokenResponse) => {
    this.router.navigateByUrl('qcloud/welcome');
  }

  private onError = () => {
    this.translate.get('log-in-error').subscribe((error: string) => {
      this.translate.get('invalid-username-or-password').subscribe((res: string) => {
        this.toastr.error(res, error);
      });
    });
  }

}
