import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ActivityTime } from 'projects/qcloud-models/activity-time';
import { Category } from 'projects/qcloud-models/category/category.model';
import { DeviceCategoryTree } from 'projects/qcloud-models/categoryTree/device-category-tree.model';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { CategoryDisplayNameType } from 'projects/qcloud-models/device/category-display-name-type.enum';
import { DeviceDeskIcon } from 'projects/qcloud-models/device/device-desk-icon.model';
import { DeviceType } from 'projects/qcloud-models/device/device-type.enum';
import { Device } from 'projects/qcloud-models/device/device.model';
import { LayoutType } from 'projects/qcloud-models/device/layout-type.enum';
import { TicketLayoutType } from 'projects/qcloud-models/device/ticket-layout-type.enum';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { DeskService } from 'projects/qcloud-rest-client/src/lib/desk.service';
import { DeviceService } from 'projects/qcloud-rest-client/src/lib/device.service';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { TimeConversionService } from '../../../shared/time-conversion.service';
import { DisplayCategoryGroup } from 'projects/qcloud-models/category/display-category-group';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.css']
})
export class DeviceComponent implements OnInit {

  device: Device;
  isEditForm: boolean;
  advancedMode: boolean = false;
  formSubmitted: boolean = false;
  nightWork: Array<boolean> = new Array(7);
  activationCode: string;
  deviceTitle: boolean = false;

  DeviceType = DeviceType;
  CategoryDisplayNameType = CategoryDisplayNameType;
  TicketLayoutType = TicketLayoutType;
  LayoutType = LayoutType;

  categories: Category[];
  displayGroups: DisplayCategoryGroup[];
  protected initialCategories: Category[];
  public categoryMultiCtrl: UntypedFormControl;

  desks: Desk[];

  constructor(private deviceService: DeviceService, private categoryService: CategoryService,
    private deskService: DeskService, public locationService: LocationService, private authService: AuthService,
    private route: ActivatedRoute, private timeConverter: TimeConversionService, private toastr: ToastrService,
    private translate: TranslateService, private router: Router) { }

  ngOnInit(): void {
    let deviceGuid: string;
    this.route.params.subscribe(params => {
      deviceGuid = params['guid']
    });
    if (deviceGuid) {
      this.isEditForm = true;
      this.getDevice(deviceGuid);
    } else {
      this.isEditForm = false;
      this.initializeDevice();
    }

    this.getDesks();
  }

  getDevice(deviceGuid: string) {
    this.deviceService.getDevice(deviceGuid).subscribe(
      device => {
        this.device = device;
        if (this.device.title != null) {
          this.deviceTitle = true;
        }
        for (let i = 0; i < this.device.deviceActivityTimes.length; i++) {

          var time = device.deviceActivityTimes[i];
          time.blockingTime = this.timeConverter.dateToTime(time.blockingTime);
          time.unblockingTime = this.timeConverter.dateToTime(time.unblockingTime);

          if (this.timeConverter.timeToMinutes(time.blockingTime)
            < this.timeConverter.timeToMinutes(time.unblockingTime)) {

            var tmp = time.blockingTime;
            time.blockingTime = time.unblockingTime;
            time.unblockingTime = tmp;
            this.nightWork[i] = true;
          }
        }

        this.getCategories();
      },
      error => {
        this.router.navigate(['/devices']);
      }
    );
  }

  initializeDevice() {
    this.device = new Device();
    this.device.locationId = +this.authService.getLocationId();
    this.device.systemId = +this.authService.getSystemId();
    this.device.categoryDisplayNameType = CategoryDisplayNameType.FullName;
    this.device.availableCategories = new Array();
    this.device.deviceActivityTimes = new Array(7);
    for (let i = 0; i < this.device.deviceActivityTimes.length; i++) {
      this.device.deviceActivityTimes[i] = new ActivityTime();
      this.device.deviceActivityTimes[i].unblockingTime = "00:00";
      this.device.deviceActivityTimes[i].blockingTime = "23:59";
      this.device.deviceActivityTimes[i].is24Hours = false;
      this.device.deviceActivityTimes[i].isButtonAlwaysVisible = false;
      this.device.deviceActivityTimes[i].isActive = true;
      this.device.deviceActivityTimes[i].day = i;
    }
    this.getCategories();
  }

  getCategories() {
    this.categoryService.getCategories((data) => {
      this.categories = data;
      this.initialCategories = new Array<Category>();
      for (let j = 0; j < this.device.availableCategories.length; j++) {
        for (let i = 0; i < this.categories.length; i++) {
          if (this.categories[i].id == this.device.availableCategories[j].id) {
            this.initialCategories.push(this.categories[i]);
          }
        }
      }
      this.categoryMultiCtrl = new UntypedFormControl(this.initialCategories);
    },
      () => { }
    );
    this.categoryService.getGroupDisplays().subscribe((data) => {
      this.displayGroups = data;
    },
      () => { }
    );
  }

  getDesks() {
    this.deskService.getDesks((data) => {
      this.desks = data;
    },
      () => { }
    );
  }

  getDeskIcons(deskIcons: DeviceDeskIcon[]) {
    this.device.deviceDeskIcons = deskIcons;
  }

  onSubmit(categories: Category[]) {
    this.formSubmitted = true;
    if (categories) {
      this.device.availableCategories = categories;
    }
    this.adjustActivityTime();
    this.deleteUnusedCategoriesAndDesks();

    if (this.isEditForm) {
      this.editDevice();
    } else {
      this.addDevice();
    }
  }

  editDevice() {
    this.deviceService.editDevice(this.device).subscribe(
      res => {
        this.translate.get('device-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
        this.router.navigate(['/devices']);
      },
      err => {
        this.translate.get('device-error').subscribe((res: string) => {
          let error = res;
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
    this.formSubmitted = false;
  }

  addDevice() {
    this.device.id = 0;
    this.device.activated = false;
    this.deviceService.addDevice(this.device).subscribe(
      device => {
        this.translate.get('device-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
        this.activationCode = device.activationCode;
      },
      err => {
        this.translate.get('device-error').subscribe((res: string) => {
          let error = res;
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
    this.formSubmitted = false;
  }

  deleteUnusedCategoriesAndDesks() {
    if (this.device.deviceType == DeviceType.MainDisplay) {
      if (this.device.layoutType == LayoutType.MultideskDesk || this.device.layoutType == LayoutType.DesksBasic) {
        this.device.availableCategories = [];
        this.device.deviceCategoryTree = new DeviceCategoryTree();
      }
      else {
        this.device.availableDesks = [];
      }
    }
  }

  adjustActivityTime() {
    this.device.deviceActivityTimes.forEach(element => {
      element.blockingTime = this.timeConverter.timeToDate(element.blockingTime);
      element.unblockingTime = this.timeConverter.timeToDate(element.unblockingTime);
    });
  }

}
