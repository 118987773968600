import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { Category } from 'projects/qcloud-models/category/category.model';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { TicketsStatistics } from 'projects/qcloud-models/statistics/ticket-statistics';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { DeskService } from 'projects/qcloud-rest-client/src/lib/desk.service';
import { StatisticsService } from 'projects/qcloud-rest-client/src/lib/statistics.service';
import {enableChartCenterText} from 'projects/qcloud-web/src/app/page/statistics/utils/chartUtils';

@Component({
  selector: 'app-ticket-statistics',
  templateUrl: './ticket-statistics.component.html',
  styleUrls: ['./ticket-statistics.component.css']
})
export class TicketStatisticsComponent implements OnInit {

  categories: Category[];
  desks: Desk[];
  ticketStats: TicketsStatistics;

  constructor(private categoryService: CategoryService, private deskService: DeskService,
    private statisticsServcie: StatisticsService) { }

  ngOnInit(): void {

    this.statisticsServcie.getTodayStats().subscribe(
      res => {
        this.ticketStats = res;
      }
    )

    this.categoryService.getCategories(
      (data)=>{
        this.categories = data;
      },
      ()=>{ }
    );

    this.deskService.getDesks(
      (data)=>{
        this.desks = data;
      },
      ()=>{ }
    );

    enableChartCenterText();
  }

}
