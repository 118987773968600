import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SmsConfiguration } from 'projects/qcloud-models/sms/sms-configuration';
import { SmsProviderConfiguration } from 'projects/qcloud-models/sms/sms-provider-configuration';
import { SmsTemplates } from 'projects/qcloud-models/sms/sms-templates';
import { TextMessage } from 'projects/qcloud-models/sms/text-message';
import { Observable } from 'rxjs';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SmsConfigService {

  appBase: string;

  constructor(private http: HttpClient, private authService: AuthService) { }

  public setAppBase(appBase: string) {
    this.appBase = appBase;
  }

  getSmsConfig(): Observable<SmsTemplates> {
    return this.http.get<SmsTemplates>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/sms`);
  }

  setSmsConfig(smsConfig: SmsTemplates) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/sms`, smsConfig);
  }

  getSmsSender(): Observable<string> {
    return this.http.get<string>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/sms/sender`);
  }

  setSmsSender(sender: SmsConfiguration) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/sms/sender`, sender);
  }

  sendTestSms(testSms: TextMessage) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/sms/test`, testSms);
  }

  setSmsProvider(config: SmsProviderConfiguration) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/sms/provider`, config);
  }

  getSmsProvider(): Observable<SmsProviderConfiguration> {
    return this.http.get<SmsProviderConfiguration>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/sms/provider`);
  }
}
