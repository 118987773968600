import { Component, OnInit } from '@angular/core';
import { Playlist } from 'projects/qcloud-models/multimedia/playlist.model';
import { MultimediaService } from 'projects/qcloud-rest-client/src/lib/multimedia.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-playlists',
  templateUrl: './playlists.component.html',
  styleUrls: ['./playlists.component.css']
})
export class PlaylistsComponent implements OnInit {

  public playlists: Playlist[];
  selectedPlaylistId: number;

  deleteLink = "/multimedia/";

  constructor(private multimediaService: MultimediaService, private translate: TranslateService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getPlaylists();
  }

  getPlaylists() {
    this.multimediaService.getPlaylists().subscribe((data) => {
      this.playlists = data;
    });
  }

  deleteSchedule(playlistId: number) {
    this.multimediaService.deletePlaylist(playlistId).subscribe(
      res => {
        this.getPlaylists();
      },
      err => {
        this.translate.get('delete-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  onDeleteConfirm(confirmed: boolean) {
    if (confirmed) {
      this.deleteSchedule(this.selectedPlaylistId);
    }
  }

}
