<div class="card-header py-3">
    <h6 class="m-0 font-weight-bold text-primary">{{ 'average-category-service-time' | translate}}
        <span class="float-right text-secondary">{{ 'average' | translate }}: 
            <span class="badge badge-success lead">{{ averageServiceTime | date: 'mm:ss' }}</span>
        </span>
    </h6>
</div>
<div class="card-body">
    <div *ngFor="let category of categories; let i = index">
        <h4 class="small font-weight-bold">{{ category.fullName }}
            <span class="float-right">{{ averageCategoryServiceTime[category.id] | date: 'mm:ss' }}</span>
        </h4>
        <div class="progress mb-4">
            <div class="progress-bar bg-success" role="progressbar" [style.width]="(averageCategoryServiceTime[category.id]/maxServiceTime)*100 + '%'"
                aria-valuenow="averageCategoryServiceTime[category.id]" aria-valuemin="0" aria-valuemax="maxServiceTime">
            </div>
            <app-statistics-average-bar [moveRight]="(averageServiceTime/maxServiceTime)*100 + '%'">
                </app-statistics-average-bar>
        </div>
    </div>
</div>