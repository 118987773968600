import { ElementRef, Injectable } from "@angular/core";



enum CssCustomClass {
  HideElement = 'd-none',
  ElementText ='element-text',
  BtnBlockLevel = 'btn-block',
  SmallDivQueueStatePosition = 'small-div-display',
  MarginRightTwo = 'mr-2',
  SmallButton = 'small-button',
  SmallButtonDevice = 'small-button-device',
  ExpandMenuButtonSize = "expand-menu-button-size",
  ExpandButtonInMenuSize = "expand-button-in-menu-size",
  InheritFontSize = 'inherit-font-size',
  DeviceSpanQueueStateSize = 'device-small-size-queue-state',
  DeviceSmallFontSizeButton = 'device-small-font-size-button'
}



export enum ButtonComponentName {
  CallTicketButtonComponent = 'CallTicketButtonComponent',
  CallAgainTicketButtonComponent = 'CallAgainTicketButtonComponent',
  AcceptTicketButtonComponent = 'AcceptTicketButtonComponent',
  PauseTicketButtonComponent = 'PauseTicketButtonComponent',
  RedirectTicketButtonComponent = 'RedirectTicketButtonComponent',
  CancelTicketButtonComponent = 'CancelTicketButtonComponent',
  ExpandMenuButtonComponent = 'ExpandMenuButtonComponent'
}
export enum AdditionalButtonComponentName {
  CancelledTicketsButton = 'CancelledTicketsButton',
  PausedTicketsButton = 'PausedTicketsButton',
  CallTicketManualButton = 'CallTicketManualButton',
  BlockedCategoriesButton = 'BlockedCategoriesButton',
  ServedCategoriesButton = 'ServedCategoriesButton',
  GenerateTicketButton = 'GenerateTicketButton',
  ShowTicketManualButton = 'ShowTicketManualButton'
}


@Injectable()
export class SmallLayoutConfigurationService {
  ButtonComponentNameEnum = ButtonComponentName;
  AdditionalButtonComponentName = AdditionalButtonComponentName;

  constructor() { }
  adjustScreen(): void {
  }


  public minimalizeButtonsMapForDevice<E>(buttons: Map<E, ElementRef>) {
    this.hideTextInButton(buttons);

    var addingClassList = [];
    var removingClassList = [];

    addingClassList.push(CssCustomClass.SmallButtonDevice);
    return this.manageClassListMap(buttons, addingClassList, removingClassList);
  }



  public minimalizeButtonsMap<E>(buttons: Map<E, ElementRef>) {
    this.hideTextInButton(buttons);

    var addingClassList = [];
    var removingClassList = [];

    addingClassList.push(CssCustomClass.SmallButton);
    return this.manageClassListMap(buttons, addingClassList, removingClassList);
  }

  private hideTextInButton<E>(buttons: Map<E, ElementRef>): void {
    var addingClassList = [];
    var removingClassList = [];
    addingClassList.push(CssCustomClass.HideElement);
    buttons.forEach((element: ElementRef, key: E) => {
      this.manageClassList(element.nativeElement.childNodes, addingClassList, removingClassList, CssCustomClass.ElementText);
    });
  }

  private manageClassList(elemets: NodeListOf<Element>, addingClasses: string[], removingClasses: string[], filter: string = ''): void {
    if (removingClasses.length > 0) {
      this.removeClassList(elemets, removingClasses, filter);
    }

    if (addingClasses.length > 0) {
      this.appendClassList(elemets, addingClasses, filter);
    }
  }
  private manageClassListMap<E>(elemets: Map<E, ElementRef>, addingClasses: string[], removingClasses: string[]){
    if (removingClasses.length > 0) {
      elemets = this.removeClassListMap(elemets, removingClasses);
    }

    if (addingClasses.length > 0) {
      elemets = this.appendClassListMap(elemets, addingClasses);
    }
    return elemets;
  }

  private appendClassListMap<E>(elemets: Map<E, ElementRef>, classes: string[]) : Map<E, ElementRef>{
    elemets.forEach((element: ElementRef, key: E) => {
      element.nativeElement.classList.add(...classes);
    });
    return elemets;
  }

  private removeClassListMap<E>(elemets: Map<E, ElementRef>, classes: string[]) : Map<E, ElementRef>{
      elemets.forEach((element: ElementRef, key: E) => {
      element.nativeElement.classList.remove(...classes);
    });
    return elemets;
  }

  private removeClassList(elemets: NodeListOf<Element>, classes: string[], filter: string = '') {
    elemets.forEach(element => {
        if(typeof filter=='undefined' || !filter || element.classList.contains(filter)) {
          element.classList.remove(...classes);
        }
      });
  }

  private appendClassList(elemets: NodeListOf<Element>, classes: string[], filter: string = '') {
    elemets.forEach(element => {
      if(typeof filter=='undefined' || !filter || element.classList.contains(filter)) {
         element.classList.add(...classes);
      }
    });
  }

  // public adjustExpandedMenuButton(button: ElementRef) {
  //   button.nativeElement.classList.add(CssCustomClass.DeviceSmallFontSizeButton);
  // }

  public positionIconQueueState(spanQueueStates: ElementRef[]) : void {
    var addingClassList = [];
    addingClassList.push(CssCustomClass.MarginRightTwo);
    this.appendClassListRef(spanQueueStates, addingClassList);
  }


  public positionDeviceSpanQueueStateForDevice(spanQueueStates: ElementRef[]) : void {
    var addingClassList = [];
    addingClassList.push(CssCustomClass.DeviceSpanQueueStateSize);
    this.appendClassListRef(spanQueueStates, addingClassList);
  }



  public positionDeviceSpanQueueCategoryStatesForDevice(spanQueueStates: ElementRef[]) : void {
    var addingClassList = [];
    addingClassList.push(CssCustomClass.DeviceSpanQueueStateSize);
    this.appendClassListRef(spanQueueStates, addingClassList);
  }


  private appendClassListRef(elemets: ElementRef[], classes: string[]) : ElementRef[]{
    elemets.forEach(element => {
      element.nativeElement.classList.add(...classes);
    });
    return elemets;
  }

  public minimalizeAdditionalButtonsMap<E>(buttons: Map<E, ElementRef>) {
    var addingClassList = [];
    var removingClassList = [];

    addingClassList.push(CssCustomClass.ExpandButtonInMenuSize);
    addingClassList.push(CssCustomClass.InheritFontSize);
    return this.manageClassListMap(buttons, addingClassList, removingClassList);
  }

  minimalizeAdditionalButtonsMapSectorTwo<E>(buttons: Map<E, ElementRef>) {
    var addingClassList = [];
    var removingClassList = [];

    addingClassList.push(CssCustomClass.ExpandButtonInMenuSize);

    return this.manageClassListMap(buttons, addingClassList, removingClassList);
  }
}
