import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { QcloudConsoleService } from '../../qcloud-console.service';

@Component({
  selector: 'lib-redirect-to-desk-modal',
  templateUrl: './redirect-to-desk-modal.component.html',
  styleUrls: ['./redirect-to-desk-modal.component.css']
})
export class RedirectToDeskModalComponent implements OnInit {
  @Output() hideInteractionModel = new EventEmitter<boolean>();
  desks: Desk[];
  selectedDesk: Desk;

  constructor(private consoleService: QcloudConsoleService) {
    this.desks = consoleService.getDesks();
    this.selectedDesk = this.desks[0];
  }

  ngOnInit(): void {
  }

  redirect() {
    this.consoleService.redirectTicketToDesk(this.selectedDesk.id);
    this.hideInteractionModel.emit(true);
  }
}
