import { Component, ElementRef, Renderer2 } from '@angular/core';
import { BaseComponent } from '../../../../shared/base-component/base.component';

@Component({
  selector: 'app-multimedia-and-single-ticket-display',
  templateUrl: './multimedia-and-single-ticket-display.component.html',
  styleUrls: ['./multimedia-and-single-ticket-display.component.css']
})
export class MultimediaAndSingleTicketDisplayComponent extends BaseComponent {

  private _isHideSingleTicketDisplay: boolean = true;
  setIsHideSingleTicketDisplay(event) {
    this.isHideMultimedia = !event;
    this._isHideSingleTicketDisplay = event;
  }
  get isHideSingleTicketDisplay() {
    return this._isHideSingleTicketDisplay;
  }
  isHideMultimedia: boolean = false;
  constructor(protected elementRef: ElementRef, protected renderer: Renderer2) {
    super(elementRef, renderer);
  }
}
