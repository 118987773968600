<div class="form-group">
    <div class="font-weight-bold mb-1">
        {{ 'holiday-schedule' | translate }}
        <button *ngIf="!addingNewHolidayTime" class="btn btn-primary btn-circle btn-sm"
            (click)="editHolidayTime(newHolidayTime); addingNewHolidayTime = true"
            matTooltip="{{ 'add' | translate }}" tooltipClass="tooltip-primary">
            <i class="fas fa-plus"></i>
        </button>
    </div>
    <div *ngIf="addingNewHolidayTime" class="row">
        <app-date-range-picker [startDate]="newHolidayTime.startDate"
            [endDate]="newHolidayTime.endDate" (rangeToEmit)="getRange($event)">
        </app-date-range-picker>
        <button class="btn btn-success btn-circle btn-sm mx-2"
            (click)="onSubmitHoliday(null); addingNewHolidayTime = false"
            matTooltip="{{ 'save' | translate }}" tooltipClass="tooltip-success">
            <i class="fas fa-check"></i>
        </button>
        <button class="btn btn-danger btn-circle btn-sm mr-2" (click)="addingNewHolidayTime = false"
            matTooltip="{{ 'cancel' | translate }}" tooltipClass="tooltip-danger">
            <i class="fas fa-times"></i>
        </button>
    </div>

    <div *ngIf="holidayTimes.length == 0">{{ 'none' | translate}}</div>

    <div class="mt-2" *ngFor="let holidayTime of holidayTimes; let i = index">
        <div *ngIf="changedHolidayTime.id != holidayTime.id">
            {{ holidayTime.startDate | date: 'dd/MM/yyyy' }} -
            {{ holidayTime.endDate | date: 'dd/MM/yyyy' }}
            <button class="btn btn-info btn-circle btn-sm mr-2"
                (click)="editHolidayTime(holidayTime); addingNewHolidayTime = false"
                matTooltip="{{ 'edit' | translate }}" tooltipClass="tooltip-info">
                <i class="fas fa-edit"></i>
            </button>
            <button class="btn btn-danger btn-circle btn-sm mr-2" (click)="deleteHolidayTime(holidayTime)"
                matTooltip="{{ 'delete' | translate }}" tooltipClass="tooltip-danger">
                <i class="fas fa-trash"></i>
            </button>
        </div>
        <div *ngIf="changedHolidayTime.id == holidayTime.id" class="row">
            <app-date-range-picker [startDate]="holidayTime.startDate"
                [endDate]="holidayTime.endDate" (rangeToEmit)="getRange($event)">
            </app-date-range-picker>
            <button class="btn btn-success btn-circle btn-sm mx-2" (click)="onSubmitHoliday(holidayTime)"
                matTooltip="{{ 'save' | translate }}" tooltipClass="tooltip-success">
                <i class="fas fa-check"></i>
            </button>
            <button class="btn btn-danger btn-circle btn-sm mr-2" (click)="editHolidayTime(newHolidayTime)"
                matTooltip="{{ 'cancel' | translate }}" tooltipClass="tooltip-danger">
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
</div>

