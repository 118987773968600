import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileExtention'
})
export class FileExtentionPipe implements PipeTransform {

  transform(item: string) {
    return item.substring(item.lastIndexOf('.'), item.length);
  }
}