import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { QuestionTranslation, ReservationQuestion } from 'projects/qcloud-models/reservation/reservation-questions';
import { Language } from 'projects/qcloud-models/translation/language.model';
import { Translation } from 'projects/qcloud-models/translation/translation.model';
import { ReservationService } from 'projects/qcloud-rest-client/src/lib/reservation.service';
import { TranslationConfigurationService } from 'projects/qcloud-web/src/app/shared/translation-configuration.service';

@Component({
  selector: 'app-reservation-questionaire-translation',
  templateUrl: './reservation-questionaire-translation.component.html',
  styleUrls: ['./reservation-questionaire-translation.component.css',
    '../../languages-and-translations.component.css']
})
export class ReservationQuestionaireTranslationComponent implements OnInit {

  @Input() translations: Translation[];
  @Input() languages: Language[];
  @Input() locationLocaleMain: string;
  startIndex: number;
  reservationQuestions: ReservationQuestion[];
  formSubmitted: boolean = false;

  constructor(
    private reservationService: ReservationService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private translationConfigurationService: TranslationConfigurationService
  ) { }

  ngOnInit(): void {
    this.translationConfigurationService.translationLanguages.subscribe(translations => {
      this.translations = translations;
      this.getReservationQuestionaireTranslations();
    });
    this.getReservationQuestionaireTranslations();
  }

  getReservationQuestionaireTranslations() {
    this.reservationService.getReservationQuestionaire(false,
      (data) => {
        this.reservationQuestions = data;
        this.translations.forEach(translation =>
          this.reservationQuestions.forEach(question => {
            if (!question.translations.find(x => x.locale == translation.locale)) {
              question.translations.push(new QuestionTranslation(translation.locale, ""));
            }
          }));
      },
      () => { });
  }

  getLocaleName(locale: string) {
    return this.languages.find(languageOption => languageOption.locale == locale).name;
  }

  deleteLocaleMain(translations: Translation[]) {
    return translations.filter(translation => translation.locale != this.locationLocaleMain);
  }

  onSubmit() {
    this.formSubmitted = true;
    this.reservationService.updateReservationQuestionaire(this.reservationQuestions).subscribe(
      () => {
        this.formSubmitted = false;
        this.translate.get('reservation-questions-translation-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      () => {
        this.formSubmitted = false;
        this.translate.get('reservation-questions-translation-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  getFilteredTranslations(startIndex: number) {
    this.startIndex = startIndex;
  }

  isOnPage() {
    return this.translations.slice(this.startIndex, this.startIndex + 2);
  }

}
