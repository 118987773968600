<div class="card shadow mb-4">
    <mat-accordion>
        <mat-expansion-panel (opened)="isCollapsed = false"
                             (closed)="isCollapsed = true"
                             hideToggle="true"
                             [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
                <h5 class="col-10 m-2 font-weight-bold text-primary">{{ 'ticket-translation' | translate }}</h5>
            </mat-panel-title>
            <mat-panel-description>
                    <button type="button" class="btn btn-primary btn-circle btn-sm" (click)="isCollapsed = !isCollapsed"
                        [attr.aria-expanded]="!isCollapsed" aria-controls="collapseCategory"
                        [ngClass]="{'clicked': isCollapsed}">
                        <i class="fas fa-angle-down"></i>
                    </button>
            </mat-panel-description>
          </mat-expansion-panel-header>
    <div>
        <div *ngIf="ticketTranslations && ticketLayout" class="card-body">
            <div class="text-right">
                <app-pagination [translations]="translations"
                    (filteredTranslationsEmitter)="getFilteredTranslations($event)">
                </app-pagination>
            </div>
            <div class="row">
                <div class="col-4">

                    <p class="font-weight-bold">{{ getLocaleName(locationLocaleMain) | translate }}</p>

                    <div class="form-group">
                        {{ 'additional-text-ticket' | translate }}
                        <div class="input-group">
                            <input disabled [(ngModel)]="ticketLayout.ticketWords.additionalTicketText" class="form-control">
                        </div>
                    </div>

                    <div class="form-group">
                        {{ 'waiting-time-text-ticket' | translate }}
                        <div class="input-group">
                            <input disabled [(ngModel)]="ticketLayout.ticketWords.waitingTimeTicketText" class="form-control">
                        </div>
                    </div>

                    <div class="form-group">
                        {{ 'arrival-time-text-ticket' | translate }}
                        <div class="input-group">
                            <input disabled [(ngModel)]="ticketLayout.ticketWords.arrivalTimeText" class="form-control">
                        </div>
                    </div>

                    <div class="form-group">
                        {{ 'invitation-text-ticket' | translate }}
                        <div class="input-group">
                            <input disabled [(ngModel)]="ticketLayout.ticketWords.invitationText" class="form-control">
                        </div>
                    </div>

                    <div class="form-group">
                        {{ 'queue-state-text-ticket' | translate }}
                        <div class="input-group">
                            <input disabled [(ngModel)]="ticketLayout.ticketWords.queueStateText" class="form-control">
                        </div>
                    </div>

                    <div class="form-group">
                        {{ 'service-provided-by-text-ticket' | translate }}
                        <div class="input-group">
                            <input disabled [(ngModel)]="ticketLayout.ticketWords.serviceProvidedByText" class="form-control">
                        </div>
                    </div>

                </div>
                <div class="col-4" *ngFor="let translation of deleteLocaleMain(isOnPage()); let i = index">
                    <form *ngIf="ticketTranslations[startIndex + i]"
                        #form="ngForm" autocomplete="off">

                        <div class="input-group">
                            <p class="font-weight-bold mt-1 mr-1">{{ getLocaleName(translation.locale) | translate }}</p>
                            <button *ngIf="!ticketTranslations[startIndex + i].isDefault"
                                class="btn btn-warning btn-circle btn-sm"
                                (click)="backToDefaultTranslation(ticketTranslations[startIndex + i])"
                                matTooltip="{{ 'back-to-default-translation' | translate }}" tooltipClass="tooltip-warning">
                                <i class="fas fa-redo-alt"></i>
                            </button>
                        </div>

                        <div class="form-group">
                            {{ 'additional-text-ticket' | translate }}
                            <div class="input-group">
                                <input class="form-control" name="additionalTicketText" #additionalTicketText="ngModel"
                                    [(ngModel)]="ticketTranslations[startIndex + i].translationWords.additionalTicketText">
                            </div>
                        </div>

                        <div class="form-group">
                            {{ 'waiting-time-text-ticket' | translate }}
                            <div class="input-group">
                                <input class="form-control" name="waitingTimeTicketText" #waitingTimeTicketText="ngModel"
                                    [(ngModel)]="ticketTranslations[startIndex + i].translationWords.waitingTimeTicketText">
                            </div>
                        </div>

                        <div class="form-group">
                            {{ 'arrival-time-text-ticket' | translate }}
                            <div class="input-group">
                                <input class="form-control" name="arrivalTimeText" #arrivalTimeText="ngModel"
                                    [(ngModel)]="ticketTranslations[startIndex + i].translationWords.arrivalTimeText">
                            </div>
                        </div>

                        <div class="form-group">
                            {{ 'invitation-text-ticket' | translate }}
                            <div class="input-group">
                                <input class="form-control" name="invitationText" #invitationText="ngModel"
                                    [(ngModel)]="ticketTranslations[startIndex + i].translationWords.invitationText">
                            </div>
                        </div>

                        <div class="form-group">
                            {{ 'queue-state-text-ticket' | translate }}
                            <div class="input-group">
                                <input class="form-control" name="queueStateText" #queueStateText="ngModel"
                                    [(ngModel)]="ticketTranslations[startIndex + i].translationWords.queueStateText">
                            </div>
                        </div>

                        <div class="form-group">
                            {{ 'service-provided-by-text-ticket' | translate }}
                            <div class="input-group">
                                <input class="form-control" name="serviceProvidedByText" #serviceProvidedByText="ngModel"
                                    [(ngModel)]="ticketTranslations[startIndex + i].translationWords.serviceProvidedByText">
                            </div>
                        </div>

                    </form>
                </div>
            </div>
            <div class="form-group">
                <app-submit-button (click)="onSubmit()" type="submit" [loading]="formSubmitted"
                    [text]="'save-ticket-translations' | translate">
                </app-submit-button>
            </div>
        </div>
    </div>
</mat-expansion-panel>
</mat-accordion>
</div>
