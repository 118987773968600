import { Component, OnInit } from '@angular/core';
import { DeviceConfigurationService } from 'projects/qcloud-device/src/app/services/device-configuration.service';
import { DeviceConfiguration } from 'projects/qcloud-models/device/device-configuration';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { DeskService } from 'projects/qcloud-rest-client/src/lib/desk.service';
import { UserService } from 'projects/qcloud-rest-client/src/lib/user.service';
import { ProjectType } from '../../../../../qcloud-console/src/lib/qcloud-console.component';
import { TicketSystemCategoryQuestionService } from 'projects/qcloud-rest-client/src/lib/category/ticket-system-category-question.service';

@Component({
  selector: 'app-console',
  templateUrl: './console.component.html',
  styleUrls: ['./console.component.css']
})
export class ConsoleComponent implements OnInit {

  isLoading: boolean = true;
  deviceConfiguration: DeviceConfiguration;
  public ProjectTypeEnum: ProjectType;

  constructor(private deviceConfigurationService: DeviceConfigurationService,
    private categoryService: CategoryService,
    private deskService: DeskService,
    private authService: AuthService,
    private userService: UserService,
    private ticketSystemQuestionService: TicketSystemCategoryQuestionService) {
  }

  ngOnInit(): void {
    this.ProjectTypeEnum = ProjectType.WEB;
    this.init();
    this.userService.stateChanged.subscribe(x => {
      this.init();
    })
  }
  init() {
    var config: DeviceConfiguration = new DeviceConfiguration();
    var context = this;
    this.categoryService.getCategories(categories => {
      config.categories = categories;
      config.locationCategories = categories;
      context.deskService.getDesks(desks => {
        config.desks = desks;
        this.ticketSystemQuestionService.getTicketQuestions().subscribe(questions => {
          config.ticketsSystemCategoryQuestions = questions;
          config.locationId = this.authService.getLocationId();
          config.systemId = this.authService.getSystemId();
          context.deviceConfigurationService.setConfiguration(config);
          context.deviceConfiguration = config;
          context.isLoading = false;
        }, () => { })
      }, () => { });
    }, () => { });
  }

}
