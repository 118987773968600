<div class="container-headers">
  <div class="row">
    <h5 class="table-header">{{ 'served-categories' | translate }}</h5>
    <button [hidden]="false" class="btn btn-warning btn-sm btn-circle mx-2" (click)="sortCategories(targetBuilderTools)"
      matTooltip="{{ 'sort-categories-by-tags' | translate }}" tooltipClass="tooltip-warning">
      <i class="fas fa-sort-alpha-down"></i>
    </button>
    <button class="btn btn-info btn-sm btn-circle" (click)="moveAllCategories()"
      matTooltip="{{ 'move-all-categories' | translate }}" tooltipClass="tooltip-info">
      <i class="fas fa-arrow-left"></i>
    </button>
    <button class="btn btn-danger btn-sm btn-circle mx-2" data-toggle="modal" data-target="#deleteModal"
      matTooltip="{{ 'delete-all' | translate }}" tooltipClass="tooltip-danger">
      <i class="fas fa-trash"></i>
    </button>
  </div>
  <div>
    <h5>{{ 'available-elements' | translate }}</h5>
  </div>
</div>
<div class="container-list">
  <div cdkDropList id="{{ListTypeEnum.Target}}" #targetBuilderToolsList="cdkDropList"
    [cdkDropListData]="targetBuilderTools" class="cdk-dnd-target-list" (cdkDropListDropped)="drop($event)">
    <div *ngFor="let item of targetBuilderTools; index as i" [class]="item.classCss" [style]="item.style"
      [cdkDragDisabled]="item.isEmptyCell" (cdkDragStarted)="dragStart($event, item)"
      (cdkDragEnded)="dragEnd($event, item)" cdkDrag>
      <div *ngIf="item.isEditMode">
        <label class="label-groupname-edit">{{ 'group-name' | translate }}</label>
        <span class="fa fa-check ml-1" (click)="setGroupName(i, item.name)"></span>
        <input type="text" (change)="item.name=$event.target.value" [value]="item.name" class="input-groupname-edit">
      </div>
      <div *ngIf="!item.isEditMode">
        <b>{{item.tag}}</b> {{item.name }}
        <span class="fas fa-fw fa-edit" *ngIf="item.isGroup && !item.isDisplayGroup" (click)="getGroupName(item)"></span>
      </div>
    </div>
  </div>
  <div cdkDropList id="{{ListTypeEnum.Template}}" #sourceBuilderToolsList="cdkDropList"
    [cdkDropListData]="sourceBuilderTools" [cdkDropListConnectedTo]="[targetBuilderToolsList]"
    class="cdk-dnd-source-list" (cdkDropListDropped)="drop($event)">
    <div *ngFor="let item of sourceBuilderTools" style="max-height: 100px; overflow-y: visible;">
      <div class="cdk-dnd-item" [ngClass]="{'text-warning' : item.isGroup === true}" *ngIf="!item.isEmptyCell" cdkDrag>
        <span class="fa fa-folder mr-1" [hidden]="!item.isGroup"></span>
        <b [hidden]="item.isGroup">{{item.tag}}</b> {{item.name }}
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-hidden="true">
  <app-delete-modal [info]="'delete-confirmation'" [confirm]="'delete'"
    (confirmed)="onDeleteConfirm($event)"></app-delete-modal>
</div>