<form *ngIf="locationTasks" #form="ngForm" autocomplete="off">

    <div class="form-group">
        {{ 'daily-ticket-reset-time' | translate}}: 
        <mat-form-field>
            <input matInput type="time" name="ticketResetTaskTime" #ticketResetTaskTime="ngModel"
                [(ngModel)]="ticketResetTime">
        </mat-form-field>
        <app-submit-button (click)="resetTickets()" type="submit" [loading]="formReset"
            [text]="'reset-tickets' | translate"></app-submit-button>
        <p>
            {{ 'last-run' | translate }}:
            <span *ngIf="isTaskCompletedOnce(locationTasks.lastTicketResetTime)">
                {{ locationTasks.lastTicketResetTime | date: 'YYYY-MM-dd HH:mm' }}</span>
            <span *ngIf="!isTaskCompletedOnce(locationTasks.lastTicketResetTime)">{{ 'none' | translate }}</span>
        </p>
        <p>{{ 'next-run' | translate }}: {{ locationTasks.futureTicketResetTime | date: 'YYYY-MM-dd HH:mm' }}</p>
    </div>

    <div class="form-group">
        {{ 'daily-category-unblocking-time' | translate}}:  
        <mat-form-field>
            <input matInput type="time" name="categoryUnblockerTaskTime" #categoryUnblockerTaskTime="ngModel"
                [(ngModel)]="categoryResetTime">
        </mat-form-field>
        <app-submit-button (click)="unblockCategories()" type="submit" [loading]="formUnblock"
            [text]="'unblock-categories' | translate"></app-submit-button>
        <p>
            {{ 'last-run' | translate }}:
            <span *ngIf="isTaskCompletedOnce(locationTasks.lastCategoriesUnblockTime)">
                {{ locationTasks.lastCategoriesUnblockTime | date: 'YYYY-MM-dd HH:mm' }}</span>
            <span *ngIf="!isTaskCompletedOnce(locationTasks.lastCategoriesUnblockTime)">{{ 'none' | translate }}</span>
        </p>
        <p>{{ 'next-run' | translate }}: {{ locationTasks.futureCategoriesUnblockTime | date: 'YYYY-MM-dd HH:mm' }}</p>
    </div>

    <div class="form-group row">
        <button class="btn btn-primary m-1" type="submit" [disabled]="formSubmitted || form.invalid" (click)="onSubmit()">
            <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status" aria-hidden="true"></span>
            {{ 'save-ticket-reset-unblock-categories' | translate }}
        </button>
    </div>

</form>
