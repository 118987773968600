<div #pagetop class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 *ngIf="!isEditForm" class="m-0 font-weight-bold text-primary">{{ 'add-user' | translate }}</h5>
        <div *ngIf="isEditForm" class="row">
            <h5 class="m-0 col-8 font-weight-bold text-primary">{{ 'edit-user' | translate }}</h5>
            <div class="col-4 text-right">
                <button type="button" class="btn btn-warning btn-circle btn-sm" tooltipClass="tooltip-warning"
                    matTooltip="{{ 'reset-password-button' | translate }}" (click)="resetPassword(user.id)">
                    <i class="fas fa-fw fa-sync"></i>
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="user" class="card-body">
        <form #form="ngForm" autocomplete="off">

            <div class="form-group">
                <div class="row">
                    <div class="col-6">
                        *{{ 'first-name' | translate }}
                        <div class="input-group">
                            <input name="firstName" #firstName="ngModel" [(ngModel)]="user.firstName"
                                class="form-control" required (change)="createUsername()">
                        </div>
                        <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)"
                            class="text-danger mx-2 my-1">
                            <div *ngIf="firstName.errors.required">{{ 'first-name-required' | translate }}</div>
                        </div>
                    </div>
                    <div class="col-6">
                        *{{ 'last-name' | translate }}
                        <div class="input-group">
                            <input name="lastName" #lastName="ngModel" [(ngModel)]="user.lastName" class="form-control"
                                required (change)="createUsername()">
                        </div>
                        <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)"
                            class="text-danger mx-2 my-1">
                            <div *ngIf="lastName.errors.required">{{ 'last-name-required' | translate }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                *{{ 'username' | translate }}
                <div class="input-group">
                    <input name="userName" #userName="ngModel" [(ngModel)]="user.userName" class="form-control"
                        required>
                </div>
                <div *ngIf="userName.invalid && (userName.dirty || userName.touched)" class="text-danger mx-2 my-1">
                    <div *ngIf="userName.errors.required">{{ 'username-required' | translate }}</div>
                </div>
            </div>

            <div class="form-group">
                {{ 'email' | translate }}
                <div class="input-group">
                    <input type="email" name="email" #email="ngModel" [(ngModel)]="user.email" class="form-control"
                        email>
                </div>
                <div *ngIf="email.invalid && (email.dirty || email.touched)" class="text-danger mx-2 my-1">
                    <div *ngIf="email.errors">{{ 'invalid-email' | translate }}</div>
                </div>
            </div>
            <app-qcloud-roles-assign *ngIf="qcloudRolesMultiCtrl" [qcloudRolesMultiCtrl]="qcloudRolesMultiCtrl"
                [qcloudRoles]="qcloudRoles" [title]="qcloudRolesToAssign" [noSelectedQcloudRoles]="noSelectedQcloudRoles"
                [displayQcloudRoles]="true" [enabledByLocationIds]="enabledByLocationIds" [isSystemRolesList]="false">
            </app-qcloud-roles-assign>
            <div class="form-group row">
                <button class="btn btn-danger m-1" routerLink="/users">{{ 'back' | translate }}</button>
                <button class="btn btn-primary m-1" type="submit" [disabled]="formSubmitted || form.invalid || !isFormReadyForSubmit()"
                    (click)="onSubmit(qcloudRolesMultiCtrl?.value); formSubmitted=true">
                    <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                        aria-hidden="true"></span>
                    {{ 'save-user' | translate }}
                </button>
                <button *ngIf="isEditForm" class="btn btn-info m-1" type="submit"
                    [disabled]="formSubmitted || form.invalid"
                    (click)="newBasedOnExisting(); pagetop.scrollIntoView({behavior: 'smooth'})">
                    <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                        aria-hidden="true"></span>
                    {{ 'add-new-user-based-on-existing' | translate }}
                </button>
            </div>

            <label *ngIf="form.invalid" class="text-danger">*{{ 'complete-required-fields' | translate }}</label>
        </form>
    </div>
</div>