import { Component, Input, OnInit } from '@angular/core';
import { Category } from 'projects/qcloud-models/category/category.model';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { Dictionary } from 'projects/qcloud-models/dictionary';
import { ProcessedTicketsCount } from 'projects/qcloud-models/statistics/processed-tickets-count';

@Component({
  selector: 'app-ticket-in-category-and-desk-statistics',
  templateUrl: './ticket-in-category-and-desk-statistics.component.html',
  styleUrls: ['./ticket-in-category-and-desk-statistics.component.css']
})
export class TicketInCategoryAndDeskStatisticsComponent implements OnInit {

  @Input() categories: Category[];
  @Input() desks: Desk[];
  @Input() processedDeskAndCategoriesTicketsCounts: Dictionary<Dictionary<ProcessedTicketsCount>>;
  selectedDeskId: number;
  selectedCategoryId: number;
  maxTickets: number;

  constructor() { }

  ngOnInit(): void {
    this.selectedDeskId = this.desks[0].id;
    this.selectedCategoryId = this.categories[0].id;
    this.changeDeskOrCategory();
  }

  changeDeskOrCategory() {
    if (this.processedDeskAndCategoriesTicketsCounts[this.selectedDeskId] && 
      this.processedDeskAndCategoriesTicketsCounts[this.selectedDeskId][this.selectedCategoryId]) {
        this.maxTickets = Math.max(...[
          this.processedDeskAndCategoriesTicketsCounts[this.selectedDeskId][this.selectedCategoryId].acceptedTicketsCount,
          this.processedDeskAndCategoriesTicketsCounts[this.selectedDeskId][this.selectedCategoryId].cancelledTicketsCount,
          this.processedDeskAndCategoriesTicketsCounts[this.selectedDeskId][this.selectedCategoryId].redirectedTicketsCount,
          this.processedDeskAndCategoriesTicketsCounts[this.selectedDeskId][this.selectedCategoryId].waitingTicketsCount]);
    }
  }

}
