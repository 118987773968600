export class TicketForHourInfo {
  id: number;
  caption: string;
  private _reservatedDateTime: Date;
  time: string;

  get reservatedDateTime() : Date {
    return this._reservatedDateTime;
  }

  set reservatedDateTime(value: Date) {
    this._reservatedDateTime = value;
    this.time = TicketForHourInfo.getHourAndMinuteFromDate(value);
  }
  public getCaptionForView() : string {
    var captionForView = "- ---";
    if(this.caption) {
      var tag = this.caption.substring(0, this.caption.length - 3);
      var id = this.caption.substring(this.caption.length - 3, this.caption.length);
      captionForView = tag + " " + id;
    }
    return captionForView;
  }
  public static getHourAndMinuteFromDate(date: Date) : string
  {
    var dateString = date.toString().split('T')[1];
    var hour = dateString.split(':')[0];
    var minutes = dateString.split(':')[1];
    return hour+':'+ minutes;
  }
}