import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { TicketsQuestion } from 'projects/qcloud-models/device/ticket-question';
import { TicketSystemCategoryQuestionService } from 'projects/qcloud-rest-client/src/lib/category/ticket-system-category-question.service';
import { SystemService } from 'projects/qcloud-rest-client/src/lib/system.service';

@Component({
  selector: 'app-system-ticket-questions',
  templateUrl: './system-ticket-questions.component.html',
  styleUrls: ['./system-ticket-questions.component.css']
})
export class SystemTicketQuestionsComponent implements OnInit {
  ticketQuestions: TicketsQuestion[];
  selectedQuestion: TicketsQuestion;
  constructor(private ticketSystemCategoryQuestionService: TicketSystemCategoryQuestionService, private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.getQuestions();
  }

  getQuestions() {
    this.ticketSystemCategoryQuestionService.getTicketQuestions().subscribe(data => {
      this.ticketQuestions = data;
    });
  }

  onDeleteConfirm(confirmed: boolean) {
    if (confirmed) {
      this.deleteCategory(this.selectedQuestion.id);
    }
  }

  deleteCategory(id: number) {
    this.ticketSystemCategoryQuestionService.deleteTicketQuestion(this.selectedQuestion.id).subscribe(
      res => {
        this.selectedQuestion = null;
        this.getQuestions()
      },
      err => {
        this.translate.get('delete-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }
}
