import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Category } from 'projects/qcloud-models/category/category.model';
import { timeout } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { CategoryGroup } from 'projects/qcloud-models/category/category-group.model';
import { PutUpdateCategoryApiModel } from '../../../qcloud-models/category/api-model/put-update-category-api-model.model';
import { DisplayCategoryGroup } from 'projects/qcloud-models/category/display-category-group';
import { DisplaySystemCategory } from 'projects/qcloud-models/category/display-system-category';

const DEFAULT_TIMEOUT = 3000;

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  appBase: string;
  endpoint: string = "categories";

  constructor(private http: HttpClient, private authService: AuthService) { }

  public setAppBase(appBase: string) {
    this.appBase = appBase;
  }

  getCategories(callback: (data: Category[]) => any, onError: () => any) {
    this.http.get(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  getCategoriesFilterByLocationIds(locationIds: number[], callback: (data: Category[]) => any, onError: () => any) {
    var getCategoriesFilterByLocationIdsApiModel = {
      filterByLocationIds: locationIds
    };
    this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/filter-by-locations`,
      getCategoriesFilterByLocationIdsApiModel
    )
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }
  getSystemCategories(callback: (data: Category[]) => any, onError: () => any) {
    this.http.get(
      `${this.appBase}api/${this.authService.getSystemId()}}/${this.endpoint}`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  getCategory(categoryId: number): Observable<Category> {
    return this.http.get<Category>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/${categoryId}`);
  }

  addCategory(category: Category) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}`, category);
  }

  editCategory(category: PutUpdateCategoryApiModel, locationId: number) {
    if(locationId === undefined) {
      locationId = this.authService.getLocationId();
    }
    return this.http.put(
      `${this.appBase}api/${this.authService.getSystemId()}/${locationId}/${this.endpoint}/${category.id}`,
      category);
  }

  deleteCategory(categoryId: number) {
    return this.http.delete(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/${categoryId}`);
  }

  setAutomaticalReset(categories: Category[]) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/restarts`, categories);
  }

  unblockCategories() {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/unblockAll`,
      {});
  };

  blockCategory(categoryId: number) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/${categoryId}/block`,
      {});
  };

  unblockCategory(categoryId: number) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/${categoryId}/unblock`,
      {});
  };

  getCategoryGroups(): Observable<CategoryGroup[]> {
    return this.http.get<CategoryGroup[]>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/groups`);
  };

  createCategoryGroups(categoryGroups: CategoryGroup[]) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/groups`,
      categoryGroups);
  };

  getOnHourTimes(categoryId: number, callback: (data: string[]) => any, onError: (data: any) => any) {
    var getOnHourTimes = `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/${categoryId}/onHourTimes`;
    this.http.get(this.appBase + getOnHourTimes)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  addSystemCategoryDisplay(displayCategory: DisplaySystemCategory): Observable<DisplaySystemCategory> {
    return this.http.post<DisplaySystemCategory>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/system-category-display`,
      displayCategory
    );
  }

  getSystemCategoryDisplays(): Observable<DisplaySystemCategory[]> {
    return this.http.get<DisplaySystemCategory[]>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/system-category-display`
    );
  }

  getSystemCategoryDisplay(id: number): Observable<DisplaySystemCategory> {
    return this.http.get<DisplaySystemCategory>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/system-category-display/${id}`
    );
  }

  updateSystemCategoryDisplay(id: number, displayCategory: DisplaySystemCategory): Observable<void> {
    return this.http.put<void>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/system-category-display/${id}`,
      displayCategory
    );
  }

  deleteSystemCategoryDisplay(id: number): Observable<void> {
    return this.http.delete<void>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/system-category-display/${id}`
    );
  }

  addGroupDisplay(displayCategoryGroup: DisplayCategoryGroup): Observable<DisplayCategoryGroup> {
    return this.http.post<DisplayCategoryGroup>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/group-display`,
      displayCategoryGroup
    );
  }

  getGroupDisplays(): Observable<DisplayCategoryGroup[]> {
    return this.http.get<DisplayCategoryGroup[]>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/group-display`
    );
  }

  getGroupDisplay(id: number): Observable<DisplayCategoryGroup> {
    return this.http.get<DisplayCategoryGroup>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/group-display/${id}`
    );
  }

  updateGroupDisplay(id: number, displayCategoryGroup: DisplayCategoryGroup): Observable<void> {
    return this.http.put<void>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/group-display/${id}`,
      displayCategoryGroup
    );
  }

  deleteGroupDisplay(id: number): Observable<void> {
    return this.http.delete<void>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/group-display/${id}`
    );
  }
}
