import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { QCloudRestClientService } from '../qcloud-rest-client.service';
import { TokenResponse } from '../../../../qcloud-models/user/token-response';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public authService: AuthService, public qcloudRestClientService: QCloudRestClientService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.isLoggedIn()) {
      request.headers.set('Authorization', 'Bearer ' + this.authService.getToken());

      const cloneReq = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + this.authService.getToken())
      });
      return next.handle(cloneReq).pipe(
        tap(
          suc => {
            var refreshToken = this.authService.getRefreshToken();
            if (this.authService.isTokenExpired() && refreshToken != undefined && refreshToken != '' && this.authService.getIsRefreshTokenStarted() === 'false') {
              this.qcloudRestClientService.getRefreshAccessTokenForUser(this.callback, this.onError);
            }
          },
          err => {
            var refreshToken = this.authService.getRefreshToken();
            if (err.status == 401 || err.status == 403 || (this.authService.isTokenExpired() && refreshToken != undefined && refreshToken != '' && this.authService.getIsRefreshTokenStarted() === 'false')) {
              this.qcloudRestClientService.getRefreshAccessTokenForUser(this.callback, this.onError);
            }

          }
        )
      );
    }
    else {
      return next.handle(request.clone());
    }
  }

  private callback = (data: TokenResponse) => { }

  private onError = () => {
    this.authService.onLogout();
  }

}
