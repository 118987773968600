<!-- Topbar -->
<nav class="navbar navbar-expand navbar-light bg-white sticky-top topbar shadow">

    <app-topbar-logo></app-topbar-logo>

    <!-- Topbar Navbar -->
    <ul class="navbar-nav ml-auto">
    
        <!-- Nav Item - Search Dropdown (Visible Only XS) -->
        <li class="nav-item d-sm-none mr-auto ml-md-3 mb-2 mt-3 my-md-0 mw-100">
            <img style="height: 2.5rem" src="assets/images/qcloud-logo.png">
        </li>
        
        <li class="nav-item">
            <a class="nav-link" role="button" (click)="changeLanguage('pl')">
                <img class="img-profile rounded-circle pointer" src="assets/images/pl.svg" 
                    alt="PL" [ngClass]="{'selected': checkLanguage('pl')}">
            </a>
        </li>
        
        <li class="nav-item">
            <a class="nav-link" role="button" (click)="changeLanguage('en')">
                <img class="img-profile rounded-circle pointer" src="assets/images/gb.svg" 
                    alt="EN" [ngClass]="{'selected': checkLanguage('en')}">
            </a>
        </li>
        
        <div class="topbar-divider d-none d-sm-block"></div>
        
        <!-- Nav Item - User Information -->
        <li class="nav-item dropdown no-arrow">
            <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{ username }} <i class="fas fa-caret-down"></i></span>
            </a>
            <!-- Dropdown - User Information -->
            <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                <a class="dropdown-item" [routerLink]="['profile/', userId]">
                    <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-600"></i>
                    {{ 'profile' | translate }}
                </a>
                <a class="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
                    <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-danger"></i>
                    {{ 'logout' | translate }}
                </a>
            </div>
        </li>
    
    </ul>

</nav>
<!-- End of Topbar -->

<!-- Logout Modal-->
<div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-hidden="true">
    <app-delete-modal [info]="'logout-info'" [confirm]="'logout'" (confirmed)="onLogoutConfirm($event)"></app-delete-modal>
</div>