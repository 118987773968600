<div *ngFor="let blockingTime of unblockingTimeDaysValue; let i = index" class="mb-4">
    <div class="item-group row">
        <mat-checkbox [disabled]="isReadOnly" [(ngModel)]="blockingTime.isActive" [ngModelOptions]="{standalone: true}" color="primary"
            class="mt-1 mr-3" style="min-width: 115px;">
            {{ dayName[i] | translate }}
        </mat-checkbox>
        <div *ngIf="blockingTime.isActive" class="row">
            <div *ngIf="!nightWork[i] && !blockingTime.is24Hours">
                <input [disabled]="isReadOnly" type="time" [ngModelOptions]="{standalone: true}" (change)="updateSlider(i)"
                    [(ngModel)]="unblockingTimeDaysValue[i].unblockingTime" />
                - <input [disabled]="isReadOnly" type="time" [ngModelOptions]="{standalone: true}" (change)="updateSlider(i)"
                    [(ngModel)]="unblockingTimeDaysValue[i].blockingTime" />
            </div>
            <div *ngIf="nightWork[i] && !blockingTime.is24Hours">
                <input [disabled]="isReadOnly" type="time" value="00:00" disabled />
                - <input [disabled]="isReadOnly" type="time" [ngModelOptions]="{standalone: true}" (change)="updateSlider(i)"
                    [(ngModel)]="unblockingTimeDaysValue[i].unblockingTime" />
                {{ 'and' | translate }} <input [disabled]="isReadOnly" type="time" [ngModelOptions]="{standalone: true}"
                    (change)="updateSlider(i)" [(ngModel)]="unblockingTimeDaysValue[i].blockingTime" />
                - <input [disabled]="isReadOnly" type="time" value="23:59" disabled />
            </div>
            <button [disabled]="isReadOnly" class="btn btn-success btn-circle btn-sm ml-2" (click)="copy(i)"
                matTooltip="{{ 'copy' | translate }}" tooltipClass="tooltip-success">
                <i class="far fa-copy"></i>
            </button>
            <button [disabled]="isReadOnly" class="btn btn-info btn-circle btn-sm ml-1" (click)="paste(i)"
                matTooltip="{{ 'paste' | translate }}" tooltipClass="tooltip-info">
                <i class="fas fa-paste"></i>
            </button>
            <mat-checkbox [disabled]="isReadOnly" [(ngModel)]="blockingTime.is24Hours" [ngModelOptions]="{standalone: true}" color="primary"
                class="mt-1 mx-2">
                {{ '24-hours-a-day' | translate }}
            </mat-checkbox>
            <mat-checkbox [disabled]="isReadOnly" [checked]="nightWork[i]" color="primary" class="mt-1 ml-2"
                (change)="nightWork[i] = !nightWork[i]">{{ 'night-work' | translate }}
            </mat-checkbox>
            <mat-checkbox [disabled]="isReadOnly" [(ngModel)]="blockingTime.isButtonAlwaysVisible" [ngModelOptions]="{standalone: true}"
                color="primary" class="mt-1 mx-2">
                {{ 'is-button-always-visible' | translate }}
            </mat-checkbox>
        </div>
    </div>
    <div class="item-group" *ngIf="blockingTime.isActive && !blockingTime.is24Hours">
        <div *ngIf="!nightWork[i]" class="main-slider">
            <ngx-slider [(value)]="minValues[i]" [(highValue)]="maxValues[i]" [options]="options"
                (userChangeStart)="onUserChange($event, i)" (userChange)="onUserChange($event, i)"
                (userChangeEnd)="onUserChange($event, i)">
            </ngx-slider>
        </div>
        <div *ngIf="nightWork[i]" class="inversed-slider">
            <ngx-slider [(value)]="minValues[i]" [(highValue)]="maxValues[i]" [options]="options"
                (userChangeStart)="onUserChange($event, i)" (userChange)="onUserChange($event, i)"
                (userChangeEnd)="onUserChange($event, i)">
            </ngx-slider>
        </div>
    </div>
    <div *ngIf="withOffices && blockingTime.isActive" class="item-group">
        <div class="input-group">
            <div class="font-weight-bold">{{ 'office' | translate }}</div>
        </div>
        <app-served-offices *ngIf="withOffices" [offices]="withOffices.offices"
            [officeMultiCtrl]="withOffices.officeMultiCtrl[i]" [displayOffices]="true">
        </app-served-offices>
    </div>
</div>