import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { DeviceLayoutService } from '../../../services/device-layout.service';
import { RowStyle } from 'projects/qcloud-models/device/row-style';
import { MultimediaService } from 'projects/qcloud-rest-client/src/lib/multimedia.service';

@Component({
  selector: 'app-start-piece-bar-compatible',
  templateUrl: './start-piece-bar-compatible.component.html',
  styleUrls: ['./start-piece-bar-compatible.component.css']
})
export class StartPieceBarCompatibleComponent implements OnInit {

  @Input() active: boolean;
  @Input() content: string;
  @Input() rowHeight: number;
  @Input() rowStyle: RowStyle;
  radius: any;

  constructor(private deviceLayoutService: DeviceLayoutService, private multimediaClient: MultimediaService) { }

  ngOnInit() {
    this.setRadius();
  }

  setRadius(): Object {
    let styleObj = {};
    if (this.rowStyle.borderColor) {
      styleObj["border-color"] = this.rowStyle.borderColor;
    }
    if (this.rowStyle.borderSize) {
      styleObj["border-width"] = `${this.rowStyle.borderSize}px 0px ${this.rowStyle.borderSize}px ${this.rowStyle.borderSize}px`;
    }
    if (this.rowStyle.backgroundType == 0 && this.rowStyle.backgroundColor) {
      styleObj["background"] = `${this.rowStyle.backgroundColor}`;
    }
    else if (this.rowStyle.backgroundType == 1) {
      styleObj["background"] = ``;
    }
    else {
      styleObj["background"] = `linear-gradient(to bottom, #c4d3dd 0%, #b1c7d4 50%, #b1c7d4 51%, #abc3d1 100%)`
    }
    styleObj["border-top-left-radius"] = `${this.rowStyle.borderRadius}px`;
    styleObj["border-bottom-left-radius"] = `${this.rowStyle.borderRadius}px`;
    return styleObj;
  }

  getStyle() {
    let styleObj = {};
    if (this.rowStyle.borderColor) {
      styleObj["border-color"] = this.rowStyle.borderColor;
    }
    if (this.rowStyle.borderSize) {
      styleObj["border-width"] = `${this.rowStyle.borderSize}px ${this.rowStyle.borderSize}px ${this.rowStyle.borderSize}px 0px`;
    }
    if (this.rowStyle.backgroundType == 0 && this.rowStyle.backgroundColor) {
      styleObj["background"] = `${this.rowStyle.backgroundColor}`;
    }
    else if (this.rowStyle.backgroundType == 1) {
      styleObj["background"] = ``;
    }
    else {
      styleObj["background"] = `linear-gradient(to bottom, #c4d3dd 0%, #b1c7d4 50%, #b1c7d4 51%, #abc3d1 100%)`
    }
    return styleObj;
  }

  getBackgroundStyle() {
    let styleObj = {};
    if (this.rowStyle.backgroundImage && this.rowStyle.backgroundType == 1) {
      styleObj["background-image"] = `url('${this.multimediaClient.getFileUriFromCdn(this.rowStyle.backgroundImage)}')`;
    }
    styleObj["border-top-left-radius"] = `${this.rowStyle.borderRadius}px`;
    styleObj["border-bottom-left-radius"] = `${this.rowStyle.borderRadius}px`;
    return styleObj;
  }
}
