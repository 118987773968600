import { Component, OnInit, Input, ViewEncapsulation, HostListener } from '@angular/core';
import { DeviceConfiguration } from 'projects/qcloud-models/device/device-configuration';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { QcloudConsoleService } from './qcloud-console.service';
import { ScreenSizeConfigurationService, ScreenSizeType } from './services/screen-size-configuration.service';

export enum ProjectType {
  WEB = 'qcloud-web',
  DEVICE = 'qclous-device'
}

@Component({
  selector: 'lib-qcloud-console',
  templateUrl: './qcloud-console.component.html',
  styleUrls: ['./qcloud-console.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class QcloudConsoleComponent implements OnInit {
  ScreenSizeTypeEnum;
  public screenSizeType: ScreenSizeType;
  ProjectTypeEnum = ProjectType;

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander(event) {
    this.consoleService.logout();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenSizeConfigurationService.updateScreenSizeType(window.innerWidth, window.innerHeight);
    this.screenSizeType = this.screenSizeConfigurationService.screenSizeType;
  }

  @Input() public deviceConfiguration: DeviceConfiguration;
  @Input() public projectType: ProjectType = ProjectType.WEB;

  constructor(public authService: AuthService, public consoleService: QcloudConsoleService,
    private screenSizeConfigurationService: ScreenSizeConfigurationService) {
  }

  ngOnInit(): void {
    this.screenSizeConfigurationService.updateScreenSizeType(window.innerWidth, window.innerHeight);
    this.screenSizeType = this.screenSizeConfigurationService.screenSizeType;
    this.ScreenSizeTypeEnum = this.screenSizeConfigurationService.ScreenSizeTypeEnum;
    this.consoleService.projectType = this.projectType;
  }
  isHideElement() : boolean {
    return this.screenSizeType == this.ScreenSizeTypeEnum.SLIM || this.screenSizeType == this.ScreenSizeTypeEnum.SMALL;
  }
}
