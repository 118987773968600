import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-confirm-reject-button',
  templateUrl: './confirm-reject-button.component.html',
  styleUrls: ['./confirm-reject-button.component.css']
})
export class ConfirmRejectButtonComponent implements OnInit {

  @Input() confirm: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
