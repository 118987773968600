<div class="card shadow mb-4">
    <div class="card-header py-3">
        <div class="row">
            <h5 class="col-8 m-0 font-weight-bold text-primary">{{ 'Logo' | translate }}</h5>
            <div class="text-right col-4">
                <button *ngIf="content?.length==0" class="btn btn-primary btn-circle btn-sm"
                    matTooltip="{{ 'add' | translate }}" tooltipClass="tooltip-primary" data-toggle="modal"
                    data-target="#logoUploaderModal" (click)="clearUploaderQueue = true">
                    <i class="fas fa-fw fa-plus"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="card-body row">
            <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12 d-flex align-items-stretch card-height mb-2 pr-0 pl-0"
                *ngFor="let item of content; let i = index">
                <div class="container-fluid text-center pointer" [ngClass]="{'selected': selectedFiles[i]}">
                    <img [src]="service.getPathToImage(item.path)" (error)="onImgError($event , item.path, item.name)"
                        alt="QCloud File" class="img-fluid rounded height">
                    <div>{{ displayFilename(item.name, i) | removeExtention }}</div>
                    <button class="btn btn-danger btn-circle btn-sm mx-1 mb-1" matTooltip="{{ 'delete' | translate }}"
                        tooltipClass="tooltip-danger" data-toggle="modal" data-target="#logoDeleteModal">
                        <i class="fas fa-fw fa-trash"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Logo Uploader Modal-->
<div class="modal fade" id="logoUploaderModal" tabindex="-1" role="dialog" aria-labelledby="modalLabel"
    aria-hidden="true">
    <app-logo-uploader [path]="path" (getFolderContent)="getDirectoryContent()"
        [clearUploaderQueue]="clearUploaderQueue" (unsetClearingUploaderQueue)="clearUploaderQueue = false">
    </app-logo-uploader>
</div>

<!-- Logo Confirm Delete Modal-->
<div class="modal fade" id="logoDeleteModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{ 'delete-question' | translate}}</h5>
                <button class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-footer">
                <button class="btn btn-danger" data-dismiss="modal">{{ 'cancel' | translate }}</button>
                <button class="btn btn-primary" data-dismiss="modal" (click)="delete(content[0])">
                    {{ 'yes' | translate }}</button>
            </div>
        </div>
    </div>
</div>