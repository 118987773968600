import { Component, Input, OnInit } from '@angular/core';
import { Category } from 'projects/qcloud-models/category/category.model';
import { Chart } from 'chart.js';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { TimeConversionService } from 'projects/qcloud-web/src/app/shared/time-conversion.service';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-daily-queueing-statistics',
  templateUrl: './daily-queueing-statistics.component.html',
  styleUrls: ['./daily-queueing-statistics.component.css']
})
export class DailyQueueingStatisticsComponent implements OnInit {

  @Input() categories: Category[];
  @Input() dailyCategoryQueue: Map<number, Map<number, number>>;
  labels: string[];
  datasets: any[];
  data: number[][];
  dailyQueueingChart;

  public categoryMultiCtrl: UntypedFormControl;
  selectedCategories: Category[];

  title: string = 'categories';
  noSelectedCategories = 'no-selected-categories';

  constructor(private categoryService: CategoryService, private timeService: TimeConversionService) { }

  ngOnInit(): void {
    this.setChart();
    this.categoryMultiCtrl = new UntypedFormControl([this.categories[0]]);
    this.getSelectedCategoriesActivities();
  }

  getSelectedCategoriesActivities() {
    this.selectedCategories = new Array();
    this.categoryMultiCtrl.value.forEach(selectedCategory => {
      this.categoryService.getCategory(selectedCategory.id).subscribe(res => {
        this.selectedCategories.push(res);
        if (this.categoryMultiCtrl.value.length == this.selectedCategories.length) {
          this.prepareChart();
          this.updateChart();
        }
      });
    });
  }

  changeCategories(categories: Category[]) {
    this.getSelectedCategoriesActivities();
  }

  prepareChart() {
    let dayOfWeek = new Date().getDay();
    let startCategoryActivity = 0;
    let endCategoryActivity = 24;
    if (!this.selectedCategories.find(category =>
      new Date(category.unblockingTimeDaysValue[dayOfWeek].unblockingTime).getHours() >
      new Date(category.unblockingTimeDaysValue[dayOfWeek].blockingTime).getHours())) {

        startCategoryActivity = Math.min.apply(Math, this.selectedCategories.map((category) => {
          return new Date(category.unblockingTimeDaysValue[dayOfWeek].unblockingTime).getHours();
        }));
        endCategoryActivity = Math.max.apply(Math, this.selectedCategories.map((category) => {
          return new Date(category.unblockingTimeDaysValue[dayOfWeek].blockingTime).getHours();
        }));
    }
    
    this.labels = new Array<string>();
    for (let i = startCategoryActivity; i <= endCategoryActivity; ++i) {
      this.labels.push(this.timeService.minutesToTime(i * 60));
    }
    this.data = new Array<number[]>();
    this.selectedCategories.forEach(category => {
      let categoryDailyQueue: Map<number, number> = new Map<number, number>();
      let timeZoneOffset = new Date().getTimezoneOffset() / 60;
      for (let i = startCategoryActivity; i <= endCategoryActivity; ++i) {
        categoryDailyQueue[i] = this.dailyCategoryQueue[category.id][i + timeZoneOffset];
      }
      this.data.push(Object.values(categoryDailyQueue));
    });
  }

  updateChart() {
    this.datasets = new Array();
    for (let i = 0; i < this.selectedCategories.length; ++i) {
      let r = Math.floor(Math.random() * 255);
      let g = Math.floor(Math.random() * 255);
      let b = Math.floor(Math.random() * 255);
      let mainColor = "rgba(" + r + "," + g + "," + b + ",1)";
      let backgroundColor = "rgba(" + r + "," + g + "," + b + ",0.05)";
      let dataset = {
        label: this.selectedCategories[i].fullName,
        lineTension: 0.3,
        backgroundColor: backgroundColor,
        borderColor: mainColor,
        pointRadius: 3,
        pointBackgroundColor: mainColor,
        pointBorderColor: mainColor,
        pointHoverRadius: 3,
        pointHoverBackgroundColor: mainColor,
        pointHoverBorderColor: mainColor,
        pointHitRadius: 10,
        pointBorderWidth: 2,
        data: this.data[i],
      }
      this.datasets.push(dataset);
    }
    this.dailyQueueingChart.data.labels = this.labels;
    this.dailyQueueingChart.data.datasets = this.datasets;
    this.dailyQueueingChart.update();
  }

  setChart() {
    let ctx = document.getElementById("dailyQueueingChart");
    this.dailyQueueingChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: this.labels,
        datasets: this.datasets
      },
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 10,
            right: 25,
            top: 25,
            bottom: 0
          }
        },
        scales: {
          xAxes: [{
            time: {
              unit: 'date'
            },
            gridLines: {
              display: false,
              drawBorder: false
            },
            ticks: {
              maxTicksLimit: 7
            }
          }],
          yAxes: [{
            ticks: {
              maxTicksLimit: 5,
              padding: 10
            },
            gridLines: {
              color: "rgb(234, 236, 244)",
              zeroLineColor: "rgb(234, 236, 244)",
              drawBorder: false,
              borderDash: [2],
              zeroLineBorderDash: [2]
            }
          }],
        },
        legend: {
          display: true,
          labels: {
            usePointStyle: true,
            padding: 15
          }
        },
        tooltips: {
          backgroundColor: "rgb(255,255,255)",
          bodyFontColor: "#858796",
          titleMarginBottom: 10,
          titleFontColor: '#6e707e',
          titleFontSize: 14,
          borderColor: '#dddfeb',
          borderWidth: 1,
          xPadding: 15,
          yPadding: 15,
          displayColors: false,
          intersect: false,
          mode: 'index',
          caretPadding: 10,
          callbacks: {
            label: function(tooltipItem, chart) {
              var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
              return datasetLabel + ': ' + (tooltipItem.yLabel);
            }
          }
        }
      }
    });
  }

}
