<div class="form-group">
    <div class="font-weight-bold">{{ title | translate}}</div>
    <div class="input-group">
        <mat-form-field color="primary">
            <mat-select [disabled]="isDisabled" [formControl]="categoryMultiCtrl" [multiple]="true" #multiSelect
                (selectionChange)="emitCategories($event.value)">
                <mat-option>
                    <ngx-mat-select-search [showToggleAllCheckbox]="true" (toggleAll)="toggleSelectAll($event)"
                        [formControl]="categoryMultiFilterCtrl"
                        toggleAllCheckboxTooltipMessage="{{ 'select-unselect-all' | translate }}"
                        [toggleAllCheckboxTooltipPosition]="'above'"
                        placeholderLabel="{{ 'search-category' | translate }}"
                        noEntriesFoundLabel="{{ 'no-matching-category' | translate }}">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let category of filteredCategoriesMulti | async" [value]="category">
                    <b>{{ category.categoryTag }}</b>
                    {{ category.fullName }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <p *ngIf="categoryMultiCtrl.value == null || categoryMultiCtrl?.value.length == 0">
        {{ noSelectedCategories | translate }}</p>
    <div *ngIf="displayCategories">
        <ul *ngFor="let category of categoryMultiCtrl?.value">
            <li>
                <app-category-button [categoryTag]="category.categoryTag"></app-category-button>
                {{ category.fullName }}
            </li>
        </ul>
    </div>
</div>