<!-- add/update calendar modal -->
<ng-template #modalContent let-close="close">
    <div class="modal-header">
        <h5 class="modal-title">{{ 'edit-reservation-vacation' | translate }}</h5>
        <button type="button" class="close" (click)="close()" mat-dialog-close>
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" mat-dialog-content>
        <div class="form-group">
            <div class="font-weight-bold mb-1">
                {{ 'blocking-vacation-activities' | translate }}
            </div>
            <div class="row">
                <app-date-range-picker [startDate]="newActivityBlock.dateStart"
                    [endDate]="newActivityBlock.dateEnd" (rangeToEmit)="getRange($event)">
                </app-date-range-picker>
                <button class="btn btn-success btn-circle btn-sm mx-2"
                    (click)="onSubmitActivityBlock(null)"
                    matTooltip="{{ 'save' | translate }}" tooltipClass="tooltip-success">
                    <i class="fas fa-check"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <!--modal footer-->
    </div>
</ng-template>
