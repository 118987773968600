<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="col-10 m-0 font-weight-bold text-primary">{{ 'change-password' | translate }}</h5>
    </div>
    <div *ngIf="changePassword" class="card-body">
        <form #form="ngForm" autocomplete="off">

            <div class="form-group">
                {{ 'old-password' | translate }}
                <input type="password" name="oldPassword" #oldPassword="ngModel" [(ngModel)]="changePassword.oldPassword"
                    class="form-control" required>
                <div *ngIf="oldPassword.invalid && (oldPassword.dirty || oldPassword.touched)" class="text-danger mx-2 my-1">
                    <div *ngIf="oldPassword.errors.required">{{ 'password-required' | translate }}</div>
                </div>
            </div>

            <div class="form-group">
                {{ 'new-password' | translate }}
                <input type="password" name="newPassword" #newPassword="ngModel" [(ngModel)]="changePassword.newPassword"
                    class="form-control" required minlength="8" pattern=".*[0-9].*">
                <small class="form-text text-muted">{{ 'password-requirements' | translate }}</small>
                <div *ngIf="newPassword.invalid && (newPassword.dirty || newPassword.touched)" class="text-danger mx-2 my-1">
                    <div *ngIf="newPassword.errors.required">{{ 'password-required' | translate }}</div>
                    <div *ngIf="newPassword.errors.minlength || newPassword.errors.pattern">{{ 'incorrect-password' | translate }}</div>
                </div>
            </div>

            <div class="form-group">
                {{ 'confirm-password' | translate }}
                <input type="password" name="confirmPassword" #confirmPassword="ngModel" (ngModel)="confirmPassword"
                    class="form-control">
                <div *ngIf="checkPasswords(confirmPassword.value) && (newPassword.dirty || newPassword.touched)" class="text-danger mx-2 my-1">
                    <div>{{ 'passwords-do-not-match' | translate }}</div>
                </div>
            </div>

            <div class="form-group row">
                <button class="btn btn-primary m-1" type="submit" [disabled]="formSubmitted || form.invalid"
                    (click)="onSubmit(); formSubmitted=true">
                    <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                        aria-hidden="true"></span>
                    {{ 'change-password' | translate }}
                </button>
            </div>
            <label *ngIf="form.invalid" class="text-danger">*{{ 'complete-required-fields' | translate }}</label>
        </form>
    </div>
</div>