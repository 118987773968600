<div class="form-group">
    <div class="font-weight-bold">{{ title | translate}}</div>
    <div class="input-group">
        <mat-form-field color="primary">
            <mat-select [formControl]="userMultiCtrl" [multiple]="true" #multiSelect
                (selectionChange)="emitUsers($event.value)">
                <mat-option>
                    <ngx-mat-select-search [showToggleAllCheckbox]="true" (toggleAll)="toggleSelectAll($event)"
                        [formControl]="userMultiFilterCtrl"
                        toggleAllCheckboxTooltipMessage="{{ 'select-unselect-all' | translate }}"
                        [toggleAllCheckboxTooltipPosition]="'above'" placeholderLabel="{{ 'search-user' | translate }}"
                        noEntriesFoundLabel="{{ 'no-matching-user' | translate }}">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let user of filteredUsersMulti | async" [value]="user">
                    {{ user.firstName }} {{ user.lastName }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <p *ngIf="userMultiCtrl.value == null || userMultiCtrl?.value.length == 0">
        {{ noSelectedUsers | translate }}</p>
    <div *ngIf="displayUsers">
        <ul *ngFor="let user of userMultiCtrl?.value">
            <li>
                {{ user.firstName }} {{ user.lastName }}
            </li>
        </ul>
    </div>
</div>