import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { Language } from 'projects/qcloud-models/translation/language.model';
import { takeUntil, take } from 'rxjs/operators';

@Component({
  selector: 'app-languages-selection',
  templateUrl: './languages-selection.component.html',
  styleUrls: ['./languages-selection.component.css']
})
export class LanguagesSelectionComponent implements OnInit {

  @Input() languages: Language[];
  @Input() disableLanguageSelection: boolean;
  @Input() languageMultiCtrl: UntypedFormControl;
  public languageMultiFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredLanguagesMulti: ReplaySubject<Language[]> = new ReplaySubject<Language[]>(1);

  protected _onDestroy = new Subject<void>();

  constructor() { }

  ngOnInit(): void {
    // load the initial language option list
    this.filteredLanguagesMulti.next(this.languages.slice());

    // listen for search field value changes
    this.languageMultiFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterLanguageMulti();
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredLanguagesMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.languageMultiCtrl.patchValue(val);
        } else {
          this.languageMultiCtrl.patchValue([]);
        }
      });
  }

  protected filterLanguageMulti() {
    if (!this.languages) {
      return;
    }
    // get the search keyword
    let search = this.languageMultiFilterCtrl.value;
    if (!search) {
      this.filteredLanguagesMulti.next(this.languages.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the Language Options
    this.filteredLanguagesMulti.next(
      this.languages.filter(language => language.name.toLowerCase().indexOf(search) > -1)
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.languages) {
      this.filteredLanguagesMulti.next(this.languages.slice());
    }
   }

}
