import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MultimediaService } from 'projects/qcloud-rest-client/src/lib/multimedia.service';
import { Playlist } from 'projects/qcloud-models/multimedia/playlist.model';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-playlist',
  templateUrl: './add-playlist.component.html',
  styleUrls: ['./add-playlist.component.css']
})
export class AddPlaylistComponent implements OnInit {

  playlist: Playlist;

  formSubmitted: boolean = false;
  editForm: boolean = false;
  ctx = this;

  constructor(private multimediaService: MultimediaService, private router: Router, private toastr: ToastrService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.resetForm();
  }

  onSubmit(form: NgForm) {
    this.multimediaService.createPlaylist(this.playlist.name).subscribe(
      playlist => {
        this.resetForm(form);
        this.router.navigate(['/playlist/edit/' + playlist.id]);
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('create-playlist-error').subscribe((res: string) => {
          let error = res;
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  resetForm(form?:NgForm) {
    if(form != null) {
      form.resetForm();
    }
    this.playlist = {
      id : 0, systemId: 1, name: '', size: 0, lastModified: new Date(), playlistItems: []
    }
  }

}
