<div class="ticket-row">
    <div class="ticket-caption-container button-container" [ngClass]="{ 'active': active }">
        <div class="middle">
        </div>
        <div class="back">
        </div>
        <div class="text-wrapper">
            <div [fittext]="true" [activateOnResize]="true" [modelToWatch]="ticketCaption">
                {{ticketCaption}}
            </div>
        </div>
    </div>
    <div class="desk-caption-container button-container" [ngClass]="{ 'active': active }">
        <div class="front">
        </div>
        <div class="middle">
        </div>
        <div class="text-wrapper">
            <div [fittext]="true" [activateOnResize]="true" [modelToWatch]="deskNumber">
                {{deskNumber}}
            </div>
        </div>
    </div>
</div>