import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from 'projects/qcloud-models/user/user.model';
import { UserService } from 'projects/qcloud-rest-client/src/lib/user.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { QcloudStompClientService } from 'projects/qcloud-stomp-client/src/public-api';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { Subscription, finalize } from 'rxjs';
import { DeskService } from 'projects/qcloud-rest-client/src/lib/desk.service';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { LocationData } from 'projects/qcloud-models/location/location.model';
import { QcloudRole } from '../../../../../qcloud-models/qcloud-role/qcloud-role';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DetailsPopupModalData } from '../../../../../qcloud-models/console/details-popup-modal/details-popup-modal-data';
import { DetailsModalComponent } from '../../shared/details-modal/details-modal.component';
import { DetailsStructureTemplate } from '../../../../../qcloud-models/console/details-popup-modal/details-structure-template';
import { DetailsStructureTemplateGroup } from '../../../../../qcloud-models/console/details-popup-modal/details-structure-template-group';
import { Category } from '../../../../../qcloud-models/category/category.model';
import { RolePermissionLevel } from '../../../../../qcloud-models/qcloud-role/role-permission-level';

@Component({
  selector: 'app-system-users',
  templateUrl: './system-users.component.html',
  styleUrls: ['./system-users.component.css']
})
export class SystemUsersComponent implements OnInit, OnDestroy {

  users: User[];
  selectedUserId: string;
  public desks: Desk[];
  private stompDesksStatusSubscription: Subscription;
  private emptyDesk: string = "---";
  private emptyLocation: string = "---";
  private locations: LocationData[] = [];

  constructor(private userService: UserService,
    private translate: TranslateService,
    private qcloudStompClientService: QcloudStompClientService,
    private toastr: ToastrService,
    private deskService: DeskService,
    private locationService: LocationService,
    private modalService: MatDialog) { }

  ngOnInit(): void {
    this.getDesks();
    this.getLocations();
  }

  ngOnDestroy(): void {
    this.stompDesksStatusSubscription.unsubscribe();
  }

  getUsers() {
    this.userService.getSystemUsers((data) => {
      this.users = data.filter(x => x.isLdapUser === false);
      this.startDeskListening();
    },
      () => { }
    );
  }

  getDesks() {
    this.deskService.getDesks((data) => {
      this.getUsers();
      this.desks = data;
    },
      () => { });
  }
  getLocations() {
    this.locationService.getAll().subscribe(res =>
      this.locations = res
    );
  }

  private startDeskListening() {
    var context = this;
    this.stompDesksStatusSubscription = this.qcloudStompClientService.subscribe("/exchange/deskStatus/1.1.*", (payload: any) => {
      let desk = context.desks.find(x => x.id == payload.deskId);
      desk.deskStatus = payload.deskStatus;
      desk.currentLoggedInUserId = payload.userId;
      context.refreshTable();
    });
  }

  private refreshTable() {
    this.users = Object.assign([], this.users);
  }

  onDeleteConfirm(confirmed: boolean) {
    if (confirmed) {
      this.deleteUser(this.selectedUserId);
    }
  }

  deleteUser(userId: string) {
    this.userService.deleteUser(userId).subscribe(
      res => {
        this.getUsers();
      },
      err => {
        this.translate.get('delete-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  changeUserActivity(user: User) {
    this.userService.changeActivity(user.id).subscribe(
      res => {
        this.getUsers();
        this.translate.get('change-activity').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.translate.get('change-activity-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  getCurrentDesk(userId: string) {
    let desk = this.desks.find(x => x.currentLoggedInUserId == userId);
    if (desk) {
      return desk.fullName;
    }
    else {
      return this.emptyDesk;
    }
  }
  getCurrentLoggedLocationTagInDesk(userId: string) {
    let desk = this.desks.find(x => x.currentLoggedInUserId == userId);
    if (!desk) {
      return this.emptyLocation;
    }
    let location = this.locations.find(x => x.id == desk.locationId);
    if (location) {
      return location.locationName + " " + location.locationTag;
    }
    else {
      return this.emptyLocation;
    }
  }
  dialogRef: any;
  public openRoleDetails(roles: QcloudRole[]) {
    var matDialogConfig = new MatDialogConfig();
    matDialogConfig.maxHeight = 900;
    matDialogConfig.maxWidth = 1800;
    matDialogConfig.minHeight = 100;
    matDialogConfig.minWidth = 800;

    var detailsPopupModalData = new DetailsPopupModalData();
    this.translate.get('qcloud.assigned-roles').subscribe(
      res => detailsPopupModalData.title = res
    );

    detailsPopupModalData.detailsStructureTemplateGroups = [];
    for (var i = 0; i < roles.length; i++) {
      var role = roles[i];
      var detailsStructureTemplateGroup = new DetailsStructureTemplateGroup();
      detailsStructureTemplateGroup.groupName = role.roleName;
      detailsStructureTemplateGroup.subGroups.push(this.getDetailsStructureTemplateGroupLocationInfo(role.locationIds));
      detailsStructureTemplateGroup.subGroups.push(this.getDetailsStructureTemplateGroupCategoryInfo(role.categories));

      detailsPopupModalData.detailsStructureTemplateGroups.push(detailsStructureTemplateGroup);
    }

    matDialogConfig.data = detailsPopupModalData;
    this.dialogRef = this.modalService.open(DetailsModalComponent, matDialogConfig);
    this.dialogRef.afterClosed().pipe(
      finalize(() => {
        this.dialogRef = undefined;
      })
    );
  }

  getDetailsStructureTemplateGroupCategoryInfo(categories: Category[]): DetailsStructureTemplateGroup {
    var detailsStructureTemplateGroupCategory = new DetailsStructureTemplateGroup();
    this.translate.get('qcloud.assigned-categories').subscribe(
      res => detailsStructureTemplateGroupCategory.groupName = res
    );
    detailsStructureTemplateGroupCategory.detailsStructureTemplate = this.getDetailsStructureTemplateCategoryInfo(categories);
    return detailsStructureTemplateGroupCategory;
  }
  getDetailsStructureTemplateCategoryInfo(categories: Category[]): DetailsStructureTemplate {
    var detailsStructureTemplate = new DetailsStructureTemplate();
    this.translate.get('category-name').subscribe(
      res => detailsStructureTemplate.columnNames.push(res)
    );
    this.translate.get('category-tag').subscribe(
      res => detailsStructureTemplate.columnNames.push(res)
    );

    categories.forEach(category => {
      var row: string[] = [];
      row.push(category.fullName);
      row.push(category.categoryTag);
      detailsStructureTemplate.rows.push(row);
    });

    return detailsStructureTemplate;
  }
  getDetailsStructureTemplateGroupLocationInfo(locationIds: number[]): DetailsStructureTemplateGroup {
    var detailsStructureTemplateGroupCategory = new DetailsStructureTemplateGroup();
    this.translate.get('qcloud.assigned-location').subscribe(
      res => detailsStructureTemplateGroupCategory.groupName = res
    );
    detailsStructureTemplateGroupCategory.detailsStructureTemplate = this.getDetailsStructureTemplateLocationInfo(locationIds);
    return detailsStructureTemplateGroupCategory;
  }

  getDetailsStructureTemplateLocationInfo(locationIds: number[]): DetailsStructureTemplate {
    var detailsStructureTemplate = new DetailsStructureTemplate();
    this.translate.get('location-name').subscribe(
      res => detailsStructureTemplate.columnNames.push(res)
    );
    this.translate.get('location-tag').subscribe(
      res => detailsStructureTemplate.columnNames.push(res)
    );

    locationIds.forEach(locId => {
      var row: string[] = [];
      var loc = this.locations.find(x => x.id == locId);
      row.push(loc.locationName);
      row.push(loc.locationTag);
      detailsStructureTemplate.rows.push(row);
    });

    return detailsStructureTemplate;
  }
  getDetailsStructureTemplateGroupQcloudRoleInfo(roles: QcloudRole[]): DetailsStructureTemplateGroup {
    var detailsStructureTemplateGroupCategory = new DetailsStructureTemplateGroup();

    this.translate.get('qcloud.assigned-roles').subscribe(
      res => detailsStructureTemplateGroupCategory.groupName = res
    );
    detailsStructureTemplateGroupCategory.detailsStructureTemplate = this.getDetailsStructureTemplateQcloudRoleInfo(roles);
    return detailsStructureTemplateGroupCategory;
  }

  getDetailsStructureTemplateQcloudRoleInfo(roles: QcloudRole[]): DetailsStructureTemplate {
    var detailsStructureTemplate = new DetailsStructureTemplate();
    this.translate.get('qcloud.role-name').subscribe(
      res => detailsStructureTemplate.columnNames.push(res)
    );
    this.translate.get('permission-level').subscribe(
      res => detailsStructureTemplate.columnNames.push(res)
    );

    roles.forEach(role => {
      var row: string[] = [];
      row.push(role.roleName);
      this.getRolePermissionLevelTranslate(role.rolePermissionLevel).subscribe(
        res => row.push(res)
      );
      detailsStructureTemplate.rows.push(row);
    });

    return detailsStructureTemplate;
  }

  getRolePermissionLevelTranslate(rolePermissionLevel: RolePermissionLevel) {
    var keyTranslatePermissionLevel = "";
    switch (rolePermissionLevel) {
      case RolePermissionLevel.SUPERADMIN:
        keyTranslatePermissionLevel = 'super-administrator';
        break;
      case RolePermissionLevel.ADMIN:
        keyTranslatePermissionLevel = 'administrator';
        break;
      case RolePermissionLevel.MANAGER:
        keyTranslatePermissionLevel = 'employee';
        break;
      case RolePermissionLevel.EMPLOYEE:
        keyTranslatePermissionLevel = 'manager';
        break;
    }

    return this.translate.get(keyTranslatePermissionLevel);
  }
}