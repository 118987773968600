import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SmsTags } from 'projects/qcloud-models/sms/sms-tags.enum';
import { SmsTemplates } from 'projects/qcloud-models/sms/sms-templates';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { SmsConfigService } from 'projects/qcloud-rest-client/src/lib/sms-config.service';

@Component({
  selector: 'app-sms-notifications',
  templateUrl: './sms-notifications.component.html',
  styleUrls: ['./sms-notifications.component.css']
})
export class SmsNotificationsComponent implements OnInit {

  smsConfig: SmsTemplates;
  SmsTags = SmsTags;
  formSubmitted = false;

  constructor(public locationService: LocationService, private smsService: SmsConfigService,
    private translate: TranslateService, private toastr: ToastrService, public authService: AuthService) { }

  ngOnInit(): void {
    this.getSmsConfig();
  }

  getSmsConfig() {
    this.smsService.getSmsConfig().subscribe(
      res => {
        this.smsConfig = res;
        this.checkSmsConfig();
      }
    );
  }

  checkSmsConfig() {
    if (!this.smsConfig.sendSmsOnReservation) this.smsConfig.sendSmsOnReservation = "";
    if (!this.smsConfig.sendSmsOnEditReservation) this.smsConfig.sendSmsOnEditReservation = "";
    if (!this.smsConfig.sendSmsOnDeleteReservation) this.smsConfig.sendSmsOnDeleteReservation = "";
    if (!this.smsConfig.sendSmsOnRemindReservation) this.smsConfig.sendSmsOnRemindReservation = "";
    if (!this.smsConfig.sendSmsOnPrint) this.smsConfig.sendSmsOnPrint = "";
    if (!this.smsConfig.sendSmsOnBeforeCall) this.smsConfig.sendSmsOnBeforeCall = "";
    if (!this.smsConfig.sendSmsOnTicketCall) this.smsConfig.sendSmsOnTicketCall = "";
  }

  onSubmit() {
    this.formSubmitted = true;
    this.smsService.setSmsConfig(this.smsConfig).subscribe(
      res => {
        this.formSubmitted = false;
        this.translate.get('update-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('update-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  updateSendSmsOnReservation(text: string) {
    this.smsConfig.sendSmsOnReservation += text;
  }

  updateSendSmsOnEditReservation(text: string) {
    this.smsConfig.sendSmsOnEditReservation += text;
  }

  updateSendSmsOnDeleteReservation(text: string) {
    this.smsConfig.sendSmsOnDeleteReservation += text;
  }

  updateSendSmsOnRemindReservation(text: string) {
    this.smsConfig.sendSmsOnRemindReservation += text;
  }

  updateSendSmsOnPrint(text: string) {
    this.smsConfig.sendSmsOnPrint += text;
  }

  updateSendSmsOnBeforeCall(text: string) {
    this.smsConfig.sendSmsOnBeforeCall += text;
  }

  updateSendSmsOnCall(text: string) {
    this.smsConfig.sendSmsOnTicketCall += text;
  }

}
