import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { QcloudConsoleService } from '../../qcloud-console.service';
import { TicketNoteService } from '../../../../../qcloud-rest-client/src/lib/note/ticket-note.service';

@Component({
  selector: 'lib-note-ticket-button',
  templateUrl: './note-ticket-button.component.html',
  styleUrls: ['./note-ticket-button.component.css', './../base-button/base-button.component.css']
})
export class NoteTicketButtonComponent implements OnInit {
  @ViewChild('button') public button: ElementRef;
  isEnabled: boolean;

  constructor(private consoleService: QcloudConsoleService, private ticketNoteService: TicketNoteService) {
    this.consoleService.ticket.subscribe((val) => {
      if (val.id != 0 && val.id != -1) {
        this.isEnabled = true;
      }
      else {
        this.isEnabled = false;
      }
    })
  }

  ngOnInit(): void {
  }

  public noteTicket() {
    this.consoleService.ticket.subscribe((val) => {
      var isNoteSettedAlready = false
      if (val.id != -1) {
        this.ticketNoteService.isNoteSettedToTicket(val.id,
          (result) => {
            isNoteSettedAlready = result;
            var isNotePopupOpen = this.consoleService.isNotePopupOpen();
            if (this.isEnabled && !isNoteSettedAlready && !isNotePopupOpen) {
              this.consoleService.openNotePopup();
            }
          },
          () => { });
      }
    }
    );
  }

}