import { Component, OnInit, Input } from '@angular/core';
import { Device } from 'projects/qcloud-models/device/device.model';
import { DeviceType } from 'projects/qcloud-models/device/device-type.enum';
import { LayoutType } from 'projects/qcloud-models/device/layout-type.enum';
import { Tag } from 'projects/qcloud-models/multimedia-device/tag.model';
import { UntypedFormControl } from '@angular/forms';
import { MultimediaService } from 'projects/qcloud-rest-client/src/lib/multimedia.service';
import { TagDevice } from 'projects/qcloud-models/multimedia-device/tag-device.model';
import { Schedule } from 'projects/qcloud-models/multimedia/schedule.model';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-devices-table',
  templateUrl: './devices-table.component.html',
  styleUrls: ['./devices-table.component.css']
})
export class DevicesTableComponent implements OnInit {

  @Input() ctx: any;
  @Input() multimediaDevices: Device[];
  DeviceType = DeviceType;
  LayoutType = LayoutType;
  @Input() tagsByDevice: { [key: string]: Tag[]; } = {};
  currentDevice: Device;
  deviceSchedule: Map<string, Schedule> = new Map<string, Schedule>();

  @Input() tags: Tag[];
  protected initialTags: Tag[];
  public tagMultiCtrl: UntypedFormControl;
  title: string = "assign-tags";

  constructor(private service: MultimediaService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.multimediaDevices.forEach(multimediaDevice => {
      this.getDeviceSchedule(multimediaDevice.guid);
    });
  }

  getTags() {
    this.ctx.getTags();
  }

  setDeviceTags(device: Device) {
    this.currentDevice = device;
    this.initialTags = this.tagsByDevice[device.guid];
    this.tagMultiCtrl = new UntypedFormControl(this.initialTags);
  }

  assignTags() {
    if (this.initialTags) {
      this.removeTagsFromDevice();
      this.addTagsToDevice();
    } else {
      for (let i = 0; i < this.tagMultiCtrl.value.length; i++) {
        let deviceTag = new TagDevice(this.currentDevice.guid, this.tagMultiCtrl.value[i]);
        this.service.addTagToDevice(deviceTag).subscribe(
          res => {
            this.getTags();
            this.ctx.getTagsByDevice();
          }
        );
      }
    }
  }

  removeTagsFromDevice() {
    for (let i = 0; i < this.initialTags.length; i++) {
      if (!this.tagMultiCtrl.value.some(tag => tag.id == this.initialTags[i].id)) {
        let deviceTag = new TagDevice(this.currentDevice.guid, this.initialTags[i]);
        this.service.removeTagFromDevice(deviceTag).subscribe(
          res => {
            this.getTags();
            this.ctx.getTagsByDevice();
          }
        );
      }
    }
  }

  addTagsToDevice() {
    for (let i = 0; i < this.tagMultiCtrl.value.length; i++) {
      if (!this.initialTags.some(tag => tag.id == this.tagMultiCtrl.value[i].id)) {
        let deviceTag = new TagDevice(this.currentDevice.guid, this.tagMultiCtrl.value[i]);
        this.service.addTagToDevice(deviceTag).subscribe(
          res => {
            this.getTags();
            this.ctx.getTagsByDevice();
          }
        );
      }
    }
  }

  getDeviceSchedule(deviceGuid: string) {
    this.service.getDeviceSchedule(deviceGuid).subscribe(
      schedule => {
        this.deviceSchedule[deviceGuid] = schedule;
      }
    )
  }

  openModal(content) {
    this.dialog.open(content);
  }

}
