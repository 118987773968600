import { TicketState } from "./ticketState.enum";

export class Ticket {
  id: number;
  effectiveCategoryId: number;
  createdDate: string;
  ticketNumber: number;
  caption: string;
  printedCategoryId: number;
  conditionalTicketText: string;
  secret: string;
  deskId: number;
  ticketState: TicketState;
  locale: string;
  metadataJson: any;
  answer: string;
  isWcag: boolean;
  questionId: number;
  constructor() {
    this.id = -1;
  }
}
