import { LOCALE_ID, Inject, Injectable } from '@angular/core';
import { CalendarEventTitleFormatter, CalendarEvent } from 'angular-calendar';
import { DatePipe } from '@angular/common';
import { TicketReservation } from 'projects/qcloud-models/reservation/ticket-reservation';

@Injectable()
export class ReservationTitleFormatter extends CalendarEventTitleFormatter {
    constructor(@Inject(LOCALE_ID) private locale: string) {
        super();
    }

    month(event: CalendarEvent<TicketReservation>): string {
        return `<b>${new DatePipe(this.locale).transform(event.start, 'HH:mm')}</b>
    -
    <b>${new DatePipe(this.locale).transform(event.end, 'HH:mm')}</b>
    ${event.title}`;
    }

    week(event: CalendarEvent<TicketReservation>): string {
        let returnString = `<span class="font-weight-bold">${new DatePipe(this.locale).transform(event.start, 'HH:mm')}
        -
        ${new DatePipe(this.locale).transform(event.end, 'HH:mm')}
         ${event.title}</span>`;
        if (event.meta.id != 0) {
            event.meta.additionalReservationData.forEach(element => {
                if (element.identityQuestion) {
                    returnString += ` <span>${element.answer}</span>`
                }
            });
        }
        return returnString;
    }

    day(event: CalendarEvent<TicketReservation>): string {
        let returnString = `<span class="font-weight-bold">${new DatePipe(this.locale).transform(event.start, 'HH:mm')}
        -
        ${new DatePipe(this.locale).transform(event.end, 'HH:mm')}
         ${event.title}</span>`;
        if (event.meta.id != 0) {
            event.meta.additionalReservationData.forEach(element => {
                if (element.identityQuestion) {
                    returnString += ` <span>${element.answer}</span>`
                }
            });
        }
        return returnString;
    }
}