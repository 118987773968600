export enum SmsTags {
    LocationName = "#locationName#",
    LocationAddress = "#locationAddress#",
    Category = "#category#",
    Date = "#date#",
    Code = "#code#",
    PhoneNumber = "#phoneNumber#",
    QueueState = "#queueState#",
    TicketCaption = "#ticketCaption#",
    TicketPrintTime = "#ticketPrintTime#",
    PredictedWaitingTime = "#predictedWaitingTime#",
    DeskName = "#deskName#"
}