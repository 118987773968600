<fieldset ngModelGroup="subFormAnswers">
    <div *ngFor="let answer of answers" class="mt-2">
        <div *ngIf="checkVisible(answer)">
            <span *ngIf="answer.questionType != noteQuestionTypeEnum.Option
            && answer.questionType != noteQuestionTypeEnum.VisibleOption">
                {{ answer.question }}</span>
            <span class="text-danger" *ngIf="answer.isRequired && answer.questionType != noteQuestionTypeEnum.Option
            && answer.questionType != noteQuestionTypeEnum.VisibleOption">*</span>

            <div *ngIf="answer.questionType == noteQuestionTypeEnum.Text
            || answer.questionType == noteQuestionTypeEnum.FirstName
            || answer.questionType == noteQuestionTypeEnum.LastName
            || answer.questionType == noteQuestionTypeEnum.InvoiceNumber">
                <input class="form-control" [(ngModel)]="answer.answer" #text="ngModel"
                    name="text{{+answer.questionId}}" [disabled]="answer.isDisabled" [required]="answer.isRequired"
                    (input)="checkForUrls($event, answer.answer)">
                <div *ngIf="text.invalid && (text.dirty || text.touched)" class="text-danger">
                    <div *ngIf="text.errors.required">
                        {{ 'required' | translate }}
                    </div>
                </div>
                <div *ngIf="textInvalid" class="text-danger">
                    {{ 'invalid-text' | translate }}
                </div>
            </div>

            <!-- TODO: update personal Id validation -->
            <div *ngIf="answer.questionType == noteQuestionTypeEnum.PersonalId">
                <input class="form-control" [(ngModel)]="answer.answer" #personalID="ngModel" name="personalID{{+answer.questionId}}"
                    [disabled]="answer.isDisabled" [required]="answer.isRequired" pattern="^[0-9]{11}$">
                <div *ngIf="personalID.invalid && (personalID.dirty || personalID.touched)" class="text-danger">
                    <div *ngIf="personalID.errors.required">
                        {{ 'personal-id-required' | translate }}
                    </div>
                    <div *ngIf="personalID.errors.pattern">
                        {{ 'invalid-personal-id' | translate }}
                    </div>
                </div>
            </div>

            <div *ngIf="answer.questionType == noteQuestionTypeEnum.PersonalIdLastNumbers">
                <input class="form-control" [(ngModel)]="answer.answer" #personalIdLastNumbers="ngModel"
                    name="personalIdLastNumbers{{+answer.questionId}}" [disabled]="answer.isDisabled" [required]="answer.isRequired" pattern="^[0-9]{5}$" maxlength="5">
                <div *ngIf="personalIdLastNumbers.invalid && (personalIdLastNumbers.dirty || personalIdLastNumbers.touched)"
                    class="text-danger">
                    <div *ngIf="personalIdLastNumbers.errors.required">
                        {{ 'personal-id-required' | translate }}
                    </div>
                    <div *ngIf="personalIdLastNumbers.errors.pattern">
                        {{ 'invalid-personal-id' | translate }}
                    </div>
                </div>
            </div>

            <div *ngIf="answer.questionType == noteQuestionTypeEnum.Email">
                <input class="form-control" [(ngModel)]="answer.answer" #email="ngModel" name="email{{+answer.questionId}}"
                    [disabled]="answer.isDisabled" [required]="answer.isRequired" email>
                <div *ngIf="email.invalid && (email.dirty || email.touched)" class="text-danger">
                    <div *ngIf="email.errors.required">
                        {{ 'email-required' | translate }}
                    </div>
                    <div *ngIf="email.errors.email">
                        {{ 'invalid-email' | translate }}
                    </div>
                </div>
            </div>

            <div *ngIf="answer.questionType == noteQuestionTypeEnum.PhoneNumber">
                <input class="form-control" [(ngModel)]="answer.answer" #phone="ngModel" name="phone{{+answer.questionId}}"
                    [disabled]="answer.isDisabled" [required]="answer.isRequired" minlength="7" maxlength="17"
                    pattern="^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$">
                <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="text-danger">
                    <div *ngIf="phone.errors.required">
                        {{ 'phone-number-required' | translate }}
                    </div>
                    <div *ngIf="phone.errors.pattern || phone.errors.minlength">
                        {{ 'invalid-phone-number' | translate }}
                    </div>
                </div>
            </div>

            <div *ngIf="answer.questionType == noteQuestionTypeEnum.TextArea">
                <textarea maxlength="700" class="form-control" aria-label="With textarea" name="descriptionTextArea{{+answer.questionId}}"
                    #noteTextArea="ngModel" [(ngModel)]="answer.answer" [disabled]="answer.isDisabled" [required]="answer.isRequired"></textarea>
                <div *ngIf="noteTextArea.invalid && (noteTextArea.dirty || noteTextArea.touched)"
                    class="text-danger mx-2 my-1">
                    <div *ngIf="noteTextArea.errors.required">{{ 'description-required' | translate }}</div>
                </div>
            </div>
            <div *ngIf="answer.questionType == noteQuestionTypeEnum.TextAreaAndCheckBox">
                <textarea maxlength="700" class="form-control" aria-label="With textarea" name="descriptionTextArea{{+answer.questionId}}"
                    #noteTextArea="ngModel" [(ngModel)]="answer.answer" [disabled]="answer.isDisabled || isMainComponentDisabled" [required]="answer.isRequired"></textarea>
                <div *ngIf="noteTextArea.invalid && (noteTextArea.dirty || noteTextArea.touched)"
                    class="text-danger mx-2 my-1">
                    <div *ngIf="noteTextArea.errors.required">{{ 'description-required' | translate }}</div>
                </div>
                <input class="checkbox-style" type="checkbox" name="checkboxIsDisabled" *ngIf="!answer.isDisabled" [(ngModel)]="isMainComponentDisabled">
                <span *ngIf="!answer.isDisabled">{{'is-textarea-disabled' | translate }}</span>
            </div>

            <div *ngIf="answer.questionType == noteQuestionTypeEnum.VIN">
                <input class="form-control" [(ngModel)]="answer.answer" #vin="ngModel" name="vin{{+answer.questionId}}"
                    [disabled]="answer.isDisabled" [required]="answer.isRequired" minlength="17" maxlength="17">
                <div *ngIf="vin.invalid && (vin.dirty || vin.touched)" class="text-danger">
                    <div *ngIf="vin.errors.required">
                        {{ 'vin-required' | translate }}
                    </div>
                    <div *ngIf="vin.errors.minlength">
                        {{ 'invalid-vin' | translate }}
                    </div>
                </div>
            </div>

            <div *ngIf="answer.questionType == noteQuestionTypeEnum.Option">
                <div class="my-2">
                    <span class="text-danger" *ngIf="answer.isRequired">*</span>
                    <mat-radio-group color="primary" [(ngModel)]="answer.answer" #chooseOption="ngModel"
                        [disabled]="answer.isDisabled" [required]="answer.isRequired" name="chooseOption{{+answer.questionId}}">
                        <mat-radio-button class="radio-button-inner-color" [value]="FIRST_OPTION">{{ answer.question.split(';_;')[0] }}
                        </mat-radio-button>
                        <mat-radio-button class="radio-button-inner-color" [value]="SECOND_OPTION">{{ answer.question.split(';_;')[1] }}
                        </mat-radio-button>
                    </mat-radio-group>
                    <div *ngIf="chooseOption.invalid && (chooseOption.dirty || chooseOption.touched)"
                        class="text-danger">
                        <div *ngIf="chooseOption.errors.required">
                            {{ 'chooseOption-required' | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div *ngIf="checkVisibleForCategory(answer) && answer.questionType == noteQuestionTypeEnum.VisibleOption"> -->
        <div *ngIf="answer.questionType == noteQuestionTypeEnum.VisibleOption">
            <div class="my-2">
                <span class="text-danger" *ngIf="answer.isRequired">*</span>
                <mat-radio-group color="primary" [(ngModel)]="answer.answer" #chooseVisibleOption="ngModel"
                    [disabled]="answer.isDisabled" [required]="answer.isRequired" name="chooseVisibleOption{{+answer.questionId}}">
                    <mat-radio-button [value]="FIRST_OPTION">{{ answer.question.split(';')[0] }}
                    </mat-radio-button>
                    <mat-radio-button [value]="SECOND_OPTION">{{ answer.question.split(';')[1] }}
                    </mat-radio-button>
                </mat-radio-group>
                <div *ngIf="chooseVisibleOption.invalid && (chooseVisibleOption.dirty || chooseVisibleOption.touched)"
                    class="text-danger">
                    <div *ngIf="chooseVisibleOption.errors.required">
                        {{ 'chooseOption-required' | translate }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</fieldset>