export class ReservationButtonTextTranslation {
    public id: number;
    public translationValue: string;
    public locationId: number;
    public deviceId: number;
    public translationId: number;

    constructor(locationId: number, deviceId: number, translationId: number) {
        this.translationValue = '';
        this.locationId = locationId;
        this.deviceId = deviceId;
        this.translationId = translationId;
    }
}