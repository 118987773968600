<div class="card shadow mb-4">
    <mat-accordion>
        <mat-expansion-panel (opened)="isCollapsed = false"
                             (closed)="isCollapsed = true"
                             hideToggle="true"
                             [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
                <h5 class="col-10 m-2 font-weight-bold text-primary">{{ 'group-translation' | translate }}</h5>
            </mat-panel-title>
            <mat-panel-description>
                    <button type="button" class="btn btn-primary btn-circle btn-sm" (click)="isCollapsed = !isCollapsed"
                        [attr.aria-expanded]="!isCollapsed" aria-controls="collapseCategory"
                        [ngClass]="{'clicked': isCollapsed}">
                        <i class="fas fa-angle-down"></i>
                    </button>
            </mat-panel-description>
          </mat-expansion-panel-header>
    <div>
        <div *ngIf="deviceGroupTranslations && deviceGroupNames && devices" class="card-body">
            <div class="text-right">
                <app-pagination [translations]="translations"
                    (filteredTranslationsEmitter)="getFilteredTranslations($event)">
                </app-pagination>
            </div>
            <div class="row">
                <div class="col-4">

                    <p class="font-weight-bold">{{ getLocaleName(locationLocaleMain) | translate }}</p>
                    <p *ngIf="deviceGroupNames.length == 0">{{ 'no-devices' | translate }}</p>

                    <div *ngFor="let deviceGroupName of deviceGroupNames">

                        <p>
                            {{ deviceGroupName.deviceName }}
                            <span *ngIf="deviceGroupName.names.length == 0" class="badge badge-danger">
                                {{ 'no-groups' | translate }}
                            </span>
                        </p>

                        <div *ngFor="let name of deviceGroupName.names">
                            <div class="form-group">
                                <div class="input-group">
                                    <input disabled [value]="name" class="form-control">
                                </div>
                            </div>
                        </div>

                        <hr>
                    </div>

                </div>
                <div class="col-4" *ngFor="let translation of deleteLocaleMain(isOnPage()); let i = index">
                    <form *ngIf="deviceGroupTranslations[startIndex + i]" #form="ngForm" autocomplete="off">

                        <p class="font-weight-bold">{{ getLocaleName(translation.locale) | translate }}</p>

                        <div *ngFor="let deviceGroupTranslation of deviceGroupTranslations[startIndex + i]; let j = index">
                            <div *ngIf="deviceGroupTranslation">

                                <p>
                                    {{ deviceGroupNames[j].deviceName }}
                                    <span *ngIf="deviceGroupTranslation.translations.length == 0" class="badge badge-danger">
                                        {{ 'no-groups' | translate }}
                                    </span>
                                </p>

                                <div *ngFor="let translation of deviceGroupTranslation.translations; let k = index">

                                    <div class="form-group">
                                        <div class="input-group">
                                            <input [name]="deviceGroupNames[j].names[k]" #name="ngModel"
                                                [(ngModel)]="translation.translation" class="form-control">
                                        </div>
                                    </div>

                                </div>

                                <hr>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
            <div class="form-group row">
                <app-submit-button (click)="onSubmit()" type="submit" [loading]="formSubmitted"
                    [text]="'save-group-translations' | translate">
                </app-submit-button>
            </div>
        </div>
    </div>
</mat-expansion-panel>
</mat-accordion>
</div>
