import { ActivityTime } from "../../activity-time";
import { Office } from "../office/office.model";
import { Employee } from "./employee.model";

export class EmployeeWorkingTime {
    public id: number;
    public office: Office;
    public day: number;
    public timeStart: any;
    public timeEnd: any;
    public is24Hours: any;
    public isActive: any;
    public isButtonAlwaysVisible:any;
    public worker: Employee;

    constructor(blt: ActivityTime) {
        this.id = blt.id;
        this.day = blt.day;
        this.timeStart = blt.unblockingTime;
        this.timeEnd = blt.blockingTime;
        this.is24Hours = blt.is24Hours;
        this.isActive = blt.isActive;
        this.isButtonAlwaysVisible = blt.isButtonAlwaysVisible;
    }
}