import { Component, OnInit } from '@angular/core';
import { Category } from 'projects/qcloud-models/category/category.model';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.css']
})
export class ReservationComponent implements OnInit {

  categories: Category[];
  choosenCategory: Category;
  constructor(private categoryService: CategoryService, public locationService: LocationService,
    public authService: AuthService) { }

  ngOnInit(): void {
    this.locationService.getLocationData(this.authService.getLocationId()).subscribe(
      locData => this.locationService.locationData = locData
    );
    this.categoryService.getCategories((data) => {
      this.categories = data.filter(x => x.canBeReserved);
      this.choosenCategory = this.categories[0];
    }, () => { });
  }

}
