import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { QcloudConsoleService } from '../../qcloud-console.service';

@Component({
  selector: 'lib-accept-ticket-button',
  templateUrl: './accept-ticket-button.component.html',
  styleUrls: ['./accept-ticket-button.component.css',  './../base-button/base-button.component.css']
})
export class AcceptTicketButtonComponent  implements OnInit {
  @Input() public tooltipTitle: string;
	@ViewChild('button') public button: ElementRef;
  @ViewChild('icon') public icon: ElementRef;
	isEnabled: boolean;
  isBlocked: boolean;
  isLocked: boolean;
  constructor(public cd: ChangeDetectorRef, private consoleService: QcloudConsoleService) {
    var context = this;
    this.consoleService.ticket.subscribe((val) => {
      if (val.id != 0 && val.id != -1) {
        context.isEnabled = true;
      }
      else {
        context.isEnabled = false;
      }
      this.consoleService.isBlocked.subscribe((val) => {
        this.isBlocked = val;
      });
      this.consoleService.isLocked.subscribe((val) => {
        this.isLocked = val;
      });
      context.cd.detectChanges();
    })
  }

  ngOnInit(): void {
  }

  public acceptTicket() {
    this.consoleService.acceptTicket();
  }
}
