import { Component, OnInit } from '@angular/core';
import { Office } from 'projects/qcloud-models/grafik/office/office.model';
import { OfficeService } from 'projects/qcloud-rest-client/src/lib/grafik/office.service';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { RouteNavigationService } from 'projects/qcloud-web/src/app/shared/route-navigation.service';

@Component({
  selector: 'app-office',
  templateUrl: './office.component.html',
  styleUrls: ['./office.component.css']
})
export class OfficeComponent implements OnInit {

  office: Office;
  isEditForm: boolean;
  formSubmitted: boolean = false;


  constructor(private authService: AuthService, private translate: TranslateService, private toastr: ToastrService,
    private officeService: OfficeService, public router: RouteNavigationService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    var officeId: number;
    this.route.params.subscribe(params => {
      officeId = params['id']
    });
    if (officeId) {
      this.isEditForm = true;
      this.getOffice(officeId);
    } else {
      this.isEditForm = false;
      this.initializeOffice();
    }
  }

  initializeOffice() {
    this.office = new Office();
    this.office.locationId = +this.authService.getLocationId();
    this.office.systemId = +this.authService.getSystemId();
  }

  getOffice(officeId: number) {
    this.officeService.getOffice(officeId).subscribe(office => {
      this.office = office;
    })
  }


  addOffice() {
    this.office.id = 0;
    this.officeService.addOffice(this.office).subscribe(
      res => {
        this.router.navigate(['workSchedule']);
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('category-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  onEnterPress(event, isFormInvalid) {
    event.preventDefault();
    if (!isFormInvalid) {
      this.onSubmit();
    }
  }

  onSubmit() {
    this.formSubmitted = true;
    if (this.isEditForm) {
      this.editOffice();
    } else {
      this.addOffice();
    }
  }

  editOffice() {
    this.officeService.editOffice(this.office).subscribe(
      res => {
        this.router.navigate(['workSchedule']);
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('category-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }
}
