import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { QcloudRole } from "../../../../../qcloud-models/qcloud-role/qcloud-role";
import { QcloudRoleService } from "../../../../../qcloud-rest-client/src/lib/role/qcloud-role.service";
import { RolePermissionLevel } from "../../../../../qcloud-models/qcloud-role/role-permission-level";

@Component({
  selector: 'app-qcloud-roles',
  templateUrl: './qcloud-roles.component.html',
  styleUrls: ['./qcloud-roles.component.css']
})
export class QcloudRolesComponent implements OnInit {

  qcloudRoles: QcloudRole[];
  selectedUserId: string;
  selectedLdapId: number;
  rolePermissionLevelEnum = RolePermissionLevel;
  constructor(private qcloudRoleService: QcloudRoleService,
    private translate: TranslateService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getQcloudRoles();
  }

  getQcloudRoles() {
    this.qcloudRoleService.getQcloudRoles()
      .subscribe(data => {
        this.qcloudRoles = data;
      });
  }

  onDeleteConfirm(confirmed: boolean) {
    if (confirmed) {
      this.deleteQcloudRole(this.selectedLdapId);
    }
  }

  deleteQcloudRole(ldapRoleId: number) {
    this.qcloudRoleService.deleteQcloudRole(ldapRoleId).subscribe({
      next: res => {
        this.getQcloudRoles();
      },
      error: err => {
        this.translate.get('delete-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    });
  }

}