<div class="card-header py-3">
    <h6 class="m-0 font-weight-bold text-primary">{{ 'ticket-in-desk-stats' | translate}}</h6>
</div>
<div class="card-body">
    <mat-form-field>
        <mat-label>{{ 'desk' | translate }}</mat-label>
        <mat-select matNativeControl [(ngModel)]="selectedDeskId" (selectionChange)="changeDesk()">
            <mat-option *ngFor="let desk of desks" [value]="desk.id">
                {{ desk.fullName }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div *ngIf="processedDeskTicketsCounts[selectedDeskId]">
        <h4 class="small font-weight-bold">{{ 'accepted' | translate }}
            <span class="float-right">{{ processedDeskTicketsCounts[selectedDeskId].acceptedTicketsCount }}</span>
        </h4>
        <div class="progress mb-4">
            <div class="progress-bar bg-success" role="progressbar" aria-valuemin="0" aria-valuemax="maxTickets"
                aria-valuenow="processedDeskTicketsCounts[selectedDeskId].acceptedTicketsCount"
                [style.width]="(processedDeskTicketsCounts[selectedDeskId].acceptedTicketsCount/maxTickets)*100 + '%'">
            </div>
        </div>
        <h4 class="small font-weight-bold">{{ 'redirected' | translate }}
            <span class="float-right">{{ processedDeskTicketsCounts[selectedDeskId].redirectedTicketsCount }}</span>
        </h4>
        <div class="progress mb-4">
            <div class="progress-bar bg-primary" role="progressbar" aria-valuemin="0" aria-valuemax="maxTickets"
                aria-valuenow="processedDeskTicketsCounts[selectedDeskId].redirectedTicketsCount"
                [style.width]="(processedDeskTicketsCounts[selectedDeskId].redirectedTicketsCount/maxTickets)*100 + '%'">
            </div>
        </div>
        <h4 class="small font-weight-bold">{{ 'cancelled' | translate }}
            <span class="float-right">{{ processedDeskTicketsCounts[selectedDeskId].cancelledTicketsCount }}</span>
        </h4>
        <div class="progress mb-4">
            <div class="progress-bar bg-danger" role="progressbar" aria-valuemin="0" aria-valuemax="maxTickets"
                aria-valuenow="processedDeskTicketsCounts[selectedDeskId].cancelledTicketsCount"
                [style.width]="(processedDeskTicketsCounts[selectedDeskId].cancelledTicketsCount/maxTickets)*100 + '%'">
            </div>
        </div>
    </div>
    <div *ngIf="!processedDeskTicketsCounts[selectedDeskId]">
        {{ 'no-tickets' | translate }}
    </div>
</div>