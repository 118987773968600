import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { FileDTO } from 'projects/qcloud-models/fileManager/file-dto.model';

@Injectable({
  providedIn: 'root'
})
export class GrafikFileService {

  appBase: string;

  public setAppBase(appBase: string){
    this.appBase = appBase;
  }

  public getUploadAddress() {
    return `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/files/upload`;
  }

  constructor(private http: HttpClient, private authService: AuthService) { }

  getDirectoryContent(path: string) : Observable<FileDTO[]> {
    return this.http.post<FileDTO[]>(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/files`, {path: path});
  }

  deleteSelected(list: FileDTO[]) {
    return this.http.post(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/files/delete`, list);
  };

  getPathToImage(filePath: string) {
    return `${this.appBase}cdn${filePath}`;
  }

  getExtension(filePath: string): string {
    return filePath.substring(filePath.lastIndexOf('.'), filePath.length);
  }

  isImage(filePath: string): boolean {
    if (filePath) {
      let extension = this.getExtension(filePath);
      switch (extension.toLowerCase()) {
        case '.jpg':
        case '.gif':
        case '.bmp':
        case '.png':
          return true;
      }
    }
    return false;
  }

  isFail(name: string): boolean {
    return ".fail" == this.getExtension(name);
  }

  isProgress(name: string): boolean {
    return ".inprogress" == this.getExtension(name);
  }

  checkImageStatus(url: string) {
    return this.http.get(url);
  }
}
