import { GroupTranslation } from './group-translation.model';

export class DeviceGroupTranslation {
    public id: number;
    public locationId: number;
    public deviceId: number;
    public translationId: number;
    public translations: GroupTranslation[];
    public translationsJson: string;

    constructor(locationId: number, deviceId: number, translationId: number) {
        this.locationId = locationId;
        this.deviceId = deviceId;
        this.translationId = translationId;
        this.translations = new Array<GroupTranslation>();
    }
}