import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { Subject, ReplaySubject } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { QcloudRole } from '../../../../../qcloud-models/qcloud-role/qcloud-role';
import { LocationDataDto } from '../../../../../qcloud-models/location/dto/location-dto.model';
import { RolePermissionLevel } from '../../../../../qcloud-models/qcloud-role/role-permission-level';
import { AuthService } from '../../../../../qcloud-rest-client/src/lib/auth/auth.service';

@Component({
  selector: 'app-qcloud-roles-assign',
  templateUrl: './qcloud-roles-assign.component.html',
  styleUrls: ['./qcloud-roles-assign.component.css']
})
export class QcloudRolesAssignComponent implements OnInit {

  @Input() qcloudRoles: QcloudRole[];
  @Input() qcloudRolesMultiCtrl: UntypedFormControl;
  public qcloudRoleMultiFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredQcloudRoleMulti: ReplaySubject<QcloudRole[]> = new ReplaySubject<QcloudRole[]>(1);

  protected _onDestroy = new Subject<void>();

  @Input() title: string;
  @Input() noSelectedQcloudRoles: string;
  @Input() displayQcloudRoles: boolean;
  @Input() enabledByLocationIds: number[];
  @Input() isSystemRolesList: boolean;
  @Output() qcloudRoleToEmit = new EventEmitter<QcloudRole[]>();
  inDatabaseRoleIds: number[] = [];

  constructor(public authService: AuthService) { }

  ngOnInit(): void {

    this.inDatabaseRoleIds = this.qcloudRoles.map(r => r.id);
    // load the initial category list
    this.filteredQcloudRoleMulti.next(this.qcloudRoles.slice());
    // listen for search field value changes
    this.qcloudRoleMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterQcloudRolesMulti();
      });
  }

  emitQcloudRoles(qcloudRoles: QcloudRole[]) {
    this.qcloudRoleToEmit.emit(qcloudRoles);
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredQcloudRoleMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          var filteredRoles = val.map(r => {
            if (!this.isRoleDisabled(r) || ( this.isRoleDisabled(r) && this.inDatabaseRoleIds.some(rId => rId == r.id))) {
              return r;
            }
          }
          );
          this.qcloudRolesMultiCtrl.patchValue(filteredRoles);
        } else {
          var filteredRoles = val.map(r => {
            if (this.isRoleDisabled(r)) {
              return r;
            }
          }
          );
          this.qcloudRolesMultiCtrl.patchValue(filteredRoles);
        }
        this.qcloudRoleToEmit.emit(this.qcloudRolesMultiCtrl.value);
      });
  }

  protected filterQcloudRolesMulti() {
    if (!this.qcloudRoles) {
      return;
    }
    // get the search keyword
    let search = this.qcloudRoleMultiFilterCtrl.value;
    if (!search) {
      this.filteredQcloudRoleMulti.next(this.qcloudRoles.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the Categories
    this.filteredQcloudRoleMulti.next(
      this.qcloudRoles.filter(qcloudRole => qcloudRole.roleName.toLowerCase().indexOf(search) > -1)
    );
  }

  isEnabledByLocationId(locations: LocationDataDto[]): boolean {
    var res = false;
    if (locations.length == 0 || (!this.isSystemRolesList && locations.length > 1)) {
      return res;
    }
    for (var i = 0; i < locations.length; i++) {
      res = res || this.enabledByLocationIds.some(locId => locId == locations[i].id);
    }
    return res;
  }
  isEnabledByPermissionLevel(permissionLevel: RolePermissionLevel): boolean {
    if (this.isSuperAdminOrLower(permissionLevel) && this.authService.isSuperAdmin()) {
      return true;
    }
    if (this.isAdminOrLower(permissionLevel) && this.authService.isAdminOrHigher()) {
      return true;
    }
    if (this.isManagerOrLower(permissionLevel) && this.authService.isManagerOrHigher()) {
      return true;
    }
    if (this.isEmployeeOrLower(permissionLevel) && this.authService.isEmployeeOrHigher()) {
      return true;
    }
    return false;
  }

  isSuperAdminOrLower(permissionLevel: RolePermissionLevel): boolean {
    return permissionLevel == RolePermissionLevel.EMPLOYEE || permissionLevel == RolePermissionLevel.MANAGER || permissionLevel == RolePermissionLevel.ADMIN || permissionLevel == RolePermissionLevel.SUPERADMIN;
  }
  isAdminOrLower(permissionLevel: RolePermissionLevel): boolean {
    return permissionLevel == RolePermissionLevel.EMPLOYEE || permissionLevel == RolePermissionLevel.MANAGER || permissionLevel == RolePermissionLevel.ADMIN;
  }
  isManagerOrLower(permissionLevel: RolePermissionLevel): boolean {
    return permissionLevel == RolePermissionLevel.EMPLOYEE || permissionLevel == RolePermissionLevel.MANAGER;
  }
  isEmployeeOrLower(permissionLevel: RolePermissionLevel): boolean {
    return permissionLevel == RolePermissionLevel.EMPLOYEE;
  }

  isRoleDisabled(qcloudRole: QcloudRole) {
    var isDisabled1 = !this.isEnabledByLocationId(qcloudRole.locations);
    var isDisabled2 = !this.isEnabledByPermissionLevel(qcloudRole.rolePermissionLevel);
    var isDisabled = isDisabled1 || isDisabled2;
    return isDisabled;
  }

}
