<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
    <h6 class="m-0 font-weight-bold text-primary">{{ 'daily-ticket-statistics' | translate }}</h6>
</div>
<div class="card-body">
    <div class="chart-pie pt-4 pb-2">
        <canvas id="ticketResultPieChart"></canvas>
    </div>
    <div class="mt-4 text-center small">
        <span class="mr-2 no-wrap">
            <i class="fas fa-circle text-success"></i> {{ 'accepted' | translate }}
        </span>
        <span class="mr-2 no-wrap">
            <i class="fas fa-circle text-danger"></i> {{ 'cancelled' | translate}}
        </span>
        <span class="no-wrap">
            <i class="fas fa-circle text-primary"></i> {{ 'redirected' | translate}}
        </span>
    </div>
</div>
