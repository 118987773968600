import { Component, Input, OnInit } from '@angular/core';
import { Category } from 'projects/qcloud-models/category/category.model';
import { ReservationQuestionAnswer } from 'projects/qcloud-models/reservation/reservation-question-answer';
import { ReservationQuestionType } from 'projects/qcloud-models/reservation/reservation-question-type.enum';

@Component({
  selector: 'app-reservation-modal-questions',
  templateUrl: './reservation-modal-questions.component.html',
  styleUrls: ['./reservation-modal-questions.component.css']
})
export class ReservationModalQuestionsComponent implements OnInit {
  @Input() answers: ReservationQuestionAnswer[];
  @Input() choosenCategory: Category;
  ReservationQuestionType = ReservationQuestionType;
  containsLinkRegex = new RegExp("([a-zA-Z\d]+[.][A-Za-z]{2,4})");
  textInvalid = false;
  FIRST_OPTION = '1';
  SECOND_OPTION = '2';

  ngOnInit(): void {
    this.answers.forEach(answer => {
      if (answer.questionType == ReservationQuestionType.Option ||
          answer.questionType == ReservationQuestionType.VisibleOption) {
        if (!answer.answer) {
          answer.answer = this.FIRST_OPTION;
        }
      }
    });
  }

  checkVisible(answer: ReservationQuestionAnswer): boolean {
    return (answer.visible ||
      this.answers.find(x => x.questionType == ReservationQuestionType.VisibleOption).answer == '2') &&
      this.checkVisibleForCategory(answer);
  }

  checkVisibleForCategory(answer: ReservationQuestionAnswer): boolean {
    return !answer.hiddenCategories.some(x => x.id == this.choosenCategory.id);
  }

  checkForUrls(event: InputEvent, answer: string) {
    if (this.containsLinkRegex.test(answer)) {
      this.textInvalid = true;
    }
    else {
      this.textInvalid = false;
    }
  }
}
