import { Component, OnInit } from '@angular/core';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { DeskService } from 'projects/qcloud-rest-client/src/lib/desk.service';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';

@Component({
  selector: 'app-customer-survey-statistics',
  templateUrl: './customer-survey-statistics.component.html',
  styleUrls: ['./customer-survey-statistics.component.css']
})
export class CustomerSurveyStatisticsComponent implements OnInit {

  desks: Desk[];

  constructor(private deskService: DeskService, public locationService: LocationService) { }

  ngOnInit(): void {

    this.deskService.getDesks(
      (data)=>{
        this.desks = data;
      },
      ()=>{ }
    );
  }

}
