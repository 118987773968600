import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Category } from 'projects/qcloud-models/category/category.model';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { ReportTemplate } from 'projects/qcloud-models/report/report-template';
import { ReportTemplateJson } from 'projects/qcloud-models/report/report-template-json';
import { ReportTemplateParameter } from 'projects/qcloud-models/report/report-template-parameter';
import { User } from 'projects/qcloud-models/user/user.model';
import { ReportService } from 'projects/qcloud-rest-client/src/lib/report.service';

@Component({
  selector: 'app-report-templates',
  templateUrl: './report-templates.component.html',
  styleUrls: ['./report-templates.component.css']
})
export class ReportTemplatesComponent implements OnInit {

  @Input() reportTemplates: ReportTemplate[];
  reportTemplate: ReportTemplate;
  reportTemplateParameters: ReportTemplateParameter[];
  savingTemplate: boolean = false;
  @Input() categories: Category[];  
  @Input() desks: Desk[];
  @Input() users: User[];
  @Output() updatedTemplates = new EventEmitter<boolean>();

  constructor(private reportService: ReportService, private toastr: ToastrService,
    private translate: TranslateService) { }

  ngOnInit(): void {
  }

  createNewTemplate() {
    this.reportTemplate = new ReportTemplate();
    this.reportTemplate.id = 0;
    this.reportTemplateParameters = new Array<ReportTemplateParameter>();
  }

  assignTemplateParameters(event: ReportTemplate) {
    this.reportTemplateParameters = event.reportTemplateParameters;
  }

  saveTemplate() {
    this.savingTemplate = true;
    this.reportTemplate.reportTemplateParameters = this.reportTemplateParameters;
    let reportTemplateToSend = new ReportTemplateJson(this.reportTemplate);
    if (this.reportTemplate.id == 0) {
      this.reportService.createTemplate(reportTemplateToSend).subscribe(
        res => {
          this.updatedTemplates.emit(true);
          this.savingTemplate = false;
          this.translate.get('report-template-success').subscribe((res: string) => {
            this.toastr.success(res);
          });
        },
        err => {
          this.updatedTemplates.emit(true);
          this.savingTemplate = false;
          this.translate.get('report-template-error').subscribe((error: string) => {
            this.translate.get('repeat').subscribe((res: string) => {
              this.toastr.error(res, error);
            });
          });
        }
      );
    }
    else {
      this.reportService.editTemplate(reportTemplateToSend).subscribe(
        res => {
          this.savingTemplate = false;
          this.translate.get('report-template-success').subscribe((res: string) => {
            this.toastr.success(res);
          });
        },
        err => {
          this.savingTemplate = false;
          this.translate.get('report-template-error').subscribe((error: string) => {
            this.translate.get('repeat').subscribe((res: string) => {
              this.toastr.error(res, error);
            });
          });
        }
      );
    }
  }

}
