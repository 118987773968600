<div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ 'file-manager' | translate }}</h5>
            <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>

        <div class="mx-2">
            <app-file-path [path]="path" (pathToEmit)="setPath($event)"></app-file-path>
        </div>

        <div *ngIf="files" class="modal-body row">
            <div class="col-lg-2 col-sm-3 col-xs-12 mb-2 d-flex align-items-stretch card-height" *ngFor="let file of files; let i = index">
                <div (click)="chooseFile(file, i)" class="container-fluid text-center pointer"
                    [ngClass]="{'selected': !multiselect && selectedFileIndex == i || multiselect && selectedFiles[i]}"> 
                    <img *ngIf="file.type == 0 && (service.isImage(file.path) || service.isVideo(file.path))"
                        [src]="service.getPathToImage(file.path, file.name)"
                        alt="QCloud File" class="img-fluid rounded height">
                    <video style="width:0;height: 0;" *ngIf="file.type == 0 && service.isVideo(file.path)" [src]="service.getPathToImage(file.path)"
                        class="h-50 img-fluid card-img-top rounded" (loadedmetadata)="setDuration($event, i)">
                    </video>
                    <i *ngIf="file.type == 1" class="fas fa-folder fa-3x card-img-top" (dblclick)="changeFolder(file)"></i>
                    <div>{{ displayFilename(file.name) | removeExtention }}</div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-danger" data-dismiss="modal" (click)="removeSelectedFiles()">
                {{ 'cancel' | translate }}
            </button>
            <button class="btn btn-primary" data-dismiss="modal" data-backdrop="false" (click)="sendFile()">
                {{ 'submit' | translate }}
            </button>
        </div>
    </div>
</div>
