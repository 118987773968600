  <div class="table-responsive">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>{{ 'reservation-schedule-range' | translate }}</th>
                    <th>{{ 'options' | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let activityBlock of locationActivityBlocks; let i = index">
                    <td>
                        <span>
                            {{ activityBlock.dateStart | date: 'dd/MM/yyyy' }} - {{ activityBlock.dateEnd | date: 'dd/MM/yyyy'}}
                        </span>
                    </td>
                    <td>
                        <button class="btn btn-info btn-circle btn-sm mr-1"  (click)="edit(activityBlock)"
                            matTooltip="{{ 'edit' | translate }}" tooltipClass="tooltip-info">
                            <i class="fas fa-fw fa-edit"></i>
                        </button>
                        <button class="btn btn-danger btn-circle btn-sm" (click)="delete(activityBlock)"
                            matTooltip="{{ 'delete' | translate }}" tooltipClass="tooltip-danger">
                            <i class="fas fa-fw fa-trash"></i>
                        </button>
                    </td>
                </tr>
        </table>
    </div>
