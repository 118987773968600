import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientOpinion } from 'projects/qcloud-models/survey/client-opinion';
import { SurveyService } from 'projects/qcloud-rest-client/src/lib/survey.service';
import { DeviceConfigurationService } from '../../services/device-configuration.service';
import { UtilitiesService } from '../../services/utilities.service';

declare var window;

@Component({
  selector: 'app-survey-display',
  templateUrl: './survey-display.component.html',
  styleUrls: ['./survey-display.component.css']
})
export class SurveyDisplayComponent implements OnInit {

  public title: string;
  public opinionSent: boolean = false;
  public thankUText: string = "Dziękujemy za wyrażenie opinii";

  constructor(private deviceConfigurationService: DeviceConfigurationService,
    private utilitiesService: UtilitiesService,
    private customerSurveyService: SurveyService,
    private router: Router) { }

  ngOnInit(): void {
    let config = this.deviceConfigurationService.getConfiguration();
    if (!config) {
      this.router.navigate(['./']);
    }
    if (!config.deskId) {
      this.title = config.locationName;
    }
    if (config.title) {
      this.title = config.title;
    }
    else {
      let deskName = this.utilitiesService.getDeskName(config.deskId);
      let deskNumber = this.utilitiesService.getDeskNumber(config.deskId);
      this.title = deskName + " " + deskNumber;
    }
  }

  sendOpinion(opinionValue: number) {
    let opinion = new ClientOpinion();
    opinion.deskId = this.deviceConfigurationService.getConfiguration().deskId;
    opinion.opinion = opinionValue;
    this.customerSurveyService.addOpinion(opinion).subscribe(() => { });
    this.opinionSent = true;
    let context = this;
    window.setTimeout(function () {
      context.opinionSent = false;
    }, 3000);
  }

}
