<div *ngIf="checkConstraints()">
    <div class="card shadow mb-4">
        <div class="card-header py-3">
            <div class="row">
                <h5 class="col-9 m-0 font-weight-bold text-primary">{{ 'integration-ldap' | translate }}</h5>
            </div>
        </div>
        <div class="card-body">
            <form #form="ngForm" autocomplete="off">
                <div class="form-group mt-2">
                    <div class="input-group">
                        *<div class="font-weight-bold">{{ 'server-hostname' | translate }}</div>
                    </div>
                    <div class="input-group">
                        <input name="hostName" #hostName="ngModel" [(ngModel)]="serverLDAPConfig.hostName"
                            class="form-control" required>
                    </div>
                    <div *ngIf="hostName.invalid && (hostName.dirty || hostName.touched)" class="text-danger mx-2 my-1">
                        <div *ngIf="hostName.errors.required">{{ 'server-hostname-required' | translate }} </div>
                    </div>
                </div>
                <div class="form-group mt-2">
                    <div class="input-group">
                        *<div class="font-weight-bold">{{ 'server-port' | translate }}</div>
                    </div>
                    <div class="input-group">
                        <input name="port" #port="ngModel" [(ngModel)]="serverLDAPConfig.port" class="form-control"
                            required>
                    </div>
                    <div *ngIf="port.invalid && (port.dirty || port.touched)" class="text-danger mx-2 my-1">
                        <div *ngIf="port.errors.required">{{ 'server-port-required' | translate }}</div>
                    </div>
                </div>
                <h6 class="col-9 m-0 font-weight-bold text-primary"></h6>
                <div class="form-group mt-2">
                    <div class="input-group">
                        *<div class="font-weight-bold">{{ 'distinguished-name' | translate }} </div>
                    </div>
                    <div class="input-group">
                        <input name="distinguishedName" #distinguishedName="ngModel"
                            [(ngModel)]="serverLDAPConfig.distinguishedName" class="form-control" required>
                    </div>
                </div>
                <div *ngIf="distinguishedName.invalid && (distinguishedName.dirty || distinguishedName.touched)"
                    class="text-danger mx-2 my-1">
                    <div *ngIf="distinguishedName.errors.required">{{ 'distinguished-name-required' | translate }}</div>
                </div>
                <div class="form-group mt-2">
                    <div class="input-group">
                        *<div class="font-weight-bold">{{ 'user-name' | translate }}</div>
                    </div>
                    <div class="input-group">
                        <input name="userName" #userName="ngModel" [(ngModel)]="serverLDAPConfig.userName"
                            class="form-control" required>
                    </div>
                    <div *ngIf="userName.invalid && (userName.dirty || userName.touched)" class="text-danger mx-2 my-1">
                        <div *ngIf="userName.errors.required">{{ 'user-name-required' | translate }}</div>
                    </div>
                </div>
                <div class="form-group mt-2">
                    <div class="input-group">
                        *<div class="font-weight-bold">{{ 'password' | translate }}</div>
                    </div>
                    <div class="input-group">
                        <input name="password" #password="ngModel" [(ngModel)]="serverLDAPConfig.password"
                            class="form-control" required>
                    </div>
                    <div *ngIf="password.invalid && (password.dirty || password.touched)" class="text-danger mx-2 my-1">
                        <div *ngIf="password.errors.required">{{ 'password-required' | translate }}</div>
                    </div>
                </div>
                <div class="form-group row">
                    <button class="btn btn-primary m-1" type="submit" [disabled]="formSubmitted || form.invalid"
                        (click)="onSubmit()">
                        <span class="spinner-border spinner-border-sm my-1" role="status" *ngIf="formSubmitted"
                            aria-hidden="true"></span>{{ 'save-changes' | translate }}
                    </button>
                    <button class="btn btn-info m-1" type="button" [disabled]="testingConnection || form.invalid"
                        (click)="testConnection()">
                        <span class="spinner-border spinner-border-sm my-1" role="status" *ngIf="testingConnection"
                            aria-hidden="true"></span>Test connection
                    </button>
                </div>
                <label *ngIf="form.invalid" class="text-danger">*{{ 'complete-required-fields' | translate }}</label>
            </form>
        </div>
    </div>
</div>

<app-licence-info *ngIf="!checkConstraints()" [title]="'integration-ldap'">
</app-licence-info>