<div *ngIf="checkLoadConsoleAssets() && !consoleService.user.mainRole.hasPermissionToServeETickets" [ngClass]="{'': consoleService.projectTypeEnum.WEB === this.consoleService.projectType, 'device-background': this.consoleService.projectType === this.consoleService.projectTypeEnum.DEVICE}">
    <div class="space-pool">
        <div [hidden]="isShowInteractionCard" class="small-card-body" [ngClass]="{'': consoleService.projectTypeEnum.WEB === this.consoleService.projectType, 'device-small-card-body': this.consoleService.projectType === this.consoleService.projectTypeEnum.DEVICE}">
            <div class="console-info">
                <lib-display-user class="display-info display-info-user" [ngClass]="{'': consoleService.projectTypeEnum.WEB === this.consoleService.projectType, 'device-font-size': this.consoleService.projectType === this.consoleService.projectTypeEnum.DEVICE}"></lib-display-user>
                <lib-display-desk class="display-info" [ngClass]="{'': consoleService.projectTypeEnum.WEB === this.consoleService.projectType, 'device-font-size': this.consoleService.projectType === this.consoleService.projectTypeEnum.DEVICE}"></lib-display-desk>
            </div>
            <div class="show-ticket" [ngClass]="{'': consoleService.projectTypeEnum.WEB === this.consoleService.projectType, 'device-font-size': this.consoleService.projectType === this.consoleService.projectTypeEnum.DEVICE}">
                <h6 class="m-0 font-weight-bold text-primary ticket-label">{{ 'console.ticket' | translate}}:</h6>
                <lib-display-ticket class="small-size small-card-operator-pool" ></lib-display-ticket>
            </div>
            <div class="space-deadline-expand-button" [ngClass]="{'': consoleService.projectTypeEnum.WEB === this.consoleService.projectType, 'device-font-size': this.consoleService.projectType === this.consoleService.projectTypeEnum.DEVICE}">
                <lib-display-deadline class="small-display-deadline"  #displayDeadlineComponent></lib-display-deadline>
                <!-- <lib-expand-menu-button class="small-size" [hidden]="true" (click)="moveToExpandedMenu()" [tooltipTitle]="'console.tooltip.expanded-menu' | translate" #expandMenuButtonComponent></lib-expand-menu-button> -->
            </div>
        </div>
        <div [hidden]="isShowInteractionCard" [ngClass]="{'ticket-management': consoleService.projectTypeEnum.WEB === this.consoleService.projectType, 'device-ticket-management': this.consoleService.projectType === this.consoleService.projectTypeEnum.DEVICE}">
            <lib-call-ticket-button class="small-size" [tooltipTitle]="'console.tooltip.call' | translate" #callTicketButtonComponent></lib-call-ticket-button>
            <lib-call-again-ticket-button class="small-size" [tooltipTitle]="'console.tooltip.again' | translate"  #callAgainTicketButtonComponent></lib-call-again-ticket-button>
            <lib-accept-ticket-button class="small-size" [tooltipTitle]="'console.tooltip.accept' | translate"  #acceptTicketButtonComponent></lib-accept-ticket-button>
            <lib-pause-ticket-button class="small-size" [tooltipTitle]="'console.tooltip.pause' | translate" (showInteractionModel)="showInteractionCard()"  #pauseTicketButtonComponent></lib-pause-ticket-button>
            <lib-redirect-ticket-button class="small-size" [tooltipTitle]="'console.tooltip.redirect' | translate" (click)="showInteractionCard()"  #redirectTicketButtonComponent></lib-redirect-ticket-button>
            <lib-cancel-ticket-button class="small-size" [tooltipTitle]="'console.tooltip.cancel' | translate" #cancelTicketButtonComponent></lib-cancel-ticket-button>
        </div>

        <div [hidden]="isShowInteractionCard" class="grid-queue-state-field">
            <lib-queue-state [categorieShowLimits]="2" (click)="showInteractionCard()" #queueStateComponent></lib-queue-state>
        </div>
        <div [hidden]="!isShowInteractionCard">
            <lib-slim-interaction-card (hideInteractionModel)="hideInteractionCard()"></lib-slim-interaction-card>
        </div>
    </div>
    <div class="expanded-menu-area" [hidden]="isShowInteractionCard">
        <div class="expand-menu-display-grid-sector-one">
            <lib-cancelled-tickets-button (click)="showInteractionCard()" [ngClass]="{'grid-item': consoleService.projectTypeEnum.WEB === this.consoleService.projectType , 'device-font-size': this.consoleService.projectType === this.consoleService.projectTypeEnum.DEVICE}" #cancelledTicketsButtonComponent></lib-cancelled-tickets-button>
            <lib-paused-tickets-button (click)="showInteractionCard()" [ngClass]="{'grid-item': consoleService.projectTypeEnum.WEB === this.consoleService.projectType , 'device-font-size': this.consoleService.projectType === this.consoleService.projectTypeEnum.DEVICE}" #pausedTicketsButtonComponent></lib-paused-tickets-button>
            <lib-call-ticket-manual-button (click)="showInteractionCard()" [ngClass]="{'grid-item': consoleService.projectTypeEnum.WEB === this.consoleService.projectType , 'device-font-size': this.consoleService.projectType === this.consoleService.projectTypeEnum.DEVICE}" [buttonTitleTranslationTag]="'console.slim.manual-call'" #callTicketManualButtonComponent></lib-call-ticket-manual-button>
            <lib-show-ticket-manual-button (click)="showInteractionCard()" [ngClass]="{'grid-item': consoleService.projectTypeEnum.WEB === this.consoleService.projectType , 'device-font-size': this.consoleService.projectType === this.consoleService.projectTypeEnum.DEVICE}" #showTicketManualButtonComponent></lib-show-ticket-manual-button>
        </div>
        <div class="expand-menu-display-grid-sector-second">
            <lib-generate-ticket-button (click)="showInteractionCard()" [ngClass]="{'grid-item': consoleService.projectTypeEnum.WEB === this.consoleService.projectType , 'device-font-size': this.consoleService.projectType === this.consoleService.projectTypeEnum.DEVICE}" #generateTicketButtonComponent></lib-generate-ticket-button>
            <lib-blocked-categories-button (click)="showInteractionCard()" [ngClass]="{'grid-item': consoleService.projectTypeEnum.WEB === this.consoleService.projectType , 'device-font-size': this.consoleService.projectType === this.consoleService.projectTypeEnum.DEVICE}" #blockedCategoriesButtonComponent></lib-blocked-categories-button>
            <lib-served-categories-button (click)="showInteractionCard()" [ngClass]="{'grid-item': consoleService.projectTypeEnum.WEB === this.consoleService.projectType , 'device-font-size': this.consoleService.projectType === this.consoleService.projectTypeEnum.DEVICE}" #servedCategoriesButtonComponent></lib-served-categories-button>
        </div>
    </div>
</div>