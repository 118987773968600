export class CategoryTranslation {
    public id: number;
    public categoryId: number;
    public fullName: string;
    public shortName: string;
    public blockingMessageActivity: string;
    public blockingMessageManual: string;
    public printedTicketAdditionalText: string;
    public translationId: number;
    public locationId: number;
}