import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { QcloudConsoleService } from '../../qcloud-console.service';

@Component({
  selector: 'lib-generate-ticket-button',
  templateUrl: './generate-ticket-button.component.html',
  styleUrls: ['./generate-ticket-button.component.css', './../base-button/base-button.component.css']
})
export class GenerateTicketButtonComponent implements OnInit {
  @ViewChild('button') public button: ElementRef;
  isEnabled: boolean;
  isBlocked: boolean;

  constructor(private consoleService: QcloudConsoleService) {
    this.consoleService.isBlocked.subscribe((val) => {
      this.isBlocked = val;
      this.consoleService.closeAllModals();
    });
  }

  ngOnInit(): void {
    this.isEnabled = this.consoleService.user.mainRole.canGenerateTicketsInConsole;
  }

  public generateTicket() {
    if (this.isEnabled) {
      this.consoleService.openGenerateTicketModal();
    } else {
      this.consoleService.setIsBlockedStatus(true);
    }
  }

}
