export class TicketDisplayRow {
    texts: any[];
    categoryId: number;
    active: boolean;
    visible: boolean;
    categoryIds: number[];
    deskId: number;
    constructor() {
        this.texts = [];
    }
}