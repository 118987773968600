import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'normalizeCharacters'
})
export class NormalizeCharactersPipe implements PipeTransform {

  transform(item: string) {
    item = item.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    item = item.replace(/\u0142/g, "l");
    return item;
  }
}