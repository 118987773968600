import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DeskStatus } from 'projects/qcloud-models/desk/desk-status.enum';
import { User } from 'projects/qcloud-models/user/user.model';
import { SystemDeskService } from '../../../../../qcloud-rest-client/src/lib/desk/system-desk.service';
import { SystemDesk } from '../../../../../qcloud-models/desk/system-desk';

@Component({
  selector: 'app-system-desks',
  templateUrl: './system-desks.component.html',
  styleUrls: ['./system-desks.component.css']
})
export class SystemDesksComponent implements OnInit {

  public desks: SystemDesk[];
  selectedDeskId: number;
  users: User[];
  DeskStatus = DeskStatus;

  constructor(private systemDeskService: SystemDeskService, private translate: TranslateService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getDesks();
  }
  getDesks() {
    this.systemDeskService.getDesks((data) => {
      this.desks = data;
    },
      () => { });
  }

  onDeleteConfirm(confirmed: boolean) {
if (confirmed) {
      this.deleteDesk(this.selectedDeskId);
    }
  }

  deleteDesk(deskId: number) {
    this.systemDeskService.deleteDesk(deskId).subscribe(
      () => {
        this.getDesks();
      },
      () => {
        this.translate.get('delete-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }
}