import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TimeConversionService } from '../../../shared/time-conversion.service';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { ActivityTime } from 'projects/qcloud-models/activity-time';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { SystemCategoryService } from '../../../../../../qcloud-rest-client/src/lib/category/system-category.service';
import { SystemCategory } from '../../../../../../qcloud-models/category/system-category.model';
import { ActivityTimeCategory } from 'projects/qcloud-models/activity-time-category/activity-time-category.model.';
import { UntypedFormControl } from '@angular/forms';
import { LocationData } from 'projects/qcloud-models/location/location.model';

@Component({
  selector: 'app-system-category',
  templateUrl: './system-category.component.html',
  styleUrls: ['./system-category.component.css']
})
export class SystemCategoryComponent implements OnInit {

  category: SystemCategory;
  isEditForm: boolean;
  advancedMode: boolean = false;
  thumbLabel = true;
  formSubmitted: boolean = false;

  checkedTicketLimit = false;
  checkedTimePerTicket = false;
  checkedService = false;
  checkedPause = false;
  addPrintText = false;
  minService = 1;
  maxService = 120;
  minPause = 5;
  maxPause = 100;
  step = 5;
  nightWork: Array<boolean> = new Array(7);
  categoryLocations: string = 'category-locations'
  locations: LocationData[];
  mainRoleLocationIds: number[];
  protected initialLoco: LocationData[] = new Array<LocationData>();;
  public locationMultiCtrl: UntypedFormControl;
  readonly panelOpenState = false;

  constructor(private timeConverter: TimeConversionService,
    private authService: AuthService, private router: Router, private route: ActivatedRoute,
    private translate: TranslateService, private toastr: ToastrService, public locationService: LocationService,
    public systemCategoryService: SystemCategoryService) { }

  ngOnInit(): void {
    let categoryId: number;
    this.route.params.subscribe(params => {
      categoryId = params['id']
    });
    if (categoryId) {
      this.isEditForm = true;
      this.getCategory(categoryId);
    } else {
      this.isEditForm = false;
      this.initializeCategory();
    }
  }

  initializeCategory() {
    this.category = new SystemCategory();
    this.category.locationId = +this.authService.getLocationId();
    this.category.localeCategoryLocationIds = [];
    this.getLocations();

    this.category.isAutomaticallyReset = true;
    this.category.blockingMessageActivity = "Limit biletów został wyczerpany"; //TODO: get translations
    this.category.blockingMessageManual = "Limit biletów został wyczerpany";
    this.category.ticketAmountToCountAverageWaitingServiceTime = 100;
    this.category.activityTimeCategory = new ActivityTimeCategory();
    this.category.activityTimeCategory.unblockingTimeDaysValue = new Array<ActivityTime>(7);
    for (let i = 0; i < this.category.activityTimeCategory.unblockingTimeDaysValue.length; i++) {
      this.category.activityTimeCategory.unblockingTimeDaysValue[i] = new ActivityTime();
      this.category.activityTimeCategory.unblockingTimeDaysValue[i].unblockingTime = "00:00";
      this.category.activityTimeCategory.unblockingTimeDaysValue[i].blockingTime = "23:59";
      this.category.activityTimeCategory.unblockingTimeDaysValue[i].is24Hours = false;
      this.category.activityTimeCategory.unblockingTimeDaysValue[i].isActive = true;
      this.category.activityTimeCategory.unblockingTimeDaysValue[i].isButtonAlwaysVisible = false;
      this.category.activityTimeCategory.unblockingTimeDaysValue[i].day = i;
      this.nightWork[i] = false;
    }

  }

  getCategory(categoryId: number) {
    this.systemCategoryService.getCategory(categoryId).subscribe(
      category => {
        this.category = category;
        this.chackIsCategoryTagUnique(category.categoryTag);
        this.getLocations();
        if (this.category.ticketLimit != 0) {
          this.checkedTicketLimit = true;
        }
        if (this.checkedTicketLimit == false) {
          this.category.ticketLimit = 0;
        }
        if (this.category.timePerTicket != 0) {
          this.checkedTimePerTicket = true;
        }
        if (this.checkedTimePerTicket == false) {
          this.category.timePerTicket = 0;
        }
        if (this.category.maxServiceTime != 0) {
          this.checkedService = true;
        }
        if (this.category.defaultPauseTimeMins != 0) {
          this.checkedPause = true;
        }
        if (this.category.additionalPrint == true) {
          this.addPrintText = true;
        }
        for (let i = 0; i < this.category.activityTimeCategory.unblockingTimeDaysValue.length; i++) {

          this.category.activityTimeCategory.unblockingTimeDaysValue[i].blockingTime =
            this.timeConverter.dateToTime(this.category.activityTimeCategory.unblockingTimeDaysValue[i].blockingTime);
          this.category.activityTimeCategory.unblockingTimeDaysValue[i].unblockingTime =
            this.timeConverter.dateToTime(this.category.activityTimeCategory.unblockingTimeDaysValue[i].unblockingTime);

          if (this.timeConverter.timeToMinutes(this.category.activityTimeCategory.unblockingTimeDaysValue[i].blockingTime)
            < this.timeConverter.timeToMinutes(this.category.activityTimeCategory.unblockingTimeDaysValue[i].unblockingTime)) {

            var tmp = this.category.activityTimeCategory.unblockingTimeDaysValue[i].blockingTime;
            this.category.activityTimeCategory.unblockingTimeDaysValue[i].blockingTime
              = this.category.activityTimeCategory.unblockingTimeDaysValue[i].unblockingTime;
            this.category.activityTimeCategory.unblockingTimeDaysValue[i].unblockingTime = tmp;
            this.nightWork[i] = true;
          }
        }
      }
    );
  }

  checkServiceTimeInput(serviceTime: number) {
    if (serviceTime < 1) {
      this.category.maxServiceTime = 1
    }
    if (serviceTime > 120) {
      this.category.maxServiceTime = 120
    }
  }

  checkPauseTimeInput(pauseTime: number) {
    if (pauseTime < 1) {
      this.category.defaultPauseTimeMins = 1
    }
    else {
      this.category.defaultPauseTimeMins = pauseTime
    }
  }

  onSubmit() {
    this.formSubmitted = true;
    for (let i = 0; i < this.category.activityTimeCategory.unblockingTimeDaysValue.length; i++) {
      if (this.nightWork[i] == true) {
        var tmp = this.category.activityTimeCategory.unblockingTimeDaysValue[i].unblockingTime;
        this.category.activityTimeCategory.unblockingTimeDaysValue[i].unblockingTime
          = this.category.activityTimeCategory.unblockingTimeDaysValue[i].blockingTime;
        this.category.activityTimeCategory.unblockingTimeDaysValue[i].blockingTime = tmp;
      }
    }
    this.category.activityTimeCategory.unblockingTimeDaysValue = this.changeTimeModel(this.category.activityTimeCategory.unblockingTimeDaysValue);
    if (this.locationMultiCtrl && (this.locationMultiCtrl.value as LocationData[]).length != 0) {
      var parse = this.locationMultiCtrl.value as LocationData[];
      var categoryLIds: number[] = [];
      parse.forEach(element => {
        categoryLIds.push(element.id);
      });
      this.category.localeCategoryLocationIds = categoryLIds;
    }

    if (this.isEditForm) {
      this.editCategory();
    } else {
      this.addCategory();
    }
  }

  addCategory() {
    this.category.id = 0;
    this.category.activityTimeCategory.unblockingTimeDaysValue.forEach(element => {
      element.id = 0;
      element.categoryId = 0;
    });
    if (this.checkedTicketLimit == false) {
      this.category.ticketLimit = 0;
    }
    if (this.checkedTimePerTicket == false) {
      this.category.timePerTicket = 0;
    }
    this.systemCategoryService.addCategory(this.category).subscribe(
      () => {
        this.router.navigate(['/systemCategories']);
      },
      () => {
        this.formSubmitted = false;
        this.translate.get('category-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  editCategory() {
    if (this.checkedTicketLimit == false) {
      this.category.ticketLimit = 0;
    }
    if (this.checkedTimePerTicket == false) {
      this.category.timePerTicket = 0;
    }
    this.systemCategoryService.editCategory(this.category).subscribe(
      () => {
        this.router.navigate(['/systemCategories']);
      },
      () => {
        this.formSubmitted = false;
        this.translate.get('category-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  changeTimeModel(time: Array<ActivityTime>) {
    time.forEach(element => {
      element.blockingTime = this.timeConverter.timeToDate(element.blockingTime);
      element.unblockingTime = this.timeConverter.timeToDate(element.unblockingTime);
    });
    return time;
  }

  newBasedOnExisting() {
    this.isEditForm = false;
    this.chackIsCategoryTagUnique(this.category.categoryTag);
  }

  assignDefaultLocation() {
    if (this.locations.length > 1) {
      this.initialLoco = new Array<LocationData>();
      for (let j = 0; j < this.category.localeCategoryLocationIds.length; j++) {
        for (let i = 0; i < this.locations.length; i++) {
          if (this.category.localeCategoryLocationIds[j] == this.locations[i].id) {
            this.initialLoco.push(this.locations[i]);
          }
        }
      }
      this.locationMultiCtrl = new UntypedFormControl(this.initialLoco);
    }
    else {
      this.category.localeCategoryLocationIds.push(this.locations[0].id);
    }
  }

  getLocations() {
    var locationIds = this.authService.getTokenLocationIds();
    this.mainRoleLocationIds = locationIds;
    this.locationService.getAll().subscribe(locationDatas => {
      this.locations = locationDatas;
      this.initialLoco = locationDatas;
      this.assignDefaultLocation();
    });
  }

  handleModalResult(event) {
    if (event === true) {
      this.onSubmit();
    }
  }
  categoryTagIsDuplicate: boolean = false;
  chackIsCategoryTagUnique(event) {
    if (event == undefined || event.length === 0) {
      return;
    }

    var systemCategoryId = -1;
    if (this.isEditForm) {
      systemCategoryId = this.category.id;
    }
    this.systemCategoryService.isCategoryTagUnique(event, systemCategoryId).subscribe(
      (data) => {
        this.categoryTagIsDuplicate = !data;
      },
      () => { }
    );
  }

  getLocationName(locationId: number) {
    return this.locations.find(x => x.id === locationId)?.locationName ?? "";
  }
}