import { Weekday } from "rrule";

export class AutoGenerateReportDTO {
    public id: string;
    public reportTemplateId: number;
    public time: Date;
    public days: number[];
    public lastDay: number;
    public fileType: string;
    public emails: string;
}