import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CategoryPriority } from 'projects/qcloud-models/category/category-priority.enum';
import { DeskCategory } from 'projects/qcloud-models/desk/desk-category.model';
import { DeskStatus } from 'projects/qcloud-models/desk/desk-status.enum';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { SystemDeskService } from '../../../../../../qcloud-rest-client/src/lib/desk/system-desk.service';
import { LocationData } from 'projects/qcloud-models/location/location.model';
import { LocationService } from '../../../../../../qcloud-rest-client/src/lib/location.service';
import { SystemDesk } from '../../../../../../qcloud-models/desk/system-desk';
import { SystemDeskCategoryPost } from '../../../../../../qcloud-models/desk/system-desk-category-post.model';
import { SystemCategoryService } from '../../../../../../qcloud-rest-client/src/lib/category/system-category.service';
import { SystemCategory } from '../../../../../../qcloud-models/category/system-category.model';

@Component({
  selector: 'app-system-desk',
  templateUrl: './system-desk.component.html',
  styleUrls: ['./system-desk.component.css']
})
export class SystemDeskComponent implements OnInit {

  desk: SystemDesk;
  isEditForm: boolean;
  advancedMode: boolean = false;
  formSubmitted: boolean = false;
  categoryPriority = CategoryPriority;

  categories: SystemCategory[];
  protected deskCategories: DeskCategory[];
  protected initialDeskCategories: DeskCategory[];
  deskLocations: string = 'desk-locations'
  locations: LocationData[];
  protected initialLoco: LocationData[] = new Array<LocationData>();;
  public locationMultiCtrl: UntypedFormControl;
  mainRoleLocationIds: number[];

  public deskCategoryMultiCtrl: UntypedFormControl;
  public deskCategoryMultiFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredDeskCategoriesMulti: ReplaySubject<DeskCategory[]> = new ReplaySubject<DeskCategory[]>(1);

  protected _onDestroy = new Subject<void>();

  constructor(private systemDeskService: SystemDeskService,
    private systemCategoryService: SystemCategoryService,
    private authService: AuthService, private route: ActivatedRoute, private toastr: ToastrService,
    private router: Router, private translate: TranslateService,
    public locationService: LocationService) { }

  ngOnInit(): void {
    let deskId: number;
    this.route.params.subscribe(params => {
      deskId = params['id']
    });
    if (deskId) {
      this.isEditForm = true;
      this.getDesk(deskId);
    } else {
      this.isEditForm = false;
      this.initializeDesk();
    }
  }

  initializeDesk() {
    this.desk = new SystemDesk();
    this.desk.localeDeskLocationIds = [];
    this.desk.locationId = +this.authService.getLocationId();
    this.desk.systemId = +this.authService.getSystemId();
    this.desk.systemCategoryForSystemDesks = new Array();
    this.desk.deskStatus = DeskStatus.Closed;
    this.getLocations();
    this.getCategories();
  }

  getDesk(deskId: number) {
    this.systemDeskService.getDesk(deskId).subscribe(
      desk => {
        this.desk = desk;
        this.getLocations();
        this.getCategories();
      }
    );
  }

  onSubmit(categories: DeskCategory[]) {
    this.formSubmitted = true;
    this.desk.systemCategoryForSystemDesks = categories.map(category => new SystemDeskCategoryPost(category));
    this.assignLocationFromSelectionComponent();
    if (this.isEditForm) {
      this.editDesk();
    } else {
      this.addDesk();
    }
  }

  addDesk() {
    this.desk.id = 0;
    this.systemDeskService.addDesk(this.desk).subscribe(
      () => {
        this.router.navigate(['/systemDesks']);
      },
      () => {
        this.formSubmitted = false;
        this.translate.get('desk-error').subscribe((res: string) => {
          let error = res;
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  editDesk() {
    this.systemDeskService.editDesk(this.desk, this.desk.id).subscribe(
      () => {
        this.router.navigate(['/systemDesks']);
      },
      () => {
        this.formSubmitted = false;
        this.translate.get('desk-error').subscribe((res: string) => {
          let error = res;
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  getCategories() {
    this.systemCategoryService.getCategories((data) => {
      this.categories = data;
      this.deskCategories = this.categories.map(category => DeskCategory.CreateDeskCategoryBasedOnSystemCategory(category));

      this.initialDeskCategories = new Array<DeskCategory>();
      for (let i = 0; i < this.deskCategories.length; i++) {
        for (let j = 0; j < this.desk.systemCategoryForSystemDesks.length; j++) {
          if (this.deskCategories[i].categoryId == this.desk.systemCategoryForSystemDesks[j].systemCategoryId) {
            this.deskCategories[i].categoryPriority = this.desk.systemCategoryForSystemDesks[j].categoryPriority;
            this.initialDeskCategories.push(this.deskCategories[i]);
          }
        }
      }
      this.deskCategoryMultiCtrl = new UntypedFormControl(this.initialDeskCategories);

      // load the initial deskCategory list
      this.filteredDeskCategoriesMulti.next(this.deskCategories.slice());

      // listen for search field value changes
      this.deskCategoryMultiFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterdeskCategoriesMulti();
        });
    },
      () => { }
    );
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredDeskCategoriesMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.deskCategoryMultiCtrl.patchValue(val);
        } else {
          this.deskCategoryMultiCtrl.patchValue([]);
        }
      });
  }

  protected filterdeskCategoriesMulti() {
    if (!this.deskCategories) {
      return;
    }
    // get the search keyword
    let search = this.deskCategoryMultiFilterCtrl.value;
    if (!search) {
      this.filteredDeskCategoriesMulti.next(this.deskCategories.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the deskCategories
    this.filteredDeskCategoriesMulti.next(
      this.deskCategories.filter(deskCategory => deskCategory.name.toLowerCase().indexOf(search) > -1)
    );
  }

  setCategoryPriority(deskCategory: DeskCategory, priority: number) {
    deskCategory.categoryPriority = priority;
  }

  getLocations() {
    var locationIds = this.authService.getTokenLocationIds();
    this.mainRoleLocationIds = locationIds;
    this.locationService.getAll().subscribe(locationDatas => {
      this.locations = locationDatas.filter(y => locationIds.includes(y.id));
      this.initialLoco = locationDatas;
      this.assignDefaultLocation();
    });

  }
  assignDefaultLocation() {
    if (this.locations.length > 1) {
      this.initialLoco = new Array<LocationData>();
      for (let j = 0; j < this.desk.localeDeskLocationIds.length; j++) {
        for (let i = 0; i < this.locations.length; i++) {
          if (this.desk.localeDeskLocationIds[j] == this.locations[i].id) {
            this.initialLoco.push(this.locations[j]);
          }
        }
      }
      this.locationMultiCtrl = new UntypedFormControl(this.initialLoco);
    }
  }
  assignLocationFromSelectionComponent() {
    if (this.locationMultiCtrl && (this.locationMultiCtrl.value as LocationData[]).length != 0) {
      var parse = this.locationMultiCtrl.value as LocationData[];
      var roleLIds: number[] = [];
      parse.forEach(element => {
        roleLIds.push(element.id);
      });
      this.desk.localeDeskLocationIds = roleLIds;
    } else {
      this.desk.localeDeskLocationIds = [];
    }
  }
}


