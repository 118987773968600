import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { TicketListenerService } from 'projects/qcloud-device/src/app/services/ticket.service';
import { UtilitiesService } from 'projects/qcloud-device/src/app/services/utilities.service';
import { BaseComponent } from 'projects/qcloud-device/src/app/shared/base-component/base.component';
import { Ticket } from 'projects/qcloud-models/console/ticket';
import { TicketState } from 'projects/qcloud-models/console/ticketState.enum';
import { TicketDisplayRow } from 'projects/qcloud-models/device/ticket-display-row';

@Component({
  selector: 'app-multidesk',
  templateUrl: './multidesk.component.html',
  styleUrls: ['./multidesk.component.css']
})
export class MultideskComponent extends BaseComponent implements OnInit {
  row: TicketDisplayRow = new TicketDisplayRow();
  animation: any;
  ticketCaption: string = "----";
  deskNumber: string = "--";
  active: boolean = false;

  constructor(protected elementRef: ElementRef, protected renderer: Renderer2) {
    super(elementRef, renderer);
  }

  startComponent(): void {
    this.ticketListenerService.ticket.subscribe((val) => {
      this.ticketCalled(val);
    })
  }

  ngOnInit() {

  }

  private ticketCalled(ticket: Ticket) {
    if (ticket.ticketState != TicketState.Called)
      return;
    this.ticketCaption = ticket.caption;
    this.deskNumber = this.utilitiesService.getDeskNumber(ticket.deskId);
    this.clearAnimation();
    this.animate();
  }

  private clearAnimation() {
    if (this.animation != undefined) {
      window.clearInterval(this.animation);
    }
    this.active = false;
  }

  private animate() {
    let x = 0;
    let context = this;
    context.active = true;
    this.animation = window.setInterval(function () {
      context.active = !context.active;
      if (++x === 7 && context.animation != undefined) {
        context.clearAnimation();
      }
    }, 1000);
  }

  initMock(): void {
    this.ticketCaption = "A100";
    this.deskNumber = "1";
  }
}
