import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { CategoryPriority } from 'projects/qcloud-models/category/category-priority.enum';

@Component({
  selector: 'app-desk-categories-column',
  templateUrl: './desk-categories-column.component.html',
  styleUrls: ['./desk-categories-column.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DeskCategoriesColumnComponent implements OnInit {

  @Input() deskCategories;
  CategoryPriority = CategoryPriority

  constructor() { }

  ngOnInit(): void {
  }

  sortByPriority(categories: any[]) {
    return categories.sort((cat1, cat2) => cat1.categoryPriority - cat2.categoryPriority ||
      cat1.categoryTag.localeCompare(cat2.categoryTag));
  }
}
