import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth/auth.service';
import { timeout } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Device } from 'projects/qcloud-models/device/device.model';
import { ActivateDevice } from 'projects/qcloud-models/device/activate-device';
import { DeviceConfiguration } from 'projects/qcloud-models/device/device-configuration';
import { DeviceCustomization } from 'projects/qcloud-models/device/device-customization.model';

const DEFAULT_TIMEOUT = 3000;

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  appBase: string;

  constructor(private http: HttpClient, private authService: AuthService) { }

  public setAppBase(appBase: string) {
    this.appBase = appBase;
  }

  getDevices(callback: (data: Device[]) => any, onError: () => any) {
    this.http.get(this.appBase +
      `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/devices`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  
  getSystemDevices(callback: (data: Device[]) => any, onError: () => any) {
    this.http.get(this.appBase +
      `api/${this.authService.getSystemId()}/devices`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }


  getDevice(deviceGuid: string): Observable<Device> {
    return this.http.get<Device>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/devices/${deviceGuid}`);
  }

  addDevice(device: Device): Observable<Device> {
    return this.http.post<Device>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/devices`, device);
  }

  editDevice(device: Device) {
    return this.http.put(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/devices/${device.id}`, device);
  }

  deleteDevice(deviceGuid: string) {
    return this.http.delete(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/devices/${deviceGuid}`);
  }

  customizeDevice(deviceCustomization: DeviceCustomization, deviceGuid: string) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/devices/customize/` +
      deviceGuid, deviceCustomization);
  }

  restartDevice(deviceGuid: string) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/devices/restart/` +
      deviceGuid, {});
  }

  restartAll() {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/devices/restartAll`, {});
  }

  public activateDevice(code: string, callback: (data: ActivateDevice) => any, onError: (error) => any) {
    this.http.get(`${this.appBase}api/devices/activate/${code}`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  public checkAddress(url, callback: (data: string) => any, onError: (error) => any) {
    this.http.get(url + "api/1/system/version")
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  public getDeviceConfiguration(callback: (data: DeviceConfiguration) => any, onError: () => any) {
    this.http.get(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/devices/configuration`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }
}
