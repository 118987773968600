import { Component, OnInit } from '@angular/core';
import { Office } from 'projects/qcloud-models/grafik/office/office.model';
import { OfficeService } from 'projects/qcloud-rest-client/src/lib/grafik/office.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { RouteNavigationService } from 'projects/qcloud-web/src/app/shared/route-navigation.service';

@Component({
  selector: 'app-offices',
  templateUrl: './offices.component.html',
  styleUrls: ['./offices.component.css']
})
export class OfficesComponent implements OnInit {

  offices: Office[]
  selectedOfficeId: number;

  constructor(private officeService: OfficeService, private translate: TranslateService,
    private toastr: ToastrService, public router: RouteNavigationService) { }

  ngOnInit(): void {
    this.getOffices();
  }

  getOffices () {
    this.officeService.getOffices((offices)=>{
      this.offices = offices;
    },()=>{})
  }

  onDeleteConfirm(confirmed: boolean){
    if (confirmed) {
      this.deleteSpeciality(this.selectedOfficeId);
    }
  }

  deleteSpeciality(officeId: number) {
    this.officeService.deleteOffice(officeId).subscribe(
      res => {
        this.getOffices();
      },
      err => {
        this.translate.get('delete-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );    
  }

}
