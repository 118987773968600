<div class="row">
    <div class="col-lg-8">
        <div class="card shadow mb-4">
            <div class="card-header py-3">
                <h5 class="m-0 font-weight-bold text-primary">{{ 'multimedia-devices' | translate }}</h5>
            </div>
            <div class="card-body">
                <app-devices-table *ngIf="tags && tagsByDevice && multimediaDevices" 
                    [ctx]="ctx" [multimediaDevices]="multimediaDevices" [tags]="tags" [tagsByDevice]="tagsByDevice">
                </app-devices-table>
            </div>
        </div>    
    </div>
    
    <div class="col-lg-4">
        <app-tags-table *ngIf="tagsWithDevices && multimediaDevices"
            [ctx]="ctx" [devices]="multimediaDevices" [tags]="tagsWithDevices">
        </app-tags-table>
    </div>
</div>