import { Component, OnInit } from '@angular/core';
import { Ticket } from 'projects/qcloud-models/console/ticket';
import { QcloudConsoleService } from '../../qcloud-console.service';

@Component({
  selector: 'lib-show-ticket-manual-modal',
  templateUrl: './show-ticket-manual-modal.component.html',
  styleUrls: ['./show-ticket-manual-modal.component.css']
})
export class ShowTicketManualModalComponent implements OnInit {

  tickets: Ticket[];

  constructor(private consoleService: QcloudConsoleService) {
    this.consoleService.tickets.subscribe((val) => this.tickets = val);
  }

  ngOnInit(): void {
  }

  jsonToNote(ticket: Ticket){
    return JSON.parse(ticket.metadataJson).Note;
  }

  jsonToNoteShort(ticket: Ticket){
    let text="";
    try{
      text = JSON.parse(ticket.metadataJson).Note;
      if(text.length > 20){
      text = text.substring(0, 20).concat('...');
      }
    }catch{}
      return text
  }

}
