import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Console } from 'console';
import { BaseComponent } from 'projects/qcloud-device/src/app/shared/base-component/base.component';

@Component({
  selector: 'app-list-header',
  templateUrl: './list-header.component.html',
  styleUrls: ['./list-header.component.css']
})
export class ListHeaderComponent extends BaseComponent implements OnInit {
  service = "Usługa";
  ticketNumber = "Numer Biletu"
  desk = "Stanowisko";
  showService = false;
  showTicket = false;
  showDesk = false;
  constructor(protected elementRef: ElementRef, protected renderer: Renderer2) {
    super(elementRef, renderer);
  }
  ngOnInit(): void {
  }

  setPosition(left: number, width: number, top: number, height: number): void {
    super.setPosition(left, width, top, height);
    var event = new Event('resize');
    //this shouldnt be used but i cant find any other way to trigger font resize
    window.dispatchEvent(event);
  }

  passParameters(params: any): void {
    if (params.display == 0) {
      this.showService = true;
      this.showTicket = true;
      this.showDesk = true;
    }
    if (params.display == 1) {
      this.showTicket = true;
      this.showDesk = true;
    }
    if (params.display == 2) {
      this.showTicket = true;
      this.showDesk = true;

    }
    if (params.display == 3) {
      this.showTicket = true
      this.ticketNumber = "Stan kolejki";
      this.showDesk = true;
      this.desk = "Śr. czas oczekiwania";
      this.showService = true;
    }
    if (params.display == 4) {
      this.showService = true;
      this.service = "Bilety oczekujące"
    }
    console.log(params);
  }
}
