<div class="rows-container" *ngIf="displayType == displayTypeEnum.HORIZONTAL">
    <div *ngFor="let row of rows.getAll().reverse();let i = index" [ngStyle]="getRowHeightStyle(i)">
        <app-full-bar-compatible [active]="row.active" [content]="row.texts[0]" [content2]="row.texts[1]"
            [content3]="row.texts[2]" [rowStyle]="rowStyle" *ngIf="row.texts[0] != undefined" [rowHeight]="rowHeight">
        </app-full-bar-compatible>
    </div>
</div>
<div class="rows-container-grouped" [style.grid-template-rows]="templateRows" *ngIf="displayType == displayTypeEnum.GROUPED_TILES">
    <div *ngFor="let row of rows.getAll().reverse();let i = index" style="height: 100%;">
        <app-full-bar-compatible [active]="row.active" [content]="row.texts[0]" [content2]="row.texts[1]"
            [content3]="row.texts[2]" [rowStyle]="rowStyle" *ngIf="row.texts[0] != undefined" [rowHeight]="rowHeight">
        </app-full-bar-compatible>
    </div>
</div>