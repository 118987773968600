<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="m-0 font-weight-bold text-primary">{{ 'reservation-limits-configuration' | translate }}</h5>
    </div>
    <div class="card-body">
        <div class="mb-3">
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>{{ 'turn-on-off' | translate }}</th>
                            <th>{{ 'name' | translate }}</th>
                            <th>{{ 'limit' | translate }}</th>
                            <th>{{ 'category-limit' | translate }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let reservationLimit of reservationLimits">
                            <td>
                                <mat-slide-toggle color="primary" [(ngModel)]="reservationLimit.enabled">
                                </mat-slide-toggle>
                            </td>
                            <td>
                                <span *ngIf="reservationLimit.ipSpecialQuestion">{{ 'ip-address' | translate }}</span>
                                <span *ngIf="!reservationLimit.ipSpecialQuestion">{{ getQuestionName(reservationLimit.questionId) }}</span>
                            </td>
                            <td>
                                <input name="limit" #limit="ngModel" [(ngModel)]="reservationLimit.limit"
                                    class="form-control" number [disabled]="!reservationLimit.enabled">
                            </td>
                            <td>
                                <input name="categoryLimit" #categoryLimit="ngModel"
                                    [(ngModel)]="reservationLimit.categoryLimit" class="form-control" number
                                    [disabled]="!reservationLimit.enabled">
                            </td>
                        </tr>
                </table>
            </div>
            <app-submit-button (click)="saveLimits()" [loading]="formSubmitted" [text]="'save-limits' | translate"></app-submit-button>
        </div>
    </div>
</div>