import { Type } from "@angular/core";
import { BasicLastTicketsListComponent } from "./components/basic-last-tickets-list/basic-last-tickets-list.component";
import { BasicLastTicketsRoomListComponent } from "./components/basic-last-tickets-room-list/basic-last-tickets-room-list.component";
import { BasicListComponent } from "./components/basic-list/basic-list.component";
import { BasicMixListComponent } from "./components/basic-mix-list/basic-mix-list.component";
import { DeskBasicComponent } from "./components/desk-basic/desk-basic.component";
import { CustomisationBarComponent } from "../../shared/customisation-bar/customisation-bar.component";
import { LastTicketsListComponent } from "./components/last-tickets-list/last-tickets-list.component";
import { LocationHeaderComponent } from "./components/location-header/location-header.component";
import { MultimediaComponent } from "./components/multimedia/multimedia.component";
import { SingleTicketDisplayComponent } from "./components/single-ticket-display/single-ticket-display.component";
import { GroupViewComponent } from "./components/group-view/group-view.component";
import { BackofficeComponent } from "./components/backoffice/backoffice.component";
import { BaseComponent } from "../../shared/base-component/base.component";
import { MultideskComponent } from "./components/multidesk/multidesk.component";
import { QueueTicketsListComponent } from "./components/queue-tickets-list/queue-tickets-list.component";
import { MultimediaAndSingleTicketDisplayComponent } from "./components/multimedia-and-single-ticket-display/multimedia-and-single-ticket-display.component";
import { ClockComponent } from "../../shared/utility-components/clock/clock.component";
import { ImageComponent } from "../../shared/utility-components/image/image.component";
import { TextComponent } from "../../shared/utility-components/text/text.component";
import { ListHeaderComponent } from "./components/list-header/list-header.component";

export class MainDisplayComponentRegistry {
    public static instance = new MainDisplayComponentRegistry();
    public registry = new Map<String, Type<BaseComponent>>();
    constructor() {
        this.registry.set("basic-list", BasicListComponent);
        this.registry.set("last-tickets-list", LastTicketsListComponent);
        this.registry.set("group-display-list", GroupViewComponent);
        this.registry.set("location-header", LocationHeaderComponent);
        this.registry.set("customisation-bar", CustomisationBarComponent);
        this.registry.set("basic-mix-list", BasicMixListComponent);
        this.registry.set("multimedia", MultimediaComponent);
        this.registry.set("basic-last-tickets-list", BasicLastTicketsListComponent);
        this.registry.set("basic-last-tickets-room-list", BasicLastTicketsRoomListComponent);
        this.registry.set("single-ticket-display", SingleTicketDisplayComponent);
        this.registry.set("desk-basic-list", DeskBasicComponent);
        this.registry.set("backoffice", BackofficeComponent);
        this.registry.set("multidesk", MultideskComponent);
        this.registry.set("queue-tickets-list", QueueTicketsListComponent);
        this.registry.set("multimedia-and-single-ticket-display", MultimediaAndSingleTicketDisplayComponent);
        this.registry.set("clock", ClockComponent);
        this.registry.set("image", ImageComponent);
        this.registry.set("text", TextComponent);
        this.registry.set("list-header", ListHeaderComponent);
    }
}