import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DeviceConfiguration } from 'projects/qcloud-models/device/device-configuration';
import { QcloudConsoleService } from '../../qcloud-console.service';
import { AdditionalButtonComponentName, SmallLayoutConfigurationService } from '../../services/small-layout-configuration.service';
import { CallTicketButtonComponent } from '../call-ticket-button/call-ticket-button.component';
// import { ExpandMenuButtonComponent } from '../expand-menu-button/expand-menu-button.component';
import { ServedCategoriesButtonComponent } from '../served-categories-button/served-categories-button.component';
import { Ticket } from 'projects/qcloud-models/console/ticket';
import { ButtonComponentName } from '../../services/big-layout-configuration.service';
import { AcceptTicketButtonComponent } from '../accept-ticket-button/accept-ticket-button.component';
import { CallAgainTicketButtonComponent } from '../call-again-ticket-button/call-again-ticket-button.component';
import { CancelTicketButtonComponent } from '../cancel-ticket-button/cancel-ticket-button.component';
import { PauseTicketButtonComponent } from '../pause-ticket-button/pause-ticket-button.component';
import { RedirectTicketButtonComponent } from '../redirect-ticket-button/redirect-ticket-button.component';
import { QueueStateComponent } from '../queue-state/queue-state.component';
import { BlockedCategoriesButtonComponent } from '../blocked-categories-button/blocked-categories-button.component';
import { CallTicketManualButtonComponent } from '../call-ticket-manual-button/call-ticket-manual-button.component';
import { CancelledTicketsButtonComponent } from '../cancelled-tickets-button/cancelled-tickets-button.component';
import { GenerateTicketButtonComponent } from '../generate-ticket-button/generate-ticket-button.component';
import { PausedTicketsButtonComponent } from '../paused-tickets-button/paused-tickets-button.component';
import { ShowTicketManualButtonComponent } from '../show-ticket-manual-button/show-ticket-manual-button.component';

@Component({
  selector: 'small-layout',
  templateUrl: './small-layout.component.html',
  styleUrls: ['./small-layout.component.css']
})
export class SmallConsoleLayoutComponent implements OnInit {

  @Input() public deviceConfiguration: DeviceConfiguration;
  @ViewChild('servedCategoriesButtonComponent') private servedCategoriesButtonComponent: ServedCategoriesButtonComponent;
  @ViewChild('callTicketButtonComponent') public callTicketButtonComponent: CallTicketButtonComponent;
  @ViewChild('acceptTicketButtonComponent') private acceptTicketButtonComponent: AcceptTicketButtonComponent;
  @ViewChild('callAgainTicketButtonComponent') private callAgainTicketButtonComponent: CallAgainTicketButtonComponent;
  @ViewChild('pauseTicketButtonComponent') private pauseTicketButtonComponent: PauseTicketButtonComponent;
  @ViewChild('redirectTicketButtonComponent') private redirectTicketButtonComponent: RedirectTicketButtonComponent;
  @ViewChild('cancelTicketButtonComponent') private cancelTicketButtonComponent: CancelTicketButtonComponent;
  // @ViewChild('expandMenuButtonComponent') private expandMenuButtonComponent: ExpandMenuButtonComponent;
  @ViewChild('queueStateComponent') private queueStateComponent: QueueStateComponent;

  @ViewChild('cancelledTicketsButtonComponent') private cancelledTicketsButtonComponent: CancelledTicketsButtonComponent;
  @ViewChild('pausedTicketsButtonComponent') private pausedTicketsButtonComponent: PausedTicketsButtonComponent;
  @ViewChild('callTicketManualButtonComponent') private callTicketManualButtonComponent: CallTicketManualButtonComponent;
  @ViewChild('blockedCategoriesButtonComponent') private blockedCategoriesButtonComponent: BlockedCategoriesButtonComponent;
  @ViewChild('generateTicketButtonComponent') private generateTicketButtonComponent: GenerateTicketButtonComponent;
  @ViewChild('showTicketManualButtonComponent') private showTicketManualButtonComponent: ShowTicketManualButtonComponent;


  isShowInteractionCard: boolean;
  isTicketCalled: boolean;
  constructor(public consoleService: QcloudConsoleService, public smallLayoutConfigurationService: SmallLayoutConfigurationService) {
    consoleService.ticket.subscribe((val: Ticket) => { val?.caption ? this.isTicketCalled = true : this.isTicketCalled = false; });
  }

  ngOnInit(): void {
    this.isShowInteractionCard = false;
    this.adjustScreen();
  }

  checkLoadConsoleAssets() {
    return this.consoleService.desk && this.consoleService.user && this.consoleService.getAllConsoleCategories()
      && this.consoleService.getCategoriesToCallTicket();
  }

  async adjustScreen() : Promise<void> {
    while(!this.checkIfAllElementsAreLoaded()) {
      await this.delay(300);
    }
    var layoutConfig = this.smallLayoutConfigurationService;
    var buttons = this.getViewChildOperatorTicketButtons();
    var spanQueueStates = this.getViewChildSpanQueueStates();
    var spanCategoryTags = this.getViewChildSpanCategoryTags();
    var spanCategoryStates = this.getViewChildSpanCategoryStates();
    layoutConfig.positionIconQueueState(spanQueueStates);

    switch(this.consoleService.projectType) {
      case(this.consoleService.projectTypeEnum.WEB):
      layoutConfig.minimalizeButtonsMap<ButtonComponentName>(buttons);
      break;

      case(this.consoleService.projectTypeEnum.DEVICE):
      layoutConfig.minimalizeButtonsMapForDevice<ButtonComponentName>(buttons);
      layoutConfig.positionDeviceSpanQueueStateForDevice(spanCategoryTags);
      layoutConfig.positionDeviceSpanQueueCategoryStatesForDevice(spanCategoryStates);
      // layoutConfig.adjustExpandedMenuButton(this.getViewChildExpandedMenu());
      break;
    }

    this.adjustExpandedMenu();
  }

  private checkIfAllElementsAreLoaded() : boolean {
    var icons = this.getViewChildButtons();
    var isAllLoaded = true;
    isAllLoaded = this.checkIfAllElementsAreLoadedMap<ButtonComponentName>(icons, isAllLoaded);
    isAllLoaded = this.queueStateComponent?.spanFaReplyIcon ? isAllLoaded && true : false;
    var additionalButtons = this.getViewChildAdditionalButtons();
    isAllLoaded = this.checkIfAllElementsAreLoadedMap<AdditionalButtonComponentName>(additionalButtons, isAllLoaded);
    var expandMenuButtonSectorTwo = this.getViewChildAdditionalButtonsSectorTwo();
    isAllLoaded = this.checkIfAllElementsAreLoadedMap(expandMenuButtonSectorTwo, isAllLoaded);
    // isAllLoaded = this.getViewChildExpandedMenu() ? isAllLoaded && true : false;
    return isAllLoaded;
  }

  private checkIfAllElementsAreLoadedArray(elements: ElementRef[], isAllLoaded: boolean) : boolean {
    elements.forEach((element: ElementRef) => {
      isAllLoaded = element ? isAllLoaded && true : false;
    });
    return isAllLoaded;
  }

  private getViewChildOperatorTicketButtons() : Map<ButtonComponentName, ElementRef> {
    var buttonComponentName = this.smallLayoutConfigurationService.ButtonComponentNameEnum;
    var buttons = new Map<ButtonComponentName, ElementRef>();
    buttons.set(buttonComponentName.CallTicketButtonComponent, this.callTicketButtonComponent?.button);
    buttons.set(buttonComponentName.CallAgainTicketButtonComponent, this.callAgainTicketButtonComponent?.button);
    buttons.set(buttonComponentName.AcceptTicketButtonComponent, this.acceptTicketButtonComponent?.button);
    buttons.set(buttonComponentName.PauseTicketButtonComponent, this.pauseTicketButtonComponent?.button);
    buttons.set(buttonComponentName.RedirectTicketButtonComponent, this.redirectTicketButtonComponent?.button);
    buttons.set(buttonComponentName.CancelTicketButtonComponent, this.cancelTicketButtonComponent?.button);
    return buttons;
  }



  private getViewChildButtons() : Map<ButtonComponentName, ElementRef> {
    var buttonComponentName = this.smallLayoutConfigurationService.ButtonComponentNameEnum;
    var buttons = new Map<ButtonComponentName, ElementRef>();
    buttons.set(buttonComponentName.CallTicketButtonComponent, this.callTicketButtonComponent?.button);
    buttons.set(buttonComponentName.CallAgainTicketButtonComponent, this.callAgainTicketButtonComponent?.button);
    buttons.set(buttonComponentName.AcceptTicketButtonComponent, this.acceptTicketButtonComponent?.button);
    buttons.set(buttonComponentName.PauseTicketButtonComponent, this.pauseTicketButtonComponent?.button);
    buttons.set(buttonComponentName.RedirectTicketButtonComponent, this.redirectTicketButtonComponent?.button);
    buttons.set(buttonComponentName.CancelTicketButtonComponent, this.cancelTicketButtonComponent?.button);
    return buttons;
  }
  // private getViewChildExpandedMenu() : ElementRef {
  //   return this.expandMenuButtonComponent?.button;
  // }

  private checkIfAllElementsAreLoadedMap<E>(elements: Map<E, ElementRef>, isAllLoaded: boolean) : boolean {
    elements.forEach((element: ElementRef, key: E) => {
      isAllLoaded = element ? isAllLoaded && true : false;
    });
    return isAllLoaded;
  }

  public showInteractionCard() : void {
    this.isShowInteractionCard = true;
  }

  public hideInteractionCard() : void {
    this.isShowInteractionCard = false;
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
  // moveToExpandedMenu() : void {
  //   if(this.expandMenuButtonComponent.isMenuExpanded) {
  //     this.servedCategoriesButtonComponent.button.nativeElement.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }

  private getViewChildSpanQueueStates() : ElementRef[] {
    var queueStates = [];
    if(this.queueStateComponent?.spanCategories){
      this.queueStateComponent.spanCategories.forEach(component => queueStates.push(component));
    }

    if(this.queueStateComponent?.spanFaReply){
      queueStates.push(this.queueStateComponent.spanFaReply);
    }
    return queueStates;
  }
  private getViewChildSpanCategoryTags() : ElementRef[] {
    var tags = [];
    if(this.queueStateComponent?.spanCategoryTags) {
      this.queueStateComponent.spanCategoryTags.forEach(component => tags.push(component));
    }

    if(this.queueStateComponent?.spanFaReplyIcon) {
      tags.push(this.queueStateComponent.spanFaReplyText);
    }
    return tags;
  }


  private getViewChildSpanCategoryStates() : ElementRef[] {
    var states = [];
    if(this.queueStateComponent?.spanCategoryStates) {
      this.queueStateComponent.spanCategoryStates.forEach(component => states.push(component));
    }
    if(this.queueStateComponent?.spanFaReplyIcon) {
      states.push(this.queueStateComponent.spanFaReplyIcon);
    }
    return states;
  }



  adjustExpandedMenu() : void {
    var layoutConfig = this.smallLayoutConfigurationService;
    var additionalButtons = this.getViewChildAdditionalButtons();
    layoutConfig.minimalizeAdditionalButtonsMap<AdditionalButtonComponentName>(additionalButtons);

    var additionalButtonsSectorTwo = this.getViewChildAdditionalButtonsSectorTwo();
    layoutConfig.minimalizeAdditionalButtonsMapSectorTwo<AdditionalButtonComponentName>(additionalButtonsSectorTwo);
  }


  private getViewChildAdditionalButtons() : Map<AdditionalButtonComponentName, ElementRef> {
    var buttonComponentName = this.smallLayoutConfigurationService.AdditionalButtonComponentName;
    var buttons = new Map<AdditionalButtonComponentName, ElementRef>();
    buttons.set(buttonComponentName.CancelledTicketsButton, this.cancelledTicketsButtonComponent?.button);
    buttons.set(buttonComponentName.PausedTicketsButton, this.pausedTicketsButtonComponent?.button);
    buttons.set(buttonComponentName.CallTicketManualButton, this.callTicketManualButtonComponent?.button);
    buttons.set(buttonComponentName.BlockedCategoriesButton, this.blockedCategoriesButtonComponent?.button);
    buttons.set(buttonComponentName.ServedCategoriesButton, this.servedCategoriesButtonComponent?.button);
    buttons.set(buttonComponentName.GenerateTicketButton, this.generateTicketButtonComponent?.button);
    buttons.set(buttonComponentName.ShowTicketManualButton, this.showTicketManualButtonComponent?.button);
    return buttons;
  }

  private getViewChildAdditionalButtonsSectorTwo() : Map<AdditionalButtonComponentName, ElementRef> {
    var buttonComponentName = this.smallLayoutConfigurationService.AdditionalButtonComponentName;
    var buttons = new Map<AdditionalButtonComponentName, ElementRef>();
    buttons.set(buttonComponentName.BlockedCategoriesButton, this.blockedCategoriesButtonComponent?.button);
    buttons.set(buttonComponentName.ServedCategoriesButton, this.servedCategoriesButtonComponent?.button);
    buttons.set(buttonComponentName.GenerateTicketButton, this.generateTicketButtonComponent?.button);
    return buttons;
  }
}