<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 *ngIf="!ctx.editForm" class="m-0 font-weight-bold text-primary">{{ 'add-schedule' | translate }}</h5>
        <div *ngIf="ctx.editForm" class="row">
            <h5 class="col-8 m-0 font-weight-bold text-primary">{{ 'edit-schedule' | translate }}</h5>
            <div class="text-right col-4">
                <h4 class="small font-weight-bold text-left">{{ 'schedule-size' | translate }}
                    <span class="float-right">2 GB</span>
                </h4>
                <div class="progress">
                    <div class="progress-bar bg-info" role="progressbar" [style.width.%]="getScheduleSpace()">
                        <span *ngIf="getScheduleSpace() > 15">
                            {{ ctx.schedule.size / 1024 / 1024 / 1024 | number : '1.2-2' }} GB
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="ctx.schedule" class="card-body">
        <form #form="ngForm" autocomplete="off">
            <div class="form-group">
                *{{ 'schedule-name' | translate }}
                <div class="input-group">
                    <input name="name" #name="ngModel" [(ngModel)]="ctx.schedule.name"
                        class="form-control" required>
                </div>
                <div *ngIf="name.invalid && (name.dirty || name.touched)" class="text-danger mx-2 my-1">
                    <div *ngIf="name.errors.required">{{ 'schedule-name-required' | translate }}</div>
                </div>
            </div>
            <div *ngIf="ctx.editForm && ctx.playlists">
                <mat-form-field>
                    <mat-label>{{ 'default-playlist' | translate }}</mat-label>
                    <mat-select  matNativeControl name="defaultPlaylistId" #defaultPlaylistId="ngModel"
                        [(ngModel)]="ctx.schedule.defaultPlaylistId" required>
                        <mat-option *ngFor="let playlist of ctx.playlists" [value]="playlist.id">
                            {{ playlist.name }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="defaultPlaylistId.hasError('required')">{{ 'no-selected-playlist' | translate }}</mat-error>
                </mat-form-field>

                <div>{{ 'publish-schedule' | translate }}</div>
                    <div *ngIf="checkIfEmptyTags()">
                        <div class="text-danger mx-2">{{ 'select-tags' | translate }}</div>
                    </div>
                <div class="row">
                    <div class="col-lg-3 col-md-6">
                        <app-assign-tags [tagMultiCtrl]="ctx.tagMultiCtrl" [tags]="ctx.tags" [title]="thematicTags">
                        </app-assign-tags>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <app-assign-system-tag-device-type [deviceTypeMultiCtrl]="ctx.deviceTypeMultiCtrl"
                            [deviceTypes]="ctx.deviceTypes">
                        </app-assign-system-tag-device-type>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <app-assign-system-tag-location [locationMultiCtrl]="ctx.locationMultiCtrl"
                            [locations]="ctx.locations">
                        </app-assign-system-tag-location>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <mat-form-field>
                            <mat-label>{{ 'operation-type' | translate }}</mat-label>
                            <mat-select matNativeControl name="tagOperationType" #tagOperationType="ngModel"
                                [(ngModel)]="ctx.schedule.tagOperationType">
                                <mat-option [value]="TagsOperationTypes.Sum">{{ 'sum' | translate }}</mat-option>
                                <mat-option [value]="TagsOperationTypes.Product">{{ 'product' | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>


                <app-calendar [schedulePlaylists]="ctx.schedule.playlistsWithWeeklyDisplayInformation"
                    [playlists]="ctx.playlists" [ctx]="scheduleCtx">
                </app-calendar>

                <div class="row mb-2">
                    <h4 class="font-weight-bold col-9">{{ 'assigned-playlists' | translate }}</h4>
                    <button class="btn btn-primary col-3" (click)="managePlaylist(ctx.playlists)">
                        {{ 'assign-playlist' | translate }}
                    </button>
                </div>
                <div class="table-responsive">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <td>{{ 'playlist-name' | translate }}</td>
                                <td>{{ 'terms' | translate }}</td>
                                <td>{{ 'hours' | translate }}</td>
                                <td>{{ 'weekdays' | translate }}</td>
                                <td>{{ 'options' | translate }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let playlist of ctx.schedule.playlistsWithWeeklyDisplayInformation; let i = index">
                                <td>
                                    <div class="btn btn-circle btn-sm btn-calendar"
                                        [ngStyle]="{'background-color': setColor(i) }">
                                </div>
                                    {{ playlist.mediaPlaylist.name }}
                                </td>
                                <td>{{ playlist.dateStart | date:'yyyy-MM-dd' }} - {{ playlist.dateEnd | date:'yyyy-MM-dd' }}</td>
                                <td>{{ playlist.weeklySchedule.beginTime | date:'HH:mm':'UTC' }} - {{ playlist.weeklySchedule.endTime | date:'HH:mm':'UTC' }}</td>
                                <td>
                                    <span *ngFor="let day of playlist.weeklySchedule.daysOfWeek" class="btn btn-blue btn-circle btn-sm mx-1 mb-1">
                                            {{ dayNames[day] | translate | slice:0:3 }}
                                    </span>
                                </td>
                                <td>
                                    <button class="btn btn-info btn-circle btn-sm mr-1"
                                        (click)="managePlaylist(ctx.playlists, playlist)">
                                        <i class="fas fa-fw fa-edit"></i>
                                    </button>
                                    <button class="btn btn-danger btn-circle btn-sm"
                                        (click)="ctx.deleteSchedulePlaylist(playlist)">
                                        <i class="fas fa-fw fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

            <div class="form-group row">
                <button class="btn btn-danger m-1" routerLink="/schedules">{{ 'back' | translate }}</button>
                <button class="btn btn-primary m-1" type="submit" [disabled]="ctx.formSubmitted || form.invalid
                || (ctx.editForm && checkIfEmptyTags())"
                    (click)="ctx.onSubmit(form)">
                    <span *ngIf="ctx.formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                        aria-hidden="true"></span>
                    {{ 'save-schedule' | translate }}
                </button>
            </div>
            <label *ngIf="form.invalid" class="text-danger">*{{ 'complete-required-fields' | translate }}</label>
        </form>
    </div>
</div>

<!-- Assign playlist modal -->
<ng-template #modalContent let-close="close">
    <div class="modal-header">
        <h5 class="modal-title">{{ 'assign-playlist' | translate }}</h5>
      <button type="button" class="close" (click)="close()" mat-dialog-close>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" mat-dialog-content>
        <mat-form-field>
            <mat-label>{{ 'playlist' | translate }}</mat-label>
            <mat-select matNativeControl [(ngModel)]="modalData.playlist.mediaPlaylistId" required>
                <mat-option *ngFor="let p of modalData.playlists" [value]="p.id">{{ p.name }}</mat-option>
            </mat-select>
        </mat-form-field>

        <app-date-range-picker [startDate]="dateStart" [endDate]="dateEnd"
            (rangeToEmit)="getRange($event)">
        </app-date-range-picker>

        <div class="mb-3">
            {{ 'weekdays' | translate }}:
            <div class="btn-group">
                <button class="btn btn-primary" (click)="selectAll()">
                    {{ 'all' | translate }}
                </button>
                <button class="btn btn-primary" (click)="selectWorkweek()">
                    {{ 'workweek' | translate }}
                </button>
                <button class="btn btn-primary" (click)="selectWeekend()">
                    {{ 'weekend' | translate }}
                </button>
                <button class="btn btn-primary" (click)="unselectAll()">
                    {{ 'deselect' | translate }}
                </button>
            </div>
        </div>

        <div class="form-group">
            <div class="item-group">
                <mat-checkbox *ngFor="let day of dayNames; let i = index" [(ngModel)]="selectedDays[i]"
                    color="primary" class="mr-4">{{ day | translate }}
                </mat-checkbox>
            </div>
        </div>

        <app-time-range-picker [beginTime]="beginTime" [endTime]="endTime"
            (rangeToEmit)="getTimeRange($event)">
        </app-time-range-picker>

    </div>
    <div class="modal-footer">
        <label *ngIf="checkDisabled(modalData.playlist)" class="text-danger">*{{ 'complete-all-fields' | translate }}</label>
        <button class="btn btn-primary" (click)="assignPlaylist(modalData.playlist); close()"
            [disabled]="checkDisabled(modalData.playlist)" mat-dialog-close>
            <span>{{ 'assign-playlist' | translate }}</span>
        </button>
    </div>
</ng-template>
