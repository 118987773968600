import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { User } from 'projects/qcloud-models/user/user.model';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { UserService } from 'projects/qcloud-rest-client/src/lib/user.service';
import { NormalizeCharactersPipe } from '../../../shared/normalize-characters.pipe';
import { QcloudRoleService } from '../../../../../../qcloud-rest-client/src/lib/role/qcloud-role.service';
import { QcloudRole } from '../../../../../../qcloud-models/qcloud-role/qcloud-role';

@Component({
  selector: 'app-system-user',
  templateUrl: './system-user.component.html',
  styleUrls: ['./system-user.component.css'],
  providers: [NormalizeCharactersPipe]
})
export class SystemUserComponent implements OnInit {

  user: User;
  loggedUser: User;
  isEditForm: boolean;
  formSubmitted: boolean = false;
  qcloudRoles: QcloudRole[];
  protected initialQcloudRoles: QcloudRole[];
  public qcloudRolesMultiCtrl: UntypedFormControl;
  qcloudRolesToAssign: string = 'qcloud.roles-to-assign-required';
  noSelectedQcloudRoles = 'qcloud.no-selected-roles';
  loggedUserMainRoleLocationIds: number[] = [];

  constructor(public authService: AuthService,
    private userService: UserService, private translate: TranslateService, private toastr: ToastrService,
    private normalizeCharactersPipe: NormalizeCharactersPipe, private route: ActivatedRoute,
    private router: Router, public locationService: LocationService, private qcloudRoleService: QcloudRoleService) { }

  ngOnInit(): void {
    this.loggedUserMainRoleLocationIds = this.authService.getTokenLocationIds();
    let userId: string;
    this.route.params.subscribe(params => {
      userId = params['id']
    });
    if (userId) {
      this.isEditForm = true;
      this.getUser(userId);
    } else {
      this.isEditForm = false;
      this.initializeUser();
    }
  }

  initializeUser() {
    this.user = new User();
    this.user.systemId = +this.authService.getSystemId()
    this.user.active = true;
    this.user.qcloudRoles = [];
    this.getQcloudRoles();
  }

  getUser(userId: string) {
    this.userService.getUser(userId).subscribe(
      user => {
        this.user = user;
        this.getQcloudRoles();
      }
    );
  }

  getQcloudRoles() {
    this.qcloudRoleService.getSystemQcloudRoles().subscribe((data) => {
      this.qcloudRoles = data;
      this.initialQcloudRoles = new Array<QcloudRole>();
      for (let j = 0; j < this.user.qcloudRoles.length; j++) {
        for (let i = 0; i < this.qcloudRoles.length; i++) {
          if (this.qcloudRoles[i].id == this.user.qcloudRoles[j].id) {
            this.initialQcloudRoles.push(this.qcloudRoles[i]);
          }
        }
      }
      this.qcloudRolesMultiCtrl = new UntypedFormControl(this.initialQcloudRoles);
    });
  }

  onSubmit(qcloudRoles: QcloudRole[]) {
    this.user.qcloudRoles = qcloudRoles;
    if (this.isEditForm) {
      this.editUser();
    } else {
      this.addUser();
    }
  }

  addUser() {
    this.userService.addUser(this.user).subscribe(
      res => {
        this.router.navigate(['/system-users']);
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('user-error').subscribe((res: string) => {
          let error = res;
          this.translate.get('username-taken').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  editUser() {
    this.userService.editUser(this.user).subscribe(
      res => {
        this.router.navigate(['/system-users']);
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('user-edit-error').subscribe((res: string) => {
          let error = res;
          this.translate.get('username-taken').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  createUsername() {
    if (!this.user.userName && this.user.firstName && this.user.lastName) {

      this.user.userName = (this.user.firstName[0] + this.user.lastName).toLowerCase();
      this.user.userName = this.normalizeCharactersPipe.transform(this.user.userName);
    }
  }

  resetPassword(userId: string) {
    this.userService.resetPassword(userId).subscribe(res => {
      this.translate.get('reset-password-default').subscribe((result: string) => {
        this.toastr.success(result);
      });
    });
  }

  newBasedOnExisting() {
    this.isEditForm = false;
  }
  isFormReadyForSubmit() {
    var choosenLocations = this.qcloudRolesMultiCtrl?.value as QcloudRole[];
    return choosenLocations && choosenLocations.length != 0;
  }
}
