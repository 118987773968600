<div>
    <div class="row mt-3">
        <h6 class="col-10 pl-4">{{ 'reservation-questionaire-translation' | translate }}</h6>
        <div class="col-2 pr-4 text-right">
            <app-pagination [translations]="translations"
                (filteredTranslationsEmitter)="getFilteredTranslations($event)">
            </app-pagination>
        </div>
    </div>

    <hr>

    <div *ngIf="reservationQuestions" class="card-body">

        <div class="row">
            <div class="col-4">

                <p class="font-weight-bold">{{ getLocaleName(locationLocaleMain) | translate }}</p>

                <div *ngFor="let question of reservationQuestions" class="form-group">
                    <div class="input-group">
                        <input disabled [(ngModel)]="question.question" class="form-control">
                    </div>
                </div>
            </div>
            <div class="col-4" *ngFor="let translation of deleteLocaleMain(isOnPage()); let i = index">
                <p class="font-weight-bold">{{ getLocaleName(translation.locale) | translate }}</p>

                <div *ngFor="let question of reservationQuestions" class="form-group">
                    <div class="input-group">
                        <input class="form-control" name="question" #question="ngModel"
                            [(ngModel)]="question.translations[startIndex + i].translation">
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group">
            <app-submit-button (click)="onSubmit()" type="submit" [loading]="formSubmitted"
                [text]="'save-reservation-questions-translations' | translate">
            </app-submit-button>
        </div>
    </div>
</div>