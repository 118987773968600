import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.css']
})
export class DeleteModalComponent implements OnInit {

  @Input() info: string;
  @Input() confirm: string;
  @Output() confirmed = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  send() {
    this.confirmed.emit(true);
  }
}
