import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SystemCategory } from 'projects/qcloud-models/category/system-category.model';
import { TicketsQuestion } from 'projects/qcloud-models/device/ticket-question';
import { SystemCategoryService } from 'projects/qcloud-rest-client/src/lib/category/system-category.service';
import { TicketSystemCategoryQuestionService } from 'projects/qcloud-rest-client/src/lib/category/ticket-system-category-question.service';
import { SystemService } from 'projects/qcloud-rest-client/src/lib/system.service';

@Component({
  selector: 'app-system-ticket-question',
  templateUrl: './system-ticket-question.component.html',
  styleUrls: ['./system-ticket-question.component.css']
})
export class SystemTicketQuestionComponent implements OnInit {
  public categoryMultiCtrl: UntypedFormControl;
  servedCategories: string = 'served-categories';
  noSelectedCategories = 'no-selected-categories';
  initialCategories: SystemCategory[];
  ticketQuestion: TicketsQuestion;
  id: number;
  categories: SystemCategory[];
  isEditForm: boolean;
  constructor(private ticketSystemCategoryQuestionService: TicketSystemCategoryQuestionService, private router: Router, private route: ActivatedRoute,
    private translate: TranslateService, private toastr: ToastrService,
    private systemCategoryService: SystemCategoryService) { }

  ngOnInit(): void {

    let id: number;
    this.route.params.subscribe(params => {
      id = params['id']
    });
    if (id) {
      this.isEditForm = true;
      this.ticketSystemCategoryQuestionService.getTicketQuestion(id).subscribe(data => {
        this.ticketQuestion = data;
        this.getCategories();
      }, err => { });
    } else {
      this.isEditForm = false;
      this.ticketQuestion = new TicketsQuestion();
      this.ticketQuestion.systemCategories = [];
      this.getCategories();
    }
  }

  getCategories() {
    this.systemCategoryService.getCategories((data) => {
      this.categories = data;
      this.initialCategories = new Array<SystemCategory>();
      for (let j = 0; j < this.ticketQuestion.systemCategories.length; j++) {
        for (let i = 0; i < this.categories.length; i++) {
          if (this.categories[i].id == this.ticketQuestion.systemCategories[j].id) {
            this.initialCategories.push(this.categories[i]);
          }
        }
      }
      this.categoryMultiCtrl = new UntypedFormControl(this.initialCategories);
    }, () => { });

  }


  onSubmit() {
    this.ticketQuestion.systemCategories = this.categoryMultiCtrl?.value
    this.ticketSystemCategoryQuestionService.setTicketQuestion(this.ticketQuestion).subscribe(
      res => {
        this.translate.get('ticket-question-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.translate.get('ticket-question-error').subscribe((res: string) => {
          let error = res;
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }
}
