<div class="text-container">
    <div class="ticket-text">
        <div [fittext]="true" [activateOnResize]="true" [modelToWatch]="ticket">
            {{ ticket }}
        </div>
    </div>
    <div class="space"></div>
    <div class="desk-text">
        <div [fittext]="true" [activateOnResize]="true" [modelToWatch]="desk">
            {{ desk }}
        </div>
    </div>
    <div class="called-ticket-text" [ngClass]="{ 'active': active }">
        <div [fittext]="true" [activateOnResize]="true" [modelToWatch]="ticketCaption">
            {{ ticketCaption }}
        </div>
    </div>
    <div class="space"></div>
    <div class="called-desk-text" [ngClass]="{ 'active': active }">
        <div [fittext]="true" [activateOnResize]="true" [modelToWatch]="deskNumber">
            {{ deskNumber }}
        </div>
    </div>
</div>