<div class="card shadow mb-4">
    <div class="card-header py-3">
        <div class="row">
            <h5 class="col-8 m-0 font-weight-bold text-primary">{{ 'system-users' | translate }}</h5>
            <div class="text-right col-4">
                <button type="button" class="btn btn-primary btn-circle btn-sm" routerLink="/system-user"
                    matTooltip="{{ 'add' | translate }}" tooltipClass="tooltip-primary">
                    <i class="fas fa-fw fa-plus"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{{ 'username' | translate }}</th>
                        <th>{{ 'current-desk' | translate }}</th>
                        <th>{{ 'current-location-tag' | translate }}</th>
                        <th>{{ 'name-and-surname' | translate }}</th>
                        <th>{{ 'qcloud.role-name' | translate }}</th>
                        <th>{{ 'activity' | translate }}</th>
                        <th>{{ 'options' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of users; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>{{ user.userName }}</td>
                        <td>{{ getCurrentDesk(user.id) }}</td>
                        <td>{{getCurrentLoggedLocationTagInDesk(user.id) }}</td>
                        <td>{{ user.firstName }} {{ user.lastName }}</td>
                        <td> <span (click)="openRoleDetails(user.qcloudRoles)" tooltipClass="tooltip-primary"
                                matTooltip="{{'show-details' | translate }}" class="popup-button btn btn-outline-info">{{ user.mainRole?.roleName ?? '' }}</span></td>
                        <td>
                            <mat-checkbox [checked]="user.active" color="primary" (click)="changeUserActivity(user)">
                            </mat-checkbox>
                        </td>
                        <td>
                            <div class="row">
                                <button class="btn btn-info btn-circle btn-sm mx-1 mb-1"
                                    [routerLink]="['/system-user/', user.id]" matTooltip="{{ 'edit' | translate }}"
                                    tooltipClass="tooltip-info">
                                    <i class="fas fa-fw fa-edit"></i>
                                </button>
                                <button (click)="selectedUserId = user.id"
                                    class="btn btn-danger btn-circle btn-sm mx-1 mb-1"
                                    matTooltip="{{ 'delete' | translate }}" tooltipClass="tooltip-danger"
                                    data-toggle="modal" data-target="#deleteModal">
                                    <i class="fas fa-fw fa-trash"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-hidden="true">
    <app-delete-modal [info]="'delete-confirmation'" [confirm]="'delete'"
        (confirmed)="onDeleteConfirm($event)"></app-delete-modal>
</div>