<div>
    <div class="form-group">
        <div class="font-weight-bold">{{ 'desk-plural' | translate }}</div>
        <div class="input-group">
            <mat-form-field [color]="color">
                <mat-select [formControl]="deskMultiCtrl" [multiple]="true" #multiSelect
                    (selectionChange)="setDesks($event.value)">
                    <mat-option>
                        <ngx-mat-select-search [showToggleAllCheckbox]="true" (toggleAll)="toggleSelectAll($event)"
                            [formControl]="deskMultiFilterCtrl"
                            toggleAllCheckboxTooltipMessage="{{ 'select-unselect-all' | translate }}"
                            [toggleAllCheckboxTooltipPosition]="'above'"
                            placeholderLabel="{{ 'search-desk' | translate }}"
                            noEntriesFoundLabel="{{ 'no-matching-desk' | translate }}">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let desk of filteredDesksMulti | async" [value]="desk">
                        {{ desk.name }}
                        <b>{{ desk.deskNumber }}</b>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="deskMultiCtrl.invalid && (deskMultiCtrl.dirty || deskMultiCtrl.touched)" class="mx-2">
            <div *ngIf="deskMultiCtrl.value == null || deskMultiCtrl?.value.length == 0">
                {{ 'no-selected-desks' | translate }}</div>
        </div>
        <ul *ngFor="let desk of deskMultiCtrl?.value; let i = index">
            <li class="item-group">
                {{ desk.name }}
                <b>{{ desk.deskNumber }}</b>
                <hr>
            </li>
        </ul>
    </div>
</div>