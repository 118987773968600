import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LocationData } from 'projects/qcloud-models/location/location.model';
import { TenantConfig } from 'projects/qcloud-models/system/tenant-config.model';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-locations-license',
  templateUrl: './locations-license.component.html',
  styleUrls: ['./locations-license.component.css']
})
export class LocationsLicenseComponent implements OnInit {

  @Input() locations: LocationData[];
  @Input() tenant: TenantConfig;
  location = new Subject<LocationData>();
  changedLocations = new Array<LocationData>();

  constructor(private locationService: LocationService, private toastr: ToastrService,
    private translate: TranslateService) {
    }

  ngOnInit(): void {
    this.initDebounce();
  }

  initDebounce() {
    this.location.pipe(debounceTime(1500), distinctUntilChanged()).subscribe(location => {
      this.finalUpdateLocationList(this.changedLocations);
      this.changedLocations = [];
     });
  }

  updateLocation(location: LocationData) {
    this.location.next(location);
    if(this.changedLocations.includes(location) == false) {
      this.changedLocations.push(location);
    }
  }

  finalUpdateLocationList(locations: Array<LocationData>) {
    this.initDebounce();
    locations.forEach(location => {
      this.locationService.editLocationData(location).subscribe(
        res => {
          this.locationService.locationData = res;
          this.translate.get('licence-update-success').subscribe((res: string) => {
            this.toastr.success(res);
          });
        },
        err => {
          this.translate.get('licence-exceeded').subscribe((error: string) => {
            this.toastr.error(error);
          });
        }
      );
    });
  }
}
