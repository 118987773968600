<div class="container">

  <!-- Outer Row -->
  <div class="row justify-content-center">

    <div class="col-xl-10 col-lg-12 col-md-9">

      <div class="card o-hidden border-0 shadow-lg my-5">
        <div class="card-body p-0">
          <!-- Nested Row within Card Body -->
          <div class="row">
            <div class="col-lg-6 d-none d-lg-block center">
              <img src="assets/images/QCloud.png" alt="QCloud" height="100" width="400">
            </div>
            <div class="col-lg-6 verticalLine">
              <div class="p-5">
                <div class="text-center">
                  <h1 class="h4 text-gray-900 mb-4">{{ 'log-in-to-qcloud-panel' | translate }}!</h1>
                </div>
                <form #form="ngForm" class="user" (keyup.enter)="onSubmit()">
                  <div class="form-group">
                    <input class="form-control form-control-user" id="exampleInputUsername" #username="ngModel"
                      name="username" [(ngModel)]="qcloudRestClientService.loginForm.username"
                      placeholder="{{ 'username' | translate }}">
                  </div>
                  <div class="form-group">
                    <input type="password" class="form-control form-control-user" id="exampleInputPassword"
                      #password="ngModel" name="password" [(ngModel)]="qcloudRestClientService.loginForm.password"
                      placeholder="{{ 'password' | translate }}">
                  </div>
                  <div class="form-group">
                    <div class="custom-control custom-checkbox small">
                      <input type="checkbox" class="custom-control-input" id="customCheck">
                      <label class="custom-control-label" for="customCheck">{{ 'remember-me' | translate }}</label>
                    </div>
                  </div>
                  <a (click)="onSubmit()" class="btn btn-primary btn-user btn-block btn-login">
                    {{ 'log-in' | translate }}
                  </a>
                </form>
                <hr>
                <div class="text-center">
                  <a class="small" target="_blank" href="https://qcloud.pl/kontakt/">
                    {{ 'forgot-password' | translate}}?</a>
                </div>
                <div class="text-center">
                  <a class="small" target="_blank" href="https://qcloud.pl/kontakt/">
                    {{ 'no-account' | translate }}? {{'sign-up' | translate }}!</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>