import { ActivityTime } from "../activity-time";

export class ReservationSchedule {
    public id: number;
    public name: string;
    public categoryBlockingTimes: ActivityTime[];
    public categoryCapacity: number;
    public reservationIntervalMins: number;
    public days: number[];
    public startTime: Date;
    public endTime: Date;
    public defaultConfiguration: boolean;
}