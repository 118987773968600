import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Device } from 'projects/qcloud-models/device/device.model';
import { Desk } from 'projects/qcloud-models/desk/desk';

@Component({
  selector: 'app-customer-survey-display',
  templateUrl: './customer-survey-display.component.html',
  styleUrls: ['./customer-survey-display.component.css'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CustomerSurveyDisplayComponent implements OnInit {

  @Input() device: Device;
  @Input() desks: Desk[];

  constructor() { }

  ngOnInit(): void {
  }

}
