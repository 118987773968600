import { Component, OnInit } from '@angular/core';
import { TicketLayout } from 'projects/qcloud-models/location/ticket-layout.model';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ticket-layout',
  templateUrl: './ticket-layout.component.html',
  styleUrls: ['./ticket-layout.component.css']
})
export class TicketLayoutComponent implements OnInit {

  ticketLayout: TicketLayout;
  logoPath: string;
  formSubmitted: boolean = false;

  constructor(private locationService: LocationService, private toastr: ToastrService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.getTicketLayout();
  }

  getTicketLayout() {
    this.locationService.getTicketLayout().subscribe(
      ticketLayout => {
        this.ticketLayout = ticketLayout;
        this.logoPath = this.ticketLayout.logoPath;
      }
    );
  }

  onSubmit() {
    this.ticketLayout.logoPath = this.logoPath;
    this.locationService.saveTicketLayout(this.ticketLayout).subscribe(
      res => {
        this.formSubmitted = false;
        this.translate.get('ticket-design-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('ticket-design-error').subscribe((res: string) => {
          let error = res;
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  deleteTicketLayout() {
    this.locationService.deleteTicketLayout().subscribe(
      res => {
        this.getTicketLayout();
        this.translate.get('ticket-design-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.translate.get('ticket-design-error').subscribe((res: string) => {
          let error = res;
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  getLogoPath(logoPath: string) {
    this.logoPath = logoPath;
  }

}
