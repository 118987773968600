import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseActivationData } from 'projects/qcloud-models/system/base-activation-data.model';
import { TenantConfig } from 'projects/qcloud-models/system/tenant-config.model';
import { SystemService } from 'projects/qcloud-rest-client/src/lib/system.service';

@Component({
  selector: 'app-system-license',
  templateUrl: './system-license.component.html',
  styleUrls: ['./system-license.component.css']
})
export class SystemLicenseComponent implements OnInit {

  @Input() tenant: TenantConfig;
  baseActivationData: BaseActivationData;
  formSubmitted: boolean = false;
  @Output() updateInfo = new EventEmitter<boolean>();

  constructor(private service: SystemService, private toastr: ToastrService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.baseActivationData = new BaseActivationData();
  }

  activateLicense() {
    this.formSubmitted = true;
    this.service.activate(this.baseActivationData).subscribe(
      res => {
        this.tenant = res;
        this.updateInfo.emit(true);
        this.translate.get('license-activated').subscribe((res: string) => {
          this.toastr.success(res);
        });
        this.formSubmitted = false;
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('license-activated-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

}
