import { Injectable } from "@angular/core";
import { ElectronService } from "projects/qcloud-device/src/app/services/electron.service";

@Injectable()
export class SaveScreenSizeService {
  private heigth: number;
  private width: number;
  constructor(public electronService: ElectronService){}

  updateScreenSize(heigth: number, width: number) {
    this.heigth = heigth;
    this.width = width;
  }

  saveDeviceScreeenSize(userId : string) {
    if (this.electronService.ipcRenderer != null) {
      var data: any = {};
      data.height = this.heigth;
      data.width = this.width;
      data.userId = userId;
      this.electronService.ipcRenderer.send("saveDeviceScreeenSize", data);
    }
  }

  changeScreenToSavedSize(userId : string) {
    if (this.electronService.ipcRenderer != null) {
      var data: any = {};
      data.userId = userId;
      this.electronService.ipcRenderer.send("changeScreenToSavedSize", data);
    }
  }
}