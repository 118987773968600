import { Injectable } from '@angular/core';
import { UserService } from 'projects/qcloud-rest-client/src/lib/user.service';
import { ConsoleRestService } from 'projects/qcloud-rest-client/src/lib/console-rest.service';
import { ConsoleLogin } from 'projects/qcloud-models/user/console-login.model';
import { Subject, Observable, Subscription, finalize } from 'rxjs';
import { Ticket } from 'projects/qcloud-models/console/ticket';
import { Category } from 'projects/qcloud-models/category/category.model';
import { DeskService } from 'projects/qcloud-rest-client/src/lib/desk.service';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { User } from 'projects/qcloud-models/user/user.model';
import { PauseTicket } from 'projects/qcloud-models/console/pauseTicket';
import { DeskStatus } from 'projects/qcloud-models/desk/desk-status.enum';
import { TicketsType } from 'projects/qcloud-models/console/ticketsType.enum';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { ConsoleModal } from 'projects/qcloud-models/console/console-modal';
import { TranslateService } from '@ngx-translate/core';
import { StatusCodes } from 'projects/qcloud-models/custom-status-codes';
import { QueueState } from 'projects/qcloud-models/console/queueState';
import { TicketService } from 'projects/qcloud-rest-client/src/lib/ticket.service';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { StompQueueStateService } from 'projects/qcloud-stomp-client/src/lib/clients/stomp-queue-state.service';
import { QcloudStompClientService } from 'projects/qcloud-stomp-client/src/public-api';
import { TicketState } from 'projects/qcloud-models/console/ticketState.enum';
import { ProjectType } from './qcloud-console.component';
import { ToastrTimeoutService } from './services/toastr-timeout.service';
import { SaveScreenSizeService } from './services/save-screen-size.service';
import { ReservationService } from 'projects/qcloud-rest-client/src/lib/reservation.service';
import { TicketReservation } from 'projects/qcloud-models/reservation/ticket-reservation';
import { ToastrService } from 'ngx-toastr';
import { RedirectAction } from './desk/redirect-to-category-modal/redirect-action.enum';
import { TicketNoteService } from '../../../qcloud-rest-client/src/lib/note/ticket-note.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NoteTicketModalPopupComponent } from './desk/note-ticket-modal-popup/note-ticket-modal-popup.component';
import { TicketSystemCategoryQuestionService } from 'projects/qcloud-rest-client/src/lib/category/ticket-system-category-question.service';
import { TicketsQuestion } from 'projects/qcloud-models/device/ticket-question';


@Injectable({
  providedIn: 'root'
})
export class QcloudConsoleService {
  isBlocked: Observable<boolean>;
  private isBlockedSubject: Subject<boolean>;

  isLocked: Observable<boolean>;
  private isLockedSubject: Subject<boolean>;

  ticket: Observable<Ticket>;
  private ticketSubject: Subject<Ticket>;

  etickets: Ticket[];
  eticketsSubscription: Subscription;
  tickets: Observable<Ticket[]>;
  private ticketsSubject: Subject<Ticket[]>;

  queueState: Observable<QueueState>;
  private queueStateSubject: Subject<QueueState>;

  cancelledTickets: Observable<Ticket[]>;
  private cancelledTicketsSubject: Subject<Ticket[]>;

  pausedTickets: Observable<Ticket[]>;
  private pausedTicketsSubject: Subject<Ticket[]>;

  redirectModal: Observable<ConsoleModal>;
  private redirectModalSubject: Subject<ConsoleModal>;

  redirectToCategoryModal: Observable<ConsoleModal>;
  private redirectToCategoryModalSubject: Subject<ConsoleModal>;

  redirectToCategoryHiddenModal: Observable<ConsoleModal>;
  private redirectToCategoryHiddenModalSubject: Subject<ConsoleModal>;

  redirectToDeskModal: Observable<ConsoleModal>;
  private redirectToDeskModalSubject: Subject<ConsoleModal>;

  redirectToForHourModal: Observable<ConsoleModal>;
  private redirectToForHourModalSubject: Subject<ConsoleModal>;

  cancelledTicketsModal: Observable<ConsoleModal>;
  private cancelledTicketsModalSubject: Subject<ConsoleModal>;

  pausedTicketsModal: Observable<ConsoleModal>;
  private pausedTicketsModalSubject: Subject<ConsoleModal>;

  pauseModal: Observable<ConsoleModal>;
  private pauseModalSubject: Subject<ConsoleModal>;

  generateTicketModal: Observable<ConsoleModal>;
  private generateTicketModalSubject: Subject<ConsoleModal>;

  noteTicketModal: Observable<ConsoleModal>;
  private noteTicketModalSubject: Subject<ConsoleModal>;

  manualCallTicketModal: Observable<ConsoleModal>;
  private manualCallTicketModalSubject: Subject<ConsoleModal>;

  manualShowTicketModal: Observable<ConsoleModal>;
  private manualShowTicketModalSubject: Subject<ConsoleModal>;

  blockedCategoriesModal: Observable<ConsoleModal>;
  private blockedCategoriesModalSubject: Subject<ConsoleModal>;

  servedCategoriesModal: Observable<ConsoleModal>;
  private servedCategoriesModalSubject: Subject<ConsoleModal>;

  logoutModal: Observable<ConsoleModal>;
  private logoutModalSubject: Subject<ConsoleModal>;

  deskStatus: Observable<DeskStatus>;
  private deskStatusSubject: Subject<DeskStatus>;

  private categoriesToCallTicket: Category[];
  private allConsoleCategories: Category[];
  private desks: Desk[];
  desk: Desk;
  user: User;
  private _projectType: ProjectType;
  projectTypeEnum = ProjectType;
  private _redirectActionEnum: RedirectAction = RedirectAction.ToCategory;
  ticketQuestions: TicketsQuestion[];
  set redirectActionEnum(value: RedirectAction) {
    this._redirectActionEnum = value;
  }
  get redirectActionEnum(): RedirectAction {
    return this._redirectActionEnum;
  }

  dialogRef: any;
  private previousQueueState: QueueState = new QueueState();

  constructor(private consoleRestService: ConsoleRestService, private ticketService: TicketService,
    private authService: AuthService, private deskService: DeskService, private userService: UserService,
    private categoryService: CategoryService, private toastr: ToastrService, private translate: TranslateService,
    private stompQueueStateService: StompQueueStateService, private qcloudStompClientService: QcloudStompClientService,
    public saveScreenSizeService: SaveScreenSizeService, private reservationService: ReservationService, public toastrTimeoutService: ToastrTimeoutService,
    private ticketNoteService: TicketNoteService, private modalService: MatDialog) {
    this.isBlockedSubject = new Subject<boolean>();
    this.isBlocked = this.isBlockedSubject.asObservable();
    this.isLockedSubject = new Subject<boolean>();
    this.isLocked = this.isLockedSubject.asObservable();
    this.redirectModalSubject = new Subject<ConsoleModal>();
    this.redirectModal = this.redirectModalSubject.asObservable();
    this.redirectToCategoryModalSubject = new Subject<ConsoleModal>();
    this.redirectToCategoryModal = this.redirectToCategoryModalSubject.asObservable();
    this.redirectToCategoryHiddenModalSubject = new Subject<ConsoleModal>();
    this.redirectToCategoryHiddenModal = this.redirectToCategoryHiddenModalSubject.asObservable();
    this.redirectToForHourModalSubject = new Subject<ConsoleModal>();
    this.redirectToForHourModal = this.redirectToForHourModalSubject.asObservable();
    this.redirectToDeskModalSubject = new Subject<ConsoleModal>();
    this.redirectToDeskModal = this.redirectToDeskModalSubject.asObservable();
    this.cancelledTicketsModalSubject = new Subject<ConsoleModal>();
    this.cancelledTicketsModal = this.cancelledTicketsModalSubject.asObservable();
    this.pausedTicketsModalSubject = new Subject<ConsoleModal>();
    this.pausedTicketsModal = this.pausedTicketsModalSubject.asObservable();
    this.pauseModalSubject = new Subject<ConsoleModal>();
    this.pauseModal = this.pauseModalSubject.asObservable();
    this.generateTicketModalSubject = new Subject<ConsoleModal>();
    this.generateTicketModal = this.generateTicketModalSubject.asObservable();
    this.noteTicketModalSubject = new Subject<ConsoleModal>();
    this.noteTicketModal = this.noteTicketModalSubject.asObservable();
    this.manualCallTicketModalSubject = new Subject<ConsoleModal>();
    this.manualCallTicketModal = this.manualCallTicketModalSubject.asObservable();
    this.manualShowTicketModalSubject = new Subject<ConsoleModal>();
    this.manualShowTicketModal = this.manualShowTicketModalSubject.asObservable();
    this.blockedCategoriesModalSubject = new Subject<ConsoleModal>();
    this.blockedCategoriesModal = this.blockedCategoriesModalSubject.asObservable();
    this.servedCategoriesModalSubject = new Subject<ConsoleModal>();
    this.servedCategoriesModal = this.servedCategoriesModalSubject.asObservable();
    this.logoutModalSubject = new Subject<ConsoleModal>();
    this.logoutModal = this.logoutModalSubject.asObservable();
    this.ticketSubject = new Subject<Ticket>();
    this.ticket = this.ticketSubject.asObservable();
    this.ticketsSubject = new Subject<Ticket[]>();
    this.tickets = this.ticketsSubject.asObservable();
    this.queueStateSubject = new Subject<QueueState>();
    this.queueState = this.queueStateSubject.asObservable();
    this.cancelledTicketsSubject = new Subject<Ticket[]>();
    this.cancelledTickets = this.cancelledTicketsSubject.asObservable();
    this.pausedTicketsSubject = new Subject<Ticket[]>();
    this.pausedTickets = this.pausedTicketsSubject.asObservable();
    this.deskStatusSubject = new Subject<DeskStatus>();
    this.deskStatus = this.deskStatusSubject.asObservable();
    this.ticket.subscribe((val) => {
      var isNoteSettedAlready = false;
      if (val.id != -1) {
        this.ticketNoteService.isNoteSettedToTicket(val.id,
          (result) => {
            isNoteSettedAlready = result;
            try { this.isAddNoteEnabled = this.getAllConsoleCategories().filter(x => x.id == val.effectiveCategoryId)[0].isNoteRequired } catch { this.isAddNoteEnabled = false }
            var isNotePopupOpen = this.isNotePopupOpen();
            if (this.isAddNoteEnabled && !isNoteSettedAlready && !isNotePopupOpen) {
              this.openNotePopup();
              this.setIsLockedStatus(true);
            }
          }
          ,
          () => { });
      }
    });
  }
  isAddNoteEnabled: boolean;
  public setCategoriesToCallTicket(categories: Category[]) {
    this.user.mainRole.categories = categories;
    this.categoriesToCallTicket = this.user.mainRole.categories;
  }

  public setServedCategoriesTicket(categories: Category[]) {
    this.user.mainRole.categories = categories;
  }

  public getCategoriesToCallTicket(): Category[] {
    return this.categoriesToCallTicket;
  }

  public setAllConsoleCategories(categories: Category[]) {
    this.allConsoleCategories = categories;
  }

  public getAllConsoleCategories(): Category[] {
    return this.allConsoleCategories;
  }

  public getDesks(): Desk[] {
    return this.desks;
  }

  public getDesk(): Desk {
    return this.desk;
  }

  public setIsBlockedStatus(getIsBlockedStatus: boolean) {
    this.isBlockedSubject.next(getIsBlockedStatus);
  }

  public setIsLockedStatus(getIsLockedStatus: boolean) {
    this.isLockedSubject.next(getIsLockedStatus);
  }

  public loginToConsole(consoleLogin: ConsoleLogin) {
    this.consoleRestService.loginToConsole(consoleLogin,
      (data) => {
        this.authService.setConsoleToken(data.access_token);
        this.saveScreenSizeService.changeScreenToSavedSize(this.authService.getTokenUserId());
      },
      (error) => {
        this.translate.get('log-in-error').subscribe((error: string) => {
          this.translate.get('invalid-username-or-password').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }
  public loginToWebConsole(consoleLogin: ConsoleLogin) {
    this.consoleRestService.loginToWebConsole(consoleLogin,
      (data) => {
        this.authService.setConsoleToken(data.access_token);
        this.saveScreenSizeService.changeScreenToSavedSize(this.authService.getTokenUserId());
      },
      (error) => { }
    );
  }

  public configureConsole(categories: Category[], desks: Desk[], ticketQuestions: TicketsQuestion[]) {
    let deskId = this.authService.getConsoleTokenDeskId();
    this.desk = desks.find(x => x.id == deskId);
    this.desks = desks;
    this.ticketQuestions = ticketQuestions;

    let userId = this.authService.getConsoleTokenUserId();
    this.deskService.getDesk(this.desk.id).subscribe(
      desk => {
        this.desk = desk;
        this.userService.getUser(userId).subscribe(
          user => {
            this.user = user;
            this.setAllConsoleCategories(categories);
            let filteredCategories = categories.filter(
              x => user.mainRole.categories.some(c => c.id == x.id) &&
                this.desk.categories.some(d => d.id == x.id))
              .sort((a, b) => a.categoryTag.localeCompare(b.categoryTag));
            this.setCategoriesToCallTicket(filteredCategories);
            this.setupETickets();
            this.getLastTicket();
            this.stompQueueStateService.deviceQueueState.subscribe(data => {
              this.setQueueState(data);
            });
            this.stompQueueStateService.startListening(this.desk.id);
          }
        );
      }
    );
  }

  setupETickets() {
    if (!this.user.mainRole.hasPermissionToServeETickets) {
      return;
    }
    let category = this.allConsoleCategories.find(x => x.eTicketRedirect);
    try {
      this.getCategoryTickets(category.id);
    } catch {
      return;
    }

    this.eticketsSubscription = this.qcloudStompClientService
      .subscribe(`/exchange/tickets/${this.authService.getSystemId()}.${this.authService.getLocationId()}.ticket`,
        (ticket: Ticket) => {
          if (ticket.id == -1 || ticket.effectiveCategoryId != category.id)
            return;
          if (ticket.ticketState == TicketState.Called) {
            this.etickets = this.etickets.filter(x => x.id != ticket.id);
            this.ticketsSubject.next(this.etickets);
          }
          if (ticket.ticketState == TicketState.New || ticket.ticketState == TicketState.RedirectedToCategory) {
            this.etickets.push(ticket);
            this.ticketsSubject.next(this.etickets);
          }
        });
  }

  public openLogoutModal() {
    this.closeAllModals();
    this.logoutModalSubject.next(new ConsoleModal(true, "console.logout"));
  }

  public logout() {
    this.saveScreenSizeService.saveDeviceScreeenSize(this.authService.getTokenUserId());
    if (this.user) {

      this.consoleRestService.logoutFromConsole(this.user.id, this.desk.id,
        (data) => {
          this.authService.onConsoleLogout();
          try {
            this.eticketsSubscription.unsubscribe();
          }
          catch {
            console.warn("eticketsSubscription.unsubscribe error");
          }
          this.desk = null;
          this.user = null;
          this.setAllConsoleCategories(null);
          this.setCategoriesToCallTicket(null);
          sessionStorage.clear();
          //localStorage.removeItem("deviceGuid");
          localStorage.removeItem("deviceConfig");
          localStorage.removeItem("token");

        },
        () => { }
      );
    }
  }

  public callTicket() {
    this.ticketService.callTicket(this.categoriesToCallTicket,
      (ticket) => this.ticketSubject.next(ticket),
      (error) => this.handleErrorCode(error)
    );
  }

  public callAgainTicket(ticketId: number) {
    this.ticketService.callAgainTicket(ticketId, (ticket) => {
      this.ticketSubject.next(ticket);
    },
      () => { })
  }

  public acceptTicket() {
    this.ticketService.acceptTicket(() => {
      this.ticketSubject.next(new Ticket());
    },
      () => { })
  }

  public acceptAndCallTicket() {
    this.ticketService.acceptAndCallTicket(this.categoriesToCallTicket,
      (ticket) => this.ticketSubject.next(ticket),
      (error) => {
        this.handleErrorCode(error);
        this.ticketSubject.next(null);
      }
    );
  }

  handleErrorCode(error: any) {
    const statusCode = error.error as StatusCodes;
    switch (+statusCode) {
      case StatusCodes.TicketNotFound: {
        this.translate.get('console.ticket-not-found').subscribe(res =>
          this.toastrTimeoutService.info(res));
        break;
      }
      case StatusCodes.TicketCurrentlyProcessed: {
        this.translate.get('console.ticket-currently-processed').subscribe(res =>
          this.toastrTimeoutService.info(res));
        break;
      }
    }
  }

  public getLastTicket() {
    this.ticketService.getLastTicket(this.desk.id, (ticket) => {
      this.ticketSubject.next(ticket);
      var isAddNoteEnabled = false;
      var isNoteSettedAlready = false;
      if (ticket.id != -1) {
        this.ticketNoteService.isNoteSettedToTicket(ticket.id,
          (result) => {
            isNoteSettedAlready = result;
            try {
              isAddNoteEnabled = this.getAllConsoleCategories().filter(x => x.id == ticket.effectiveCategoryId)[0].isNoteRequired;
            } catch {
              isAddNoteEnabled = false;
            }
            var isNotePopupOpen = this.isNotePopupOpen();
            if (isAddNoteEnabled
              && !isNoteSettedAlready
              && !isNotePopupOpen
            ) {
              this.setIsLockedStatus(true);
              this.openNotePopup();
            }
          },
          () => { });
      }
    },
      (erros) => { }
    );
  }


  public openNotePopup() {
    this.setIsBlockedStatus(true);
    switch (this.projectType) {
      case this.projectTypeEnum.WEB:
        this.openNotePopupWeb();
        break;
      case this.projectTypeEnum.DEVICE:
        this.openNoteTicketModal();
        break;
    }
  }

  public isNotePopupOpened: boolean = false;
  public openNotePopupWeb() {
    var matDialogConfig = new MatDialogConfig();
    matDialogConfig.maxHeight = 900;
    matDialogConfig.maxWidth = 1800;
    matDialogConfig.minHeight = 100;
    matDialogConfig.minWidth = 500;
    matDialogConfig.disableClose = true;
    matDialogConfig.data = {
      isReadOnly: false,
      hideHeader: false
    };

    this.dialogRef = this.modalService.open(NoteTicketModalPopupComponent, matDialogConfig);
    this.isNotePopupOpened = true;
    this.dialogRef.afterClosed().subscribe(
      () => {
        this.dialogRef = undefined;
        this.isNotePopupOpened = false;
        this.setIsBlockedStatus(false);
      }
    );

  }

  public isNotePopupOpen(): boolean {
    return this.isNotePopupOpened;
  }


  public cancelTicket() {
    this.ticketService.cancelTicket(() => {
      this.ticketSubject.next(new Ticket());
    },
      () => { })
  }

  public openRedirectModal() {
    this.closeAllModals();
    this.redirectModalSubject.next(new ConsoleModal(true, "console.choose-redirect"));
  }

  public openRedirectToCategoryModal() {
    this.redirectModalSubject.next(new ConsoleModal(false, ""));
    this.redirectToCategoryModalSubject.next(new ConsoleModal(true, "console.redirect"));
  }
  public openRedirectToCategoryHiddenModal() {
    this.redirectActionEnum = RedirectAction.ToCategoryHidden;
    this.redirectModalSubject.next(new ConsoleModal(false, ""));
    this.redirectToCategoryHiddenModalSubject.next(new ConsoleModal(true, "console.redirect"));
  }

  public openRedirectToForHourCategoryModal() {
    this.redirectModalSubject.next(new ConsoleModal(false, ""));
    this.redirectToForHourModalSubject.next(new ConsoleModal(true, "console.redirect"));
  }

  public openRedirectToDeskModal() {
    this.redirectModalSubject.next(new ConsoleModal(false, ""));
    this.redirectToDeskModalSubject.next(new ConsoleModal(true, "console.redirect"));
  }
  public openRedirectToForHourModal() {
    this.redirectModalSubject.next(new ConsoleModal(false, ""));
    this.redirectToDeskModalSubject.next(new ConsoleModal(true, "console.redirect"));
  }

  redirectETicket() {
    let category = this.allConsoleCategories.find(x => x.eTicketRedirect);
    if (category) {
      this.redirectTicketToCategory(category.id);
    }
  }

  public redirectTicketToCategory(categoryId: number) {
    this.redirectActionEnum = RedirectAction.ToCategory;
    this.redirectToCategoryModalSubject.next(new ConsoleModal(false, ""));
    this.ticketService.redirectTicketToCategory(categoryId, () => {
      this.ticketSubject.next(new Ticket());
    },
      () => { });
  };
  public redirectTicketToCategoryHidden(categoryId: number) {
    this.redirectActionEnum = RedirectAction.ToCategoryHidden;
    this.redirectToCategoryHiddenModalSubject.next(new ConsoleModal(false, ""));
    this.ticketService.redirectTicketToCategoryHidden(categoryId, () => {
      this.getLastTicket();
    },
      () => { });
  };
  private _selectedCategory: Category;
  public set selectedCategory(value: Category) {
    this._selectedCategory = value;
  }
  public get selectedCategory(): Category {
    return this._selectedCategory;
  }

  private formatDateToHHMM(date: Date): string {
    return date.toTimeString().split(' ')[0].substring(0, 5);
  }

  public redirectToCategoryTypeForHourModal() {
    this.redirectActionEnum = RedirectAction.ToForHour;
    this.redirectModalSubject.next(new ConsoleModal(false, ""));
    this.redirectToCategoryModalSubject.next(new ConsoleModal(true, "console.redirect"));
  }
  public redirectCategoryToForHourModal(category: Category) {
    this.selectedCategory = category;
    this.redirectActionEnum = RedirectAction.ToForHour;
    this.redirectToCategoryModalSubject.next(new ConsoleModal(false, ""));
    this.redirectToForHourModalSubject.next(new ConsoleModal(true, "console.redirect"));
  }

  public redirectTicketToForHour(reservation: TicketReservation) {
    var reservationTimeInfo = new Date(Date.UTC(
      reservation.reservationTime.getFullYear(),
      reservation.reservationTime.getMonth(),
      reservation.reservationTime.getDate(),
      reservation.reservationTime.getHours(),
      reservation.reservationTime.getMinutes(),
      reservation.reservationTime.getSeconds())
    );

    reservation.reservationTime = new Date(Date.UTC(
      reservation.reservationTime.getFullYear(),
      reservation.reservationTime.getMonth(),
      reservation.reservationTime.getDate(),
      reservation.reservationTime.getHours(),
      reservation.reservationTime.getMinutes(),
      reservation.reservationTime.getSeconds())
    );

    this.redirectToForHourModalSubject.next(new ConsoleModal(false, ""));

    this.ticketService.redirectTicketToForHour(reservation.categoryId, reservation, () => {
      this.ticketSubject.next(new Ticket());
      this.translate.get('reservation-success').subscribe(
        (res) => {
          this.toastr.success(res + reservationTimeInfo.getUTCHours() + ":" + (reservationTimeInfo.getUTCMinutes() < 10 ? '0' : '') + reservationTimeInfo.getUTCMinutes());
        });
    },
      err => {
        this.translate.get('reservation-error').subscribe(
          res => this.toastr.error(res));
      });
  }

  public redirectTicketToDesk(deskId: number) {
    this.redirectToDeskModalSubject.next(new ConsoleModal(false, ""));
    this.ticketService.redirectTicketToDesk(deskId, () => {
      this.ticketSubject.next(new Ticket());
    },
      () => { })
  };

  public openCancelledTicketsModal() {
    this.closeAllModals();
    this.cancelledTicketsModalSubject.next(new ConsoleModal(true, "cancelled"));
    this.getCancelledTickets();
  }

  public getCancelledTickets() {
    this.ticketService.getCancelledTickets((data) => {
      this.cancelledTicketsSubject.next(data);
    },
      () => { });
  }

  public callCancelledTicket(ticketId: number) {
    this.cancelledTicketsModalSubject.next(new ConsoleModal(false, ""));
    this.ticketService.callCancelledTicket(ticketId, (ticket) => {
      this.ticketSubject.next(ticket);
    },
      () => { });
  };

  public openPauseModal() {
    this.closeAllModals();
    this.pauseModalSubject.next(new ConsoleModal(true, "console.pause"));
  }

  public pasueTicket(pause: PauseTicket) {
    this.pauseModalSubject.next(new ConsoleModal(false, ""));
    this.ticketService.pauseTicket(pause, () => {
      this.ticketSubject.next(new Ticket());
    },
      () => { });
  }

  public openPausedTicketsModal() {
    this.closeAllModals();
    this.pausedTicketsModalSubject.next(new ConsoleModal(true, "console.paused"));
    this.getPausedTickets();
  }

  public getPausedTickets() {
    this.ticketService.getPausedTickets((data) => {
      this.pausedTicketsSubject.next(data);
    },
      () => { });
  }

  public openGenerateTicketModal() {
    this.closeAllModals();
    this.generateTicketModalSubject.next(new ConsoleModal(true, "console.generate-ticket"));
  }

  public generateTicket(categoryId: number) {
    this.ticketService.generateTicket(categoryId, (ticket) => {
      this.translate.get('console.manual-ticket-generated').subscribe((res: string) => {
        this.toastrTimeoutService.success(res + " " + ticket.caption);
      });
    },
      (error) => {
        let errorMessage = error.error;
        if (errorMessage) {
          this.toastrTimeoutService.error(errorMessage);
        } else {
          this.translate.get('console.manual-ticket-generation-fail').subscribe((res: string) => {
            this.toastrTimeoutService.error(res);
          });
        }
      });
  }

  public openNoteTicketModal() {
    this.isNotePopupOpened = true;
    this.closeAllModals();
    this.noteTicketModalSubject.next(new ConsoleModal(true, "console.note-ticket"));
  }

  public openManualCallTicketModal(ticketsType: TicketsType, categoryId?: number) {
    switch (ticketsType) {
      case TicketsType.All:
        this.getTickets();
        break;
      case TicketsType.Category:
        this.getCategoryTickets(categoryId);
        break;
      case TicketsType.Redirected:
        this.getRedirectedTickets();
        break;
      default:
        break;
    }
    this.closeAllModals();
    this.manualCallTicketModalSubject.next(new ConsoleModal(true, "console.manual-call"));
  }

  public openManualShowTicketModal(ticketsType: TicketsType, categoryId?: number) {
    switch (ticketsType) {
      case TicketsType.All:
        this.getTickets();
        break;
      case TicketsType.Category:
        this.getCategoryTickets(categoryId);
        break;
      case TicketsType.Redirected:
        this.getRedirectedTickets();
        break;
      default:
        break;
    }
    this.closeAllModals();
    this.manualShowTicketModalSubject.next(new ConsoleModal(true, "console.manual-show"));
  }

  public getTickets() {
    this.ticketService.getAvailableTickets((data) => {
      this.ticketsSubject.next(data);
    },
      () => { });
  }

  public getCategoryTickets(categoryId: number) {
    var categoryIds: number[] = [];
    categoryIds.push(categoryId);
    this.ticketService.getCategoryAvailableTickets(categoryIds, (data) => {
      this.etickets = data;
      this.ticketsSubject.next(data);
    },
      () => { });
  }

  public getRedirectedTickets() {
    this.ticketService.getRedirectedToDeskAvailableTickets((data) => {
      this.ticketsSubject.next(data);
    },
      () => { });
  }

  public callManualTicket(ticketId: number) {
    this.manualCallTicketModalSubject.next(new ConsoleModal(false, ""));
    this.pausedTicketsModalSubject.next(new ConsoleModal(false, ""));
    this.ticketService.callManualTicket(ticketId, (ticket) => {
      this.ticketSubject.next(ticket);
      var isAddNoteEnabled = false;
      var isNoteSettedAlready = false;
      if (ticket.id != -1) {
        this.ticketNoteService.isNoteSettedToTicket(ticket.id,
          (result) => {
            isNoteSettedAlready = result;
            try {
              isAddNoteEnabled = this.getAllConsoleCategories().filter(x => x.id == ticket.effectiveCategoryId)[0].isNoteRequired;
            } catch {
              isAddNoteEnabled = false;
            }
            var isNotePopupOpen = this.isNotePopupOpen();
            if (isAddNoteEnabled
              && !isNoteSettedAlready
              && !isNotePopupOpen
            ) {
              this.setIsLockedStatus(true);
              this.openNotePopup();
            }
          },
          () => { });
      }
    },
      (error) => {
        const StatusCode: StatusCodes = error.error as StatusCodes;
        console.log(StatusCode);
        switch (+StatusCode) {
          case StatusCodes.TicketNotFound: {
            this.translate.get('console.ticket-not-found').subscribe(res =>
              this.toastrTimeoutService.info(res));
            break;
          }
          case StatusCodes.TicketCurrentlyProcessed: {
            this.translate.get('console.ticket-currently-processed').subscribe(res =>
              this.toastrTimeoutService.info(res));
            break;
          }
        }
      })
  }

  public callManualTicketWhileServedOther(ticketId: number) {
    this.ticketService.callManualTicketWhileServedOther(ticketId,
      (ticket) => { },
      (error) => { }
    );
  }

  public openBlockedCategoriesModal() {
    this.closeAllModals();
    this.blockedCategoriesModalSubject.next(new ConsoleModal(true, "console.block-categories"));
  }

  public blockCategory(categoryId: number) {
    this.categoryService.blockCategory(categoryId).subscribe(
      res => {
        this.categoriesToCallTicket.find(c => c.id == categoryId).isBlocked = true;
      }
    );
  }

  public unblockCategory(categoryId: number) {
    this.categoryService.unblockCategory(categoryId).subscribe(
      res => {
        this.categoriesToCallTicket.find(c => c.id == categoryId).isBlocked = false;
      }
    );
  }

  public openServedCategoriesModal() {
    this.closeAllModals();
    this.servedCategoriesModalSubject.next(new ConsoleModal(true, "served-categories"));
  }

  public changeDeskStatus(deskStatus: DeskStatus) {
    this.deskService.changeDeskStatus(this.desk.id, deskStatus).subscribe(
      res => {
        this.deskStatusSubject.next(deskStatus);
        this.ticketSubject.next(new Ticket());
      }
    );
  }

  playNotificationAudio() {
    let audio = new Audio();
    audio.src = "assets/sounds/achive-sound.mp3";
    audio.load();
    audio.play();
  }


  public setQueueState(queueState: Map<number, number>) {
    var userCategories = this.getCategoriesToCallTicket().filter(x => !x.eTicketRedirect);

    var isPlayNotifcationSound = false;
    if (this.previousQueueState.queueStateByCategory.size != 0 && userCategories.length != 0) {
      userCategories.forEach((category) => {
        var quantityOfTicktesCurrent = queueState[category.id];
        var quantityOfTicktesPrevious = this.previousQueueState.queueStateByCategory.get(category.id);
        if (quantityOfTicktesPrevious == 0 && quantityOfTicktesCurrent == 1) {
          isPlayNotifcationSound = true;
        }
      });
    }

    let queueStateByCategories = new QueueState();
    this.allConsoleCategories.forEach(category => {
      queueStateByCategories.queueStateByCategory.set(category.id, queueState[category.id]);
    });
    queueStateByCategories.queueStateByCategory.set(-1, queueState[-1]); //redirected tickets
    this.previousQueueState = queueStateByCategories;
    this.queueStateSubject.next(queueStateByCategories);
    if (isPlayNotifcationSound) {
      this.playNotificationAudio();
    }
  }

  public closeAllModals() {
    this.redirectModalSubject.next(new ConsoleModal(false, ""));
    this.redirectToCategoryModalSubject.next(new ConsoleModal(false, ""));
    this.redirectToCategoryHiddenModalSubject.next(new ConsoleModal(false, ""));
    this.redirectToForHourModalSubject.next(new ConsoleModal(false, ""));
    this.redirectToDeskModalSubject.next(new ConsoleModal(false, ""));
    this.cancelledTicketsModalSubject.next(new ConsoleModal(false, ""));
    this.pauseModalSubject.next(new ConsoleModal(false, ""));
    this.generateTicketModalSubject.next(new ConsoleModal(false, ""));
    this.noteTicketModalSubject.next(new ConsoleModal(false, ""));
    this.manualCallTicketModalSubject.next(new ConsoleModal(false, ""));
    this.manualShowTicketModalSubject.next(new ConsoleModal(false, ""));
    this.pausedTicketsModalSubject.next(new ConsoleModal(false, ""));
    this.blockedCategoriesModalSubject.next(new ConsoleModal(false, ""));
    this.servedCategoriesModalSubject.next(new ConsoleModal(false, ""));
    this.logoutModalSubject.next(new ConsoleModal(false, ""));
  }

  public set projectType(value: ProjectType) {
    this._projectType = value;
  }

  public get projectType() {
    return this._projectType;
  }
}
