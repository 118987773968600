<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="m-0 font-weight-bold text-primary">{{ 'reservation-vacation-configuration' | translate }}</h5>
    </div>
    <div class="card-body">
        <div>
            <app-reservation-vacation-list *ngIf="reservationCategories" [reservationCategories]="reservationCategories">
            </app-reservation-vacation-list>
            <div class="mb-3">
                <app-reservation-vacation-main>
                </app-reservation-vacation-main>
                <app-reservation-vacation-button>
                </app-reservation-vacation-button>
            </div>
        </div>
    </div>
</div>

<app-vacation-activity-blocks></app-vacation-activity-blocks>
