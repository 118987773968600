import { Injectable } from '@angular/core';
import { Category } from 'projects/qcloud-models/category/category.model';
import { LocationActivityBlock } from 'projects/qcloud-models/location/location-activity-block.model';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReservationVacationService {

  private _choosenCategory: Subject<Category>;
  choosenCategory: Observable<Category>;

  private _add: Subject<boolean>;
  add: Observable<boolean>;

  private _edit: Subject<LocationActivityBlock>;
  edit: Observable<LocationActivityBlock>;

  private _delete: Subject<LocationActivityBlock>;
  delete: Observable<LocationActivityBlock>;

  constructor() {
    this._choosenCategory = new Subject<Category>();
    this.choosenCategory = this._choosenCategory.asObservable();

    this._add = new Subject<boolean>();
    this.add  = this._add.asObservable();

    this._edit = new Subject<LocationActivityBlock>();
    this.edit  = this._edit.asObservable();

    this._delete = new Subject<LocationActivityBlock>();
    this.delete  = this._delete.asObservable();
  }

  updateCategory(newValue) {
    this._choosenCategory.next(newValue);
  }

  updateAdd(newValue){
    this._add.next(newValue);
  }

  updateEdit(newValue){
    this._edit.next(newValue);
  }

  updateDelete(newValue){
    this._delete.next(newValue);
  }
  
}
