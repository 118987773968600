<div class="header-qcloud-logo">
    <img src="./assets/icon.ico">
</div>
<div class="header-text">
    <div class="header-text-container">
        <div [fittext]="true" [activateOnResize]="true" [modelToWatch]="locationName">
            {{ locationName }}
        </div>
    </div>
    <div class="blue-stick">
    </div>
</div>
<div class="client-logo">
    <img [src]="locationLogo">
</div>