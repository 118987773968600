import { ElementRef, Injectable } from "@angular/core";


enum CssCustomClass {
  MarginRightTwo = 'mr-2',
  BigIconLayout = 'big-icon-layout'
}

export enum ButtonComponentName {
  CallTicketButtonComponent = 'CallTicketButtonComponent',
  CallAgainTicketButtonComponent = 'CallAgainTicketButtonComponent',
  AcceptTicketButtonComponent = 'AcceptTicketButtonComponent',
  PauseTicketButtonComponent = 'PauseTicketButtonComponent',
  RedirectTicketButtonComponent = 'RedirectTicketButtonComponent',
  CancelTicketButtonComponent = 'CancelTicketButtonComponent',
  ExpandMenuButtonComponent = 'ExpandMenuButtonComponent',
  LibLogout = 'LibLogout'
}

@Injectable()
export class BigLayoutConfigurationService {
  ButtonComponentNameEnum = ButtonComponentName;
  constructor() { }
  adjustScreen(): void {}

  public adjustSmallScreen(): void {}

  public adjustButtonIconsMap<E>(buttons: Map<E, ElementRef>) {
    var addingClassList = [];
    var removingClassList = [];

    addingClassList.push(CssCustomClass.BigIconLayout);

    return this.manageClassListMap(buttons, addingClassList, removingClassList);
  }

  private manageClassListMap<E>(elemets: Map<E, ElementRef>, addingClasses: string[], removingClasses: string[]){
    if (removingClasses.length > 0) {
      elemets = this.removeClassListMap(elemets, removingClasses);
    }

    if (addingClasses.length > 0) {
      elemets = this.appendClassListMap(elemets, addingClasses);
    }
    return elemets;
  }

  private appendClassListRef(elemets: ElementRef[], classes: string[]) : ElementRef[]{
    elemets.forEach(element => {
      element.nativeElement.classList.add(...classes);
    });
    return elemets;
  }

  private appendClassListMap<E>(elemets: Map<E, ElementRef>, classes: string[]) : Map<E, ElementRef>{
    elemets.forEach((element: ElementRef, key: E) => {
      element.nativeElement.classList.add(...classes);
    });
    return elemets;
  }

  private removeClassListMap<E>(elemets: Map<E, ElementRef>, classes: string[]) : Map<E, ElementRef>{
      elemets.forEach((element: ElementRef, key: E) => {
      element.nativeElement.classList.remove(...classes);
    });
    return elemets;
  }

  public positionIconQueueState(spanQueueStates: ElementRef[]) : void {
    var addingClassList = [];
    addingClassList.push(CssCustomClass.MarginRightTwo);
    this.appendClassListRef(spanQueueStates, addingClassList);
  }
}