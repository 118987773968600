import { Component, OnInit } from '@angular/core';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { DeskService } from 'projects/qcloud-rest-client/src/lib/desk.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CategoryTable } from 'projects/qcloud-models/category/category-table.model';
import { SystemCategoryService } from '../../../../../qcloud-rest-client/src/lib/category/system-category.service';
import { SystemCategory } from '../../../../../qcloud-models/category/system-category.model';

@Component({
  selector: 'app-system-categories',
  templateUrl: './system-categories.component.html',
  styleUrls: ['./system-categories.component.css']
})
export class SystemCategoriesComponent implements OnInit {

  selectedCategoryId: number;
  categoryTable: CategoryTable[];
  systemCategories: SystemCategory[];
  desks: Desk[];

  constructor(private categoryService: CategoryService, private deskService: DeskService,
    private translate: TranslateService, private toastr: ToastrService,
    public systemCategoryService: SystemCategoryService) { }

  ngOnInit(): void {
    this.getCategories();
  }

  getCategories() {
    this.systemCategoryService.getCategories((categories) => {
      this.systemCategories = categories;
    },
      () => { });
  }

  onDeleteConfirm(confirmed: boolean) {
    if (confirmed) {
      this.deleteCategory(this.selectedCategoryId);
    }
  }

  deleteCategory(categoryId: number) {
    this.systemCategoryService.deleteCategory(categoryId).subscribe(
      res => {
        this.getCategories();
      },
      err => {
        this.translate.get('delete-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }
}
