<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="m-0 font-weight-bold text-primary">{{ 'reservation-questionaire-configuration' | translate }}</h5>
    </div>
    <div class="card-body">
        <div class="mb-3">
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{{ 'order' | translate }}</th>
                            <th>{{ 'identity-question' | translate }}</th>
                            <th>{{ 'required' | translate }}</th>
                            <th>{{ 'question-type' | translate }}</th>
                            <th>{{ 'question' | translate }}</th>
                            <th>{{ 'visible' | translate }}</th>
                            <th>{{ 'hidden-categories' | translate }}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody *ngIf="questionsTable">
                        <tr *ngFor="let question of questionsTable; let i = index">
                            <td>{{ i + 1 }}</td>
                            <td>
                                <button *ngIf="i != 0" class="btn btn-primary btn-sm btn-circle mr-1"
                                    (click)="changeOrder(i, -1)">
                                    <i class="fas fa-arrow-up"></i>
                                </button>
                                <button *ngIf="(i + 1) != questionsTable.length"
                                    class="btn btn-primary btn-sm btn-circle" (click)="changeOrder(i, 1)">
                                    <i class="fas fa-arrow-down"></i>
                                </button>
                            </td>
                            <td>
                                <mat-checkbox [(ngModel)]="question.identityQuestion" color="primary" class="mt-1 mr-3">
                                </mat-checkbox>
                            </td>
                            <td>
                                <mat-checkbox [(ngModel)]="question.required" color="primary" class="mt-1 mr-3">
                                </mat-checkbox>
                            </td>
                            <td>
                                <mat-form-field>
                                    <mat-select matNativeControl [(ngModel)]="question.questionType"
                                        (selectionChange)="changeQuestionType($event, question)">
                                        <mat-option [value]="ReservationQuestionType.Text">
                                            {{ 'text' | translate }}
                                        </mat-option>
                                        <mat-option [value]="ReservationQuestionType.FirstName">
                                            {{ 'first-name' | translate }}
                                        </mat-option>
                                        <mat-option [value]="ReservationQuestionType.LastName">
                                            {{ 'last-name' | translate }}
                                        </mat-option>
                                        <mat-option [value]="ReservationQuestionType.Email">
                                            {{ 'email' | translate }}
                                        </mat-option>
                                        <mat-option [value]="ReservationQuestionType.PhoneNumber">
                                            {{ 'phone' | translate }}
                                        </mat-option>
                                        <mat-option [value]="ReservationQuestionType.PersonalId">
                                            {{ 'personal-identity-number' | translate }}
                                        </mat-option>
                                        <mat-option [value]="ReservationQuestionType.PersonalIdLastNumbers">
                                            {{ '5-last-numbers-personal-identity-number' | translate }}
                                        </mat-option>
                                        <mat-option [value]="ReservationQuestionType.VIN">
                                            VIN
                                        </mat-option>
                                        <mat-option [value]="ReservationQuestionType.Option">
                                            {{ 'option' | translate }}
                                        </mat-option>
                                        <mat-option [value]="ReservationQuestionType.VisibleOption">
                                            {{ 'visible-option' | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                            <td>
                                <div *ngFor="let oneQuestion of question.questions; let j = index; trackBy: trackByFn">
                                    <input [(ngModel)]="question.questions[j]" class="form-control mb-1" required>
                                </div>
                            </td>
                            <td>
                                <mat-checkbox [(ngModel)]="question.visible" color="primary" class="mt-1 mr-3">
                                </mat-checkbox>
                            </td>
                            <td>
                                <app-served-categories [categories]="reservationCategories"
                                    [categoryMultiCtrl]="question.hiddenCategories"></app-served-categories>
                            </td>
                            <td>
                                <button class="btn btn-danger btn-sm btn-circle" (click)="deleteQuestion(i)">
                                    <i class="fas fa-fw fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                </table>
            </div>
            <button class="btn btn-info m-1" (click)="addQuestion()">
                {{ 'add-question' | translate }}
            </button>
            <app-submit-button (click)="saveQuestions()" [loading]="formSubmitted"
                [text]="'save-questions' | translate"></app-submit-button>
        </div>
    </div>
</div>