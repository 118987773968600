<div class="card-header py-3">
    <h6 class="m-0 font-weight-bold text-primary">{{ 'desk-customer-survey' | translate}}
    </h6>
</div>
<div class="card-body">
    <div *ngFor="let desk of desks; let i = index">
        <h4 class="small font-weight-bold">{{ desk.fullName }}
            <span class="float-right">
                <span class="text-success"><i class="fas fa-grin-beam"></i> {{ happy[i] }}</span>
                <span class="text-warning mx-2"><i class="fas fa-smile"></i> {{ ambivalent[i] }}</span>
                <span class="text-danger"><i class="fas fa-meh"></i> {{ unhappy[i] }}</span>
            </span>
        </h4>
        <div class="progress mb-4">
            <div class="progress-bar bg-success" role="progressbar" [style.width]="(happy[i]/opinions[i])*100 + '%'"
                aria-valuenow="happy[i]" aria-valuemin="0" aria-valuemax="opinions[i]"></div>
            <div class="progress-bar bg-warning" role="progressbar" [style.width]="(ambivalent[i]/opinions[i])*100 + '%'"
                aria-valuenow="ambivalent[i]" aria-valuemin="0" aria-valuemax="opinions[i]"></div>
            <div class="progress-bar bg-danger" role="progressbar" [style.width]="(unhappy[i]/opinions[i])*100 + '%'"
                aria-valuenow="unhappy[i]" aria-valuemin="0" aria-valuemax="opinions[i]"></div>
        </div>
    </div>
</div>
