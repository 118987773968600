<div class="row">
    <div *ngFor="let category of sortByPriority(deskCategories)">
        <div *ngIf="category.categoryPriority == CategoryPriority.High"
            matTooltip="{{ 'high' | translate }} {{ 'priority' | translate }}" tooltipClass="priority"
            placement="top" class="btn btn-high btn-circle btn-sm mx-1 mb-1">
            {{ category.categoryTag }}
        </div>
        <div *ngIf="category.categoryPriority == CategoryPriority.Mid"
            matTooltip="{{ 'mid' | translate }} {{ 'priority' | translate }}" tooltipClass="priority"
            placement="top" class="btn btn-mid btn-circle btn-sm mx-1 mb-1">
            {{ category.categoryTag }}
        </div>
        <div *ngIf="category.categoryPriority == CategoryPriority.Low"
            matTooltip="{{ 'low' | translate }} {{ 'priority' | translate }}" tooltipClass="priority"
            placement="top" class="btn btn-low btn-circle btn-sm mx-1 mb-1">
            {{ category.categoryTag }}
        </div>
    </div>
</div>
