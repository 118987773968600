import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Category } from 'projects/qcloud-models/category/category.model';
import { QcloudConsoleService } from '../../qcloud-console.service';
import { RedirectAction } from './redirect-action.enum';

@Component({
  selector: 'lib-redirect-to-category-modal',
  templateUrl: './redirect-to-category-modal.component.html',
  styleUrls: ['./redirect-to-category-modal.component.css']
})
export class RedirectToCategoryModalComponent implements OnInit {
  @Output() hideInteractionModel = new EventEmitter<boolean>();
  @Output() choosenCategoryModel = new EventEmitter<Category>();

  categories: Category[];
  selectedCategory: Category;

  constructor(private consoleService: QcloudConsoleService) {
    this.categories = consoleService.getAllConsoleCategories();
    this.selectedCategory = this.categories[0];
  }

  ngOnInit(): void {
  }

  redirect() {
    switch (this.consoleService.redirectActionEnum) {
      case (RedirectAction.ToCategory):
        this.consoleService.redirectTicketToCategory(this.selectedCategory.id);
        this.hideInteractionModel.emit(true);
        break;
      case (RedirectAction.ToForHour):
        this.consoleService.redirectCategoryToForHourModal(this.selectedCategory);
        this.choosenCategoryModel.emit(this.selectedCategory);
        break;
      case (RedirectAction.ToCategoryHidden):
        this.consoleService.redirectTicketToCategoryHidden(this.selectedCategory.id);
        this.choosenCategoryModel.emit(this.selectedCategory);
        break;
    }
  }
}