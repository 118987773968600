import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { timeout } from 'rxjs/operators';
import { Office } from 'projects/qcloud-models/grafik/office/office.model';
import { OfficeDeskRelation } from 'projects/qcloud-models/grafik/office/office-desk-relation';

const DEFAULT_TIMEOUT = 3000;

@Injectable({
  providedIn: 'root'
})

export class OfficeService {

  appBase: string;

  constructor(private http: HttpClient, private authService: AuthService) { }

  public setAppBase(appBase: string) {
    this.appBase = appBase;
  }

  addOffice(office: Office) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/desks`, office);
  }

  getOffice(officeId: number): Observable<Office> {
    return this.http.get<Office>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/desks/${officeId}`)
  }

  getOffices(callback: (data: Office[]) => any, onError: () => any) {
    this.http.get(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/desks`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  deleteOffice(officeId: number) {
    return this.http.delete(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/desks/${officeId}`);
  }

  editOffice(office: Office) {
    return this.http.put(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/desks/${office.id}`,
      office);
  }

  addOfficeDeskRelation(officeDesk: OfficeDeskRelation[]) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/officeDesks`, officeDesk);
  }

  getOfficeDeskRelations(callback: (data: OfficeDeskRelation[]) => any, onError: () => any) {
    this.http.get(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/officeDesks`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  setOfficeDeskRelations(data: OfficeDeskRelation[], callback: () => any, onError: () => any) {
    this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/officeDesks`, data)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  getOfficeForDesk(deskId: number, callback: (office: OfficeDeskRelation) => any, onError: () => any) {
    this.http.get(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/officeDesks/${deskId}`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }
}
