import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TicketsType } from 'projects/qcloud-models/console/ticketsType.enum';
import { QcloudConsoleService } from '../../qcloud-console.service';

@Component({
  selector: 'lib-call-ticket-manual-button',
  templateUrl: './call-ticket-manual-button.component.html',
  styleUrls: ['./call-ticket-manual-button.component.css',  './../base-button/base-button.component.css']
})
export class CallTicketManualButtonComponent  implements OnInit {
  @Input() buttonTitleTranslationTag: string;
  @Input() public tooltipTitle: string;
	@ViewChild('button') public button: ElementRef;
  isEnabled: boolean;
  hasPermission: boolean;
  isBlocked: boolean;

  constructor(private consoleService: QcloudConsoleService) {
    this.consoleService.ticket.subscribe((val) => {
      if (val.id != -1) {
        this.isEnabled = false;
      } else {
        this.isEnabled = true;
      }
    });
    this.consoleService.isBlocked.subscribe((val) => {
      this.isBlocked = val;
      this.consoleService.closeAllModals();
    });
  }

  ngOnInit(): void {
    this.hasPermission = this.consoleService.user.mainRole.hasPermissionToCallingTicketsManually;
  }

  public manualCallTicket() {
    if (this.isEnabled) {
      this.consoleService.openManualCallTicketModal(TicketsType.All);
    }
    else{
      this.consoleService.setIsBlockedStatus(true);
    }
  }

  getButtonTitleTranslateTag() {
    if(this.buttonTitleTranslationTag === undefined || this.buttonTitleTranslationTag === "") {
      return 'console.manual-call';
    }
    return this.buttonTitleTranslationTag;
  }
}
