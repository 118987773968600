import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { QcloudConsoleService } from 'projects/qcloud-console/src/public-api';
import { User } from 'projects/qcloud-models/user/user.model';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-topbar-logo',
  templateUrl: './topbar-logo.component.html',
  styleUrls: ['./topbar-logo.component.css']
})
export class TopbarLogoComponent implements OnInit {

  @ViewChild("logoutModal") private logoutModalRef: TemplateRef<Object>;
  user: User;
  dialogRef : MatDialogRef<any>;

  constructor(private router: Router, private consoleService: QcloudConsoleService, private authService: AuthService, private dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  toWelcomePage() {
    if (this.authService.isConsoleLoggedIn()) {
      this.dialogRef = this.dialog.open(this.logoutModalRef);
    } else {
      this.goToWelcomePage();
    }
  }

  consoleLogout() {
    this.consoleService.logout();
    this.goToWelcomePage();
  }

  goToWelcomePage() {
    this.router.navigate(['qcloud/welcome']);
  }

}
