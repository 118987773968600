<div *ngIf="customisation.showBottomBar" class="wrapper">
    <div *ngIf="customisation.showClock" class="customisation-time-wrapper">
        <div [fittext]="true" [activateOnResize]="true" [modelToWatch]="customisation.date" [maxFontSize]="40">
            {{ customisation.date | date: 'HH:mm' }}
        </div>
    </div>
    <div *ngIf="customisation.showDate" class="customisation-date-wrapper">
        <div [fittext]="true" [activateOnResize]="true" [modelToWatch]="customisation.date" [maxFontSize]="40">
            {{ customisation.date | date: 'dd/MM/yyyy' }}
        </div>
    </div>
    <div *ngIf="customisation.showText" class="customisation-text-wrapper" [ngStyle]="getTextWrapperWidth()">
        <p class="scroll-slow">{{ customisation.customText }}</p>
    </div>
    <div *ngIf="customisation.showText" class="logo-wrapper"><img class="bottom-img" src="./assets/QCloud.png"></div>
</div>
<div *ngIf="!customisation.showBottomBar" class="wrapper">
    <div *ngIf="!customisation.showText" class="single-logo-wrapper"><img class="bottom-img" src="./assets/QCloud.png"></div>
</div>