import { Injectable } from '@angular/core';
import { DeviceConfiguration } from 'projects/qcloud-models/device/device-configuration';
import { TokenResponse } from 'projects/qcloud-models/user/token-response';
import { MainDisplayLayouts } from '../devices/main-display/main-display-layouts';
import { ElectronService } from './electron.service';
@Injectable({
  providedIn: 'root'
})
export class DeviceConfigurationService {

  private qcloudAddressKey = "qcloudAddress";
  private deviceGuidKey = "deviceGuid";
  private deviceConfigKey = "deviceConfig";
  private token: TokenResponse;
  private deviceGuid: string;
  private deviceConfiguration: DeviceConfiguration;
  private appBase: string;
  public isOffline: boolean = true;
  public canWorkOffline: boolean;

  constructor(private electronService: ElectronService) {
    this.appBase = this.getFromStoreValueFile(this.qcloudAddressKey);
    this.deviceGuid = this.getFromStoreValueFile(this.deviceGuidKey);
  }

  public getToken(): TokenResponse {
    return this.token;
  }

  public setToken(value: TokenResponse) {
    this.token = value;
  }

  public getConfiguration(): DeviceConfiguration {
    return this.deviceConfiguration;
  }

  public loadConfigForOffline() {
    var value = localStorage.getItem(this.deviceConfigKey)
    if (value) {
      this.deviceConfiguration = JSON.parse(localStorage.getItem(this.deviceConfigKey));
      if (this.deviceConfiguration.layoutType == MainDisplayLayouts.Multimedia) {
        this.canWorkOffline = true;
      }
    }
    return this.deviceConfiguration;
  }


  public setConfiguration(value: DeviceConfiguration) {
    this.deviceConfiguration = value;
    localStorage.setItem(this.deviceConfigKey, JSON.stringify(value));
  }

  public setAppBase(value: string) {
    this.appBase = value;
    this.setToStoreValue(this.qcloudAddressKey, value);
  }

  public getAppBase(): string {
    return this.appBase;
  }

  public setDeviceGuid(value: string) {
    this.deviceGuid = value;
    this.setToStoreValue(this.deviceGuidKey, value);
  }

  public getDeviceGuid(): string {
    return this.deviceGuid;
  }

  private setToStoreValue(key: string, value) {
    if (this.electronService.ipcRenderer) {
      var data = this.electronService.ipcRenderer.sendSync('load-configuration', key);
      var json = {};
      try {
        json = JSON.parse(data);
      }
      catch (error) {
        json = {};
      }
      json[key] = value;
      data = JSON.stringify(json);
      this.electronService.ipcRenderer.send('save-configuration', data);
      return data;
    }
    else {
      localStorage.setItem(key, value);
    }

  }

  private getFromStoreValue(key: string): string {
    return localStorage.getItem(key);
  }

  private getFromStoreValueFile(key: string): string {
    if (this.electronService.ipcRenderer != null) {
      var data = this.electronService.ipcRenderer.sendSync('load-configuration', key);
      var json = {};
      try {
        json = JSON.parse(data);
      }
      catch (error) {
        json = {};
      }
      return json[key];
    }
    else {
      return localStorage.getItem(key);
    }
  }

}
