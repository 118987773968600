import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocationData } from 'projects/qcloud-models/location/location.model';
import { AuthService } from './auth/auth.service';
import { TicketLayout } from 'projects/qcloud-models/location/ticket-layout.model';
import { Personalisation } from 'projects/qcloud-models/location/personalisation';
import { LocationActivityBlock } from 'projects/qcloud-models/location/location-activity-block.model';
import { LocationConstraints } from 'projects/qcloud-models/location/location-constraints';
import { LocationTasks } from 'projects/qcloud-models/location/location-tasks';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  appBase: string;
  locationData: LocationData;

  constructor(private http: HttpClient, private authService: AuthService) { }

  public setAppBase(appBase: string) {
    this.appBase = appBase;
  }

  getAll(): Observable<LocationData[]> {
    return this.http.get<LocationData[]>(
      `${this.appBase}api/${this.authService.getSystemId()}/locations`);
  }

  getLocationData(locationId: number): Observable<LocationData> {
    return this.http.get<LocationData>(
      `${this.appBase}api/${this.authService.getSystemId()}/locations/${locationId}`);
  }

  editLocationData(locationData: LocationData): Observable<LocationData> {
    return this.http.put<LocationData>(
      `${this.appBase}api/${this.authService.getSystemId()}/locations/${locationData.id}`, locationData);
  }

  addLocation(locationData: LocationData): Observable<LocationData> {
    return this.http.post<LocationData>(
      `${this.appBase}api/${this.authService.getSystemId()}/locations`, locationData);
  }

  deleteLocation(locationId: number) {
    return this.http.delete(
      `${this.appBase}api/${this.authService.getSystemId()}/locations/${locationId}`);
  }

  getTicketLayout(): Observable<TicketLayout> {
    return this.http.get<TicketLayout>(
      `${this.appBase}api/${this.authService.getSystemId()}/locations/${this.authService.getLocationId()}/ticketsLayout`);
  }

  saveTicketLayout(ticketLayout: TicketLayout) {
    return this.http.put(
      `${this.appBase}api/${this.authService.getSystemId()}/locations/${this.authService.getLocationId()}/ticketsLayout`, ticketLayout);
  }

  deleteTicketLayout() {
    return this.http.delete(
      `${this.appBase}api/${this.authService.getSystemId()}/locations/${this.authService.getLocationId()}/ticketsLayout`);
  }

  getPersonalisation(): Observable<Personalisation> {
    return this.http.get<Personalisation>(
      `${this.appBase}api/${this.authService.getSystemId()}/locations/${this.authService.getLocationId()}/personalisation`);
  }

  savePersonalisation(personalisation: Personalisation) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/locations/${this.authService.getLocationId()}/personalisation`, personalisation);
  }

  deletePersonalisation() {
    return this.http.delete(
      `${this.appBase}api/${this.authService.getSystemId()}/locations/${this.authService.getLocationId()}/personalisation`);
  }

  //TODO: Move to ticket service
  resetTickets() {
    return this.http.get(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tickets/reset`);
  };

  setActivity(locationActivityBlock: LocationActivityBlock[]) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/locations/${this.authService.getLocationId()}/activity`,
      locationActivityBlock);
  }

  setVacationActivity(locationActivityBlock: LocationActivityBlock[]) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/locations/${this.authService.getLocationId()}/activity/vacation`,
      locationActivityBlock);
  }

  getActivity(): Observable<LocationActivityBlock[]> {
    return this.http.get<LocationActivityBlock[]>(
      `${this.appBase}api/${this.authService.getSystemId()}/locations/${this.authService.getLocationId()}/activity`);
  }

  getVacationActivity(): Observable<LocationActivityBlock[]> {
    return this.http.get<LocationActivityBlock[]>(
      `${this.appBase}api/${this.authService.getSystemId()}/locations/${this.authService.getLocationId()}/activity/vacation`);
  }

  deleteActivity(activityId: number) {
    return this.http.delete(
      `${this.appBase}api/${this.authService.getSystemId()}/locations/${this.authService.getLocationId()}/activity/${activityId}`);
  }

  deleteVacationActivity(activityId: number) {
    return this.http.delete(
      `${this.appBase}api/${this.authService.getSystemId()}/locations/${this.authService.getLocationId()}/activity/${activityId}/vacation`);
  }

  getLocationConstraints(): LocationConstraints {
    return this.locationData?.constraints;
  }

  getLocationTasks(): Observable<LocationTasks> {
    return this.http.get<LocationTasks>(
      `${this.appBase}api/${this.authService.getSystemId()}/locations/${this.authService.getLocationId()}/tasks`);
  }


  saveLocationTasks(locationTasks: LocationTasks) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/locations/${this.authService.getLocationId()}/tasks`, locationTasks);
  }
}
