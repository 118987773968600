<div class="row">
    <div *ngIf="!infinitePause" class="col-12">
        <div class="input-group mb-2">
            <div class="input-group-prepend">
                <span class="input-group-text">{{ 'console.pause-for' | translate }}:</span>
            </div>
            <input type="number" class="form-control" min="1" max="100" name="pauseTime" [(ngModel)]="pause.pauseTime"/>
            <div class="input-group-append">
                <span class="input-group-text">{{ 'minutes-shortcut' | translate }}</span>
            </div>
        </div>
    </div>
    <div class="col-lg-12 col-xl-8">
        <div class="form-check">
            <input type="checkbox" class="form-check-input"
                [(ngModel)]="infinitePause" name="infinitePause" (change)="changePauseTime()">
            <label class="form-check-label">{{ 'console.pause-until-further-notice' | translate }}</label>
        </div>
        <div class="form-check">
            <input type="checkbox" class="form-check-input" name="returnToDesk" [(ngModel)]="pause.returnToDesk">
            <label class="form-check-label">{{ 'console.return-to-desk' | translate }}</label>
        </div>
    </div>
    <button class="btn btn-info col-lg-12 col-xl-4" (click)="pauseTicket()">{{ 'console.pause' | translate }}</button>
</div>