<div class="form-group">
    <div class="font-weight-bold">{{ title | translate}}</div>
    <div class="input-group">
        <mat-form-field color="primary">
            <mat-select [formControl]="specialityMultiCtrl" [multiple]="false" #multiSelect
                (selectionChange)="emitSpecialities($event.value)">
                <mat-option>
                    <ngx-mat-select-search [showToggleAllCheckbox]="true" (toggleAll)="toggleSelectAll($event)"
                        [formControl]="specialityMultiFilterCtrl"
                        toggleAllCheckboxTooltipMessage="{{ 'select-unselect-all' | translate }}"
                        [toggleAllCheckboxTooltipPosition]="'above'"
                        placeholderLabel="{{ 'search-category' | translate }}"
                        noEntriesFoundLabel="{{ 'no-matching-category' | translate }}">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option>{{'no-specialisation' | translate}}</mat-option>
                <mat-option *ngFor="let speciality of filteredSpecialitiesMulti | async" [value]="speciality">
                    <b>{{ speciality.name }}</b>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>