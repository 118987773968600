import { RowStyle } from "./device/row-style";

export class DeviceLayout {
    id: number;
    systemId: number;
    locationId: number;
    layoutJson: string;
    layoutNumber: number;
    rowStyle: RowStyle;
    backgroundImg: string;
    public get components(): LayoutComponent[] {
        let parse = JSON.parse(this.layoutJson);
        return parse.components;
    }
}

export class LayoutComponent {
    name: string;
    position: number[];
    params: any;
    style: string;
}
