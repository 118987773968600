import { Component, OnInit, Input } from '@angular/core';
import { DeviceConfiguration } from 'projects/qcloud-models/device/device-configuration';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { ConsoleLogin } from 'projects/qcloud-models/user/console-login.model';
import { QcloudConsoleService } from '../qcloud-console.service';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { UserService } from 'projects/qcloud-rest-client/src/lib/user.service';
import { ProjectType } from '../qcloud-console.component';
import { LocationService } from '../../../../qcloud-rest-client/src/lib/location.service';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-login-web',
  templateUrl: './login-web.component.html',
  styleUrls: ['./login-web.component.css']
})
export class LoginWebComponent implements OnInit {

  @Input() public deviceConfiguration: DeviceConfiguration;

  public choosenDesk: Desk;
  public deskList: Desk[];
  public username = "";
  public password = "";
  projectTypeEnum = ProjectType;

  constructor(private consoleService: QcloudConsoleService, public authService: AuthService,
    private userService: UserService, public locationService: LocationService, private router: Router) { }

  ngOnInit(): void {
    this.deskList = this.deviceConfiguration.desks;
    let deskId = this.authService.getDeskId();
    let locationId = this.authService.getLocationId();
    if (deskId) {
      this.choosenDesk = this.deskList.find(x => x.id == deskId && x.locationId == locationId);
    } else {
      this.choosenDesk = this.deskList[0];
    }
    if (!this.choosenDesk) {
      this.choosenDesk = this.deskList[0];
    }
  }

  public loginToConsole() {
    let consoleLogin = new ConsoleLogin();
    consoleLogin.deskId = this.choosenDesk.id;
    consoleLogin.userName = this.authService.getTokenUsername();
    this.consoleService.loginToWebConsole(consoleLogin);
    this.password = "";
    this.userService.updateStateChanged(true);
  }
  routeToChoosingLocation() {
    this.router.navigate(["/qcloud/select-location-step/0"]);
  }
}
