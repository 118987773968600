export class Customisation {
    public backgroundUrl: string = '';
    public showBottomBar: boolean;
    public showClock: boolean;
    public showText: boolean;
    public showDate: boolean;
    public date: Date;
    public customText: string;
    public amms: boolean = false;
    public printType: number;
}