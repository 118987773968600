import { ScheduleTagsTypes } from './schedule-tags-types.enum';

export class ScheduleTag {
    public id: number;
    public scheduleTagsType: ScheduleTagsTypes;

    constructor(id: number, scheduleTagsType: ScheduleTagsTypes) {
        this.id = id;
        this.scheduleTagsType = scheduleTagsType;
    }
}