<div #pagetop class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 *ngIf="!isEditForm" class="m-0 font-weight-bold text-primary">{{ 'qcloud.add-role' | translate }}</h5>
        <div *ngIf="isEditForm" class="row">
            <h5 class="m-0 col-8 font-weight-bold text-primary">{{ 'qcloud.edit-role' | translate }}</h5>
        </div>
    </div>
    <div *ngIf="ldapRole" class="card-body">
        <form #form="ngForm" autocomplete="off">
            <div class="form-group">
                <div class="row">
                    <div class="col-6">
                        *{{ 'qcloud.role-name' | translate }}
                        <div class="input-group">
                            <mat-form-field>
                                <mat-select matNativeControl [(ngModel)]="choosenQcloudRole" name="choosenQcloudRole"
                                (selectionChange)="changeQcloudRoleFormField()" required>
                                    <mat-option *ngFor="let role of qcloudRoles" [value]="role">
                                        {{ role.roleName }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-6">
                        *{{ 'qcloud.role-key' | translate }}
                        <div class="input-group">
                            <input name="key" #key="ngModel" [(ngModel)]="ldapRole.key" class="form-control"
                                maxlength="30" required>
                        </div>
                        <div *ngIf="key.invalid && (key.dirty || key.touched)" class="text-danger mx-2 my-1">
                            <div *ngIf="key.errors.required">{{ 'qcloud.key-required' | translate }}</div>
                            <div *ngIf="key.errors.maxlength">{{ 'qcloud.key-error-max-length' | translate
                                }}{{keyMaxCharacterLength}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <button class="btn btn-danger m-1" routerLink="/ldapRoles">{{ 'back' | translate
                    }}</button>
                <button class="btn btn-primary m-1" type="submit" [disabled]="formSubmitted || form.invalid"
                    (click)="onSubmit(); formSubmitted=true">
                    <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                        aria-hidden="true"></span>
                    {{ 'qcloud.save-role' | translate }}
                </button>
                <button *ngIf="isEditForm" class="btn btn-info m-1" type="submit"
                    [disabled]="formSubmitted || form.invalid"
                    (click)="newBasedOnExisting(); pagetop.scrollIntoView({behavior: 'smooth'})">
                    <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                        aria-hidden="true"></span>
                    {{ 'qcloud.add-new-role-based-on-existing' | translate }}
                </button>
            </div>

            <label *ngIf="form.invalid" class="text-danger">*{{ 'complete-required-fields' | translate }}</label>
        </form>
    </div>
</div>