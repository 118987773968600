import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { DetailsPopupModalData } from '../../../../../qcloud-models/console/details-popup-modal/details-popup-modal-data';
import { DetailsStructureTemplateGroup } from '../../../../../qcloud-models/console/details-popup-modal/details-structure-template-group';

@Component({
  selector: 'app-details-modal',
  templateUrl: './details-modal.component.html',
  styleUrls: ['./details-modal.component.css']
})
export class DetailsModalComponent implements OnInit {

  title: string;
  detailsStructureTemplateGroups: DetailsStructureTemplateGroup[];

  panelOpenState: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: DetailsPopupModalData) {
    this.detailsStructureTemplateGroups = data.detailsStructureTemplateGroups;
    this.title = data.title;
  }

  ngOnInit(): void { }
}
