import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { DeviceConfigurationService } from 'projects/qcloud-device/src/app/services/device-configuration.service';
import { BaseComponent } from 'projects/qcloud-device/src/app/shared/base-component/base.component';

@Component({
  selector: 'app-location-header',
  templateUrl: './location-header.component.html',
  styleUrls: ['./location-header.component.css']
})
export class LocationHeaderComponent extends BaseComponent implements OnInit {
  locationLogo: string;
  locationName: string;
  constructor(protected elementRef: ElementRef, protected renderer: Renderer2) {
    super(elementRef, renderer);
  }

  startComponent(): void {
    let deviceConfiguration = this.configurationService.getConfiguration();
    this.locationName = deviceConfiguration.locationName;
    if (deviceConfiguration.logoImgPath != null && deviceConfiguration.logoImgPath != "") {

      var pathSystemLocation =  `/${deviceConfiguration.systemId}/${deviceConfiguration.locationId}`;
      var url = deviceConfiguration.deviceNetworkConfiguration.qdsHost + "cdn" + pathSystemLocation + deviceConfiguration.logoImgPath;
      this.locationLogo = url;
    }
    else {
      this.locationLogo = "./assets/icon.ico";
    }

    if (deviceConfiguration.title) {
      this.locationName = deviceConfiguration.title;
    }
  }

  initMock(): void {
    this.locationName = "Example name";
  }
}
