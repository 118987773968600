<div class="form-group">
    <div class="input-group mb-1">
        <span class="mt-3 mr-1">{{ 'hours' | translate }}:</span>
        <mat-form-field>
            <input type="time" matInput (change)="updateSlider()" [(ngModel)]="beginTime">
        </mat-form-field>
        <span class="mt-3 mx-1">-</span>
        <mat-form-field>
            <input type="time" matInput (change)="updateSlider()" [(ngModel)]="endTime">
        </mat-form-field>
        
    </div>
    <div class="item-group">
        <div class="main-slider">
            <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options"
                (userChangeStart)="onUserChange($event)" (userChange)="onUserChange($event)"
                (userChangeEnd)="onUserChange($event)">
            </ngx-slider>
        </div>
    </div>
</div>