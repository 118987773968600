<div *ngFor="let answer of answers">
    <div *ngIf="checkVisible(answer)">
        <span *ngIf="answer.questionType != ReservationQuestionType.Option 
            && answer.questionType != ReservationQuestionType.VisibleOption">
            {{ answer.question }}</span>
        <span class="text-danger" *ngIf="answer.isRequired">*</span>

        <div *ngIf="answer.questionType == ReservationQuestionType.Text
            || answer.questionType == ReservationQuestionType.FirstName
            || answer.questionType == ReservationQuestionType.LastName">
            <input class="form-control" [(ngModel)]="answer.answer" #text="ngModel" name="text"
                [required]="answer.isRequired" (input)="checkForUrls($event, answer.answer)">
            <div *ngIf="text.invalid && (text.dirty || text.touched)" class="text-danger">
                <div *ngIf="text.errors.required">
                    {{ 'required' | translate }}
                </div>
            </div>
            <div *ngIf="textInvalid" class="text-danger">
                {{ 'invalid-text' | translate }}
            </div>
        </div>

        <!-- TODO: update personal Id validation -->
        <div *ngIf="answer.questionType == ReservationQuestionType.PersonalId">
            <input class="form-control" [(ngModel)]="answer.answer" #personalID="ngModel" name="personalID"
                [required]="answer.isRequired" pattern="^[0-9]{11}$">
            <div *ngIf="personalID.invalid && (personalID.dirty || personalID.touched)" class="text-danger">
                <div *ngIf="personalID.errors.required">
                    {{ 'personal-id-required' | translate }}
                </div>
                <div *ngIf="personalID.errors.pattern">
                    {{ 'invalid-personal-id' | translate }}
                </div>
            </div>
        </div>

        <div *ngIf="answer.questionType == ReservationQuestionType.PersonalIdLastNumbers">
            <input class="form-control" [(ngModel)]="answer.answer" #personalIdLastNumbers="ngModel"
                name="personalIdLastNumbers" [required]="answer.isRequired" pattern="^[0-9]{5}$" maxlength="5">
            <div *ngIf="personalIdLastNumbers.invalid && (personalIdLastNumbers.dirty || personalIdLastNumbers.touched)"
                class="text-danger">
                <div *ngIf="personalIdLastNumbers.errors.required">
                    {{ 'personal-id-required' | translate }}
                </div>
                <div *ngIf="personalIdLastNumbers.errors.pattern">
                    {{ 'invalid-personal-id' | translate }}
                </div>
            </div>
        </div>

        <div *ngIf="answer.questionType == ReservationQuestionType.Email">
            <input class="form-control" [(ngModel)]="answer.answer" #email="ngModel" name="email"
                [required]="answer.isRequired" email>
            <div *ngIf="email.invalid && (email.dirty || email.touched)" class="text-danger">
                <div *ngIf="email.errors.required">
                    {{ 'email-required' | translate }}
                </div>
                <div *ngIf="email.errors.email">
                    {{ 'invalid-email' | translate }}
                </div>
            </div>
        </div>

        <div *ngIf="answer.questionType == ReservationQuestionType.PhoneNumber">
            <input class="form-control" [(ngModel)]="answer.answer" #phone="ngModel" name="phone"
                [required]="answer.isRequired" minlength="7" maxlength="17"
                pattern="^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$">
            <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="text-danger">
                <div *ngIf="phone.errors.required">
                    {{ 'phone-number-required' | translate }}
                </div>
                <div *ngIf="phone.errors.pattern || phone.errors.minlength">
                    {{ 'invalid-phone-number' | translate }}
                </div>
            </div>
        </div>

        <div *ngIf="answer.questionType == ReservationQuestionType.VIN">
            <input class="form-control" [(ngModel)]="answer.answer" #vin="ngModel" name="vin"
                [required]="answer.isRequired" minlength="17" maxlength="17">
            <div *ngIf="vin.invalid && (vin.dirty || vin.touched)" class="text-danger">
                <div *ngIf="vin.errors.required">
                    {{ 'vin-required' | translate }}
                </div>
                <div *ngIf="vin.errors.minlength">
                    {{ 'invalid-vin' | translate }}
                </div>
            </div>
        </div>

        <div *ngIf="answer.questionType == ReservationQuestionType.Option">
            <div class="my-2">
                <mat-radio-group color="primary" [(ngModel)]="answer.answer">
                    <mat-radio-button [value]="FIRST_OPTION">{{ answer.question.split(';')[0] }}
                    </mat-radio-button>
                    <mat-radio-button [value]="SECOND_OPTION">{{ answer.question.split(';')[1] }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </div>

    <div *ngIf="checkVisibleForCategory(answer) && answer.questionType == ReservationQuestionType.VisibleOption">
        <div class="my-2">
            <mat-radio-group color="primary" [(ngModel)]="answer.answer">
                <mat-radio-button [value]="FIRST_OPTION">{{ answer.question.split(';')[0] }}
                </mat-radio-button>
                <mat-radio-button [value]="SECOND_OPTION">{{ answer.question.split(';')[1] }}
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
</div>