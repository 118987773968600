import { Component, HostListener, OnChanges, OnInit } from '@angular/core';
import { ScreenSizeConfigurationService, ScreenSizeType } from 'projects/qcloud-console/src/lib/services/screen-size-configuration.service';


enum CssCustomClasses {
  MavbarMargin = 'navbar-margin',
  SlimMavbarMargin = 'slim-navbar-margin',
  SmallMavbarMargin = 'small-navbar-margin',
  ContainerFluid = 'container-fluid'
}
@Component({
  selector: 'app-navbar-footer',
  templateUrl: './navbar-footer.component.html',
  styleUrls: ['./navbar-footer.component.css']
})
export class NavbarFooterComponent implements OnInit {
  ScreenSizeTypeEnum;
  public screenSizeType: ScreenSizeType;
  constructor(private screenSizeConfigurationService: ScreenSizeConfigurationService) { }

  ngOnInit(): void {
    this.screenSizeConfigurationService.updateScreenSizeType(window.innerWidth, window.innerHeight);
    this.screenSizeType = this.screenSizeConfigurationService.screenSizeType;
    this.ScreenSizeTypeEnum = this.screenSizeConfigurationService.ScreenSizeTypeEnum;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenSizeConfigurationService.updateScreenSizeType(window.innerWidth, window.innerHeight);
    this.screenSizeType = this.screenSizeConfigurationService.screenSizeType;
  }

  isHideElement() : boolean {
    return this.screenSizeType == this.ScreenSizeTypeEnum.SLIM || this.screenSizeType == this.ScreenSizeTypeEnum.SMALL;
  }
  getNavbarMarginCss() : string {
    var cssClass = '';
    switch(this.screenSizeType) {
      case this.ScreenSizeTypeEnum.SLIM:
        cssClass = cssClass.concat(' ', CssCustomClasses.SlimMavbarMargin);
        break;
      case this.ScreenSizeTypeEnum.SMALL:
        cssClass = cssClass.concat(' ', CssCustomClasses.SmallMavbarMargin);
        break;
      case this.ScreenSizeTypeEnum.BIG:
        cssClass = cssClass.concat(' ', CssCustomClasses.MavbarMargin, ' ', CssCustomClasses.ContainerFluid);
        break;
    }
    return cssClass.trim();
  }
}
