import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { DeviceConfiguration } from 'projects/qcloud-models/device/device-configuration';
import { QcloudConsoleService } from '../../qcloud-console.service';
import { QueueStateComponent } from '../queue-state/queue-state.component';
import { BigLayoutConfigurationService, ButtonComponentName } from '../../services/big-layout-configuration.service';
import { CallTicketButtonComponent } from '../call-ticket-button/call-ticket-button.component';
import { AcceptTicketButtonComponent } from '../accept-ticket-button/accept-ticket-button.component';
import { CallAgainTicketButtonComponent } from '../call-again-ticket-button/call-again-ticket-button.component';
import { CancelTicketButtonComponent } from '../cancel-ticket-button/cancel-ticket-button.component';
import { PauseTicketButtonComponent } from '../pause-ticket-button/pause-ticket-button.component';
import { RedirectTicketButtonComponent } from '../redirect-ticket-button/redirect-ticket-button.component';
import { LogoutComponent } from '../logout/logout.component';


@Component({
  selector: 'big-layout',
  templateUrl: './big-layout.component.html',
  styleUrls: ['./big-layout.component.css']
})
export class BigConsoleLayoutComponent implements OnInit {

  @Input() public deviceConfiguration: DeviceConfiguration;
  @ViewChild('queueStateComponent') private queueStateComponent: QueueStateComponent;
  @ViewChild('callTicketButtonComponent') private callTicketButtonComponent: CallTicketButtonComponent;
  @ViewChild('acceptTicketButtonComponent') private acceptTicketButtonComponent: AcceptTicketButtonComponent;
  @ViewChild('callAgainTicketButtonComponent') private callAgainTicketButtonComponent: CallAgainTicketButtonComponent;
  @ViewChild('pauseTicketButtonComponent') private pauseTicketButtonComponent: PauseTicketButtonComponent;
  @ViewChild('redirectTicketButtonComponent') private redirectTicketButtonComponent: RedirectTicketButtonComponent;
  @ViewChild('cancelTicketButtonComponent') private cancelTicketButtonComponent: CancelTicketButtonComponent;
  @ViewChild('libLogout') public libLogout: LogoutComponent;

  constructor(public consoleService: QcloudConsoleService, public bigLayoutConfigurationService: BigLayoutConfigurationService) { }

  ngOnInit(): void {
    this.adjustScreen();
  }

  async adjustScreen() : Promise<void> {
    while(!this.checkIfAllElementsAreLoaded()) {
      await this.delay(300);
    }
    var layoutConfig = this.bigLayoutConfigurationService;
    var icons = this.getViewChildIcons();
    layoutConfig.adjustButtonIconsMap<ButtonComponentName>(icons);
    var spanQueueStates = this.getViewChildSpanQueueStates();
    layoutConfig.positionIconQueueState(spanQueueStates);
  }


  private checkIfAllElementsAreLoaded() : boolean {
    var icons = this.getViewChildIcons();
    var isAllLoaded = true;
    isAllLoaded = this.checkIfAllElementsAreLoadedMap<ButtonComponentName>(icons, isAllLoaded);

    var spanQueueStates = this.getViewChildSpanQueueStates();
    isAllLoaded = this.checkIfAllElementsAreLoadedArray(spanQueueStates, isAllLoaded);
    isAllLoaded = this.queueStateComponent?.spanFaReplyIcon ? isAllLoaded && true : false;
    return isAllLoaded;
  }

  private getViewChildSpanQueueStates() : ElementRef[] {
    var queueStates = [];
    if(this.queueStateComponent?.spanCategories){
      this.queueStateComponent.spanCategories.forEach(component => queueStates.push(component));
    }

    if(this.queueStateComponent?.spanFaReply){
      queueStates.push(this.queueStateComponent.spanFaReply);
    }
    return queueStates;
  }

  private checkIfAllElementsAreLoadedArray(elements: ElementRef[], isAllLoaded: boolean) : boolean {
    elements.forEach((element: ElementRef) => {
      isAllLoaded = element ? isAllLoaded && true : false;
    });
    return isAllLoaded;
  }

  private getViewChildIcons() : Map<ButtonComponentName, ElementRef> {
    var buttonComponentName = this.bigLayoutConfigurationService.ButtonComponentNameEnum;
    var buttons = new Map<ButtonComponentName, ElementRef>();
    buttons.set(buttonComponentName.CallTicketButtonComponent, this.callTicketButtonComponent?.icon);
    buttons.set(buttonComponentName.CallAgainTicketButtonComponent, this.callAgainTicketButtonComponent?.icon);
    buttons.set(buttonComponentName.AcceptTicketButtonComponent, this.acceptTicketButtonComponent?.icon);
    buttons.set(buttonComponentName.PauseTicketButtonComponent, this.pauseTicketButtonComponent?.icon);
    buttons.set(buttonComponentName.RedirectTicketButtonComponent, this.redirectTicketButtonComponent?.icon);
    buttons.set(buttonComponentName.CancelTicketButtonComponent, this.cancelTicketButtonComponent?.icon);
    buttons.set(buttonComponentName.LibLogout, this.libLogout?.icon);
    return buttons;
  }

  private checkIfAllElementsAreLoadedMap<E>(elements: Map<E, ElementRef>, isAllLoaded: boolean) : boolean {
    elements.forEach((element: ElementRef, key: E) => {
      isAllLoaded = element ? isAllLoaded && true : false;
    });
    return isAllLoaded;
  }

  checkLoadConsoleAssets() {
    return this.consoleService.desk && this.consoleService.user && this.consoleService.getAllConsoleCategories()
      && this.consoleService.getCategoriesToCallTicket();
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

}
