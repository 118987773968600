import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FileDTO } from 'projects/qcloud-models/fileManager/file-dto.model';
import { GrafikFileService } from 'projects/qcloud-rest-client/src/lib/grafik/grafik-file.service';

@Component({
  selector: 'app-work-schedule-logo',
  templateUrl: './work-schedule-logo.component.html',
  styleUrls: ['./work-schedule-logo.component.css']
})
export class WorkScheduleLogoComponent implements OnInit {

  content: FileDTO[];
  selectedFiles: boolean[];
  path: string = "";
  clearUploaderQueue: boolean = false;

  constructor(public service: GrafikFileService, private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.getDirectoryContent();
  }

  getDirectoryContent() {
    this.service.getDirectoryContent(this.path).subscribe(
      res => {
        this.content = res;
        this.selectedFiles = new Array(this.content.length);
      },
      err => {
        this.translate.get('file-manager-error').subscribe((res: string) => {
          let error = res;
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  delete(item: FileDTO) {
    let selected = new Array<FileDTO>(item);
    this.service.deleteSelected(selected).subscribe(
      res => {
        this.getDirectoryContent();
      },
      err => {
        this.translate.get('delete-element-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    )
  }

  displayFilename(filename: string, i: number) {
    let maxFileLength = 47;
    let filenameLength = filename.length - this.service.getExtension(filename).length;
    if (filenameLength <= maxFileLength || this.selectedFiles[i]) {
      return filename;
    } else {
      return filename.slice(0, maxFileLength - 3) + "...";
    }
  }

  onImgError(event, path: string, name: string) {
    this.translate.get('file-conversion-in-progress').subscribe((error: string) => {
      this.translate.get('please-wait').subscribe((res: string) => {
        this.toastr.info(res, error);
      });
    });
    event.target.src = 'assets/gifs/loading.gif';
    let url = this.service.getPathToImage(path);
    let interval = setInterval(() => {
      this.service.checkImageStatus(url).subscribe(
        res => {
          clearInterval(interval);
          event.target.src = url;
        },
        err => {
          if (err.status != 404) {
            clearInterval(interval);
            event.target.src = url;
          }
        }
      );
    }, 1000);
  }
}
