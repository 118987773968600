import { Component, ComponentFactoryResolver, ComponentRef, ElementRef, OnInit, Renderer2, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DeviceLayout, LayoutComponent } from 'projects/qcloud-models/device-layout';
import { DeviceLayoutClientService } from 'projects/qcloud-rest-client/src/lib/device-layout-client.service';
import { MainDisplayComponentRegistry } from 'projects/qcloud-device/src/app/devices/main-display/main-display-components-registry';
import { BaseComponent } from 'projects/qcloud-device/src/app/shared/base-component/base.component';
import { RowStyle } from 'projects/qcloud-models/device/row-style';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { MultimediaService } from 'projects/qcloud-rest-client/src/lib/multimedia.service';
@Component({
  selector: 'app-device-layout',
  templateUrl: './device-layout.component.html',
  styleUrls: ['./device-layout.component.css']
})
export class DeviceLayoutComponent implements OnInit {
  public deviceLayout: DeviceLayout;
  public style: AppComponentStyle[] = [];
  public selectedComponentStyle: AppComponentStyle;
  public selectedIndex: number = 0;
  public selectedRow: boolean;
  components: ComponentRef<BaseComponent>[];
  componentName: string;
  id: number;
  public rowStyle: RowStyle;
  @ViewChild("vc", { read: ViewContainerRef }) vc!: ViewContainerRef;
  @ViewChild("container", { read: ElementRef }) container!: ElementRef;
  constructor(private deviceLayoutservice: DeviceLayoutClientService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private componentFactoryResolver: ComponentFactoryResolver,
    private multimediaClient: MultimediaService) {
    this.rowStyle = new RowStyle();
  }

  ngOnInit(): void {
    this.components = new Array(0);
    this.route.params.subscribe(params => {
      this.id = params['id']
      this.deviceLayoutservice.getDeviceLayout(this.id).subscribe(y => {
        this.rowStyle = y.rowStyle;
        this.deviceLayout = y;
        this.deviceLayout.components.forEach(element => {
          let obj = new AppComponentStyle();
          if (element.style) {
            obj = JSON.parse(element.style);
          }
           if (!element.params) {
            element.params = {};
          }
          if (obj) {
            this.style.push(obj);
          }
          else {
            this.style.push(new AppComponentStyle)
          }
        });
        this.updateBackgroundImage();
        this.createView();
      })

    });
  }

  createView(): void {
    for (let i = 0; i < this.deviceLayout.components.length; i++) {
      const element = this.deviceLayout.components[i];
      const componentClass = MainDisplayComponentRegistry.instance.registry.get(element.name);
      this.addComponent(
        i,
        componentClass,
        element.position,
        element.params,
        this.convertToStyleString(i)
      );
    };
  }

  selectToEdit(i: number) {
    this.selectedIndex = i;
    this.selectedComponentStyle = this.style[i];
    let component = this.components[i];
    this.components.forEach(element => {
      element.instance.setSelected(false);
    });
    component.instance.setSelected(true);
    this.selectedRow = false;
  }

  selectToEditRow() {
    this.components.forEach(element => {
      element.instance.setSelected(false);
    });
    this.selectedComponentStyle = undefined;
    this.selectedRow = true;
  }

  addNewComponent(name: string) {
    var newIndex = this.deviceLayout.components.length;
    const componentClass = MainDisplayComponentRegistry.instance.registry.get(name);
    var layout = new LayoutComponent();
    layout.position = [0, 5, 0, 5];
    layout.style = "";
    layout.params = {};
    layout.name = name;
    this.style.push(new AppComponentStyle());
    this.deviceLayout.components.push(layout);
    this.addComponent(
      newIndex,
      componentClass,
      layout.position,
      layout.params,
      layout.style
    );
  }

  addComponent(index: number, componentClass: Type<BaseComponent>, positions: any, parameters: any, style: string) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentClass);
    const component = this.vc.createComponent(componentFactory);
    component.instance.setPosition(positions[0], positions[1], positions[2], positions[3])
    component.instance.setStyle(style);
    component.instance.setRowStyle(this.rowStyle);
    component.instance.passParameters(parameters);
    component.instance.initMock();
    if (parameters?.displaytype) {
      component.instance.displayType = parameters?.displaytype;
    }
    if (parameters?.maxTicketsToShow) {
      component.instance.maxTicketsToShow = parameters?.maxTicketsToShow;
    }
    if (parameters?.quantityOfColumns) {
      component.instance.quantityOfColumns = parameters?.quantityOfColumns;
    }
    this.components.push(component);

    // Add an onclick event to the component's root element
    const componentRoot = component.location.nativeElement;
    this.renderer.listen(componentRoot, 'click', () => { this.selectToEdit(index) });
  }

  convertToStyleString(i: number) {
    let string = "";
    if (this.style[i].color) {
      string += `color:${this.style[i].color};`
    }
    if (this.style[i].borderSize) {
      string += `border:${this.style[i].borderSize}px solid;`
    }
    if (this.style[i].borderRadius) {
      string += `border-radius:${this.style[i].borderRadius}%;`
    }
    if (this.style[i].borderColor) {
      string += `border-color:${this.style[i].borderColor};`
    }
    if (this.style[i].backgroundType == 0) {
      string += `background: none;`
    }
    if (this.style[i].backgroundType == 1) {
      if (this.style[i].backgroundColor) {
        string += `background: ${this.style[i].backgroundColor};`
      }
    }
    else if (this.style[i].backgroundType == 2) {
      if (this.style[i].backgroundImage) {
        let uri = this.multimediaClient.getFileUriFromCdn(this.style[i].backgroundImage);
        string += `background-image: url(${uri});`
        console.log(string);
      }
    }
    return string;
  }

  updateBackgroundImage() {
    if (!this.deviceLayout.backgroundImg) {
      this.container.nativeElement.style.background = "url('assets/images/background.png')";
      return;
    }
    let uri = this.multimediaClient.getFileUriFromCdn(this.deviceLayout.backgroundImg);
    this.container.nativeElement.style.background = `url('${uri}')`
  }

  deleteBackgroundImage() {
    this.deviceLayout.backgroundImg = undefined;
    this.updateBackgroundImage();
  }

  update(i: number) {
    if (this.style[i].backgroundType == 0) {
      this.style[i].backgroundImage = undefined;
      this.style[i].backgroundColor = undefined;
    }
    else if (this.style[i].backgroundType == 1) {
      this.style[i].backgroundImage = undefined;
    }
    else if (this.style[i].backgroundType == 2) {
      this.style[i].backgroundColor = undefined;
    }

    let convert = this.convertToStyleString(i);
    let data = this.deviceLayout.components[i];
    let positions = data.position;
    let component = this.components[i];
    component.instance.setPosition(positions[0], positions[1], positions[2], positions[3]);
    component.instance.setStyle(convert);
    component.instance.passParameters(data.params);
    component.instance.initMock();
  }

  delete(id: number) {
    this.selectedComponentStyle = undefined;
    this.selectedIndex = -1;
    var q = this.components.splice(id, 1);
    this.vc.remove(id);
    this.deviceLayout.components.splice(id, 1);
    this.style.splice(id, 1);

  }

  save() {
    for (let i = 0; i < this.deviceLayout.components.length; i++) {
      let layout = this.deviceLayout.components[i]
      layout.style = JSON.stringify(this.style[i]);
    }
    this.deviceLayout.rowStyle = this.rowStyle;
    this.deviceLayoutservice.saveDeviceLayout(this.id, this.deviceLayout).subscribe(c => {
      this.toastr.success("Poprawnie zapisano layout");
    })
  }

  updateRows() {
    let string = "";
    if (this.rowStyle.borderSize) {
      string += `border:${this.rowStyle.borderSize}px solid;`
    }
    if (this.rowStyle.borderRadius) {
      string += `border-radius:${this.rowStyle.borderRadius}%;`
    }
    if (this.rowStyle.borderColor) {
      string += `border-color:${this.rowStyle.borderColor};`
    }
    if (this.rowStyle.backgroundType == 0) {
      if (this.rowStyle.backgroundColor) {
        string += `background: ${this.rowStyle.backgroundColor};`
      }
    }
    else {
      if (this.rowStyle.backgroundImage) {
        let uri = this.multimediaClient.getFileUriFromCdn(this.rowStyle.backgroundImage);
        string += `background: url('${uri}');`
      }
    }
  }
}

export class AppComponentStyle {
  font: string;
  color: string = "#000000";
  borderSize: number;
  borderColor: string = "#000000";
  borderRadius: number;
  backgroundType: number;
  backgroundImage: string;
  backgroundColor: string = "#000000";
}