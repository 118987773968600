export enum DeviceType
{
    CallConsole = 1,
    TicketDispenser = 2,
    MainDisplay = 3,
    DeskDisplay = 4,
    OfficeConsole = 5,
    OfficeDisplay = 6,
    CustomerSurveyDisplay = 7,
    CallingDisplay = 8,
    TicketsForHoursDispenser = 9,
    Unknown = 0
}