import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { take, takeUntil } from 'rxjs/operators';
import { Subject, ReplaySubject } from 'rxjs';
import {Office} from 'projects/qcloud-models/grafik/office/office.model';

@Component({
  selector: 'app-served-offices',
  templateUrl: './served-offices.component.html',
  styleUrls: ['./served-offices.component.css']
})
export class ServedOfficesComponent implements OnInit {

  @Input() offices: Office[];

  @Input() officeMultiCtrl: UntypedFormControl;
  public officeMultiFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredOfficesMulti: ReplaySubject<Office[]> = new ReplaySubject<Office[]>(1);

  protected _onDestroy = new Subject<void>();

  @Input() title: string;
  @Input() noSelectedCOffices: string;
  @Input() displayOffices: boolean;

  @Output() officesToEmit = new EventEmitter<Office[]>();
  constructor() { }

  ngOnInit(): void {
    // load the initial office list
    this.filteredOfficesMulti.next(this.offices.slice());
    // listen for search field value changes
    this.officeMultiFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterOfficesMulti();
    });
  }

  emitOffices(offices: Office[]) {
    this.officesToEmit.emit(offices);
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected filterOfficesMulti() {
    if (!this.offices) {
      return;
    }
    // get the search keyword
    let search = this.officeMultiFilterCtrl.value;
    if (!search) {
      this.filteredOfficesMulti.next(this.offices.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the Categories
    this.filteredOfficesMulti.next(
      this.offices.filter(office => office.name.toLowerCase().indexOf(search) > -1)
    );
  }

}
