<div class="card shadow mb-4">
    <mat-accordion>
        <mat-expansion-panel (opened)="isCollapsed = false"
                             (closed)="isCollapsed = true"
                             hideToggle="true"
                             [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
                <h5 class="col-10 m-2 font-weight-bold text-primary">{{ 'reservation-translation' | translate }}</h5>
            </mat-panel-title>
            <mat-panel-description>
                    <button type="button" class="btn btn-primary btn-circle btn-sm" (click)="isCollapsed = !isCollapsed"
                        [attr.aria-expanded]="!isCollapsed" aria-controls="collapseCategory"
                        [ngClass]="{'clicked': isCollapsed}">
                        <i class="fas fa-angle-down"></i>
                    </button>
            </mat-panel-description>
          </mat-expansion-panel-header>
    <div>

        <app-reservation-button-text-translation [locationLocaleMain]="locationLocaleMain"
            [translations]="translations" [languages]="languages">
        </app-reservation-button-text-translation>

        <hr>

        <app-reservation-questionaire-translation [locationLocaleMain]="locationLocaleMain"
            [translations]="translations" [languages]="languages">
        </app-reservation-questionaire-translation>

    </div>
</mat-expansion-panel>
</mat-accordion>
</div>