import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';
import { TimeConversionService } from 'projects/qcloud-web/src/app/shared/time-conversion.service';

@Component({
  selector: 'app-average-waiting-service-time-statistics',
  templateUrl: './average-waiting-service-time-statistics.component.html',
  styleUrls: ['./average-waiting-service-time-statistics.component.css']
})
export class AverageWaitingServiceTimeStatisticsComponent implements OnInit {

  @Input() averageWaitingTime: number;
  @Input() averageServiceTime: number;
  labels: string[];
  data: number[];
  combinedTime: number;

  constructor(private translate: TranslateService, private timeService: TimeConversionService) { }

  ngOnInit(): void {
    let ctx = document.getElementById("averageWaitingServiceTimePieChart");
    this.setLabels();
    this.data = [this.timeService.timestampToSeconds(this.averageWaitingTime),
      this.timeService.timestampToSeconds(this.averageServiceTime)];
    this.combinedTime = this.data.reduce((a, b) => a + b, 0);
    let averageWaitingServiceTimePieChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: this.labels,
        datasets: [{
          data: this.data,
          backgroundColor: ['#4e73df', '#1cc88a'],
          hoverBackgroundColor: ['#2e59d9', '#17a673'],
          hoverBorderColor: "rgba(234, 236, 244, 1)",
        }],
      },
      options: {
        maintainAspectRatio: false,
        tooltips: {
          backgroundColor: "rgb(255,255,255)",
          bodyFontColor: "#858796",
          borderColor: '#dddfeb',
          borderWidth: 1,
          xPadding: 15,
          yPadding: 15,
          displayColors: false,
          caretPadding: 10,
          callbacks: {
            label: (tooltipItem, data) => {
              if (data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] == 0)
                return;
              return data.labels[tooltipItem.index] + ": " + 
                this.timeService.secondsToTime(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]);
            } 
          }
        },
        legend: {
          display: false
        },
        cutoutPercentage: 80,
        elements: {
          center: {
          text: this.timeService.secondsToTime(this.combinedTime),
          color: '#4e73df'
          }
        }
      }
    });
  }

  setLabels() {    
    this.labels = new Array(2);
    this.setLabelsTranslations();
    
    this.translate.onLangChange.subscribe(
      () => {
        this.setLabelsTranslations();
      }
    );
  }

  setLabelsTranslations() {
    this.translate.get(['average-waiting-time', 'average-service-time']).subscribe(
      (translations: {[key: string]: string}) => {
        this.labels[0] = translations['average-waiting-time'];
        this.labels[1] = translations['average-service-time'];
      }
    );
  }

}
