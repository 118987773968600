<div class="table-responsive">
    <table class="table table-bordered">
        <thead>
            <tr>
                <th>#</th>
                <th>{{ 'categories' | translate }}</th>
                <th>{{ 'duration' | translate }}</th>
                <th>{{ 'options' | translate }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let activityBlock of locationActivityBlocks; let i = index">
                <td>{{ i + 1 }}</td>
                <td>
                    <app-categories-column *ngIf="blockedCategories[i]" [categories]="blockedCategories[i]"></app-categories-column>
                </td>
                <td>
                    {{ activityBlock.dateStart | date: 'dd/MM/yyyy' }} -
                    {{ activityBlock.dateEnd | date: 'dd/MM/yyyy' }}
                </td>
                <td>
                    <button class="btn btn-info btn-circle btn-sm mx-1 mb-1" (click)="getActivityBlock(i)"
                        matTooltip="{{ 'edit' | translate }}" tooltipClass="tooltip-info">
                        <i class="fas fa-fw fa-edit"></i>
                    </button>
                    <button class="btn btn-danger btn-circle btn-sm mx-1 mb-1"
                        (click)="currentActivityBlock = activityBlock"
                        data-toggle="modal" data-target="#deleteActivityModal"
                        matTooltip="{{ 'delete' | translate }}" tooltipClass="tooltip-danger">
                        <i class="fas fa-fw fa-trash"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="form-group">
    <button class="btn btn-primary m-1" (click)="newActivityBlock()">
        {{ 'add-new-category-activity-block' | translate }}
    </button>
</div>

<ng-template #modalActivityBlock let-close="close">
    <div class="modal-header">
        <h5 *ngIf="isNewActivityBlock" class="modal-title">{{ 'add-new-category-activity-block' | translate}}</h5>
        <h5 *ngIf="!isNewActivityBlock" class="modal-title">{{ 'edit-category-activity-block' | translate}}</h5>
        <button type="button" class="close" (click)="close()" mat-dialog-close>
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" mat-dialog-content>
        <app-served-categories *ngIf="categoryMultiCtrl" [categoryMultiCtrl]="categoryMultiCtrl"
            [categories]="categories" [title]="categoriesToBlock" [noSelectedCategories]="noSelectedCategories"
            [displayCategories]="true">
        </app-served-categories>

        <app-date-range-picker [startDate]="currentActivityBlock.dateStart" [endDate]="currentActivityBlock.dateEnd"
            (rangeToEmit)="getRange($event)">
        </app-date-range-picker>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" (click)="onSubmit(); close()"mat-dialog-close>
            <span *ngIf="isNewActivityBlock">{{ 'add-new-category-activity-block' | translate }}</span>
            <span *ngIf="!isNewActivityBlock">{{ 'edit-category-activity-block' | translate }}</span>
        </button>
    </div>
</ng-template>

<div class="modal fade" id="deleteActivityModal" tabindex="-1" role="dialog" aria-hidden="true">
    <app-delete-modal [info]="'delete-confirmation'" [confirm]="'delete'" (confirmed)="onDeleteConfirm($event)"></app-delete-modal>
</div>