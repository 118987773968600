import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SmsProviderConfiguration } from 'projects/qcloud-models/sms/sms-provider-configuration';
import { SmsConfigService } from 'projects/qcloud-rest-client/src/lib/sms-config.service';

@Component({
  selector: 'app-sms-provider',
  templateUrl: './sms-provider.component.html',
  styleUrls: ['./sms-provider.component.css']
})
export class SmsProviderComponent implements OnInit {
  smsProviderConfiguration: SmsProviderConfiguration;
  configuration: any;
  formSubmitted: boolean = false;
  constructor(private smsService: SmsConfigService,
    private translate: TranslateService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.configuration = {};
    this.getProvider();
  }

  getProvider() {
    this.smsService.getSmsProvider().subscribe(
      smsProviderConfiguration => {
        this.smsProviderConfiguration = smsProviderConfiguration;
        this.configuration = JSON.parse(smsProviderConfiguration.configJSON);
        if (this.configuration == null) {
          this.configuration = {};
        }
      }
    );
  }

  onSubmit() {
    this.smsProviderConfiguration.configJSON = JSON.stringify(this.configuration);
    this.smsService.setSmsProvider(this.smsProviderConfiguration).subscribe(
      res => {
        this.formSubmitted = false;
        this.translate.get('update-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('update-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }
}
