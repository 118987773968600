<div class="card shadow mb-4">
    <div class="card-header py-3">
        <div class="row">
            <h5 class="col-8 m-0 font-weight-bold text-primary">{{ 'playlists' | translate }}</h5>
            <div class="text-right col-4">
                <button type="button" class="btn btn-primary btn-circle btn-sm" routerLink="/playlist/add"
                    matTooltip="{{ 'add' | translate }}" tooltipClass="tooltip-primary">
                    <i class="fas fa-fw fa-plus"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{{ 'name' | translate }}</th>
                        <th>{{ 'options' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let playlist of playlists; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>{{ playlist.name }}</td>
                        <td>
                            <div class="row">
                                <button class="btn btn-info btn-circle btn-sm mx-1 mb-1"
                                    [routerLink]="['/playlist/edit/', playlist.id]"
                                    matTooltip="{{ 'edit' | translate }}" tooltipClass="tooltip-info">
                                    <i class="fas fa-fw fa-edit"></i>
                                </button>
                                <button (click)="selectedPlaylistId = playlist.id" class="btn btn-danger btn-circle btn-sm mx-1 mb-1"
                                    matTooltip="{{ 'delete' | translate }}" tooltipClass="tooltip-danger"
                                    data-toggle="modal" data-target="#deleteModal">
                                    <i class="fas fa-fw fa-trash"></i>
                                </button>
                            </div>
                        </td>
                        </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-hidden="true">
    <app-delete-modal [info]="'delete-confirmation'" [confirm]="'delete'" (confirmed)="onDeleteConfirm($event)"></app-delete-modal>
</div>

