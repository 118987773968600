export class DisplaySystemCategory {
    id: number;
    systemId: number;
    systemCategoryId: number;
    fontColorHex: string;
    borderColorHex: string;
    borderSize: number;
    borderRadius: number;
    backgroundType: number;
    backgroundImgPath: string;
    backgroundColorHex: string;
    afterPrintImgPath: string;
    onActiveBackgroundColorHex: string;
}