import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { BaseComponent } from '../../base-component/base.component';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.css']
})
export class ImageComponent extends BaseComponent implements OnInit {
  paramUrl: string;
  url: string;
  constructor(protected elementRef: ElementRef, protected renderer: Renderer2) {
    super(elementRef, renderer);
  }

  ngOnInit(): void {
  }

  startComponent(): void {
    this.url = this.getUrlForServer(this.paramUrl);
  }

  passParameters(params: any): void {
    this.paramUrl = params.imageUrl;
  }

  initMock(): void {
    if (this.paramUrl) {
      let config = this.configurationService.getConfiguration();
      let appBase = config.deviceNetworkConfiguration.qdsHost;
      let locationId = config.locationId;
      let systemId = config.systemId;
      this.url = `${appBase}cdn/${systemId}/${locationId}/${this.paramUrl.substring(1)}`
    }
  }
}
