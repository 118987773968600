<div class="form-group">
    <div class="font-weight-bold">{{ 'assigned-desk' | translate }}</div>
    <mat-form-field class="ml-2">
        <mat-select name="deskId" #deskId="ngModel" [(ngModel)]="device.deskId">
            <mat-option *ngFor="let desk of desks" [value]="desk.id">{{ desk.name }} {{desk.deskNumber}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div class="form-group">
    <div class="input-group">
        *<div class="font-weight-bold">{{ 'layout' | translate }}</div>
    </div>
    <mat-form-field class="ml-2">
        <mat-select name="layoutType" #layoutType="ngModel" [(ngModel)]="device.layoutType" required>
            <mat-option [value]="LayoutDeskType.Basic">BASIC</mat-option>
            <mat-option *ngIf="locationService.getLocationConstraints().isWorkersScheduleEnabled"
                [value]="LayoutDeskType.Office">OFFICE
            </mat-option>
            <mat-option *ngIf="!locationService.getLocationConstraints().isWorkersScheduleEnabled"
                disabled matTooltipPosition="right" matTooltipClass="tooltip-orange"
                matTooltip="{{ 'option-available-after-extending-licence' | translate }}">OFFICE
            </mat-option>
            <mat-option [value]="LayoutDeskType.ByHourCategory">BY HOUR CATEGORY</mat-option>
        </mat-select>
    </mat-form-field>
    <div *ngIf="layoutType.invalid && (layoutType.dirty || layoutType.touched)" class="text-danger">
        <div *ngIf="layoutType.errors.required">{{ 'layout-required' | translate }}</div>
    </div>
</div>

<div class="form-group">
    <div class="input-group">
        *<div class="font-weight-bold">{{ 'read-out-tickets-method' | translate }}</div>
    </div>
    <mat-form-field class="ml-2">
        <mat-select name="sayingTicketsType" #sayingTicketsType="ngModel" [(ngModel)]="device.sayingTicketsType" required>
            <mat-option [value]="SayingTicketsTypes.Loudly">{{ 'read-ticket-aloud' | translate }}</mat-option>
            <mat-option [value]="SayingTicketsTypes.SimpleSound">{{ 'simple-sound' | translate }}</mat-option>
            <mat-option [value]="SayingTicketsTypes.NoSound">{{ 'no-sound' | translate }}</mat-option>
        </mat-select>
    </mat-form-field>
    <div *ngIf="sayingTicketsType.invalid && (sayingTicketsType.dirty || sayingTicketsType.touched)" class="text-danger">
        <div *ngIf="sayingTicketsType.errors.required">{{ 'read-out-tickets-method-required' | translate }}</div>
    </div>
</div>
