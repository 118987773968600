import { Playlist } from './playlist.model';
import { WeeklySchedule } from './weekly-schedule.model';

export class PlaylistWithWeeklySchedule {
    public id: number;
    public mediaPlaylistId: number;
    public dateStart: Date;
    public dateEnd: Date;
    public weeklySchedule: WeeklySchedule;
    public mediaPlaylist: Playlist;
}