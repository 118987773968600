<div class="card shadow mb-4">
    <div class="card-header py-3">
        <div class="row">
            <h5 class="col-8 m-0 font-weight-bold text-primary">{{ 'display-configuration' | translate }}</h5>
            <div class="text-right col-4">
            </div>
        </div>
    </div>
    <div *ngIf="configurationSchedule" class="card-body">
        <div class="form-group">
            <div class="item-group">
                <mat-checkbox name="ShowWorkersAfterWorkShiftCheckBox" #ShowWorkersAfterWorkShiftCheckBox="ngModel"
                    [(ngModel)]="configurationSchedule.showWorkersAfterWorkShift" class="font-weight-bold" color="primary">
                    {{ 'show-workers-after-work-shift' | translate }}
                </mat-checkbox>
            </div>
            <div class="item-group">
                <mat-checkbox name="ShowWorkersBeforeWorkShiftCheckBox" #ShowWorkersBeforeWorkShiftCheckBox="ngModel"
                    [(ngModel)]="configurationSchedule.showWorkersBeforeWorkShift" class="font-weight-bold" color="primary">
                    {{ 'show-workers-before-work-shift' | translate }}
                </mat-checkbox>
            </div>      
        </div>
      
        <div class ="item-group">
            <button class="btn btn-primary btn-sm" (click)="editConfiguration()">
                {{ 'save' | translate }}</button>
        </div>
    </div>
</div>