<div class="table-responsive">
    <table class="table table-bordered">
        <thead>
            <tr>
                <th>#</th>
                <th>{{ 'news-content' | translate }}</th>
                <th>{{ 'options' | translate }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let singleNews of news; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ singleNews.name }}</td>
                <td>
                    <div class="row">
                        <button class="btn btn-info btn-circle btn-sm mx-1 mb-1"
                            (click)="router.navigate(['singleNews', singleNews.id])" matTooltip="{{ 'edit' | translate }}"
                            tooltipClass="tooltip-info">
                            <i class="fas fa-fw fa-edit"></i>
                        </button>
                        <button (click)="selectedNewsId = singleNews.id"
                            class="btn btn-danger btn-circle btn-sm mx-1 mb-1" matTooltip="{{ 'delete' | translate }}"
                            tooltipClass="tooltip-danger" data-toggle="modal" data-target="#deleteModal4">
                            <i class="fas fa-fw fa-trash"></i>
                        </button>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="modal fade" id="deleteModal4" tabindex="-1" role="dialog" aria-hidden="true">
    <app-delete-modal [info]="'delete-confirmation'" [confirm]="'delete'" (confirmed)="onDeleteConfirm($event)">
    </app-delete-modal>
</div>