import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { QcloudConsoleService } from '../../qcloud-console.service';

@Component({
  selector: 'lib-served-categories-button',
  templateUrl: './served-categories-button.component.html',
  styleUrls: ['./served-categories-button.component.css',  './../base-button/base-button.component.css']
})
export class ServedCategoriesButtonComponent  implements OnInit {
  @Input() buttonTitleTranslationTag: string;
  @Input() public tooltipTitle: string;
  @ViewChild('button') public button: ElementRef;
  isEnabled: boolean;
  hasPermission: boolean;

  constructor(private consoleService: QcloudConsoleService) {
    this.consoleService.ticket.subscribe((val) => {
      if (val.id != -1) {
        this.isEnabled = false;
      } else {
        this.isEnabled = true;
      }
    });
   }

  ngOnInit(): void {
    this.hasPermission = this.consoleService.user.mainRole.manualCategoryCalling;
  }

  public openServedCategoriesModal() {
    if (this.isEnabled) {
      this.consoleService.openServedCategoriesModal();
    }
  }

  getButtonTitleTranslateTag() {
    if(this.buttonTitleTranslationTag === undefined || this.buttonTitleTranslationTag === "") {
      return 'served-categories';
    }
    return this.buttonTitleTranslationTag;
  }
}
