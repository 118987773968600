import { Category } from "../category/category.model";
import { LocationDataDto } from "../location/dto/location-dto.model";

export class QcloudRole {
    public id: number;
    public systemId: number;
    public locationIds: number[];
    public locations: LocationDataDto[];
    public roleName: string;
    public rolePermissionLevel: number;
    public categories: Category[];
    public hasAccessToConsole: boolean;
    public hasPermissionToCallingTicketsManually: boolean;
    public hasPermissionToBlockingCategoryManually: boolean;
    public manualCategoryCalling: boolean;
    public canGenerateTicketsInConsole: boolean;
    public hasPermissionToReserveTickets: boolean;
    public hasPermissionToConfigureGrafik: boolean;
    public hasPermissionToServeETickets: boolean;
}