<div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800">{{ 'statistics-ticket' | translate }}</h1>
</div>

<div *ngIf="ticketStats && categories" class="card-group">
    <div class="card shadow mb-4">
        <app-ticket-result-statistics
            [processedTicketsCount]="ticketStats.processedTicketsCount">
        </app-ticket-result-statistics>
    </div>
    <div class="card shadow mb-4 mx-4">
        <app-queue-state-statistics [queueState]="ticketStats.queueState" [categories]="categories">
        </app-queue-state-statistics>
    </div>
    <div class="card shadow mb-4">
        <app-average-waiting-service-time-statistics
            [averageWaitingTime]="ticketStats.averageWaitingTime" [averageServiceTime]="ticketStats.averageServiceTime">
        </app-average-waiting-service-time-statistics>
    </div>
</div>

<div *ngIf="desks && ticketStats" class="row">
    <div class="col-lg-6">
        <div class="card shadow mb-4">
            <app-waiting-time-desk-statistics [averageWaitingTime]="ticketStats.averageWaitingTime"
                [desks]="desks" [averageDeskWaitingTime]="ticketStats.averageDeskWaitingTime">
            </app-waiting-time-desk-statistics>
        </div>
    </div>
    <div class="col-lg-6">
        <div class="card shadow mb-4">
            <app-service-time-desk-statistics [averageServiceTime]="ticketStats.averageServiceTime"
                [desks]="desks" [averageDeskServiceTime]="ticketStats.averageDeskServiceTime">
            </app-service-time-desk-statistics>
        </div>
    </div>
</div>

<div *ngIf="categories && ticketStats" class="row">
    <div class="col-lg-6">
        <div class="card shadow mb-4">
            <app-waiting-time-category-statistics [averageWaitingTime]="ticketStats.averageWaitingTime" 
                [categories]="categories" [averageCategoryWaitingTime]="ticketStats.averageCategoryWaitingTime">
            </app-waiting-time-category-statistics>
        </div>
    </div>
    <div class="col-lg-6">
        <div class="card shadow mb-4">
            <app-service-time-category-statistics [averageServiceTime]="ticketStats.averageServiceTime"
                [categories]="categories" [averageCategoryServiceTime]="ticketStats.averageCategoryServiceTime">
            </app-service-time-category-statistics>
        </div>
    </div>
</div>

<div *ngIf="categories && ticketStats" class="card shadow mb-4">
    <app-ticket-in-category-statistics [categories]="categories"
        [processedCategoryTicketsCounts]="ticketStats.processedCategoryTicketsCounts">
    </app-ticket-in-category-statistics>
</div>

<div *ngIf="desks && ticketStats" class="card shadow mb-4">
    <app-ticket-in-desk-statistics [desks]="desks"
        [processedDeskTicketsCounts]="ticketStats.processedDeskTicketsCounts">
    </app-ticket-in-desk-statistics>
</div>

<div *ngIf="categories && desks && ticketStats" class="card shadow mb-4">
    <app-ticket-in-category-and-desk-statistics [categories]="categories" [desks]="desks"
        [processedDeskAndCategoriesTicketsCounts]="ticketStats.processedDeskAndCategoriesTicketsCounts">       
    </app-ticket-in-category-and-desk-statistics>
</div>

<div *ngIf="categories && ticketStats" class="card shadow mb-4">
    <app-daily-queueing-statistics [categories]="categories"
        [dailyCategoryQueue]="ticketStats.dailyCategoryQueue">
    </app-daily-queueing-statistics>
</div>
