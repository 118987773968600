<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="m-0 font-weight-bold text-primary">{{ 'on-hours-schedule-configuration' | translate }}</h5>
    </div>
    <div class="card-body">
        <div>
            <mat-form-field>
                <mat-label>{{ 'reservation-choosen-category' | translate }}</mat-label>
                <mat-select matNativeControl [(ngModel)]="choosenCategory">
                    <mat-option *ngFor="let category of reservationCategories" [value]="category">
                        {{ category.fullName }} {{ category.categoryTag }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="mb-3">
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>{{ 'name' | translate }}</th>
                            <th>{{ 'reservation-schedule-range' | translate }}</th>
                            <th>{{ 'days' | translate }}</th>
                            <th>{{ 'options' | translate }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of categorySchedules; let i = index">
                            <td>{{ data.name }}</td>
                            <td>
                                <span *ngIf="data.defaultConfiguration==false">
                                    {{ data.startTime | date:'dd/MM/yyyy' }} - {{ data.endTime | date:'dd/MM/yyyy'}}
                                </span>
                                <span *ngIf="data.defaultConfiguration">
                                    {{ 'reservation-category-always-active' | translate }}
                                </span>
                            </td>
                            <td>
                                <span *ngFor="let day of data.days"
                                    class="btn btn-blue btn-circle btn-sm mx-1 mb-1">
                                    {{ dayNames[day] | translate | slice:0:3 }}
                                </span>
                            </td>
                            <td>
                                <button class="btn btn-info btn-circle btn-sm mr-1" (click)="editSchedule(data)"
                                    matTooltip="{{ 'edit' | translate }}" tooltipClass="tooltip-info">
                                    <i class="fas fa-fw fa-edit"></i>
                                </button>
                                <button class="btn btn-danger btn-circle btn-sm" (click)="deleteSchedule(data)"
                                    matTooltip="{{ 'delete' | translate }}" tooltipClass="tooltip-danger">
                                    <i class="fas fa-fw fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                </table>
            </div>
            <button class="btn btn-primary" (click)="addSchedule()">
                {{ 'add-schedule' | translate }}
            </button>
        </div>
    </div>
</div>

<!-- add/update schedule modal -->
<ng-template #modalContent let-close="close">
    <div class="modal-header">
        <h5 class="modal-title">{{ 'edit-on-hours-schedule' | translate }}</h5>
        <button mat-dialog-close type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" mat-dialog-content>
        <div class="col-12">
            <mat-label>{{ 'schedule-name' | translate }}</mat-label>
            <input [(ngModel)]="schedule.name" class="form-control">
        </div>
        <div class="col-12">
            <mat-label>{{ 'reservation-schedule-range' | translate }}</mat-label>
        </div>
        <div class="col-12">
            <div>
                <div class="form-group">
                    <div class="item-group">
                        <mat-checkbox name="default" #default="ngModel" [(ngModel)]="schedule.defaultConfiguration"
                            color="primary" class="mr-4">{{ 'reservation-category-always-active' | translate }}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="form-group text-center">
                <div *ngIf="!schedule.defaultConfiguration">
                    <app-date-range-picker [startDate]="scheduleRange[0]" [endDate]="scheduleRange[1]"
                        (rangeToEmit)="setScheduleRange($event)">
                    </app-date-range-picker>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="mb-3">
                {{ 'weekdays' | translate }}:
                <div class="btn-group">
                    <button class="btn btn-primary" (click)="selectAll()">
                        {{ 'all' | translate }}
                    </button>
                    <button class="btn btn-primary" (click)="selectWorkweek()">
                        {{ 'workweek' | translate }}
                    </button>
                    <button class="btn btn-primary" (click)="selectWeekend()">
                        {{ 'weekend' | translate }}
                    </button>
                    <button class="btn btn-primary" (click)="unselectAll()">
                        {{ 'deselect' | translate }}
                    </button>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="item-group">
                <mat-checkbox *ngFor="let day of dayNames; let i = index" [(ngModel)]="selectedDays[i]"
                    color="primary" class="mr-4">{{ day | translate }}
                </mat-checkbox>
            </div>
        </div>
        <div class="col-6">
            <div class="form-group">
                <mat-label>{{ 'reservation-capacity' | translate }}</mat-label>
                <input [(ngModel)]="schedule.categoryCapacity" class="form-control" type="number" [min]="1">
            </div>
        </div>
        <div class="col-6">
            <div class="form-group">
                <mat-label>{{ 'reservation-interval' | translate }} (.min)</mat-label>
                <input [(ngModel)]="schedule.reservationIntervalMins" class="form-control" type="number" [min]="1">
            </div>
        </div>
        <div class="col-12">
            <mat-label>{{ 'work-times' | translate }}</mat-label>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{{ 'from' | translate }}</th>
                        <th>{{ 'to' | translate }}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of scheduleTimes; let i = index">
                        <td>{{ i+1 }}</td>
                        <td>
                            <mat-form-field>
                                <input (change)=checkTime() matInput type="time" [(ngModel)]="data.startTime">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field>
                                <input (change)=checkTime() matInput type="time" [(ngModel)]="data.endTime">
                            </mat-form-field>
                        </td>
                        <td>
                            <button class="btn btn-danger btn-circle btn-sm" (click)="deleteScheduleTime(i)">
                                <i class="fas fa-fw fa-trash"></i>
                            </button>
                        </td>
                    </tr>
            </table>
        </div>
        <div class="col-12">
            <button class="btn btn-primary" (click)="addScheduleTime()">
                {{ 'add-work-time' | translate }}
            </button>
        </div>
    </div>
    <div class="modal-footer">
        <div *ngIf="!isTimeCorect">{{ 'wrong-time-setting' | translate }}</div>
            <button mat-dialog-close [disabled]=(!isTimeCorect) class="btn btn-primary" (click)="onCloseModal()">
                {{ 'save' | translate }}
            </button>
    </div>
</ng-template>