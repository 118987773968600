import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { Subject, ReplaySubject } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { User } from 'projects/qcloud-models/user/user.model';

@Component({
  selector: 'app-served-users',
  templateUrl: './served-users.component.html',
  styleUrls: ['./served-users.component.css']
})
export class ServedUsersComponent implements OnInit {

  @Input() users: User[];

  @Input() userMultiCtrl: UntypedFormControl;
  public userMultiFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredUsersMulti: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);

  protected _onDestroy = new Subject<void>();

  @Input() title: string;
  @Input() noSelectedUsers: string;
  @Input() displayUsers: boolean;

  @Output() usersToEmit = new EventEmitter<User[]>();

  constructor() { }

  ngOnInit(): void {
    // load the initial user list
    this.filteredUsersMulti.next(this.users.slice());

    // listen for search field value changes
    this.userMultiFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterUsersMulti();
    });
  }

  emitUsers(users: User[]) {
    this.usersToEmit.emit(users);
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredUsersMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.userMultiCtrl.patchValue(val);
        } else {
          this.userMultiCtrl.patchValue([]);
        }
        this.usersToEmit.emit(this.userMultiCtrl.value);
      });
  }

  protected filterUsersMulti() {
    if (!this.users) {
      return;
    }
    // get the search keyword
    let search = this.userMultiFilterCtrl.value;
    if (!search) {
      this.filteredUsersMulti.next(this.users.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the Users
    this.filteredUsersMulti.next(
      this.users.filter(user =>
        user.lastName.toLowerCase().indexOf(search) > -1 ||
        user.firstName.toLowerCase().indexOf(search) > -1)
    );
  }

}
