import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeExtention'
})
export class RemoveExtentionPipe implements PipeTransform {

  transform(item: string) {
    if(item.includes('.')) {
      let idx = item.lastIndexOf('.');
      return item.substring(0, idx);
    } else {
      return item;
    }
  }
}