import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';
import { Dictionary } from 'projects/qcloud-models/dictionary';
import { DeskOpinionData } from 'projects/qcloud-models/survey/desk-opinion-data.model';
import { ProcessedOpinionData } from 'projects/qcloud-models/survey/processed-opinion-data.model';
import { SurveyService } from 'projects/qcloud-rest-client/src/lib/survey.service';
import { enableChartCenterText } from 'projects/qcloud-web/src/app/page/statistics/utils/chartUtils';

@Component({
  selector: 'app-daily-customer-survey-statistics',
  templateUrl: './daily-customer-survey-statistics.component.html',
  styleUrls: ['./daily-customer-survey-statistics.component.css']
})
export class DailyCustomerSurveyStatisticsComponent implements OnInit {

  labels: string[];
  opinions: number;
  deskOpinions: Dictionary<DeskOpinionData>;
  today: Date = new Date();

  constructor(private translate: TranslateService, private surveyService: SurveyService) { }

  ngOnInit(): void {
    this.setPieChartWithData();
    this.setLabels();
    enableChartCenterText();
  }

  setPieChartWithData() {
    let ctx = document.getElementById("dailyCustomerSurveyPieChart");
    let chartData = [];
    let endDay = new Date(this.today);
    endDay.setDate(this.today.getDate() + 1);
    this.surveyService.getOpinions(this.today, endDay,
      data => {
        chartData = [data.overallOpinion.happy, data.overallOpinion.neutral, data.overallOpinion.angry];
        this.opinions = chartData.reduce((a, b) => a + b, 0);
        let dailyCustomerSurveyPieChart = new Chart(ctx, {
          type: 'doughnut',
          data: {
            labels: this.labels,
            datasets: [{
              data: chartData,
              backgroundColor: ['#1cc88a', '#f6c23e', '#d9534f'],
              hoverBackgroundColor: ['#17a673', '#f4b515', '#d33b36'],
              hoverBorderColor: "rgba(234, 236, 244, 1)",
            }],
          },
          options: {
            maintainAspectRatio: false,
            tooltips: {
              backgroundColor: "rgb(255,255,255)",
              bodyFontColor: "#858796",
              borderColor: '#dddfeb',
              borderWidth: 1,
              xPadding: 15,
              yPadding: 15,
              displayColors: false,
              caretPadding: 10,
            },
            legend: {
              display: false
            },
            cutoutPercentage: 80,
            elements: {
              center: {
                text: this.opinions,
                color: '#4e73df'
              }
            }
          }
        });
      },
      err => {
        console.log(err);
      }
    )
  }

  setLabels() {
    this.labels = new Array(3);
    this.setLabelsTranslations();

    this.translate.onLangChange.subscribe(
      () => {
        this.setLabelsTranslations();
      }
    );
  }

  setLabelsTranslations() {
    this.translate.get(["happy", "ambivalent", "unhappy"]).subscribe(
      (translations: { [key: string]: string }) => {
        this.labels[0] = translations['happy'];
        this.labels[1] = translations['ambivalent'];
        this.labels[2] = translations['unhappy'];
      }
    );
  }

}
