import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Translation } from 'projects/qcloud-models/translation/translation.model';
import { Language } from 'projects/qcloud-models/translation/language.model';
import { DeskTranslation } from 'projects/qcloud-models/translation/desk-translation.model';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { DeskService } from 'projects/qcloud-rest-client/src/lib/desk.service';
import { TranslationService } from 'projects/qcloud-rest-client/src/lib/translation.service';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { TranslationConfigurationService } from 'projects/qcloud-web/src/app/shared/translation-configuration.service';

@Component({
  selector: 'app-desk-translation',
  templateUrl: './desk-translation.component.html',
  styleUrls: ['./desk-translation.component.css',
  '../languages-and-translations.component.css']
})
export class DeskTranslationComponent implements OnInit {

  deskTranslations: Array<DeskTranslation[]>;
  @Input() translations: Translation[];
  @Input() languages: Language[];
  @Input() locationLocaleMain: string;
  startIndex: number;
  desks: Desk[];
  isCollapsed: boolean = false;
  formSubmitted: boolean = false;


  constructor(
    private deskService: DeskService, 
    private service: TranslationService, 
    private authService: AuthService,
    private translate: TranslateService, 
    private toastr: ToastrService, 
    private translationConfigurationService: TranslationConfigurationService
    ) { }

  ngOnInit(): void {
    this.deskTranslations = new Array<DeskTranslation[]>(this.translations.length);
    this.translationConfigurationService.translationLanguages.subscribe(translations => {
      this.translations = translations;
      this.getDesksAndTranslations();
    });
    this.getDesksAndTranslations();
  }

  getDesksAndTranslations() {
    this.deskService.getDesks((data)=>{
      this.desks = data;
      for (let i = 0; i < this.translations.length; i++) {
        this.deskTranslations[i] = new Array<DeskTranslation>(this.desks.length); 
      }
      this.service.getDeskTranslation().subscribe(
        res => {
          for (let j = 0; j < this.desks.length; j++) {
            for (let i = 0; i < this.translations.length; i++) {
              let dT = res.find(dT => dT.translationId == this.translations[i].id && dT.deskId == this.desks[j].id);
              if (dT) {
                this.deskTranslations[i][j] = dT;
              } else {
                dT = new DeskTranslation();
                dT.translationId = this.translations[i].id;
                dT.deskId = this.desks[j].id;
                dT.locationId = this.authService.getLocationId();
                this.deskTranslations[i][j] = dT;
              }
            }  
          }
        }
      );
    },
    ()=>{}
    );
  }

  getLocaleName(locale: string) {
    return this.languages.find(languageOption => languageOption.locale == locale).name;
  }

  deleteLocaleMain(translations: Translation[]) {
    return translations.filter(translation => translation.locale != this.locationLocaleMain);
  }

  onSubmit() {
    this.formSubmitted = true;
    let mergedDeskTranslations: DeskTranslation[] = new Array<DeskTranslation>();
    this.deskTranslations.forEach(deskTranslation => {
      mergedDeskTranslations = mergedDeskTranslations.concat(deskTranslation);
    });
    this.service.setDeskTranslation(mergedDeskTranslations).subscribe(
      res => {
        this.formSubmitted = false;
        this.translate.get('desk-translation-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('desk-translation-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  getFilteredTranslations(startIndex: number) {
    this.startIndex = startIndex;
  }

  isOnPage() {
    return this.translations.slice(this.startIndex, this.startIndex + 2);
  }

}
