import { Component, OnInit } from '@angular/core';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { OfficeDeskRelation } from 'projects/qcloud-models/grafik/office/office-desk-relation';
import { Office } from 'projects/qcloud-models/grafik/office/office.model';
import { DeskService } from 'projects/qcloud-rest-client/src/lib/desk.service';
import { OfficeService } from 'projects/qcloud-rest-client/src/lib/grafik/office.service';

@Component({
  selector: 'app-office-desk',
  templateUrl: './office-desk.component.html',
  styleUrls: ['./office-desk.component.css']
})
export class OfficeDeskComponent implements OnInit {
  
  officeDeskRelations: OfficeDeskRelation[];
  offices: Office[];
  desks: Desk[];
  constructor(private officeService: OfficeService,
    private deskService: DeskService) { }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.deskService.getDesks((desks) => {
      this.desks = desks;
    }, () => { });
    this.officeService.getOffices((offices) => {
      this.offices = offices;
    }, () => { })
    this.officeService.getOfficeDeskRelations((officeDeskRelations) => {
      this.officeDeskRelations = officeDeskRelations;
    }, () => { })
  }

  getDeskName(deskId) {
    let desk = this.desks.filter(x => x.id == deskId)[0];
    if (desk == undefined) return "";
    return `${desk.fullName} ${desk.deskNumber}`;
  }

  getOfficeName(officeId) {
    let office = this.offices.filter(x => x.id == officeId)[0];
    if (office == undefined) return "";
    return `${office.name}`;
  }
}
