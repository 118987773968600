import { Component, OnInit } from '@angular/core';
import { SystemVersion } from 'projects/qcloud-models/system/system-version';
import { SystemService } from 'projects/qcloud-rest-client/src/lib/system.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  currentYear: string;
  systemVersion: SystemVersion;

  constructor(private systemService: SystemService) { }

  ngOnInit(): void {
    this.systemService.getSystemVersion().subscribe(
      res => this.systemVersion = res
    );

    this.currentYear = new Date().getFullYear().toString();
  }

}
