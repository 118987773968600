import { Component, OnInit } from '@angular/core';
import { Employee } from 'projects/qcloud-models/grafik/employee/employee.model';
import { EmployeeWorkingTime } from 'projects/qcloud-models/grafik/employee/employee-working-time';
import { EmployeeService } from 'projects/qcloud-rest-client/src/lib/grafik/employee.service';
import { SpecialisationService } from 'projects/qcloud-rest-client/src/lib/grafik/specialisation.service';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivityTime } from 'projects/qcloud-models/activity-time';
import { TimeConversionService } from 'projects/qcloud-web/src/app/shared/time-conversion.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { Specialisation } from 'projects/qcloud-models/grafik/specialisation/specialisation.model';
import { Office } from 'projects/qcloud-models/grafik/office/office.model';
import { OfficeService } from 'projects/qcloud-rest-client/src/lib/grafik/office.service';
import { EmployeeHolidayTime } from 'projects/qcloud-models/grafik/employee/employee-holiday-time.model';
import { RouteNavigationService } from 'projects/qcloud-web/src/app/shared/route-navigation.service';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {

  employee: Employee;
  isEditForm: boolean;
  formSubmitted: boolean = false;
  nightWork: Array<boolean> = new Array(7);
  blt: Array<ActivityTime> = new Array(7);
  holidayTimes: Array<EmployeeHolidayTime>;

  specialities: Specialisation[];
  offices: Office[];
  officesObject: Object;

  public specialityMultiCtrl: UntypedFormControl;
  public officeMultiCtrl: Array<UntypedFormControl>;
  _choosenSpeciality: Specialisation;
  set choosenSpeciality(value: Specialisation) {
    this._choosenSpeciality = value;
  }
  get choosenSpeciality() {
    return this._choosenSpeciality;
  }

  noSelectedSpecialities: string = 'no-selected-specialities';
  noSelectedOffices: string = 'no-selected-offices';

  constructor(private authService: AuthService, private translate: TranslateService,
    private timeConverter: TimeConversionService, private toastr: ToastrService,
    private employeeService: EmployeeService, public router: RouteNavigationService,
    private specialitySerivce: SpecialisationService, private route: ActivatedRoute,
    private officeService: OfficeService) { }

  ngOnInit(): void {
    var employeeId: number;
    this.route.params.subscribe(params => {
      employeeId = params['id']
    });
    if (employeeId) {
      this.isEditForm = true;
      this.getEmployee(employeeId);
    } else {
      this.isEditForm = false;
      this.initializeEmployee();
    }
  }

  initializeEmployee() {
    this.employee = new Employee();
    this.employee.locationId = +this.authService.getLocationId();
    this.employee.systemId = +this.authService.getSystemId();
    this.blt = new Array<ActivityTime>(7);
    this.holidayTimes = new Array<EmployeeHolidayTime>();
    //it would be really nice to do this by mapping
    for (let i = 0; i < this.blt.length; i++) {
      this.blt[i] = new ActivityTime();
      this.blt[i].unblockingTime = "00:00";
      this.blt[i].blockingTime = "23:59";
      this.blt[i].is24Hours = false;
      this.blt[i].isButtonAlwaysVisible = false;
      this.blt[i].isActive = true;
      this.blt[i].day = i;
      this.nightWork[i] = false;
    }
    this.specialitySerivce.getSpecialities((data) => {
      this.specialities = data;
      this.choosenSpeciality = this.specialities[0];
      this.specialityMultiCtrl = new UntypedFormControl(new Array<Specialisation>());
    }, () => { });

    this.officeService.getOffices((data) => {
      this.offices = data;
      this.sortOffices();
      let initialOffices = new Array<Office>();
      this.officeMultiCtrl = new Array<UntypedFormControl>(7);
      for (let i = 0; i < this.officeMultiCtrl.length; i++) {
        this.officeMultiCtrl[i] = new UntypedFormControl(initialOffices);
      }
      this.officesObject = {
        offices: this.offices,
        initialOffices: new Array<Office>(),
        officeMultiCtrl: this.officeMultiCtrl
      }
    }, () => { });
  }

  sortOffices() {
    this.offices.sort((a, b) => {
      return a.name.localeCompare(b.name, 'pl-PL', { numeric: true, sensitivity: 'base' });
    })
  }

  onEnterPress(event, isFormInvalid) {
    event.preventDefault();
    if (!isFormInvalid) {
      this.onSubmit();
    }
  }

  onSubmit() {
    this.formSubmitted = true;
    for (let i = 0; i < this.blt.length; i++) {
      if (this.nightWork[i] == true) {
        var tmp = this.blt[i].unblockingTime;
        this.blt[i].unblockingTime
          = this.blt[i].blockingTime;
        this.blt[i].blockingTime = tmp;
      }
    }
    if (this.isEditForm) {
      this.editEmployee();
    } else {
      this.addEmployee();
    }
  }

  getEmployee(employeeId: number) {
    this.employeeService.getEmployee(employeeId).subscribe(employee => {
      this.employee = employee;
      this.holidayTimes = this.employee.holidayTimes;
      for (let i = 0; i < this.employee.workTimes.length; i++) {

        this.employee.workTimes[i].timeEnd =
          this.timeConverter.dateToTime(this.employee.workTimes[i].timeEnd);
        this.employee.workTimes[i].timeStart =
          this.timeConverter.dateToTime(this.employee.workTimes[i].timeStart);

        if (this.timeConverter.timeToMinutes(this.employee.workTimes[i].timeEnd)
          < this.timeConverter.timeToMinutes(this.employee.workTimes[i].timeStart)) {

          var tmp = this.employee.workTimes[i].timeEnd
          this.employee.workTimes[i].timeEnd = this.employee.workTimes[i].timeStart;
          this.employee.workTimes[i].timeStart = tmp;
          this.nightWork[i] = true;
        }
      }
      this.blt = new Array<ActivityTime>(7);
      for (let i = 0; i < this.blt.length; i++) {
        this.blt[i] = new ActivityTime();
        this.blt[i].unblockingTime = this.employee.workTimes[i].timeStart;
        this.blt[i].blockingTime = this.employee.workTimes[i].timeEnd;
        this.blt[i].is24Hours = this.employee.workTimes[i].is24Hours;
        this.blt[i].isActive = this.employee.workTimes[i].isActive;
        this.blt[i].isButtonAlwaysVisible = this.employee.workTimes[i].isButtonAlwaysVisible;
        this.blt[i].day = this.employee.workTimes[i].day;
      }
      this.specialitySerivce.getSpecialities((data) => {
        this.specialities = data;
        if (employee.specialisation) {
          this.choosenSpeciality = this.specialities.find(s => s.id == employee.specialisation.id);
          this.specialityMultiCtrl = new UntypedFormControl(this.choosenSpeciality);
        }
        else {
          this.choosenSpeciality = this.specialities[0];
          this.specialityMultiCtrl = new UntypedFormControl(new Array<Specialisation>());
        }
      }, () => { });

      this.officeService.getOffices((data) => {
        this.offices = data;
        this.sortOffices();
        this.officeMultiCtrl = new Array<UntypedFormControl>(7);
        for (let i = 0; i < this.officeMultiCtrl.length; i++) {
          let office = this.employee.workTimes[i].office;
          if (office && office.id > 0) {
            let employeeOffice = this.offices.find(office => office.id == employee.workTimes[i].office.id)
            this.officeMultiCtrl[i] = new UntypedFormControl(employeeOffice);
          }
          else {
            this.officeMultiCtrl[i] = new UntypedFormControl();
          }
        }
        this.officesObject = {
          offices: this.offices,
          initialOffices: new Array<Office>(),
          officeMultiCtrl: this.officeMultiCtrl,
          noSelectedOffices: this.noSelectedOffices
        };
      }, () => { });
    });
  }

  addEmployee() {
    this.blt = this.changeTimeModel(this.blt);
    this.employee.workTimes = this.blt.map(singleWorkTime => {
      return new EmployeeWorkingTime(singleWorkTime);
    })
    this.employee.id = 0;
    this.employee.order = 0;
    this.employee.workTimes.forEach(element => {
      element.id = 0;
    });
    this.employee.holidayTimes = this.changeHolidayTimeModel(this.holidayTimes);
    if (this.specialityMultiCtrl.value && this.specialityMultiCtrl.value.id) {
      this.employee.specialisation = this.specialityMultiCtrl.value
    }
    this.officeMultiCtrl.forEach((element, index) => {
      if (element.value && element.value.id) {
        this.employee.workTimes[index].office = element.value;
      }
      else {
        this.employee.workTimes[index].office = null;
      }
    });
    this.employeeService.addEmployee(this.employee).subscribe(
      res => {
        this.router.navigate(['workSchedule']);
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('employee-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  editEmployee() {
    this.blt = this.changeTimeModel(this.blt);
    this.employee.holidayTimes = this.changeHolidayTimeModel(this.holidayTimes);
    this.employee.holidayTimes.forEach(element => {
      element.id = 0;
    });
    this.employee.workTimes = this.blt.map(singleWorkTime => {
      return new EmployeeWorkingTime(singleWorkTime);
    });
    if (this.specialityMultiCtrl.value && this.specialityMultiCtrl.value.id) {
      this.employee.specialisation = this.specialityMultiCtrl.value;
    }
    else {
      this.employee.specialisation = null;
    }
    this.officeMultiCtrl.forEach((element, index) => {
      if (element.value && element.value.id) {
        this.employee.workTimes[index].office = element.value;
      }
      else {
        this.employee.workTimes[index].office = null;
      }
    })
    this.employeeService.editEmployee(this.employee).subscribe(
      res => {
        this.router.navigate(['workSchedule']);
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('employee-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  changeTimeModel(time: Array<ActivityTime>) {
    time.forEach(element => {
      element.blockingTime = this.timeConverter.timeToDate(element.blockingTime);
      element.unblockingTime = this.timeConverter.timeToDate(element.unblockingTime);
    });
    return time;
  }

  changeHolidayTimeModel(holidayTime: Array<EmployeeHolidayTime>) {
    holidayTime.forEach(ht => {
      let timeZoneOffset = ht.startDate.getTimezoneOffset();
      ht.startDate.setMinutes(ht.startDate.getMinutes() - timeZoneOffset);
      ht.endDate.setMinutes(ht.endDate.getMinutes() - timeZoneOffset);
    })
    return holidayTime;
  }

}
