<div #pagetop class="card shadow mb-4">
    <div class="card-header py-3">
        <div class="row">
            <h5 *ngIf="!isEditForm" class="col-9 m-0 font-weight-bold text-primary">
                {{ 'add-office' | translate }}
            </h5>
            <h5 *ngIf="isEditForm" class="col-9 m-0 font-weight-bold text-primary">
                {{ 'edit-office' | translate }}
            </h5>
        </div>
    </div>
    <div *ngIf="office" class="card-body">
        <form #form3="ngForm" autocomplete="off" (keydown.enter)="onEnterPress($event, form3.invalid)">
            
            <div class="form-group">
                <div class="input-group">
                    *<div class="font-weight-bold">{{ 'office-name' | translate }}</div>
                </div>
                <div class="input-group">
                    <input name="name" #name="ngModel" [(ngModel)]="office.name"
                        class="form-control" required>
                </div>
                <div *ngIf="name.invalid && (name.dirty || name.touched)" class="text-danger mx-2 my-1">
                    <div *ngIf="name.errors.required">{{ 'office-name-required' | translate }}</div>
                </div>
            </div>

            <div class="form-group">
                <div class="input-group">
                    <div class="font-weight-bold">{{ 'floor' | translate }}</div>
                </div>
                <div class="input-group">
                    <input name="floor" #floor="ngModel" [(ngModel)]="office.floor"
                        class="form-control">
                </div>
            </div>

            <div class="form-group row">
                <button class="btn btn-danger m-1" (click)="router.navigate(['workSchedule'])">{{ 'back' | translate }}</button>
                <button class="btn btn-primary m-1" type="submit" [disabled]="formSubmitted || form3.invalid"
                    (click)="onSubmit()">
                <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                    aria-hidden="true"></span>{{ 'save-office' | translate }}
                </button>
            </div>
        </form>
    </div>
</div>
