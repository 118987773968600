<div *ngIf="offices && officeDeskRelations" class="table-responsive">
    <table class="table table-bordered">
        <thead>
            <tr>
                <th>#</th>
                <th>{{ 'office' | translate }}</th>
                <th>{{ 'desk' | translate }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let officeDesk of officeDeskRelations; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ getOfficeName(officeDesk.officeId) }}</td>
                <td>{{ getDeskName(officeDesk.deskId) }}</td>
            </tr>
        </tbody>
    </table>
</div>