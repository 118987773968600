import { Component, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Category } from "../../../../../../qcloud-models/category/category.model";
import { AuthService } from "../../../../../../qcloud-rest-client/src/lib/auth/auth.service";
import { QcloudRoleService } from "../../../../../../qcloud-rest-client/src/lib/role/qcloud-role.service";
import { NormalizeCharactersPipe } from "../../../shared/normalize-characters.pipe";
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { LocationData } from "projects/qcloud-models/location/location.model";
import { SystemCategoryService } from "../../../../../../qcloud-rest-client/src/lib/category/system-category.service";
import { QcloudRole } from "../../../../../../qcloud-models/qcloud-role/qcloud-role";

@Component({
  selector: 'app-system-qcloud-role',
  templateUrl: './system-qcloud-role.component.html',
  styleUrls: ['./system-qcloud-role.component.css'],
  providers: [NormalizeCharactersPipe]
})
export class SystemQcloudRoleComponent implements OnInit {
  isEditForm: boolean;
  formSubmitted: boolean = false;
  serveETickets: boolean;
  systemQcloudRole: QcloudRole;
  protected initialCategories: Category[];
  public categoryMultiCtrl: UntypedFormControl;
  categories: Category[];
  servedCategories: string = 'served-system-categories';
  noSelectedCategories = 'no-selected-categories';
  locations: LocationData[];
  protected initialLoco: LocationData[];
  public locationMultiCtrl: UntypedFormControl;
  userLocations: string = 'user-locations'
  isLockedForEditing: boolean = false;
  enabledByLocationIds: number[] = [];
  mainRoleLocationIds: number[] = [];

  constructor(public authService: AuthService, private translate: TranslateService, private toastr: ToastrService,
    private route: ActivatedRoute, private router: Router, public locationService: LocationService,
    public qcloudRoleService: QcloudRoleService,
    private systemCategoryService: SystemCategoryService) { }

  ngOnInit(): void {
    let qcloudRoleId: number;
    this.serveETickets = this.authService.getServeETicket() == "true";
    this.route.params.subscribe(params => {
      qcloudRoleId = params['id']
      if (qcloudRoleId) {
        this.isEditForm = true;
        this.getSystemQcloudRole(qcloudRoleId);
      } else {
        this.isEditForm = false;
        this.initializeQcloudRole();
      }
    });
  }

  initializeQcloudRole() {
    this.systemQcloudRole = new QcloudRole();
    this.systemQcloudRole.locationIds = [];
    this.systemQcloudRole.systemId = +this.authService.getSystemId();
    this.systemQcloudRole.categories = [];

    this.getCategories();
    this.getLocations();
  }

  getCategories() {
    this.systemCategoryService.getCategories((data) => {
      this.categories = data.map(sysCategory => Category.mapFromSystemCategoryIntoCategory(sysCategory));
      this.initialCategories = new Array<Category>();
      var systemCategorieIds = Array.from(new Set(this.systemQcloudRole.categories.map( sc => sc.systemCategoryId ?? -1)));
      for (let j = 0; j < this.systemQcloudRole.categories.length; j++) {
        for (let i = 0; i < this.categories.length; i++) {
          if (this.categories[i].id == systemCategorieIds[j]) {
            this.initialCategories.push(this.categories[i]);
          }
        }
      }
      this.categoryMultiCtrl = new UntypedFormControl(this.initialCategories);
    },
      () => { }
    );
  }

  getSystemQcloudRole(systemQcloudRoleId: number) {
    this.qcloudRoleService.getQcloudRole(systemQcloudRoleId).subscribe(
      role => {
        this.systemQcloudRole = role;
        this.systemQcloudRole.rolePermissionLevel = Number(this.systemQcloudRole.rolePermissionLevel);
        this.getCategories();
        this.getLocations();
      }
    );
  }

  getLocations() {
    this.locationService.getAll().subscribe(
      locations => {
        this.locations = locations;
        this.mainRoleLocationIds = this.authService.getTokenLocationIds();
        for (var i = 0; i < this.mainRoleLocationIds.length; i++) {
          var newLocationId = this.mainRoleLocationIds[i];
          var isExistInEnabledByLocationIds = this.enabledByLocationIds.some(enabledByLocationId => enabledByLocationId == newLocationId);
          if (!isExistInEnabledByLocationIds) {
            this.enabledByLocationIds.push(newLocationId);
          }
        }
        this.initialLoco = locations;
        if (this.isEditForm) {
          this.assignLocation();
        } else {
          this.assignDefaultLocation();
        }
      }
    );
  }

  assignDefaultLocation() {
    if (this.locations.length > 1) {
      this.mainRoleLocationIds = this.authService.getTokenLocationIds();
      this.initialLoco = new Array<LocationData>();
      for (let j = 0; j < this.systemQcloudRole.locationIds.length; j++) {
        for (let i = 0; i < this.locations.length; i++) {
          if (this.mainRoleLocationIds[j] == this.locations[i].id) {
            this.initialLoco.push(this.locations[i]);
          }
        }
      }
      this.locationMultiCtrl = new UntypedFormControl(this.initialLoco);
    }
    else {
      this.systemQcloudRole.locationIds.push(this.locations[0].id);
    }
  }
  assignLocation() {
    if (this.locations.length > 1) {
      this.initialLoco = new Array<LocationData>();
      for (let i = 0; i < this.locations.length; i++) {
        if (this.systemQcloudRole.locationIds.some(x => x === this.locations[i].id)) {
          this.initialLoco.push(this.locations[i]);
        }
      }
      this.locationMultiCtrl = new UntypedFormControl(this.initialLoco);
    }
    else {
      this.systemQcloudRole.locationIds.push(this.locations[0].id);
    }
  }

  onSubmit(categories: Category[]) {
    this.systemQcloudRole.categories = categories;

    if (this.locationMultiCtrl) {
      var parse = this.locationMultiCtrl.value as LocationData[];
      var roleLIds: number[] = [];
      parse.forEach(element => {
        roleLIds.push(element.id);
      });
      this.systemQcloudRole.locationIds = roleLIds;
    }
    if (this.isEditForm) {
      this.editSystemQcloudRole();
    } else {
      this.addQcloudRole();
    }
  }
  addQcloudRole() {
    this.qcloudRoleService.addSystemQcloudRole(this.systemQcloudRole).subscribe({
      next: () => {
        this.formSubmitted = true;
        this.router.navigate(['/systemQcloudRoles']);
      },
      error: () => {
        var errorMessage = "qcloud.role-add-error"
        this.formSubmitted = false;
        this.translate.get(errorMessage).subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    }
    );

  }

  editSystemQcloudRole() {
    this.qcloudRoleService.updateSystemQcloudRole(this.systemQcloudRole).subscribe({
      next: () => {
        this.formSubmitted = true;
        this.router.navigate(['/systemQcloudRoles']);
      },
      error: () => {
        var errorMessage = "role-update-error"
        this.formSubmitted = false;
        this.translate.get(errorMessage).subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    });
  }

  newBasedOnExisting() {
    this.isEditForm = false;
  }
  handleModalResult(event) {
    if (event === true) {
      this.onSubmit(this.categoryMultiCtrl?.value);
      this.formSubmitted = true;
    }
  }

  toastrShowInfo(msg: string) {
    this.toastr.info(msg);
  }
}