import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { QcloudRoleService } from "../../../../../qcloud-rest-client/src/lib/role/qcloud-role.service";
import { QcloudRole } from "../../../../../qcloud-models/qcloud-role/qcloud-role";
import { LocationDataDto } from "../../../../../qcloud-models/location/dto/location-dto.model";
import { RolePermissionLevel } from "../../../../../qcloud-models/qcloud-role/role-permission-level";
import { DetailsStructureTemplate } from "../../../../../qcloud-models/console/details-popup-modal/details-structure-template";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { DetailsModalComponent } from "../../shared/details-modal/details-modal.component";
import { finalize } from "rxjs";
import { DetailsPopupModalData } from "../../../../../qcloud-models/console/details-popup-modal/details-popup-modal-data";
import { DetailsStructureTemplateGroup } from "../../../../../qcloud-models/console/details-popup-modal/details-structure-template-group";

@Component({
  selector: 'app-system-qcloud-roles',
  templateUrl: './system-qcloud-roles.component.html',
  styleUrls: ['./system-qcloud-roles.component.css']
})
export class SystemQcloudRolesComponent implements OnInit {

  systemRoles: QcloudRole[];
  selectedUserId: string;
  selectedLdapId: number;
  showLimitLocationIds: number = 15;
  rolePermissionLevelEnum = RolePermissionLevel;

  constructor(private translate: TranslateService, public qcloudRoleService: QcloudRoleService,
    private toastr: ToastrService, private modalService: MatDialog) { }

  ngOnInit(): void {
    this.getQcloudRoles();
  }

  getQcloudRoles() {
    this.qcloudRoleService.getSystemQcloudRoles()
      .subscribe(data => {
        this.systemRoles = data;
      });
  }

  onDeleteConfirm(confirmed: boolean) {
    if (confirmed) {
      this.deleteQcloudRole(this.selectedLdapId);
    }
  }

  deleteQcloudRole(systemRoleId: number) {
    this.qcloudRoleService.deleteQcloudRole(systemRoleId).subscribe({
      next: res => {
        this.getQcloudRoles();
      },
      error: err => {
        this.translate.get('delete-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    });
  }

  getDetailsStructureTemplateLocationInfo(role: QcloudRole): DetailsStructureTemplate {
    var detailsStructureTemplate = new DetailsStructureTemplate();
    this.translate.get('location-name').subscribe(
      res => detailsStructureTemplate.columnNames.push(res)
    );
    this.translate.get('location-tag').subscribe(
      res => detailsStructureTemplate.columnNames.push(res)
    );

    role.locations.forEach(loc => {
      var row: string[] = [];
      row.push(loc.locationName);
      row.push(loc.locationTag);
      detailsStructureTemplate.rows.push(row);
    });

    return detailsStructureTemplate;
  }


  dialogRef: any;
  public openLocationDetails(role: QcloudRole) {
    var matDialogConfig = new MatDialogConfig();
    matDialogConfig.maxHeight = 900;
    matDialogConfig.maxWidth = 1800;
    matDialogConfig.minHeight = 100;
    matDialogConfig.minWidth = 800;
    var template = this.getDetailsStructureTemplateLocationInfo(role);
    var detailsPopupModalData = new DetailsPopupModalData();

    this.translate.get('qcloud.assigned-location').subscribe(
      res => detailsPopupModalData.title = res
    );

    detailsPopupModalData.detailsStructureTemplateGroups = [];
    var detailsStructureTemplateGroup = new DetailsStructureTemplateGroup();
    detailsStructureTemplateGroup.detailsStructureTemplate = template;
    detailsPopupModalData.detailsStructureTemplateGroups.push(detailsStructureTemplateGroup);
    matDialogConfig.data = detailsPopupModalData;
    this.dialogRef = this.modalService.open(DetailsModalComponent, matDialogConfig);
    this.dialogRef.afterClosed().pipe(
      finalize(() => {
        this.dialogRef = undefined;
      })
    );
  }
}