<div class="login-container mt-4">
    <div class="justify-content-center">
        <div class="card o-hidden border-0 shadow-lg">
            <div class="card-body p-0">
                <div class="p-5">
                    <div class="text-center">
                        <h5 class="modal-title">{{ 'select-location' | translate }}</h5>
                    </div>
                    <div class="form-group">
                        <select [(ngModel)]="choosenLocation" class="form-control">
                            <option *ngFor="let location of locations" [ngValue]="location"
                                [disabled]="!isLocationEnable(location.id)"
                                [ngClass]="{'disabled-option': false == isLocationEnable(location.id)}">
                                {{ location.locationName }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-danger" data-dismiss="modal" routerLink="/qcloud/welcome">{{ 'back' |
                        translate }}</button>
                    <button class="btn btn-primary" data-dismiss="modal" (click)="changeLocation()">
                        {{ 'choose-location' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>