import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenResponse } from '../../../qcloud-models/user/token-response';
import { Login } from 'projects/qcloud-models/user/login.model';
import { AuthService } from './auth/auth.service';


@Injectable()
export class QCloudRestClientService {

  private appBase: string;
  loginForm: Login;

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  public setAppBase(appBase: string) {
    this.appBase = appBase;
  }

  public getAuthTokenForDevice(guid: string, callback: (data: TokenResponse) => any, onError: () => any) {
    var tokenUrl = `token`;
    let context = this;

    var loginModel = {
      deviceId: guid
    };

    var callbackWrapper = function (data: TokenResponse) {
      context.authService.setToken(data.access_token);
      callback(data);
    };

    this.http.post(this.appBase + tokenUrl,
      loginModel
    )
      .subscribe(
        callbackWrapper,
        onError
      );
  }

  getAuthTokenForUser(callback: (data: TokenResponse) => any, onError: () => any) {
    let context = this;

    if (this.loginForm != undefined) {
      var username = this.loginForm.username;
      var password = this.loginForm.password;
    }
    var loginModel = {
      username: username,
      password: password
    };

    var tokenUrl = `token`;
    var callbackWrapper = function (data: TokenResponse) {
      context.authService.setToken(data.access_token);
      context.authService.setRefreshToken(data.refresh_token);
      context.authService.setServeETicket(data.canServeETickets);
      callback(data);
    };

    this.http.post(this.appBase + tokenUrl,
      loginModel
    )
    .subscribe(
      callbackWrapper,
      onError
    );
  }
  changeLocation(choosenLocationId: number, callback: (data: TokenResponse) => any, onError: () => any) {
    let context = this;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      })
    };
    if (this.loginForm != undefined) {
      var username = this.loginForm.username;
      // var password = this.loginForm.password;
    }
    var tokenUrl = `changeLocation`;
    var callbackWrapper = function (data: TokenResponse) {
      context.authService.setToken(data.access_token);
      context.authService.setRefreshToken(data.refresh_token);
      context.authService.setServeETicket(data.canServeETickets);
      callback(data);
    };
    this.http.post(this.appBase + tokenUrl,
      `username=${username}&locationId=${choosenLocationId}`,
      httpOptions
    )
      .subscribe(
        callbackWrapper,
        onError
      );
  }

  // update accessToken by refreshToken
  getRefreshAccessTokenForUser(callback: (data: TokenResponse) => any, onError: () => any) {
    let context = this;
    var userName = this.authService.getTokenUsername();
    var authorizationType =  userName.includes("\\") ? "LDAP" : "QCLOUD";

    var refreshToken = this.authService.getRefreshToken() ?? '';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    var tokenUrl = `refresh-accesss-token`;
    var callbackWrapper = function (data: TokenResponse) {
      context.authService.setToken(data.access_token);
      context.authService.setRefreshToken(data.refresh_token);
      context.authService.setServeETicket(data.canServeETickets);
      context.authService.setIsRefreshTokenStarted(false);
      callback(data);
    };
    var onErrorWrapper = function () {
      context.authService.setIsRefreshTokenStarted(false);
      context.authService.onLogout();
      onError();
    };

    context.authService.setIsRefreshTokenStarted(true);
    this.http.post(this.appBase + tokenUrl,
      JSON.stringify(authorizationType+ ":" + refreshToken),
      httpOptions
    )
      .subscribe(
        callbackWrapper,
        onErrorWrapper
      );
  }
}
