import { Directive, ElementRef, HostListener, AfterViewInit, OnChanges, SimpleChanges, Input } from '@angular/core';
import { fonts } from './fit-text.dictionary';
@Directive({
  selector: '[appFittext]'
})
export class FittextDirective implements AfterViewInit, OnChanges {
  @Input('appFittext') content: string;
  constructor(private el: ElementRef) {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.content) {
      this.el.nativeElement.innerHTML = this.content;
      this.onResize();
    }
  }
  ngAfterViewInit() {
    this.onResize();
  }
  @HostListener('window:load')
  @HostListener('window:resize')
  onResize() {

    this.clearFont(this.el);
    const parentX = this.getNumberFromPixels(window.getComputedStyle(this.el.nativeElement.parentElement).width);
    const parentY = this.getNumberFromPixels(window.getComputedStyle(this.el.nativeElement.parentElement).height) - 8;
    let textX: number;
    let textY: number;
    let fontLevel = 0;
    do {
      this.setFont(this.el, fontLevel++);

      textX = this.getNumberFromPixels(window.getComputedStyle(this.el.nativeElement).width);
      textY = this.getNumberFromPixels(window.getComputedStyle(this.el.nativeElement).height);
    }
    while ((textX >= parentX || textY >= parentY) && fontLevel < fonts.length)
  }
  private getNumberFromPixels(pixels: string): number {
    return Number(pixels.substring(0, pixels.length - 2));
  }
  setFont(el: ElementRef, fontLevel: number) {
    el.nativeElement.style.fontSize = fonts[fontLevel].fontSize;
    el.nativeElement.style.lineHeight = fonts[fontLevel].lineHeight;
  }

  clearFont(el: ElementRef) {
    el.nativeElement.removeAttribute('font-size');
    el.nativeElement.removeAttribute('line-height');
  }
}