import { Component, Input, OnInit } from '@angular/core';
import { Category } from 'projects/qcloud-models/category/category.model';
import { Dictionary } from 'projects/qcloud-models/dictionary';

@Component({
  selector: 'app-service-time-category-statistics',
  templateUrl: './service-time-category-statistics.component.html',
  styleUrls: ['./service-time-category-statistics.component.css']
})
export class ServiceTimeCategoryStatisticsComponent implements OnInit {

  @Input() categories: Category[];
  @Input() averageCategoryServiceTime: Dictionary<number>;
  @Input() averageServiceTime: number;
  maxServiceTime: number;

  constructor() { }

  ngOnInit(): void {
    this.maxServiceTime = +Object.entries(this.averageCategoryServiceTime).reduce((a, b) => a[1] > b[1] ? a : b)[1];
  }

}
