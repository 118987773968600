import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { DeviceConfigurationService } from 'projects/qcloud-device/src/app/services/device-configuration.service';
import { TicketListenerService } from 'projects/qcloud-device/src/app/services/ticket.service';
import { Ticket } from 'projects/qcloud-models/console/ticket';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { TicketDisplayRow } from 'projects/qcloud-models/device/ticket-display-row';
import { BaseComponent } from '../../../../shared/base-component/base.component';
import { TicketState } from 'projects/qcloud-models/console/ticketState.enum';

declare var window: any;

@Component({
  selector: 'app-desk-basic',
  templateUrl: './desk-basic.component.html',
  styleUrls: ['./desk-basic.component.css']
})
export class DeskBasicComponent extends BaseComponent implements OnInit {
  rowHeight: number = 0;
  rowPadding: number = 0;
  rows: TicketDisplayRow[] = [];
  animation: any;

  //TODO: add translations
  service = "Usługa";
  ticketNumber = "Numer Biletu"

  constructor(protected elementRef: ElementRef, protected renderer: Renderer2) {
    super(elementRef, renderer);
  }

  startComponent(): void {
    var deviceConfiguration = this.configurationService.getConfiguration();
    let desks: Desk[] = deviceConfiguration.deviceDesks;
    let rows = [];
    desks.forEach(element => {
      let row = new TicketDisplayRow();
      row.texts[0] = element.fullName;
      row.texts[1] = "----"
      row.deskId = element.id;
      row.active = false;
      rows.push(row);
    });
    this.rows = rows;

    this.ticketListenerService.ticket.subscribe((val) => {
      this.ticketCalled(val);
    });
    this.setGridRows(rows.length);
  }

  ngOnInit() {
  }

  private setGridRows(number: number) {
    var orientation = window.orientation % 90 === 0 ? 'portrait' : 'landscape'
    if (orientation == 'landscape') {
      if (number < 4) number = 4;
      this.rowPadding = 0.05;
    }
    else {
      if (number < 6) number = 6;
      this.rowPadding = 0.1;
    }
    this.rowHeight = 100 / number;
    this.rowPadding *= this.rowHeight;
  }

  getRowHeightStyle() {
    return { height: this.rowHeight + '%', "padding-bottom": this.rowPadding + '%' }
  }

  private ticketCalled(ticket: Ticket) {
    if (ticket.ticketState != TicketState.Called)
      return;
    let row = this.rows.filter(x => x.deskId == ticket.deskId)[0];
    if (row == undefined)
      return;
    row.texts[1] = ticket.caption;
    this.clearAnimation();
    this.animate(row);
  }

  private clearAnimation() {
    if (this.animation != undefined) {
      window.clearInterval(this.animation);
    }
    this.rows.forEach(element => {
      element.active = false;
    });
  }

  private animate(row: TicketDisplayRow) {
    let x = 0;
    let context = this;
    this.animation = window.setInterval(function () {
      row.active = !row.active;
      if (++x === 6 && context.animation != undefined) {
        window.clearInterval(context.animation);
      }
    }, 1000);
  }

  initMock(): void {
    let rows = [];
    for (let i = 0; i < 2; i++) {
      let row = new TicketDisplayRow();
      row.texts[0] = "Example " + (i + 1);
      row.texts[1] = "----"
      row.deskId = 1;
      row.active = false;
      rows.push(row);
    }
    this.rows = rows;
    this.setGridRows(rows.length);
  }

}
