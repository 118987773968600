import { Injectable } from '@angular/core';
import * as Electron from 'electron';

declare let window: any;

@Injectable({
    providedIn: 'root'
})
export class ElectronService {

    //TODO: add type to _electron and get electron()
    //check why cant use Electron.RendererInterface here
    private _electron: any;

    private get electron(): any {
        if (!this._electron) {
            if (window && window.require) {
                this._electron = window.require('electron');
                return this._electron;
            }
            return null;
        }
        return this._electron;
  }

    public get ipcRenderer(): Electron.IpcRenderer {
        return this.electron ? this.electron.ipcRenderer : null;
    }

    constructor() { }
}
