import { Component, ElementRef, EventEmitter, Output, Renderer2 } from '@angular/core';
import { TicketListenerService } from 'projects/qcloud-device/src/app/services/ticket.service';
import { UtilitiesService } from 'projects/qcloud-device/src/app/services/utilities.service';
import { Ticket } from 'projects/qcloud-models/console/ticket';
import { BaseComponent } from '../../../../shared/base-component/base.component';
import { TicketState } from 'projects/qcloud-models/console/ticketState.enum';

@Component({
  selector: 'app-single-ticket-display',
  templateUrl: './single-ticket-display.component.html',
  styleUrls: ['./single-ticket-display.component.css']
})
export class SingleTicketDisplayComponent extends BaseComponent {
  deskNumber: string = "--";
  ticketCaption: string = "----";
  animation: any;
  active: boolean = false;
  //TODO: add translations
  ticket = "Przywoływany bilet"
  desk = "Stanowisko";
  @Output() hideComponent = new EventEmitter<boolean>();

  constructor(protected elementRef: ElementRef, protected renderer: Renderer2) {
    super(elementRef, renderer);
  }

  startComponent(): void {
    this.ticketListenerService.ticket.subscribe((val) => {
      this.ticketCalled(val);
    })
  }

  ticketCalled(val: Ticket) {
    if (val.ticketState != TicketState.Called)
      return;
    this.sendEmitToShowComponent();
    this.clearAnimation();
    this.ticketCaption = val.caption;
    this.deskNumber = this.utilitiesService.getDeskNumber(val.deskId);
    this.animate();
  }

  private clearAnimation() {
    if (this.animation != undefined) {
      window.clearInterval(this.animation);
    }
  }

  private animate() {
    this.active = false;
    let x = 0;
    let context = this;
    this.animation = window.setInterval(function () {
      if (++x < 6 && context.animation != undefined) {
        context.active = !context.active;
      } else {
        context.active = false;
      }
      if (x === 15 && context.animation != undefined) {
        window.clearInterval(context.animation);
        context.sendEmitToHideComponent();
      }
    }, 1000);
  }
  private sendEmitToShowComponent() {
    this.hideComponent.emit(false);
  }
  private sendEmitToHideComponent() {
    this.hideComponent.emit(true);
  }

  initMock(): void {
    this.ticketCaption = "A100";
    this.deskNumber = "1";
  }
}
