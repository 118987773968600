import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Category } from 'projects/qcloud-models/category/category.model';
import { LocationActivityBlock } from 'projects/qcloud-models/location/location-activity-block.model';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { ReservationVacationService } from '../reservation-vacation.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-vacation-activity-blocks',
  templateUrl: './vacation-activity-blocks.component.html',
  styleUrls: ['./vacation-activity-blocks.component.css']
})
export class VacationActivityBlocksComponent implements OnInit {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  categoryId: number;
  locationActivityBlocks: LocationActivityBlock[];
  changedActivityBlock: LocationActivityBlock;
  addingNewActivityBlock: boolean;
  newActivityBlock: LocationActivityBlock;
  constructor(private locationService: LocationService,private authService: AuthService,
    private translate: TranslateService, private toastr: ToastrService,private reservationVacationService: ReservationVacationService,private modal: MatDialog) {
     }

  ngOnInit(): void {
    this.changedActivityBlock =
    new LocationActivityBlock(+this.authService.getLocationId(), +this.authService.getSystemId());
    this.listeners();
  }

  listeners(){
          this.reservationVacationService.choosenCategory.subscribe((data: Category)=>{
            if(data){
              this.categoryId=data.id;
            this.getLocationActivityBlocks(data.id);
            }
          });

          this.reservationVacationService.add.subscribe((data: boolean)=>{
            if(data==true){
              this.addingNewActivityBlock=true;
              this.editActivityBlock(new LocationActivityBlock(+this.authService.getLocationId(), +this.authService.getSystemId()));
              this.openModal();
              this.reservationVacationService.updateAdd(false);
            }
          });

          this.reservationVacationService.edit.subscribe((data: LocationActivityBlock)=>{
            if(data){
              this.getLocationActivityBlocks(this.categoryId);
              this.addingNewActivityBlock=false;
              this.newActivityBlock=data;
              this.openModal();
            }
          });

          this.reservationVacationService.delete.subscribe((data: LocationActivityBlock)=>{
            if(data){
              this.getLocationActivityBlocks(this.categoryId);
              this.deleteActivityBlock(data);
            }
          });
  }

  getLocationActivityBlocks(categoryId: number) {
    this.locationService.getVacationActivity().subscribe(
      res => {
        this.locationActivityBlocks = res.filter(x => x.blockedCategoryIds.find(y => y == categoryId));
        this.locationActivityBlocks.forEach(activityBlock => {
          activityBlock.dateStart = new Date(activityBlock.dateStart);
          activityBlock.dateEnd = new Date(activityBlock.dateEnd);
        });
      }
    );
  }

  editLocationActivityBlock(newActivityBlock: LocationActivityBlock, activityBlock: LocationActivityBlock) {
    if(newActivityBlock==null){
      this.locationService.deleteVacationActivity(activityBlock.id).subscribe(res=>{
        this.translate.get('location-activity-blocks-success').subscribe((res: string) => {
          this.toastr.success(res);
          this.getLocationActivityBlocks(this.categoryId);
          this.reservationVacationService.updateEdit(null);
        });
      },
      err => {
        this.translate.get('location-activity-blocks-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      })
      return;
    }
    if (activityBlock) {
      try{
      activityBlock.blockedCategoryIds = activityBlock.blockedCategoryIds.filter(x => x != this.categoryId);
      }catch{}
    }

      if (newActivityBlock) {
        if(this.addingNewActivityBlock==false){
          let i = this.locationActivityBlocks.findIndex(x=>x.id == this.newActivityBlock.id)
          this.locationActivityBlocks[i].dateStart = this.newActivityBlock.dateStart;
          this.locationActivityBlocks[i].dateEnd = this.newActivityBlock.dateEnd;
        }else{
        newActivityBlock.blockedCategoryIds = [this.categoryId];
        this.locationActivityBlocks.push(newActivityBlock);
        }
      }

    this.locationService.setVacationActivity(this.locationActivityBlocks).subscribe(
      res => {
        this.translate.get('location-activity-blocks-success').subscribe((res: string) => {
          this.toastr.success(res);
          this.getLocationActivityBlocks(this.categoryId);
          this.reservationVacationService.updateEdit(null);
        });
      },
      err => {
        this.translate.get('location-activity-blocks-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }
  editActivityBlock(activityBlock: LocationActivityBlock) {
    this.newActivityBlock =
      new LocationActivityBlock(+this.authService.getLocationId(), +this.authService.getSystemId());
    if (activityBlock) {
      this.changedActivityBlock = activityBlock;
    } else {
      this.changedActivityBlock = this.newActivityBlock;
    }
  }

  onSubmitActivityBlock(activityBlock: LocationActivityBlock) {
    if(this.addingNewActivityBlock==false){
      activityBlock=this.changedActivityBlock;
    }
    this.editLocationActivityBlock(this.newActivityBlock, activityBlock);
    this.editActivityBlock(this.newActivityBlock);
  }

  deleteActivityBlock(activityBlock: LocationActivityBlock) {
    this.editLocationActivityBlock(null, activityBlock);
    this.editActivityBlock(this.newActivityBlock);
  }

  getRange(range: Date[]) {
    this.newActivityBlock.dateStart = range[0];
    this.newActivityBlock.dateEnd = range[1];
  }

  openModal(){
    var matDialogConfig = new MatDialogConfig();
    matDialogConfig.maxHeight = 900;
    matDialogConfig.maxWidth = 800;
    this.modal.open(this.modalContent, matDialogConfig);
  }
}