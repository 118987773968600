import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Ticket } from 'projects/qcloud-models/console/ticket';
import { BaseComponent } from '../../../../shared/base-component/base.component';
import { DeviceConfigurationService } from '../../../../services/device-configuration.service';
import { DeviceConfiguration } from 'projects/qcloud-models/device/device-configuration';
import { Subscription } from 'rxjs';
import { QcloudStompClientService } from 'projects/qcloud-stomp-client/src/public-api';
import { TicketService } from '../../../../../../../qcloud-rest-client/src/lib/ticket.service';
import { TicketState } from '../../../../../../../qcloud-models/console/ticketState.enum';

@Component({
  selector: 'app-queue-tickets-list',
  templateUrl: './queue-tickets-list.component.html',
  styleUrls: ['./queue-tickets-list.component.css']
})
export class QueueTicketsListComponent extends BaseComponent implements OnInit {
  MAX_TICKETS = 8;
  private config: DeviceConfiguration;
  private stompTicketSubscription: Subscription;
  ticketsToShow: Ticket[] = [];
  rowHeight: number = 0;
  rowPadding: number = 0;

  constructor(protected elementRef: ElementRef, protected renderer: Renderer2) {
    super(elementRef, renderer);
  }

  startComponent(): void {
    console.log("queue ticket list component up")
    this.config = this.configurationService.getConfiguration();
    if (this.maxTicketsToShow) {
      this.MAX_TICKETS = this.maxTicketsToShow;
    }
    var categorieIds = [];
    this.ticketService.getCategoryAvailableTickets(categorieIds, (data) => {
      this.ticketsToShow = data;
    },
      () => { }
    );

    this.stompTicketSubscription = this.qcloudStompClientService.subscribe(`/exchange/tickets/${this.config.systemId}.${this.config.locationId}.ticket`,
      (ticket: Ticket) => {
        if (ticket.ticketState == TicketState.Accepted || ticket.ticketState == TicketState.Called || ticket.ticketState == TicketState.Cancelled) {

          var ticketIndex = this.ticketsToShow.findIndex(t => t.id === ticket.id);
          if (ticketIndex != -1) {
            this.ticketsToShow.splice(ticketIndex, 1);
          }
        } else {
          this.ticketsToShow.push(ticket);
        }
      });
    this.setGridRows(this.MAX_TICKETS);
  }

  ngOnInit() {

  }

  private setGridRows(number: number) {
    var orientation = window.orientation % 90 === 0 ? 'portrait' : 'landscape'
    if (orientation == 'landscape') {
      this.rowPadding = 0.05;
    }
    else {
      this.rowPadding = 0.1;
    }
    this.rowHeight = 100 / (number / this.quantityOfColumns);
  }

  initMock(): void {
    this.setGridRows(this.MAX_TICKETS);
    for (let i = 0; i < this.MAX_TICKETS; i++) {
      let t = new Ticket()
      t.ticketNumber = 1 + i;
      t.caption = "A00" + (i + 1);
      this.ticketsToShow.push(t);
    }
  }
}
