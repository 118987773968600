import { Component, Input, OnInit } from '@angular/core';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { Dictionary } from 'projects/qcloud-models/dictionary';

@Component({
  selector: 'app-service-time-desk-statistics',
  templateUrl: './service-time-desk-statistics.component.html',
  styleUrls: ['./service-time-desk-statistics.component.css']
})
export class ServiceTimeDeskStatisticsComponent implements OnInit {

  @Input() desks: Desk[];
  @Input() averageDeskServiceTime: Dictionary<number>;
  @Input() averageServiceTime: number;
  maxServiceTime: number;

  constructor() { }

  ngOnInit(): void {
    this.maxServiceTime = +Object.entries(this.averageDeskServiceTime).reduce((a, b) => a[1] > b[1] ? a : b)[1];
  }

}
