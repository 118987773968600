import { ActiveToast, IndividualConfig, ToastrService } from "ngx-toastr";
import { ScreenSizeType } from "./screen-size-configuration.service";
import { EnumType } from "typescript";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ToastrTimeoutService {

  private ScreenSizeTypeEnum = ScreenSizeType;
  private _timeOut: number;
  private _screenSizeType: ScreenSizeType = ScreenSizeType.BIG;
  // private screenSizeTypeEnum: EnumType;

  constructor(private toastr: ToastrService) {}

  /** show toast */
  show(message?: string, title?: string, override?: Partial<IndividualConfig>, type?: string): ActiveToast<any>{
    override = this.replaceIndividualConfigTimeOutIfNotExists(override);
    return this.toastr.show(message, title, override);
  }
  /** show successful toast */
  success(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any>{
    override = this.replaceIndividualConfigTimeOutIfNotExists(override);
    return this.toastr.success(message, title, override);
  }
  /** show error toast */
  error(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any>{
    override = this.replaceIndividualConfigTimeOutIfNotExists(override);
    return this.toastr.error(message, title, override);
  }
  /** show info toast */
  info(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any>{
    override = this.replaceIndividualConfigTimeOutIfNotExists(override);
    return this.toastr.info(message, title, override);
  }
  /** show warning toast */
  warning(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any>{
    override = this.replaceIndividualConfigTimeOutIfNotExists(override);
    return this.toastr.warning(message, title, override);
  }

  private updateTimoOutAccordingToScreenSizeType() {
    switch(this.screenSizeType) {
      case this.ScreenSizeTypeEnum.SLIM:
            this.timeOut = 1000;
        break;
      case this.ScreenSizeTypeEnum.SMALL:
            this.timeOut = 1000;
        break;
      case this.ScreenSizeTypeEnum.BIG:
            this.timeOut = 5000;
        break;
      default:
        this.timeOut = 5000;
        break;
    }
  }

  private set timeOut(timeOut : number){
    this._timeOut = timeOut;
  }

  private get timeOut() : number {
    return this._timeOut;
  }

  private replaceIndividualConfigTimeOutIfNotExists(override?: Partial<IndividualConfig>) : Partial<IndividualConfig> {
    if(override) {
      if(!override.timeOut) {
        override.timeOut = this.timeOut;
      }
    }
    else {
      override = { timeOut: this.timeOut };
    }
    return override;
  }

  set screenSizeType(type: ScreenSizeType) {
    this._screenSizeType = type;
    this.updateTimoOutAccordingToScreenSizeType();
  }
  get screenSizeType() : ScreenSizeType {
    return this._screenSizeType;
  }
}