<div class="form-group">
    <div class="input-group">
        <mat-form-field color="primary">
            <mat-select [formControl]="deviceMultiCtrl" [multiple]="true" #multiSelect>
                <mat-option>
                    <ngx-mat-select-search [showToggleAllCheckbox]="true" (toggleAll)="toggleSelectAll($event)"
                        [formControl]="deviceMultiFilterCtrl"
                        toggleAllCheckboxTooltipMessage="{{ 'select-unselect-all' | translate }}"
                        [toggleAllCheckboxTooltipPosition]="'above'"
                        placeholderLabel="{{ 'search-device' | translate }}"
                        noEntriesFoundLabel="{{ 'no-matching-device' | translate }}">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let device of filteredDevicesMulti | async" [value]="device">
                    {{ device.deviceName }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <p *ngIf="deviceMultiCtrl.value == null || deviceMultiCtrl?.value.length == 0">
        {{ 'no-selected-devices' | translate }}</p>
    <ul *ngFor="let device of deviceMultiCtrl?.value">
        <li>
            {{ device.deviceName }}
        </li>
    </ul>
</div>