import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { TenantConfig } from 'projects/qcloud-models/system/tenant-config.model';
import { SystemService } from 'projects/qcloud-rest-client/src/lib/system.service';

@Component({
  selector: 'app-default-settings',
  templateUrl: './default-settings.component.html',
  styleUrls: ['./default-settings.component.css']
})
export class DefaultSettingsComponent implements OnInit {

  tenant: TenantConfig;
  formSubmitted: boolean = false;

  constructor(private service: SystemService, private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.service.getTenant().subscribe(
      tenant => {
        this.tenant = tenant;
      }
    );
  }

  onSubmit() {
    this.service.updateTenant(this.tenant).subscribe(
      res => {
        this.formSubmitted = false;
        this.translate.get('default-settings-update-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('default-settings-update-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  getLogoPath(logoPath: string) {
    this.tenant.logoPath = logoPath;
  }
}
