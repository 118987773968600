import { Component, OnInit } from '@angular/core';
import { MultimediaService } from 'projects/qcloud-rest-client/src/lib/multimedia.service';
import { Schedule } from 'projects/qcloud-models/multimedia/schedule.model';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit {

  public schedules: Schedule[];
  selectedScheduleId: number;

  constructor(private multimediaService: MultimediaService, private translate: TranslateService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getSchedules();
  }

  getSchedules() {
    this.multimediaService.getSchedules().subscribe((data) => {
      this.schedules = data;
    });
  }

  deleteSchedule(scheduleId: number) {
    this.multimediaService.deleteSchedule(scheduleId).subscribe(
      res => {
        this.getSchedules();
      },
      err => {
        this.translate.get('delete-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  onDeleteConfirm(confirmed: boolean) {
    if (confirmed) {
      this.deleteSchedule(this.selectedScheduleId);
    }
  }

}
