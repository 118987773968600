<div class="table-responsive">
    <table class="table table-bordered">
        <thead>
            <tr>
                <th>#</th>
                <th>{{ 'name' | translate }}</th>
                <th>{{ 'options' | translate }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let speciality of specialities; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ speciality.name }}</td>
                <td>
                    <div class="row">
                        <button class="btn btn-info btn-circle btn-sm mx-1 mb-1"
                            (click)="router.navigate(['speciality', speciality.id])"
                            matTooltip="{{ 'edit' | translate }}" tooltipClass="tooltip-info">
                            <i class="fas fa-fw fa-edit"></i>
                        </button>
                        <button
                            (click)="selectedSpecialityId = speciality.id"
                            class="btn btn-danger btn-circle btn-sm mx-1 mb-1"
                            matTooltip="{{ 'delete' | translate }}" tooltipClass="tooltip-danger"
                            data-toggle="modal" data-target="#deleteModal2">
                            <i class="fas fa-fw fa-trash"></i>
                        </button>
                    </div>
                </td>
                </tr>
        </tbody>
    </table>
</div>

<div class="modal fade" id="deleteModal2" tabindex="-1" role="dialog" aria-hidden="true">
    <app-delete-modal [info]="'delete-confirmation'" [confirm]="'delete'" (confirmed)="onDeleteConfirm($event)"></app-delete-modal>
</div>
