import { NgModule } from '@angular/core';
import { QcloudConsoleComponent } from './qcloud-console.component';
import { QcloudRestClientModule } from '../../../qcloud-rest-client/src/lib/qcloud-rest-client.module';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { DeskComponent } from './desk/desk.component';
import { BigConsoleLayoutComponent } from './desk/console-big-layout/big-layout.component';
import { SmallConsoleLayoutComponent } from './desk/console-small-layout/small-layout.component';
import { SlimConsoleLayoutComponent } from './desk/console-slim-layout/slim-layout.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './desk/logout/logout.component';
import { CallTicketButtonComponent } from './desk/call-ticket-button/call-ticket-button.component';
import { AcceptTicketButtonComponent } from './desk/accept-ticket-button/accept-ticket-button.component';
import { DisplayTicketComponent } from './desk/display-ticket/display-ticket.component';
import { DisplayDeadlineComponent } from './desk/display-deadline/display-deadline.component';
import { CancelTicketButtonComponent } from './desk/cancel-ticket-button/cancel-ticket-button.component';
import { RedirectTicketButtonComponent } from './desk/redirect-ticket-button/redirect-ticket-button.component';
import { RedirectModalComponent } from './desk/redirect-modal/redirect-modal.component';
import { GenerateTicketButtonComponent } from './desk/generate-ticket-button/generate-ticket-button.component';
import { DisplayDeskComponent } from './desk/display-desk/display-desk.component';
import { DisplayUserComponent } from './desk/display-user/display-user.component';
import { CallAgainTicketButtonComponent } from './desk/call-again-ticket-button/call-again-ticket-button.component';
import { CancelledTicketsModalComponent } from './desk/cancelled-tickets-modal/cancelled-tickets-modal.component';
import { PausedTicketsModalComponent } from './desk/paused-tickets-modal/paused-tickets-modal.component';
import { PausedTicketsButtonComponent } from './desk/paused-tickets-button/paused-tickets-button.component';
import { CancelledTicketsButtonComponent } from './desk/cancelled-tickets-button/cancelled-tickets-button.component';
import { PauseTicketModalComponent } from './desk/pause-ticket-modal/pause-ticket-modal.component';
import { PauseTicketButtonComponent } from './desk/pause-ticket-button/pause-ticket-button.component';
import { RedirectToCategoryModalComponent } from './desk/redirect-to-category-modal/redirect-to-category-modal.component';
import { RedirectToDeskModalComponent } from './desk/redirect-to-desk-modal/redirect-to-desk-modal.component';
import { DeskStatusComponent } from './desk/desk-status/desk-status.component';
import { GenerateTicketModalComponent } from './desk/generate-ticket-modal/generate-ticket-modal.component';
import { CallTicketManualButtonComponent } from './desk/call-ticket-manual-button/call-ticket-manual-button.component';
import { CallTicketManualModalComponent } from './desk/call-ticket-manual-modal/call-ticket-manual-modal.component';
import { ShowTicketManualButtonComponent } from './desk/show-ticket-manual-button/show-ticket-manual-button.component';
import { ShowTicketManualModalComponent } from './desk/show-ticket-manual-modal/show-ticket-manual-modal.component';
import { QueueStateComponent } from './desk/queue-state/queue-state.component';
import { ServedCategoriesModalComponent } from './desk/served-categories-modal/served-categories-modal.component';
import { ServedCategoriesButtonComponent } from './desk/served-categories-button/served-categories-button.component';
import { BlockedCategoriesButtonComponent } from './desk/blocked-categories-button/blocked-categories-button.component';
import { BlockedCategoriesModalComponent } from './desk/blocked-categories-modal/blocked-categories-modal.component';
import { QueueStateAllTicketsComponent } from './desk/queue-state-all-tickets/queue-state-all-tickets.component';
import { CloseAllModalsButtonComponent } from './desk/close-all-modals-button/close-all-modals-button.component';
import { InteractionCardComponent } from './desk/interaction-card/interaction-card.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LogoutModalComponent } from './desk/logout-modal/logout-modal.component';
import { ETicketDeskComponent } from './desk/e-ticket-desk/e-ticket-desk.component';
import { CallETicketButtonComponent } from './desk/e-ticket-desk/call-eticket-button/call-eticket-button.component';
import { NoteTicketButtonComponent } from './desk/note-ticket-button/note-ticket-button.component';
import { BigLayoutConfigurationService } from './services/big-layout-configuration.service';
import { SlimLayoutConfigurationService } from './services/slim-layout-configuration.service';
import { SmallLayoutConfigurationService } from './services/small-layout-configuration.service';
import { ScreenSizeConfigurationService } from './services/screen-size-configuration.service';
import { ExpandMenuButtonComponent } from './desk/expand-menu-button/expand-menu-button.component';
import { SlimInteractionCardComponent } from './desk/slim-interaction-card/slim-interaction-card.component';
import { SaveScreenSizeService } from './services/save-screen-size.service';
import { ForHourChooserComponent } from './desk/for-hour-modal/for-hour-chooser.component';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NoteTicketModalPopupComponent } from './desk/note-ticket-modal-popup/note-ticket-modal-popup.component';
import { NoteModalQuestionsComponent } from './desk/note-ticket-modal-popup/note-modal-questions/note-modal-questions.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { LoginWebComponent } from './login-web/login-web.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [QcloudConsoleComponent,
    DeskComponent,
    BigConsoleLayoutComponent,
    SmallConsoleLayoutComponent,
    SlimConsoleLayoutComponent,
    LoginComponent,
    LoginWebComponent,
    LogoutComponent,
    CallTicketButtonComponent,
    AcceptTicketButtonComponent,
    DisplayTicketComponent,
    DisplayDeadlineComponent,
    CancelTicketButtonComponent,
    RedirectTicketButtonComponent,
    RedirectModalComponent,
    GenerateTicketButtonComponent,
    DisplayDeskComponent,
    DisplayUserComponent,
    CallAgainTicketButtonComponent,
    CancelledTicketsModalComponent,
    PausedTicketsModalComponent,
    PausedTicketsButtonComponent,
    CancelledTicketsButtonComponent,
    PauseTicketModalComponent,
    PauseTicketButtonComponent,
    RedirectToCategoryModalComponent,
    RedirectToDeskModalComponent,
    DeskStatusComponent,
    GenerateTicketModalComponent,
    NoteTicketButtonComponent,
    CallTicketManualButtonComponent,
    CallTicketManualModalComponent,
    ShowTicketManualButtonComponent,
    ShowTicketManualModalComponent,
    QueueStateComponent,
    ServedCategoriesModalComponent,
    ServedCategoriesButtonComponent,
    BlockedCategoriesButtonComponent,
    BlockedCategoriesModalComponent,
    QueueStateAllTicketsComponent,
    CloseAllModalsButtonComponent,
    ExpandMenuButtonComponent,
    InteractionCardComponent,
    LogoutModalComponent,
    ETicketDeskComponent,
    CallETicketButtonComponent,
    SlimInteractionCardComponent,
    ForHourChooserComponent,
    NoteTicketModalPopupComponent,
    NoteModalQuestionsComponent
  ],
  imports: [QcloudRestClientModule,
    FormsModule,
    BrowserModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    BrowserAnimationsModule,
    MatRadioModule,
    MatSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  providers: [
    BigLayoutConfigurationService,
    SmallLayoutConfigurationService,
    SlimLayoutConfigurationService,
    ScreenSizeConfigurationService,
    SaveScreenSizeService
  ],
  exports: [QcloudConsoleComponent]
})
export class QcloudConsoleModule { }
