<div *ngIf="device" class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="m-0 font-weight-bold text-primary">{{ 'device-customization' | translate }}: {{ device.deviceName }}</h5>
    </div>
    <div class="card-body">
        <form #form="ngForm" autocomplete="off">

            <div class="form-group">
                {{ 'text-bar' | translate }}
                <mat-form-field class="ml-2">
                    <mat-select name="barType" #barType="ngModel" [(ngModel)]="device.deviceCustomization.barType">
                        <mat-option [value]="BarType.Without">{{ 'none' | translate }}</mat-option>
                        <mat-option [value]="BarType.Text">{{ 'text' | translate }}</mat-option>
                        <mat-option [value]="BarType.Rss">RSS</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div *ngIf="device.deviceCustomization.barType == BarType.Text" class="form-group">
                {{ 'text' | translate }}
                <div class="input-group">
                    <input name="barText" #barText="ngModel" [(ngModel)]="device.deviceCustomization.barText"
                        class="form-control">
                </div>
            </div>

            <div *ngIf="device.deviceCustomization.barType == BarType.Rss" class="form-group">
                {{ 'rss-link' | translate }}
                <div class="input-group">
                    <input name="rssLink" #rssLink="ngModel" [(ngModel)]="device.deviceCustomization.rssLink"
                        class="form-control">
                </div>
            </div>

            <div class="form-group">
                <div class="item-group">
                    <mat-checkbox [color]="color" name="customBackground" #customBackground="ngModel"
                    [(ngModel)]="device.deviceCustomization.customBackground">{{ 'background' | translate }}</mat-checkbox>
                </div>

                <div *ngIf="device.deviceCustomization.customBackground" class="item-group">
                    <button class="btn btn-primary btn-sm" data-toggle="modal" data-target="#fileManagerModal">
                        {{ 'select-background' | translate }}
                    </button>
                    {{ device.deviceCustomization.backgroundImgPath }}
                    <input [(ngModel)]="device.deviceCustomization.backgroundImgPath" name="backgroundImgPath" hidden
                        [required]="device.deviceCustomization.customBackground">
                    <button *ngIf="device.deviceCustomization.backgroundImgPath" class="btn btn-danger btn-circle btn-sm"
                        (click)="setBackgroundPath('')">
                        <i class="fas fa-fw fa-times"></i>
                    </button>
                </div>
            </div>

            <!-- File Manager Modal-->
            <div class="modal fade" id="fileManagerModal" tabindex="-1" role="dialog" aria-labelledby="modalLabel"
                aria-hidden="true">
                <app-file-popup [selectedFilePath]="device.deviceCustomization.backgroundImgPath" [multiselect]="false"
                    (filePathToEmit)="setBackgroundPath($event)">
                </app-file-popup>
            </div>
            
            <div class="form-group">
                <div class="item-group">
                    <mat-checkbox [color]="color" name="displayDate" #displayDate="ngModel"
                    [(ngModel)]="device.deviceCustomization.displayDate">{{ 'date-display' | translate }}</mat-checkbox>
                </div>
            </div>

            <div class="form-group">
                <div class="item-group">
                    <mat-checkbox [color]="color" name="displayTime" #displayTime="ngModel"
                    [(ngModel)]="device.deviceCustomization.displayTime">{{ 'time-display' | translate }}</mat-checkbox>
                </div>
            </div>
            
            <div class="form-group row">
                <button class="btn btn-danger m-1" routerLink="/devices">{{ 'back' | translate }}</button>
                <button class="btn btn-primary m-1" type="submit"
                    [disabled]="formSubmitted || form.invalid" (click)="onSubmit(form)">
                <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                    aria-hidden="true"></span>{{ 'save-device' | translate }}
                </button>
            </div>
            <label *ngIf="form.invalid" class="text-danger">{{ 'select-background' | translate }}</label>
        </form>
    </div>
</div>
