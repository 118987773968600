import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LocationData } from 'projects/qcloud-models/location/location.model';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit {

  locationData: LocationData;
  isEditForm: boolean;
  formSubmitted: boolean = false;

  constructor(private service: LocationService, private route: ActivatedRoute, private router: Router,
    private authService: AuthService, private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit(): void {
    let locationId: number;
    this.route.params.subscribe(params => {
      locationId = params['id']
    });
    if (locationId) {
      this.isEditForm = true;
      this.getLocationData(locationId);
    } else {
      this.isEditForm = false;
      this.initializeLocation();
    }
  }

  getLocationData(locationId: number) {
    this.service.getLocationData(locationId).subscribe(
      locationData => {
        this.locationData = locationData;
      }
    );
  }

  initializeLocation() {
    this.locationData = new LocationData();
    this.locationData.systemId = this.authService.getTokenSystemId();
    this.locationData.timeZone = "Central European Standard Time";
  }

  getLogoPath(logoImgPath: string) {
    this.locationData.logoImgPath = logoImgPath;
  }

  clearLogoPath() {
    this.locationData.logoImgPath = null;
  }
  areETicketsEnabled() {
    return this.service.getLocationConstraints().areETicketsEnabled;
  }

  onSubmit() {
    this.formSubmitted = true;
    if (this.isEditForm) {
      this.editLocation();
    } else {
      this.addLocation();
    }
  }

  undoEdit() {
    if(this.isEditForm) {
      this.getLocationData(this.locationData.id);
    }
  }

  editLocation() {
    this.service.editLocationData(this.locationData).subscribe(
      res => {
        this.service.locationData = res;
        this.translate.get('edit-location-data-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
        this.formSubmitted = false;
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('edit-location-data-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  addLocation() {
    this.service.addLocation(this.locationData).subscribe(
      res => {
        this.formSubmitted = false;
        this.initializeLocation();
        this.router.navigate(['/locations']);
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('add-location-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

}
