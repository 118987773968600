<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="m-0 font-weight-bold text-primary">{{ 'resetting-tickets-unblocking-categories' | translate }}</h5>
    </div>
    <div class="card-body">
        <app-ticekts-reset-unblock-categories></app-ticekts-reset-unblock-categories>
    </div>
</div>

<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="m-0 font-weight-bold text-primary">{{ 'automatic-category-reset' | translate }}</h5>
    </div>
    <div *ngIf="categories" class="card-body">
        <app-reset-categories [categories]="categories"></app-reset-categories>
    </div>
</div>

<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="m-0 font-weight-bold text-primary">{{ 'group-categories-under-queue-state' | translate }}</h5>
    </div>
    <div *ngIf="categories" class="card-body">
        <app-group-categories [categories]="categories"></app-group-categories>
    </div>
</div>

<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="m-0 font-weight-bold text-primary">{{ 'schedule-for-blocking-category-activities' | translate }}</h5>
    </div>
    <div *ngIf="categories" class="card-body">
        <app-category-blocking-schedule [categories]="categories"></app-category-blocking-schedule>
    </div>
</div>