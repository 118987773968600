import { Component, OnInit } from '@angular/core';
import { News } from 'projects/qcloud-models/grafik/news/news.model';
import { NewsService } from 'projects/qcloud-rest-client/src/lib/grafik/news.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { RouteNavigationService } from 'projects/qcloud-web/src/app/shared/route-navigation.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  news: News[]
  selectedNewsId: number;

  constructor(private newsService: NewsService, private translate: TranslateService,
    private toastr: ToastrService, public router: RouteNavigationService) { }

  ngOnInit(): void {
    this.getNews();
  }

  getNews () {
    this.newsService.getNews().subscribe(
      (res) => this.news = res
    );
  }

  onDeleteConfirm(confirmed: boolean){
    if (confirmed) {
      this.deleteNews(this.selectedNewsId);
    }
  }

  deleteNews(newsId: number) {
    this.newsService.deleteNews(newsId).subscribe(
      res => {
        this.getNews();
      },
      err => {
        this.translate.get('delete-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );    
  }
}
