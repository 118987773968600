import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit {

  @Input() warningMessage: string;
  @Input() title: string;
  @Input() question: string;
  @Input() confirm: string;
  @Output() confirmed = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void { }

  send() {
    this.confirmed.emit(true);
  }
}
