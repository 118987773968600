<div class="card shadow mb-4">
    <div class="card-header py-3">
        <div class="row">
            <h5 class="col-8 m-0 font-weight-bold text-primary">{{ 'personalisation' | translate}}</h5>
            <div class="col-4 text-right">
                <button type="button" class="btn btn-warning btn-circle btn-sm" tooltipClass="tooltip-warning"
                    matTooltip="{{ 'set-default-values' | translate }}" (click)="deletePersonalisation()">
                    <i class="fas fa-fw fa-sync"></i>
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="personalisation" class="card-body">
        <form #form="ngForm" autocomplete="off">
            <p class="font-weight-bold">{{ 'default-names-used-in-layouts' | translate }}</p>

            <div class="form-group">
                {{ 'ticket-pronunciation' | translate }}
                <div class="input-group">
                    <input name="speakTicketText" #speakTicketText="ngModel"
                    [(ngModel)]="personalisation.locationWords.speakTicketText" class="form-control">
                </div>
            </div>

            <div class="form-group">
                {{ 'desk-pronunciation' | translate }}
                <div class="input-group">
                    <input name="speakDeskText" #speakDeskText="ngModel"
                    [(ngModel)]="personalisation.locationWords.speakDeskText" class="form-control">
                </div>
            </div>

            <div class="form-group">
                {{ 'category-pronunciation' | translate }}
                <div class="input-group">
                    <input name="speakCategoryText" #speakCategoryText="ngModel"
                    [(ngModel)]="personalisation.locationWords.speakCategoryText" class="form-control">
                </div>
            </div>

            <div class="form-group">
                {{ 'tag-text' | translate }}
                <div class="input-group">
                    <input name="tagText" #speakTagText="ngModel"
                    [(ngModel)]="personalisation.locationWords.tagText" class="form-control">
                </div>
            </div>

            <div class="form-group">
                {{ 'ticket-arrival-time-text' | translate }}
                <div class="input-group">
                    <input name="arrivalTime" #arrivalTime="ngModel" [(ngModel)]="personalisation.locationWords.arrivalTime"
                        class="form-control">
                </div>
            </div>

            <div class="form-group">
                {{ 'ticket-dispenser-queue-state-text' | translate }}
                <div class="input-group">
                    <input name="queueState" #queueState="ngModel" [(ngModel)]="personalisation.locationWords.queueState"
                    class="form-control">
                </div>
            </div>

            <div class="form-group">
                {{ 'ticket-dispenser-average-waiting-time-text' | translate }}
                <div class="input-group">
                    <input name="aproxWaitTime" #aproxWaitTime="ngModel"
                    [(ngModel)]="personalisation.locationWords.aproxWaitTime" class="form-control">
                </div>
            </div>

            <div class="form-group">
                {{ 'ticket-dispenser-return-text' | translate }}
                <div class="input-group">
                    <input name="returnText" #aproxWaitTime="ngModel"
                    [(ngModel)]="personalisation.locationWords.returnText" class="form-control">
                </div>
            </div>

            <div class="form-group">
                {{ 'default-background-for-devices' | translate }}
                <div class="input-group">
                    <button class="btn btn-primary btn-sm mr-1" data-toggle="modal" data-target="#fileManagerModal">
                        {{ 'select-background' | translate}}
                    </button>
                    {{ personalisation.defaultBackgroundUri }}
                    <button *ngIf="personalisation.defaultBackgroundUri" class="btn btn-danger btn-circle btn-sm ml-1"
                        (click)="setDefaultDeviceBackgroundPath('')">
                        <i class="fas fa-fw fa-times"></i>
                    </button>
                </div>
            </div>

            <!-- File Manager Modal-->
            <div class="modal fade" id="fileManagerModal" tabindex="-1" role="dialog" aria-labelledby="modalLabel"
                aria-hidden="true">
                <app-file-popup [selectedFilePath]="personalisation.defaultBackgroundUri" [multiselect]="false"
                    (filePathToEmit)="setDefaultDeviceBackgroundPath($event)">
                </app-file-popup>
            </div>

            <div class="form-group row">
                <button class="btn btn-primary m-1" type="submit" [disabled]="formSubmitted || form.invalid"
                    (click)="onSubmit()">
                <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                    aria-hidden="true"></span>{{ 'save-personalisation' | translate }}
                </button>
            </div>
        </form>
    </div>
</div>