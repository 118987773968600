<div *ngIf="checkLoadConsoleAssets() && !consoleService.user.mainRole.hasPermissionToServeETickets" [ngClass]="{'': consoleService.projectTypeEnum.WEB === this.consoleService.projectType, 'device-background': this.consoleService.projectType === this.consoleService.projectTypeEnum.DEVICE}" #divBody>
    <div [hidden]="isShowInteractionCard" class="slim-card-body" [ngClass]="{'': consoleService.projectTypeEnum.WEB === this.consoleService.projectType , 'device-operator-pool': this.consoleService.projectType === this.consoleService.projectTypeEnum.DEVICE}">
        <lib-display-ticket class="slim-size slim-card-operator-pool" #displayTicket></lib-display-ticket>
        <lib-call-ticket-button class="slim-size" [tooltipTitle]="'console.tooltip.call' | translate" [hidden]="isTicketCalled" #callTicketButtonComponent></lib-call-ticket-button>
        <lib-call-again-ticket-button class="slim-size" [tooltipTitle]="'console.tooltip.again' | translate" #callAgainTicketButtonComponent></lib-call-again-ticket-button>
        <lib-accept-ticket-button class="slim-size" [tooltipTitle]="'console.tooltip.accept' | translate" #acceptTicketButtonComponent></lib-accept-ticket-button>
        <lib-pause-ticket-button class="slim-size" [tooltipTitle]="'console.tooltip.pause' | translate" (showInteractionModel)="showInteractionCard()" #pauseTicketButtonComponent></lib-pause-ticket-button>
        <lib-redirect-ticket-button class="slim-size" [tooltipTitle]="'console.tooltip.redirect' | translate" (click)="showInteractionCard()" #redirectTicketButtonComponent></lib-redirect-ticket-button>
        <lib-cancel-ticket-button class="slim-size" [tooltipTitle]="'console.tooltip.cancel' | translate" #cancelTicketButtonComponent></lib-cancel-ticket-button>
        <lib-expand-menu-button class="slim-size" (click)="moveToExpandedMenu()" [tooltipTitle]="'console.tooltip.expanded-menu' | translate" #expandMenuButtonComponent></lib-expand-menu-button>
    </div>

    <div [hidden]="isShowInteractionCard" [ngClass]="isTicketCalled ? gridQueueWithDeadlineTimerCssClass : gridQueueStateFieldCssClass">
        <lib-display-deadline [hidden]="!isTicketCalled"  class="slim-display-deadline"  #displayDeadlineComponent></lib-display-deadline>
        <lib-queue-state [categorieShowLimits]="2" (click)="showInteractionCard()" #queueStateComponent></lib-queue-state>
    </div>
    <div [hidden]="!isShowInteractionCard">
        <lib-slim-interaction-card (hideInteractionModel)="hideInteractionCard()"></lib-slim-interaction-card>
    </div>
    <div [hidden]="!expandMenuButtonComponent.isMenuExpanded || isShowInteractionCard" class="slim-card-body expanded-menu-scope grid-container-sector-one">
        <lib-cancelled-tickets-button class="grid-item" (click)="showInteractionCard()" [ngClass]="{'': consoleService.projectTypeEnum.WEB === this.consoleService.projectType , 'device-font-size': this.consoleService.projectType === this.consoleService.projectTypeEnum.DEVICE}" #cancelledTicketsButtonComponent></lib-cancelled-tickets-button>
        <lib-paused-tickets-button class="grid-item" (click)="showInteractionCard()" [ngClass]="{'': consoleService.projectTypeEnum.WEB === this.consoleService.projectType , 'device-font-size': this.consoleService.projectType === this.consoleService.projectTypeEnum.DEVICE}" #pausedTicketsButtonComponent></lib-paused-tickets-button>
        <lib-call-ticket-manual-button class="grid-item" (click)="showInteractionCard()" [buttonTitleTranslationTag]="'console.slim.manual-call'" [tooltipTitle]="'console.manual-call' | translate"  [ngClass]="{'': consoleService.projectTypeEnum.WEB === this.consoleService.projectType , 'device-font-size': this.consoleService.projectType === this.consoleService.projectTypeEnum.DEVICE}" #callTicketManualButtonComponent></lib-call-ticket-manual-button>
        <lib-show-ticket-manual-button class="grid-item" (click)="showInteractionCard()" [ngClass]="{'': consoleService.projectTypeEnum.WEB === this.consoleService.projectType , 'device-font-size': this.consoleService.projectType === this.consoleService.projectTypeEnum.DEVICE}" #showTicketManualButtonComponent></lib-show-ticket-manual-button>
        </div>
    <div [hidden]="!expandMenuButtonComponent.isMenuExpanded || isShowInteractionCard" class="slim-card-body expanded-menu-scope grid-container-sector-second">
        <lib-blocked-categories-button class="grid-item" (click)="showInteractionCard()" [ngClass]="{'': consoleService.projectTypeEnum.WEB === this.consoleService.projectType , 'device-font-size': this.consoleService.projectType === this.consoleService.projectTypeEnum.DEVICE}" #blockedCategoriesButtonComponent></lib-blocked-categories-button>
        <lib-served-categories-button class="grid-item" (click)="showInteractionCard()" [buttonTitleTranslationTag]="'console.slim.served-categories'" [tooltipTitle]="'served-categories' | translate" [ngClass]="{'': consoleService.projectTypeEnum.WEB === this.consoleService.projectType , 'device-font-size': this.consoleService.projectType === this.consoleService.projectTypeEnum.DEVICE}" #servedCategoriesButtonComponent></lib-served-categories-button>
        <lib-generate-ticket-button class="grid-item" (click)="showInteractionCard()" [ngClass]="{'': consoleService.projectTypeEnum.WEB === this.consoleService.projectType , 'device-font-size': this.consoleService.projectType === this.consoleService.projectTypeEnum.DEVICE}" #generateTicketButtonComponent></lib-generate-ticket-button>
    </div>
</div>