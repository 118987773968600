<div class="frame">
    <img [src]="url" *ngIf="showImage" class="fit">
    <video autoplay muted loop *ngIf="showMp4" class="fit" oncanplay="this.play()" onloadedmetadata="this.muted = true"
        [src]="url" type="video/mp4">
    </video>
    <iframe
     [src]="url" 
     *ngIf="showHTML" 
     class="fit" 
     frameborder="0"
     allow="accelerometer;
     autoplay;
     clipboard-write;
     encrypted-media;
     gyroscope;
     picture-in-picture"
     allowfullscreen></iframe>
    <img src="assets/QCloud.png" *ngIf="!showImage && !showMp4 && !showHTML" class="fit">
</div>