import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { DeviceConfigurationService } from 'projects/qcloud-device/src/app/services/device-configuration.service';
import { BaseComponent } from 'projects/qcloud-device/src/app/shared/base-component/base.component';
import { TicketForHourInfo } from './ticket-for-hour-info';
import { TicketReservation } from 'projects/qcloud-models/reservation/ticket-reservation';
import { ReservationService } from 'projects/qcloud-rest-client/src/lib/reservation.service';
import { QcloudStompClientService } from 'projects/qcloud-stomp-client/src/public-api';
import { Subscription } from 'rxjs';
import { DeviceConfiguration } from 'projects/qcloud-models/device/device-configuration';

@Component({
  selector: 'app-desk-ticket-for-hour-queue-display',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './desk-ticket-for-hour-queue-display.html',
  styleUrls: ['./desk-ticket-for-hour-queue-display.css']
})
export class DeskTicketForHourQueueDisplayComponent extends BaseComponent implements OnInit {
  deskName: string;
  deskNumber: string;
  animation: any;
  active: boolean = false;
  ticketForHourInfos: TicketForHourInfo[] = [];
  ticketReservations: TicketReservation[]
  public ticketForHourInfosView: TicketForHourInfo[];
  private stompTicketSubscription: Subscription;
  private config: DeviceConfiguration;

  constructor(protected elementRef: ElementRef, protected renderer: Renderer2,
    private cdr: ChangeDetectorRef) {
    super(elementRef, renderer);

  }

  startComponent(): void {
    this.config = this.configurationService.getConfiguration();
  }

  ngOnInit(): void {
    let locationId = this.config.locationId;
      let systemId = this.config.systemId;
      this.stompTicketSubscription = this.qcloudStompClientService.subscribe(`/exchange/tickets/${systemId}.${locationId}.ticket`,
      () => {
        this.getTodayTicketReservation();
      });
    this.getTodayTicketReservation();
  }

  public getTodayTicketReservation() {
    var today = new Date();
    const startOfDay = new Date(today);
    var endOfDay = new Date(today);
    endOfDay.setDate(endOfDay.getDate() + 1);
    endOfDay.setHours(23,59,59,59);

    this.reservationService.getTicketsReservationsForDisplayCategoryDesk(startOfDay, endOfDay, this.config.deskId, 6, (data) => {
      var ticketForHourInfosForView = [];
      data.forEach(ticketForHourInfo => {
        var ticketForHourCell = new TicketForHourInfo();
        ticketForHourCell.id = ticketForHourInfo.id;
        ticketForHourCell.caption = ticketForHourInfo.caption;
        ticketForHourCell.reservatedDateTime = ticketForHourInfo.reservatedDateTime;
        ticketForHourCell.time = TicketForHourInfo.getHourAndMinuteFromDate(ticketForHourInfo.reservatedDateTime);
        ticketForHourInfosForView.push(ticketForHourCell);
      });
      this.ticketForHourInfos = ticketForHourInfosForView;
      this.cdr.detectChanges();
    }, () => {});
  }
}