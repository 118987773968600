<div *ngIf="locationService.getLocationConstraints().isClientSurveyEnabled" class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="m-0 font-weight-bold text-primary">{{ 'consumer-survey' | translate }}</h5>
    </div>
    <div class="card-body">
        <form #form="ngForm" autocomplete="off">          
            <div class="form-group">
                <mat-form-field>
                    <mat-label>{{ 'survey-method' | translate }}</mat-label>
                    <mat-select matNativeControl name="surveyMethod" #surveyMethod="ngModel" [(ngModel)]="survey.type" required>
                        <mat-option value="1">
                            {{ 'three-scale-panel' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <button class="btn btn-primary m-1" type="submit" [disabled]="formSubmitted || form.invalid"
                (click)="onSubmit(); formSubmitted=true">
                <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                    aria-hidden="true"></span>
                {{ 'save-changes' | translate }}
            </button>
        </form>
    </div>
</div>

<app-licence-info *ngIf="!locationService.getLocationConstraints().isClientSurveyEnabled"
    [title]="'consumer-survey'">
</app-licence-info>