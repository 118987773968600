import { Component, OnInit, Input } from '@angular/core';
import { Category } from 'projects/qcloud-models/category/category.model';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-office-console',
  templateUrl: './office-console.component.html',
  styleUrls: ['./office-console.component.css']
})
export class OfficeConsoleComponent implements OnInit {

  @Input() categories: Category[];
  @Input() categoryMultiCtrl: UntypedFormControl;

  servedCategories: string = 'served-categories';
  noSelectedCategories = 'no-selected-categories';
  
  constructor() { }

  ngOnInit(): void {
  }

}
