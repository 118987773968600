import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { User } from 'projects/qcloud-models/user/user.model';
import { timeout } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { ChangePassword } from 'projects/qcloud-models/user/change-password.model';
import { UserCategory } from '../../../qcloud-models/userCategory/userCategory.model';
import { UserPersonalInfoDto } from '../../../qcloud-models/user/user-personal-info-dto.model';

const DEFAULT_TIMEOUT = 6000;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  appBase: string;
  access_token: string;
  endpoint: string = "users";

  constructor(private http: HttpClient, private authService: AuthService) {
    this._stateChanged = new Subject<boolean>();
    this.stateChanged = this._stateChanged.asObservable();
  }

  public setAppBase(appBase: string) {
    this.appBase = appBase;
  }
  private _stateChanged: Subject<boolean>;
  stateChanged: Observable<boolean>;

  updateStateChanged(newValue) {
    this._stateChanged.next(newValue);
  }

  getUsers(callback: (data: User[]) => any, onError: (msg: any) => any) {
    this.http.get(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }
  getQuantityOfUsers(callback: (quantity: number) => any, onError: (msg: any) => any) {
    this.http.get(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/quantity`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }
  getUserPersonalInfos(callback: (userPersonalInfo: UserPersonalInfoDto[]) => any, onError: (msg: any) => any) {
    this.http.get(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/user-personal-info`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }
  getSystemUsers(callback: (data: User[]) => any, onError: (msg: any) => any) {
    this.http.get(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/system`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  getUser(userId: string): Observable<User> {
    return this.http.get<User>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/${userId}`);
  }

  addUser(user: User) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}`, user);
  }

  editUser(user: User) {
    return this.http.put(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/${user.id}`, user);
  }

  updateUserCategories(user: User) {
    return this.http.put(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/categories/${user.id}`, user);
  }

  deleteUser(userId: string) {
    return this.http.delete(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/${userId}`);
  }

  resetPassword(userId: string) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/${userId}/resetpassword`, userId);
  }

  changePassword(changePassword: ChangePassword) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/changepassword`,
      changePassword);
  }

  getUsersForConsole(callback: (data: User[]) => any, onError: (error) => any) {
    this.http.get(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/console/loginList`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  changeActivity(userId: string) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/${userId}/changeActivity`,
      {});
  }

  getUserCategory(userId: string): Observable<UserCategory[]> {
    return this.http.get<UserCategory[]>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/usercategory/${userId}`);
  }
  updateUserCategoryByUser(userId: string, categoryId: number): Observable<UserCategory[]> {
    return this.http.put<UserCategory[]>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/usercategory/${userId}/${categoryId}`, {});
  }

}
