<div #pagetop class="card shadow mb-4">
    <div class="card-header py-3">
        <div class="row">
            <h5 class="col-9 m-0 font-weight-bold text-primary">
                {{ 'edit-office-desk-maps' | translate }}
            </h5>
        </div>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{{ 'office' | translate }}</th>
                        <th>{{ 'desk' | translate }}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let officeDesk of officeDeskRelations; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>
                            <mat-form-field class="ml-2">
                                <mat-select name="officeId" #officeId="ngModel" [(ngModel)]="officeDesk.officeId">
                                    <mat-option *ngFor="let office of offices" [value]="office.id">{{ office.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="ml-2">
                                <mat-select name="deskId" #deskId="ngModel" [(ngModel)]="officeDesk.deskId">
                                    <mat-option *ngFor="let desk of desks" [value]="desk.id">{{ desk.name }}
                                        {{desk.deskNumber}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td>
                            <button (click)="onDelete(i)" class="btn btn-danger btn-circle btn-sm mx-1 mb-1"
                                matTooltip="{{ 'delete' | translate }}" tooltipClass="tooltip-danger">
                                <i class="fas fa-fw fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <button type="button" class="btn btn-primary btn-circle btn-sm" (click)="add()"
                matTooltip="{{ 'add' | translate }}" tooltipClass="tooltip-primary">
                <i class="fas fa-fw fa-plus"></i>
            </button>
        </div>
        <button class="btn btn-primary m-1" type="submit" (click)="onSubmit()">
            {{ 'save' | translate }}
        </button>
    </div>
</div>