import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { timeout } from 'rxjs/operators';
import { Specialisation } from 'projects/qcloud-models/grafik/specialisation/specialisation.model';

const DEFAULT_TIMEOUT = 3000;

@Injectable({
  providedIn: 'root'
})
export class SpecialisationService {

  appBase: string;

  constructor(private http: HttpClient, private authService: AuthService) { }

  public setAppBase(appBase: string){
    this.appBase = appBase;
  }

  getSpecialities(callback: (data: Specialisation[]) => any, onError: () => any) {
    this.http.get(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/specialisations`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  getSpeciality(specialisationId: number) : Observable<Specialisation>{
    return this.http.get<Specialisation>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/specialisations/${specialisationId}`)
  }


  addSpeciality(speciality: Specialisation) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/specialisations`, speciality);
  }

  deleteSpeciality(specialityId: number) {
    return this.http.delete(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/specialisations/${specialityId}`);
  }

  editSpeciality(speciality: Specialisation) {
    return this.http.put(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/specialisations/${speciality.id}`,
      speciality);
  }
}
