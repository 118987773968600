import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { QcloudConsoleService } from '../../qcloud-console.service';

@Component({
  selector: 'lib-pause-ticket-button',
  templateUrl: './pause-ticket-button.component.html',
  styleUrls: ['./pause-ticket-button.component.css',  './../base-button/base-button.component.css']
})
export class PauseTicketButtonComponent  implements OnInit {
  @Output() showInteractionModel = new EventEmitter<boolean>();
  @Input() public tooltipTitle: string;
	@ViewChild('button') public button: ElementRef;
  @ViewChild('icon') public icon: ElementRef;
  isEnabled: boolean;
  isLocked: boolean;

  constructor(private consoleService: QcloudConsoleService) {
    this.consoleService.ticket.subscribe((val) => {
      if (val.id != 0 && val.id != -1) {
        this.isEnabled = true;
      }
      else {
        this.isEnabled = false;
      }
    });
    this.consoleService.isLocked.subscribe((val) => {
      this.isLocked = val;
    });
  }

  ngOnInit(): void {
  }

  public openPauseModal() {
    this.showInteractionModel.emit(true);
    if (this.isEnabled)
      this.consoleService.openPauseModal();
  }

}
