<div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
        <div class="modal-body">
            <button *ngIf="uploader.queue.length < 1" type="button" (click)="fileInput.click()" class="btn btn-primary mb-3">
                {{ 'add-file' | translate }}
            </button>
            <button class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
            <span class="hidden-input">
                <input type="file" accept="image/*" #fileInput ng2FileSelect [uploader]="uploader"/>
            </span>

            
 
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>{{ 'name' | translate }}</th>
                            <th>{{ 'extension' | translate }}</th>
                            <th>{{ 'size' | translate }}</th>
                            <th>{{ 'status' | translate }}</th>
                            <th>{{ 'operations' | translate }}</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of uploader.queue">
                        <td><strong>
                            <div *ngIf="item?.file?.name.length > 30">{{ item?.file?.name | removeExtention | slice:0:30 }}...</div>
                            <div *ngIf="!(item?.file?.name.length > 30)">{{ item?.file?.name | removeExtention }}</div>
                        </strong></td>
                        <td><strong>{{ item?.file?.name | fileExtention }}</strong></td>
                        <td *ngIf="uploader.options.isHTML5" nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
                        <td>
                            <span *ngIf="item.isSuccess"><i class="fas fa-check green"></i></span>
                            <span *ngIf="item.isError"><i class="fas fa-ban red"></i></span>
                        </td>
                        <td nowrap>
                            <button type="button" class="btn btn-success btn-sm mr-1" (click)="item.upload()"
                                [disabled]="item.isReady || item.isUploading || item.isSuccess">
                                {{ 'send' | translate }}
                            </button>
                            <button type="button" class="btn btn-danger btn-sm" (click)="item.remove()">
                                {{ 'delete' | translate }}
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
 
            {{ 'transfer-progress' | translate }}:
            <div *ngIf="uploader.progress==100">
                {{ 'file-send-successfully' | translate}}
                <span><i class="fas fa-check green"></i></span>
            </div>
            <div *ngIf="uploader.progress < 100" class="progress mb-2">
                <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
            </div>
            <div *ngIf="uploader.progress==100">
                <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal">
                    {{ 'close-window' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>