import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './page/dashboard/dashboard.component';
import { DesksComponent } from './page/desks/desks.component';
import { CategoriesComponent } from './page/categories/categories.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { DeskComponent } from './page/desks/desk/desk.component';
import { UsersComponent } from './page/users/users.component';
import { UserComponent } from './page/users/user/user.component';
import { DevicesComponent } from './page/devices/devices.component';
import { DeviceComponent } from './page/devices/device/device.component';
import { DeviceCustomizationComponent } from './page/devices/device-customization/device-customization.component';
import { FileManagerComponent } from './page/file-manager/file-manager.component';
import { MaintenanceComponent } from './page/location-configuration/maintenance/maintenance.component';
import { PlaylistsComponent } from './page/multimedia/playlists/playlists.component';
import { AddPlaylistComponent } from './page/multimedia/playlists/add-playlist/add-playlist.component';
import { EditPlaylistComponent } from './page/multimedia/playlists/edit-playlist/edit-playlist.component';
import { TicketLayoutComponent } from './page/location-configuration/ticket-layout/ticket-layout.component';
import { PersonalisationComponent } from './page/location-configuration/personalisation/personalisation.component';
import { LanguagesAndTranslationsComponent } from './page/location-configuration/languages-and-translations/languages-and-translations.component';
import { MultimediaDevicesComponent } from './page/multimedia/multimedia-devices/multimedia-devices.component';
import { ScheduleComponent } from './page/multimedia/schedule/schedule.component';
import { AddScheduleComponent } from './page/multimedia/schedule/add-schedule/add-schedule.component';
import { EditScheduleComponent } from './page/multimedia/schedule/edit-schedule/edit-schedule.component';
import { LocationComponent } from './page/system-settings/locations/location/location.component';
import { LocationsComponent } from './page/system-settings/locations/locations.component';
import { SelectLocationComponent } from './page/select-location/select-location.component';
import { DefaultSettingsComponent } from './page/system-settings/default-settings/default-settings.component';
import { ProfileComponent } from './page/profile/profile.component';
import { LicenseComponent } from './page/system-settings/license/license.component';
import { EmailConfigComponent } from './page/system-settings/email-config/email-config.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { ConsoleComponent } from './page/console/console.component';
import { NavbarFooterComponent } from './main/navbar-footer/navbar-footer.component';
import { TicketStatisticsComponent } from './page/statistics/ticket-statistics/ticket-statistics.component';
import { ReservationStatisticsComponent } from './page/statistics/reservation-statistics/reservation-statistics.component';
import { CustomerSurveyStatisticsComponent } from './page/statistics/customer-survey-statistics/customer-survey-statistics.component';
import { ReportStatisticsComponent } from './page/statistics/report-statistics/report-statistics.component';
import { TicketHistoryStatisticsComponent } from './page/statistics/ticket-history-statistics/ticket-history-statistics.component';
import { ReservationComponent } from './page/reservation/reservation.component';
import { ReservationImportCSVComponent } from './page/reservation/reservation-import-csv/reservation-import-csv.component';
import { ReservationConfigurationPageComponent } from './page/reservation/reservation-configuration-page/reservation-configuration-page.component';
import { AxxonIntelectIntegrationPageComponent } from './page/Integrations/axxon-intelect-Integration-page';
import { CustomerSurveyComponent } from './page/additional-modules/customer-survey/customer-survey.component';
import { WorkScheduleComponent } from './page/additional-modules/work-schedule/work-schedule.component';
import { SmsNotificationsComponent } from './page/additional-modules/sms-notifications/sms-notifications.component';
import { CategoryComponent } from './page/categories/category/category.component';
import { EmployeeComponent } from './page/additional-modules/work-schedule/employees-list/employee/employee.component';
import { SpecialityComponent } from './page/additional-modules/work-schedule/specialities/speciality/speciality.component';
import { OfficeComponent } from './page/additional-modules/work-schedule/offices/office/office.component';
import { SingleNewsComponent } from './page/additional-modules/work-schedule/news/single-news/single-news.component';
import { AmmsIntegrationPageComponent } from './page/Integrations/amms-integration-page';
import { SmsProviderComponent } from './page/system-settings/sms-provider/sms-provider.component';
import { DeviceLayoutsComponent } from './page/device-layouts/device-layouts.component';
import { OfficeDeskEditComponent } from './page/additional-modules/work-schedule/office-desk/office-desk-edit/office-desk-edit.component';
import { QrCodeComponent } from './page/additional-modules/qr-code/qr-code.component';
import { OnHoursComponent } from './page/on-hours/on-hours.component';
import { IsAdminGuard } from '../../../qcloud-rest-client/src/lib/auth/auth.guard';
import { DeviceLayoutComponent } from './page/device-layouts/device-layout/device-layout.component';
import { LDAPIntegrationComponent } from './page/additional-modules/ldap-integration/ldap-intergation.component';
import { QcloudRoleComponent } from './page/qcloud-roles/qcloud-role/qcloud-role.component';
import { QcloudRolesComponent } from './page/qcloud-roles/qcloud-roles.component';
import { NoteTemplateConfigurationComponent } from './page/note-template-configurations/note-template-configuration/note-template-configuration.component';
import { LdapRolesComponent } from './page/ldap-roles/ldap-roles.component';
import { LdapRoleComponent } from './page/ldap-roles/ldap-role/ldap-role.component';
import { NoteTemplateConfigurationsComponent } from './page/note-template-configurations/note-template-configurations.component';
import { NoteHistoryStatisticsComponent } from './page/statistics/note-history-statistics/note-history-statistics.component';
import { SystemCategoryComponent } from './page/system-categories/system-category/system-category.component';
import { SystemCategoriesComponent } from './page/system-categories/system-categories.component';
import { SystemQcloudRolesComponent } from './page/system-qcloud-roles/system-qcloud-roles.component';
import { SystemQcloudRoleComponent } from './page/system-qcloud-roles/system-qcloud-role/system-qcloud-role.component';
import { LogsComponent } from './page/logs/logs/logs.component';
import { SystemDeskComponent } from './page/system-desks/system-desk/system-desk.component';
import { SystemDesksComponent } from './page/system-desks/system-desks.component';
import { SystemUsersComponent } from './page/system-users/system-users.component';
import { SystemUserComponent } from './page/system-users/system-user/system-user.component';
import { SelectLocationStepComponent } from './page/select-location-step/select-location-step.component';
import { CategoriesDisplayComponent } from './page/categories-display/categories-display.component';
import { AddEditCategoriesDisplayComponent } from './page/categories-display/add-edit-categories-display/add-edit-categories-display.component';
import { AddEditCategoryGroupsComponent } from './page/categories-display/add-edit-category-groups/add-edit-category-groups.component';
import { SystemTicketQuestionsComponent } from './page/system-ticket-questions/system-ticket-questions.component';
import { SystemTicketQuestionComponent } from './page/system-ticket-questions/system-ticket-question/system-ticket-question.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: 'qcloud', component: NavbarFooterComponent, canActivate: [IsAdminGuard],
    children: [
      { path: 'welcome', component: WelcomeComponent },
      { path: 'web-console', component: ConsoleComponent },
      { path: 'profile/:id', component: ProfileComponent },
      { path: 'reservations', component: ReservationComponent },
      { path: 'workSchedule', component: WorkScheduleComponent },
      { path: 'employee', component: EmployeeComponent },
      { path: 'employee/:id', component: EmployeeComponent },
      { path: 'speciality', component: SpecialityComponent },
      { path: 'speciality/:id', component: SpecialityComponent },
      { path: 'office', component: OfficeComponent },
      { path: 'office/:id', component: OfficeComponent },
      { path: 'singleNews', component: SingleNewsComponent },
      { path: 'singleNews/:id', component: SingleNewsComponent },
      { path: 'addofficedesk', component: OfficeDeskEditComponent },
      { path: 'select-location-step/:modulePage', component: SelectLocationStepComponent }
    ]
  },
  {
    path: '', component: MainComponent,
    children: [
      {
        path: '', canActivate: [IsAdminGuard], data: { permittedRoles: ['SuperAdministrator', 'Administrator', 'Manager'] },
        children: [
          { path: '', component: DashboardComponent },
          { path: 'dashboard', component: DashboardComponent },
          { path: 'profile/:id', component: ProfileComponent },
          { path: 'categories', component: CategoriesComponent },
          { path: 'category', component: CategoryComponent },
          { path: 'category/:id', component: CategoryComponent },
          { path: 'systemCategories', component: SystemCategoriesComponent },
          { path: 'systemCategory', component: SystemCategoryComponent },
          { path: 'systemCategory/:id', component: SystemCategoryComponent },
          { path: 'desks', component: DesksComponent },
          { path: 'desk', component: DeskComponent },
          { path: 'desk/:id', component: DeskComponent },
          { path: 'systemDesks', component: SystemDesksComponent },
          { path: 'systemDesk', component: SystemDeskComponent },
          { path: 'systemDesk/:id', component: SystemDeskComponent },
          { path: 'users', component: UsersComponent },
          { path: 'user', component: UserComponent },
          { path: 'user/:id', component: UserComponent },
          { path: 'system-users', component: SystemUsersComponent },
          { path: 'system-user', component: SystemUserComponent },
          { path: 'system-user/:id', component: SystemUserComponent },
          { path: 'filemanager', component: FileManagerComponent },
          { path: 'playlists', component: PlaylistsComponent },
          { path: 'playlist/add', component: AddPlaylistComponent },
          { path: 'playlist/edit/:id', component: EditPlaylistComponent },
          { path: 'multimediaDevices', component: MultimediaDevicesComponent },
          { path: 'schedules', component: ScheduleComponent },
          { path: 'schedule/add', component: AddScheduleComponent },
          { path: 'schedule/edit/:id', component: EditScheduleComponent },
          { path: 'statistics/ticket', component: TicketStatisticsComponent },
          { path: 'statistics/reservation', component: ReservationStatisticsComponent },
          { path: 'statistics/customerSurvey', component: CustomerSurveyStatisticsComponent },
          { path: 'statistics/report', component: ReportStatisticsComponent },
          { path: 'statistics/ticketHistory', component: TicketHistoryStatisticsComponent },
          { path: 'reservations', component: ReservationComponent },
          { path: 'reservationConfiguration', component: ReservationConfigurationPageComponent },
          { path: 'reservationImportCSV', component: ReservationImportCSVComponent },
          { path: 'statistics/reports', component: ReportStatisticsComponent },
          { path: 'statistics/ticketHistory', component: TicketHistoryStatisticsComponent },
          { path: 'statistics/noteHistory', component: NoteHistoryStatisticsComponent },
          { path: 'customerSurvey', component: CustomerSurveyComponent },
          { path: 'workSchedule', component: WorkScheduleComponent },
          { path: 'smsNotifications', component: SmsNotificationsComponent },
          { path: 'employee', component: EmployeeComponent },
          { path: 'employee/:id', component: EmployeeComponent },
          { path: 'speciality', component: SpecialityComponent },
          { path: 'speciality/:id', component: SpecialityComponent },
          { path: 'office', component: OfficeComponent },
          { path: 'office/:id', component: OfficeComponent },
          { path: 'singleNews', component: SingleNewsComponent },
          { path: 'singleNews/:id', component: SingleNewsComponent },
          { path: 'addofficedesk', component: OfficeDeskEditComponent },
          { path: 'eticket', component: QrCodeComponent },
          { path: 'onHours', component: OnHoursComponent },
          { path: 'selectLocation', component: SelectLocationComponent },
          { path: 'ldapConfiguration', component: LDAPIntegrationComponent },
          { path: 'qcloudRoles', component: QcloudRolesComponent },
          { path: 'qcloudRole', component: QcloudRoleComponent },
          { path: 'qcloudRole/:id', component: QcloudRoleComponent },
          { path: 'systemQcloudRoles', component: SystemQcloudRolesComponent },
          { path: 'systemQcloudRole', component: SystemQcloudRoleComponent },
          { path: 'systemQcloudRole/:id', component: SystemQcloudRoleComponent },
          { path: 'noteTemplateConfiguration', component: NoteTemplateConfigurationComponent },
          { path: 'noteTemplateConfiguration/:id', component: NoteTemplateConfigurationComponent },
          { path: 'ldapRoles', component: LdapRolesComponent },
          { path: 'ldapRole', component: LdapRoleComponent },
          { path: 'ldapRole/:id', component: LdapRoleComponent },
        ]
      },
      {
        path: '', canActivate: [IsAdminGuard], data: { permittedRoles: ['SuperAdministrator', 'Administrator'] },
        children: [
          { path: 'devices', component: DevicesComponent },
          { path: 'device', component: DeviceComponent },
          { path: 'device/:guid', component: DeviceComponent },
          { path: 'device/customize/:guid', component: DeviceCustomizationComponent },
          { path: 'personalisation', component: PersonalisationComponent },
          { path: 'ticketLayout', component: TicketLayoutComponent },
          { path: 'maintenance', component: MaintenanceComponent },
          { path: 'languagesAndTranslations', component: LanguagesAndTranslationsComponent },
          { path: 'location/:id', component: LocationComponent },
          { path: 'license', component: LicenseComponent },
          { path: 'axxon', component: AxxonIntelectIntegrationPageComponent },
          { path: 'amms', component: AmmsIntegrationPageComponent },
          { path: 'emailConfig', component: EmailConfigComponent },
          { path: 'smsProvider', component: SmsProviderComponent },
          { path: 'notesConfiguration', component: NoteTemplateConfigurationsComponent },
          { path: 'logs', component: LogsComponent },
          { path: 'categories-display', component: CategoriesDisplayComponent },
          { path: 'categories-display/edit/:id', component: AddEditCategoriesDisplayComponent },
          { path: 'categories-display/add', component: AddEditCategoriesDisplayComponent },
          { path: 'categories-display/group/edit/:id', component: AddEditCategoryGroupsComponent },
          { path: 'categories-display/group/add', component: AddEditCategoryGroupsComponent },
          { path: 'ticket-questions', component: SystemTicketQuestionsComponent },
          { path: 'ticket-questions/edit/:id', component: SystemTicketQuestionComponent },
          { path: 'ticket-questions/add', component: SystemTicketQuestionComponent }
        ]
      },
      {
        path: '', canActivate: [IsAdminGuard], data: { permittedRoles: ['SuperAdministrator'] },
        children: [
          { path: 'locations', component: LocationsComponent },
          { path: 'location', component: LocationComponent },
          { path: 'defaultSettings', component: DefaultSettingsComponent },
          { path: 'deviceLayouts', component: DeviceLayoutsComponent },
          { path: 'layout/:id', component: DeviceLayoutComponent }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }