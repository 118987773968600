<div class="button-container" [ngStyle]="getBackgroundStyle()">
    <div class="button-container" [ngClass]="{ 'active': active }" [style.background-image]="backgroundImage">
        <div class="middle" [ngStyle]="getStyle()">
            <div class="text-wrapper">
                <div class="left" [ngStyle]="{ 'width': content2 ? '50%' : '100%' }">
                    <div [fittext]="true" [activateOnResize]="true" [modelToWatch]="content">
                        {{ content }}
                    </div>
                </div>
                <div class="right" *ngIf="content2">
                    <div style="width:70%;float:left" [fittext]="true" [activateOnResize]="true"
                        [modelToWatch]="content2">
                        {{ content2 }}
                    </div>
                    <div *ngIf="content3" style="width: 30%;height: 100%;
                        background-size: contain;background-repeat: no-repeat;float:left;"
                        [style.background-image]="getIconPath()">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>