import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { QcloudConsoleService } from 'projects/qcloud-console/src/public-api';
import { User } from 'projects/qcloud-models/user/user.model';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  username: string;
  userId: string;
  user: User;

  constructor(private translate: TranslateService, private authService: AuthService,
    private router: Router, public consoleService: QcloudConsoleService) {
  }

  ngOnInit(): void {
    this.username = this.authService.getTokenUsername();
    this.userId = this.authService.getTokenUserId();
    this.translate.use(this.translate.getDefaultLang());
  }

  public changeLanguage(language: string){
    this.translate.use(language);
    this.translate.setDefaultLang(language);
  }

  checkLanguage(language: string): boolean {
    return language == this.translate.currentLang;
  }

  onLogoutConfirm(confirmed: boolean) {
    if (confirmed) {
      this.logout();
    }
  }

  logout() {
    if (this.authService.isConsoleLoggedIn()) {
      this.consoleService.logout();
    }
    this.authService.onLogout();
    this.router.navigate(['login']);
  }

}
