<div class="card shadow mb-4">
    <div class="card-header py-3">
        <div class="row">
            <h5 class="col-8 m-0 font-weight-bold text-primary">{{ 'ticket-question' | translate }}</h5>
        </div>
    </div>
    <div *ngIf="ticketQuestion" class="card-body">
        <app-served-categories *ngIf="categoryMultiCtrl"
            [categoryMultiCtrl]="categoryMultiCtrl" [categories]="categories" [title]="servedCategories"
            [noSelectedCategories]="noSelectedCategories" [displayCategories]="true">
        </app-served-categories>
        <div class="form-group">
            {{ 'question' | translate }}
            <div class="input-group">
                <input name="question" #question="ngModel" [(ngModel)]="ticketQuestion.question" class="form-control">
            </div>
        </div>

        <div class="form-group">
            {{ 'key-name' | translate }}
            <div class="input-group">
                <input name="keyName" #keyName="ngModel" [(ngModel)]="ticketQuestion.keyName" class="form-control">
            </div>
        </div>

        <div class="form-group">
            {{ 'question-type' | translate }}
            <div class="input-group ">
                <select name="questionType" #questionType="ngModel" [(ngModel)]="ticketQuestion.type"
                    class="form-control">
                    <option value="0">{{ 'number' | translate }}</option>
                    <option value="1">{{ 'string' | translate }}</option>
                </select>
            </div>
        </div>

        <div class="form-group row">
            <button class="btn btn-primary m-1" type="submit" (click)="onSubmit();">
                {{'save' | translate }}
            </button>
        </div>
    </div>
</div>