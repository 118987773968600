import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LocationData } from 'projects/qcloud-models/location/location.model';
import { Login } from 'projects/qcloud-models/user/login.model';
import { TokenResponse } from 'projects/qcloud-models/user/token-response';
import { User } from 'projects/qcloud-models/user/user.model';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { UserService } from 'projects/qcloud-rest-client/src/lib/user.service';
import { QCloudRestClientService } from 'projects/qcloud-rest-client/src/public-api';

@Component({
  selector: 'app-select-location-popup',
  templateUrl: './select-location-popup.component.html',
  styleUrls: ['./select-location-popup.component.css']
})
export class SelectLocationPopupComponent implements OnInit {

  locations: LocationData[];
  choosenLocation: LocationData;
  user: User;
  enabledByLocationIds: number[] = [];

  constructor(private service: LocationService, public authService: AuthService, private userService: UserService,
    public qcloudRestClientService: QCloudRestClientService, private toastr: ToastrService,
    private translate: TranslateService, private dialogRef: MatDialogRef<SelectLocationPopupComponent>,
    private router: Router) { }

  ngOnInit(): void {
    this.getAllLocations();
    this.enabledByLocationIds.push(...this.authService.getTokenLocationIds());
    this.qcloudRestClientService.loginForm = new Login();
  }

  getAllLocations() {
    this.service.getAll().subscribe(
      locations => {
        this.locations = locations;
        var loggedUserLocationId = this.authService.getLocationId();
        this.choosenLocation = this.locations.find(x => x.id == loggedUserLocationId);
      }
    );
  }

  changeLocation() {
    this.qcloudRestClientService.loginForm.username = this.authService.getTokenUsername();
    this.qcloudRestClientService.changeLocation(this.choosenLocation.id, this.callback, this.onError);
    this.qcloudRestClientService.loginForm = new Login();
  }

  private callback = (data: TokenResponse) => {
    this.authService.setLocationId(this.choosenLocation.id);
    this.service.getLocationData(this.choosenLocation.id).subscribe(
      locationData => {
        this.service.locationData = locationData;
        this.dialogRef.close();
        var path = this.router.url;
        console.log("Route to another page");
        this.router.navigate(['/']).then(()=> {

           this.router.navigateByUrl(path);
        });
      }
    );
  }

  private onError = () => {
    this.translate.get('change-location-error').subscribe((error: string) => {
      this.translate.get('repeat').subscribe((res: string) => {
        this.toastr.error(res, error);
      });
    });
  }
  isLocationEnable(locationId: number) {
    var res = this.enabledByLocationIds.some(l => l === locationId);
    return res;
  }
}
