<div class="form-group">
    <div class="font-weight-bold">{{ title | translate}}</div>
    <div class="input-group">
        <mat-form-field color="primary">
            <mat-select [disabled]="isDisabled" [formControl]="locationMultiCtrl" [multiple]="true" #multiSelect
                (selectionChange)="emitLocations($event.value)">
                <mat-option>
                    <ngx-mat-select-search [showToggleAllCheckbox]="true" (toggleAll)="toggleSelectAll($event)"
                        [formControl]="locationMultiFilterCtrl"
                        toggleAllCheckboxTooltipMessage="{{ 'select-unselect-all' | translate }}"
                        [toggleAllCheckboxTooltipPosition]="'above'"
                        placeholderLabel="{{ 'search-location' | translate }}"
                        noEntriesFoundLabel="{{ 'no-matching-location' | translate }}">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let location of filteredLocationsMulti | async" [value]="location" [disabled]="!isLocationEnable(location.id)">
                    <b>{{ location.locationName }}</b>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <p *ngIf="locationMultiFilterCtrl.value == null || locationMultiFilterCtrl?.value.length == 0">
        {{ noSelectedLocations | translate }}</p>
</div>