<div class="form-group">
    <div class="font-weight-bold">{{ title | translate}}</div>
    <div class="input-group">
        <mat-form-field color="primary">
            <mat-select [formControl]="officeMultiCtrl" [multiple]="false"
                (selectionChange)="emitOffices($event.value)">
                <mat-option>
                    <ngx-mat-select-search [formControl]="officeMultiFilterCtrl"
                        placeholderLabel="{{ 'search-office' | translate }}"
                        noEntriesFoundLabel="{{ 'no-matching-office' | translate }}">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option>{{'no-office' | translate}}</mat-option>
                <mat-option *ngFor="let office of filteredOfficesMulti | async" [value]="office">
                    <b>{{ office.name }}</b>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>