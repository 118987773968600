import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-statistics-average-bar',
  templateUrl: './statistics-average-bar.component.html',
  styleUrls: ['./statistics-average-bar.component.css']
})
export class StatisticsAverageBarComponent implements OnInit {

  @Input() moveRight;

  constructor() { }

  ngOnInit(): void {
  }

}
