import { Component, OnInit, Input } from '@angular/core';
import { TranslationService } from 'projects/qcloud-rest-client/src/lib/translation.service';
import { PersonalisationTranslation } from 'projects/qcloud-models/translation/personalisation-translation';
import { Language } from 'projects/qcloud-models/translation/language.model';
import { LocationData } from 'projects/qcloud-models/location/location.model';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { Personalisation } from 'projects/qcloud-models/location/personalisation';
import { Translation } from 'projects/qcloud-models/translation/translation.model';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { TranslationConfigurationService } from 'projects/qcloud-web/src/app/shared/translation-configuration.service';

@Component({
  selector: 'app-personalisation-translation',
  templateUrl: './personalisation-translation.component.html',
  styleUrls: ['./personalisation-translation.component.css',
  '../languages-and-translations.component.css']
})
export class PersonalisationTranslationComponent implements OnInit {

  @Input() translations: Translation[];
  @Input() languages: Language[];
  @Input() locationData: LocationData;
  startIndex: number;
  personalisation: Personalisation;
  personalisationTranslations: PersonalisationTranslation[];
  isCollapsed: boolean = false;
  formSubmitted: boolean = false;

  constructor(
    private service: TranslationService,
    private locationService: LocationService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private translationConfigurationService: TranslationConfigurationService
    ) { }

  ngOnInit(): void {
    this.locationService.getPersonalisation().subscribe(
      personalisation => {
        this.personalisation = personalisation;
      }
    );
    this.translationConfigurationService.translationLanguages.subscribe(translations => {
      this.translations = translations;
      this.getPersonalisationTranslation();
    });
    this.getPersonalisationTranslation();
  }

  getPersonalisationTranslation() {
    this.service.getPersonalisationTranslation().subscribe(
      res => {
        this.personalisationTranslations = new Array<PersonalisationTranslation>();
        for (let i = 0; i < this.translations.length; i++) {
          let pT = res.find(pT => pT.translationId == this.translations[i].id);
          this.personalisationTranslations.push(pT);
        }
      }
    );
  }

  getLocaleName(locale: string) {
    return this.languages.find(languageOption => languageOption.locale == locale).name;
  }

  deleteLocaleMain(translations: Translation[]) {
    return translations.filter(translation => translation.locale != this.locationData.locationLocaleMain);
  }

  onSubmit() {
    this.formSubmitted = true;
    this.personalisationTranslations.forEach(personalisationTranslation => {
      personalisationTranslation.isDefault = false;
      personalisationTranslation.translationWordsJson =
        JSON.stringify(personalisationTranslation.translationWords);
    });
    this.service.setPersonalisationTranslation(this.personalisationTranslations)
      .subscribe(
      res => {
        this.getPersonalisationTranslation();
        this.translate.get('personalisation-translations-success').subscribe((res: string) => {
          this.toastr.success(res);
          this.formSubmitted = false;
        });
      },
      err => {
        this.translate.get('personalisation-translations-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
            this.formSubmitted = false;
          });
        });
      }
    );
  }

  backToDefaultTranslation(personalisation: PersonalisationTranslation) {
    this.service.deletePersonalisationTranslation(personalisation.id).subscribe(
      res => {
        this.getPersonalisationTranslation();
        this.translate.get('personalisation-translations-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.translate.get('personalisation-translations-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  getFilteredTranslations(startIndex: number) {
    this.startIndex = startIndex;
  }

  isOnPage() {
    return this.translations.slice(this.startIndex, this.startIndex + 2);
  }

}
