import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, scheduled } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { ReservationCategoryGroup as ReservationCategoryGroup } from 'projects/qcloud-models/reservation-category-group/reservation-category-group';
import { ReservationSchedule } from 'projects/qcloud-models/reservation/reservation-schedule';

const DEFAULT_TIMEOUT = 3000;

@Injectable({
  providedIn: 'root'
})
export class ReservationGroupClientService {

  appBase: string;

  constructor(private http: HttpClient, private authService: AuthService) { }

  public setAppBase(appBase: string) {
    this.appBase = appBase;
  }

  getGroups(callback: (data: ReservationCategoryGroup[]) => any, onError: () => any) {
    this.http.get(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/reservation/group`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  setGroups(data: ReservationCategoryGroup[], callback: () => any, onError: () => any) {
    this.http.put(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/reservation/group`, data)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  getSchedule(id: number,
    callback: (data: ReservationSchedule[]) => void, onError: (error: any) => void) {
    this.http.get(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/reservation/group/schedule/${id}`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  addSchedule(categoryId: number,
    reservationConfiguration: ReservationSchedule,
    callback: () => any, onError: () => any) {
    this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/reservation/group/schedule/${categoryId}`, reservationConfiguration)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  updateSchedule(categoryId: number,
    reservationConfiguration: ReservationSchedule,
    callback: () => any, onError: () => any) {
    this.http.put(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/reservation/group/schedule/${categoryId}/${reservationConfiguration.id}`, reservationConfiguration)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  deleteSchedule(groupId: number, scheduleId: number, callback: () => any, onError: () => any) {
    this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/reservation/group/schedule/${groupId}/${scheduleId}`, null)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }
}
