import { Component, OnInit } from '@angular/core';
import { Schedule } from 'projects/qcloud-models/multimedia/schedule.model';
import { MultimediaService } from 'projects/qcloud-rest-client/src/lib/multimedia.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Playlist } from 'projects/qcloud-models/multimedia/playlist.model';
import { NgForm, UntypedFormControl } from '@angular/forms';
import { PlaylistWithWeeklySchedule } from 'projects/qcloud-models/multimedia/playlist-with-weekly-schedulemodel';
import { Tag } from 'projects/qcloud-models/multimedia-device/tag.model';
import { PlaylistItem } from 'projects/qcloud-models/multimedia/playlistItem.model';
import { ScheduleTag } from 'projects/qcloud-models/multimedia/schedule-tag.model';
import { ScheduleTagsTypes } from 'projects/qcloud-models/multimedia/schedule-tags-types.enum';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { LocationData } from 'projects/qcloud-models/location/location.model';
import { DeviceType } from 'projects/qcloud-models/device/device-type.enum';

@Component({
  selector: 'app-edit-schedule',
  templateUrl: './edit-schedule.component.html',
  styleUrls: ['./edit-schedule.component.css']
})
export class EditScheduleComponent implements OnInit {

  schedule: Schedule;
  maxScheduleSize: number = 2 * 1024 * 1024 * 1024;
  playlists: Playlist[];
  tags: Tag[];
  tagMultiCtrl: UntypedFormControl;
  locations: LocationData[];
  locationMultiCtrl: UntypedFormControl;
  deviceTypes: DeviceType[];
  deviceTypeMultiCtrl: UntypedFormControl;

  isNeededToRefresh :boolean = true;
  formSubmitted: boolean = false;
  editForm: boolean = true;
  ctx = this;

  constructor(private multimediaService: MultimediaService, private route: ActivatedRoute, private toastr: ToastrService,
    private translate: TranslateService, private locationService: LocationService) { }

  ngOnInit(): void {
    let scheduleId: number;
    this.route.params.subscribe(params => {
      scheduleId = params['id']
    });
    this.getSchedule(scheduleId);
    this.getPlaylists();
  }

  getSchedule(scheduleId: number) {
    this.multimediaService.getSchedule(scheduleId).subscribe(
      schedule => {
        this.schedule = schedule;
        this.schedule.playlistsWithWeeklyDisplayInformation.sort((p1, p2) => p1.id - p2.id);
        if (this.schedule.defaultPlaylistId) {
          this.multimediaService.getPlaylistItems(this.schedule.defaultPlaylistId).subscribe(
            playlistItems => {
              this.schedule.defaultPlaylist.playlistItems = playlistItems;
            }
          );
        }
        if(this.isNeededToRefresh)
        {
          this.getTags();
          this.getLocations();
          this.getDeviceTypes();
        }
      },
      err => {}
    );
  }

  getPlaylists() {
    this.multimediaService.getPlaylists().subscribe((data) => {
      this.playlists = data;
    });
  }

  getTags() {
    this.multimediaService.getTags().subscribe((data) => {
      this.tags = data;
      let initialTags = new Array<Tag>();
      for (let i = 0; i < this.tags.length; i++) {
        if (this.schedule.scheduleTags.some(scheduleTag =>
          scheduleTag.id == this.tags[i].id && scheduleTag.scheduleTagsType == ScheduleTagsTypes.Regular)) {
          initialTags.push(this.tags[i]);
        }
      }
      this.tagMultiCtrl = new UntypedFormControl(initialTags);
    });
  }

  getLocations() {
    this.locationService.getAll().subscribe((data) => {
      this.locations = data;
      let initialLocations = new Array<LocationData>();
      for (let i = 0; i < this.locations.length; i++) {
        if (this.schedule.scheduleTags.some(scheduleTag =>
          scheduleTag.id == this.locations[i].id && scheduleTag.scheduleTagsType == ScheduleTagsTypes.SystemTagLocation)) {
          initialLocations.push(this.locations[i]);
        }
      }
      this.locationMultiCtrl = new UntypedFormControl(initialLocations);
    });
  }

  getDeviceTypes() {
    this.deviceTypes = [DeviceType.MainDisplay, DeviceType.DeskDisplay, DeviceType.TicketDispenser];
    let initialDeviceTypes = new Array<DeviceType>();
    for (let i = 0; i < this.deviceTypes.length; i++) {
      if (this.schedule.scheduleTags.some(scheduleTag =>
        scheduleTag.id == this.deviceTypes[i] && scheduleTag.scheduleTagsType == ScheduleTagsTypes.SystemTagDeviceType)) {
        initialDeviceTypes.push(this.deviceTypes[i]);
      }
    }
    this.deviceTypeMultiCtrl = new UntypedFormControl(initialDeviceTypes);
  }

  onSubmit(form: NgForm) {
    this.formSubmitted = true;
    let playlist = this.playlists.find(p => p.id == this.schedule.defaultPlaylistId);
    let sizeDifference = this.maxScheduleSize - this.calculateNewScheduleSize(playlist);
    if (sizeDifference > 0) {
      this.assignTagsToSchedule();
      this.multimediaService.updateSchedule(this.schedule).subscribe(
        res => {
          this.translate.get('schedule-update-success').subscribe((res: string) => {
            this.toastr.success(res);
          });
          this.getSchedule(this.schedule.id);
          this.formSubmitted = false;
        },
        err => {
          this.translate.get('schedule-update-error').subscribe((error: string) => {
            this.translate.get('repeat').subscribe((res: string) => {
              this.toastr.error(res, error);
            });
          });
          this.formSubmitted = false;
        }
      );
    } else {
      this.handleExceededSize(sizeDifference);
    }
  }

  assignTagsToSchedule() {
    let regularTags = this.tagMultiCtrl.value.map(tag => new ScheduleTag(tag.id, ScheduleTagsTypes.Regular));
    let deviceTypeTags = this.deviceTypeMultiCtrl.value.map(deviceType =>
      new ScheduleTag(deviceType, ScheduleTagsTypes.SystemTagDeviceType));
    let locationTags = this.locationMultiCtrl.value.map(location =>
      new ScheduleTag(location.id, ScheduleTagsTypes.SystemTagLocation));
    this.schedule.scheduleTags = regularTags.concat(locationTags, deviceTypeTags);    
    this.schedule.scheduleTagsJson = JSON.stringify(this.schedule.scheduleTags);
  }

  assignPlaylistToSchedule(playlistWithWeekly: PlaylistWithWeeklySchedule) {
    let playlist = this.playlists.find(p => p.id == playlistWithWeekly.mediaPlaylistId);
    let sizeDifference = this.maxScheduleSize - this.calculateNewScheduleSize(playlist);
    if (sizeDifference > 0) {
      this.multimediaService.addSchedulePlaylist(this.schedule.id, playlistWithWeekly).subscribe(
        res => {
          this.translate.get('assign-playlist-to-schedule-success').subscribe((res: string) => {
            this.toastr.success(res);
          });
          this.isNeededToRefresh = false;
          this.getSchedule(this.schedule.id);
        },
        err => {
          this.translate.get('assign-playlist-to-schedule-error').subscribe((error: string) => {
            this.translate.get('repeat').subscribe((res: string) => {
              this.toastr.error(res, error);
            });
          });
        }
      );
    } else {
      this.handleExceededSize(sizeDifference);
    }
  }

  handleExceededSize(sizeDifference: number) {
    sizeDifference = Math.round(-sizeDifference / (1024 * 1024));
      this.translate.get('maximum-size-of-schedule-was-exceeded').subscribe((error: string) => {
        this.translate.get('repeat').subscribe((res: string) => {
          this.toastr.error(res, error + sizeDifference + " MB");
        });
      });
  }

  deleteSchedulePlaylist(playlist: PlaylistWithWeeklySchedule) {
    this.multimediaService.deleteSchedulePlaylist(this.schedule.id, playlist.id).subscribe(
      res => {
        this.translate.get('delete-schedule-playlist-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
        this.getSchedule(this.schedule.id);
      },
      err => {
        this.translate.get('delete-schedule-playlist-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  editSchedulePlaylist(playlist: PlaylistWithWeeklySchedule) {
    this.multimediaService.editSchedulePlaylist(this.schedule.id, playlist).subscribe(
      res => {
        this.translate.get('edit-schedule-playlist-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
        this.getSchedule(this.schedule.id);
      },
      err => {
        this.translate.get('edit-schedule-playlist-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  calculateNewScheduleSize(newPlaylist: Playlist): number {
    let newScheduleSize = 0;
    let allPlaylists = this.getAllUniqePlaylistsFromSchedule(newPlaylist);
    let allFiles = this.getAllUniqeFilesFromSchedule(allPlaylists);
    allFiles.forEach(file => {
      newScheduleSize += file.size;
    });
    return newScheduleSize;
  }

  getAllUniqePlaylistsFromSchedule(newPlaylist: Playlist): Array<Playlist> {
    let allPlaylistsWithWeekly = this.schedule.playlistsWithWeeklyDisplayInformation; 
    let allPlaylists = new Array<Playlist>();
    allPlaylistsWithWeekly = allPlaylistsWithWeekly.filter((playlist, index, self) =>
      index === self.findIndex((p) => (p.mediaPlaylistId === playlist.mediaPlaylistId))
    );
    allPlaylistsWithWeekly.forEach(playlistWithWeekly => {
      let playlist = this.playlists.find(p => p.id == playlistWithWeekly.mediaPlaylistId);
      if (playlist) {
        allPlaylists.push(playlist);
      }
    });
    let defaultPlaylist = this.playlists.find(playlist => playlist.id == this.schedule.defaultPlaylistId);
    if (!allPlaylists.find(playlist => playlist.id == this.schedule.defaultPlaylistId) && defaultPlaylist) {
      allPlaylists.push(defaultPlaylist);
    }
    if (newPlaylist && !allPlaylists.find(playlist => playlist.id == newPlaylist.id)) {
      allPlaylists.push(newPlaylist);
    }
    return allPlaylists;
  }

  getAllUniqeFilesFromSchedule(allPlaylists: Array<Playlist>): Array<PlaylistItem> {
    let allFiles = new Array<PlaylistItem>();
    allPlaylists.forEach(playlist => {
      allFiles = allFiles.concat(playlist.playlistItems);
    });
    allFiles = allFiles.filter((file, index, self) =>
      index === self.findIndex((f) => (f.filePath === file.filePath))
    );
    return allFiles;
  }
}
