import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-warning-label',
  templateUrl: './warning-label.component.html',
  styleUrls: ['./warning-label.component.css']
})
export class WarningLabelComponent implements OnInit {

  @Input() warningMessageTranslation: string;

  constructor() { }

  ngOnInit(): void {}
}
