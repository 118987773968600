import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { Category } from 'projects/qcloud-models/category/category.model';
import { ColorService } from 'projects/qcloud-web/src/app/shared/color.service';

@Component({
  selector: 'app-queue-state-statistics',
  templateUrl: './queue-state-statistics.component.html',
  styleUrls: ['./queue-state-statistics.component.css']
})
export class QueueStateStatisticsComponent implements OnInit {

  @Input() queueState: Map<number, number>;
  @Input() categories: Category[];
  queueLength: number;
  backgroundColors: Array<string>;

  constructor(private colorService: ColorService) { }

  ngOnInit(): void {
    this.setChart();
  }

  setChart() {
    let ctx = document.getElementById("queueStatePieChart");
    let labels = this.categories.map(c => c.fullName);
    let data = new Array<number>();
    this.categories.forEach(category =>
    {
      data.push(this.queueState[category.id]);  
    });
    this.queueLength = data.reduce((a, b) => a + b, 0);
    let colors = this.colorService.generateRandomColors(this.categories.length, 20);
    this.backgroundColors = colors.map(x => x.secondary);
    let hoverBackgroundColors = colors.map(x => x.primary);
    let queueStatePieChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: [{
          data: data,
          backgroundColor: this.backgroundColors,
          hoverBackgroundColor: hoverBackgroundColors,
          hoverBorderColor: "rgba(234, 236, 244, 1)",
        }],
      },
      options: {
        maintainAspectRatio: false,
        tooltips: {
          backgroundColor: "rgb(255,255,255)",
          bodyFontColor: "#858796",
          borderColor: '#dddfeb',
          borderWidth: 1,
          xPadding: 15,
          yPadding: 15,
          displayColors: false,
          caretPadding: 10,
        },
        legend: {
          display: false
        },
        cutoutPercentage: 80,
        elements: {
          center: {
            text: this.queueLength,
            color: '#4e73df'
          }
        }
      }
    });
  }

}
