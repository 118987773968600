import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { LocationData } from 'projects/qcloud-models/location/location.model';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { TranslationService } from 'projects/qcloud-rest-client/src/lib/translation.service';
import { Language } from 'projects/qcloud-models/translation/language.model';
import { UntypedFormControl } from '@angular/forms';
import { Translation } from 'projects/qcloud-models/translation/translation.model';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { TranslationConfigurationService } from '../../../shared/translation-configuration.service';

@Component({
  selector: 'app-languages-and-translations',
  templateUrl: './languages-and-translations.component.html',
  styleUrls: ['./languages-and-translations.component.css']
})
export class LanguagesAndTranslationsComponent implements OnInit {

  locationData: LocationData;
  mainLanguage: Language;
  mainLanguageSelect: string;
  languages: Language[];
  translationLanguages: Language[];
  public translations: Translation[];
  initialLanguage: Language[];
  public languageMultiCtrl: UntypedFormControl;
  formSubmitted: boolean = false;
  disableMainLanguageSelection: boolean = true;

  constructor(
    private locationService: LocationService,
    private translationService: TranslationService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private authService: AuthService,
    private translationConfigurationService: TranslationConfigurationService)
    { }

  ngOnInit(): void {
    this.locationService.getLocationData(this.authService.getLocationId()).subscribe(
      async locationData => {
        this.locationData = locationData;
        this.mainLanguageSelect = locationData.locationLocaleMain;
        this.translationConfigurationService.languages.subscribe( data => {
          this.languages = data;
          this.mainLanguage = this.languages.find(x => x.locale === this.mainLanguageSelect);
          // cannot choose translation for main language
          this.translationLanguages = this.languages.filter(x => x.locale != this.locationData.locationLocaleMain);
          this.translationConfigurationService.updateMainLanguage(this.languages.find(x => x.locale === this.locationData.locationLocaleMain));
          this.getTranslations();
        }
        );
      }
    );
  }

  getTranslations() {
    this.translationService.getTranslations().subscribe((data) => {
      this.translations = data;
      this.translations = this.translations.sort((t1, t2) => t1.id - t2.id);
      this.initialLanguage = new Array<Language>();
      for (let j = 0; j < this.languages.length; j++) {
        if (this.translations.some(translation => translation.locale == this.languages[j].locale)) {
          this.initialLanguage.push(this.languages[j]);
        }
      }
      this.languageMultiCtrl = new UntypedFormControl(this.initialLanguage);
      this.translationConfigurationService.updateTranslationLanguages(this.translations);
    },
      () => {}
    );
  }

  onSubmit() {
    this.formSubmitted = true;
    this.locationData.locationLocaleMain = this.mainLanguageSelect;
    this.editLocationData();
    for (let i = 0; i < this.translations.length; i++) {
      if (!this.languageMultiCtrl.value.some(languageOption => languageOption.locale == this.translations[i].locale)) {
        this.translationService.deleteTranslation(this.translations[i].id).subscribe(
          res => {
            this.getTranslations();
            this.formSubmitted = false;
          });
      }
    }
    for (let i = 0; i < this.languageMultiCtrl.value.length; i++) {
      if (!this.translations.some(translation => translation.locale == this.languageMultiCtrl.value[i].locale)) {
        this.translationService.addTranslation(this.languageMultiCtrl.value[i]).subscribe(
          res => {
            this.getTranslations();
            this.formSubmitted = false;
          });
      }
    }
    this.translationConfigurationService.updateTranslationLanguages(this.translations);
    this.formSubmitted = false;
  }

  editLocationData() {
    this.locationService.editLocationData(this.locationData).subscribe(
      res => {
        this.translate.get('languages-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.translate.get('languages-error').subscribe((res: string) => {
          let error = res;
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

}
