import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { QcloudConsoleService } from '../../qcloud-console.service';

@Component({
  selector: 'lib-call-again-ticket-button',
  templateUrl: './call-again-ticket-button.component.html',
  styleUrls: ['./call-again-ticket-button.component.css',  './../base-button/base-button.component.css']
})
export class CallAgainTicketButtonComponent  implements OnInit {
  @Input() public tooltipTitle: string;
	@ViewChild('button') public button: ElementRef;
  @ViewChild('icon') public icon: ElementRef;
  isEnabled: boolean;
  ticketId: number;
  isBlocked: boolean;
  isLocked: boolean;

  constructor(private consoleService: QcloudConsoleService) {
    this.consoleService.ticket.subscribe((val) => {
      this.ticketId = val.id;
      if (this.ticketId != -1 && this.ticketId != 0) {
        this.isEnabled = true;
      }
      else {
        this.isEnabled = false;
      }
    })
    this.consoleService.isBlocked.subscribe((val) => {
      this.isBlocked = val;
    });
    this.consoleService.isLocked.subscribe((val) => {
      this.isLocked = val;
    });
  }

  ngOnInit(): void {
  }

  public callAgainTicket() {
    if (this.isEnabled && this.ticketId)
      this.consoleService.callAgainTicket(this.ticketId);
      this.consoleService.setIsBlockedStatus(false);
  }

}
