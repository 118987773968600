import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DeviceLayout } from 'projects/qcloud-models/device-layout';
import { LayoutType } from 'projects/qcloud-models/device/layout-type.enum';
import { RowStyle } from 'projects/qcloud-models/device/row-style';
import { DeviceLayoutClientService } from 'projects/qcloud-rest-client/src/lib/device-layout-client.service';

@Component({
  selector: 'app-device-layouts',
  templateUrl: './device-layouts.component.html',
  styleUrls: ['./device-layouts.component.css']
})
export class DeviceLayoutsComponent implements OnInit {
  public deviceLayouts: DeviceLayout[];
  constructor(private deviceLayoutservice: DeviceLayoutClientService,
    private translate: TranslateService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getDevicesLayouts();
  }

  getLayoutName(key: number) {
    return LayoutType[key];
  }

  getDevicesLayouts() {
    this.deviceLayoutservice.getDeviceLayouts().subscribe((data) => {
      this.deviceLayouts = data;
    }, () => { })
  }
}