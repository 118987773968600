import {Component, ChangeDetectionStrategy, ChangeDetectorRef, Input, SimpleChanges, OnChanges} from '@angular/core';
import { isSameDay, isSameMonth, startOfDay, endOfDay } from 'date-fns';
import { CalendarEvent, CalendarView, CalendarMonthViewBeforeRenderEvent, CalendarWeekViewBeforeRenderEvent,
  CalendarDayViewBeforeRenderEvent, CalendarEventTimesChangedEvent, CalendarEventTitleFormatter, CalendarDateFormatter} from 'angular-calendar';
import { EventTitleFormatter } from './event-title-formatter.provider';
import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import { RRule, Weekday } from 'rrule';
import { ViewPeriod } from 'calendar-utils';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { PlaylistWithWeeklySchedule } from 'projects/qcloud-models/multimedia/playlist-with-weekly-schedulemodel';
import { Playlist } from 'projects/qcloud-models/multimedia/playlist.model';
import { RecurringEvent } from 'projects/qcloud-models/multimedia/recurring-event';
import { Subject } from 'rxjs';
import { ColorService } from 'projects/qcloud-web/src/app/shared/color.service';
import { ScheduleDateFormatter } from 'projects/qcloud-web/src/app/shared/schedule-date-formatter.provider';

registerLocaleData(localePl);

@Component({
  selector: 'app-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
  providers: [
    {
      provide: CalendarEventTitleFormatter,
      useClass: EventTitleFormatter,
    },
    {
      provide: CalendarDateFormatter,
      useClass: ScheduleDateFormatter,
    }
  ],
})
export class CalendarComponent implements OnChanges {

  @Input() schedulePlaylists: PlaylistWithWeeklySchedule[];
  @Input() playlists: Playlist[];
  @Input() ctx;

  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  viewPeriod: ViewPeriod;
  recurringEvents: RecurringEvent[] = new Array<RecurringEvent>();
  calendarEvents: CalendarEvent[] = [];
  activeDayIsOpen: boolean = true;
  locale: string;
  refresh: Subject<any> = new Subject();

  constructor(private translate: TranslateService, private cdr: ChangeDetectorRef,
    private colorService: ColorService) {
    this.locale = this.translate.currentLang;
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.locale = event.lang;
      this.cdr.detectChanges();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.fillCalendarEvent(changes.schedulePlaylists.currentValue);
    this.refreshView();
  }

  fillCalendarEvent(schedulePlaylists: PlaylistWithWeeklySchedule[]) {
    this.recurringEvents = new Array<RecurringEvent>();
    let colors = this.colorService.generateRandomColors(schedulePlaylists.length, 80);
    this.colorService.setScheduleCalendarColors(colors);
    schedulePlaylists.forEach(schedulePlaylist => {
      let name = this.playlists.find((playlist) => playlist.id == schedulePlaylist.mediaPlaylistId).name;
      let startDate = new Date(schedulePlaylist.dateStart);
      let timeDate = new Date(schedulePlaylist.weeklySchedule.beginTime);
      startDate.setHours(timeDate.getUTCHours(), timeDate.getUTCMinutes());
      let endDate = new Date(schedulePlaylist.dateEnd);
      timeDate = new Date(schedulePlaylist.weeklySchedule.endTime);
      endDate.setHours(timeDate.getUTCHours(), timeDate.getUTCMinutes());
      let weekdays = this.getWeekdays(schedulePlaylist.weeklySchedule.daysOfWeek);
      let calendarEvent: RecurringEvent = {
        start: startDate,
        end: endDate,
        title: name,
        id: schedulePlaylist.id,
        color: colors[schedulePlaylists.indexOf(schedulePlaylist)],
        rrule: {
          freq: RRule.WEEKLY,
          byweekday: weekdays,
        }
      }
      this.recurringEvents.push(calendarEvent);
    });
  }

  refreshView() {
    this.viewPeriod = undefined;
    this.refresh.next(true);
  }

  getWeekdays(daysOfWeek: number[]): Weekday[] {
    let weekdays = new Array<Weekday>();
    daysOfWeek.forEach(dayOfWeek => {
      switch (dayOfWeek) {
        case 0:
          weekdays.push(RRule.SU)
          break;
        case 1:
          weekdays.push(RRule.MO)
          break;
        case 2:
          weekdays.push(RRule.TU)
          break;
        case 3:
          weekdays.push(RRule.WE)
          break;
        case 4:
          weekdays.push(RRule.TH)
          break;
        case 5:
          weekdays.push(RRule.FR)
          break;
        case 6:
          weekdays.push(RRule.SA)
          break;
        default:
          break;
      }
    });
    return weekdays;
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (events.length != 0) {
      if (isSameMonth(date, this.viewDate)) {
        if (
          (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
          events.length === 0
        ) {
          this.activeDayIsOpen = false;
        } else {
          this.activeDayIsOpen = true;
        }
        this.viewDate = date;
      }
    } else {
      this.ctx.managePlaylist(this.playlists);
    }

  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  eventTimesChanged({event, newStart, newEnd}: CalendarEventTimesChangedEvent): void {
    let playlist = this.getPlaylist(event);
    playlist.weeklySchedule.beginTime = newStart;
    playlist.weeklySchedule.endTime = newEnd;
    this.ctx.editPlaylistTime(playlist);
  }

  managePlaylist(event: CalendarEvent) {
    let playlist = this.getPlaylist(event);
    this.ctx.managePlaylist(this.playlists, playlist);
  }

  getPlaylist(event: CalendarEvent): PlaylistWithWeeklySchedule {
    return this.schedulePlaylists.find(playlist => playlist.id == event.id);
  }

  updateCalendarEvents(
    viewRender:
      | CalendarMonthViewBeforeRenderEvent
      | CalendarWeekViewBeforeRenderEvent
      | CalendarDayViewBeforeRenderEvent
  ): void {
    if (!this.viewPeriod ||
      this.viewPeriod.start.getTime() != viewRender.period.start.getTime() ||
      this.viewPeriod.end.getTime() != viewRender.period.end.getTime())
    {
      this.viewPeriod = viewRender.period;
      this.calendarEvents = [];

      this.recurringEvents.forEach((event) => {
        const { title, color, start, end, id } = event;

        const rule: RRule = new RRule({
          ...event.rrule,
          dtstart: new Date(startOfDay(start).setHours(2)),
          until: new Date(endOfDay(end).setHours(2)),
        });

        rule.all().forEach((date) => {
          let calendarEvent: CalendarEvent = {title: title, color: color, id: id,
            start: new Date(date.setHours(start.getHours(), start.getMinutes())),
            end: new Date(date.setHours(end.getHours(), end.getMinutes())),
            draggable: true,
            resizable: {
              beforeStart: true,
              afterEnd: true,
            }}
          this.calendarEvents.push(calendarEvent);
        });
      });
      this.cdr.detectChanges();
    }
  }
}
