import { Component, OnInit } from '@angular/core';
import { Specialisation } from 'projects/qcloud-models/grafik/specialisation/specialisation.model';
import { SpecialisationService } from 'projects/qcloud-rest-client/src/lib/grafik/specialisation.service';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { RouteNavigationService } from 'projects/qcloud-web/src/app/shared/route-navigation.service';


@Component({
  selector: 'app-speciality',
  templateUrl: './speciality.component.html',
  styleUrls: ['./speciality.component.css']
})
export class SpecialityComponent implements OnInit {

  speciality: Specialisation;
  isEditForm: boolean;
  formSubmitted: boolean = false;


  constructor(private authService: AuthService, private translate: TranslateService, private toastr: ToastrService,
    private specialityService: SpecialisationService, public router: RouteNavigationService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    var specialityId: number;
    this.route.params.subscribe(params => {
      specialityId = params['id']
    });
    if (specialityId) {
      this.isEditForm = true;
      this.getSpeciality(specialityId);
    } else {
      this.isEditForm = false;
      this.initializeSpeciality();
    }
  }

  initializeSpeciality() {
    this.speciality = new Specialisation();
    this.speciality.locationId = +this.authService.getLocationId();
    this.speciality.systemId = +this.authService.getSystemId();
  }

  getSpeciality(specialityId: number) {
    this.specialityService.getSpeciality(specialityId).subscribe(speciality => {
      this.speciality = speciality;
    })
  }

  onEnterPress(event, isFormInvalid) {
    event.preventDefault();
    if (!isFormInvalid) {
      this.onSubmit();
    }
  }

  onSubmit() {
    this.formSubmitted = true;
    if (this.isEditForm) {
      this.editSpeciality();
    } else {
      this.addSpeciality();
    }
  }


  addSpeciality() {
    this.speciality.id = 0;
    this.specialityService.addSpeciality(this.speciality).subscribe(
      res => {
        this.router.navigate(['workSchedule']);
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('speciality-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  editSpeciality() {
    this.specialityService.editSpeciality(this.speciality).subscribe(
      res => {
        this.router.navigate(['workSchedule']);
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('speciality-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }
}
