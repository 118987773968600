<div class="row mt-4">
    <div class="col-sm-12 col-md-8">
        <div class="card shadow mb-3">
            <div class="card-header py-2 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary">{{ 'console.ticket-service' | translate}}</h6>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-5 mb-2">
                        <lib-display-ticket></lib-display-ticket>
                    </div>
                    <div class="col-sm-7">
                        <div class="row">
                            <lib-call-eticket-button class="col-sm-12 col-md-6 mb-1 pr-md-1">
                            </lib-call-eticket-button>
                            <lib-redirect-ticket-button class="col-sm-12 col-md-6 mb-1 pr-md-1">
                            </lib-redirect-ticket-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 col-sm-12">
        <div class="mb-3">
            <div class="card shadow">
                <div class="card-header py-2 d-flex flex-row align-items-center justify-content-between">
                    <h6 class="m-0 font-weight-bold text-primary">{{ 'queue-state' | translate}}</h6>
                    <lib-queue-state-all-tickets></lib-queue-state-all-tickets>
                </div>
                <div class="card-body d-flex justify-content-center">
                    <lib-queue-state></lib-queue-state>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-4 col-md-4">
        <lib-interaction-card></lib-interaction-card>
    </div>
    <div class="col-sm-12 col-md-8">
        <div class="card shadow w-100 mb-3">
            <div class="card-header py-2 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary">{{ 'console.manual-call' | translate}}</h6>
            </div>
            <div class="card-body d-flex justify-content-center">
                <lib-call-ticket-manual-modal></lib-call-ticket-manual-modal>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-4">
        <div class="card shadow mb-3">
            <div class="card-header py-2 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary">{{ 'console.desk-data' | translate}}</h6>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12 col-lg-6">
                        <div class="font-weight-bold">
                            <lib-display-desk></lib-display-desk>
                        </div>
                        <lib-desk-status></lib-desk-status>
                    </div>
                    <div class="col-md-12 col-lg-6">
                        <div class="font-weight-bold">
                            <lib-display-user></lib-display-user>
                        </div>
                        <lib-logout></lib-logout>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
