import { Component, Input, OnInit } from '@angular/core';
import { Language } from 'projects/qcloud-models/translation/language.model';
import { Translation } from 'projects/qcloud-models/translation/translation.model';

@Component({
  selector: 'app-reservation-translation',
  templateUrl: './reservation-translation.component.html',
  styleUrls: ['./reservation-translation.component.css']
})
export class ReservationTranslationComponent implements OnInit {
  
  @Input() translations: Translation[];
  @Input() languages: Language[];
  @Input() locationLocaleMain: string;
  isCollapsed: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
