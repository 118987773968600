import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocationConstraints } from 'projects/qcloud-models/location/location-constraints';
import { Observable } from 'rxjs';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  appBase: string;

  constructor(private http: HttpClient, private authService: AuthService) { }

  public setAppBase(appBase: string) {
    this.appBase = appBase;
  }

  getLocationsLeft(): Observable<number> {
    return this.http.get<number>(`${this.appBase}api/${this.authService.getSystemId()}/license/locations`);
  }

  getDevicesLeft(): Observable<number> {
    return this.http.get<number>(
      `${this.appBase}api/${this.authService.getSystemId()}/license/${this.authService.getLocationId()}/devices`);
  }

  getLocationsConstraints(): Observable<LocationConstraints> {
    return this.http.get<LocationConstraints>(`${this.appBase}api/${this.authService.getSystemId()}/license/${this.authService.getLocationId()}/constraints`);
  }
}
