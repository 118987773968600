<div class="container-two-columns" *ngIf="quantityOfColumns == 2">
    <div *ngFor="let ticket of ticketsToShow | slice:0:MAX_TICKETS; index as i" style="height: 100%;">
        <app-full-bar-compatible [active]="false" [content]="ticket.caption" [rowStyle]="rowStyle"
            *ngIf="ticket.caption != undefined" [rowHeight]="rowHeight">
        </app-full-bar-compatible>
    </div>
</div>
<div class="container-three-columns" *ngIf="quantityOfColumns == 3">
    <app-full-bar-compatible *ngFor="let ticket of ticketsToShow | slice:0:MAX_TICKETS; index as i" [active]="false"
        [content]="ticket.caption" [rowStyle]="rowStyle">
    </app-full-bar-compatible>
</div>