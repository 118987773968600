import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PauseTicket } from 'projects/qcloud-models/console/pauseTicket';
import { QcloudConsoleService } from '../../qcloud-console.service';

@Component({
  selector: 'lib-pause-ticket-modal',
  templateUrl: './pause-ticket-modal.component.html',
  styleUrls: ['./pause-ticket-modal.component.css']
})
export class PauseTicketModalComponent implements OnInit {
  @Output() hideInteractionModel = new EventEmitter<boolean>();
  pause: PauseTicket;
  infinitePause: boolean;

  constructor(private consoleService: QcloudConsoleService) {
    this.pause = new PauseTicket();
  }

  ngOnInit(): void {
  }

  changePauseTime() {
    if (this.infinitePause) {
      this.pause.pauseTime = 0;
    } else {
      this.pause.pauseTime = 1;
    }
  }

  pauseTicket() {
    this.consoleService.pasueTicket(this.pause);
    this.hideInteractionModel.emit(true);
  }

}
