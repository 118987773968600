import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Language } from 'projects/qcloud-models/translation/language.model';
import { Translation } from 'projects/qcloud-models/translation/translation.model';
import { TicketLayout } from 'projects/qcloud-models/location/ticket-layout.model';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { TranslationService } from 'projects/qcloud-rest-client/src/lib/translation.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { TicketTranslation } from 'projects/qcloud-models/translation/ticket-translation';
import { TranslationConfigurationService } from 'projects/qcloud-web/src/app/shared/translation-configuration.service';

@Component({
  selector: 'app-ticket-translation',
  templateUrl: './ticket-translation.component.html',
  styleUrls: ['./ticket-translation.component.css',
  '../languages-and-translations.component.css']
})
export class TicketTranslationComponent implements OnInit {

  @Input() translations: Translation[];
  @Input() languages: Language[];
  @Input() locationLocaleMain: string;
  startIndex: number;
  ticketLayout: TicketLayout;
  ticketTranslations: TicketTranslation[];
  isCollapsed: boolean = false;
  formSubmitted: boolean = false;

  constructor(
    private locationService: LocationService, 
    private service: TranslationService,
    private translate: TranslateService, 
    private toastr: ToastrService,
    private translationConfigurationService: TranslationConfigurationService
    ) { }

  ngOnInit(): void {
    this.locationService.getTicketLayout().subscribe(
      ticketLayout => {
        this.ticketLayout = ticketLayout;
      }
    );
    this.translationConfigurationService.translationLanguages.subscribe(translations => {
      this.translations = translations;
      this.getTicketTranslations();
    });
    this.getTicketTranslations();
  }

  getTicketTranslations() {
    this.service.getTicketTranslation().subscribe(
      res => {
        this.ticketTranslations = new Array<TicketTranslation>();
        for (let i = 0; i < this.translations.length; i++) {
          let pT = res.find(pT => pT.translationId == this.translations[i].id);
          this.ticketTranslations.push(pT);
        }
      }
    );
  }

  getLocaleName(locale: string) {
    return this.languages.find(languageOption => languageOption.locale == locale).name;
  }

  deleteLocaleMain(translations: Translation[]) {
    return translations.filter(translation => translation.locale != this.locationLocaleMain);
  }

  onSubmit() {
    this.formSubmitted = true;
    this.ticketTranslations.forEach(personalisationTranslation => {
      personalisationTranslation.isDefault = false;
      personalisationTranslation.translationWordsJson =
        JSON.stringify(personalisationTranslation.translationWords);  
    });
    this.service.setTicketTranslation(this.ticketTranslations).subscribe(
      res => {
        this.formSubmitted = false;
        this.getTicketTranslations();
        this.translate.get('ticket-translation-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('ticket-translation-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  backToDefaultTranslation(ticketTranslation: TicketTranslation) {
    this.service.deleteTicketTranslation(ticketTranslation.id).subscribe(
      res => {
        this.getTicketTranslations();
        this.translate.get('ticket-translation-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.translate.get('ticket-translations-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  getFilteredTranslations(startIndex: number) {
    this.startIndex = startIndex;
  }

  isOnPage() {
    return this.translations.slice(this.startIndex, this.startIndex + 2);
  }

}
