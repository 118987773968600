<div class="row">
    <mat-form-field class="col-sm-6 col-md-4 col-lg-3 report-scope">
        <mat-label>{{ 'scope' | translate }}</mat-label>
        <mat-select matNativeControl name="scope" #scope="ngModel" [(ngModel)]="reportTemplateParameter.reportScope" required>
            <mat-option [value]="ReportScope.Desks">{{ 'desks' | translate }}</mat-option>
            <mat-option [value]="ReportScope.Categories">{{ 'categories' | translate }}</mat-option>
            <mat-option [value]="ReportScope.Users">{{ 'users' | translate }}</mat-option>
        </mat-select>
    </mat-form-field>

    <div class="form-group col-sm-6 col-md-4 col-lg-3">
        <div class="item-group">
            <mat-checkbox name="ticketCount" #ticketCount="ngModel" [(ngModel)]="reportTemplateParameter.ticketCount"
                color="primary">{{ 'ticket-count' | translate }}</mat-checkbox>
        </div>
        <div class="item-group">
            <mat-checkbox name="serviceWaitingTime" #serviceWaitingTime="ngModel" [(ngModel)]="reportTemplateParameter.serviceWaitingTime"
                color="primary">{{ 'operation-times' | translate }}</mat-checkbox>
        </div>
        <div class="item-group">
            <mat-checkbox name="pause" #pause="ngModel" [(ngModel)]="reportTemplateParameter.pause"
                color="primary">{{ 'paused-tickets' | translate }}</mat-checkbox>
        </div>
        <div class="item-group">
            <mat-checkbox name="redirection" #redirection="ngModel" [(ngModel)]="reportTemplateParameter.redirection"
                color="primary">{{ 'redirected-tickets' | translate }}</mat-checkbox>
        </div>

        <span *ngIf="reportTemplateParameter.reportScope != ReportScope.Categories">
            <div class="item-group">
                <mat-checkbox name="loginTime" #loginTime="ngModel" [(ngModel)]="reportTemplateParameter.loginTime"
                    color="primary">{{ 'login-time' | translate }}</mat-checkbox>
            </div>
            <div class="item-group">
                <mat-checkbox name="deskStatusTime" #deskStatusTime="ngModel" [(ngModel)]="reportTemplateParameter.deskStatusTime"
                    color="primary">{{ 'desk-status-time' | translate }}</mat-checkbox>
            </div>
        </span>

        <span *ngIf="reportTemplateParameter.reportScope == ReportScope.Desks">
            <div class="item-group">
                <mat-checkbox *ngIf="locationService.getLocationConstraints().isClientSurveyEnabled" name="opinions" #opinions="ngModel" [(ngModel)]="reportTemplateParameter.opinions"
                    color="primary">{{ 'customer-survey' | translate }}
                </mat-checkbox>
            </div>
            <div class="item-group">
                <mat-checkbox *ngIf="!locationService.getLocationConstraints().isClientSurveyEnabled" disabled
                    class="font-weight-bold" matTooltipPosition="right" matTooltipClass="tooltip-orange"
                    matTooltip="{{ 'option-available-after-extending-licence' | translate }}">
                    {{ 'customer-survey' | translate }}
                </mat-checkbox>
            </div>
        </span>

        <span *ngIf="reportTemplateParameter.reportScope == ReportScope.Categories">
            <div class="item-group">
                <mat-checkbox *ngIf="locationService.getLocationConstraints().areEnabledTicketsReservations" name="ticketReservations" #ticketReservations="ngModel" [(ngModel)]="reportTemplateParameter.ticketReservations"
                    color="primary">{{ 'reservation-statistics' | translate }}
                </mat-checkbox>
                <mat-checkbox *ngIf="!locationService.getLocationConstraints().areEnabledTicketsReservations" disabled
                    class="font-weight-bold" matTooltipPosition="right" matTooltipClass="tooltip-orange"
                    matTooltip="{{ 'option-available-after-extending-licence' | translate }}">
                    {{ 'reservation-statistics' | translate }}
                </mat-checkbox>
            </div>
        </span>

    </div>

    <div class="form-group col-sm-6 col-md-4 col-lg-2">
        <div class="item-group">
            <mat-checkbox name="onHour" #onHour="ngModel" [(ngModel)]="reportTemplateParameter.onHour"
                color="primary">{{ 'on-hour' | translate }}</mat-checkbox>
        </div>
    </div>

    <div class="col-sm-6 col-md-4 col-lg-3">
        <app-served-categories *ngIf="categoryMultiCtrl && categories && reportTemplateParameter.reportScope == ReportScope.Categories"
            [categoryMultiCtrl]="categoryMultiCtrl" [categories]="categories" [title]="servedCategories"
            [noSelectedCategories]="noSelectedCategories" [displayCategories]="true" (categoriesToEmit)="selectCategories($event)">
        </app-served-categories>

        <app-served-desks *ngIf="deskMultiCtrl && reportTemplateParameter.reportScope == ReportScope.Desks"
            [deskMultiCtrl]="deskMultiCtrl" [desks]="desks" [title]="servedDesks" [noSelectedDesks]="noSelectedDesks" [displayDesks]="true"
            (desksToEmit)="selectDesks($event)">
        </app-served-desks>

        <app-served-users *ngIf="userMultiCtrl && reportTemplateParameter.reportScope == ReportScope.Users"
            [userMultiCtrl]="userMultiCtrl" [users]="users" [title]="servedUsers" [noSelectedUsers]="noSelectedUsers" [displayUsers]="true"
            (usersToEmit)="selectUsers($event)">
        </app-served-users>
    </div>

</div>
