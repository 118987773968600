import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import {
  CalendarEvent, CalendarView, CalendarMonthViewBeforeRenderEvent, CalendarEventTitleFormatter, CalendarDateFormatter
} from 'angular-calendar';
import { Category } from 'projects/qcloud-models/category/category.model';
import { GetAvailableTimesParameter } from 'projects/qcloud-models/reservation/get-available-times-parameter';
import { ReservationMonthCapacity } from 'projects/qcloud-models/reservation/reservation-month-capacity';
import { TicketReservation } from 'projects/qcloud-models/reservation/ticket-reservation';
import { ReservationService } from 'projects/qcloud-rest-client/src/lib/reservation.service';
import { Subject } from 'rxjs';
import { ColorService } from '../../../shared/color.service';
import { ScheduleDateFormatter } from '../../../shared/schedule-date-formatter.provider';
import { ReservationModalComponent } from './reservation-modal/reservation-modal.component';
import { ReservationTitleFormatter } from './reservation-title-formatter.provider';

@Component({
  selector: 'app-reservation-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './reservation-calendar.component.html',
  styleUrls: ['./reservation-calendar.component.css'],
  providers: [
    DatePipe,
    {
      provide: CalendarEventTitleFormatter,
      useClass: ReservationTitleFormatter,
    },
    {
      provide: CalendarDateFormatter,
      useClass: ScheduleDateFormatter,
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class ReservationCalendarComponent implements OnInit {
  modalAvalaibleTimes: TicketReservation[];

  @ViewChild(ReservationModalComponent)
  private modal: ReservationModalComponent;

  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  private _viewDate: Date = new Date();
  set viewDate(value: Date) {
    this._viewDate = value;
    if (this.view == CalendarView.Month) {
      this.refreshCapacity();
    }
    if (this.view == CalendarView.Week) {
      this._viewDate = this.getSunday(this.viewDate);
      this.getListForNextDays(7);
    }
    else {
      this.getListForNextDays(1);
    }
  }
  get viewDate() {
    return this._viewDate;
  }
  calendarEvents: CalendarEvent<TicketReservation>[] = [];
  locale: string;
  refresh: Subject<any> = new Subject();
  _choosenCategory: Category;
  reservationColorLate: any;
  reservationColorUsed: any;
  reservationColorFree: any;
  reservationColorToBe: any;
  dayStartHour: number;
  dayEndHour: number;
  hourSegments: number;
  @Input() set choosenCategory(value: Category) {
    this._choosenCategory = value;
    if (this.view == CalendarView.Month) {
      this.refreshCapacity();
    }
    if (this.view == CalendarView.Week) {
      this._viewDate = this.getSunday(this.viewDate);
      this.getListForNextDays(7);
    }
    else {
      this.getListForNextDays(1);
    }
  }
  reservationMonthCapacity: ReservationMonthCapacity;
  ticketReservations: TicketReservation[];

  constructor(private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private reservationService: ReservationService,
    private datePipe: DatePipe,
    private colorService: ColorService) {
    this.locale = this.translate.currentLang;
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.locale = event.lang;
      this.cdr.detectChanges();
    });
  }

  ngOnInit(): void {
    let colorLate = "#e74a3b";
    let colorUsed = "#0000FF";
    let colorFree = "#1cc88a";
    let colorToBe = "#4e73df";
    //66 is alpha channel
    this.reservationColorLate = { primary: colorLate, secondary: this.colorService.increaseBrightness(colorLate, 18) + "66" };
    this.reservationColorUsed = { primary: colorUsed, secondary: this.colorService.increaseBrightness(colorUsed, 18) + "66" };
    this.reservationColorFree = { primary: colorFree, secondary: this.colorService.increaseBrightness(colorFree, 18) + "66" };
    this.reservationColorToBe = { primary: colorToBe, secondary: this.colorService.increaseBrightness(colorToBe, 18) + "66" };

  }

  onReservationClick(event: CalendarEvent<TicketReservation>): void {
    if(event.start < new Date())
      return;
    this.modal.open(this._choosenCategory, event,()=>{
      if (this.view == CalendarView.Day) {
        this.getListForNextDays(1);
      }
      if (this.view == CalendarView.Week) {
        this._viewDate = this.getSunday(this.viewDate);
        this.getListForNextDays(7);
      }
    });
  }

  refreshCapacity() {
    if (this._choosenCategory == null) {
      return;
    }
    if (this.view == CalendarView.Day) {
      return;
    }
    this.reservationMonthCapacity = new ReservationMonthCapacity();
    this.reservationMonthCapacity.capacity = new Array();
    let availableTimesParameter = new GetAvailableTimesParameter(this._choosenCategory.id, this.viewDate);
    this.reservationService.getMonthCapacityForCategory(availableTimesParameter, (data) => {
      this.reservationMonthCapacity = data;
      this.refresh.next(true);
    }, () => { });
  }

  getListForNextDays(dayCount: number) {
    if (this._choosenCategory == null) {
      return;
    }
    if (this.view == CalendarView.Month) {
      return;
    }
    let date = this.datePipe.transform(this.viewDate, "yyyy-MM-dd");
    var isOnHourReservationType = false;
    this.reservationService.getReservationListForNextDays(this._choosenCategory.id, date, dayCount, isOnHourReservationType, (data) => {
      this.ticketReservations = data;
      this.refillCalendarEvent();
      this.refresh.next(true);
    }, () => { });
  }

  refillCalendarEvent() {
    let minHour = 24;
    let maxHour = 0;
    let minutesDiff = 1440;
    this.calendarEvents = new Array();
    this.ticketReservations.forEach(reservation => {
      let reservationTime = new Date(reservation.reservationTime);
      let endOfReservation = new Date(reservationTime);
      endOfReservation.setMinutes(reservationTime.getMinutes() + reservation.length);
      minHour = Math.min(minHour, reservationTime.getHours());
      maxHour = Math.max(maxHour, endOfReservation.getHours());
      var diff = Math.abs(reservationTime.getTime() - endOfReservation.getTime());
      minutesDiff = Math.min(Math.round(diff / 60000), minutesDiff);
      let event: CalendarEvent;
      if (reservation.code == null && reservationTime > new Date()) {
        event = {
          start: reservationTime,
          end: endOfReservation,
          title: "Wolny termin",
          color: this.reservationColorFree,
          meta: reservation,
          id: reservation.id
        }
      }
      else if (reservation.used) {
        event = {
          start: reservationTime,
          end: endOfReservation,
          title: "Wykorzystane",
          color: this.reservationColorUsed,
          meta: reservation,
          id: reservation.id
        }
      }
      else if (reservationTime < new Date()) {
        event = {
          start: reservationTime,
          end: endOfReservation,
          title: "Spóżniona rezerwacja",
          color: this.reservationColorLate,
          meta: reservation,
          id: reservation.id
        }
      }
      else {
        event = {
          start: reservationTime,
          end: endOfReservation,
          title: reservation.code,
          color: this.reservationColorToBe,
          meta: reservation,
          id: reservation.id
        }
      }
      this.calendarEvents.push(event);
    });
    if (minHour > maxHour) {
      return;
    }
    this.dayStartHour = Math.max(minHour, 0);
    this.dayEndHour = Math.min(maxHour, 24);
    var rest = minutesDiff % 60;
    if (rest == 0) {
      this.hourSegments = 1;
    }
    else if (rest > 60) {
      this.hourSegments = 1;
    }
    else {
      this.hourSegments = 60 / rest;
    }
  }

  dayClicked({ date }: { date: Date; events: CalendarEvent[] }): void {
    this.view = CalendarView.Day;
    this.viewDate = date;
  }

  setView(view: CalendarView) {
    this.view = view;
    if (this.view == CalendarView.Day) {
      this.getListForNextDays(1);
    }
    if (this.view == CalendarView.Week) {
      this._viewDate = this.getSunday(this.viewDate);
      this.getListForNextDays(7);
    }
    else if (this.view == CalendarView.Month) {
      this.refreshCapacity();
    }
  }

  getSunday(d: Date): Date {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day;
    return new Date(d.setDate(diff));
  }

  beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
    let ctx = this;
    renderEvent.body.forEach((day) => {
      if (ctx.reservationMonthCapacity && day.inMonth) {
        const dayOfMonth = day.date.getDate();
        if (this.reservationMonthCapacity.capacity[dayOfMonth - 1] == undefined) {
          return;
        }
        switch (this.reservationMonthCapacity.capacity[dayOfMonth - 1]) {
          case 0: {
            day.cssClass = 'bg-red';
            break;
          }
          case 1: {
            day.cssClass = 'bg-orange';
            break;
          }
          case 2: {
            day.cssClass = 'bg-yellow';
            break;
          }
          case 3: {
            day.cssClass = 'bg-green';
            break;
          }
          case -1: {
            day.cssClass = "bg-gray";
          }
        }
      }
    });
  }
}
