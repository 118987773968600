import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FileUploader } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { GrafikFileService } from 'projects/qcloud-rest-client/src/lib/grafik/grafik-file.service';

@Component({
  selector: 'app-logo-uploader',
  templateUrl: './logo-uploader.component.html',
  styleUrls: ['./logo-uploader.component.css']
})
export class LogoUploaderComponent implements OnInit {

  @Input() path: string;
  @Input() clearUploaderQueue: boolean;
  uploader: FileUploader;
  @Output() getFolderContent = new EventEmitter();
  @Output() unsetClearingUploaderQueue = new EventEmitter();

  constructor(private service: GrafikFileService, private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.uploader = new FileUploader({
      isHTML5: true,
      url: this.service.getUploadAddress(),
      maxFileSize: 500 * 1024 * 1024
    });
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploader.onWhenAddingFileFailed = (item, filter) => {
      if (filter.name == 'fileSize') {
        this.translate.get('send-file-error').subscribe((error: string) => {
          this.translate.get('maximum-allowed-size-of-the-transmitted-file').subscribe((res: string) => {
            this.toastr.error(res, error + item.name);
          });
        });
      } else {
        this.translate.get('send-file-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error + item.name);
          });
        });
      }
    };
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      form.append('path', this.path);
    };
    this.uploader.onCompleteAll= () => {
      this.getContent();
      setTimeout(this.getContent, 1000);
    }
  }

  getContent() {
    this.getFolderContent.emit("");
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.path) {
      this.path = changes.path.currentValue;
      if(this.uploader) {
        this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
          form.append('path', this.path);
        };
      }
    }
    if (changes.clearUploaderQueue) {
      this.clearUploaderQueue = changes.clearUploaderQueue.currentValue;
      if (this.clearUploaderQueue)
      {
        this.uploader.clearQueue();
        this.unsetClearingUploaderQueue.emit("");
      }
    }
  }

}
