export class DisplayCategoryGroup {
    id: number;
    systemId: number;
    name: string;
    fontColorHex: string;
    borderColorHex: string;
    borderSize: number;
    borderRadius: number;
    backgroundType: number;
    backgroundImgPath: string;
    backgroundColorHex: string;
    backgroundDeviceImgPath: string;
    onActiveBackgroundColorHex: string;
  }