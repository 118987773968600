import { QcloudRole } from "../qcloud-role/qcloud-role";

export class User {
    public id: string;
    public systemId: number;
    public firstName: string;
    public lastName: string;
    public userName: string;
    public ldapDistinguishedName: string;
    public email: string;
    public qcloudRoles: QcloudRole[];
    public mainRole: QcloudRole;
    public role: string;
    public active: boolean;
    public isLdapUser: boolean;
}
