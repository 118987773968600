import { Component, OnInit } from '@angular/core';
import { DeskStatus } from 'projects/qcloud-models/desk/desk-status.enum';
import { QcloudConsoleService } from '../../qcloud-console.service';

@Component({
  selector: 'lib-desk-status',
  templateUrl: './desk-status.component.html',
  styleUrls: ['./desk-status.component.css']
})
export class DeskStatusComponent implements OnInit {

  DeskStatus = DeskStatus;
  choosenStatus: DeskStatus;

  constructor(private consoleService: QcloudConsoleService) {
    consoleService.deskStatus.subscribe((val: DeskStatus) => { this.choosenStatus = val; });
  }

  ngOnInit(): void {
    this.choosenStatus = this.consoleService.desk.deskStatus;
  }

  changeDeskStatus() {
    this.consoleService.changeDeskStatus(this.choosenStatus);
    if (this.choosenStatus == DeskStatus.Closed || this.choosenStatus == DeskStatus.Pause)
    {
      this.consoleService.setIsBlockedStatus(true);
    }
    else
    {
      this.consoleService.setIsBlockedStatus(false);
    }
  }

}
