import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Authentication } from 'projects/qcloud-models/email/authentication.enum';
import { EmailConfig } from 'projects/qcloud-models/email/email-config.model';
import { EmailConfigService } from 'projects/qcloud-rest-client/src/lib/email-config.service';

@Component({
  selector: 'app-email-config',
  templateUrl: './email-config.component.html',
  styleUrls: ['./email-config.component.css']
})
export class EmailConfigComponent implements OnInit {

  emailConfig: EmailConfig;
  formSubmitted: boolean = false;
  testMailFormSubmitted: boolean = false;
  Authentication = Authentication;
  @Input() testMailAddress: string;

  constructor(private emailConfigService: EmailConfigService, private translate: TranslateService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getEmailConfig();
  }

  getEmailConfig() {
    this.emailConfigService.getEmailConfig().subscribe(
      emailConfig => {
        this.emailConfig = emailConfig;
      }
    );
  }

  onSubmit() {
    this.emailConfigService.setEmailConfig(this.emailConfig).subscribe(
      res => {
        this.formSubmitted = false;
        this.translate.get('update-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('update-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  onTestMail() {
    this.emailConfigService.sendTestEmail(this.testMailAddress).subscribe(
      res => {
        this.testMailFormSubmitted = false;
        this.translate.get('send-test-mail-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.testMailFormSubmitted = false;
        this.translate.get('send-test-mail-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  updateAuthenticationMethod() {
    switch (this.emailConfig.port) {
      case 25:
        this.emailConfig.authentication = Authentication.None;
        break;
      case 465:
          this.emailConfig.authentication = Authentication.SSL;
          break;
      case 587:
        this.emailConfig.authentication = Authentication.STARTTLS;
        break;
      default:
        break;
    }
  }
}
