import { DeskService } from 'projects/qcloud-rest-client/src/lib/desk.service';
import { Category } from './category.model';

export class CategoryTable {
    public id: number;
    public fullName: string;
    public shortName: string;
    public categoryTag: string;
    public deskNumbers: string[];

    constructor(category: Category, deskService: DeskService) {
        this.id = category.id;
        this.fullName = category.fullName;
        this.shortName = category.shortName;
        this.categoryTag = category.categoryTag;
        this.deskNumbers = new Array<string>();
        deskService.getDesks(
            (desks) => {
                desks.forEach(desk => {
                    if (desk.categories.find(x => x.id == this.id)) {
                        this.deskNumbers.push(desk.deskNumber);
                    }
                });
            },
            () => { }
        );
    }
}
