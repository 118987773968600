import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject, ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-single-picker',
  templateUrl: './single-picker.component.html',
  styleUrls: ['./single-picker.component.css']
})
export class SinglePickerComponent implements OnInit {

  @Input() elements: any;

  @Input() elementMultiCtrl: UntypedFormControl;
  public elementMultiFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredElementsMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  protected _onDestroy = new Subject<void>();

  @Input() title: string;
  @Input() noSelectedElements: string;
  @Input() displayElements: boolean;
  @Input() placeholder: string;
  @Input() nomatch: string;

  @Output() elementsToEmit = new EventEmitter<any[]>();
  constructor() { }

  ngOnInit(): void {
    // load the initial elements list
    this.filteredElementsMulti.next(this.elements.slice());
    // listen for search field value changes
    this.elementMultiFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterElementsMulti();
    });
  }

  emitElements(elements: any[]) {
    this.elementsToEmit.emit(elements);
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected filterElementsMulti() {
    if (!this.elements) {
      return;
    }
    // get the search keyword
    let search = this.elementMultiFilterCtrl.value;
    if (!search) {
      this.filteredElementsMulti.next(this.elements.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter elements
    this.filteredElementsMulti.next(
      this.elements.filter(element => element.fullName.toLowerCase().indexOf(search) > -1)
    );
  }

}
