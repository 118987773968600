import { WeekDay } from '@angular/common';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ActivityTime } from 'projects/qcloud-models/activity-time';
import { Category } from 'projects/qcloud-models/category/category.model';
import { ReservationSchedule } from 'projects/qcloud-models/reservation/reservation-schedule';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { ReservationService } from 'projects/qcloud-rest-client/src/lib/reservation.service';
import { TimeConversionService } from 'projects/qcloud-web/src/app/shared/time-conversion.service';
import { WeekService } from 'projects/qcloud-web/src/app/shared/week.service';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
@Component({
  selector: 'app-on-hours-schedule',
  templateUrl: './on-hours-schedule.component.html',
  styleUrls: ['./on-hours-schedule.component.css']
})
export class OnHoursScheduleComponent implements OnInit {

  @Input() reservationCategories: Category[];
  _choosenCategory: Category;
  set choosenCategory(value: Category) {
    this._choosenCategory = value;
    this.categoryChanged();
  }
  get choosenCategory() {
    return this._choosenCategory;
  }
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  categorySchedules: ReservationSchedule[];
  schedule: ReservationSchedule;

  dayNames = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
  selectedDays = new Array<boolean>(7);
  scheduleRange: Date[];
  scheduleTimes: any[];
  isTimeCorect: boolean = true;

  constructor(private toastr: ToastrService, private translate: TranslateService,
    private reservationService: ReservationService, private weekService: WeekService,
    private timeService: TimeConversionService, public locationService: LocationService, private dialog: MatDialog) { }


  ngOnInit(): void {
    this.choosenCategory = this.reservationCategories[0];
  }

  categoryChanged() {
    this.getSchedules();
  }

  getSchedules() {
    this.reservationService.getReservationCategorySchedules(this.choosenCategory.id, (data) => {
      this.categorySchedules = data;
    }, () => { });
  }

  dialogRef : MatDialogRef<any>;
  editSchedule(schedule: ReservationSchedule): void {
    this.schedule = schedule;
    this.scheduleRange = new Array();
    this.selectedDays = new Array<boolean>(7);
    this.schedule.days.forEach(element => {
      this.selectedDays[element] = true;
    });
    this.scheduleTimes = new Array();
    let ctx = this;
    this.schedule.categoryBlockingTimes.forEach(element => {
      this.scheduleTimes.push({
        startTime: ctx.timeService.dateToTime(element.unblockingTime),
        endTime: ctx.timeService.dateToTime(element.blockingTime)
      });
    });
    this.scheduleRange.push(new Date(this.schedule.startTime));
    this.scheduleRange.push(new Date(this.schedule.endTime));
    var matDialogConfig = new MatDialogConfig();
    matDialogConfig.maxHeight = 900;
    matDialogConfig.maxWidth = 800;
    this.dialogRef = this.dialog.open(this.modalContent, matDialogConfig);
  }

  deleteSchedule(schedule: ReservationSchedule) {
    this.reservationService.deleteReservationCategorySchedule(schedule.id, this.choosenCategory.id, () => {
      this.getSchedules();
    }, () => { })
  }

  addSchedule() {
    this.schedule = new ReservationSchedule();
    this.scheduleRange = new Array();
    this.scheduleRange.push(new Date());
    this.scheduleRange.push(new Date());
    this.scheduleTimes = new Array();
    this.selectedDays = new Array<boolean>(7);
    this.schedule.id = 0;
    this.schedule.name = this.choosenCategory.fullName + "_" + (this.categorySchedules.length + 1);
    this.schedule.categoryBlockingTimes = new Array();
    this.dialogRef = this.dialog.open(this.modalContent, { maxHeight: '900px', maxWidth: '800px'});
  }

  selectAll() {
    this.updateSelected(this.weekService.selectAll());
  }

  unselectAll() {
    this.updateSelected(this.weekService.unselectAll());
  }

  selectWeekend() {
    this.updateSelected(this.weekService.selectWeekend());
  }

  selectWorkweek() {
    this.updateSelected(this.weekService.selectWorkweek());
  }

  updateSelected(daysOfWeek: WeekDay[]) {
    this.selectedDays = new Array<boolean>(7);
    for (let i = 0; i < daysOfWeek.length; i++) {
      this.selectedDays[daysOfWeek[i]] = true;
    }
  }

  deleteScheduleTime(i: number) {
    this.schedule.categoryBlockingTimes.splice(i, 1);
    this.scheduleTimes.splice(i, 1);
  }

  addScheduleTime() {
    let newItem = new ActivityTime();
    newItem.blockingTime = new Date();
    newItem.unblockingTime = new Date();
    this.schedule.categoryBlockingTimes.push(newItem);
    this.scheduleTimes.push({ startTime: "00:00", endTime: "00:00" });
  }

  setScheduleRange(value: Date[]): void {
    this.schedule.startTime = value[0];
    this.schedule.endTime = value[1];
  }

  onCloseModal() {
    let ctx = this;
    if (this.schedule.defaultConfiguration) {
      this.schedule.startTime = new Date();
      this.schedule.endTime = new Date(2099, 1, 1);
    }
    this.schedule.categoryBlockingTimes = new Array();
    this.scheduleTimes.forEach(element => {
      let newTime = new ActivityTime();
      newTime.blockingTime = ctx.timeService.timeToDate(element.endTime);
      newTime.unblockingTime = ctx.timeService.timeToDate(element.startTime);
      ctx.schedule.categoryBlockingTimes.push(newTime);
    });
    let daysAr = new Array();
    this.selectedDays.forEach((day, index) => {
      if (day) {
        daysAr.push(index);
      }
    });
    this.schedule.days = daysAr;
    if (this.schedule.id == 0) {
      this.reservationService.addReservationCategorySchedule(this.schedule, this.choosenCategory.id, () => {
        this.translate.get('reservation-schedule-save-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
        this.getSchedules();
      }, () => {
        this.translate.get('reservation-schedule-save-error').subscribe((res: string) => {
          this.toastr.error(res);
        });
      });
    }
    else {
      this.reservationService.updateReservationCategorySchedule(this.schedule, this.choosenCategory.id, () => {
        this.translate.get('reservation-schedule-save-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
        this.getSchedules();
      }, () => {
        this.translate.get('reservation-schedule-save-error').subscribe((res: string) => {
          this.toastr.error(res);
        });
      });
    }
  }

  clearModal() {
    this.scheduleTimes = new Array();
    this.selectedDays = new Array();
    this.schedule = new ReservationSchedule();
  }

  checkTime() {
    let ctx = this;
    for (let element of this.scheduleTimes) {
      let newTime = new ActivityTime();
      newTime.blockingTime = ctx.timeService.timeToDate(element.endTime);
      newTime.unblockingTime = ctx.timeService.timeToDate(element.startTime);
      if (newTime.unblockingTime > newTime.blockingTime) {
        this.isTimeCorect = false;
        break;
      }
      else {
        this.isTimeCorect = true;
      }
    }
  }
}