import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { User } from 'projects/qcloud-models/user/user.model';
import { UserService } from 'projects/qcloud-rest-client/src/lib/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  user: User;
  formSubmitted: boolean = false;
  editForm: boolean = true;
  ctx = this;

  constructor(private userService: UserService, private route: ActivatedRoute, private router: Router,
    private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit(): void {
    let userId: string;
    this.route.params.subscribe(params => {
      userId = params['id']
    });
    this.getUser(userId);
  }

  getUser(userId: string) {
    this.userService.getUser(userId).subscribe(
      user => {
        this.user = user;
      }
    );
  }

  onSubmit() {
    this.userService.editUser(this.user).subscribe(
      res => {
        this.formSubmitted = false;
        this.translate.get('profile-edit-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('profile-edit-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

}
