<div class="card shadow mb-4">
    <mat-accordion>
        <mat-expansion-panel (opened)="isCollapsed = false"
                             (closed)="isCollapsed = true"
                             hideToggle="true"
                             [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
                <h5 class="col-10 m-2 font-weight-bold text-primary">{{ 'desk-translation' | translate }}</h5>
            </mat-panel-title>
            <mat-panel-description>
                    <button type="button" class="btn btn-primary btn-circle btn-sm" (click)="isCollapsed = !isCollapsed"
                        [attr.aria-expanded]="!isCollapsed" aria-controls="collapseCategory"
                        [ngClass]="{'clicked': isCollapsed}">
                        <i class="fas fa-angle-down"></i>
                    </button>
            </mat-panel-description>
          </mat-expansion-panel-header>
    <div>
        <div *ngIf="deskTranslations && desks" class="card-body">
            <div class="text-right">
                <app-pagination [translations]="translations"
                    (filteredTranslationsEmitter)="getFilteredTranslations($event)">
                </app-pagination>
            </div>
            <div class="row">
                <div class="col-4">

                    <p class="font-weight-bold">{{ getLocaleName(locationLocaleMain) | translate }}</p>
                    <p *ngIf="desks.length == 0">{{ 'no-desks' | translate }}</p>

                    <div *ngFor="let desk of desks">

                        <div class="btn btn-teal btn-sm mx-1 mb-1">{{ desk.deskNumber }}</div>

                        <div class="form-group">
                            {{ 'desk-name' | translate }}
                            <div class="input-group">
                                <input disabled [(ngModel)]="desk.name" class="form-control">
                            </div>
                        </div>

                        <div class="form-group">
                            {{ 'desk-pronunciation' | translate }}
                            <div class="input-group">
                                <input disabled [(ngModel)]="desk.deskFullNamePronunciation" class="form-control">
                            </div>
                        </div>

                        <div *ngIf="desk.roomNumberPronunciation" class="form-group">
                            {{ 'room-number-pronunciation' | translate }}
                            <div class="input-group">
                                <input disabled [(ngModel)]="desk.roomNumberPronunciation" class="form-control">
                            </div>
                        </div>

                        <hr>
                    </div>

                </div>
                <div class="col-4" *ngFor="let translation of deleteLocaleMain(isOnPage()); let i = index">
                    <form *ngIf="deskTranslations[startIndex + i]" #form="ngForm" autocomplete="off">

                        <p class="font-weight-bold">{{ getLocaleName(translation.locale) | translate }}</p>

                        <div *ngFor="let deskTranslation of deskTranslations[startIndex + i]; let j = index">
                            <div *ngIf="deskTranslation">
                                <div class="btn btn-teal btn-sm mx-1 mb-1">{{ desks[j].deskNumber }}</div>

                                <div class="form-group">
                                    {{ 'desk-name' | translate }}
                                    <div class="input-group">
                                        <input name="name{{i}}{{j}}" #name="ngModel"
                                            [(ngModel)]="deskTranslation.name" class="form-control">
                                    </div>
                                </div>

                                <div class="form-group">
                                    {{ 'desk-pronunciation' | translate }}
                                    <div class="input-group">
                                        <input name="deskFullNamePronunciation{{i}}{{j}}" #deskFullNamePronunciation="ngModel"
                                            [(ngModel)]="deskTranslation.deskFullNamePronunciation" class="form-control">
                                    </div>
                                </div>

                                <div *ngIf="desks[j].roomNumberPronunciation" class="form-group">
                                    {{ 'room-number-pronunciation' | translate }}
                                    <div class="input-group">
                                        <input name="roomNumberPronunciation{{i}}{{j}}" #roomNumberPronunciation="ngModel"
                                            [(ngModel)]="deskTranslation.roomNumberPronunciation" class="form-control">
                                    </div>
                                </div>

                                <hr>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
            <div class="form-group row">
                <app-submit-button (click)="onSubmit()" type="submit" [loading]="formSubmitted"
                    [text]="'save-desk-translations' | translate">
                </app-submit-button>
            </div>
        </div>
    </div>
</mat-expansion-panel>
</mat-accordion>
</div>