import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ChangeContext, Options, PointerType } from 'ngx-slider-v2';
import { TimeConversionService } from 'projects/qcloud-web/src/app/shared/time-conversion.service';

@Component({
  selector: 'app-time-range-picker',
  templateUrl: './time-range-picker.component.html',
  styleUrls: ['./time-range-picker.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TimeRangePickerComponent implements OnInit {

  @Input() beginTime: string;
  @Input() endTime: string;
  @Output() rangeToEmit = new EventEmitter();

  minValue: number;
  maxValue: number;

  options: Options = {
    floor: 0,
    ceil: 1440,
    step: 10,
    enforceStep: false,
    translate: (value: number): string => {
      return this.timeConverter.minutesToTime(value);
    },
    combineLabels: (minValue: string, maxValue: string): string => {
      return minValue + ' - ' + maxValue;
    }
  };

  constructor(private timeConverter: TimeConversionService) { }

  ngOnInit(): void {
    this.rangeToEmit.emit([this.beginTime, this.endTime]);
    this.updateSlider();
  }

  onUserChange(changeContext: ChangeContext) {
    if (changeContext.pointerType === PointerType.Min) {
      this.beginTime = this.timeConverter.minutesToTime(changeContext.value);
    } else {
      this.endTime = this.timeConverter.minutesToTime(changeContext.highValue);
    }
    this.rangeToEmit.emit([this.beginTime, this.endTime]);
  }

  updateSlider() {
    if (this.endTime) {
      this.maxValue = this.timeConverter.timeToMinutes(this.endTime);
    }

    if (this.beginTime) {
      this.minValue = this.timeConverter.timeToMinutes(this.beginTime);
    }

    if (this.beginTime && this.endTime) {
      this.rangeToEmit.emit([this.beginTime, this.endTime]);
    }
  }

}
