import { CalendarDateFormatter, DateFormatterParams } from 'angular-calendar';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable()
export class ScheduleDateFormatter extends CalendarDateFormatter {

    public dayViewTitle({ date, locale }: DateFormatterParams): string {
        return formatDate(date, 'EEEE, dd/MM/yyyy', locale);
    }

    public weekViewHour({ date, locale }: DateFormatterParams): string {
        return formatDate(date, 'HH:mm', locale);
    }

    public dayViewHour({ date, locale }: DateFormatterParams): string {
        return formatDate(date, 'HH:mm', locale);
    }
}