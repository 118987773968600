import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Device } from 'projects/qcloud-models/device/device.model';
import { Language } from 'projects/qcloud-models/translation/language.model';
import { ReservationButtonTextTranslation } from 'projects/qcloud-models/translation/reservation-button-text-translation';
import { Translation } from 'projects/qcloud-models/translation/translation.model';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { DeviceService } from 'projects/qcloud-rest-client/src/lib/device.service';
import { TranslationService } from 'projects/qcloud-rest-client/src/lib/translation.service';
import { TranslationConfigurationService } from 'projects/qcloud-web/src/app/shared/translation-configuration.service';

@Component({
  selector: 'app-reservation-button-text-translation',
  templateUrl: './reservation-button-text-translation.component.html',
  styleUrls: ['./reservation-button-text-translation.component.css']
})
export class ReservationButtonTextTranslationComponent implements OnInit {

  @Input() translations: Translation[];
  @Input() languages: Language[];
  @Input() locationLocaleMain: string;
  devices: Device[] = [];
  startIndex: number;
  formSubmitted: boolean = false;
  reservationButtonTextTranslations: Array<ReservationButtonTextTranslation[]>;

  constructor(
    private translate: TranslateService, 
    private deviceService: DeviceService, 
    private translationService: TranslationService, 
    private authService: AuthService, 
    private toastr: ToastrService,
    private translationConfigurationService: TranslationConfigurationService
    ) { }

  ngOnInit(): void {
    this.getDeviceNames();
    this.translationConfigurationService.translationLanguages.subscribe(translations => {
      this.translations = translations;
      this.getReservationButtonTextTranslations();
    });
    this.getReservationButtonTextTranslations();
  }

  getDeviceNames() {
    this.deviceService.getDevices((data) => {
      this.devices = data.filter(x => x.canPrintReservedTickets);
    },
    () => { });
  }

  getReservationButtonTextTranslations() {
    let result = new Array<ReservationButtonTextTranslation[]>();
    this.translationService.getReservationButtonTextTranslations().subscribe(data => {
      for(let i = 0; i < this.translations.length; i++) {
        let language = this.translations[i];
        let languageTranslation = new Array<ReservationButtonTextTranslation>();
        this.devices.forEach(device => {
          let translation = data.find(x => x.deviceId === device.id && x.translationId === language.id)
          if (!translation) {
            translation = new ReservationButtonTextTranslation(this.authService.getLocationId(), device.id, language.id);
          }
          languageTranslation.push(translation);
        });
        result[i] = languageTranslation;
      };
    });

    this.reservationButtonTextTranslations = result;

  } 

  getFilteredTranslations(startIndex: number) {
    this.startIndex = startIndex;
  }

  getLocaleName(locale: string) {
    return this.languages.find(languageOption => languageOption.locale == locale).name;
  }

  isOnPage() {
    return this.translations.slice(this.startIndex, this.startIndex + 2);
  }

  deleteLocaleMain(translations: Translation[]) {
    return translations.filter(translation => translation.locale != this.locationLocaleMain);
  }

  onSubmit() {
    this.formSubmitted = true;

    let reservationButtonTextTranslations = new Array<ReservationButtonTextTranslation>();
    this.reservationButtonTextTranslations.forEach(language => {
      language.forEach(translation => {
          reservationButtonTextTranslations.push(translation);
      });
    });

    this.translationService.setReservationButtonTextTranslations(reservationButtonTextTranslations).subscribe(
      res => {
        this.formSubmitted = false;
        this.translate.get('set-reservation-button-text-translations-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('set-reservation-button-text-translations-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      });
  }
}

