import { Node } from './node.model';
import { DeviceCategoryTreeValue } from './device-category-tree-value.model';

export class DeviceCategoryTree {
    root: Node<DeviceCategoryTreeValue> = new Node<DeviceCategoryTreeValue>(null);
    
    public static addElementToTree(root: Node<DeviceCategoryTreeValue>, element: DeviceCategoryTreeValue) {
        let node = new Node(element);
        root.nodes.push(node);
        return node;
    };
}