<div (click)="toWelcomePage()" class="d-none d-sm-inline-block mr-auto ml-md-3 my-2 my-md-0 mw-100 pointer">
    <img src="assets/images/QCloud.png" alt="QCloud">
</div>

<!-- Console Logout Modal-->
<ng-template #logoutModal let-modal>
    <div class="modal-body">
        {{ 'console.logout' | translate}}
    </div>
    <div class="modal-footer" mat-dialog-content>
        <button type="submit" class="btn btn-secondary" (click)="modal.close('Save click')">{{ 'cancel' | translate }}</button>
        <button type="submit" class="btn btn-danger" (click)="modal.close('Save click'); consoleLogout()">
            {{ 'logout' | translate }}
        </button>
    </div>
</ng-template>
