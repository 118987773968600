import { Component, ElementRef, HostBinding, OnInit, Renderer2 } from '@angular/core';
import { DisplayType } from '../../../../../qcloud-models/display-type';
import { StringLiteral } from 'typescript';
import { DeviceConfigurationService } from '../../services/device-configuration.service';
import { TicketListenerService } from '../../services/ticket.service';
import { UtilitiesService } from '../../services/utilities.service';
import { QueueStateService } from '../../services/queue-state.service';
import { FileService } from 'projects/qcloud-rest-client/src/lib/file.service';
import { ScheduleService } from '../../services/schedule.service';
import { QcloudStompClientService } from 'projects/qcloud-stomp-client/src/lib/qcloud-stomp-client.service';
import { TicketService } from 'projects/qcloud-rest-client/src/lib/ticket.service';
import { DeskService } from 'projects/qcloud-rest-client/src/lib/desk.service';
import { DeskStatusService } from '../../services/desk-status.service';
import { ReservationService } from 'projects/qcloud-rest-client/src/lib/reservation.service';
import { RowStyle } from 'projects/qcloud-models/device/row-style';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css']
})
export class BaseComponent implements OnInit {
  @HostBinding('style.position')
  position: "absolute";
  @HostBinding('style.display')
  display: "block";
  @HostBinding('style.left')
  left: string = "";
  @HostBinding('style.width')
  width: string = "";
  @HostBinding('style.top')
  top: string = "";
  @HostBinding('style.height')
  height: string = "";
  private _displayType: DisplayType = DisplayType.HORIZONTAL;
  public rowStyle: RowStyle;

  locationId: number;
  systemId: number;
  public imageUrl: string;

  set displayType(value: DisplayType) {
    this._displayType = value;
  }
  public get displayType() {
    return this._displayType;
  }

  private _maxTicketsToShow: number;

  set maxTicketsToShow(maxTicketsToShow: number) {
    this._maxTicketsToShow = maxTicketsToShow;
  }
  get maxTicketsToShow() {
    return this._maxTicketsToShow;
  }
  private _quantityOfColumns: number = 2;

  set quantityOfColumns(quantityOfColumns: number) {
    this._quantityOfColumns = quantityOfColumns;
  }
  get quantityOfColumns() {
    return this._quantityOfColumns;
  }

  constructor(protected elementRef: ElementRef, protected renderer: Renderer2) { }

  //we have to pass ioc from parent and init later after constructor, it is needed so component can be reusable in other projects that are mockups
  protected configurationService: DeviceConfigurationService;
  protected ticketListenerService: TicketListenerService;
  protected utilitiesService: UtilitiesService;
  protected queueStateService: QueueStateService;
  protected scheduleService: ScheduleService;
  protected fileService: FileService;
  protected qcloudStompClientService: QcloudStompClientService;
  protected ticketService: TicketService;
  protected deskStatusService: DeskStatusService;
  protected deskService: DeskService;
  protected reservationService: ReservationService;
  initComponent(configurationService: DeviceConfigurationService,
    ticketListenerService: TicketListenerService,
    utilitiesService: UtilitiesService,
    queueStateService: QueueStateService,
    scheduleService: ScheduleService,
    fileService: FileService,
    ticketService: TicketService,
    qcloudStompClientService: QcloudStompClientService,
    deskStatusService: DeskStatusService,
    deskService: DeskService,
    reservationService: ReservationService) {
    this.configurationService = configurationService;
    this.ticketListenerService = ticketListenerService;
    this.utilitiesService = utilitiesService;
    this.queueStateService = queueStateService;
    this.scheduleService = scheduleService;
    this.fileService = fileService;
    this.qcloudStompClientService = qcloudStompClientService;
    this.ticketService = ticketService;
    this.deskStatusService = deskStatusService;
    this.deskService = deskService;
    this.reservationService = reservationService;

    let config = this.configurationService.getConfiguration();
    this.locationId = config.locationId;
    this.systemId = config.systemId;
  }

  startComponent() { }
  passParameters(params: any) {

  }

  initMock() { }

  public setRowStyle(rowStyle: RowStyle): void {
    this.rowStyle = rowStyle;
  }

  ngOnInit(): void {
  }

  setPosition(left: number, width: number, top: number, height: number) {
    this.position = "absolute";
    this.display = "block";
    this.left = `${left}%`;
    this.width = `${width}%`;
    this.top = `${top}%`;
    this.height = `${height}%`;
  }

  setStyle(style: string) {
    const hostElement = this.elementRef.nativeElement;
    if (style && style.length > 0) {
      var properties = style.split(";");
      properties.forEach(y => {
        let split = y.split(":");
        var rest = ""
        for (let i = 1; i < split.length; i++) {
          rest += split[i];
          if ((i + 1) < split.length) {
            rest += ":";
          }
        }
        this.renderer.setStyle(hostElement, split[0], rest);
      });
    }
  }

  getUrlForServer(url: string) {
    return this.fileService.appBase + `cdn/${this.systemId}/${this.locationId}/` + this.removeDoubleSlashInFront(url);
  }

  removeDoubleSlashInFront(name: string): string {
    return name.substring(1);
  }

  @HostBinding('style.outline')
  border: string = "";

  setSelected(selected: boolean) {
    if(selected){
      this.border = "3px # #FF5733 dashed";
    }
    else {
      this.border = "";
    }
  }

}