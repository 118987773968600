<div class="card shadow mb-4">
    <div class="card-header py-3">
        <div class="row">
            <h5 class="col-8 m-0 font-weight-bold text-primary">{{ 'desks' | translate }}</h5>
            <div class="text-right col-4">
                <button type="button" class="btn btn-primary btn-circle btn-sm" routerLink="/desk"
                    matTooltip="{{ 'add' | translate }}" tooltipClass="tooltip-primary">
                    <i class="fas fa-fw fa-plus"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{{ 'name' | translate }}</th>
                        <th>{{ 'room-number' | translate }}</th>
                        <th>{{ 'desk-status' | translate }}</th>
                        <th>{{ 'desk-user-logged-in' | translate }}</th>
                        <th>{{ 'served-categories' | translate }}</th>
                        <th>{{ 'options' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let desk of desks; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>{{ desk.fullName }}</td>
                        <td>
                            <span *ngIf="desk.roomNumber">{{ desk.roomNumber }}</span>
                            <span *ngIf="!desk.roomNumber">---</span>
                        </td>
                        <td>
                            <span *ngIf="desk.deskStatus == DeskStatus.Open">{{ 'desk-status-open' | translate }}</span>
                            <span *ngIf="desk.deskStatus == DeskStatus.Pause">{{ 'desk-status-paused' | translate }}</span>
                            <span *ngIf="desk.deskStatus == DeskStatus.Closed">{{ 'desk-status-closed' | translate }}</span>
                        </td>
                        <td>
                            {{ getUserName(desk.currentLoggedInUserId) }}
                        </td>
                        <td>
                            <app-desk-categories-column [deskCategories]="desk.categories">
                            </app-desk-categories-column>
                        </td>
                        <td>
                            <div class="row">
                                <button class="btn btn-info btn-circle btn-sm mx-1 mb-1"
                                    [routerLink]="['/desk/', desk.id]" matTooltip="{{ 'edit' | translate }}"
                                    tooltipClass="tooltip-info">
                                    <i class="fas fa-fw fa-edit"></i>
                                </button>
                                <button (click)="selectedDeskId = desk.id"
                                    class="btn btn-danger btn-circle btn-sm mx-1 mb-1"
                                    matTooltip="{{ 'delete' | translate }}" tooltipClass="tooltip-danger"
                                    data-toggle="modal" data-target="#deleteModal">
                                    <i class="fas fa-fw fa-trash"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-hidden="true">
    <app-delete-modal [info]="'delete-confirmation'" [confirm]="'delete'" (confirmed)="onDeleteConfirm($event)">
    </app-delete-modal>
</div>