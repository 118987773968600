<div *ngIf="locationService.getLocationConstraints().isOnHoursEnabled">
    <div class="card shadow mb-4">
        <div class="card-header py-3">
            <h5 class="m-0 font-weight-bold text-primary">{{ 'on-hours-configuration' | translate }}</h5>
        </div>
        <div *ngIf="categories && reservationConfiguration" class="card-body">
            <app-served-categories *ngIf="categoryMultiCtrl" [categories]="categories"
                [categoryMultiCtrl]="categoryMultiCtrl" [noSelectedCategories]="noSelectedCategories"
                [displayCategories]="true">
            </app-served-categories>
        </div>
        <div class="form-group row">
            <button class="btn btn-primary" style="margin-left: 2rem;" type="submit" (click)="saveConfiguration()"
                [disabled]="formSubmitted">
                <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                    aria-hidden="true"></span> {{ 'save-changes' | translate }}
            </button>
        </div>
    </div>
    


    <app-on-hours-schedule *ngIf="onHoursCategories" [reservationCategories]="onHoursCategories">
    </app-on-hours-schedule>
</div>

<app-licence-info *ngIf="!locationService.getLocationConstraints().isOnHoursEnabled"
    [title]="'reservation-configuration'">
</app-licence-info>