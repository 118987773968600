<div *ngIf="checkLoadConsoleAssets() && !consoleService.user.mainRole.hasPermissionToServeETickets" class="row mt-4">
    <div class="col-sm-12 col-md-8">
        <div class="card shadow mb-3">
            <div class="card-header py-2 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary">{{ 'console.ticket-service' | translate}}</h6>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-5 mb-2">
                        <lib-display-ticket></lib-display-ticket>
                        <lib-display-deadline></lib-display-deadline>
                    </div>
                    <div class="col-sm-7">
                        <div class="row">
                            <lib-call-ticket-button class="col-sm-12 col-md-6 col-xl-4 mb-1 pr-md-1" #callTicketButtonComponent>
                            </lib-call-ticket-button>
                            <lib-call-again-ticket-button  class="col-sm-12 col-md-6 col-xl-4 mb-1 pr-md-1" #callAgainTicketButtonComponent>
                            </lib-call-again-ticket-button>
                            <lib-accept-ticket-button  class="col-sm-12 col-md-6 col-xl-4 mb-1 pr-md-1" #acceptTicketButtonComponent>
                            </lib-accept-ticket-button>
                            <lib-pause-ticket-button  class="col-sm-12 col-md-6 col-xl-4 mb-1 pr-md-1" #pauseTicketButtonComponent>
                            </lib-pause-ticket-button>
                            <lib-redirect-ticket-button  class="col-sm-12 col-md-6 col-xl-4 mb-1 pr-md-1" #redirectTicketButtonComponent>
                            </lib-redirect-ticket-button>
                            <lib-cancel-ticket-button  class="col-sm-12 col-md-6 col-xl-4 mb-1 pr-md-1" #cancelTicketButtonComponent>
                            </lib-cancel-ticket-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-4 col-md-4">
        <div class="card shadow mb-3">
            <div class="card-header py-2 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary">{{ 'console.desk-data' | translate}}</h6>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12 col-lg-6">
                        <div class="font-weight-bold">
                            <lib-display-desk></lib-display-desk>
                        </div>
                        <lib-desk-status></lib-desk-status>
                    </div>
                    <div class="col-md-12 col-lg-6">
                        <div class="font-weight-bold">
                            <lib-display-user></lib-display-user>
                        </div>
                        <lib-logout #libLogout></lib-logout>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-8 col-sm-12">
        <div class="mb-3">
            <div class="card shadow">
                <div class="card-header py-2 d-flex flex-row align-items-center justify-content-between">
                    <h6 class="m-0 font-weight-bold text-primary">{{ 'queue-state' | translate}}</h6>
                    <lib-queue-state-all-tickets></lib-queue-state-all-tickets>
                </div>
                <div class="card-body d-flex justify-content-center">
                    <lib-queue-state #queueStateComponent></lib-queue-state>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-4 col-md-4">
        <lib-interaction-card></lib-interaction-card>
    </div>
    <div class="col-sm-12 col-md-12">
        <div class="card shadow w-100 mb-3">
            <div class="card-header py-2 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary">{{ 'console.additional-options' | translate}}</h6>
            </div>
            <div class="card-body d-flex justify-content-center expand-menu-layout">
                <lib-cancelled-tickets-button class="lib-button"></lib-cancelled-tickets-button>
                <lib-paused-tickets-button class="lib-button"></lib-paused-tickets-button>
                <lib-call-ticket-manual-button class="lib-button"></lib-call-ticket-manual-button>
                <lib-blocked-categories-button class="lib-button"></lib-blocked-categories-button>
                <lib-served-categories-button class="lib-button"></lib-served-categories-button>
                <lib-generate-ticket-button class="lib-button"></lib-generate-ticket-button>
                <lib-show-ticket-manual-button class="lib-button">
                </lib-show-ticket-manual-button>
            </div>
        </div>
    </div>
</div>
<lib-e-ticket-desk *ngIf="checkLoadConsoleAssets() && consoleService.user.mainRole.hasPermissionToServeETickets"></lib-e-ticket-desk>
<div class="logo-container">
    <div>
        <img src="assets/QCloud.png" class="logo">
    </div>
</div>