import { Component, OnInit } from '@angular/core';
import { MultimediaService } from 'projects/qcloud-rest-client/src/lib/multimedia.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Playlist } from 'projects/qcloud-models/multimedia/playlist.model';
import { PlaylistItem } from 'projects/qcloud-models/multimedia/playlistItem.model';
import { FileDuration } from 'projects/qcloud-models/fileManager/file-duration.model';
import { PlaylistFile } from 'projects/qcloud-models/multimedia/playlist-file.model';
import { Observable, forkJoin } from 'rxjs';

@Component({
  selector: 'app-edit-playlist',
  templateUrl: './edit-playlist.component.html',
  styleUrls: ['./edit-playlist.component.css']
})
export class EditPlaylistComponent implements OnInit {

  playlist: Playlist;
  maxPlaylistSize: number = 1 * 1024 * 1024 * 1024;
  formSubmitted: boolean = false;
  editForm: boolean = true;
  ctx = this;

  constructor(private multimediaService: MultimediaService, private route: ActivatedRoute, private toastr: ToastrService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    let playlistId: number;
    this.route.params.subscribe(params => {
      playlistId = params['id']
    });
    this.getPlaylist(playlistId);
  }

  getPlaylist(playlistId: number) {
    this.multimediaService.getPlaylist(playlistId).subscribe(
      playlist => {
        this.playlist = playlist;
        this.playlist.playlistItems = this.playlist.playlistItems.sort((item1, item2) => item1.order - item2.order);
      },
      err => {}
    );
  }

  getPlaylistItems(playlistId: number) {
    this.multimediaService.getPlaylistItems(playlistId).subscribe(
      playlistItems => {
        this.playlist.playlistItems = playlistItems.sort((item1, item2) => item1.order - item2.order);
      },
      err => {}
    );
  }

  changePlaylistName(playlist: Playlist) {
    this.multimediaService.changePlaylistName(playlist.id, playlist.name).subscribe(
      res => {
        this.translate.get('change-playlist-name-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
        this.getPlaylist(playlist.id);
      },
      err => {
        this.translate.get('change-playlist-name-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    )
  }

  addPlaylistItems(filesWithDuration: FileDuration[]) {
    let playlistFiles = filesWithDuration.map(fileWithDuration => new PlaylistFile(fileWithDuration.filePath, +fileWithDuration.size,
      (fileWithDuration.seconds + (fileWithDuration.minutes * 60)))
      );
    let sizeDifference = this.maxPlaylistSize - this.calculatePlaylistSize(playlistFiles);
    if (sizeDifference > 0) {
      this.multimediaService.addPlaylistItems(this.playlist.id, playlistFiles).subscribe(
        res => {
          this.multimediaService.getPlaylist(this.playlist.id).subscribe(
            playlist => {
              this.playlist = playlist;
              this.playlist.playlistItems = this.playlist.playlistItems.sort((item1, item2) => item1.order - item2.order);
            },
            err => {}
          );
        },
        err => {
          this.translate.get('add-playlist-item-error').subscribe((error: string) => {
            this.translate.get('repeat').subscribe((res: string) => {
              this.toastr.error(res, error);
            });
          });
        }
      );
    } else {
      sizeDifference = Math.round(-sizeDifference / (1024 * 1024));
      this.translate.get('maximum-size-of-playlist-was-exceeded').subscribe((error: string) => {
        this.translate.get('repeat').subscribe((res: string) => {
          this.toastr.error(res, error + sizeDifference + " MB");
        });
      });
    }
  }

  calculatePlaylistSize(newPlaylistFiles: PlaylistFile[]): number {
    let playlistFiles = this.playlist.playlistItems.map(item => new PlaylistFile(item.filePath, item.size, item.showLength));
    newPlaylistFiles.forEach(newPlaylistFile => {
      playlistFiles.push(newPlaylistFile);
    });
    playlistFiles = playlistFiles.filter((file, index, self) =>
      index === self.findIndex(t => t.filePath === file.filePath));
    let playlistSize: number = 0;
    playlistFiles.forEach(file => playlistSize += +file.size);
    return playlistSize;
  }

  changeOrder(index: number, direction: number) {
    var item = this.playlist.playlistItems[index];
    var itemToSwap = this.playlist.playlistItems[index + direction];
    this.multimediaService.changeFileOrder(this.playlist.id, item.id, itemToSwap.id).subscribe(
      res => {
        this.getPlaylistItems(this.playlist.id);
      },
      err => {
        this.translate.get('change-order-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        }); 
      }
    );
  }

  deleteItemFromPlaylist(item: PlaylistItem) {
    this.multimediaService.deleteItemFromPlaylist(this.playlist.id, item.id).subscribe(
      res => {
        this.getPlaylist(this.playlist.id);
      },
      err => {
        this.translate.get('playlist-element-delete-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  changePlaylistItemTime(item: PlaylistItem) {
    this.multimediaService.changePlaylistItemTime(this.playlist.id, item.id, item.showLength).subscribe(
      res => {
        this.translate.get('change-playlist-item-time-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
        this.getPlaylistItems(this.playlist.id);
      },
      err => {
        this.translate.get('change-playlist-item-time-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  changePlaylistItemsTime(items: PlaylistItem[]) {
    let observables: Observable<any>[] = [];
    items.forEach(item => {
      observables.push(this.multimediaService.changePlaylistItemTime(this.playlist.id, item.id, item.showLength));
    });
    forkJoin(observables).subscribe(
      res => {
        this.translate.get('change-playlist-items-time-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.translate.get('change-playlist-items-time-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      },
      () => this.getPlaylistItems(this.playlist.id)
    );
  }
}
