import { Component, OnInit } from '@angular/core';
import { QcloudConsoleService } from '../../qcloud-console.service';

@Component({
  selector: 'lib-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.css']
})
export class LogoutModalComponent implements OnInit {

  constructor(private consoleService: QcloudConsoleService) {
   }

  ngOnInit(): void {
  }

  public logout() {
    this.consoleService.logout();
  }

  public closeModal() {
    this.consoleService.closeAllModals();
  }

}
