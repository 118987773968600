import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LocationData } from 'projects/qcloud-models/location/location.model';
import { LicenseService } from 'projects/qcloud-rest-client/src/lib/license.service';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css']
})
export class LocationsComponent implements OnInit {

  locations: LocationData[];
  locationId: number;

  constructor(private service: LocationService, private toastr: ToastrService, private translate: TranslateService,
    private router: Router, private licenseService: LicenseService) { }

  ngOnInit(): void {
    this.getAllLocations();
  }

  getAllLocations() {
    this.service.getAll().subscribe(
      locations => {
        this.locations = locations;
      }
    );
  }

  onDeleteConfirm(confirmed: boolean) {
    if (confirmed) {
      this.deleteLocation();
    }
  }

  deleteLocation() {
    this.service.deleteLocation(this.locationId).subscribe(
      res => {
        this.getAllLocations();
      },
      err => {
        this.translate.get('delete-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  goToAddLocation() {
    this.licenseService.getLocationsLeft().subscribe(
      locationsLeft => {
        if (locationsLeft > 0) {
          this.router.navigateByUrl('/location');
        } else {
          this.translate.get('licence-exceeded').subscribe((error: string) => {
            this.toastr.error(error);
          });
        }
      },
      err => {}
    );
  }
}
