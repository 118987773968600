<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="m-0 font-weight-bold text-primary">{{ 'license' | translate }}</h5>
    </div>
    <app-system-license *ngIf="tenant" [tenant]="tenant" (updateInfo)="updateInfo()"></app-system-license>
</div>

<div *ngIf="authService.isSuperAdmin()" class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="m-0 font-weight-bold text-primary">{{ 'locations' | translate }}</h5>
    </div>
    <app-locations-license *ngIf="locations && tenant" [locations]="locations" [tenant]="tenant"></app-locations-license>
</div>
