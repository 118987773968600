import { SystemCategory } from "../category/system-category.model";

export class TicketsQuestion {
    public id: number;
    public systemId: number;
    public type: number;
    public question: string;
    public keyName: string;
    public isEnabled: boolean;
    public systemCategories: SystemCategory[];
}