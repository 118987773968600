import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeConversionService {

  constructor() { }

  timeToDate(time): Date {
    let hour: number;
    let minute: number;
    (time[0] != 0) ? hour = time[0] + time[1] : hour = time[1];
    (time[3] != 0) ? minute = time[3] + time[4] : minute = time[4];
    let date = new Date();
    date.setHours(hour);
    date.setMinutes(minute);
    date.setSeconds(0);
    date.setMilliseconds(0);
    let timeZoneOffset = date.getTimezoneOffset();
    date.setMinutes(date.getMinutes() - timeZoneOffset);
    return date;
  }

  dateToTime(date: Date): string {  
    let time = date.toString();
    time = time.slice(11, 16);
    return time;
  }

  dateToDateWithoutTime(date: Date): Date {
    let newDate = new Date(date);
    let year = newDate.getFullYear();
    let month = newDate.getMonth();
    let day = newDate.getDate();
    let hourOffset = date.getTimezoneOffset() / 60;
    return new Date(year, month, day, 0 - hourOffset, 0, 0);
  }

  minutesToTime (minutes: number): string {
    let time = "";
    let hours = Math.floor(minutes / 60);
    if (hours < 10) {
      time += "0";
    }
    time += hours + ":";
    minutes = minutes - hours * 60;
    if (minutes < 10) {
      time += "0";
    }
    time += minutes;
    return time;
  }
  
  secondsToTime (secondes: number): string {
    return this.minutesToTime(secondes);
  }

  timeToMinutes(time: string): number {
    let hours;
    let minutes;
    (time[0] != "0") ? hours = time[0] + time[1] : hours = time[1];
    (time[3] != "0") ? minutes = time[3] + time[4] : minutes = time[4];
    hours = parseInt(hours);
    minutes = parseInt(minutes);
    return hours * 60 + minutes;
  }

  timestampToSeconds(timestamp: number) {
    return Math.floor(timestamp / 1000);
  }
}
