<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="m-0 font-weight-bold text-primary">{{ 'send-test-sms' | translate }}</h5>
    </div>
    <div class="card-body">

        <div class="form-group">
            {{ 'phone-number' | translate }}
            <div class="item-group">
                <input class="form-control" name="number" #number="ngModel" [(ngModel)]="testSms.to">
            </div>
        </div>

        <div class="form-group">
            {{ 'message' | translate }}
            <div class="item-group">
                <input class="form-control" name="message" #message="ngModel" [(ngModel)]="testSms.message">
            </div>
        </div>

        <div class="form-group">
            <button class="btn btn-primary m-1" type="send" [disabled]="formSubmitted" (click)="onSubmit()">
                <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                    aria-hidden="true"></span>{{ 'send' | translate }}
            </button>
        </div>
    </div>
</div>
