import { ActivityTime } from "../../activity-time";
import { Category } from "../category.model";

export class PutUpdateCategoryApiModel {
    public id: number;
    public locationId: number;
    public fullName: string;
    public shortName: string;
    public categoryTag: string;
    public ticketLimit: number;
    public timePerTicket: number;
    public isBlocked: boolean;
    public unblockingTimeDaysValue: ActivityTime[];
    public isAutomaticallyReset: boolean;
    public blockingMessageActivity: string;
    public blockingMessageManual: string;
    public isActive: boolean;
    public canBeReserved: boolean;
    public canMobile: boolean;
    public eTicketRedirect: boolean;
    public reservationIntervalMins: number;
    public canOnHour: boolean;
    public maxServiceTime: number;
    public workingType: number;
    public avgTimeBlock: boolean;
    public avgTimeBlockText: string;
    public smsCategory: boolean;
    public sendSmsOnReservation: boolean;
    public sendSmsOnPrint: boolean;
    public sendSmsOnBeforeCall: boolean;
    public sendSmsOnTicketCall: boolean;
    public conditionalTicket: boolean;
    public conditionalTicketText: string;
    public additionalPrint: boolean;
    public additionalPrintText: string;
    public printedTicketAdditionalText: string;
    public defaultPauseTimeMins: number;
    public isNoteRequired: boolean;
    public ticketAmountToCountAverageWaitingServiceTime: number;
    public systemCategoryId: number;
    public isInheritanceFullName: boolean;
    public isInheritanceShortName: boolean;
    public isInheritanceCategoryTag: boolean;
    public isInheritanceReservationGroupId: boolean;
    public isInheritanceLocationId: boolean;
    public isInheritanceTicketLimit: boolean;
    public isInheritanceTimePerTicket: boolean;
    public isInheritanceIsBlocked: boolean;
    public isInheritanceIsAutomaticallyReset: boolean;
    public isInheritanceBlockingMessageActivity: boolean;
    public isInheritanceBlockingMessageManual: boolean;
    public isInheritanceCanBeReserved: boolean;
    public isInheritanceCanMobile: boolean;
    public isInheritanceCanOnHour: boolean;
    public isInheritanceETicketRedirect: boolean;
    public isInheritanceReservationIntervalMins: boolean;
    public isInheritanceMaxServiceTime: boolean;
    public isInheritanceDefaultPauseTimeMins: boolean;
    public isInheritanceIsNoteRequired: boolean;
    public isInheritanceWorkingType: boolean;
    public isInheritanceAvgTimeBlock: boolean;
    public isInheritanceAvgTimeBlockText: boolean;
    public isInheritanceSmsCategory: boolean;
    public isInheritanceSendSmsOnReservation: boolean;
    public isInheritanceSendSmsOnPrint: boolean;
    public isInheritanceSendSmsOnBeforeCall: boolean;
    public isInheritanceSendSmsOnTicketCall: boolean;
    public isInheritanceConditionalTicket: boolean;
    public isInheritanceConditionalTicketText: boolean;
    public isInheritanceAdditionalPrint: boolean;
    public isInheritanceAdditionalPrintText: boolean;
    public isInheritancePrintedTicketAdditionalText: boolean;
    public isInheritanceTicketAmountToCountAverageWaitingServiceTime: boolean;
    public isInheritanceSystemCategoryId: boolean;

    public static mapFromSystemCategoryIntoPutUpdateCategoryApiModel(сategory: Category): PutUpdateCategoryApiModel {
        var cat = new PutUpdateCategoryApiModel();
        cat.id = сategory.id;
        cat.locationId = сategory.locationId;
        cat.fullName = сategory.fullName;
        cat.shortName = сategory.shortName;
        cat.categoryTag = сategory.categoryTag;
        cat.ticketLimit = сategory.ticketLimit;
        cat.timePerTicket = сategory.timePerTicket;
        cat.isBlocked = сategory.isBlocked;
        cat.unblockingTimeDaysValue = сategory.unblockingTimeDaysValue;;

        cat.isAutomaticallyReset = сategory.isAutomaticallyReset;
        cat.blockingMessageActivity = сategory.blockingMessageActivity;
        cat.blockingMessageManual = сategory.blockingMessageManual;
        cat.isActive = сategory.isActive;
        cat.canBeReserved = сategory.canBeReserved;
        cat.eTicketRedirect = сategory.eTicketRedirect;
        cat.canMobile = сategory.canMobile;
        cat.reservationIntervalMins = сategory.reservationIntervalMins;
        cat.maxServiceTime = сategory.maxServiceTime;
        cat.workingType = сategory.workingType;
        cat.avgTimeBlock = сategory.avgTimeBlock;
        cat.avgTimeBlockText = сategory.avgTimeBlockText;
        cat.smsCategory = сategory.smsCategory;
        cat.sendSmsOnReservation = сategory.sendSmsOnReservation;
        cat.sendSmsOnPrint = сategory.sendSmsOnPrint;
        cat.sendSmsOnBeforeCall = сategory.sendSmsOnBeforeCall;
        cat.sendSmsOnTicketCall = сategory.sendSmsOnTicketCall;
        cat.conditionalTicket = сategory.conditionalTicket;
        cat.conditionalTicketText = сategory.conditionalTicketText;
        cat.additionalPrint = сategory.additionalPrint;
        cat.additionalPrintText = сategory.additionalPrintText;
        cat.printedTicketAdditionalText = сategory.printedTicketAdditionalText;
        cat.defaultPauseTimeMins = сategory.defaultPauseTimeMins;
        cat.isNoteRequired = сategory.isNoteRequired;
        cat.ticketAmountToCountAverageWaitingServiceTime = сategory.ticketAmountToCountAverageWaitingServiceTime;
        cat.canOnHour = сategory.canOnHour;
        cat.isInheritanceFullName = true;
        cat.isInheritanceShortName = true;
        cat.isInheritanceCategoryTag = true;
        cat.isInheritanceReservationGroupId = true;
        cat.isInheritanceLocationId = true;
        cat.isInheritanceTicketLimit = true;
        cat.isInheritanceTimePerTicket = true;
        cat.isInheritanceIsBlocked = true;
        cat.isInheritanceIsAutomaticallyReset = true;
        cat.isInheritanceBlockingMessageActivity = true;
        cat.isInheritanceBlockingMessageManual = true;
        cat.isInheritanceCanBeReserved = true;
        cat.isInheritanceCanMobile = true;
        cat.isInheritanceCanOnHour = true;
        cat.isInheritanceETicketRedirect = true;
        cat.isInheritanceReservationIntervalMins = true;
        cat.isInheritanceMaxServiceTime = true;
        cat.isInheritanceDefaultPauseTimeMins = true;
        cat.isInheritanceIsNoteRequired = true;
        cat.isInheritanceWorkingType = true;
        cat.isInheritanceAvgTimeBlock = true;
        cat.isInheritanceAvgTimeBlockText = true;
        cat.isInheritanceSmsCategory = true;
        cat.isInheritanceSendSmsOnReservation = true;
        cat.isInheritanceSendSmsOnPrint = true;
        cat.isInheritanceSendSmsOnBeforeCall = true;
        cat.isInheritanceSendSmsOnTicketCall = true;
        cat.isInheritanceConditionalTicket = true;
        cat.isInheritanceConditionalTicketText = true;
        cat.isInheritanceAdditionalPrint = true;
        cat.isInheritanceAdditionalPrintText = true;
        cat.isInheritancePrintedTicketAdditionalText = true;
        cat.isInheritanceTicketAmountToCountAverageWaitingServiceTime = true;
        cat.isInheritanceSystemCategoryId = true;

        return cat;
    }
}
