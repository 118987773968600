<div *ngIf="checkConstraints()">
    <div class="card shadow mb-4">
        <div class="card-header py-3">
            <div class="row">
                <h5 class="col-8 m-0 font-weight-bold text-primary">{{ 'employees-list' | translate }}</h5>
                <div class="text-right col-4">
                    <button type="button" class="btn btn-primary btn-circle btn-sm"
                        (click)="router.navigate(['employee'])" matTooltip="{{ 'add' | translate }}"
                        tooltipClass="tooltip-primary">
                        <i class="fas fa-fw fa-plus"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body">
            <app-employees-list></app-employees-list>
        </div>
    </div>

    <div class="card shadow mb-4">
        <div class="card-header py-3">
            <div class="row">
                <h5 class="col-8 m-0 font-weight-bold text-primary">{{ 'specialisations' | translate }}</h5>
                <div class="text-right col-4">
                    <button type="button" class="btn btn-primary btn-circle btn-sm"
                        (click)="router.navigate(['speciality'])" matTooltip="{{ 'add' | translate }}"
                        tooltipClass="tooltip-primary">
                        <i class="fas fa-fw fa-plus"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body">
            <app-specialities></app-specialities>
        </div>
    </div>

    <div class="card shadow mb-4">
        <div class="card-header py-3">
            <div class="row">
                <h5 class="col-8 m-0 font-weight-bold text-primary">{{ 'offices' | translate }}</h5>
                <div class="text-right col-4">
                    <button type="button" class="btn btn-primary btn-circle btn-sm"
                        (click)="router.navigate(['office'])" matTooltip="{{ 'add' | translate }}"
                        tooltipClass="tooltip-primary">
                        <i class="fas fa-fw fa-plus"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body">
            <app-offices></app-offices>
        </div>
    </div>

    <div class="card shadow mb-4">
        <div class="card-header py-3">
            <div class="row">
                <h5 class="col-8 m-0 font-weight-bold text-primary">{{ 'office-desk-map' | translate }}</h5>
                <div class="text-right col-4">
                    <button type="button" class="btn btn-primary btn-circle btn-sm"
                        (click)="router.navigate(['addofficedesk'])" matTooltip="{{ 'add' | translate }}"
                        tooltipClass="tooltip-primary">
                        <i class="fas fa-fw fa-plus"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body">
            <app-office-desk></app-office-desk>
        </div>
    </div>

    <div class="card shadow mb-4">
        <div class="card-header py-3">
            <div class="row">
                <h5 class="col-8 m-0 font-weight-bold text-primary">{{ 'news' | translate }}</h5>
                <div class="text-right col-4">
                    <button type="button" class="btn btn-primary btn-circle btn-sm"
                        (click)="router.navigate(['singleNews'])" matTooltip="{{ 'add' | translate }}"
                        tooltipClass="tooltip-primary">
                        <i class="fas fa-fw fa-plus"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body">
            <app-news></app-news>
        </div>
    </div>

    <app-configuration-schedule></app-configuration-schedule>
    <app-work-schedule-logo></app-work-schedule-logo>
</div>

<app-licence-info *ngIf="!checkConstraints()" [title]="'work-schedule'">
</app-licence-info>