<div class="form-group">
    <div class="input-group">
        <mat-form-field color="primary">
            <mat-label>{{ 'language-list' | translate }}</mat-label>
            <mat-select [formControl]="languageMultiCtrl" [multiple]="true" #multiSelect [disabled]="disableLanguageSelection">
                <mat-option>
                    <ngx-mat-select-search [showToggleAllCheckbox]="true" (toggleAll)="toggleSelectAll($event)"
                        [formControl]="languageMultiFilterCtrl"
                        toggleAllCheckboxTooltipMessage="{{ 'select-unselect-all' | translate }}"
                        [toggleAllCheckboxTooltipPosition]="'above'"
                        placeholderLabel="{{ 'search-language' | translate }}"
                        noEntriesFoundLabel="{{ 'no-matching-language' | translate }}">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let language of filteredLanguagesMulti | async" [value]="language">
                    {{ language.name | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <p *ngIf="languageMultiCtrl.value == null || languageMultiCtrl?.value.length == 0">
        {{ 'no-selected-language' | translate }}</p>
    <ul *ngFor="let language of languageMultiCtrl?.value">
        <li>
            {{ language.name | translate }}
        </li>
    </ul>
</div>