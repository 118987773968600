import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { QcloudRole } from '../../../../qcloud-models/qcloud-role/qcloud-role';
import { AuthService } from '../auth/auth.service';


@Injectable({
  providedIn: 'root'
})
export class QcloudRoleService {

  appBase: string;
  endpoint: string = "qcloudRoles";

  constructor(private http: HttpClient, private authService: AuthService) { }

  public setAppBase(appBase: string) {
    this.appBase = appBase;
  }

  getQcloudRoles(): Observable<QcloudRole[]> {
    return this.http.get<QcloudRole[]>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}`);
  }
  getSystemQcloudRoles(): Observable<QcloudRole[]> {
    return this.http.get<QcloudRole[]>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/system`);
  }
  getQcloudRole(roleId: number): Observable<QcloudRole> {
    return this.http.get<QcloudRole>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/${roleId}`);
  }

  addQcloudRole(data: QcloudRole): Observable<QcloudRole> {
    return this.http.post<QcloudRole>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}`, data);
  }
  updateQcloudRole(data: QcloudRole): Observable<QcloudRole> {
    return this.http.put<QcloudRole>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}`, data);
  }

  addSystemQcloudRole(data: QcloudRole): Observable<QcloudRole> {
    return this.http.post<QcloudRole>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/system`, data);
  }
  updateSystemQcloudRole(data: QcloudRole): Observable<QcloudRole> {
    return this.http.put<QcloudRole>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/system`, data);
  }
  deleteQcloudRole(ldapRoleId: number) {
    return this.http.delete(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/${ldapRoleId}`);
  }


}
