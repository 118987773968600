<div class="modal-dialog" role="document">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">{{ title | translate}}</h5>
            <button class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <p class="bg-warning text-primary" style="padding: 0.5rem;">{{ warningMessage | translate}}</p>
            <p class="">{{ question | translate}}</p>
        </div>
        <div class="modal-footer">
            <button class="btn btn-secondary" data-dismiss="modal">{{ 'cancel' | translate }}</button>
            <button class="btn btn-success" data-dismiss="modal" (click)="send()">{{ confirm | translate }}</button>
        </div>
    </div>
</div>