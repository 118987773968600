import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { SystemCategory } from '../../../../qcloud-models/category/system-category.model';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs/internal/Observable';

const DEFAULT_TIMEOUT = 3000;

@Injectable({
  providedIn: 'root'
})
export class SystemCategoryService {

  appBase: string;
  endpoint: string = "systemCategories";
  constructor(private http: HttpClient, private authService: AuthService) { }

  public setAppBase(appBase: string) {
    this.appBase = appBase;
  }

  getCategories(callback: (data: SystemCategory[]) => any, onError: () => any) {
    this.http.get(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  getCategory(categoryId: number): Observable<SystemCategory> {
    return this.http.get<SystemCategory>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/${categoryId}`);
  }

  addCategory(category: SystemCategory) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}`, category);
  }

  editCategory(category: SystemCategory) {
    return this.http.put(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/${category.id}`,
      category);
  }

  deleteCategory(categoryId: number) {
    return this.http.delete(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/${categoryId}`);
  }

  isCategoryTagUnique(categoryTag: string, systemCategoryId: number): Observable<boolean> {
    var body = {
      categoryTag: categoryTag,
      systemCategoryId: systemCategoryId
    };
    return this.http.post<boolean>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/is-tag-unique`, body);
  }
}
