import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { LdapRole } from '../../../../qcloud-models/ldap/ldap-role';


@Injectable({
  providedIn: 'root'
})
export class LdapRoleService {

  appBase: string;

  constructor(private http: HttpClient, private authService: AuthService) { }

  public setAppBase(appBase: string) {
    this.appBase = appBase;
  }

  getLdapRoles(): Observable<LdapRole[]> {
    return this.http.get<LdapRole[]>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/ldapRoles`);
  }
  getLdapRole(roleId: number): Observable<LdapRole> {
    return this.http.get<LdapRole>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/ldapRoles/${roleId}`);
  }

  addLdapRole(data: LdapRole): Observable<LdapRole> {
    return this.http.post<LdapRole>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/ldapRoles`, data);
  }
  updateLdapRole(data: LdapRole): Observable<LdapRole> {
    return this.http.put<LdapRole>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/ldapRoles`, data);
  }
  deleteLdapRole(ldapRoleId: number) {
    return this.http.delete(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/ldapRoles/${ldapRoleId}`);
  }
}
