import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { Dictionary } from 'projects/qcloud-models/dictionary';
import { DeskOpinionData } from 'projects/qcloud-models/survey/desk-opinion-data.model';
import { SurveyService } from 'projects/qcloud-rest-client/src/lib/survey.service';

@Component({
  selector: 'app-desk-customer-survey-statistics',
  templateUrl: './desk-customer-survey-statistics.component.html',
  styleUrls: ['./desk-customer-survey-statistics.component.css']
})
export class DeskCustomerSurveyStatisticsComponent implements OnInit {

  @Input() desks: Desk[];
  desksIds: number[] = new Array<number>();
  happy: number[] = new Array<number>()
  ambivalent: number[] = new Array<number>()
  unhappy: number[] = new Array<number>()
  opinions: number[] = new Array<number>()
  happyWidth: number[];
  deskOpinions: Dictionary<DeskOpinionData>;
  today: Date = new Date();

  constructor(private surveyService: SurveyService) { }

  ngOnInit(): void {
    this.desksIds.push(0);
    this.desks.map(desk => {
      this.desksIds.push(desk.id);
      return;
    })
    let endDay = new Date(this.today);
    endDay.setDate(endDay.getDate() + 1);
    this.surveyService.getOpinions(this.today, endDay,
      data => {
        this.deskOpinions = data.deskOpinions;
        let noDesk = new Desk();
        noDesk.id = 0;
        noDesk.fullName = "Brak stanowiska"
        this.desks.unshift(noDesk)
        this.visualizeDeskOpinions();
      },
      err => {
      }
    )
  }

  visualizeDeskOpinions() {
    this.happy = Array.from({ length: this.desksIds.length }, (_, index) => {
      return this.deskOpinions.hasOwnProperty(this.desksIds[index]) ? this.deskOpinions[this.desksIds[index]].happy : 0;
    });
    this.ambivalent = Array.from({ length: this.desksIds.length }, (_, index) => {
      return this.deskOpinions.hasOwnProperty(this.desksIds[index]) ? this.deskOpinions[this.desksIds[index]].neutral : 0;
    });
    this.unhappy = Array.from({ length: this.desksIds.length }, (_, index) => {
      return this.deskOpinions.hasOwnProperty(this.desksIds[index]) ? this.deskOpinions[this.desksIds[index]].angry : 0;
    });
    this.opinions = this.happy.map((num, idx) => {
      return num + this.ambivalent[idx] + this.unhappy[idx];
    });
  }

}
