import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { OfficeDeskRelation } from 'projects/qcloud-models/grafik/office/office-desk-relation';
import { Office } from 'projects/qcloud-models/grafik/office/office.model';
import { DeskService } from 'projects/qcloud-rest-client/src/lib/desk.service';
import { OfficeService } from 'projects/qcloud-rest-client/src/lib/grafik/office.service';

@Component({
  selector: 'app-office-desk-edit',
  templateUrl: './office-desk-edit.component.html',
  styleUrls: ['./office-desk-edit.component.css']
})
export class OfficeDeskEditComponent implements OnInit {

  officeDeskRelations: OfficeDeskRelation[];
  offices: Office[];
  desks: Desk[];
  constructor(private officeService: OfficeService, private deskService: DeskService,
    private translate: TranslateService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getData();
  }

  private getData() {
    this.officeService.getOffices((offices) => {
      this.offices = offices;
      this.deskService.getDesks((desks) => {
        this.desks = desks;
        this.officeService.getOfficeDeskRelations((officeDeskRelations) => {
          this.officeDeskRelations = officeDeskRelations;
        }, () => { })
      }, () => { });
    }, () => { })

  }

  public add() {
    this.officeDeskRelations.push(new OfficeDeskRelation());
  }

  public onDelete(i: number) {
    this.officeDeskRelations.splice(i, 1);
  }

  public onSubmit() {
    this.officeService.setOfficeDeskRelations(this.officeDeskRelations, () => {
      this.translate.get('edit-office-maps-success').subscribe((res: string) => {
        this.toastr.success(res);
      });
    }, () => {
      this.translate.get('edit-office-maps-error').subscribe((res: string) => {
        let error = res;
        this.translate.get('repeat').subscribe((res: string) => {
          this.toastr.error(res, error);
        })
      });
    })
  }
}
