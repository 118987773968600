import { Component, OnInit } from '@angular/core';
import { QcloudConsoleService } from '../../qcloud-console.service';

@Component({
  selector: 'lib-queue-state-all-tickets',
  templateUrl: './queue-state-all-tickets.component.html',
  styleUrls: ['./queue-state-all-tickets.component.css']
})
export class QueueStateAllTicketsComponent implements OnInit {

  queueState: number;

  constructor(private consoleService: QcloudConsoleService) {
    this.consoleService.queueState.subscribe((val) => {
      let qs = 0;
      var callableCategories = this.consoleService.getCategoriesToCallTicket();
      callableCategories.forEach((category) => {
        var ticketCount = val.queueStateByCategory.get(category.id) ?? 0;
        if (ticketCount) {
          qs += ticketCount;
        }
      });

      var ticketCount = val.queueStateByCategory.get(-1) ?? 0;
      qs += ticketCount;
      this.queueState = qs;
    });
  }

  ngOnInit(): void {
  }

}
