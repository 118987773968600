import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { environment } from 'projects/qcloud-web/src/environments/environment';

@Component({
  selector: 'app-file-path',
  templateUrl: './file-path.component.html',
  styleUrls: ['./file-path.component.css']
})
export class FilePathComponent implements OnInit, OnChanges {

  @Input() path: string;
  @Output() pathToEmit = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  goBack() {
    var n = this.path.length;
    if( environment.production) {
      n = this.path.lastIndexOf("/");
    }
    else {
      n = this.path.lastIndexOf("\\");
    }

    this.path = this.path.substring(0, n);
    this.pathToEmit.emit(this.path);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.path = changes.path.currentValue;
  }

}
