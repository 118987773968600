<div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800">{{ 'select-location' | translate }}</h1>
</div>

<div class="row">
    <div *ngFor="let location of locations; let i = index" class="col-xl-3 col-md-6 mb-4">
        <div *ngIf="location.id == authService.getLocationId()" class="primary-background card border-left-primary shadow h-100 py-2">
            <div class="card-body">
                <div class="no-gutters align-items-center">
                    <div class="mr-2">
                        <div class="font-weight-bold text-primary text-uppercase">{{ location.locationName}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="location.id != authService.getLocationId()" class="pointer card border-left-secondary shadow h-100 py-2"
            data-toggle="modal" data-target="#enterPasswordModal" (click)="selectedLocationId = location.id">
            <div class="card-body">
                <div class="no-gutters align-items-center">
                    <div class="mr-2">
                        <div class="font-weight-bold text-secondary text-uppercase">{{ location.locationName}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="enterPasswordModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                {{ 'enter-password' | translate }}
                <input type="password" class="form-control form-control-user"
                    #password="ngModel" name="password" [(ngModel)]="qcloudRestClientService.loginForm.password">
            </div>
            <div class="modal-footer">
                <button class="btn btn-danger" data-dismiss="modal">{{ 'cancel' | translate }}</button>
                <button class="btn btn-primary" data-dismiss="modal" (click)="changeLocation()">
                    {{ 'change-location' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>