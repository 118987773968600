import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth/auth.service';
import { Translation } from 'projects/qcloud-models/translation/translation.model';
import { Observable } from 'rxjs';
import { PersonalisationTranslation } from 'projects/qcloud-models/translation/personalisation-translation';
import { CategoryTranslation } from 'projects/qcloud-models/translation/category-translation.model';
import { DeskTranslation } from 'projects/qcloud-models/translation/desk-translation.model';
import { DeviceGroupTranslation } from 'projects/qcloud-models/translation/device-group-translation.model';
import { DeviceGroupNames } from 'projects/qcloud-models/translation/device-group-names.model';
import { Language } from 'projects/qcloud-models/translation/language.model';
import { TicketTranslation } from 'projects/qcloud-models/translation/ticket-translation';
import { ReservationButtonTextTranslation } from 'projects/qcloud-models/translation/reservation-button-text-translation';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  appBase: string;

  constructor(private http: HttpClient, private authService: AuthService) { }

  public setAppBase(appBase: string) {
    this.appBase = appBase;
  }

  getTranslations(): Observable<Translation[]> {
    return this.http.get<Translation[]>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/translation`);
  }

  addTranslation(translation: Translation) {
    return this.http.post( 
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/translation`, translation);
  }

  deleteTranslation(translationId: number) {
    return this.http.delete(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/translation/${translationId}`);
  }

  setPersonalisationTranslation(personalisationTranslation: PersonalisationTranslation[]) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/translation/personalisation`,
      personalisationTranslation);
  }

  deletePersonalisationTranslation(personalisationTranslationId: number) {
    return this.http.delete(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/translation/personalisation/`
      + personalisationTranslationId);
  }

  getPersonalisationTranslation(): Observable<PersonalisationTranslation[]> {
    return this.http.get<PersonalisationTranslation[]>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/translation/personalisation`);
  }

  setTicketTranslation(ticketTranslation: TicketTranslation[]) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/translation/ticket`,
      ticketTranslation);
  }

  deleteTicketTranslation(ticketTranslationId: number) {
    return this.http.delete(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/translation/ticket/`
      + ticketTranslationId);
  }

  getTicketTranslation(): Observable<TicketTranslation[]> {
    return this.http.get<TicketTranslation[]>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/translation/ticket`);
  }

  setCategoryTranslation(categoryTranslation: CategoryTranslation[]) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/translation/category`,
      categoryTranslation);
  }

  getCategoryTranslation(): Observable<CategoryTranslation[]> {
    return this.http.get<CategoryTranslation[]>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/translation/category`);
  }

  setDeskTranslation(deskTranslation: DeskTranslation[]) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/translation/desk`,
      deskTranslation);
  }

  getDeskTranslation(): Observable<DeskTranslation[]> {
    return this.http.get<DeskTranslation[]>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/translation/desk`);
  }

  getDeviceGroupsTranslation(): Observable<DeviceGroupTranslation[]> {
    return this.http.get<DeviceGroupTranslation[]>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/translation/deviceGroups`);
  }

  setDeviceGroupTranslation(deviceGroupTranslations: DeviceGroupTranslation[]) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/translation/deviceGroups`,
      deviceGroupTranslations);
  }

  getDeviceGroupNamesToTranslate(): Observable<DeviceGroupNames[]> {
    return this.http.get<DeviceGroupNames[]>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/translation/deviceGroupNames`);
  }

  getLanguages(): Observable<Language[]> {
    return this.http.get<Language[]>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/translation/languages`);
  }

  getReservationButtonTextTranslations(): Observable<ReservationButtonTextTranslation[]> {
    return this.http.get<ReservationButtonTextTranslation[]>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/translation/reservationButtonText`);
  }

  setReservationButtonTextTranslations(reservationButtonTextTranslations: ReservationButtonTextTranslation[]) {
    return this.http.post<ReservationButtonTextTranslation[]>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/translation/reservationButtonText`, reservationButtonTextTranslations);
  }
}
