import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-categories-column',
  templateUrl: './categories-column.component.html',
  styleUrls: ['./categories-column.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CategoriesColumnComponent implements OnInit {

  @Input() categories;

  constructor() { }

  ngOnInit(): void {
  }

  sortByTag(categories: any[]) {
    if (categories)
      return categories.sort((cat1, cat2) => cat1.categoryTag.localeCompare(cat2.categoryTag));
  }

}
