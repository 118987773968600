import { Component, OnInit } from '@angular/core';
import { DeviceService } from 'projects/qcloud-rest-client/src/lib/device.service';
import { Device } from 'projects/qcloud-models/device/device.model';
import { DeviceType } from 'projects/qcloud-models/device/device-type.enum';
import { MultimediaService } from 'projects/qcloud-rest-client/src/lib/multimedia.service';
import { Tag } from 'projects/qcloud-models/multimedia-device/tag.model';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-multimedia-devices',
  templateUrl: './multimedia-devices.component.html',
  styleUrls: ['./multimedia-devices.component.css']
})
export class MultimediaDevicesComponent implements OnInit {

  ctx = this;
  multimediaDevices: Device[];
  tagsWithDevices: Tag[];
  tags: Tag[];
  tagsByDevice: { [key: string]: Tag[]; } = {};
  DeviceType = DeviceType;

  constructor(private deviceService: DeviceService, private service: MultimediaService,
    private translate: TranslateService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getMultimediaDevices();
    this.getTags();
    this.getTagsByDevice();
  }

  getMultimediaDevices() {
    this.deviceService.getDevices((data) => {
      this.multimediaDevices = data.filter(this.isMultimediaDevice);
    },
      () => { }
    );
  }

  isMultimediaDevice(element: Device): boolean {
    return element.deviceType == DeviceType.DeskDisplay || 
      element.deviceType == DeviceType.MainDisplay || 
      element.deviceType == DeviceType.TicketDispenser
  }

  getTags() {
    let error = false;
    this.service.getTagsWithDevices().subscribe(
      res => this.tagsWithDevices = res.sort((tag1, tag2) => tag1.id - tag2.id),
      err => {
        error = true;
      }
    );
    this.service.getTags().subscribe(
      res => this.tags = res,
      err => {
        error = true;
      }
    );
    if (error) {
      this.translate.get('get-tags-error').subscribe((res: string) => {
        let error = res;
        this.translate.get('repeat').subscribe((res: string) => {
          this.toastr.error(res, error);
        });
      });
    }
  }

  getTagsByDevice() {
    this.service.getDevicesTags().subscribe(
      res => {
        this.tagsByDevice = res;
        console.log(this.tagsByDevice);
      },
      err => {
        this.translate.get('get-devices-tags-error').subscribe((res: string) => {
          let error = res;
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

}
