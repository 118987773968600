<div class="form-group">
    <div class="font-weight-bold">{{ title | translate}}</div>
    <div class="input-group">
        <mat-form-field color="primary">
            <mat-select [formControl]="deskMultiCtrl" [multiple]="true" #multiSelect
                (selectionChange)="emitDesks($event.value)">
                <mat-option>
                    <ngx-mat-select-search [showToggleAllCheckbox]="true" (toggleAll)="toggleSelectAll($event)"
                        [formControl]="deskMultiFilterCtrl"
                        toggleAllCheckboxTooltipMessage="{{ 'select-unselect-all' | translate }}"
                        [toggleAllCheckboxTooltipPosition]="'above'" placeholderLabel="{{ 'search-desk' | translate }}"
                        noEntriesFoundLabel="{{ 'no-matching-desk' | translate }}">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let desk of filteredDesksMulti | async" [value]="desk">
                    {{ desk.fullName }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <p *ngIf="deskMultiCtrl.value == null || deskMultiCtrl?.value.length == 0">
        {{ noSelectedDesks | translate }}</p>
    <div *ngIf="displayDesks">
        <ul *ngFor="let desk of deskMultiCtrl?.value">
            <li>
                {{ desk.fullName }}
            </li>
        </ul>
    </div>
</div>