import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Ticket } from 'projects/qcloud-models/console/ticket';
import { QcloudConsoleService } from '../../qcloud-console.service';

@Component({
  selector: 'lib-cancelled-tickets-modal',
  templateUrl: './cancelled-tickets-modal.component.html',
  styleUrls: ['./cancelled-tickets-modal.component.css']
})
export class CancelledTicketsModalComponent implements OnInit {
  @Output() hideInteractionModel = new EventEmitter<boolean>();
  cancelledTickets: Ticket[];

  constructor(private consoleService: QcloudConsoleService) {
    this.consoleService.cancelledTickets.subscribe((val) => this.cancelledTickets = val);
  }

  ngOnInit(): void {
  }

  jsonToNote(ticket: Ticket){
    return JSON.parse(ticket.metadataJson).Note;
  }

  jsonToNoteShort(ticket: Ticket){
    let text="";
    try{
      text = JSON.parse(ticket.metadataJson).Note;
      if(text.length > 20){
      text = text.substring(0, 20).concat('...');
      }
    }catch{}
      return text
  }

  callCancelledTicket(ticketId: number) {
    this.consoleService.callCancelledTicket(ticketId);
    this.hideInteractionModel.emit(true);
  }

}
