import { Component, OnInit } from '@angular/core';
import { error } from 'console';
import { Category } from 'projects/qcloud-models/category/category.model';
import { SystemCategory } from 'projects/qcloud-models/category/system-category.model';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { Device } from 'projects/qcloud-models/device/device.model';
import { LocationData } from 'projects/qcloud-models/location/location.model';
import { QcloudLog } from 'projects/qcloud-models/logs/qcloud-log';
import { User } from 'projects/qcloud-models/user/user.model';
import { AuditLogClientService } from 'projects/qcloud-rest-client/src/lib/audit-log-client.service';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { SystemCategoryService } from 'projects/qcloud-rest-client/src/lib/category/system-category.service';
import { DeskService } from 'projects/qcloud-rest-client/src/lib/desk.service';
import { DeviceService } from 'projects/qcloud-rest-client/src/lib/device.service';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { UserService } from 'projects/qcloud-rest-client/src/lib/user.service';
import { UserPersonalInfoDto } from '../../../../../../qcloud-models/user/user-personal-info-dto.model';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class LogsComponent implements OnInit {
  public dateFrom: Date;
  public dateTo: Date;
  public locationId: number | undefined;
  public locations: LocationData[];
  public entityType: number;
  public logs: QcloudLog[];
  public userPersonalInfoDtos: UserPersonalInfoDto[];
  public category: Category[];
  public systemCategory: SystemCategory[];
  public desks: Desk[];
  public devices: Device[];
  EntityTypeEnums: { key: number, value: string }[] = [
    { key: 1, value: 'User' },
    { key: 2, value: 'Device' },
    { key: 3, value: 'Category' },
    { key: 4, value: 'SystemCategory' },
    { key: 5, value: 'Desk' },
    { key: 6, value: 'SystemDesk' },
    { key: 7, value: "Reservation" }];

  constructor(private auditLogService: AuditLogClientService, private locationService: LocationService,
    private userService: UserService,
    private categoryService: CategoryService,
    private systemCategoryService: SystemCategoryService,
    private deskService: DeskService,
    private deviceService: DeviceService) {
    const today = new Date();
    this.dateTo = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
    this.dateFrom = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  }

  ngOnInit(): void {
    this.locationService.getAll().subscribe(res => this.locations = res);
    this.userService.getUserPersonalInfos(data => {
      this.userPersonalInfoDtos = data;
    }, error => { });
    this.categoryService.getSystemCategories((data) => {
      this.category = data;
    }, () => { });
    this.systemCategoryService.getCategories((data) => {
      this.systemCategory = data;
    }, () => { });
    this.deskService.getSystemDesks((data) => {
      this.desks = data;
    }, () => { });
    this.deviceService.getSystemDevices((data) => {
      this.devices = data;
    }, () => { });
  }

  search(): void {
    let dt = this.dateTo;
    let df = this.dateFrom;
    this.auditLogService.GetLogs(df, dt, this.locationId, this.entityType).subscribe(res => this.logs = res);
  }

  getEntityType(key: number): string {
    let entityType = this.EntityTypeEnums.find(e => e.key === key);
    return entityType ? entityType.value : '';
  }

  getActionType(key: number): string {
    let actionType = {
      0: 'Create',
      1: 'Update',
      2: 'Delete',
      3: 'Login'
    };
    return actionType[key] || '';
  }

  getUserName(userId: string): string {
    let user = this.userPersonalInfoDtos.find(u => u.id === userId);
    return user ? user.userName : '';
  }

  getEntityName(log: QcloudLog): string {
    let entityName = '';
    if (log.entityType === 1) {
      entityName = this.getUserName(log.entityId);
    } else if (log.entityType === 2) {
      let device = this.devices.find(d => d.guid == log.entityId);
      entityName = device ? device.deviceName : '';
    } else if (log.entityType === 3) {
      let category = this.category.find(c => c.id == Number(log.entityId));
      entityName = category ? category.fullName : '';
    } else if (log.entityType === 4) {
      let systemCategory = this.systemCategory.find(sc => sc.id == Number(log.entityId));
      entityName = systemCategory ? systemCategory.fullName : '';
    } else if (log.entityType === 5) {
      let desk = this.desks.find(d => d.id == Number(log.entityId));
      entityName = desk ? desk.name : '';
    } else if (log.entityType === 6) {
      let location = this.locations.find(l => l.id == Number(log.entityId));
      entityName = location ? location.locationName : '';
    }
    else if (log.entityType === 6) {
      entityName = log.entityId + " " + log.payload;
    }
    return entityName;
  }
}

