<div class="card shadow mb-4">
    <div class="card-header py-3">
        <div class="row">
            <h5 class="col-8 m-0 font-weight-bold text-primary">{{ 'audit' | translate }}</h5>
            <div class="text-right col-4">

            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <div class="form-inline">
                <mat-form-field class="ml-2">
                    <input matInput [matDatepicker]="dateFromPicker" placeholder="Date From" [(ngModel)]="dateFrom">
                    <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
                    <mat-datepicker #dateFromPicker></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="ml-2">
                    <input matInput [matDatepicker]="dateToPicker" placeholder="Date To" [(ngModel)]="dateTo">
                    <mat-datepicker-toggle matSuffix [for]="dateToPicker"></mat-datepicker-toggle>
                    <mat-datepicker #dateToPicker></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="ml-2">
                    <mat-select placeholder="Location" [(ngModel)]="locationId">
                        <mat-option [value]="undefined">
                            None
                        </mat-option>
                        <mat-option *ngFor="let item of locations" [value]="item.id">
                            {{ item.locationName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="ml-2">
                    <mat-select placeholder="Entity Type" [(ngModel)]="entityType">
                        <mat-option [value]="undefined">
                            None
                        </mat-option>
                        <mat-option *ngFor="let item of EntityTypeEnums" [value]="item.key">
                            {{ item.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button class="btn btn-primary m-1" type="submit" (click)="search()">Search</button>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <td>#</td>
                        <td>{{ 'location' | translate }}</td>
                        <td>{{ 'timestamp' | translate }}</td>
                        <td>{{ 'user' | translate }}</td>
                        <td>{{ 'entityType' | translate }}</td>
                        <td>{{ 'actionType' | translate }}</td>
                        <!-- <td>{{ 'payload' | translate }}</td> -->
                        <td>{{ 'entityId' | translate }}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let log of logs; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>{{ log.locationId ? log.locationId : '' }}</td>
                        <td>{{ log.timeStamp | date:'yyyy-MM-dd HH:mm:ss' }}</td>
                        <td>{{ getUserName(log.userGuid) }}</td>
                        <td>{{ getEntityType(log.entityType) }}</td>
                        <td>{{ getActionType(log.actionType) }}</td>
                        <!-- <td>{{ log.payload }}</td> -->
                        <td>{{ getEntityName(log) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>