import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';
import { ProcessedTicketsCount } from 'projects/qcloud-models/statistics/processed-tickets-count';

@Component({
  selector: 'app-ticket-result-statistics',
  templateUrl: './ticket-result-statistics.component.html',
  styleUrls: ['./ticket-result-statistics.component.css']
})
export class TicketResultStatisticsComponent implements OnInit {

  @Input() processedTicketsCount: ProcessedTicketsCount;
  labels: string[];
  ticketAmount: number;

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    let ctx = document.getElementById("ticketResultPieChart");
    this.setLabels();
    let data = [this.processedTicketsCount.acceptedTicketsCount,
      this.processedTicketsCount.cancelledTicketsCount,
      this.processedTicketsCount.redirectedTicketsCount];
    this.ticketAmount = data.reduce((a, b) => a + b, 0);
    let ticketResultPieChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: this.labels,
        datasets: [{
          data: data,
          backgroundColor: ['#1cc88a', '#d9534f', '#4e73df'],
          hoverBackgroundColor: ['#17a673', '#d33b36', '#2e59d9'],
          hoverBorderColor: "rgba(234, 236, 244, 1)",
        }],
      },
      options: {
        maintainAspectRatio: false,
        tooltips: {
          backgroundColor: "rgb(255,255,255)",
          bodyFontColor: "#858796",
          borderColor: '#dddfeb',
          borderWidth: 1,
          xPadding: 15,
          yPadding: 15,
          displayColors: false,
          caretPadding: 10,
        },
        legend: {
          display: false
        },
        cutoutPercentage: 80,
        elements: {
          center: {
          text: this.ticketAmount,
          color: '#4e73df'
          }
        }
      }
    });
  }

  setLabels() {    
    this.labels = new Array(3);
    this.setLabelsTranslations();
    
    this.translate.onLangChange.subscribe(
      () => {
        this.setLabelsTranslations();
      }
    );
  }

  setLabelsTranslations() {
    this.translate.get(["accepted", "cancelled", "redirected"]).subscribe(
      (translations: {[key: string]: string}) => {
        this.labels[0] = translations['accepted'];
        this.labels[1] = translations['cancelled'];
        this.labels[2] = translations['redirected'];
      }
    );
  }

}
