import { Component, OnInit, Input } from '@angular/core';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { UntypedFormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { Category } from 'projects/qcloud-models/category/category.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reset-categories',
  templateUrl: './reset-categories.component.html',
  styleUrls: ['./reset-categories.component.css']
})
export class ResetCategoriesComponent implements OnInit {

  @Input() categories: Category[];
  protected initialCategories: Category[];
  public categoryMultiCtrl: UntypedFormControl;
  formSubmitted: boolean = false;
  color: ThemePalette = "primary";
  title: string = 'auto-reset-categories';
  noSelectedCategories: string = 'no-automatic-reset-categories';

  constructor(private categoryService: CategoryService, private toastr: ToastrService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    
    this.initialCategories = new Array<Category>();
    for (let i = 0; i < this.categories.length; i++) {
      if (this.categories[i].isAutomaticallyReset) {
        this.initialCategories.push(this.categories[i]);
      }
    }
    this.categoryMultiCtrl = new UntypedFormControl(this.initialCategories);
  }

  onSubmit() {
    this.categories.forEach(category => {
      category.isAutomaticallyReset = this.categoryMultiCtrl.value.includes(category);
    });
    this.categoryService.setAutomaticalReset(this.categories).subscribe(
      res => {
        this.translate.get('automatic-category-reset-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.translate.get('automatic-category-reset-error').subscribe((res: string) => {
          let error = res;
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      });
  }
}
