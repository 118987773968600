import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { PlaylistItem } from 'projects/qcloud-models/multimedia/playlistItem.model';
import { FileDuration } from 'projects/qcloud-models/fileManager/file-duration.model';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Playlist } from 'projects/qcloud-models/multimedia/playlist.model';

@Component({
  selector: 'app-playlist-layout',
  templateUrl: './playlist-layout.component.html',
  styleUrls: ['./playlist-layout.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PlaylistLayoutComponent implements OnInit {

  @Input() ctx;
  showLengthMinutes: number[];
  showLengthSeconds: number[];
  playlistItemChanged: Subject<PlaylistItem> = new Subject<PlaylistItem>();
  playlistChanged: Subject<Playlist> = new Subject<Playlist>();
  setDurationOfAllElements: boolean = false;
  allMinutes: number = 1;
  allSeconds: number = 0;

  constructor() { 
    this.playlistItemChanged.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(playlistItem => {
      this.ctx.changePlaylistItemTime(playlistItem, true);
     });
     this.playlistChanged.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(playlist => {
      this.ctx.changePlaylistName(playlist);
     });
  }

  ngOnInit(): void {
    this.showLengthMinutes = new Array<number>(this.ctx.playlist.playlistItems.length);
    this.showLengthSeconds = new Array<number>(this.ctx.playlist.playlistItems.length);
    for (let i = 0; i < this.ctx.playlist.playlistItems.length; i++) {
      this.showLengthMinutes[i] = Math.floor(this.ctx.playlist.playlistItems[i].showLength / 60);
      this.showLengthSeconds[i] = this.ctx.playlist.playlistItems[i].showLength - this.showLengthMinutes[i] * 60;
    }
  }

  getName(path: string) {
    let n = path.lastIndexOf("\\");
    return path.substring(n + 1, path.length);
  }

  changePlaylistName(playlist: Playlist) {
    if (playlist.name) {
      this.playlistChanged.next(playlist);
    }
  }

  addPlaylistItemsWithDuration(filesWithDuration: FileDuration[]) {
    filesWithDuration.forEach(fileWithDuration => {
      this.showLengthMinutes.push(fileWithDuration.minutes);
      this.showLengthSeconds.push(fileWithDuration.seconds);
    });
    this.ctx.addPlaylistItems(filesWithDuration);
  }
  
  changeOrder(index: number, direction: number) {
    [this.showLengthMinutes[index], this.showLengthMinutes[index + direction]] = 
      [this.showLengthMinutes[index + direction], this.showLengthMinutes[index]];
    [this.showLengthSeconds[index], this.showLengthSeconds[index + direction]] = 
      [this.showLengthSeconds[index + direction], this.showLengthSeconds[index]];
    this.ctx.changeOrder(index, direction);
  }

  deleteItemFromPlaylist(item: PlaylistItem, index: number) {
    this.showLengthMinutes.splice(index, 1);
    this.showLengthSeconds.splice(index, 1);
    this.ctx.deleteItemFromPlaylist(item);
  }

  changeItemTime(item: PlaylistItem, index: number) {
    item.showLength = this.showLengthMinutes[index] * 60 + this.showLengthSeconds[index];
    this.playlistItemChanged.next(item);
  }

  setTimeOfAllElements() {
    for (let i = 0; i < this.showLengthMinutes.length; i++) {
      this.showLengthMinutes[i] = this.allMinutes;
      this.showLengthSeconds[i] = this.allSeconds;
      this.ctx.playlist.playlistItems[i].showLength = this.allMinutes * 60 + this.allSeconds;
    }
    this.ctx.changePlaylistItemsTime(this.ctx.playlist.playlistItems);
  }

  getPlaylistSpace() {
    if (this.ctx.editForm == true) {
      return (this.ctx.playlist.size / this.ctx.maxPlaylistSize) * 100;
    }
    return 0;
  }
}