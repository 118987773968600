import { Component, Input, OnChanges, OnInit, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { TimeConversionService } from '../../../shared/time-conversion.service';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { ActivityTime } from 'projects/qcloud-models/activity-time';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Category } from 'projects/qcloud-models/category/category.model';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { PutUpdateCategoryApiModel } from '../../../../../../qcloud-models/category/api-model/put-update-category-api-model.model';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  @Input() public localeCategoryId: number;
  category: Category;
  isEditForm: boolean;
  advancedMode: boolean = false;
  thumbLabel = true;
  formSubmitted: boolean = false;
  isUnblockingTimeDaysValueUpdated: boolean = false;

  checkedTicketLimit = false;
  checkedTimePerTicket = false;
  checkedService = false;
  checkedPause = false;
  addPrintText = false;
  minService = 1;
  maxService = 120;
  minPause = 5;
  maxPause = 100;
  step = 5;
  nightWork: Array<boolean> = new Array(7);
  isLockedForEditing = false;
  @ViewChildren('chkboxes') chkboxes: QueryList<any>;

  constructor(private categoryService: CategoryService, private timeConverter: TimeConversionService,
    private authService: AuthService, private router: Router, private route: ActivatedRoute,
    private translate: TranslateService, private toastr: ToastrService, public locationService: LocationService) { }

  ngOnInit(): void {
    let categoryId: number;
    this.route.params.subscribe(params => {
      categoryId = params['id']
    });
    if (this.localeCategoryId === undefined) {
      if (categoryId) {
        this.initializeEditCategory(categoryId);
      } else {
        this.isEditForm = false;
        this.isLockedForEditing = false;
        this.initializeCategory();
      }
    } else {
      this.initializeEditCategory(this.localeCategoryId);
    }
  }

  initializeEditCategory(categoryId: number) {
    this.isEditForm = true;
    this.isLockedForEditing = true;
    this.getCategory(categoryId);
  }

  initializeCategory() {
    this.category = new Category();
    this.category.locationId = +this.authService.getLocationId();
    this.category.isAutomaticallyReset = true;
    this.category.blockingMessageActivity = "Limit biletów został wyczerpany"; //TODO: get translations
    this.category.blockingMessageManual = "Limit biletów został wyczerpany";
    this.category.ticketAmountToCountAverageWaitingServiceTime = 100;
    this.category.unblockingTimeDaysValue = new Array<ActivityTime>(7);
    for (let i = 0; i < this.category.unblockingTimeDaysValue.length; i++) {
      this.category.unblockingTimeDaysValue[i] = new ActivityTime();
      this.category.unblockingTimeDaysValue[i].unblockingTime = "00:00";
      this.category.unblockingTimeDaysValue[i].blockingTime = "23:59";
      this.category.unblockingTimeDaysValue[i].is24Hours = false;
      this.category.unblockingTimeDaysValue[i].isActive = true;
      this.category.unblockingTimeDaysValue[i].isButtonAlwaysVisible = false;
      this.category.unblockingTimeDaysValue[i].day = i;
      this.nightWork[i] = false;
    }
  }

  getCategory(categoryId: number) {
    this.categoryService.getCategory(categoryId).subscribe(
      category => {
        this.category = category;
        if (this.category.ticketLimit != 0) {
          this.checkedTicketLimit = true;
        }
        if (this.checkedTicketLimit == false) {
          this.category.ticketLimit = 0;
        }
        if (this.category.timePerTicket != 0) {
          this.checkedTimePerTicket = true;
        }
        if (this.checkedTimePerTicket == false) {
          this.category.timePerTicket = 0;
        }
        if (this.category.maxServiceTime != 0) {
          this.checkedService = true;
        }
        if (this.category.defaultPauseTimeMins != 0) {
          this.checkedPause = true;
        }
        if (this.category.additionalPrint == true) {
          this.addPrintText = true;
        }
        for (let i = 0; i < this.category.unblockingTimeDaysValue.length; i++) {

          this.category.unblockingTimeDaysValue[i].blockingTime =
            this.timeConverter.dateToTime(this.category.unblockingTimeDaysValue[i].blockingTime);
          this.category.unblockingTimeDaysValue[i].unblockingTime =
            this.timeConverter.dateToTime(this.category.unblockingTimeDaysValue[i].unblockingTime);

          if (this.timeConverter.timeToMinutes(this.category.unblockingTimeDaysValue[i].blockingTime)
            < this.timeConverter.timeToMinutes(this.category.unblockingTimeDaysValue[i].unblockingTime)) {

            var tmp = this.category.unblockingTimeDaysValue[i].blockingTime;
            this.category.unblockingTimeDaysValue[i].blockingTime
              = this.category.unblockingTimeDaysValue[i].unblockingTime;
            this.category.unblockingTimeDaysValue[i].unblockingTime = tmp;
            this.nightWork[i] = true;
          }
        }
        if (this.category.systemCategory) {
          for (let i = 0; i < this.category.systemCategory.activityTimeCategory.unblockingTimeDaysValue.length; i++) {
            this.category.systemCategory.activityTimeCategory.unblockingTimeDaysValue[i].blockingTime =
              this.timeConverter.dateToTime(this.category.systemCategory.activityTimeCategory.unblockingTimeDaysValue[i].blockingTime);
            this.category.systemCategory.activityTimeCategory.unblockingTimeDaysValue[i].unblockingTime =
              this.timeConverter.dateToTime(this.category.systemCategory.activityTimeCategory.unblockingTimeDaysValue[i].unblockingTime);

            if (this.timeConverter.timeToMinutes(this.category.systemCategory.activityTimeCategory.unblockingTimeDaysValue[i].blockingTime)
              < this.timeConverter.timeToMinutes(this.category.systemCategory.activityTimeCategory.unblockingTimeDaysValue[i].unblockingTime)) {

              var tmp = this.category.systemCategory.activityTimeCategory.unblockingTimeDaysValue[i].blockingTime;
              this.category.systemCategory.activityTimeCategory.unblockingTimeDaysValue[i].blockingTime
                = this.category.systemCategory.activityTimeCategory.unblockingTimeDaysValue[i].unblockingTime;
              this.category.systemCategory.activityTimeCategory.unblockingTimeDaysValue[i].unblockingTime = tmp;
            }
          }
          this.isLockEnabled = this.isAllAttributeEqualsToSystemCategory();
          this.isLockedForEditing = this.isLockEnabled;
        } else {
          this.isLockedForEditing = false;
        }
      },
      error => {
        if (this.localeCategoryId === undefined) {
          if (this.localeCategoryId === undefined) {
            this.router.navigate(['/categories']);
          }
        }
      }
    );
  }

  checkServiceTimeInput(serviceTime: number) {
    if (serviceTime < 1) {
      this.category.maxServiceTime = 1
    }
    if (serviceTime > 120) {
      this.category.maxServiceTime = 120
    }
  }

  checkPauseTimeInput(pauseTime: number) {
    if (pauseTime < 1) {
      this.category.defaultPauseTimeMins = 1
    }
    else {
      this.category.defaultPauseTimeMins = pauseTime
    }
  }

  onSubmit() {
    this.formSubmitted = true;
    for (let i = 0; i < this.category.unblockingTimeDaysValue.length; i++) {
      if (this.nightWork[i] == true) {
        var tmp = this.category.unblockingTimeDaysValue[i].unblockingTime;
        this.category.unblockingTimeDaysValue[i].unblockingTime
          = this.category.unblockingTimeDaysValue[i].blockingTime;
        this.category.unblockingTimeDaysValue[i].blockingTime = tmp;
      }
    }
    this.category.unblockingTimeDaysValue = this.changeTimeModel(this.category.unblockingTimeDaysValue);
    if (this.isEditForm) {
      this.editCategory();
    } else {
      this.addCategory();
    }
  }

  addCategory() {
    this.category.id = 0;
    this.category.unblockingTimeDaysValue.forEach(element => {
      element.id = 0;
      element.categoryId = 0;
    });
    if (this.checkedTicketLimit == false) {
      this.category.ticketLimit = 0;
    }
    if (this.checkedTimePerTicket == false) {
      this.category.timePerTicket = 0;
    }
    this.categoryService.addCategory(this.category).subscribe(
      res => {
        if (this.localeCategoryId === undefined) {
          this.router.navigate(['/categories']);
        }
        this.formSubmitted = false;
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('category-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  editCategory() {
    if (this.checkedTicketLimit == false) {
      this.category.ticketLimit = 0;
    }
    if (this.checkedTimePerTicket == false) {
      this.category.timePerTicket = 0;
    }
    var putCategory = PutUpdateCategoryApiModel.mapFromSystemCategoryIntoPutUpdateCategoryApiModel(this.category);
    var updatingAtLocationId : number = undefined;
    if(this.localeCategoryId != undefined) {
      updatingAtLocationId = this.category.locationId;
    }
    this.categoryService.editCategory(putCategory, updatingAtLocationId).subscribe(
      res => {
        if (this.localeCategoryId === undefined) {
          this.router.navigate(['/categories']);
        }
        this.formSubmitted = false;
        this.getCategory(this.category.id);
      },
      err => {
        this.formSubmitted = false;
        this.getCategory(this.category.id);
        this.translate.get('category-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  changeTimeModel(time: Array<ActivityTime>) {
    time.forEach(element => {
      element.blockingTime = this.timeConverter.timeToDate(element.blockingTime);
      element.unblockingTime = this.timeConverter.timeToDate(element.unblockingTime);
    });
    return time;
  }

  newBasedOnExisting() {
    this.isEditForm = false;
  }

  isAnyActiveTimeNotEqualToSystemCategeryActiveTime(): boolean {
    if (!this.category.systemCategory) {
      return true;
    }
    var isEquals = true;
    for (var i = 0; i < this.category.unblockingTimeDaysValue.length; i++) {
      var systemCategoryUnblockingTimeDayValue = this.category.systemCategory.activityTimeCategory.unblockingTimeDaysValue.find(unblocking => unblocking.day == this.category.unblockingTimeDaysValue[i].day);
      isEquals = isEquals && ActivityTime.IsSettedDateTimeEquals(this.category.unblockingTimeDaysValue[i], systemCategoryUnblockingTimeDayValue);
    }
    if (this.isLockEnabled != isEquals) {
      this.isLockEnabled = this.isLockEnabled && isEquals;
      this.isLockedForEditing = this.isLockEnabled;
    }
    return isEquals;
  }

  setSystemCategoryValueOnFullName() {
    this.category.fullName = this.category.systemCategory.fullName;
    this.checkisLockEnabled();
  }
  setSystemCategoryValueOnShortName() {
    this.category.shortName = this.category.systemCategory.shortName;
    this.checkisLockEnabled();
  }
  setSystemCategoryValueOnCategoryTag() {
    this.category.categoryTag = this.category.systemCategory.categoryTag;
    this.checkisLockEnabled();
  }
  setSystemCategoryValueOnTicketLimit() {
    this.category.ticketLimit = this.category.systemCategory.ticketLimit;
    this.checkisLockEnabled();
  }
  setSystemCategoryValueOnTimePerTicket() {
    this.category.timePerTicket = this.category.systemCategory.timePerTicket;
    this.checkisLockEnabled();
  }
  setSystemCategoryValueOnMaxServiceTime() {
    this.category.maxServiceTime = this.category.systemCategory.maxServiceTime;
    this.checkisLockEnabled();
  }
  setSystemCategoryValueOnCanBeReserved() {
    this.category.canBeReserved = this.category.systemCategory.canBeReserved;
    this.checkisLockEnabled();
  }
  setSystemCategoryValueOnCanOnHour() {
    this.category.canOnHour = this.category.systemCategory.canOnHour;
    this.checkisLockEnabled();
  }
  setSystemCategoryValueOnSmsCategory() {
    this.category.smsCategory = this.category.systemCategory.smsCategory;
    this.checkisLockEnabled();
  }
  setSystemCategoryValueOnCanMobile() {
    this.category.canMobile = this.category.systemCategory.canMobile;
    this.checkisLockEnabled();
  }
  setSystemCategoryValueOnBlockingMessageActivity() {
    this.category.blockingMessageActivity = this.category.systemCategory.blockingMessageActivity;
    this.checkisLockEnabled();
  }
  setSystemCategoryValueOnBlockingMessageManual() {
    this.category.blockingMessageManual = this.category.systemCategory.blockingMessageManual;
    this.checkisLockEnabled();
  }
  setSystemCategoryValueOnIsAutomaticallyReset() {
    this.category.isAutomaticallyReset = !this.category.isAutomaticallyReset;
    this.checkisLockEnabled();
  }
  isChangedIsAutomaticallReset(): boolean {
    if (!this.category.systemCategory) {
      return false;
    }
    var valueisChangedIsAutomaticallReset = this.category.isAutomaticallyReset != this.category.systemCategory.isAutomaticallyReset;
    return valueisChangedIsAutomaticallReset;
  }
  isChangedAvgTimeBlock(): boolean {
    if (!this.category.systemCategory) {
      return false;
    }
    var valueisChangedAvgTimeBlock = this.category.avgTimeBlock != this.category.systemCategory.avgTimeBlock || (this.category.avgTimeBlockText ?? '') != (this.category.systemCategory.avgTimeBlockText ?? '');
    return valueisChangedAvgTimeBlock;
  }
  isChangedConditionalTicket(): boolean {
    if (!this.category.systemCategory) {
      return false;
    }
    var valueisChangedConditionalTicket = this.category.conditionalTicket != this.category.systemCategory.conditionalTicket || (this.category.conditionalTicketText ?? '') != (this.category.systemCategory.conditionalTicketText ?? '');
    return valueisChangedConditionalTicket;
  }
  setSystemCategoryValueConditionalTicket() {
    this.category.conditionalTicket = this.category.systemCategory.conditionalTicket;
    this.category.conditionalTicketText = this.category.systemCategory.conditionalTicketText;
    this.checkisLockEnabled();
  }
  setSystemCategoryValueAvgTimeBlock() {
    this.category.avgTimeBlock = this.category.systemCategory.avgTimeBlock;
    this.category.avgTimeBlockText = this.category.systemCategory.avgTimeBlockText;
    this.checkisLockEnabled();
  }
  setSystemCategoryValueOnPrintedTicketAdditionalText() {
    this.category.printedTicketAdditionalText = this.category.systemCategory.printedTicketAdditionalText;
    this.checkisLockEnabled();
  }
  setSystemCategoryValueOnDefaultPauseTimeMins() {
    this.category.defaultPauseTimeMins = this.category.systemCategory.defaultPauseTimeMins;
    this.checkisLockEnabled();
  }
  setSystemCategoryValueOnIsNoteRequired() {
    this.category.isNoteRequired = this.category.systemCategory.isNoteRequired;
    this.checkisLockEnabled();
  }
  setSystemCategoryValueOnETicketRedirect() {
    this.category.eTicketRedirect = this.category.systemCategory.eTicketRedirect;
    this.checkisLockEnabled();
  }
  setSystemCategoryValueOnSendSmsOnReservation() {
    this.category.sendSmsOnReservation = this.category.systemCategory.sendSmsOnReservation;
    this.checkisLockEnabled();
  }
  setSystemCategoryValueOnSendSmsOnPrint() {
    this.category.sendSmsOnPrint = this.category.systemCategory.sendSmsOnPrint;
    this.checkisLockEnabled();
  }
  setSystemCategoryValueOnSendSmsOnBeforeCall() {
    this.category.sendSmsOnBeforeCall = this.category.systemCategory.sendSmsOnBeforeCall;
    this.checkisLockEnabled();
  }
  setSystemCategoryValueOnSendSmsOnTicketCall() {
    this.category.sendSmsOnTicketCall = this.category.systemCategory.sendSmsOnTicketCall;
    this.checkisLockEnabled();
  }
  setSystemCategoryValueOnAvgTimeBlockText() {
    this.category.avgTimeBlockText = this.category.systemCategory.avgTimeBlockText;
    this.checkisLockEnabled();
  }
  setSystemCategoryValueOnTicketAmountToCountAverageWaitingServiceTime() {
    this.category.ticketAmountToCountAverageWaitingServiceTime = this.category.systemCategory.ticketAmountToCountAverageWaitingServiceTime;
    this.checkisLockEnabled();
  }
  setSystemCategoryValueOnUnblockingTimeDaysValue() {
    var ressetUnblockingTimeDaysValue = [];

    for (let i = 0; i < this.category.systemCategory.activityTimeCategory.unblockingTimeDaysValue.length; i++) {
      this.category.unblockingTimeDaysValue[i].blockingTime = "" + this.category.systemCategory.activityTimeCategory.unblockingTimeDaysValue[i].blockingTime;
      this.category.unblockingTimeDaysValue[i].unblockingTime = "" + this.category.systemCategory.activityTimeCategory.unblockingTimeDaysValue[i].unblockingTime;
      this.category.unblockingTimeDaysValue[i].isActive = this.category.systemCategory.activityTimeCategory.unblockingTimeDaysValue[i].isActive;
    }
    ressetUnblockingTimeDaysValue = this.category.unblockingTimeDaysValue;
    this.category.unblockingTimeDaysValue = Object.assign([]);
    this.category.unblockingTimeDaysValue = ressetUnblockingTimeDaysValue;
    this.isUnblockingTimeDaysValueUpdated = Object.assign({}, true);
    this.checkisLockEnabled();
  }

  isLockEnabled: boolean = true;
  checkisLockEnabled() {
    this.isLockEnabled = this.isAllAttributeEqualsToSystemCategory()
    this.isLockedForEditing = this.isLockEnabled;
  }

  isAllAttributeEqualsToSystemCategory(): boolean {
    var isAllEquals = true;
    if (!this.category.systemCategory) {
      return false;
    }

    isAllEquals = isAllEquals && this.category.fullName === this.category.systemCategory.fullName;
    isAllEquals = isAllEquals && this.category.shortName === this.category.systemCategory.shortName;
    isAllEquals = isAllEquals && this.category.categoryTag === this.category.systemCategory.categoryTag;
    isAllEquals = isAllEquals && this.category.ticketLimit === this.category.systemCategory.ticketLimit;
    isAllEquals = isAllEquals && this.category.timePerTicket === this.category.systemCategory.timePerTicket;
    isAllEquals = isAllEquals && this.category.isBlocked === this.category.systemCategory.isBlocked;
    isAllEquals = isAllEquals && this.category.ticketReservationBlockTimes === this.category.systemCategory.ticketReservationBlockTimes;
    isAllEquals = isAllEquals && this.category.isAutomaticallyReset === this.category.systemCategory.isAutomaticallyReset;
    isAllEquals = isAllEquals && this.category.blockingMessageActivity === this.category.systemCategory.blockingMessageActivity;
    isAllEquals = isAllEquals && this.category.blockingMessageManual === this.category.systemCategory.blockingMessageManual;
    isAllEquals = isAllEquals && this.category.isActive === this.category.systemCategory.isActive;
    isAllEquals = isAllEquals && this.category.canBeReserved === this.category.systemCategory.canBeReserved;
    isAllEquals = isAllEquals && this.category.eTicketRedirect === this.category.systemCategory.eTicketRedirect;
    isAllEquals = isAllEquals && this.category.canMobile === this.category.systemCategory.canMobile;
    isAllEquals = isAllEquals && this.category.reservationIntervalMins === this.category.systemCategory.reservationIntervalMins;
    isAllEquals = isAllEquals && this.category.maxServiceTime === this.category.systemCategory.maxServiceTime;
    isAllEquals = isAllEquals && this.category.workingType === this.category.systemCategory.workingType;
    isAllEquals = isAllEquals && this.category.avgTimeBlock === this.category.systemCategory.avgTimeBlock;
    isAllEquals = isAllEquals && this.category.avgTimeBlockText === this.category.systemCategory.avgTimeBlockText;
    isAllEquals = isAllEquals && this.category.smsCategory === this.category.systemCategory.smsCategory;
    isAllEquals = isAllEquals && this.category.sendSmsOnReservation === this.category.systemCategory.sendSmsOnReservation;
    isAllEquals = isAllEquals && this.category.sendSmsOnPrint === this.category.systemCategory.sendSmsOnPrint;
    isAllEquals = isAllEquals && this.category.sendSmsOnBeforeCall === this.category.systemCategory.sendSmsOnBeforeCall;
    isAllEquals = isAllEquals && this.category.sendSmsOnTicketCall === this.category.systemCategory.sendSmsOnTicketCall;
    isAllEquals = isAllEquals && this.category.conditionalTicket === this.category.systemCategory.conditionalTicket;
    isAllEquals = isAllEquals && this.category.conditionalTicketText === this.category.systemCategory.conditionalTicketText;
    isAllEquals = isAllEquals && this.category.additionalPrint === this.category.systemCategory.additionalPrint;
    isAllEquals = isAllEquals && this.category.additionalPrintText === this.category.systemCategory.additionalPrintText;
    isAllEquals = isAllEquals && this.category.printedTicketAdditionalText === this.category.systemCategory.printedTicketAdditionalText;
    isAllEquals = isAllEquals && this.category.defaultPauseTimeMins === this.category.systemCategory.defaultPauseTimeMins;
    isAllEquals = isAllEquals && this.category.isNoteRequired === this.category.systemCategory.isNoteRequired;
    isAllEquals = isAllEquals && this.category.ticketAmountToCountAverageWaitingServiceTime === this.category.systemCategory.ticketAmountToCountAverageWaitingServiceTime;
    isAllEquals = isAllEquals && this.category.canOnHour === this.category.systemCategory.canOnHour;
    isAllEquals = isAllEquals && this.isAnyActiveTimeNotEqualToSystemCategeryActiveTime();
    return isAllEquals;
  }
  changeIsLockedForEditing() {
    this.isLockedForEditing = !this.isLockedForEditing;
  }
}
