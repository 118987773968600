import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { take, takeUntil } from 'rxjs/operators';
import { Subject, ReplaySubject } from 'rxjs';
import { Specialisation } from 'projects/qcloud-models/grafik/specialisation/specialisation.model';

@Component({
  selector: 'app-served-specialities',
  templateUrl: './served-specialities.component.html',
  styleUrls: ['./served-specialities.component.css']
})
export class ServedSpecialitiesComponent implements OnInit {


  @Input() specialities: Specialisation[];

  @Input() specialityMultiCtrl: UntypedFormControl;
  public specialityMultiFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredSpecialitiesMulti: ReplaySubject<Specialisation[]> = new ReplaySubject<Specialisation[]>(1);

  protected _onDestroy = new Subject<void>();

  @Input() title: string;
  @Input() noSelectedSpecialities: string;
  @Input() displaySpecialities: boolean;

  @Output() specialitiesToEmit = new EventEmitter<Specialisation[]>();
  constructor() { }

  ngOnInit(): void {
    // load the initial category list
    this.filteredSpecialitiesMulti.next(this.specialities.slice());
    // listen for search field value changes
    this.specialityMultiFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterCategoriesMulti();
    });
  }

  emitSpecialities(specialities: Specialisation[]) {
    this.specialitiesToEmit.emit(specialities);
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredSpecialitiesMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.specialityMultiCtrl.patchValue(val);
        } else {
          this.specialityMultiCtrl.patchValue([]);
        }
        this.specialitiesToEmit.emit(this.specialityMultiCtrl.value);
      });
  }

  protected filterCategoriesMulti() {
    if (!this.specialities) {
      return;
    }
    // get the search keyword
    let search = this.specialityMultiFilterCtrl.value;
    if (!search) {
      this.filteredSpecialitiesMulti.next(this.specialities.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the Categories
    this.filteredSpecialitiesMulti.next(
      this.specialities.filter(category => category.name.toLowerCase().indexOf(search) > -1)
    );
  }

}
