<div class="title-container">
    <div [fittext]="true" [activateOnResize]="true" [modelToWatch]="title">
        {{ title }}
    </div>
</div>
<div class="opinion-container" *ngIf="!opinionSent">
    <img src="assets/survey_happy.png" (click)="sendOpinion(0)">
    <img src="assets/survey_neutral.png" (click)="sendOpinion(1)">
    <img src="assets/survey_angry.png" (click)="sendOpinion(2)">
</div>
<div class="opinion-container" *ngIf="opinionSent">
    <div class="thank-you-container">
        <div [fittext]="true" [activateOnResize]="true" [modelToWatch]="thankUText">
            {{ thankUText }}
        </div>
    </div>
</div>