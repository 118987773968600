import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { Ticket } from 'projects/qcloud-models/console/ticket';
import { PauseTicket } from 'projects/qcloud-models/console/pauseTicket';
import { Category } from 'projects/qcloud-models/category/category.model';
import { TicketCreate } from 'projects/qcloud-models/dispenser/ticket-create';
import { AuthService } from './auth/auth.service';
import { TicketReservation } from 'projects/qcloud-models/reservation/ticket-reservation';

const DEFAULT_TIMEOUT = 3000;

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  appBase: string;

  constructor(private http: HttpClient, private authService: AuthService) { }

  public setAppBase(appBase: string) {
    this.appBase = appBase;
  }

  public callTicket(categories: Category[], callback: (data: Ticket) => any, onError: (error) => any) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.authService.getConsoleToken()}`);
    this.http.post(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tickets/call`,
      categories, { headers: headers })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  public callAgainTicket(ticketId: number, callback: (data: Ticket) => any, onError: (error) => any) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.authService.getConsoleToken()}`);
    this.http.get(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tickets/${ticketId}/callAgain`,
      { headers: headers })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  public acceptTicket(callback: (data: Ticket) => any, onError: (error) => any) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.authService.getConsoleToken()}`);
    this.http.get(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tickets/accept`,
      { headers: headers })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  public acceptAndCallTicket(categories: Category[], callback: (data: Ticket) => any, onError: (error) => any) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.authService.getConsoleToken()}`);
    this.http.post(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tickets/acceptandcall`,
      categories, { headers: headers })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  public cancelTicket(callback: (data: Ticket) => any, onError: (error) => any) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.authService.getConsoleToken()}`);
    this.http.get(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tickets/cancel`,
      { headers: headers })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  public getCancelledTickets(callback: (data: Ticket[]) => any, onError: (error) => any) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.authService.getConsoleToken()}`);
    this.http.get(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tickets/cancelled`,
      { headers: headers })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  public callCancelledTicket(ticketId: number, callback: (data: Ticket) => any, onError: (error) => any) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.authService.getConsoleToken()}`);
    this.http.get(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tickets/${ticketId}/callCancelled`,
      { headers: headers })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  public getPausedTickets(callback: (data: Ticket[]) => any, onError: (error) => any) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.authService.getConsoleToken()}`);
    this.http.get(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tickets/paused`,
      { headers: headers })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  public pauseTicket(pause: PauseTicket, callback: (data: Ticket[]) => any, onError: (error) => any) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.authService.getConsoleToken()}`);
    this.http.get(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tickets/${pause.pauseTime}/pause/${pause.returnToDesk}`,
      { headers: headers })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  public redirectTicketToCategory(categoryId: number, callback: (data: Ticket) => any, onError: (error) => any) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.authService.getConsoleToken()}`);
    this.http.get(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tickets/${categoryId}/redirect`,
      { headers: headers })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }
  public redirectTicketToCategoryHidden(categoryId: number, callback: (data: Ticket) => any, onError: (error) => any) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.authService.getConsoleToken()}`);
    this.http.get(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tickets/${categoryId}/redirectCategoryHidden`,
      { headers: headers })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  public redirectTicketToForHour(categoryId: number, reservation: TicketReservation, callback: (data: Ticket) => any, onError: (error) => any) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.authService.getConsoleToken()}`);
    this.http.post(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tickets/${categoryId}/redirectToForHour`,
      reservation,
      { headers: headers})
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }


  public redirectTicketToDesk(deskId: number, callback: (data: Ticket) => any, onError: (error) => any) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.authService.getConsoleToken()}`);
    this.http.get(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tickets/${deskId}/redirectToDesk`,
      { headers: headers })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  public getQueueState(callback: (data: Map<number, number>) => any, onError: (error) => any) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.authService.getConsoleToken()}`);
    this.http.get(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tickets/queueState`,
      { headers: headers })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  public getAvailableTickets(callback: (data: Ticket[]) => any, onError: (error) => any, pageSize: number = -1  ) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.authService.getConsoleToken()}`);
    headers = headers.set('PageSize', pageSize.toString());
    this.http.get(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tickets/availableTickets`,
      { headers: headers })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  public getCategoryAvailableTickets(categoryIds: number[], callback: (data: Ticket[]) => any, onError: (error) => any, pageSize: number =-1 ) {
    let headers = new HttpHeaders();
    headers = headers.set('CategoryIds', categoryIds.toString());
    headers = headers.set('PageSize', pageSize.toString());
    this.http.get(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tickets/availableTickets`,
      { headers: headers })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  public getRedirectedToDeskAvailableTickets(callback: (data: Ticket[]) => any, onError: (error) => any) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.authService.getConsoleToken()}`);
    this.http.get(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tickets/availableTicketsRedirectedToDesk`,
      { headers: headers })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  public getLastTicket(deskId: number, callback: (data: Ticket) => any, onError: (error) => any) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.authService.getConsoleToken()}`);
    this.http.get(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tickets/${deskId}/last`,
      { headers: headers })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  public generateTicket(categoryId: number, callback: (data: Ticket) => any, onError: (error) => any) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.authService.getConsoleToken()}`);
    this.http.post(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tickets/${categoryId}/newFromConsole`,
      {}, { headers: headers })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }
  getCategories(callback: (data: Category[]) => any, onError: () => any) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.authService.getConsoleToken()}`);
    this.http.get(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/categories`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  public createTicket(ticketCreate: TicketCreate, categoryId: number, callback: (data: Ticket) => any, onError: (error) => any) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.authService.getToken()}`);
    this.http.post(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tickets/${categoryId}/new`,
      ticketCreate, { headers: headers })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  getOnHourTickets(categoryId: number, callback: (data: Ticket[]) => any, onError: (data: any) => any) {
    var getOnHourTickets = `/api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tickets/${categoryId}/nnn/onHour`;
    this.http.get(this.appBase + getOnHourTickets)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  public callManualTicket(ticketId: number, callback: (data: Ticket) => any, onError: (error) => any) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.authService.getConsoleToken()}`);
    this.http.post(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tickets/${ticketId}`,
      {}, { headers: headers })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  public callManualTicketWhileServedOther(ticketId: number, callback: (data: Ticket) => any, onError: (error) => any) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.authService.getConsoleToken()}`);
    this.http.post(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tickets/callwhileserved/${ticketId}`,
      {}, { headers: headers })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }
}