import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ThemePalette } from '@angular/material/core';
import { NgForm } from '@angular/forms';
import { DeviceService } from 'projects/qcloud-rest-client/src/lib/device.service';
import { Device } from 'projects/qcloud-models/device/device.model';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { BarType } from 'projects/qcloud-models/device/bar-type.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-device-customization',
  templateUrl: './device-customization.component.html',
  styleUrls: ['./device-customization.component.css']
})
export class DeviceCustomizationComponent implements OnInit {

  device: Device;
  color: ThemePalette = 'primary';
  formSubmitted: boolean = false;
  BarType = BarType;

  constructor(private deviceService: DeviceService, private route: ActivatedRoute, private authService: AuthService,
    private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit(): void {
    var deviceGuid: string;
    this.route.params.subscribe(params => {
      deviceGuid = params['guid']
    });
    this.getDevice(deviceGuid);
  }

  getDevice(deviceGuid: string) {
    this.deviceService.getDevice(deviceGuid).subscribe(
      device => {
        this.device = device;
      }
    );
  }

  onSubmit(form: NgForm) {
    this.formSubmitted = true;
    if (form.value.customBackground) {
      form.value.backgroundImgPath = this.device.deviceCustomization.backgroundImgPath;
    }
    this.deviceService.customizeDevice(form.value, this.device.guid).subscribe(
      res => {
        this.translate.get('device-customization-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.translate.get('device-customization-error').subscribe((res: string) => {
          let error = res;
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
    this.formSubmitted = false;
  }

  setBackgroundPath(backgroundPath: string) {
    this.device.deviceCustomization.backgroundImgPath = backgroundPath;
  }
}
