import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Translation } from 'projects/qcloud-models/translation/translation.model';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {

  @Input() translations: Translation[];
  startIndex: number = 0;
  @Output() filteredTranslationsEmitter = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
    this.emitFilteredTranslations(this.startIndex);
  }

  emitFilteredTranslations(startIndex: number) {
    this.startIndex = startIndex;
    this.filteredTranslationsEmitter.emit(this.startIndex);
  }

}
