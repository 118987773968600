<form #resetCategoryform="ngForm" autocomplete="off">

    <app-served-categories *ngIf="categoryMultiCtrl" [categories]="categories" [categoryMultiCtrl]="categoryMultiCtrl"
        [title]="title" [noSelectedCategories]="noSelectedCategories" [displayCategories]="true">
    </app-served-categories>    

    <div class="form-group row">
        <button class="btn btn-primary m-1" type="submit" (click)="onSubmit()"
            [disabled]="formSubmitted || resetCategoryform.invalid">
        <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
            aria-hidden="true"></span> {{ 'save-category-reset' | translate }}
        </button>
    </div>
</form>

