import { Component, Input, OnInit } from '@angular/core';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { Dictionary } from 'projects/qcloud-models/dictionary';

@Component({
  selector: 'app-waiting-time-desk-statistics',
  templateUrl: './waiting-time-desk-statistics.component.html',
  styleUrls: ['./waiting-time-desk-statistics.component.css']
})
export class WaitingTimeDeskStatisticsComponent implements OnInit {

  @Input() desks: Desk[];
  @Input() averageDeskWaitingTime: Dictionary<number>;
  @Input() averageWaitingTime: number;
  maxWaitingTime: number;

  constructor() { }

  ngOnInit(): void {
    this.maxWaitingTime = +Object.entries(this.averageDeskWaitingTime).reduce((a, b) => a[1] > b[1] ? a : b)[1];
  }

}
