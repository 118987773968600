<div class="form-group">
    <div class="font-weight-bold">{{ title | translate}}</div>
    <div class="input-group">
        <mat-form-field color="primary">
            <mat-select [formControl]="qcloudRolesMultiCtrl" [multiple]="true" #multiSelect
                (selectionChange)="emitQcloudRoles($event.value)">
                <mat-option>
                    <ngx-mat-select-search [showToggleAllCheckbox]="true" (toggleAll)="toggleSelectAll($event)"
                        [formControl]="qcloudRoleMultiFilterCtrl"
                        toggleAllCheckboxTooltipMessage="{{ 'select-unselect-all' | translate }}"
                        [toggleAllCheckboxTooltipPosition]="'above'"
                        placeholderLabel="{{ 'search-role' | translate }}"
                        noEntriesFoundLabel="{{ 'no-matching-role' | translate }}">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let qcloudRole of filteredQcloudRoleMulti | async" [value]="qcloudRole" [disabled]="isRoleDisabled(qcloudRole)">
                <!-- <mat-option *ngFor="let qcloudRole of filteredQcloudRoleMulti | async" [value]="qcloudRole" [disabled]="!isEnabledByLocationId(qcloudRole.locations) && !isEnabledByPermissionLevel(qcloudRole.rolePermissionLevel)"> -->
                    <b>{{ qcloudRole.id }}</b>
                    {{ qcloudRole.roleName }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <p *ngIf="qcloudRolesMultiCtrl.value == null || qcloudRolesMultiCtrl?.value.length == 0" class="text-danger mx-2 my-1">
        {{ noSelectedQcloudRoles | translate }}</p>
</div>