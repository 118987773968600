import { Component, OnInit } from '@angular/core';
import { QcloudConsoleService } from '../../qcloud-console.service';
import { Ticket } from 'projects/qcloud-models/console/ticket';
import { validateHorizontalPosition } from '@angular/cdk/overlay';
import { TicketsQuestion } from 'projects/qcloud-models/device/ticket-question';
import { Category } from 'projects/qcloud-models/category/category.model';

@Component({
  selector: 'lib-display-ticket',
  templateUrl: './display-ticket.component.html',
  styleUrls: ['./display-ticket.component.css']
})
export class DisplayTicketComponent implements OnInit {
  ticketCaption: string;
  ticket: Ticket;
  isTicketAnswer: boolean;
  showTicket: boolean;
  ticketAnswerCaption: string;
  ticketAnswer: string;
  ticketQuestions: TicketsQuestion[];
  categories: Category[];
  constructor(consoleService: QcloudConsoleService) {
    this.ticketQuestions = consoleService.ticketQuestions;
    this.categories = consoleService.getCategoriesToCallTicket();
    consoleService.ticket.subscribe((val: Ticket) => {
      this.ticket = val;
      this.ticketCaption = val?.caption
      if (this.ticket.answer) {
        this.isTicketAnswer = true;
        this.showTicket = false;
        var ticketCategory = this.categories.find(x => x.id == this.ticket.printedCategoryId);
        var ticketQuestion = this.ticketQuestions.find(x => x.systemCategories.some(y => y.id == ticketCategory.systemCategoryId));
        this.ticketAnswerCaption = ticketQuestion.keyName;
        this.ticketAnswer = this.ticket.answer;
      }
      else {
        this.showTicket = true;
        this.isTicketAnswer = false;
      }
    });
  }

  ngOnInit(): void {

  }

  toggle(){
    this.showTicket = !this.showTicket;
  }
}

