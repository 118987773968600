import { Component, OnInit, Input } from '@angular/core';
import { Category } from 'projects/qcloud-models/category/category.model';
import { CategoryGroup } from 'projects/qcloud-models/category/category-group.model';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-group-categories',
  templateUrl: './group-categories.component.html',
  styleUrls: ['./group-categories.component.css']
})
export class GroupCategoriesComponent implements OnInit {

  @Input() categories: Category[];
  categoryGroups: CategoryGroup[];
  constructor(private categoryService: CategoryService, private toastr: ToastrService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.categoryService.getCategoryGroups().subscribe(
      categoryGroups => {
        this.categoryGroups = categoryGroups;
      }
    )
  }

  setCategoryGroups(categoryGroups: CategoryGroup[]) {
    this.categoryService.createCategoryGroups(categoryGroups).subscribe(
      res => {
        this.translate.get('category-groups-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.translate.get('category-groups-error').subscribe((res: string) => {
          let error = res;
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      });
  }
}
