import { NgModule } from '@angular/core';
import { QCloudRestClientService } from './qcloud-rest-client.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './auth/token-interceptor';

@NgModule({
  imports: [HttpClientModule],
  exports: [],
  providers: [QCloudRestClientService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }],
  declarations: [
  ]
})
export class QcloudRestClientModule { }
