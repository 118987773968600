import { Component, Input, OnInit } from '@angular/core';
import { Category } from 'projects/qcloud-models/category/category.model';
import { ReservationVacationService } from './reservation-vacation.service';

@Component({
  selector: 'app-reservation-vacation',
  templateUrl: './reservation-vacation.component.html',
  styleUrls: ['./reservation-vacation.component.css']
})
export class ReservationVacationComponent implements OnInit {
  @Input() reservationCategories: Category[];
  constructor(private reservationVacationService: ReservationVacationService) {
   }

  ngOnInit(): void {
  }
}
