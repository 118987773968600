import { Category } from 'projects/qcloud-models/category/category.model';
import { DisplayCategoryGroup } from 'projects/qcloud-models/category/display-category-group';
export class DragAndDropListElement {
    public static ID_MAX = 0;
    id: number;
    categoryId: number;
    name: string;
    tag: string;
    scopeSize: number; // = scopeEndId - scopeStartId
    isEditMode: boolean;
    icon: string;
    class: string;

    private _depth: number;
    set depth(value: number) {
        this._depth = value;
        this.updateClassCss();
        this.updateStyle();
    };
    get depth(): number {
        return this._depth;
    }
    private _isDragged: boolean;
    set isDragged(value: boolean) {
        this._isDragged = value;
        this.updateClassCss();
    };
    get isDragged(): boolean {
        return this._isDragged;
    }
    private _isGroup: boolean; /// TODO: replace isGroup, isEmpty : booleand by Enum.
    set isGroup(value: boolean) {
        this._isGroup = value;
        this.updateClassCss();
    };
    get isGroup(): boolean {
        return this._isGroup;
    }
    private _isEmptyCell: boolean;
    set isEmptyCell(value: boolean) {
        this._isEmptyCell = value;
        this.updateClassCss();
    };
    get isEmptyCell(): boolean {
        return this._isEmptyCell;
    }

    private _isDisplayGroup: boolean;
    set isDisplayGroup(value: boolean) {
        this._isDisplayGroup = value;
        this.updateClassCss();
    };
    get isDisplayGroup(): boolean {
        return this._isDisplayGroup;
    }

    private _displayGroupId: number;
    set displayGroupId(value: number) {
        this._displayGroupId = value;
    };
    get displayGroupId(): number {
        return this._displayGroupId;
    }

    private _style: string;
    set style(value: string) {
        this._style = value;
    };
    get style(): string {
        return this._style;
    }

    private _classCss: string;

    set classCss(value: string) {
        this._classCss = value;
    }
    updateClassCss() {
        var newCssClass = this.defineCssClasses();
        this.classCss = newCssClass;
    }
    updateStyle() {
        var newStyle = this.defineStyleCss();
        this.style = newStyle;
    }
    get classCss(): string {
        return this._classCss;
    }


    defineStyleCss(): string {
        var leftStep: number = this.depth + 1;
        leftStep = leftStep > 50 ? 50 : leftStep;
        var returnValue = "margin-left: " + leftStep + "%;";
        return returnValue;
    }

    defineCssClasses(): string {
        var returnValue = "cdk-dnd-item-base";

        if (this.depth > 0) {
            returnValue = returnValue + " margin-top-0 margin-bottom-0 ";
        }
        if (this.isDragged === true) {
            returnValue = returnValue + " cdk-drag-placeholder-opacity-half ";
        }
        if (this.isGroup === true) {
            returnValue = returnValue + " text-warning margin-bottom-0";
        }
        return returnValue;
    }


    constructor() {
        this.id = DragAndDropListElement.ID_MAX;
        DragAndDropListElement.ID_MAX++;
    }

    get quantityElementsInGroup(): number {
        var quantityElementTypeGroup = 1;
        if (this.isGroup) {
            return this.scopeSize + quantityElementTypeGroup;
        }
        return 0;
    }

    public clone(): DragAndDropListElement {
        var cloneObj = new DragAndDropListElement();
        cloneObj.id = this.id;
        cloneObj.categoryId = this.categoryId;
        cloneObj.depth = this.depth;
        cloneObj.isEmptyCell = this.isEmptyCell;
        cloneObj.isGroup = this.isGroup;
        cloneObj.name = this.name;
        cloneObj.tag = this.tag;
        cloneObj.isDragged = this.isDragged;
        cloneObj.scopeSize = this.scopeSize;
        cloneObj.displayGroupId = this.displayGroupId;
        cloneObj.isDisplayGroup = this.isDisplayGroup;
        return cloneObj;
    }
    public static CreateEmptyItem(depth: number = 1): DragAndDropListElement {
        var emptyItem = new DragAndDropListElement();
        emptyItem.depth = depth;
        emptyItem.isEmptyCell = true;
        emptyItem.isGroup = false;
        emptyItem.scopeSize = 0;
        emptyItem.name = "";
        emptyItem.tag = "";
        emptyItem.isDragged = false;
        return emptyItem;
    }
    public static CreateGroup(groupName: string, depth: number = 0, groupId: number = 0): DragAndDropListElement {
        var item = new DragAndDropListElement();
        item.id = groupId;
        item.categoryId = -1;
        item.icon = 'fa fa-folder';
        item.class = 'wide';
        item.isEditMode = false;

        item.name = groupName;
        item.tag = "";
        item.isGroup = true;
        item.isEmptyCell = false;
        item.scopeSize = 1;
        item.depth = depth;

        return item;
    }

    public static CreateDisplayGroup(displayGroup: DisplayCategoryGroup, depth: number = 0): DragAndDropListElement {
        var item = new DragAndDropListElement();
        item.categoryId = -1;
        item.icon = 'fa fa-folder';
        item.class = 'wide';
        item.isEditMode = false;

        item.name = displayGroup.name;
        item.tag = "";
        item.isGroup = true;
        item.isDisplayGroup = true;
        item.displayGroupId = displayGroup.id;
        item.isEmptyCell = false;
        item.scopeSize = 1;
        item.depth = depth;

        return item;
    }

    public static CreateElementForSourceList(category: Category, depth: number = 0): DragAndDropListElement {
        var item = new DragAndDropListElement();
        item.categoryId = category.id;
        item.name = category.fullName;
        item.tag = category.categoryTag;
        item.class = "half";
        item.isGroup = false;
        item.isEmptyCell = false;
        item.scopeSize = 0;
        item.depth = depth;
        return item;
    }
}