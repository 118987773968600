export class QueueState {
    //<categoryId, ticketCount>
    queueStateByCategory: Map<number, number>;

    constructor() {
        this.queueStateByCategory = new Map<number, number>();
    }
}

export class SingleQueueState {
    public categoryId: number;
    public length: number;
}