<div *ngIf="locationService.getLocationConstraints().areEnabledTicketsReservations">
    <div *ngIf="categories" class="card shadow mb-4">
        <div class="card-header py-3">
            <h5 class="m-0 font-weight-bold text-primary">{{ 'statistics-reservation' | translate }}</h5>
        </div>
        <div class="card-body">
            <div *ngFor="let category of categories; let i = index">
                <h4 class="small font-weight-bold">{{ category.fullName }}
                    <span class="float-right">
                        <span class="text-primary">{{ used[i] }}</span>/{{ allReserved[i] }}
                    </span>
                </h4>
                <div class="progress mb-4">
                    <div class="progress-bar" role="progressbar" [style.width]="(used[i]/allReserved[i])*100 + '%'"
                        aria-valuenow="used[i]" aria-valuemin="0" aria-valuemax="allReserved[i]">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-licence-info *ngIf="!locationService.getLocationConstraints().areEnabledTicketsReservations"
    [title]="'statistics-reservation'">
</app-licence-info>