<div class="modal-dialog" role="document">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ 'add-link' | translate }}</h5>
            <button class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="input-group">{{ 'paste-link' | translate }}</div>
            <div class="input-group">
                <input [(ngModel)]="link" class="form-control">
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-danger" data-dismiss="modal">Anuluj</button>
            <button class="btn btn-primary" data-dismiss="modal" (click)="addLink()">
                {{ 'add-link' | translate }}
            </button>
        </div>
    </div>
</div>
