import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FileService } from 'projects/qcloud-rest-client/src/lib/file.service';
import { FileDTO } from 'projects/qcloud-models/fileManager/file-dto.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FileDuration } from 'projects/qcloud-models/fileManager/file-duration.model';

@Component({
  selector: 'app-file-popup',
  templateUrl: './file-popup.component.html',
  styleUrls: ['./file-popup.component.css']
})
export class FilePopupComponent implements OnInit, OnChanges {

  files: FileDTO[];
  @Input() selectedFilePath: string;
  @Input() multiselect: boolean;
  @Output() filePathToEmit = new EventEmitter();
  @Output() filesWithDurationsToEmit = new EventEmitter();
  selectedFileIndex: number;
  selectedFiles: boolean[];
  filesDuration: FileDuration[];
  path: string = "";

  constructor(public service: FileService, private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.getDirectoryContent();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedFilePath = changes.selectedFilePath.currentValue;
    if (!this.selectedFilePath) {
      this.selectedFileIndex = null;
    }
  }

  getDirectoryContent() {
    this.service.getDirectoryContent(this.path).subscribe(
      files => {
        this.files = files;
        this.selectedFiles = new Array<boolean>(this.files.length);
        this.filesDuration = new Array<FileDuration>(this.files.length);
        for (let i = 0; i < files.length; i++) {
          this.filesDuration[i] = new FileDuration(files[i].path, files[i].size, 1, 0);
          if (files[i].path == this.selectedFilePath) {
            this.selectedFileIndex = i;
          }
        }
      },
      err => {
        this.translate.get('file-manager-error').subscribe((res: string) => {
          let error = res;
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  chooseFile(file: FileDTO, idx: number) {
    if (file.type == 0) {
      if (!this.multiselect) {
        this.selectedFilePath = file.path;
        this.selectedFileIndex = idx;
      } else {
        this.selectedFiles[idx] = !this.selectedFiles[idx];
      }
    }
  }

  sendFile() {
    if (!this.multiselect) {
      this.filePathToEmit.emit(this.selectedFilePath);
    } else {
      let filesDuration: FileDuration[] = new Array<FileDuration>();
      for (let i = 0; i < this.files.length; i++) {
        if (this.selectedFiles[i]) {
          filesDuration.push(this.filesDuration[i]);
        }
      }
      this.filesWithDurationsToEmit.emit(filesDuration);
      this.selectedFiles = new Array<boolean>(this.files.length);
    }
  }

  setDuration(e, index: number) {
    let duration = e.target.duration;
    duration = Math.floor(duration);
    let minutes = Math.floor(duration / 60);
    let seconds = duration - minutes * 60;
    this.filesDuration[index].minutes = minutes;
    this.filesDuration[index].seconds = seconds;
  }

  changeFolder(item: FileDTO) {
    this.path = item.path;
    this.getDirectoryContent();
  }

  setPath(path: string) {
    this.path = path;
    this.getDirectoryContent();
  }

  displayFilename(filename: string) {
    let maxFileLength = 13;
    let filenameLength = filename.length - this.service.getExtension(filename).length;
    if (filenameLength <= maxFileLength) {
      return filename;
    } else {
      return filename.slice(0, maxFileLength - 3) + "...";
    }
  }

  removeSelectedFiles() {
    if (this.multiselect) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        this.selectedFiles[i] = false;
      }
    } else {
      this.selectedFileIndex = null;
    }
  }
}
