import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Editor, toHTML } from 'ngx-editor';
import { ToastrService } from 'ngx-toastr';
import { Category } from 'projects/qcloud-models/category/category.model';
import { ReservationCategoryGroup } from 'projects/qcloud-models/reservation-category-group/reservation-category-group';
import { ReservationLimit } from 'projects/qcloud-models/reservation/reservation-limit';
import { ReservationModuleConfiguration } from 'projects/qcloud-models/reservation/reservation-module-configuration';
import { ReservationQuestion } from 'projects/qcloud-models/reservation/reservation-questions';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { ReservationGroupClientService } from 'projects/qcloud-rest-client/src/lib/reservation-group-client.service';
import { ReservationService } from 'projects/qcloud-rest-client/src/lib/reservation.service';

@Component({
  selector: 'app-reservation-configuration-page',
  templateUrl: './reservation-configuration-page.component.html',
  styleUrls: ['./reservation-configuration-page.component.css']
})
export class ReservationConfigurationPageComponent implements OnInit, OnDestroy {

  editor: Editor;
  form: UntypedFormGroup;
  categories: Category[];
  reservationCategories: Category[];
  reservationCategoryGroups: ReservationCategoryGroup[];
  initialCategories: Category[];
  public categoryMultiCtrl: UntypedFormControl;
  noSelectedCategories: string = 'no-reservation-categories';

  formSubmitted: boolean = false;
  reservationConfiguration: ReservationModuleConfiguration;
  questions: ReservationQuestion[];
  reservationLimits: ReservationLimit[];
  isSmsEnabled: boolean;

  constructor(private categoryService: CategoryService, private toastr: ToastrService,
    private translate: TranslateService, private reservationService: ReservationService,
    public reservationGroupClientService: ReservationGroupClientService,
    public locationService: LocationService) { }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  ngOnInit(): void {
    this.editor = new Editor();
    this.form = new UntypedFormGroup({
      editorContent: new UntypedFormControl("<p></p>")
    });

    if (!this.locationService.getLocationConstraints().areEnabledTicketsReservations) return;

    this.categoryService.getCategories(
      (data) => {
        this.categories = data;
        this.reservationCategories = data.filter(x => x.canBeReserved);
        this.initialCategories = new Array<Category>();
        for (let i = 0; i < this.categories.length; i++) {
          if (this.categories[i].canBeReserved) {
            this.initialCategories.push(this.categories[i]);
          }
        }
        this.categoryMultiCtrl = new UntypedFormControl(this.initialCategories);
      },
      () => { }
    );

    this.reservationGroupClientService.getGroups((data) => {
      this.reservationCategoryGroups = data;
    }, () => { });

    this.reservationService.getReservationConfiguration(data => {
      this.reservationConfiguration = data;
      this.form = new UntypedFormGroup({
        editorContent: new UntypedFormControl(data.terms)
      });
      this.checkSms();
    }, () => { });

    this.getReservationQuestionsAndLimits();
  }

  saveConfiguration() {
    this.formSubmitted = true;
    this.updateReservationCategories();
    this.updateReservationConfiguration();
  }

  updateReservationCategories() {
    let ids = new Array<number>();
    this.categories.forEach(category => {
      if (this.categoryMultiCtrl.value.includes(category)) {
        category.canBeReserved = true;
        ids.push(category.id);
      }
      else {
        category.canBeReserved = false;
      }
    });
    this.reservationService.updateReservationCategories(ids,
      () => {
        this.translate.get('reservation-categories-save-success').subscribe((res: string) => {
          this.reservationCategories = this.categories.filter(x => x.canBeReserved == true);
          this.toastr.success(res);
        });
      },
      () => {
        this.translate.get('reservation-categories-save-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  updateReservationConfiguration() {
    let content = this.form.get("editorContent");
    if (content.value) {
      if (typeof content.valid === 'string') {
        this.reservationConfiguration.terms = toHTML(content.value);
      } else {
        this.reservationConfiguration.terms = content.value;
      }
    } else {
      this.reservationConfiguration.terms = "<p></p>";
    }
    this.reservationService.setReservationConfiguration(this.reservationConfiguration,
      () => {
        this.formSubmitted = false;
        this.translate.get('reservation-configuration-save-success').subscribe((res: string) => {
          this.reservationCategories = this.categories.filter(x => x.canBeReserved == true);
          this.toastr.success(res);
        });
      },
      () => {
        this.formSubmitted = false;
        this.translate.get('reservation-configuration-save-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  getReservationQuestionsAndLimits() {
    this.reservationService.getReservationQuestionaire(false, (data) => {
      this.questions = data;
    },
      () => { });

    this.reservationService.getReservationLimits().subscribe((res) => this.reservationLimits = res);
  }

  checkSms() {
    this.isSmsEnabled = this.locationService.getLocationConstraints().isSmsEnabled;
    if (!this.isSmsEnabled) {
      this.reservationConfiguration.sendReservationSms = false;
    }
  }

}
