import { Component, OnInit } from '@angular/core';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { TimeConversionService } from '../../../../shared/time-conversion.service';
import { ToastrService } from 'ngx-toastr';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { TranslateService } from '@ngx-translate/core';
import { LocationTasks } from 'projects/qcloud-models/location/location-tasks';
import * as moment from 'moment';

@Component({
  selector: 'app-ticekts-reset-unblock-categories',
  templateUrl: './ticekts-reset-unblock-categories.component.html',
  styleUrls: ['./ticekts-reset-unblock-categories.component.css']
})
export class TicektsResetUnblockCategoriesComponent implements OnInit {

  locationTasks: LocationTasks;
  formSubmitted: boolean = false;
  formReset: boolean = false;
  formUnblock: boolean = false;
  ticketResetTime: string;
  categoryResetTime: string;

  constructor(private locationService: LocationService, private convertTime: TimeConversionService,
    private toastr: ToastrService, private categoryService: CategoryService, 
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.getLocationTasks();
  }

  getLocationTasks() {
    this.locationService.getLocationTasks().subscribe(
      locationTasks => {
        this.locationTasks = locationTasks;
        this.ticketResetTime = moment(locationTasks.ticketResetTime).format("HH:mm");
        this.categoryResetTime = moment(locationTasks.categoriesUnblockTime).format("HH:mm");
      }
    );
  }

  resetTickets() {
    this.formReset = true;
    this.locationService.resetTickets().subscribe(
      res => {
        this.formReset = false;
        this.translate.get('reset-tickets-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.formReset = false;
        this.translate.get('reset-tickets-error').subscribe((res: string) => {
          let error = res;
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      });
  }

  unblockCategories() {
    this.formUnblock = true;
    this.categoryService.unblockCategories().subscribe(
      res => {
        this.formUnblock = false;
        this.translate.get('unblock-categories-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.formUnblock = false;
        this.translate.get('unblock-categories-error').subscribe((res: string) => {
          let error = res;
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      });
  }

  onSubmit() {
    this.formSubmitted = true;
    this.locationTasks.ticketResetTime = this.convertTime.timeToDate(this.ticketResetTime);
    this.locationTasks.categoriesUnblockTime = this.convertTime.timeToDate(this.categoryResetTime);
    this.locationService.saveLocationTasks(this.locationTasks).subscribe(
      res => {
        this.formSubmitted = false;
        this.translate.get('reset-tickets-unblock-categories-update-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
        this.getLocationTasks();
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('reset-tickets-unblock-categories-update-error').subscribe((res: string) => {
          let error = res;
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
          this.getLocationTasks();
        });
      }
    );
  }

  isTaskCompletedOnce(taskDate: Date): boolean {
    return new Date(taskDate).getFullYear() > 1;
  }

}
