import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DeviceConfigurationService } from 'projects/qcloud-device/src/app/services/device-configuration.service';
import { QueueStateService } from 'projects/qcloud-device/src/app/services/queue-state.service';
import { TicketListenerService } from 'projects/qcloud-device/src/app/services/ticket.service';
import { UtilitiesService } from 'projects/qcloud-device/src/app/services/utilities.service';
import { Category } from 'projects/qcloud-models/category/category.model';
import { Ticket } from 'projects/qcloud-models/console/ticket';
import { DeviceQueueState } from 'projects/qcloud-models/device/device-queue-state';
import { TicketDisplayRow } from 'projects/qcloud-models/device/ticket-display-row';
import { BaseComponent } from '../../../../shared/base-component/base.component';
import { TicketState } from 'projects/qcloud-models/console/ticketState.enum';

declare var window;

@Component({
  selector: 'app-backoffice',
  templateUrl: './backoffice.component.html',
  styleUrls: ['./backoffice.component.css']
})
export class BackofficeComponent extends BaseComponent implements OnInit, OnDestroy {
  rowHeight: number = 0;
  rowPadding: number = 0;
  rows: TicketDisplayRow[] = [];
  animation: any;
  queueState: Map<number, DeviceQueueState>;

  constructor(protected elementRef: ElementRef, protected renderer: Renderer2) {
    super(elementRef, renderer);

  }

  startComponent(): void {
    var deviceConfiguration = this.configurationService.getConfiguration();
    let categories: Category[] = [];
    deviceConfiguration.deviceCategoryTree.root.nodes.forEach(element => {
      categories.push(element.value);
    });
    let rows = [];
    categories.forEach(element => {
      if (element.id != -1) {
        let row = new TicketDisplayRow();
        row.texts[0] = this.utilitiesService.getCategoryName(element.id);
        row.texts[1] = "--";
        row.texts[2] = 0
        row.categoryId = element.id;
        row.active = false;
        rows.push(row);
      }
    });
    this.rows = rows;

    this.ticketListenerService.ticket.subscribe((val) => {
      this.ticketCalled(val);
    });

    this.queueStateService.subscribeQueueState();
    this.queueStateService.queueState.subscribe(data => {
      this.queueState = data;
      this.queueStateChanged();
    });

    this.setGridRows(rows.length);
  }

  ngOnDestroy(): void {
    try {
      this.queueStateService.unsubcribeQueueState();
    }
    catch (exception) { }
  }

  private setGridRows(number: number) {
    var orientation = window.orientation % 90 === 0 ? 'portrait' : 'landscape'
    if (orientation == 'landscape') {
      if (number < 4) number = 4;
      this.rowPadding = 0.05;
    }
    else {
      if (number < 6) number = 6;
      this.rowPadding = 0.1;
    }
    this.rowHeight = 100 / number;
    this.rowPadding *= this.rowHeight;
  }

  getRowHeightStyle() {
    return { height: this.rowHeight + '%', "padding-bottom": this.rowPadding + '%' }
  }


  ngOnInit() { }

  private ticketCalled(ticket: Ticket) {
    if (ticket.ticketState != TicketState.Called)
      return;
    let row = this.rows.filter(x => x.categoryId == ticket.effectiveCategoryId)[0];
    if (row == undefined)
      return;
    row.texts[1] = this.queueState[ticket.effectiveCategoryId].length;
    row.texts[2] = this.queueState[ticket.effectiveCategoryId].predictedWaitingTime;
  }

  private queueStateChanged() {
    this.rows.forEach(row => {
      row.texts[1] = this.queueState[row.categoryId].length;
      row.texts[2] = this.queueState[row.categoryId].predictedWaitingTime;
    });
  }

  initMock(): void {
    let rows = [];
    let row = new TicketDisplayRow();
    row.texts[0] = "Example";
    row.texts[1] = "--";
    row.texts[2] = 0
    row.categoryId = 1;
    row.active = false;
    rows.push(row);
    let row2 = new TicketDisplayRow();
    row.texts[0] = "Example";
    row.texts[1] = "--";
    row.texts[2] = 2
    row.categoryId = 2;
    row.active = false;
    rows.push(row2);
    this.rows = rows;
    this.setGridRows(rows.length);
  }
}
