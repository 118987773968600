import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DeviceType } from 'projects/qcloud-models/device/device-type.enum';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-assign-system-tag-device-type',
  templateUrl: './assign-system-tag-device-type.component.html',
  styleUrls: ['./assign-system-tag-device-type.component.css']
})
export class AssignSystemTagDeviceTypeComponent implements OnInit {

  @Input() deviceTypes: DeviceType[];
  @Input() deviceTypeMultiCtrl: UntypedFormControl;
  public deviceTypeMultiFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredDeviceTypesMulti: ReplaySubject<DeviceType[]> = new ReplaySubject<DeviceType[]>(1);
  DeviceType = DeviceType;

  protected _onDestroy = new Subject<void>();

  constructor() { }

  ngOnInit(): void {
    // load the initial deviceType list
    this.filteredDeviceTypesMulti.next(this.deviceTypes.slice());

    // listen for search field value changes
    this.deviceTypeMultiFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterDeviceTypesMulti();
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredDeviceTypesMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.deviceTypeMultiCtrl.patchValue(val);
        } else {
          this.deviceTypeMultiCtrl.patchValue([]);
        }
      });
  }

  protected filterDeviceTypesMulti() {
    if (!this.deviceTypes) {
      return;
    }
    // get the search keyword
    let search = this.deviceTypeMultiFilterCtrl.value;
    if (!search) {
      this.filteredDeviceTypesMulti.next(this.deviceTypes.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the deviceTypes
    this.filteredDeviceTypesMulti.next(
      this.deviceTypes.filter(deviceType => deviceType > -1)
    );
  }

}
