import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { QcloudConsoleService } from '../../qcloud-console.service';
import { TicketNoteService } from '../../../../../qcloud-rest-client/src/lib/note/ticket-note.service';

@Component({
  selector: 'lib-call-ticket-button',
  templateUrl: './call-ticket-button.component.html',
  styleUrls: ['./call-ticket-button.component.css', './../base-button/base-button.component.css']
})
export class CallTicketButtonComponent implements OnInit {
  @Input() public tooltipTitle: string;
  @ViewChild('button') public button: ElementRef;
  @ViewChild('icon') public icon: ElementRef;
  isEnabled: boolean;
  isAddNoteEnabled: boolean;
  isBlocked: boolean;
  isLocked: boolean;


  constructor(private consoleService: QcloudConsoleService, private ticketNoteService: TicketNoteService) {

    this.consoleService.ticket.subscribe((val) => {
      if (val.id != -1) {
        this.isEnabled = false;
      }
      else {
        this.isEnabled = true;
      }
    });
    this.consoleService.isBlocked.subscribe((val) => {
      this.isBlocked = val;
    });
    this.consoleService.isLocked.subscribe((val) => {
      this.isLocked = val;
    });
  }

  ngOnInit(): void {
  }

  public callTicket() {
    if (this.isEnabled) {
      this.consoleService.callTicket();
    }
  }
}