import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { Ticket } from '../../../../qcloud-models/console/ticket';
import { AuthService } from '../auth/auth.service';
import { FormNoteVm } from '../../../../qcloud-models/console/note-dynamic-form/FormNoteVm';
const DEFAULT_TIMEOUT = 3000;
@Injectable({
  providedIn: 'root'
})
export class TicketNoteService {

  appBase: string;

  constructor(private http: HttpClient, private authService: AuthService) { }

  public setAppBase(appBase: string) {
    this.appBase = appBase;
  }

  public addAnswerNotesToTicket(ticketId: number, formNoteVm: FormNoteVm, callback: (data: Ticket) => any, onError: (error) => any) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.authService.getConsoleToken()}`);
    this.http.post(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/notes/addnote/${ticketId}`,
      formNoteVm, { headers: headers })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }
  public isNoteSettedToTicket(ticketId: number, callback: (data: boolean) => any, onError: (error) => any) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.authService.getConsoleToken()}`);
    return this.http.get(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/notes/isNoteSaved/${ticketId}`,
      { headers: headers })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }
}