import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TicketsType } from 'projects/qcloud-models/console/ticketsType.enum';
import { QcloudConsoleService } from '../../qcloud-console.service';

@Component({
  selector: 'lib-show-ticket-manual-button',
  templateUrl: './show-ticket-manual-button.component.html',
  styleUrls: ['./show-ticket-manual-button.component.scss', './../base-button/base-button.component.css']
})
export class ShowTicketManualButtonComponent  implements OnInit {
	@ViewChild('button') public button: ElementRef;
  isLocked: boolean;
  constructor(private consoleService: QcloudConsoleService) {
    this.consoleService.isLocked.subscribe((val) => {
      this.isLocked = val;
    });
  }

  ngOnInit(): void {
  }

  public manualShowTicket() {
      this.consoleService.openManualShowTicketModal(TicketsType.All);
  }

}
