<div class="form-group">
    <div class="input-group">
        <mat-form-field color="primary">
            <mat-label>{{ 'system-tags' | translate }}: {{ 'device-types' | translate }}</mat-label>
            <mat-select [formControl]="deviceTypeMultiCtrl" [multiple]="true" #multiSelect>
                <mat-option>
                    <ngx-mat-select-search [showToggleAllCheckbox]="true" (toggleAll)="toggleSelectAll($event)"
                        [formControl]="deviceTypeMultiFilterCtrl"
                        toggleAllCheckboxTooltipMessage="{{ 'select-unselect-all' | translate }}"
                        [toggleAllCheckboxTooltipPosition]="'above'"
                        placeholderLabel="{{ 'search-device-type' | translate }}"
                        noEntriesFoundLabel="{{ 'no-matching-device-type' | translate }}">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let deviceType of filteredDeviceTypesMulti | async" [value]="deviceType">
                    <span *ngIf="deviceType == DeviceType.MainDisplay">
                        <b>M</b>: {{ 'main-display' | translate }}</span>
                    <span *ngIf="deviceType == DeviceType.DeskDisplay">
                        <b>D</b>: {{ 'desk-display' | translate }}</span>
                    <span *ngIf="deviceType == DeviceType.TicketDispenser">
                        <b>A</b>: {{ 'ticket-dispenser' | translate }}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <p *ngIf="deviceTypeMultiCtrl.value == null || deviceTypeMultiCtrl?.value.length == 0">
        {{ 'no-selected-device-types' | translate }}</p>
    <div class="input-group">
        <span *ngFor="let deviceType of deviceTypeMultiCtrl?.value" class="badge badge-info mr-1">
            <span *ngIf="deviceType == DeviceType.MainDisplay" class="badge badge-info">M</span>
            <span *ngIf="deviceType == DeviceType.DeskDisplay" class="badge badge-info">D</span>
            <span *ngIf="deviceType == DeviceType.TicketDispenser" class="badge badge-info">A</span>
        </span>
    </div>
</div>