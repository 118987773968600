<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="m-0 font-weight-bold text-primary">{{ 'mail-agent' | translate }}</h5>
    </div>
    <div *ngIf="emailConfig" class="card-body">
        <form #form="ngForm" autocomplete="off">
            <input type="hidden" name="systemId" [(ngModel)]="emailConfig.systemId" [value]="emailConfig.id">
            <input type="hidden" name="systemId" [(ngModel)]="emailConfig.systemId" [value]="emailConfig.locationId">
            <input type="hidden" name="systemId" [(ngModel)]="emailConfig.systemId" [value]="emailConfig.systemId">
            <div class="form-group">
                *{{ 'server-address' | translate }}
                <div class="input-group">
                    <input name="smtpServer" #smtpServer="ngModel" [(ngModel)]="emailConfig.smtpServer"
                        class="form-control" required>
                </div>
                <div *ngIf="smtpServer.invalid && (smtpServer.dirty || smtpServer.touched)"
                    class="text-danger mx-2 my-1">
                    <div *ngIf="smtpServer.errors.required">{{ 'server-address-required' | translate }}</div>
                </div>
            </div>

            <div class="form-group">
                *{{ 'smtp-port' | translate }}
                <div class="input-group">
                    <input name="port" #port="ngModel" [(ngModel)]="emailConfig.port"
                        class="form-control" required type="number" (change)="updateAuthenticationMethod()">
                </div>
                <div *ngIf="port.invalid && (port.dirty || port.touched)" class="text-danger mx-2 my-1">
                    <div *ngIf="port.errors.required">{{ 'smtp-port-required' | translate }}</div>
                </div>
            </div>

            <mat-form-field>
                <mat-label>{{ 'authentication-method' | translate }}</mat-label>
                <mat-select matNativeControl name="authentication" #authentication="ngModel"
                    [(ngModel)]="emailConfig.authentication" required>
                    <mat-option [value]="Authentication.None">{{ 'none' | translate }}</mat-option>
                    <mat-option [value]="Authentication.STARTTLS">STARTTLS</mat-option>
                    <mat-option [value]="Authentication.SSL">SSL/TLS</mat-option>
                </mat-select>
            </mat-form-field>

            <div class="form-group">
                *{{ 'login' | translate }}
                <div class="input-group">
                    <input name="userName" #userName="ngModel" [(ngModel)]="emailConfig.userName"
                        class="form-control" required>
                </div>
                <div *ngIf="userName.invalid && (userName.dirty || userName.touched)"
                    class="text-danger mx-2 my-1">
                    <div *ngIf="userName.errors.required">{{ 'login-required' | translate }}</div>
                </div>
            </div>

            <div class="form-group">
                *{{ 'password' | translate }}
                <div class="input-group">
                    <input name="password" #password="ngModel" [(ngModel)]="emailConfig.password"
                        class="form-control" required type="password">
                </div>
                <div *ngIf="password.invalid && (password.dirty || password.touched)"
                    class="text-danger mx-2 my-1">
                    <div *ngIf="password.errors.required">{{ 'password-required' | translate }}</div>
                </div>
            </div>
            
            <div class="form-group row">
                <button class="btn btn-primary m-1" type="submit" [disabled]="formSubmitted || form.invalid"
                    (click)="onSubmit(); formSubmitted=true">
                <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                    aria-hidden="true"></span>{{ 'save-mail-agent-configuration' | translate }}
                </button>
            </div>
            <label *ngIf="form.invalid" class="text-danger">*{{ 'complete-required-fields' | translate }}</label>
        </form>
    </div>
</div>


<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="m-0 font-weight-bold text-primary">{{ 'test-mail-agent' | translate }}</h5>
    </div>

    <div *ngIf="emailConfig" class="card-body">
        <form #testform="ngForm" autocomplete="off">
            <div class="form-group">
                *{{ 'email' | translate }}
                <div class="input-group">
                    <input name="testMailAddress"  [(ngModel)]="testMailAddress"
                        class="form-control" required>
                </div>
            </div>
            <div class="form-group row">
                <button class="btn btn-primary m-1" type="submit" [disabled]="testMailFormSubmitted || testform.invalid"
                    (click)="onTestMail(); testMailFormSubmitted=true">
                <span *ngIf="testMailFormSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                    aria-hidden="true"></span>{{ 'send-test-mail' | translate }}
                </button>
            </div>
        </form>
    </div>
</div> 
