<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="col-10 m-0 font-weight-bold text-primary">{{ 'amms-category-mapping-title' | translate }}</h5>
    </div>
    <div class="card-body">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>#</th>
                    <th>{{ 'categories' | translate }}</th>
                    <th>{{ 'amms-service-code' | translate }}</th>
                    <th>{{ 'amms-unit-code' | translate }}</th>
                    <th>{{ 'amms-resource-code' | translate }}</th>
                    <th>{{ 'amms-location-code' | translate }}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let map of categoriesBindings; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>
                        <mat-form-field class="ml-2">
                            <mat-select name="categoryId" #categoryId="ngModel" [(ngModel)]="map.categoryId">
                                <mat-option *ngFor="let category of categories" [value]="category.id">
                                    {{ category.fullName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field class="ml-2">
                            <mat-select name="serviceCode" #serviceCode="ngModel" [(ngModel)]="map.ammsServiceCodeId">
                                <mat-option *ngFor="let item of serviceCodesForCat" [value]="item.id">
                                    {{ item.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field class="ml-2">
                            <mat-select name="unitCode" #unitCode="ngModel" [(ngModel)]="map.ammsUnitCodeId">
                                <mat-option *ngFor="let item of unitCodesForCat" [value]="item.id">
                                    {{ item.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button class="btn btn-danger btn-circle btn-sm mx-1 mb-1" tooltipclass="tooltip-danger"
                            (click)="map.ammsUnitCodeId = undefined">
                            <i class="fas fa-fw fa-trash"></i>
                        </button>
                    </td>
                    <td>
                        <mat-form-field class="ml-2">
                            <mat-select name="resourceCode" #resourceCode="ngModel" [(ngModel)]="map.ammsResourceId">
                                <mat-option *ngFor="let item of resourceCodesForCat" [value]="item.id">
                                    {{ item.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button class="btn btn-danger btn-circle btn-sm mx-1 mb-1" tooltipclass="tooltip-danger"
                            (click)="map.ammsResourceId = undefined">
                            <i class="fas fa-fw fa-trash"></i>
                        </button>
                    </td>
                    <td>
                        <mat-form-field class="ml-2">
                            <mat-select name="locationCode" #locationCode="ngModel" [(ngModel)]="map.ammsLocationId">
                                <mat-option *ngFor="let item of locationCodesForCat" [value]="item.id">
                                    {{ item.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button class="btn btn-danger btn-circle btn-sm mx-1 mb-1" tooltipclass="tooltip-danger"
                            (click)="map.ammsLocationId = undefined">
                            <i class="fas fa-fw fa-trash"></i>
                        </button>
                    </td>
                    <td>
                        <button class="btn btn-danger btn-circle btn-sm mx-1 mb-1" tooltipclass="tooltip-danger"
                            (click)="removeMap(i)">
                            <i class="fas fa-fw fa-trash"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <button class="btn btn-primary m-1" (click)="addNewMap()">{{ 'add' | translate }}</button>
        <button class="btn btn-primary m-1" (click)="saveMaps()">{{ 'save' | translate }}</button>
    </div>
</div>

<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="col-10 m-0 font-weight-bold text-primary">{{ 'amms-service-codes-title' | translate }}</h5>
    </div>
    <div class="card-body">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>#</th>
                    <th>{{ 'service-code-id' | translate }}</th>
                    <th>{{ 'service-code-name' | translate }}</th>
                    <th>{{ 'service-code-code' | translate }}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of serviceCodes; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>
                        {{ item.id }}
                    </td>
                    <td>
                        <div class="input-group">
                            <input [(ngModel)]="item.name" class="form-control">
                        </div>
                    </td>
                    <td>
                        <div class="input-group">
                            <input [(ngModel)]="item.code" class="form-control">
                        </div>
                    </td>
                    <td>
                        <button class="btn btn-danger btn-circle btn-sm mx-1 mb-1" tooltipclass="tooltip-danger"
                            (click)="remove(i, serviceCodes)">
                            <i class="fas fa-fw fa-trash"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <button class="btn btn-primary m-1" (click)="addNew(serviceCodes, 0)">{{ 'add' | translate }}</button>
        <button class="btn btn-primary m-1" (click)="saveCodes(serviceCodes, 0)">{{ 'save' | translate }}</button>
    </div>
</div>

<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="col-10 m-0 font-weight-bold text-primary">{{ 'amms-unit-codes-title' | translate }}</h5>
    </div>
    <div class="card-body">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>#</th>
                    <th>{{ 'service-code-id' | translate }}</th>
                    <th>{{ 'service-code-name' | translate }}</th>
                    <th>{{ 'service-code-code' | translate }}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of unitCodes; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>
                        {{ item.id }}
                    </td>
                    <td>
                        <div class="input-group">
                            <input [(ngModel)]="item.name" class="form-control">
                        </div>
                    </td>
                    <td>
                        <div class="input-group">
                            <input [(ngModel)]="item.code" class="form-control">
                        </div>
                    </td>
                    <td>
                        <button class="btn btn-danger btn-circle btn-sm mx-1 mb-1" tooltipclass="tooltip-danger"
                            (click)="remove(i, unitCodes)">
                            <i class="fas fa-fw fa-trash"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <button class="btn btn-primary m-1" (click)="addNew(unitCodes, 1)">{{ 'add' | translate }}</button>
        <button class="btn btn-primary m-1" (click)="saveCodes(unitCodes, 1)">{{ 'save' | translate }}</button>
    </div>
</div>

<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="col-10 m-0 font-weight-bold text-primary">{{ 'amms-service-resource-title' | translate }}</h5>
    </div>
    <div class="card-body">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>#</th>
                    <th>{{ 'service-code-id' | translate }}</th>
                    <th>{{ 'service-code-name' | translate }}</th>
                    <th>{{ 'service-code-code' | translate }}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of resourceCodes; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>
                        {{ item.id }}
                    </td>
                    <td>
                        <div class="input-group">
                            <input [(ngModel)]="item.name" class="form-control">
                        </div>
                    </td>
                    <td>
                        <div class="input-group">
                            <input [(ngModel)]="item.code" class="form-control">
                        </div>
                    </td>
                    <td>
                        <button class="btn btn-danger btn-circle btn-sm mx-1 mb-1" tooltipclass="tooltip-danger"
                            (click)="remove(i, resourceCodes)">
                            <i class="fas fa-fw fa-trash"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <button class="btn btn-primary m-1" (click)="addNew(resourceCodes, 3)">{{ 'add' | translate }}</button>
        <button class="btn btn-primary m-1" (click)="saveCodes(resourceCodes, 3)">{{ 'save' | translate }}</button>
    </div>
</div>

<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="col-10 m-0 font-weight-bold text-primary">{{ 'amms-service-location-title' | translate }}</h5>
    </div>
    <div class="card-body">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>#</th>
                    <th>{{ 'service-code-id' | translate }}</th>
                    <th>{{ 'service-code-name' | translate }}</th>
                    <th>{{ 'service-code-code' | translate }}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of locationCodes; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>
                        {{ item.id }}
                    </td>
                    <td>
                        <div class="input-group">
                            <input [(ngModel)]="item.name" class="form-control">
                        </div>
                    </td>
                    <td>
                        <div class="input-group">
                            <input [(ngModel)]="item.code" class="form-control">
                        </div>
                    </td>
                    <td>
                        <button class="btn btn-danger btn-circle btn-sm mx-1 mb-1" tooltipclass="tooltip-danger"
                            (click)="remove(i, locationCodes)">
                            <i class="fas fa-fw fa-trash"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <button class="btn btn-primary m-1" (click)="addNew(locationCodes, 2)">{{ 'add' | translate }}</button>
        <button class="btn btn-primary m-1" (click)="saveCodes(locationCodes, 2)">{{ 'save' | translate }}</button>
    </div>
</div>

<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="col-10 m-0 font-weight-bold text-primary">{{ 'amms-default-category-title' | translate }}</h5>
    </div>
    <div class="card-body">
        {{'amms-default-category' | translate }}
        <mat-form-field class="ml-2">
            <mat-select name="defaultCategoryId" [(ngModel)]="defaultCategoryId">
                <mat-option *ngFor="let category of categories" [value]="category.id">
                    {{ category.fullName }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div>
            <button class="btn btn-primary m-1" (click)="setDefaultCategoryId()">{{ 'save' | translate }}</button>
        </div>
    </div>
</div>