import { UntypedFormControl } from "@angular/forms";
import { Category } from "../category/category.model";
import { ReservationQuestionType } from "./reservation-question-type.enum";

export class ReservationQuestion {
    public id: number;
    public systemId: number;
    public locationId: number;
    public identityQuestion: boolean;
    public required: boolean;
    public order: number;
    public question: string;
    public questionType: ReservationQuestionType;
    public visible: boolean;
    public hiddenCategories: Category[];
    public translations: QuestionTranslation[];

    constructor(reservationQuestion?: ReservationQuestionTable) {
        this.id = reservationQuestion?.id ?? undefined;
        this.systemId = reservationQuestion?.systemId ?? undefined;
        this.locationId = reservationQuestion?.locationId ?? undefined;
        this.identityQuestion = reservationQuestion?.identityQuestion ?? undefined;
        this.required = reservationQuestion?.required ?? undefined;
        this.order = reservationQuestion?.order ?? undefined;
        this.questionType = reservationQuestion?.questionType ?? undefined;
        this.question = reservationQuestion?.questions.join(";") ?? undefined;
        this.visible = reservationQuestion?.visible ?? undefined;
        this.hiddenCategories = reservationQuestion?.hiddenCategories.value ?? undefined;
        this.translations = reservationQuestion?.translations ?? undefined;
    }
}

export class ReservationQuestionTable {
    public id: number;
    public systemId: number;
    public locationId: number;
    public identityQuestion: boolean;
    public required: boolean;
    public order: number;
    public questions: string[];
    public questionType: ReservationQuestionType;
    public visible: boolean;
    public hiddenCategories: UntypedFormControl;
    public translations: QuestionTranslation[];

    constructor(categories: Category[], reservationQuestion?: ReservationQuestion) {
        this.id = reservationQuestion?.id ?? 0;
        this.systemId = reservationQuestion?.systemId ?? undefined;
        this.locationId = reservationQuestion?.locationId ?? undefined;
        this.identityQuestion = reservationQuestion?.identityQuestion ?? false;
        this.required = reservationQuestion?.required ?? false;
        this.order = reservationQuestion?.order ?? undefined;
        this.questionType = reservationQuestion?.questionType ?? undefined;
        this.questions = reservationQuestion?.question.split(';') ?? [""];
        this.visible = reservationQuestion?.visible ?? true;
        let initCategories = [];
        reservationQuestion?.hiddenCategories.forEach(hiddenCategory => {
            initCategories.push(categories.find(x => x.id == hiddenCategory.id));
        });
        this.hiddenCategories = new UntypedFormControl(initCategories);
        this.translations = reservationQuestion?.translations ?? [];
    }
}

export class QuestionTranslation {
    public locale: string;
    public translation: string;

    constructor(locale: string, translation: string) {
        this.locale = locale;
        this.translation = translation;
    }
}