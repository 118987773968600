import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from './auth/auth.service';
import { timeout } from 'rxjs/operators';
import { Observable } from 'rxjs';


const DEFAULT_TIMEOUT = 3000;

@Injectable({
  providedIn: 'root'
})
export class AuditLogClientService {

  appBase: string;

  constructor(private http: HttpClient, private authService: AuthService) { }

  public setAppBase(appBase: string) {
    this.appBase = appBase;
  }

  public GetLogs(dateFrom: Date, dateTo: Date, locationId: number, entityType: number): Observable<any> {
    const params = new HttpParams()
      .set('from', dateFrom.toISOString())
      .set('to', dateTo.toISOString())
      .set('locationId', locationId ? locationId.toString() : '')
      .set('entityType', entityType ? entityType.toString() : '');
    return this.http.get(`${this.appBase}api/${this.authService.getSystemId()}`, { params: params })
      .pipe(timeout(DEFAULT_TIMEOUT));
  }
}
