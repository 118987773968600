import { Component, OnInit } from '@angular/core';
import { Category } from 'projects/qcloud-models/category/category.model';
import { QcloudConsoleService } from '../../qcloud-console.service';

@Component({
  selector: 'lib-generate-ticket-modal',
  templateUrl: './generate-ticket-modal.component.html',
  styleUrls: ['./generate-ticket-modal.component.css']
})
export class GenerateTicketModalComponent implements OnInit {

  categories: Category[];
  selectedCategory: Category;
  isConfirmVisible: Boolean;

  constructor(public consoleService: QcloudConsoleService) {
    this.categories = consoleService.getCategoriesToCallTicket();
    this.selectedCategory = this.categories[0];
  }

  ngOnInit(): void {
    this.isConfirmVisible = false;
  }

  generateTicket() {
    this.consoleService.generateTicket(this.selectedCategory.id);
    this.toggleConfirm();
  }

  toggleConfirm(){
    this.isConfirmVisible = !this.isConfirmVisible;
  }

}
