import { Component, Input, OnInit} from '@angular/core';
import { EmployeeHolidayTime } from 'projects/qcloud-models/grafik/employee/employee-holiday-time.model';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';

@Component({
  selector: 'app-holiday-time',
  templateUrl: './holiday-time.component.html',
  styleUrls: ['./holiday-time.component.css']
})
export class HolidayTimeComponent implements OnInit {

  @Input() employeeId: number;
  @Input() holidayTimes: EmployeeHolidayTime[];
  addingNewHolidayTime: boolean;
  newHolidayTime: EmployeeHolidayTime;
  changedHolidayTime: EmployeeHolidayTime;

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.getHolidayTimes();
    this.changedHolidayTime =
      new EmployeeHolidayTime(+this.authService.getLocationId(), +this.authService.getSystemId());
  }

  getHolidayTimes() {
    this.holidayTimes.forEach(ht=>{
      ht.startDate = new Date(ht.startDate),
      ht.endDate = new Date(ht.endDate)
    })
  }

  updateHolidayTimes(newHolidayTime: EmployeeHolidayTime, holidayTime: EmployeeHolidayTime) {
    if(holidayTime){
      let swapIndex = this.holidayTimes.findIndex(ht=>ht==holidayTime);
      this.holidayTimes.splice(swapIndex,1);
    }
    if (newHolidayTime) {
      newHolidayTime.id = this.holidayTimes.length;
      this.holidayTimes.push(newHolidayTime);
    }
  }

  editHolidayTime(holidayTime: EmployeeHolidayTime) {
    this.newHolidayTime =
      new EmployeeHolidayTime(+this.authService.getLocationId(), +this.authService.getSystemId());
    if (holidayTime) {
      this.changedHolidayTime = holidayTime;
    } else {
      this.changedHolidayTime = this.newHolidayTime;
    }
  }

  onSubmitHoliday(holidayTime: EmployeeHolidayTime) {
    this.updateHolidayTimes(this.newHolidayTime, holidayTime);
    this.editHolidayTime(holidayTime);
    this.changedHolidayTime = this.newHolidayTime;
  }

  deleteHolidayTime(holidayTime: EmployeeHolidayTime) {
    this.updateHolidayTimes(null, holidayTime);
    this.editHolidayTime(this.newHolidayTime);
  }

  getRange(range: Date[]) {
    this.newHolidayTime.startDate = range[0];
    this.newHolidayTime.endDate = range[1];
  }

}