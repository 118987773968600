<div class="form-group">
    <div class="input-group">
        *<div class="font-weight-bold">{{ 'style-of-printed-tickets' | translate }}</div>
    </div>
    <mat-form-field class="ml-2">
        <mat-select name="ticketLayoutType" #ticketLayoutType="ngModel" [(ngModel)]="device.ticketLayoutType" required>
            <mat-option [value]="TicketLayoutType.LAYOUT60MM">
                {{ 'width' | translate }} 60{{ 'mm' | translate }}
            </mat-option>
            <mat-option [value]="TicketLayoutType.LAYOUT80MM">
                {{ 'width' | translate }} 80{{ 'mm' | translate }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div *ngIf="ticketLayoutType.invalid && (ticketLayoutType.dirty || ticketLayoutType.touched)" class="text-danger">
        <div *ngIf="ticketLayoutType.errors.required">{{ 'style-of-printed-tickets-required' | translate }}</div>
    </div>
</div>


<div class="form-group">
    <div class="item-group">
        <mat-checkbox name="canPrintReservedTickets" #canPrintReservedTickets="ngModel" class="font-weight-bold"
            [(ngModel)]="device.canPrintReservedTickets" color="primary">{{ 'print-reserved-tickets' | translate }}
        </mat-checkbox>
    </div>
</div>

<div class="form-group">
    <div class="font-weight-bold">{{ 'reservation-button-text' | translate }}</div>
    <div class="input-group">
        <input name="reservationButtonText" #reservationButtonText="ngModel" [(ngModel)]="device.reservationButtonText"
            class="form-control">
    </div>
</div>

<div *ngIf="advancedMode">
    <div class="form-group">
        <div class="item-group">
            <mat-checkbox color="primary" name="canMakeReservations" #canMakeReservations="ngModel"
                [(ngModel)]="device.canMakeReservations">{{ 'make-reservation' | translate }}</mat-checkbox>
        </div>
    </div>
    
    <div class="form-group">
        {{ 'make-reservation-text' | translate }}
        <div class="input-group">
            <input name="makeReservationButtonText" #makeReservationButtonText="ngModel"
                [(ngModel)]="device.makeReservationButtonText" class="form-control">
        </div>
    </div>

    <div class="form-group">
        <div class="item-group">
            <mat-checkbox color="primary" name="canShowAvgWaitTime" #canShowAvgWaitTime="ngModel"
                [(ngModel)]="device.canShowAvgWaitTime">{{ 'show-average-waiting-time' | translate }}</mat-checkbox>
        </div>
    </div>

    <div class="form-group">
        <div class="item-group">
            <mat-checkbox color="primary" name="canShowQueue" #canShowQueue="ngModel"
                [(ngModel)]="device.canShowQueue">{{ 'show-queue-state' | translate }}</mat-checkbox>
        </div>
    </div>
</div>

<div class="form-group">
    <div class="input-group">
        *<div class="font-weight-bold">{{ 'layout' | translate }}</div>
    </div>
    <mat-form-field class="ml-2">
        <mat-select name="layoutType" #layoutType="ngModel" [(ngModel)]="device.layoutType" required>
            <mat-option [value]="LayoutType.Vertical">{{ 'vertical' | translate }}</mat-option>
            <mat-option [value]="LayoutType.Horizontal">{{ 'horizontal' | translate }}</mat-option>
            <mat-option [value]="LayoutType.DeskDispenser">{{ 'desk-dispenser' | translate }}</mat-option>
        </mat-select>
    </mat-form-field>
    <div *ngIf="layoutType.invalid && (layoutType.dirty || layoutType.touched)" class="text-danger">
        <div *ngIf="layoutType.errors.required">{{ 'layout-required' | translate }}</div>
    </div>
</div>

<div class="form-group">
    <div class="input-group">
        <div class="font-weight-bold">{{ 'ticket-generation-mode' | translate }}</div>
    </div>
    <mat-form-field class="ml-2">
        <mat-select name="ticketGenerationType" #ticketGenerationType="ngModel" [(ngModel)]="device.ticketGenerationType"
            required>
            <mat-option [value]="TicketGenerationType.Print">{{ 'print' | translate }}</mat-option>
            <mat-option [value]="TicketGenerationType.Sms">{{ 'sms' | translate }}</mat-option>
            <mat-option [value]="TicketGenerationType.PrintOrSms">{{ 'print-or-sms' | translate }}</mat-option>
        </mat-select>
    </mat-form-field>
</div>

<app-dnd-categories [categories]="categories" [categoryTree]="device.deviceCategoryTree" [maxDepth]="maxDepthCategoryTree"
    (categoryTreeToEmit)="getCategoryTree($event)"></app-dnd-categories>