import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-device-activation-column',
  templateUrl: './device-activation-column.component.html',
  styleUrls: ['./device-activation-column.component.css']
})
export class DeviceActivationColumnComponent implements OnInit {

  @Input() activationCode: string;
  @Input() activated: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
