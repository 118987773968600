import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { LocationData } from 'projects/qcloud-models/location/location.model';
import { ReplaySubject, Subject, takeUntil, take } from 'rxjs';

@Component({
  selector: 'app-select-user-locations',
  templateUrl: './select-user-locations.component.html',
  styleUrls: ['./select-user-locations.component.css']
})
export class SelectUserLocationsComponent implements OnInit {

  @Input() locations: LocationData[] = [];
  @Input() locationMultiCtrl: UntypedFormControl;
  public locationMultiFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredLocationsMulti: ReplaySubject<LocationData[]> = new ReplaySubject<LocationData[]>(1);

  protected _onDestroy = new Subject<void>();

  @Input() title: string;
  @Input() noSelectedLocations: string;
  @Input() isDisabled: boolean;
  @Input() enabledByLocationIds: number[] = [];
  @Output() locationsToEmit = new EventEmitter<LocationData[]>();

  constructor() { }

  ngOnInit(): void {
    // load the initial category list
    this.filteredLocationsMulti.next(this.locations.slice());
    // listen for search field value changes
    this.locationMultiFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterCategoriesMulti();
    });
  }

  emitLocations(locations: LocationData[]) {
    this.locationsToEmit.emit(locations);
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredLocationsMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.locationMultiCtrl.patchValue(val);
        } else {
          this.locationMultiCtrl.patchValue([]);
        }
        this.locationsToEmit.emit(this.locationMultiCtrl.value);
      });
  }

  protected filterCategoriesMulti() {
    if (!this.locations) {
      return;
    }
    // get the search keyword
    let search = this.locationMultiFilterCtrl.value;
    if (!search) {
      this.filteredLocationsMulti.next(this.locations.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the Categories
    this.filteredLocationsMulti.next(
      this.locations.filter(location => location.locationName.toLowerCase().indexOf(search) > -1)
    );
  }
  isLocationEnable(locationId: number) {
    var res = this.enabledByLocationIds.some(l => l === locationId);
    return res;
  }
}