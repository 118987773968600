<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="col-10 m-0 font-weight-bold text-primary">{{ 'edit-profile' | translate }}</h5>
    </div>
    <div *ngIf="user" class="card-body">
        <form #form="ngForm" autocomplete="off">

            <div class="form-group">
                <div class="row">
                    <div class="col-6">
                        *{{ 'first-name' | translate }}
                        <div class="input-group">
                            <input name="firstName" #firstName="ngModel" [(ngModel)]="user.firstName"
                                class="form-control" required>
                        </div>
                        <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)" class="text-danger mx-2 my-1">
                            <div *ngIf="firstName.errors.required">{{ 'first-name-required' | translate }}</div>
                        </div>
                    </div>
                    <div class="col-6">
                        *{{ 'last-name' | translate }}
                        <div class="input-group">
                            <input name="lastName" #lastName="ngModel" [(ngModel)]="user.lastName"
                                class="form-control" required>
                        </div>
                        <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)" class="text-danger mx-2 my-1">
                            <div *ngIf="lastName.errors.required">{{ 'last-name-required' | translate }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                {{ 'email' | translate }}
                <div class="input-group">
                    <input name="email" #email="ngModel" [(ngModel)]="user.email" class="form-control">
                </div>
            </div>

            <div class="form-group row">
                <button class="btn btn-danger m-1" routerLink="/dashboard">{{ 'back' | translate }}</button>
                <button class="btn btn-primary m-1" type="submit" [disabled]="formSubmitted || form.invalid"
                    (click)="onSubmit(); formSubmitted=true">
                    <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                        aria-hidden="true"></span>
                    {{ 'save-profile' | translate }}
                </button>
            </div>
            <label *ngIf="form.invalid" class="text-danger">*{{ 'complete-required-fields' | translate }}</label>
        </form>
    </div>
</div>

<app-change-password *ngIf="user" [userId]="user.id"></app-change-password>