import { LOCALE_ID, Inject, Injectable } from '@angular/core';
import { CalendarEventTitleFormatter, CalendarEvent } from 'angular-calendar';
import { DatePipe } from '@angular/common';

@Injectable()
export class EventTitleFormatter extends CalendarEventTitleFormatter {
  constructor(@Inject(LOCALE_ID) private locale: string) {
    super();
  }

  month(event: CalendarEvent): string {
    return `<b>${new DatePipe(this.locale).transform(event.start,'HH:mm')}</b>
    -
    <b>${new DatePipe(this.locale).transform(event.end,'HH:mm')}</b>
    ${event.title}`;
  }

  week(event: CalendarEvent): string {
    return `<div class="font-weight-bold">${new DatePipe(this.locale).transform(event.start,'HH:mm')}
    -
    ${new DatePipe(this.locale).transform(event.end,'HH:mm')}</div>
    ${event.title}`;
  }

  day(event: CalendarEvent): string {
    return `<div class="font-weight-bold">${new DatePipe(this.locale).transform(event.start,'HH:mm')}
    -
    ${new DatePipe(this.locale).transform(event.end,'HH:mm')}</div>
    ${event.title}`;
  }
}