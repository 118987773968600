<div class="card-header py-3">
    <h6 class="m-0 font-weight-bold text-primary">{{ 'average-desk-waiting-time' | translate}}
        <span class="float-right text-secondary">{{ 'average' | translate }}: 
            <span class="badge badge-primary lead">{{ averageWaitingTime | date: 'mm:ss' }}</span>
        </span>
    </h6>
</div>
<div class="card-body">
    <div *ngFor="let desk of desks; let i = index">
        <h4 class="small font-weight-bold">{{ desk.fullName }}
            <span class="float-right">{{ averageDeskWaitingTime[desk.id] | date: 'mm:ss' }}</span>
        </h4>
        <div class="progress mb-4">
            <div class="progress-bar" role="progressbar" [style.width]="(averageDeskWaitingTime[desk.id]/maxWaitingTime)*100 + '%'"
                aria-valuenow="averageDeskWaitingTime[desk.id]" aria-valuemin="0" aria-valuemax="maxWaitingTime">
            </div>
            <app-statistics-average-bar [moveRight]="(averageWaitingTime/maxWaitingTime)*100 + '%'">
                </app-statistics-average-bar>
        </div>
    </div>
</div>
