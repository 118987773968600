import { Component, OnInit } from '@angular/core';
import { Specialisation } from 'projects/qcloud-models/grafik/specialisation/specialisation.model';
import { SpecialisationService } from 'projects/qcloud-rest-client/src/lib/grafik/specialisation.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { RouteNavigationService } from 'projects/qcloud-web/src/app/shared/route-navigation.service';

@Component({
  selector: 'app-specialities',
  templateUrl: './specialities.component.html',
  styleUrls: ['./specialities.component.css']
})
export class SpecialitiesComponent implements OnInit {

  specialities: Specialisation[];
  selectedSpecialityId: number;

  constructor(private specialityService: SpecialisationService, private translate: TranslateService,
    private toastr: ToastrService, public router: RouteNavigationService) { }

  ngOnInit(): void {
    this.getSpecialities();
  }

  getSpecialities() {
    this.specialityService.getSpecialities((specialities) => {
      this.specialities = specialities;
    }, () => { })
  }

  onDeleteConfirm(confirmed: boolean) {
    if (confirmed) {
      this.deleteSpeciality(this.selectedSpecialityId);
    }
  }

  deleteSpeciality(specialityId: number) {
    this.specialityService.deleteSpeciality(specialityId).subscribe(
      res => {
        this.getSpecialities();
      },
      err => {
        this.translate.get('delete-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }
}
