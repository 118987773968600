import { Component, Input, OnInit } from '@angular/core';
import { Category } from 'projects/qcloud-models/category/category.model';
import { Dictionary } from 'projects/qcloud-models/dictionary';
import { ProcessedTicketsCount } from 'projects/qcloud-models/statistics/processed-tickets-count';

@Component({
  selector: 'app-ticket-in-category-statistics',
  templateUrl: './ticket-in-category-statistics.component.html',
  styleUrls: ['./ticket-in-category-statistics.component.css']
})
export class TicketInCategoryStatisticsComponent implements OnInit {

  @Input() categories: Category[];
  @Input() processedCategoryTicketsCounts: Dictionary<ProcessedTicketsCount>;
  selectedCategoryId: number;
  maxTickets: number;

  constructor() { }

  ngOnInit(): void {
    this.selectedCategoryId = this.categories[0].id;
    this.changeCategory();
  }

  changeCategory() {
    if (this.processedCategoryTicketsCounts[this.selectedCategoryId]) {
      this.maxTickets = Math.max(...[this.processedCategoryTicketsCounts[this.selectedCategoryId].acceptedTicketsCount,
        this.processedCategoryTicketsCounts[this.selectedCategoryId].cancelledTicketsCount,
        this.processedCategoryTicketsCounts[this.selectedCategoryId].redirectedTicketsCount,
        this.processedCategoryTicketsCounts[this.selectedCategoryId].waitingTicketsCount]);
    }
  }

}
