import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { SystemCategory } from '../../../../qcloud-models/category/system-category.model';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs/internal/Observable';
import { TicketsQuestion } from 'projects/qcloud-models/device/ticket-question';

const DEFAULT_TIMEOUT = 3000;

@Injectable({
    providedIn: 'root'
})
export class TicketSystemCategoryQuestionService {

    appBase: string;
    endpoint: string = "systemCategories";
    constructor(private http: HttpClient, private authService: AuthService) { }

    public setAppBase(appBase: string) {
        this.appBase = appBase;
    }

    getTicketQuestion(id: number): Observable<TicketsQuestion> {
        return this.http.get<TicketsQuestion>(`${this.appBase}api/${this.authService.getSystemId()}/ticket-question/${id}`);
    }

    getTicketQuestions(): Observable<TicketsQuestion[]> {
        return this.http.get<TicketsQuestion[]>(`${this.appBase}api/${this.authService.getSystemId()}/ticket-question`);
    }

    setTicketQuestion(body: TicketsQuestion) {
        return this.http.put<TicketsQuestion>(`${this.appBase}api/${this.authService.getSystemId()}/ticket-question/${body.id}`, body);
    }

    // Delete a ticket question by id
    deleteTicketQuestion(id: number): Observable<void> {
        return this.http.delete<void>(`${this.appBase}api/${this.authService.getSystemId()}/ticket-question/${id}`);
    }
}
