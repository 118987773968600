<div class="login-container mt-4">
    <div class="justify-content-center">
        <div class="card o-hidden border-0 shadow-lg">
            <div class="card-body p-0">
                <div class="p-5">
                    <div class="text-center">
                        <h1 class="h4 text-gray-900 mb-4">{{ 'console.log-in-to-console' | translate }}!</h1>
                    </div>
                    <div class="form-group">
                        <select [(ngModel)]="choosenDesk" class="form-control">
                            <option *ngFor="let desk of deskList" [ngValue]="desk">
                                {{ desk.fullName }}
                            </option>
                        </select>
                    </div>
                    <div class="buttons-container">
                        <button class="btn btn-danger" style="margin-right: 1rem;" data-dismiss="modal" routerLink="/qcloud/welcome" (click)="routeToChoosingLocation()">{{ 'back' |
                            translate }}</button>
                        <button (click)="loginToConsole()" class="btn btn-primary btn-user btn-login">
                            {{ 'log-in' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>