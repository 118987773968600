import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { parseString } from 'xml2js';
import { DeviceConfigurationService } from './device-configuration.service';
import { Customisation } from 'projects/qcloud-models/device/customisation.model';
import { BarType } from 'projects/qcloud-models/device/bar-type.enum';
import { DeviceCustomization } from 'projects/qcloud-models/device/device-customization.model';
import { Observable } from 'rxjs';

@Injectable()
export class CustomisationService {

  public customisation: Customisation;
  public deviceCustomisation: DeviceCustomization;
  public customBackgroundUrl: string;
  private personalisationDefaultBackgroundUrl: string;
  private appBase: string;
  private qdsAppBase: string;
  public clockIntervalId: any;
  public rssIntervalId: any;
  public rssItemIndex: number;
  public rssItems: any[];
  public systemId: number;
  public locationId: number;

  constructor(private configurationService: DeviceConfigurationService, private http: HttpClient) {
    this.appBase = configurationService.getAppBase();
    var deviceConfiguration = this.configurationService.getConfiguration();
    this.deviceCustomisation = deviceConfiguration.deviceCustomization;
    this.qdsAppBase = deviceConfiguration.deviceNetworkConfiguration.qdsHost;
    this.customBackgroundUrl = `/assets/background.png`;
    this.personalisationDefaultBackgroundUrl = deviceConfiguration.personalisation.defaultBackgroundUri;
    this.customisation = new Customisation();
    this.setCustomisation();
    this.systemId = deviceConfiguration.systemId;
    this.locationId = deviceConfiguration.locationId;
    this.rssItemIndex = 0;
  }

  public getCustomisation(): Customisation {
    return this.customisation;
  }

  private setCustomisation() {
    this.setBackgroundUrl();
    this.setBar();
    this.setDateAndTime();
    this.setText();
    this.setRss();
  }

  private setBackgroundUrl() {
    if (this.deviceCustomisation.customBackground) {
      this.customisation.backgroundUrl = this.qdsAppBase + "cdn/" + this.deviceCustomisation.backgroundImgPath;
    }
    else if(this.personalisationDefaultBackgroundUrl) {
      this.customisation.backgroundUrl = this.qdsAppBase + "cdn/" + this.personalisationDefaultBackgroundUrl;
    }else {
      this.customisation.backgroundUrl = this.customBackgroundUrl;
    }

  }

  private setBar() {
    if (this.deviceCustomisation.barType != BarType.Without || this.deviceCustomisation.displayDate ||
      this.deviceCustomisation.displayTime) {
      this.customisation.showBottomBar = true;
    }
  }

  private setDateAndTime() {
    this.customisation.showClock = this.deviceCustomisation.displayTime;
    this.customisation.showDate = this.deviceCustomisation.displayDate;
    this.customisation.date = new Date();
    if (this.deviceCustomisation.displayTime) {
      clearInterval(this.clockIntervalId);
      this.clockIntervalId = setInterval(() => this.customisation.date = new Date(), 10000);
    }
    else {
      clearInterval(this.clockIntervalId);
    }
  }

  private setText() {
    this.customisation.showText = this.deviceCustomisation.barType != BarType.Without;
    if (this.deviceCustomisation.barType == BarType.Text) {
      this.customisation.customText = this.deviceCustomisation.barText;
    }
  }

  private setRss() {
    if (this.deviceCustomisation.barType == BarType.Rss) {
      this.getRssContent().subscribe(
        (data: any) => {
          parseString(data, function (err, result) {
            this.rssItems = result.rss.channel[0].item;
            clearInterval(this.rssIntervalId);
            this.rssInterval(this);
            this.rssIntervalId = setInterval(() => this.rssInterval(), 30000);
          });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  public rssInterval() {
    this.customisation.customText = this.rssItems[this.rssItemIndex].description;

    if (this.rssItemIndex > this.rssItems.length) {
      this.rssItemIndex = 0;
    }
    else {
      this.rssItemIndex++;
    }
  }

  getRssContent(): Observable<any> {
    return this.http.get<any>(`${this.appBase}api/${this.systemId}/${this.locationId}/devices/customize/rss`);
  }
}
