import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { timeout } from 'rxjs/operators';
import { News } from 'projects/qcloud-models/grafik/news/news.model';

const DEFAULT_TIMEOUT = 3000;

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  appBase: string;

  constructor(private http: HttpClient, private authService: AuthService) { }

  public setAppBase(appBase: string){
    this.appBase = appBase;
  }

  addNews(news: News) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/newses`, news);
  }

  getSingleNews(newsId: number) : Observable<News>{
    return this.http.get<News>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/newses/${newsId}`)
  }

  getNews() : Observable<News[]>{
    return this.http.get<News[]>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/newses/`)
  }

  deleteNews(newsId: number) {
    return this.http.delete(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/newses/${newsId}`);
  }

  editNews(news: News){
    return this.http.put(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/newses/${news.id}`,
      news);
  }

}