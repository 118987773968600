export enum NoteQuestionType {
    Text = 0,
    PersonalId = 1,
    Email = 2,
    PhoneNumber = 3,
    FirstName = 4,
    LastName = 5,
    IpAddress = 6,
    VIN = 7,
    PersonalIdLastNumbers = 8,
    Option = 9,
    VisibleOption = 10,
    TextArea = 11,
    InvoiceNumber = 12,
    TextAreaAndCheckBox = 13
}