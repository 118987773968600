import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DisplayCategoryGroup } from 'projects/qcloud-models/category/display-category-group';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';

@Component({
  selector: 'app-add-edit-category-groups',
  templateUrl: './add-edit-category-groups.component.html',
  styleUrls: ['./add-edit-category-groups.component.css']
})
export class AddEditCategoryGroupsComponent implements OnInit {

  displayCategoryGroup: DisplayCategoryGroup;

  categoryId: number;
  isEditForm: boolean;

  constructor(private categoryService: CategoryService,
    private route: ActivatedRoute, private router: Router) {
    this.categoryId = this.route.snapshot.params['id'];
    this.isEditForm = this.categoryId != undefined;
  }

  ngOnInit(): void {
    this.getCategories();
  }

  getCategories() {
    if (this.isEditForm) {
      this.categoryService.getGroupDisplay(this.categoryId).subscribe((data) => {
        this.displayCategoryGroup = data;
      });
    }
    else {
      this.displayCategoryGroup = new DisplayCategoryGroup();
    }
  }


  onSubmit() {
    if (this.isEditForm) {
      this.categoryService.updateGroupDisplay(this.displayCategoryGroup.id, this.displayCategoryGroup).subscribe((data) => {
        this.router.navigateByUrl('/categories-display');
      });
    } else {
      this.categoryService.addGroupDisplay(this.displayCategoryGroup).subscribe((data) => {
        this.router.navigateByUrl('/categories-display');
      });
    }
  }

}
