import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { QcloudConsoleService } from '../../qcloud-console.service';

@Component({
  selector: 'lib-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css', './../base-button/base-button.component.css']
})
export class LogoutComponent implements OnInit {
  @ViewChild('icon') public icon: ElementRef;
  constructor(private consoleService: QcloudConsoleService) { }

  ngOnInit(): void {
  }

  public logout() {
    if (this.consoleService.user.mainRole.hasPermissionToServeETickets) {
      this.consoleService.logout();
    }
    else
    {
      this.consoleService.openLogoutModal();
    }
  }
}
