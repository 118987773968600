import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, NgForm, UntypedFormControl } from '@angular/forms';
import { Device } from 'projects/qcloud-models/device/device.model';
import { Category } from 'projects/qcloud-models/category/category.model';
import { Desk } from 'projects/qcloud-models/desk/desk';

@Component({
  selector: 'app-office-display',
  templateUrl: './office-display.component.html',
  styleUrls: ['./office-display.component.css'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class OfficeDisplayComponent implements OnInit {

  @Input() device: Device;
  @Input() categories: Category[];
  @Input() categoryMultiCtrl: UntypedFormControl;
  @Input() desks: Desk[];

  servedCategories: string = 'served-categories';
  noSelectedCategories = 'no-selected-categories';
  
  constructor() { }

  ngOnInit(): void {
  }

}
