<div class="card shadow mb-4">
    <div class="card-header py-3">
        <div class="row">
            <h5 class="col-8 m-0 font-weight-bold text-primary">{{ 'report-autogeneration-title' | translate }}</h5>
            <div class="text-right col-4">
                <button type="button" class="btn btn-primary btn-circle btn-sm" (click)="createNewTask()"
                    matTooltip="{{ 'add' | translate }}" tooltipClass="tooltip-primary">
                    <i class="fas fa-fw fa-plus"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="mb-3">
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>{{ 'report-template' | translate }}</th>
                            <th>{{ 'schedule' | translate }}</th>
                            <th>{{ 'file-type' | translate }}</th>
                            <th>{{ 'emails' | translate }}</th>
                            <th>{{ 'options' | translate }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let task of reportTasks; let i = index">
                            <td>{{ getTemplateNameById(task.reportTemplateId)}}</td>
                            <td>
                                {{ getTime(task.time) }}
                                <span *ngFor="let day of task.days" class="btn btn-blue btn-circle btn-sm mx-1 mb-1">
                                    {{ dayNames[day] | translate | slice:0:3 }}
                                </span>
                            </td>
                            <td>
                                <span>
                                    {{ getFileType(task.fileType) }}
                                </span>
                            </td>
                            <td>
                                <span>
                                    {{ task.emails }}
                                </span>
                            </td>
                            <td>
                                <button class="btn btn-info btn-circle btn-sm mr-1" (click)="editTask(task)"
                                    matTooltip="{{ 'edit' | translate }}" tooltipClass="tooltip-info">
                                    <i class="fas fa-fw fa-edit"></i>
                                </button>
                                <button class="btn btn-danger btn-circle btn-sm" (click)="deleteTask(task)"
                                    matTooltip="{{ 'delete' | translate }}" tooltipClass="tooltip-danger">
                                    <i class="fas fa-fw fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                </table>

                <div class="alert alert-warning" *ngIf="!isEmailConfigured" role="alert">
                    {{ 'email-not-configured' | translate }}
                </div>
            </div>
        </div>
    </div>
</div>



<!-- add/update schedule modal -->
<ng-template #modalContent let-close="close">
    <div class="modal-header">
        <h5 class="modal-title">{{ 'edit-report-task' | translate }}</h5>
        <button type="button" class="close" (click)="close()" mat-dialog-close>
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" mat-dialog-content>
        <div class="col-12">
            <mat-label>{{ 'hour' | translate }}</mat-label>
        </div>
        <div class="col-2">
            <div class="form-group text-left">
                <mat-form-field>
                    <input matInput type="time" [(ngModel)]="timeSelected">
                </mat-form-field>
            </div>
        </div>
        <div class="col-12">
            <div class="mb-3">
                {{ 'weekdays' | translate }}:
                <div class="btn-group">
                    <button class="btn btn-primary" (click)="selectAll()">
                        {{ 'all' | translate }}
                    </button>
                    <button class="btn btn-primary" (click)="selectWorkweek()">
                        {{ 'workweek' | translate }}
                    </button>
                    <button class="btn btn-primary" (click)="selectWeekend()">
                        {{ 'weekend' | translate }}
                    </button>
                    <button class="btn btn-primary" (click)="unselectAll()">
                        {{ 'deselect' | translate }}
                    </button>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="item-group">
                <mat-checkbox *ngFor="let day of dayNames; let i = index" [(ngModel)]="selectedDays[i]" color="primary"
                    class="mr-4">{{ day | translate }}
                </mat-checkbox>
            </div>
        </div>
        <div class="col-12 top">
            <mat-form-field>
                <mat-label>{{ 'report-template' | translate }}</mat-label>
                <mat-select matNativeControl #reportTemplateId="ngModel" [(ngModel)]="task.reportTemplateId" required>
                    <mat-option *ngFor="let template of reportTemplates" [value]="template.id">{{ template.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12">
            <mat-form-field>
                <mat-label>{{ 'last-days' | translate }}</mat-label>
                <mat-select matNativeControl name="lastDay" #lastDay="ngModel" [(ngModel)]="task.lastDay" required>
                    <mat-option [value]=1>Day</mat-option>
                    <mat-option [value]=7>Week</mat-option>
                    <mat-option [value]=30>Month</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12">
            <mat-form-field>
                <mat-label>{{ 'file-type' | translate }}</mat-label>
                <mat-select matNativeControl name="fileType" #fileType="ngModel" [(ngModel)]="task.fileType" required>
                    <mat-option [value]="FileType.Excel">Excel</mat-option>
                    <mat-option [value]="FileType.Pdf">PDF</mat-option>
                    <mat-option [value]="FileType.Csv">CSV</mat-option>
                    <mat-option [value]="FileType.Xml">XML</mat-option>
                    <mat-option [value]="FileType.Json">JSON</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12">
            <mat-label>{{ 'emails' | translate }}</mat-label>
            <input [(ngModel)]="task.emails" class="form-control">
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" (click)="onCloseModal()" mat-dialog-close>
            {{ 'save' | translate }}
        </button>
    </div>
</ng-template>