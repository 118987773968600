import { Component, OnInit, Input } from '@angular/core';
import { NgForm, ControlContainer } from '@angular/forms';
import { TicketLayoutType } from 'projects/qcloud-models/device/ticket-layout-type.enum';
import { LayoutType } from 'projects/qcloud-models/device/layout-type.enum';
import { Device } from 'projects/qcloud-models/device/device.model';
import { Category } from 'projects/qcloud-models/category/category.model';
import { TicketGenerationType } from 'projects/qcloud-models/device/ticket-generation-type.enum';
import { DisplayCategoryGroup } from 'projects/qcloud-models/category/display-category-group';

@Component({
  selector: 'app-ticket-dispenser',
  templateUrl: './ticket-dispenser.component.html',
  styleUrls: ['./ticket-dispenser.component.css'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class TicketDispenserComponent implements OnInit {

  @Input() device: Device;
  @Input() categories: Category[];
  @Input() advancedMode: boolean;
  @Input() displayGroups: DisplayCategoryGroup[];

  TicketLayoutType = TicketLayoutType;
  LayoutType = LayoutType;
  TicketGenerationType = TicketGenerationType;
  maxDepthCategoryTree: number = 100;

  constructor() { }

  ngOnInit(): void {
    if (!this.device.ticketLayoutType) {
      this.device.ticketLayoutType = TicketLayoutType.LAYOUT60MM;
    }

    if (!this.device.layoutType) {
      this.device.layoutType = LayoutType.Vertical;
    }

    if (!this.device.ticketGenerationType) {
      this.device.ticketGenerationType = TicketGenerationType.Print;
    }
  }

  getCategoryTree(categoryTree: any) {
    this.device.deviceCategoryTree = categoryTree;
  }
}
