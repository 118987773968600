<div class="modal-header">
    <h5 class="modal-title">{{ 'select-location' | translate }}</h5>
    <button class="close" data-dismiss="modal" aria-label="Close" mat-dialog-close>
        <span aria-hidden="true">×</span>
    </button>
</div>
<div class="modal-body" mat-dialog-content>
    <div class="form-group">
        <select [(ngModel)]="choosenLocation" class="form-control" >
            <option *ngFor="let location of locations" [ngValue]="location" [disabled]="!isLocationEnable(location.id)"
                [ngClass]="{'disabled-option': false == isLocationEnable(location.id)}">
                {{ location.locationName }}
            </option>
        </select>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-danger" data-dismiss="modal" mat-dialog-close>{{ 'back' | translate }}</button>
    <button class="btn btn-primary" data-dismiss="modal" (click)="changeLocation()">
        {{ 'change-location' | translate }}
    </button>
</div>