import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LocationData } from 'projects/qcloud-models/location/location.model';
import { Login } from 'projects/qcloud-models/user/login.model';
import { TokenResponse } from 'projects/qcloud-models/user/token-response';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { QCloudRestClientService } from 'projects/qcloud-rest-client/src/public-api';
import { ModulePage } from './module-page.enum';

@Component({
  selector: 'app-select-location-step',
  templateUrl: './select-location-step.component.html',
  styleUrls: ['./select-location-step.component.css']
})
export class SelectLocationStepComponent implements OnInit {

  locations: LocationData[];
  choosenLocation: LocationData;
  enabledByLocationIds: number[] = [];

  constructor(private service: LocationService, public authService: AuthService,
    public qcloudRestClientService: QCloudRestClientService, private toastr: ToastrService,
    private translate: TranslateService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.getAllLocations();
    this.enabledByLocationIds.push(...this.authService.getTokenLocationIds());
    this.qcloudRestClientService.loginForm = new Login();
  }

  getAllLocations() {
    this.service.getAll().subscribe(
      locations => {
        var mainRoleLocationIds = this.authService.getTokenLocationIds();
        var filteredLocations: LocationData[] = [];
        for (let j = 0; j < mainRoleLocationIds.length; j++) {
          for (let i = 0; i < locations.length; i++) {
            if (mainRoleLocationIds[j] == locations[i].id) {
                filteredLocations.push(locations[i]);
            }
          }
        }
        this.locations = filteredLocations;
        var loggedUserLocationId = this.authService.getLocationId();
        this.choosenLocation = this.locations.find(x => x.id == loggedUserLocationId);
        if(this.locations.length === 1) {
          this.changeLocation();
        }
      }
    );
  }

  changeLocation() {
    this.qcloudRestClientService.loginForm.username = this.authService.getTokenUsername();
    this.qcloudRestClientService.changeLocation(this.choosenLocation.id, this.callback, this.onError);
    this.qcloudRestClientService.loginForm = new Login();
  }

  private callback = (data: TokenResponse) => {
    this.authService.setLocationId(this.choosenLocation.id);
    this.service.getLocationData(this.choosenLocation.id).subscribe(
      locationData => {
        this.service.locationData = locationData;

        this.route.params.subscribe(params => {
          var modulePage = +params['modulePage'];
          var path = this.getNavigationPath(modulePage);
          this.router.navigate([path]);
        });
      }
    );
  }

  private onError = () => {
    this.translate.get('change-location-error').subscribe((error: string) => {
      this.translate.get('repeat').subscribe((res: string) => {
        this.toastr.error(res, error);
      });
    });
  }
  getNavigationPath(modulePage: number): string {
    switch (modulePage) {
      case ModulePage.WEB_CONSOLE:
        return 'qcloud/web-console';
      case ModulePage.RESERVATIONS:
        return 'qcloud/reservations';
      case ModulePage.DASHBOARD:
        return '';
      case ModulePage.WORK_SCHEDULE:
        return 'qcloud/workSchedule';
      default:
        return '';
    }
  }

  isLocationEnable(locationId: number) {
    var res = this.enabledByLocationIds.some(l => l === locationId);
    return res;
  }
}
