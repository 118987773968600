<div id="wrapper">
    <ul class="navbar-nav sidebar mw"></ul>
    <div id="content-wrapper" class="d-flex flex-column navbar-padding">
        <div id="content">
            <app-navbar [hidden]="isHideElement()"></app-navbar>
            <div [ngClass]="getNavbarMarginCss()">
                <router-outlet></router-outlet>
            </div>
        </div>
        <app-footer [hidden]="isHideElement()"></app-footer>
    </div>
</div>
