import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { TicketListenerService } from 'projects/qcloud-device/src/app/services/ticket.service';
import { UtilitiesService } from 'projects/qcloud-device/src/app/services/utilities.service';
import { Ticket } from 'projects/qcloud-models/console/ticket';
import { TicketDisplayRow } from 'projects/qcloud-models/device/ticket-display-row';
import { Queue } from 'projects/qcloud-models/queue';
import { BaseComponent } from '../../../../shared/base-component/base.component';
import { TicketState } from 'projects/qcloud-models/console/ticketState.enum';

declare var window;

@Component({
  selector: 'app-basic-last-tickets-list',
  templateUrl: './basic-last-tickets-list.component.html',
  styleUrls: ['./basic-last-tickets-list.component.css']
})
export class BasicLastTicketsListComponent extends BaseComponent implements OnInit {
  MAX_TICKETS = 8;
  animation: any;
  rowHeight: number = 0;
  rowPadding: number = 0;
  rows: Queue<TicketDisplayRow>;

  constructor(protected elementRef: ElementRef, protected renderer: Renderer2) {
    super(elementRef, renderer);

  }

  startComponent(): void {
    this.rows = new Queue<TicketDisplayRow>(this.MAX_TICKETS);
    this.ticketListenerService.ticket.subscribe((val) => {
      this.ticketCalled(val);
    })
    this.setGridRows(this.MAX_TICKETS);
  }

  private setGridRows(number: number) {
    var orientation = window.orientation % 90 === 0 ? 'portrait' : 'landscape'
    console.log(number);
    if (orientation == 'landscape') {
      if (number < 3) number = 3;
      this.rowPadding = 0.05;
    }
    else {
      if (number < 6) number = 6;
      this.rowPadding = 0.1;
    }
    this.rowHeight = 100 / number;
  }

  getRowHeightStyle(i) {
    if (i == this.rows.getAll().length - 1) {
      return { height: this.rowHeight + '%' }
    }
    return { height: this.rowHeight + '%', "padding-bottom": this.rowPadding + '%' }
  }

  ngOnInit() {

  }

  private ticketCalled(ticket: Ticket) {
    if (ticket.ticketState != TicketState.Called)
      return;
    if (this.rows.size() > this.MAX_TICKETS - 1) {
      this.rows.dequeue();
    }
    let row = new TicketDisplayRow()
    row.texts[0] = this.utilitiesService.getCategoryName(ticket.effectiveCategoryId);
    row.texts[1] = ticket.caption;
    row.texts[2] = this.utilitiesService.getDeskRoomNumber(ticket.deskId);
    row.texts[3] = this.utilitiesService.getDeskNumber(ticket.deskId);
    row.active = false;
    row.visible = true;
    this.rows.enqueue(row);
    this.clearAnimation();
    this.animate(row);
  }

  private clearAnimation() {
    if (this.animation != undefined) {
      window.clearInterval(this.animation);
    }
    this.rows.getAll().forEach(element => {
      element.active = false;
    });
  }

  private animate(row: TicketDisplayRow) {
    let x = 0;
    let context = this;
    this.animation = window.setInterval(function () {
      row.active = !row.active;
      if (++x === 6 && context.animation != undefined) {
        window.clearInterval(context.animation);
      }
    }, 1000);
  }

  initMock(): void {
    this.rows = new Queue<TicketDisplayRow>(this.MAX_TICKETS);
    this.setGridRows(this.MAX_TICKETS);
    for (let i = 0; i < this.MAX_TICKETS; i++) {
      let row = new TicketDisplayRow()
      row.texts[0] = "Example";
      row.texts[1] = "A10" + i;
      row.texts[2] = 1;
      row.texts[3] = 1;
      row.active = false;
      row.visible = true;
      this.rows.enqueue(row);
    }
    console.log(this.rows);
  }
}
