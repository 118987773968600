import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { TicketNoteTemplateVm } from '../../../../qcloud-models/console/TicketNoteTemplateVm';
import { NoteQuestionType } from '../../../../qcloud-models/note/note-question-type.enum';
import { NoteQuestion } from '../../../../qcloud-models/note/note-questions';
const DEFAULT_TIMEOUT = 3000;
@Injectable({
  providedIn: 'root'
})
export class TicketNoteConfigurationService {

  appBase: string;
  endpoint: string = "noteConfigurations";

  constructor(private http: HttpClient, private authService: AuthService) { }

  public setAppBase(appBase: string) {
    this.appBase = appBase;
  }


  public addTicketNoteTemplate(ticketNoteTemplateVm: TicketNoteTemplateVm, callback: (data: TicketNoteTemplateVm) => any, onError: (error) => any) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.authService.getConsoleToken()}`);
    this.http.post(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/add`,
      ticketNoteTemplateVm, { headers: headers })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }
  public updateTicketNoteTemplate(ticketNoteTemplateVm: TicketNoteTemplateVm, callback: (data: TicketNoteTemplateVm) => any, onError: (error) => any) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.authService.getConsoleToken()}`);
    this.http.put(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/update`,
      ticketNoteTemplateVm, { headers: headers })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  public getNoteTicketTemplates(callback: (data: TicketNoteTemplateVm[]) => any, onError: (error) => any) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.authService.getConsoleToken()}`);
    return this.http.get(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}`,
      { headers: headers })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }
  getNoteTicketTemplate(noteTicketTemplateId: number, callback: (data: TicketNoteTemplateVm) => any, onError: (error) => any) {
    return this.http.get(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/${noteTicketTemplateId}`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  deleteNoteTicketTemplate(noteTicketTemplateId: number, callback: (data: boolean) => any, onError: (error) => any) {
    return this.http.delete(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/${noteTicketTemplateId}`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }
  getQuestionsByType(noteQuestionType: NoteQuestionType, callback: (data: number[]) => any, onError: (error) => any) {
    return this.http.get(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/questionIds/${noteQuestionType}`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }
  getQuestionsByIds(questionIds: number[], callback: (data: NoteQuestion[]) => any, onError: (error) => any) {
    let headers = new HttpHeaders();
    headers = headers.set('Ids', questionIds.join(', '));

    return this.http.get(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/${this.endpoint}/questions`,
      { headers: headers })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }
}