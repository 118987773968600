import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { Category } from 'projects/qcloud-models/category/category.model';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css']
})
export class MaintenanceComponent implements OnInit {

  categories: Category[];

  constructor(private categoryService: CategoryService) { }

  ngOnInit(): void {
    this.categoryService.getCategories((data)=>{
      this.categories = data;
    },
    ()=>{ });
  }
}
