import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-device-state-column',
  templateUrl: './device-state-column.component.html',
  styleUrls: ['./device-state-column.component.css']
})
export class DeviceStateColumnComponent implements OnInit, OnChanges {

  @Input() deviceState: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.deviceState = changes.deviceState.currentValue;
  }

}
