import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Category } from 'projects/qcloud-models/category/category.model';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { ReportScope } from 'projects/qcloud-models/report/report-scope.enum';
import { QCloudElement, ReportTemplateParameter } from 'projects/qcloud-models/report/report-template-parameter';
import { User } from 'projects/qcloud-models/user/user.model';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { LocationData } from 'projects/qcloud-models/location/location.model';

@Component({
  selector: 'app-report-template-parameter',
  templateUrl: './report-template-parameter.component.html',
  styleUrls: ['./report-template-parameter.component.css']
})
export class ReportTemplateParameterComponent implements OnInit {

  ReportScope = ReportScope;
  @Input() reportTemplateParameterElementsOriginal: ReportTemplateParameter[];
  private _reportTemplateParameter: ReportTemplateParameter;
  @Input() set reportTemplateParameter(value: ReportTemplateParameter) {
    this._reportTemplateParameter = value;
  }

  @Input() set updateView(value: boolean) {
    this.updateTemplateParameter();
  }
  get reportTemplateParameter(): ReportTemplateParameter {
    return this._reportTemplateParameter;
  }

  get categories(): Category[] {
    return this._categories;
  }
  @Input() set categories(value: Category[]) {
    this._categories = value;
  }
  _categories: Category[] = [];
  get desks(): Desk[] {
    return this._desks;
  }
  @Input() set desks(value: Desk[]) {
    this._desks = value;
  }
  _desks: Desk[] = [];

  get users(): User[] {
    return this._users;
  }
  @Input() set users(value: User[]) {
    this._users = value;
  }
  _users: User[] = [];

  categoryMultiCtrl: UntypedFormControl;
  servedCategories = 'served-categories';
  noSelectedCategories = 'no-selected-categories';
  deskMultiCtrl: UntypedFormControl;
  servedDesks = 'desks';
  noSelectedDesks = 'no-selected-desks';
  userMultiCtrl: UntypedFormControl;
  servedUsers = 'users';
  noSelectedUsers = 'no-selected-users';

  constructor(public locationService: LocationService) { }

  ngOnInit(): void {
    this.categoryMultiCtrl = new UntypedFormControl([]);
    this.deskMultiCtrl = new UntypedFormControl([]);
    this.userMultiCtrl = new UntypedFormControl([]);
    this.updateTemplateParameter();
  }
  updateTemplateParameter() {
    if (this.reportTemplateParameterElementsOriginal === undefined) {
      return;
    }
    if (this.reportTemplateParameter.reportScope == ReportScope.Categories) {
      let categoriesOriginal = this.reportTemplateParameterElementsOriginal.find(x => x.reportScope == ReportScope.Categories).elements;
      let initialCategories = this.categories.filter(x => categoriesOriginal.find(y => y.id == x.id));
      this.categoryMultiCtrl = new UntypedFormControl(initialCategories);
      this.selectCategories(initialCategories);
    }
    if (this.reportTemplateParameter.reportScope == ReportScope.Desks) {
      let desksOriginal = this.reportTemplateParameterElementsOriginal.find(x => x.reportScope == ReportScope.Desks).elements;
      let initialDesks = this.desks.filter(x => desksOriginal.find(y => y.id == x.id));
      this.deskMultiCtrl = new UntypedFormControl(initialDesks);
      this.selectDesks(initialDesks);
    }
    if (this.reportTemplateParameter.reportScope == ReportScope.Users) {
      let usersOriginal = this.reportTemplateParameterElementsOriginal.find(x => x.reportScope == ReportScope.Users).elements;
      let initialUsers = this.users.filter(x => usersOriginal.find(y => y.id == x.id));
      this.userMultiCtrl = new UntypedFormControl(initialUsers);
      this.selectUsers(initialUsers);
    }
  }

  selectCategories(categories: Category[]) {
    this.reportTemplateParameter.elements = categories.map(category => new QCloudElement(category.id, category.fullName));
  }

  selectDesks(desks: Desk[]) {
    this.reportTemplateParameter.elements = desks.map(desk => new QCloudElement(desk.id, desk.fullName));
  }

  selectUsers(users: User[]) {
    this.reportTemplateParameter.elements = users.map(user => new QCloudElement(user.id, user.firstName + " " + user.lastName));
  }
}
