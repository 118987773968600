<div class="form-group">
    <div class="font-weight-bold">{{ title | translate}}</div>
    <div class="input-group">
        <mat-form-field color="primary">
            <mat-select [formControl]="elementMultiCtrl" [multiple]="false"
                (selectionChange)="emitElements($event.value)">
                <mat-option>
                    <ngx-mat-select-search [formControl]="elementMultiFilterCtrl"
                        placeholderLabel="{{ placeholder | translate }}"
                        noEntriesFoundLabel="{{ nomatch | translate }}">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let element of filteredElementsMulti | async" [value]="element">
                    <b>{{ element.fullName }}</b>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>