export enum ReservationQuestionType {
    Text = 0,
    PersonalId,
    Email,
    PhoneNumber,
    FirstName,
    LastName,
    IpAddress,
    VIN,
    PersonalIdLastNumbers,
    Option,
    VisibleOption
}