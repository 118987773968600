import { Injectable } from '@angular/core';
import { WeekDay } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class WeekService {

  constructor() { }

  public unselectAll() {
    return [];
}

  public selectAll() {
    return [WeekDay.Sunday, WeekDay.Monday, WeekDay.Tuesday, WeekDay.Wednesday,
      WeekDay.Thursday, WeekDay.Friday, WeekDay.Saturday];
  }

  public selectWeekend() {
    return [WeekDay.Sunday, WeekDay.Saturday];
  }

  public selectWorkweek() {
    return [WeekDay.Monday, WeekDay.Tuesday, WeekDay.Wednesday, WeekDay.Thursday, WeekDay.Friday];
  }
}
