import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CalendarEvent, CalendarMonthViewBeforeRenderEvent, CalendarView } from 'angular-calendar';
import { ToastrService } from 'ngx-toastr';
import { Category } from 'projects/qcloud-models/category/category.model';
import { GetAvailableTimesParameter } from 'projects/qcloud-models/reservation/get-available-times-parameter';
import { ReservationMonthCapacity } from 'projects/qcloud-models/reservation/reservation-month-capacity';
import { ReservationQuestionAnswer } from 'projects/qcloud-models/reservation/reservation-question-answer';
import { ReservationQuestion } from 'projects/qcloud-models/reservation/reservation-questions';
import { TicketReservation } from 'projects/qcloud-models/reservation/ticket-reservation';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { ReservationService } from 'projects/qcloud-rest-client/src/lib/reservation.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-reservation-modal',
  templateUrl: './reservation-modal.component.html',
  styleUrls: ['./reservation-modal.component.css']
})
export class ReservationModalComponent implements OnInit {
  modalChooseTimeData: { timeChoosen: TicketReservation, availableTimes: TicketReservation[] }
  choosenDate: Date;
  view: CalendarView = CalendarView.Month;
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  refresh: Subject<any> = new Subject();
  reservationMonthCapacity: ReservationMonthCapacity;
  ticketReservations: TicketReservation[];
  viewDate: Date;
  choosenCategory: Category;
  locale: string;
  questions: ReservationQuestion[];
  answers: ReservationQuestionAnswer[];
  ticketReservation: TicketReservation;
  isCalendarDisplayed: boolean;
  isEmailSendingEnabled: boolean;
  sendMailOnReservation: boolean;
  isSmsSendingEnabled: boolean;
  sendSmsOnReservation: boolean;
  formSubmitted: boolean;
  dialogRef : MatDialogRef<any>;

  callback: () => any;

  constructor(private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private reservationService: ReservationService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    public locationService: LocationService,
    private dialog: MatDialog) {
    this.locale = this.translate.currentLang;
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.locale = event.lang;
      this.cdr.detectChanges();
    });
    this.reservationService.getReservationQuestionaire(false, (data) => {
      this.questions = data;
    }, () => {

    });
    this.reservationService.getReservationConfiguration(
      (data) => {
        this.isEmailSendingEnabled = data.sendReservationEmail;
        this.isSmsSendingEnabled = data.sendReservationSms;
      },
      () => { }
    );
  }

  ngOnInit(): void {
    this.modalChooseTimeData = {
      timeChoosen: null,
      availableTimes: new Array(0)
    }
  }

  public open(category: Category, event: CalendarEvent<TicketReservation>, callback: () => any) {
    this.callback = callback;
    this.choosenCategory = category;
    this.formSubmitted = false;
    this.ticketReservation = event.meta;
    this.choosenDate = new Date(this.ticketReservation.reservationTime);
    let date = this.datePipe.transform(event.start, "yyyy-MM-dd");
    this.choosenDate = this.choosenDate;
    if (this.ticketReservation.id == 0) {
      this.answers = this.questions.map(question =>
        new ReservationQuestionAnswer(question, this.translate.currentLang));
    }
    else {
      this.answers = this.ticketReservation.additionalReservationData;
    }
    var isOnHourReservationType = false;
    this.reservationService.getReservationListForNextDays(category.id, date, 1, isOnHourReservationType, (data) => {
      let timeChoosen = data.filter(x => x.reservationTime === this.ticketReservation.reservationTime);
      this.modalChooseTimeData = {
        timeChoosen: timeChoosen[0],
        availableTimes: data.filter(x => x.id == 0 || x.id == timeChoosen[0].id)
      }
    }, () => { });
    this.refreshCapacity();
    var matDialogConfig = new MatDialogConfig();
    matDialogConfig.maxHeight = 1200;
    matDialogConfig.maxWidth = 800;
    matDialogConfig.minHeight = 400;
    matDialogConfig.minWidth = 700;
    this.dialogRef = this.dialog.open(this.modalContent, matDialogConfig);
  }

  addMonth(direction: number) {
    let newDate = new Date(this.choosenDate);
    newDate.setMonth(this.choosenDate.getMonth() + direction);
    this.choosenDate = newDate;
    this.refreshCapacity();
  }

  onDeleteConfirm(confirm: boolean) {
    if (confirm) {
      this.reservationService.deleteReservation(this.ticketReservation.id, () => {
        this.callback();
        this.translate.get('reservation-delete-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      }, () => {
        this.translate.get('reservation-delete-error').subscribe((res: string) => {
          this.callback();
          let error = res;
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      });
    }
  }

  onCloseModal() {
    this.formSubmitted = true;
    let reservation = this.ticketReservation;
    reservation.sendMailOnReservation = this.sendMailOnReservation;
    reservation.sendSmsOnReservation = this.sendSmsOnReservation;
    if (reservation.additionalReservationData == null) {
      reservation.additionalReservationData = this.answers;
    }
    reservation.reservationTime = new Date(this.modalChooseTimeData.timeChoosen.reservationTime + "Z");
    reservation.categoryId = this.choosenCategory.id;
    if (reservation.id == 0) {
      this.reservationService.createReservation(reservation).subscribe(
        res => {
          this.formSubmitted = false;
          this.callback();
        },
        err => {
          this.formSubmitted = false;
          this.callback();
          this.translate.get('reservation-error').subscribe(
            res => this.toastr.error(res));
        }
      );
    }
    else {
      this.reservationService.updateReservation(reservation).subscribe(
        res => {
          this.formSubmitted = false;
          this.callback();
        },
        err => {
          this.formSubmitted = false;
          this.callback();
          this.translate.get('reservation-error').subscribe(
            res => this.toastr.error(res));
        }
      );
    }
  }

  getListForNextDays(dayCount: number) {
    if (this.choosenCategory == null) {
      return;
    }
    let date = this.datePipe.transform(this.choosenDate, "yyyy-MM-dd");
    var isOnHourReservationType = false;
    this.reservationService.getReservationListForNextDays(this.choosenCategory.id, date, dayCount, isOnHourReservationType, (data) => {
      this.ticketReservations = data;
      this.refresh.next(true);
    }, () => { });
  }

  getListForModal(event: any) {
    this.choosenDate = new Date(event);
    this.refresh.next(true);
    let date = this.datePipe.transform(event, "yyyy-MM-dd");
    var isOnHourReservationType = false;
    this.reservationService.getReservationListForNextDays(this.choosenCategory.id, date, 1, isOnHourReservationType, (data) => {
      let timeChoosen = data.filter(x => x.reservationTime === this.ticketReservation.reservationTime);
      this.modalChooseTimeData = {
        timeChoosen: timeChoosen[0],
        availableTimes: data.filter(x => x.id == 0)
      }
    }, () => { });
  }

  refreshCapacity() {
    this.reservationMonthCapacity = new ReservationMonthCapacity();
    this.reservationMonthCapacity.capacity = new Array();
    let availableTimesParameter = new GetAvailableTimesParameter(this.choosenCategory.id, this.choosenDate);
    this.reservationService.getMonthCapacityForCategory(availableTimesParameter, (data) => {
      this.reservationMonthCapacity = data;
      this.refresh.next(true);
    }, () => { });
  }

  beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
    let ctx = this;
    renderEvent.body.forEach((day) => {
      if (ctx.reservationMonthCapacity && day.inMonth) {
        const dayOfMonth = day.date.getDate();
        if (this.reservationMonthCapacity.capacity[dayOfMonth - 1] == undefined) {
          return;
        }
        switch (this.reservationMonthCapacity.capacity[dayOfMonth - 1]) {
          case 0: {
            day.cssClass = 'bg-red';
            break;
          }
          case 1: {
            day.cssClass = 'bg-orange';
            break;
          }
          case 2: {
            day.cssClass = 'bg-yellow';
            break;
          }
          case 3: {
            day.cssClass = 'bg-green';
            break;
          }
          case -1: {
            day.cssClass = "bg-gray";
          }
        }
        if (this.choosenDate.getDate() == dayOfMonth) {
          day.cssClass = "bg-blue";
        }
      }
    });
  }
}
