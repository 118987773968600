import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { NgForm, ControlContainer, UntypedFormControl } from '@angular/forms';
import { LayoutType } from 'projects/qcloud-models/device/layout-type.enum';
import { SayingTicketsTypes } from 'projects/qcloud-models/device/saying-tickets-types.enum';
import { Device } from 'projects/qcloud-models/device/device.model';
import { Category } from 'projects/qcloud-models/category/category.model';
import { ThemePalette } from '@angular/material/core';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { ReplaySubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { DeviceDeskIcon } from 'projects/qcloud-models/device/device-desk-icon.model';

@Component({
  selector: 'app-calling-display',
  templateUrl: './calling-display.component.html',
  styleUrls: ['./calling-display.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class CallingDisplayComponent implements OnInit {
  @Input() device: Device;
  @Input() desks: Desk[];

  protected initialDesks: Desk[];

  public deskMultiCtrl: UntypedFormControl;
  public deskMultiFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredDesksMulti: ReplaySubject<Desk[]> = new ReplaySubject<Desk[]>(1);

  protected _onDestroy = new Subject<void>();

  color: ThemePalette = 'primary';

  LayoutType = LayoutType;
  maxDepthCategoryTree: number = 1;

  constructor() { }

  ngOnInit(): void {
    if (!this.device.layoutType) {
      this.device.layoutType = LayoutType.Basic;
    }

    this.initialDesks = new Array<Desk>();
    for (let i = 0; i < this.desks.length; i++) {
      for (let j = 0; j < this.device.availableDesks.length; j++) {
        if (this.desks[i].id == this.device.availableDesks[j].id) {
          this.initialDesks.push(this.desks[i]);
        }
      }
    }

    this.deskMultiCtrl = new UntypedFormControl(this.initialDesks);

    // load the initial deskCategory list
    this.filteredDesksMulti.next(this.desks.slice());

    // listen for search field value changes
    this.deskMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterdeskCategoriesMulti();
      });
  }

  setDesks(desks: Desk[]) {
    this.device.availableDesks = desks;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredDesksMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.deskMultiCtrl.patchValue(val);
          if (this.device.layoutType = LayoutType.MultideskDesk) {
            this.device.availableDesks = this.deskMultiCtrl.value;
          }
        } else {
          this.deskMultiCtrl.patchValue([]);
          if (this.device.layoutType = LayoutType.MultideskDesk) {
            this.device.availableDesks = this.deskMultiCtrl.value;
          }
        }
      });
  }

  protected filterdeskCategoriesMulti() {
    if (!this.desks) {
      return;
    }
    // get the search keyword
    let search = this.deskMultiFilterCtrl.value;
    if (!search) {
      this.filteredDesksMulti.next(this.desks.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the deskCategories
    this.filteredDesksMulti.next(
      this.desks.filter(deskCategory => deskCategory.name.toLowerCase().indexOf(search) > -1)
    );
  }

}
function takeUntil(_onDestroy: any): any {
  throw new Error('Function not implemented.');
}

