import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { TicketNoteTemplateVm } from "../../../../../qcloud-models/console/TicketNoteTemplateVm";
import { TicketNoteConfigurationService } from "../../../../../qcloud-rest-client/src/lib/note/ticket-note-configuration.service";

@Component({
  selector: 'app-note-template-configurations',
  templateUrl: './note-template-configurations.component.html',
  styleUrls: ['./note-template-configurations.component.css']
})
export class NoteTemplateConfigurationsComponent implements OnInit {
  ticketNoteTemplateVm: TicketNoteTemplateVm[] = [];
  selectedNoteTemplateId: number;

  constructor(private translate: TranslateService,
    private toastr: ToastrService,
    private ticketNoteConfigurationService: TicketNoteConfigurationService) { }

  ngOnInit(): void {
    this.getTicketNoteTemplateVm();
  }

  onDeleteConfirm(confirmed: boolean) {
    if (confirmed) {
      this.deleteNoteTemplate(this.selectedNoteTemplateId);
    }
  }
  deleteNoteTemplate(noteTemplateId: number) {
    this.ticketNoteConfigurationService.deleteNoteTicketTemplate(noteTemplateId,
      () => {
        this.getTicketNoteTemplateVm();
      },
      () => {
        this.translate.get('delete-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  getTicketNoteTemplateVm() {
    this.ticketNoteConfigurationService.getNoteTicketTemplates(
      (result => {
        this.ticketNoteTemplateVm = result;
      }),
      () => { });
  }
}