import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StatsQueueState } from 'projects/qcloud-models/device/device-queue-state';
import { TicketsStatistics } from 'projects/qcloud-models/statistics/ticket-statistics';
import { Observable } from 'rxjs';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  appBase: string;

  constructor(private http: HttpClient, private authService: AuthService) { }

  public setAppBase(appBase: string) {
    this.appBase = appBase;
  }

  getTodayStats(): Observable<TicketsStatistics> {
    return this.http.get<any>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/statistics/today`);
  }

  getDeviceStats(): Observable<StatsQueueState> {
    return this.http.get<StatsQueueState>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/statistics/device`);
  }

  getTodayReservationStats(): Observable<any> {
    return this.http.get<any>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/statistics/reservation`);
  }
}