export class AmmsMappedReservation {
    public date: Date;
    public resourceName: string;
    public locationName: string;
    public pesel: string;
    public clientName: string;

    public constructor(init?:Partial<AmmsMappedReservation>) {
        Object.assign(this, init);
    }
}