import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { DisplaySystemCategory } from 'projects/qcloud-models/category/display-system-category';
import { ToastrService } from 'ngx-toastr';
import { Category } from 'projects/qcloud-models/category/category.model';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemCategoryService } from 'projects/qcloud-rest-client/src/lib/category/system-category.service';
import { SystemCategory } from 'projects/qcloud-models/category/system-category.model';

@Component({
  selector: 'app-add-edit-categories-display',
  templateUrl: './add-edit-categories-display.component.html',
  styleUrls: ['./add-edit-categories-display.component.css']
})
export class AddEditCategoriesDisplayComponent implements OnInit {

  displaySystemCategory: DisplaySystemCategory;

  categoryId: number;
  isEditForm: boolean;
  categories: SystemCategory[];

  constructor(private categoryService: CategoryService,
    private systemCategoryService: SystemCategoryService,
    private route: ActivatedRoute, private router: Router) {
    this.categoryId = this.route.snapshot.params['id'];
    this.isEditForm = this.categoryId != undefined;
  }

  ngOnInit(): void {
    this.getCategories();
  }

  getCategories() {
    if (this.isEditForm) {
      this.categoryService.getSystemCategoryDisplay(this.categoryId).subscribe((data) => {
        this.displaySystemCategory = data;
      });
    }
    else {
      this.displaySystemCategory = new DisplaySystemCategory();
    }
    this.systemCategoryService.getCategories((data) => {
      this.categories = data;
    }, () => {
      this.categories = [];
    });
  }


  onSubmit() {
    if (this.isEditForm) {
      this.categoryService.updateSystemCategoryDisplay(this.displaySystemCategory.id, this.displaySystemCategory).subscribe((data) => {
        this.router.navigateByUrl('/categories-display');
      });
    } else {
      this.categoryService.addSystemCategoryDisplay(this.displaySystemCategory).subscribe((data) => {
        this.router.navigateByUrl('/categories-display');
      });
    }
  }
}

