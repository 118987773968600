import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Ticket } from 'projects/qcloud-models/console/ticket';
import { QcloudConsoleService } from '../../qcloud-console.service';

@Component({
  selector: 'lib-paused-tickets-modal',
  templateUrl: './paused-tickets-modal.component.html',
  styleUrls: ['./paused-tickets-modal.component.css']
})
export class PausedTicketsModalComponent implements OnInit {
  @Output() hideInteractionModel = new EventEmitter<boolean>();
  pausedTickets: Ticket[];

  constructor(private consoleService: QcloudConsoleService) {
    this.consoleService.pausedTickets.subscribe((val) => this.pausedTickets = val);
  }

  ngOnInit(): void {
  }

  jsonToNote(ticket: Ticket){
    return JSON.parse(ticket.metadataJson).Note;
  }

  jsonToNoteShort(ticket: Ticket){
    let text="";
    try{
      text = JSON.parse(ticket.metadataJson).Note;
      if(text.length > 20){
      text = text.substring(0, 20).concat('...');
      }
    }catch{}
      return text
  }


  callPausedTicket(ticketId: number) {
    this.consoleService.callManualTicket(ticketId);
    this.hideInteractionModel.emit(true);
  }

}
