import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ReservationLimit } from 'projects/qcloud-models/reservation/reservation-limit';
import { ReservationQuestion } from 'projects/qcloud-models/reservation/reservation-questions';
import { ReservationService } from 'projects/qcloud-rest-client/src/lib/reservation.service';

@Component({
  selector: 'app-reservation-limits',
  templateUrl: './reservation-limits.component.html',
  styleUrls: ['./reservation-limits.component.css']
})
export class ReservationLimitsComponent implements OnInit {

  @Input() reservationLimits: ReservationLimit[];
  @Input() questions: ReservationQuestion[];
  formSubmitted = false;

  constructor(private reservationService: ReservationService, private toastr: ToastrService,
    private translate: TranslateService) { }

  ngOnInit(): void {
  }

  getQuestionName(questionId: number): string {
    return this.questions.find(x => x.id == questionId).question;
  }

  saveLimits() {
    this.formSubmitted = true;
    this.reservationService.updateReservationLimits(this.reservationLimits).subscribe(
      (res) => {
        this.formSubmitted = false;
        this.translate.get('reservation-limits-save-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      (err) => {
        this.formSubmitted = false;
        this.translate.get('reservation-limits-save-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

}
