import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { DeviceGroupTranslation } from 'projects/qcloud-models/translation/device-group-translation.model';
import { Translation } from 'projects/qcloud-models/translation/translation.model';
import { Language } from 'projects/qcloud-models/translation/language.model';
import { TranslationService } from 'projects/qcloud-rest-client/src/lib/translation.service';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DeviceGroupNames } from 'projects/qcloud-models/translation/device-group-names.model';
import { GroupTranslation } from 'projects/qcloud-models/translation/group-translation.model';
import { Device } from 'projects/qcloud-models/device/device.model';
import { DeviceService } from 'projects/qcloud-rest-client/src/lib/device.service';
import { TranslationConfigurationService } from 'projects/qcloud-web/src/app/shared/translation-configuration.service';

@Component({
  selector: 'app-device-group-translation',
  templateUrl: './device-group-translation.component.html',
  styleUrls: ['./device-group-translation.component.css',
    '../languages-and-translations.component.css']
})
export class DeviceGroupTranslationComponent implements OnInit {

  deviceGroupTranslations: Array<DeviceGroupTranslation[]>;
  @Input() translations: Translation[];
  @Input() languages: Language[];
  @Input() locationLocaleMain: string;
  startIndex: number;
  deviceGroupNames: DeviceGroupNames[] = [];
  devices: Device[] = [];
  isCollapsed: boolean = false;
  formSubmitted: boolean = false;

  constructor(
    private service: TranslationService, 
    private authService: AuthService,
    private translate: TranslateService, 
    private toastr: ToastrService, 
    private deviceService: DeviceService,
    private translationConfigurationService: TranslationConfigurationService
    ) { }

  ngOnInit(): void {
    this.deviceGroupTranslations = new Array<DeviceGroupTranslation[]>(this.translations.length);
    this.translationConfigurationService.translationLanguages.subscribe(translations => {
      this.translations = translations;
      this.getDeviceGroupTranslations();
    });
    this.getDeviceGroupNamesToTranslate();
  }

  getDeviceGroupNamesToTranslate() {
    this.service.getDeviceGroupNamesToTranslate().subscribe((data) => {
      this.deviceGroupNames = data;
      this.getDeviceNames();
      this.getDeviceGroupTranslations();
    },
      () => { }
    );
  }

  getDeviceGroupTranslations() {
    this.service.getDeviceGroupsTranslation().subscribe(data => {
      for (let i = 0; i < this.translations.length; i++) {
        let oneLanguageTranslations = data.filter((deviceGroupTranslation) =>
          deviceGroupTranslation.translationId == this.translations[i].id);
        this.deviceGroupTranslations[i] = new Array<DeviceGroupTranslation>();
        this.deviceGroupNames.forEach(deviceGroupName => {
          let newDeviceGroupTranslations = oneLanguageTranslations.find(x => x.deviceId == deviceGroupName.id);
          if (!newDeviceGroupTranslations) {
            newDeviceGroupTranslations = this.createDeviceGroupTranslations(deviceGroupName.id, this.translations[i].id, deviceGroupName.names);
          } else {
            deviceGroupName.names.forEach(name => {
              if (!newDeviceGroupTranslations.translations.find(t => t.groupName === name)) {
                let gT = new GroupTranslation();
                gT.groupName = name;
                newDeviceGroupTranslations.translations.push(gT);
              }
            })
          }
          this.deviceGroupTranslations[i].push(newDeviceGroupTranslations);
        });
      }
    });
  }

  private createDeviceGroupTranslations(deviceId: number, translationId: number, deviceGroupNames: string[]): DeviceGroupTranslation {
    let deviceGroupTranslations = new DeviceGroupTranslation(this.authService.getLocationId(), deviceId, translationId);
    deviceGroupNames.forEach(name => {
      let gT = new GroupTranslation();
      gT.groupName = name;
      deviceGroupTranslations.translations.push(gT);
    });
    return deviceGroupTranslations;
  }

  getDeviceNames() {
    this.deviceService.getDevices((data) => {
      this.devices = data;
      this.deviceGroupNames.forEach(deviceGroupName => {
        deviceGroupName.deviceName = this.devices.find(device => device.id == deviceGroupName.id).deviceName;
      });
    },
      () => { });
  }

  getLocaleName(locale: string) {
    return this.languages.find(languageOption => languageOption.locale == locale).name;
  }

  deleteLocaleMain(translations: Translation[]) {
    return translations.filter(translation => translation.locale != this.locationLocaleMain);
  }

  onSubmit() {
    this.formSubmitted = true;
    let mergedDeviceGroupTranslations: DeviceGroupTranslation[] = new Array<DeviceGroupTranslation>();
    this.deviceGroupTranslations.forEach(deviceGroupTranslation => {
      mergedDeviceGroupTranslations = mergedDeviceGroupTranslations.concat(deviceGroupTranslation);
    });
    mergedDeviceGroupTranslations.forEach(mergedDeviceGroupTranslation => {
      mergedDeviceGroupTranslation.translationsJson =
        JSON.stringify(mergedDeviceGroupTranslation.translations);
    })
    this.service.setDeviceGroupTranslation(mergedDeviceGroupTranslations).subscribe(
      res => {
        this.formSubmitted = false;
        this.translate.get('group-translation-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('group-translation-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  getFilteredTranslations(startIndex: number) {
    this.startIndex = startIndex;
  }

  isOnPage() {
    return this.translations.slice(this.startIndex, this.startIndex + 2);
  }

}
