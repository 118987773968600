<div class="card shadow mb-4" *ngIf="displayModule">
    <div class="card-header py-3">
        <div class="row">
            <h5 class="col-8 m-0 font-weight-bold text-primary">{{'import-csv' | translate}}</h5>
        </div>
    </div>
    <div class="card-body">
        <button type="button" class="btn btn-success" (click)="fileInput.click()">
            {{ 'add-file' | translate }}
        </button>
        <span class="hidden-input">
            <input type="file" #fileInput (change)="onFileSelected($event)" accept=".csv"/>
        </span>
        <div class="mt-4" *ngIf="displayFileArray && !hasReservations">
            {{ 'no-reservations' | translate }}
        </div>
        <div class="table-responsive  mt-4" *ngIf="displayFileArray && hasReservations">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <td>#</td>
                        <td>{{ 'reservation-date' | translate }}</td>
                        <td>{{ 'name-and-surname-doctor' | translate }}</td>
                        <td>{{ 'medical-unit' | translate }}</td>
                        <td>{{ 'personal-identity-number' | translate }}</td>
                        <td>{{ 'name-and-surname-patient' | translate }}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of rows; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>{{ row.date | date:"yyyy-MM-dd HH:mm" }}</td>
                        <td>{{ row.resourceName }}</td>
                        <td>{{ row.locationName }}</td>
                        <td>{{ row.pesel }}</td>
                        <td>{{ row.clientName }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <button *ngIf="displaySaveButton && displayFileArray" data-toggle="modal" data-target="#confirmImportCsvModal" class="btn btn-primary mt-4 float-right" type="submit">
            {{ 'save' | translate }}
        </button>
    </div>
</div>

<div class="card shadow mb-4" *ngIf="hasFetchedreservationConfiguration && !displayModule">
    <div class="card-header py-3">
        <h5 class="m-0 font-weight-bold text-warning">{{ 'import-csv' | translate }}</h5>
    </div>
    <div class="card-body">
        {{ 'go-to' | translate }}
        <a rel="noopener noreferrer" routerLink="/reservationConfiguration">
            {{ 'reservation-configuration' | translate }}
        </a>
    </div>
</div>

<div class="modal fade" id="confirmImportCsvModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                {{ 'csv-overrite-warning' | translate }}
            </div>
            <div class="modal-footer">
                <button class="btn btn-danger" data-dismiss="modal">{{ 'cancel' | translate }}</button>
                <button class="btn btn-primary" data-dismiss="modal"  (click)="onSubmit()">
                    {{ 'save' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>


<app-reservation-export-csv></app-reservation-export-csv>