import { ReportTemplate } from "./report-template";

export class ReportTemplateJson {
    public id: number;
    public reportTemplateJson: string;

    constructor(reportTemplate: ReportTemplate) {
        this.id = reportTemplate.id;
        this.reportTemplateJson = JSON.stringify(reportTemplate);
    }
}