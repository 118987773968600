import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-licence-info',
  templateUrl: './licence-info.component.html',
  styleUrls: ['./licence-info.component.css']
})
export class LicenceInfoComponent implements OnInit {

  @Input() title: string;

  constructor() { }

  ngOnInit(): void {
  }

}
