<div class="card shadow mb-4">
    <div class="card-header py-3">
        <div class="row">
            <h5 class="col-8 m-0 font-weight-bold text-primary">{{ 'report-templates' | translate }}</h5>
            <div class="text-right col-4">
                <button type="button" class="btn btn-primary btn-circle btn-sm" (click)="createNewTemplate()"
                    matTooltip="{{ 'add' | translate }}" tooltipClass="tooltip-primary">
                    <i class="fas fa-fw fa-plus"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="card-body">

        <mat-form-field>
            <mat-label>{{ 'report-template' | translate }}</mat-label>
            <mat-select matNativeControl [(ngModel)]="reportTemplate" (selectionChange)="assignTemplateParameters($event.value)">
                <mat-option *ngFor="let template of reportTemplates" [value]="template">{{ template.name }}</mat-option>
            </mat-select>
        </mat-form-field>

        <form *ngIf="reportTemplate && reportTemplateParameters" #form="ngForm" autocomplete="off">

            <div class="form-group">
                <input name="name" #name="ngModel" [(ngModel)]="reportTemplate.name"
                    class="form-control" required>
            </div>

            <app-report-template [reportTemplateParameters]="reportTemplateParameters"
                [users]="users" [desks]="desks" [categories]="categories">
            </app-report-template>

            <div class="form-group">
                <app-submit-button [loading]="savingTemplate" [disabled]="form.invalid" (click)="saveTemplate()"
                    type="submit" [text]="'save-template' | translate">
                </app-submit-button>
            </div>

            <label *ngIf="form.invalid" class="text-danger">*{{ 'complete-required-fields' | translate }}</label>
        </form>
    </div>
</div>
