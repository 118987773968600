import { UntypedFormControl } from "@angular/forms";
import { Category } from "../category/category.model";
import { NoteQuestionType } from "./note-question-type.enum";

export class NoteQuestion {
    public id: number;
    public systemId: number;
    public locationId: number;
    public identityQuestion: boolean;
    public required: boolean;
    public order: number;
    public question: string;
    public questionType: NoteQuestionType;
    public visible: boolean;
    public hiddenCategories: Category[];
    public translations: QuestionTranslation[];
    // #MGR: Add attribute: will bve it send and get correct objects from server?
    public questions: string[] = [];
    //



    constructor(noteQuestion?: NoteQuestionTable) {
        this.id = noteQuestion?.id ?? undefined;
        this.systemId = noteQuestion?.systemId ?? undefined;
        this.locationId = noteQuestion?.locationId ?? undefined;
        this.identityQuestion = noteQuestion?.identityQuestion ?? undefined;
        this.required = noteQuestion?.required ?? undefined;
        this.order = noteQuestion?.order ?? undefined;
        this.questionType = noteQuestion?.questionType ?? undefined;
        this.question = noteQuestion?.questions.join(";") ?? undefined;
        this.visible = noteQuestion?.visible ?? undefined;
        this.hiddenCategories = noteQuestion?.hiddenCategories.value ?? undefined;
        this.translations = noteQuestion?.translations ?? undefined;
    }
}

export class NoteQuestionTable {
    public id: number;
    public systemId: number;
    public locationId: number;
    public identityQuestion: boolean;
    public required: boolean;
    public order: number;
    public questions: string[];
    public questionType: NoteQuestionType;
    public visible: boolean;
    public hiddenCategories: UntypedFormControl;
    public translations: QuestionTranslation[];

    constructor(categories: Category[], noteQuestion?: NoteQuestion) {
        this.id = noteQuestion?.id ?? 0;
        this.systemId = noteQuestion?.systemId ?? undefined;
        this.locationId = noteQuestion?.locationId ?? undefined;
        this.identityQuestion = noteQuestion?.identityQuestion ?? false;
        this.required = noteQuestion?.required ?? false;
        this.order = noteQuestion?.order ?? undefined;
        this.questionType = noteQuestion?.questionType ?? undefined;
        this.questions = noteQuestion?.question.split(';') ?? [""];
        this.visible = noteQuestion?.visible ?? true;
        let initCategories = [];
        noteQuestion?.hiddenCategories.forEach(hiddenCategory => {
            initCategories.push(categories.find(x => x.id == hiddenCategory.id));
        });
        this.hiddenCategories = new UntypedFormControl(initCategories);
        this.translations = noteQuestion?.translations ?? [];
    }
}

export class QuestionTranslation {
    public locale: string;
    public translation: string;

    constructor(locale: string, translation: string) {
        this.locale = locale;
        this.translation = translation;
    }
}