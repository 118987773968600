<div class="table-responsive">
    <table class="table table-striped">
        <thead>
            <tr>
                <th>#</th>
                <th>{{ 'name' | translate }}</th>
                <th>{{ 'multimedia' | translate }}</th>
                <th>{{ 'system-tags' | translate }}</th>
                <th>{{ 'thematic-tags' | translate }}</th>
                <th>{{ 'schedule' | translate }}</th>
                <th>{{ 'options' | translate }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let device of multimediaDevices; let i = index">
                <td>
                    {{ i + 1 }}
                </td>
                <td>
                    {{ device.deviceName }}
                </td>
                <td>
                    <app-confirm-reject-button
                        [confirm]="device.layoutType == LayoutType.Multimedia || device.layoutType == LayoutType.MultimediaFHD || device.layoutType == LayoutType.MultimediaTicketsInQueue">
                    </app-confirm-reject-button>
                </td>
                <td>
                    <span class="badge badge-info mr-1">{{ device.locationId }}</span>
                    <span *ngIf="device.deviceType == DeviceType.MainDisplay" class="badge badge-info">M</span>
                    <span *ngIf="device.deviceType == DeviceType.DeskDisplay" class="badge badge-info">D</span>
                    <span *ngIf="device.deviceType == DeviceType.TicketDispenser" class="badge badge-info">A</span>
                </td>
                <td>
                    <span *ngFor="let tag of tagsByDevice[device.guid]" class="badge badge-primary mr-1">{{ tag.name }}</span>
                </td>
                <td>
                    <span *ngIf="deviceSchedule[device.guid]">{{ deviceSchedule[device.guid].name }}</span>
                    <span *ngIf="!deviceSchedule[device.guid]">{{ 'none' | translate }}</span>
                </td>
                <td>
                    <button class="btn btn-primary btn-circle btn-sm mx-1 mb-1" tooltipClass="tooltip-primary"
                        (click)="setDeviceTags(device); openModal(assignTagsModal)" matTooltip="{{ 'assign-tags' | translate }}">
                        <i class="fas fa-hashtag"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- Assing Tags Modal-->
<ng-template #assignTagsModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{ 'assign-tags' | translate }}</h5>
        <button class="close" mat-dialog-close>
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body" mat-dialog-content>
        <app-assign-tags [tagMultiCtrl]="tagMultiCtrl" [tags]="tags" [title]="title"></app-assign-tags>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" (click)="assignTags()" mat-dialog-close>
            {{ 'assign-tags' | translate}}
        </button>
    </div>
</ng-template>