<div class="form-group">
    <div class="input-group">
        *<div class="font-weight-bold">{{ 'layout' | translate }}</div>
    </div>
    <mat-form-field class="ml-2">
        <mat-select name="layoutType" #layoutType="ngModel" [(ngModel)]="device.layoutType" required>
            <mat-option [value]="LayoutType.Basic">BASIC</mat-option>
            <mat-option [value]="LayoutType.Basic_mix">BASIC MIX</mat-option>
            <mat-option [value]="LayoutType.Groups_View">GROUP VIEW</mat-option>
            <mat-option [value]="LayoutType.Multimedia">MULTIMEDIA</mat-option>
            <mat-option [value]="LayoutType.MultimediaFHD">MULTIMEDIA FHD</mat-option>
            <mat-option [value]="LayoutType.MultimediaTicketsInQueue">MULTIMEDIA TICKETS IN QUEUE</mat-option>
            <mat-option [value]="LayoutType.BasicLastTickets">BASIC LAST TICKETS</mat-option>
            <mat-option [value]="LayoutType.BasicLastTicketsRoom">BASIC LAST TICKETS ROOM</mat-option>
            <mat-option [value]="LayoutType.MultideskCategory">MULTIDESK CATEGORY</mat-option>
            <mat-option [value]="LayoutType.MultideskDesk">MULTIDESK DESKS</mat-option>
            <mat-option [value]="LayoutType.BackOffice">BACKOFFICE</mat-option>
            <mat-option [value]="LayoutType.MultiTickets">MULTI TICKETS</mat-option>
            <mat-option [value]="LayoutType.MultiTicketsInQueue">MULTI TICKETS IN QUEUE</mat-option>
            <mat-option [value]="LayoutType.DesksBasic">DESK BASIC</mat-option>
        </mat-select>
    </mat-form-field>
    <div *ngIf="layoutType.invalid && (layoutType.dirty || layoutType.touched)" class="text-danger">
        <div *ngIf="layoutType.errors.required">{{ 'layout-required' | translate }}</div>
    </div>
</div>

<div class="form-group">
    <div class="input-group">
        *<div class="font-weight-bold">{{ 'read-out-tickets-method' | translate }}</div>
    </div>
    <mat-form-field class="ml-2">
        <mat-select name="sayingTicketsType" #sayingTicketsType="ngModel" [(ngModel)]="device.sayingTicketsType"
            required>
            <mat-option [value]="SayingTicketsTypes.Loudly">{{ 'read-ticket-aloud' | translate }}</mat-option>
            <mat-option [value]="SayingTicketsTypes.SimpleSound">{{ 'simple-sound' | translate }}</mat-option>
            <mat-option [value]="SayingTicketsTypes.NoSound">{{ 'no-sound' | translate }}</mat-option>
        </mat-select>
    </mat-form-field>
    <div *ngIf="sayingTicketsType.invalid && (sayingTicketsType.dirty || sayingTicketsType.touched)"
        class="text-danger">
        <div *ngIf="sayingTicketsType.errors.required">{{ 'read-out-tickets-method-required' | translate }}</div>
    </div>
</div>

<div *ngIf="device.layoutType == LayoutType.MultideskDesk || device.layoutType == LayoutType.DesksBasic">
    <app-served-desks *ngIf="deskMultiCtrl" [deskMultiCtrl]="deskMultiCtrl" [desks]="desks" [title]="desksPlural"
        [noSelectedDesks]="noSelectedDesks" [displayDesks]="true" (desksToEmit)="setDesks($event)">
    </app-served-desks>
</div>
<div *ngIf="device.layoutType != LayoutType.MultideskDesk && device.layoutType != LayoutType.DesksBasic">
    <app-dnd-categories [categories]="categories" [categoryTree]="device.deviceCategoryTree"
        [maxDepth]="maxDepthCategoryTree" (categoryTreeToEmit)="getCategoryTree($event)">
    </app-dnd-categories>
</div>

<div *ngIf="device.layoutType == LayoutType.Multimedia">
    <div class="form-group">
        <div class="item-group">
            <mat-checkbox name="showDeskIcon" #showDeskIcon="ngModel" [(ngModel)]="device.showDeskIcon" [color]="color">
                {{ 'desk-icons' | translate }}</mat-checkbox>
        </div>
    </div>
    <div *ngIf="device.showDeskIcon" class="form-group">
        <app-served-desks *ngIf="deskMultiCtrl" [deskMultiCtrl]="deskMultiCtrl" [desks]="desks"
            [noSelectedDesks]="noSelectedDesks" [displayDesks]="false" (desksToEmit)="setDesks($event)">
        </app-served-desks>
        <ul *ngFor="let desk of device.availableDesks; let i = index">
            <li class="item-group">
                {{ desk.name }}
                <b>{{ desk.deskNumber }}</b>:
                <button class="btn btn-primary btn-sm" data-toggle="modal" data-target=".fileManagerModal"
                    (click)="setCurrentDeskIcon(desk.id)">
                    {{ 'select-desk-icon' | translate }}
                </button>

                <!-- File Manager Modal-->
                <div *ngIf="currentDeskIcon" class="modal fade fileManagerModal" tabindex="-1" role="dialog"
                    aria-labelledby="modalLabel" aria-hidden="true">
                    <app-file-popup [selectedFilePath]="currentDeskIcon?.imgPath" [multiselect]="false"
                        (filePathToEmit)="getImgPath($event, currentDeskIcon?.deskId)">
                    </app-file-popup>
                </div>
                {{ deskIcons[i].imgPath }}
                <hr>
            </li>
        </ul>
    </div>
</div>