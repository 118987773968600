
import { NgModule } from '@angular/core';
import { FittextDirective } from 'projects/qcloud-device/src/app/shared/fit-text.directive';
import { Ng2FittextModule } from 'ng2-fittext';
import { DeskTicketForHourQueueDisplayComponent } from 'projects/qcloud-device/src/app/devices/desk-display/components/desk-ticket-for-hour-queue-display/desk-ticket-for-hour-queue-display';
import { BackofficeComponent } from 'projects/qcloud-device/src/app/devices/main-display/components/backoffice/backoffice.component';
import { BasicLastTicketsListComponent } from 'projects/qcloud-device/src/app/devices/main-display/components/basic-last-tickets-list/basic-last-tickets-list.component';
import { BasicLastTicketsRoomListComponent } from 'projects/qcloud-device/src/app/devices/main-display/components/basic-last-tickets-room-list/basic-last-tickets-room-list.component';
import { BasicListComponent } from 'projects/qcloud-device/src/app/devices/main-display/components/basic-list/basic-list.component';
import { BasicMixListComponent } from 'projects/qcloud-device/src/app/devices/main-display/components/basic-mix-list/basic-mix-list.component';
import { GroupViewComponent } from 'projects/qcloud-device/src/app/devices/main-display/components/group-view/group-view.component';
import { LastTicketsListComponent } from 'projects/qcloud-device/src/app/devices/main-display/components/last-tickets-list/last-tickets-list.component';
import { LocationHeaderComponent } from 'projects/qcloud-device/src/app/devices/main-display/components/location-header/location-header.component';
import { MultideskComponent } from 'projects/qcloud-device/src/app/devices/main-display/components/multidesk/multidesk.component';
import { MultimediaAndSingleTicketDisplayComponent } from 'projects/qcloud-device/src/app/devices/main-display/components/multimedia-and-single-ticket-display/multimedia-and-single-ticket-display.component';
import { MultimediaComponent } from 'projects/qcloud-device/src/app/devices/main-display/components/multimedia/multimedia.component';
import { QueueTicketsListComponent } from 'projects/qcloud-device/src/app/devices/main-display/components/queue-tickets-list/queue-tickets-list.component';
import { SingleTicketDisplayComponent } from 'projects/qcloud-device/src/app/devices/main-display/components/single-ticket-display/single-ticket-display.component';
import { SurveyDisplayComponent } from 'projects/qcloud-device/src/app/devices/survey-display/survey-display.component';
import { BaseComponent } from 'projects/qcloud-device/src/app/shared/base-component/base.component';
import { EndPieceBarCompatibleComponent } from 'projects/qcloud-device/src/app/shared/compatible/end-piece-bar-compatible/end-piece-bar-compatible.component';
import { FullBarCompatibleComponent } from 'projects/qcloud-device/src/app/shared/compatible/full-bar-compatible/full-bar-compatible.component';
import { MiddlePieceBarCompatibleComponent } from 'projects/qcloud-device/src/app/shared/compatible/middle-piece-bar-compatible/middle-piece-bar-compatible.component';
import { StartPieceBarCompatibleComponent } from 'projects/qcloud-device/src/app/shared/compatible/start-piece-bar-compatible/start-piece-bar-compatible.component';
import { CustomisationBarComponent } from 'projects/qcloud-device/src/app/shared/customisation-bar/customisation-bar.component';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { DeskBasicComponent } from 'projects/qcloud-device/src/app/devices/main-display/components/desk-basic/desk-basic.component';
import { ListHeaderComponent } from 'projects/qcloud-device/src/app/devices/main-display/components/list-header/list-header.component';

@NgModule({
    declarations: [FittextDirective,
        DeskBasicComponent,
        BaseComponent,
        BasicListComponent,
        LocationHeaderComponent,
        LastTicketsListComponent,
        QueueTicketsListComponent,
        MultimediaAndSingleTicketDisplayComponent,
        BasicMixListComponent,
        MultimediaComponent,
        BasicLastTicketsListComponent,
        BasicLastTicketsRoomListComponent,
        SingleTicketDisplayComponent,
        CustomisationBarComponent,
        GroupViewComponent,
        BackofficeComponent,
        SurveyDisplayComponent,
        MultideskComponent,
        StartPieceBarCompatibleComponent,
        MiddlePieceBarCompatibleComponent,
        EndPieceBarCompatibleComponent,
        FullBarCompatibleComponent,
        DeskTicketForHourQueueDisplayComponent,
        ListHeaderComponent],
    imports: [Ng2FittextModule,
        BrowserModule,
        FormsModule],
    exports: [FittextDirective,
        BaseComponent,
        DeskBasicComponent,
        BasicListComponent,
        LocationHeaderComponent,
        LastTicketsListComponent,
        QueueTicketsListComponent,
        MultimediaAndSingleTicketDisplayComponent,
        BasicMixListComponent,
        MultimediaComponent,
        BasicLastTicketsListComponent,
        BasicLastTicketsRoomListComponent,
        SingleTicketDisplayComponent,
        CustomisationBarComponent,
        GroupViewComponent,
        BackofficeComponent,
        SurveyDisplayComponent,
        MultideskComponent,
        StartPieceBarCompatibleComponent,
        MiddlePieceBarCompatibleComponent,
        EndPieceBarCompatibleComponent,
        FullBarCompatibleComponent,
        DeskTicketForHourQueueDisplayComponent,
        ListHeaderComponent]
})
export class AppDeviceModule { }