import { Component, OnInit } from '@angular/core';
import { Employee } from 'projects/qcloud-models/grafik/employee/employee.model';
import { EmployeeService } from 'projects/qcloud-rest-client/src/lib/grafik/employee.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormControl } from '@angular/forms';
import { RouteNavigationService } from 'projects/qcloud-web/src/app/shared/route-navigation.service';

@Component({
  selector: 'app-employees-list',
  templateUrl: './employees-list.component.html',
  styleUrls: ['./employees-list.component.css']
})
export class EmployeesListComponent implements OnInit {

  employees: Employee[];
  selectedEmployeeId: number;
  dateCtrl: Array<UntypedFormControl> = new Array();

  constructor(private employeeService: EmployeeService, private translate: TranslateService,
    private toastr: ToastrService, public router: RouteNavigationService) { }

  ngOnInit(): void {
    this.getEmployees();
  }

  getEmployees() {
    this.employeeService.getEmployees((employees) => {
      this.employees = employees;
      this.dateCtrl = new Array(employees.length)
      for (let i = 0; i < employees.length; i++) {
        this.dateCtrl[i] = new UntypedFormControl(new Date(2020, 1, 1, 1, 1, 1));
        this.dateCtrl[i].setValue(employees[i].nearestAvailableWorkTime);
      }
      this.updateOrder();
    }, () => { })
  }


  submitDate(employee: Employee, isDeleting: boolean) {
    if (isDeleting) {
      this.dateCtrl[employee.order] = new UntypedFormControl()
      employee.nearestAvailableWorkTime = null;
    }
    else {
      employee.nearestAvailableWorkTime = this.dateCtrl[employee.order].value._d;
    }

    this.employeeService.editEmployeeWithoutTime(employee).subscribe(
      res => {
        this.router.navigate(['workSchedule']);
      },
      err => {
        this.translate.get('employee-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  onDeleteConfirm(confirmed: boolean) {
    if (confirmed) {
      this.deleteEmployee(this.selectedEmployeeId);
    }
  }

  deleteEmployee(employeeId: number) {
    this.employeeService.deleteEmployee(employeeId).subscribe(
      res => {
        this.getEmployees();
      },
      err => {
        this.translate.get('delete-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  updateOrder() {
    this.employees.forEach((et, idx) => {
      et.order = idx;
    });
  }

  changeOrder(index: number, direction: number) {
    let swapTarget = index + direction;
    [this.employees[index], this.employees[swapTarget]] = [this.employees[swapTarget], this.employees[index]];
    [this.dateCtrl[index], this.dateCtrl[swapTarget]] = [this.dateCtrl[swapTarget], this.dateCtrl[index]];
    this.updateOrder();
  }

  saveOrder() {
    this.employees.map(e => {
      e.nearestAvailableWorkTime = this.dateCtrl[e.order].value;
      this.employeeService.editEmployeeWithoutTime(e).subscribe(res => { });
    });
    this.translate.get('save-order-success').subscribe((res: string) => {
      this.toastr.success(res);
    });
  }
}
