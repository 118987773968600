<div class="card-body">
    <div class="table-responsive">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>{{ '#' | translate }}</th>
                    <th>{{ 'location-name' | translate }}</th>
                    <th *ngIf="tenant.constraints.isEnabled['TicketsReservations']">{{ 'ticket-reservation' | translate }}</th>
                    <th *ngIf="tenant.constraints.isEnabled['WorkerSchedule']">{{ 'work-schedule' | translate }}</th>
                    <th *ngIf="tenant.constraints.isEnabled['ClientSurvey']">{{ 'consumer-survey' | translate }}</th>
                    <th *ngIf="tenant.constraints.isEnabled['Sms']">{{ 'sms-notifications' | translate }}</th>
                    <th *ngIf="tenant.constraints.isEnabled['Mobile']">{{ 'mobile-client' | translate }}</th>
                    <th *ngIf="tenant.constraints.isEnabled['ETicket']">{{ 'eticket' | translate }}</th>
                    <th *ngIf="tenant.constraints.isEnabled['OnHours']">{{ 'on-hours' | translate }}</th>
                    <th *ngIf="tenant.constraints.isEnabled['LDAP']">{{ 'integration-ldap' | translate }}</th>
                    <th>{{ 'devices' | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let location of locations; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ location.locationName }}</td>
                    <td *ngIf="tenant.constraints.isEnabled['TicketsReservations']">
                        <mat-slide-toggle color="primary" (change)="updateLocation(location)"
                            [(ngModel)]="location.constraints.areEnabledTicketsReservations">
                        </mat-slide-toggle>
                    </td>
                    <td *ngIf="tenant.constraints.isEnabled['WorkerSchedule']">
                        <mat-slide-toggle color="primary" (change)="updateLocation(location)"
                            [(ngModel)]="location.constraints.isWorkersScheduleEnabled">
                        </mat-slide-toggle>
                    </td>
                    <td *ngIf="tenant.constraints.isEnabled['ClientSurvey']">
                        <mat-slide-toggle color="primary" (change)="updateLocation(location)"
                            [(ngModel)]="location.constraints.isClientSurveyEnabled">
                        </mat-slide-toggle>
                    </td>
                    <td *ngIf="tenant.constraints.isEnabled['Sms']">
                        <mat-slide-toggle color="primary" (change)="updateLocation(location)"
                            [(ngModel)]="location.constraints.isSmsEnabled">
                        </mat-slide-toggle>
                    </td>
                    <td *ngIf="tenant.constraints.isEnabled['Mobile']">
                        <mat-slide-toggle color="primary" (change)="updateLocation(location)"
                            [(ngModel)]="location.constraints.isMobileClientEnabled">
                        </mat-slide-toggle>
                    </td>
                    <td *ngIf="tenant.constraints.isEnabled['ETicket']">
                        <mat-slide-toggle color="primary" (change)="updateLocation(location)"
                            [(ngModel)]="location.constraints.areETicketsEnabled">
                        </mat-slide-toggle>
                    </td>
                    <td *ngIf="tenant.constraints.isEnabled['OnHours']">
                        <mat-slide-toggle color="primary" (change)="updateLocation(location)"
                            [(ngModel)]="location.constraints.isOnHoursEnabled">
                        </mat-slide-toggle>
                    </td>
                    <td *ngIf="tenant.constraints.isEnabled['LDAP']">
                        <mat-slide-toggle color="primary" (change)="updateLocation(location)"
                            [(ngModel)]="location.constraints.isLDAPEnabled">
                        </mat-slide-toggle>
                    </td>
                    <td>
                        <input type="number" class="form-control" (change)="updateLocation(location)"
                            [(ngModel)]="location.constraints.devicesLimit">
                    </td>
                  </tr>
            </tbody>
        </table>
    </div>
</div>

