import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { QcloudConsoleService } from '../../qcloud-console.service';

@Component({
  selector: 'lib-paused-tickets-button',
  templateUrl: './paused-tickets-button.component.html',
  styleUrls: ['./paused-tickets-button.component.css',  './../base-button/base-button.component.css']
})
export class PausedTicketsButtonComponent  implements OnInit {
	@ViewChild('button') public button: ElementRef;
  isEnabled: boolean;
  isBlocked: boolean;

  constructor(private consoleService: QcloudConsoleService) {
    this.consoleService.ticket.subscribe((val) => {
      if (val.id != 0 && val.id != -1) {
        this.isEnabled = false;
      }
      else {
        this.isEnabled = true;
      }
    })
  }

  ngOnInit(): void {
    this.consoleService.isBlocked.subscribe((val) => {
      this.isBlocked = val;
    })
  }

  public openPausedTicketsModal() {
    if (this.isEnabled)
      this.consoleService.openPausedTicketsModal();
  }

}
