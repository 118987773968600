import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { QcloudRoleService } from '../../../../../qcloud-rest-client/src/lib/role/qcloud-role.service';
import { LdapRoleService } from '../../../../../qcloud-rest-client/src/lib/ldap/ldap-role.service';
import { LdapRole } from '../../../../../qcloud-models/ldap/ldap-role';

@Component({
  selector: 'app-ldap-roles',
  templateUrl: './ldap-roles.component.html',
  styleUrls: ['./ldap-roles.component.css']
})
export class LdapRolesComponent implements OnInit {
  ldapRoles: LdapRole[];
  selectedLdapId: number;

  constructor(private qcloudRoleService: QcloudRoleService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private ldapRoleService: LdapRoleService) { }

  ngOnInit(): void {
    this.getLdapRoles();
  }

  getLdapRoles() {
    this.ldapRoleService.getLdapRoles()
      .subscribe(res => {
        this.ldapRoles = res;
      });
  }

  onDeleteConfirm(confirmed: boolean) {
    if (confirmed) {
      this.deleteLdapRole(this.selectedLdapId);
    }
  }

  deleteLdapRole(ldapRoleId: number) {
    this.ldapRoleService.deleteLdapRole(ldapRoleId).subscribe({
      next: () => {
        this.getLdapRoles();
      },
      error: () => {
        this.translate.get('delete-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    });
  }

}