import { CategoryPriority } from '../category/category-priority.enum';
import { DeskCategory } from './desk-category.model';

export class DeskCategoryPost {
    public id: number;
    public categoryPriority: CategoryPriority

    constructor(deskCategory: DeskCategory) {
        this.id = deskCategory.categoryId;
        this.categoryPriority = deskCategory.categoryPriority;
    }
}
