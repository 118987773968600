import { Component, OnInit } from '@angular/core';
import { News } from 'projects/qcloud-models/grafik/news/news.model';
import { NewsService } from 'projects/qcloud-rest-client/src/lib/grafik/news.service';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { RouteNavigationService } from 'projects/qcloud-web/src/app/shared/route-navigation.service';

@Component({
  selector: 'app-single-news',
  templateUrl: './single-news.component.html',
  styleUrls: ['./single-news.component.css']
})
export class SingleNewsComponent implements OnInit {

  singleNews: News;
  isEditForm: boolean;
  formSubmitted: boolean = false;

  constructor(private authService: AuthService, private translate: TranslateService, private toastr: ToastrService,
    private newsService: NewsService, public router: RouteNavigationService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    var newsId: number;
    this.route.params.subscribe(params => {
      newsId = params['id']
    });
    if (newsId) {
      this.isEditForm = true;
      this.getSingleNews(newsId);
    } else {
      this.isEditForm = false;
      this.initializeSingleNews();
    }
  }

  initializeSingleNews() {
    this.singleNews = new News();
    this.singleNews.locationId = +this.authService.getLocationId();
    this.singleNews.systemId = +this.authService.getSystemId();
  }

  getSingleNews(newsId: number) {
    this.newsService.getSingleNews(newsId).subscribe(news => {
      this.singleNews = news;
    });
  }

  addNews() {
    this.singleNews.id = 0;
    this.newsService.addNews(this.singleNews).subscribe(
      res => {
        this.router.navigate(['workSchedule']);
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('category-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  onEnterPress(event, isFormInvalid) {
    event.preventDefault();
    if (!isFormInvalid) {
      this.onSubmit();
    }
  }

  onSubmit() {
    this.formSubmitted = true;
    if (this.isEditForm) {
      this.editNews();
    } else {
      this.addNews();
    }
  }

  editNews() {
    this.newsService.editNews(this.singleNews).subscribe(
      res => {
        this.router.navigate(['workSchedule']);
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('category-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }
}
