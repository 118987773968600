import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgForm, ControlContainer, UntypedFormControl } from '@angular/forms';
import { LayoutType } from 'projects/qcloud-models/device/layout-type.enum';
import { SayingTicketsTypes } from 'projects/qcloud-models/device/saying-tickets-types.enum';
import { Device } from 'projects/qcloud-models/device/device.model';
import { Category } from 'projects/qcloud-models/category/category.model';
import { ThemePalette } from '@angular/material/core';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { DeviceDeskIcon } from 'projects/qcloud-models/device/device-desk-icon.model';

@Component({
  selector: 'app-main-display',
  templateUrl: './main-display.component.html',
  styleUrls: ['./main-display.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class MainDisplayComponent implements OnInit {

  @Input() device: Device;
  @Input() categories: Category[];
  @Input() desks: Desk[];
  desksPlural = 'desk-plural';
  noSelectedDesks = 'no-selected-desks';

  protected initialDesks: Desk[];
  public deskIcons: DeviceDeskIcon[];
  public currentDeskIcon: DeviceDeskIcon;
  @Output() deskIconsToEmit = new EventEmitter();

  public deskMultiCtrl: UntypedFormControl;

  color: ThemePalette = 'primary';

  LayoutType = LayoutType;
  SayingTicketsTypes = SayingTicketsTypes;

  maxDepthCategoryTree: number = 2;

  constructor() { }

  ngOnInit(): void {
    if (!this.device.layoutType) {
      this.device.layoutType = LayoutType.Basic;
    }
    if (!this.device.sayingTicketsType) {
      this.device.sayingTicketsType = SayingTicketsTypes.Loudly;
    }

    this.initialDesks = new Array<Desk>();
    this.deskIcons = new Array<DeviceDeskIcon>();
    for (let i = 0; i < this.desks.length; i++) {
      this.deskIcons.push(new DeviceDeskIcon(this.desks[i].id, ""));
      if (this.device.layoutType == LayoutType.Multimedia) {
        for (let j = 0; j < this.device.deviceDeskIcons.length; j++) {
          if (this.desks[i].id == this.device.deviceDeskIcons[j].deskId) {
            this.initialDesks.push(this.desks[i]);
            this.deskIcons[i].imgPath = this.device.deviceDeskIcons[j].imgPath;
          }
        }
      } else if (this.device.layoutType == LayoutType.MultideskDesk || this.device.layoutType == LayoutType.DesksBasic) {
        for (let j = 0; j < this.device.availableDesks.length; j++) {
          if (this.desks[i].id == this.device.availableDesks[j].id) {
            this.initialDesks.push(this.desks[i]);
          }
        }
      }
    }

    this.deskMultiCtrl = new UntypedFormControl(this.initialDesks);
  }

  setDesks(desks: Desk[]) {
    this.device.availableDesks = desks;
  }

  getImgPath(imgPath: string, deskId: number) {
    let deviceDeskIcon = new DeviceDeskIcon(deskId, imgPath);
    let foundIndex = this.deskIcons.findIndex(x => x.deskId == deviceDeskIcon.deskId);
    this.deskIcons[foundIndex] = deviceDeskIcon;
    this.currentDeskIcon = null;
    this.device.deviceDeskIcons = new Array<DeviceDeskIcon>();
    for (let i = 0; i < this.deskIcons.length; i++) {
      if (this.deskIcons[i].imgPath != "") {
        this.device.deviceDeskIcons.push(this.deskIcons[i]);
      }
    }
    this.deskIconsToEmit.emit(this.device.deviceDeskIcons);
  }

  setCurrentDeskIcon(deskId: number) {
    let foundIndex = this.deskIcons.findIndex(x => x.deskId == deskId);
    this.currentDeskIcon = this.deskIcons[foundIndex];
  }

  getCategoryTree(categoryTree: any) {
    this.device.deviceCategoryTree = categoryTree;
  }
}
