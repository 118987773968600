export class LocationActivityBlock
{
    public id: number;
    public locationId: number;
    public systemId: number;
    public dateStart: Date;
    public dateEnd: Date;
    public blockedCategoryIds: number[];

    constructor(locationId: number, systemId: number) {
        this.locationId = locationId;
        this.systemId = systemId;
        this.dateStart = new Date();
        this.dateEnd = new Date();
    }
}