import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { DeskStatus } from 'projects/qcloud-models/desk/desk-status.enum';

const DEFAULT_TIMEOUT = 3000;

@Injectable({
  providedIn: 'root'
})
export class DeskService {

  appBase: string;

  constructor(private http: HttpClient, private authService: AuthService) { }

  public setAppBase(appBase: string){
    this.appBase = appBase;
  }

  getDesks(callback: (data: Desk[]) => any, onError: () => any) {
    this.http.get(this.appBase +
      `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/desks`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  getSystemDesks(callback: (data: Desk[]) => any, onError: () => any) {
    this.http.get(this.appBase +
      `api/${this.authService.getSystemId()}}/desks`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  getDesksByLocation(locationId: number, callback: (data: Desk[]) => any, onError: () => any) {
    this.http.get(this.appBase +
      `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/desks`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  getDesk(deskId: number) : Observable<Desk> {
    return this.http.get<Desk>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/desks/${deskId}`);
  }

  addDesk(desk: Desk) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/desks`, desk);
  }

  editDesk(desk: Desk, deskId: number) {
    return this.http.put(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/desks/${deskId}`, desk);
  }

  deleteDesk(deskId: number) {
    return this.http.delete(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/desks/${deskId}`);
  }

  changeDeskStatus(deskId: number, deskStatus: DeskStatus) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/desks/${deskId}/status/${deskStatus}`,
      {});
  }
}
