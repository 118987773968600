<ng-template #modalContent let-close="close">
    <div class="modal-header">
        <h5 class="modal-title">{{ 'reservation' | translate }}</h5>
        <button type="button" class="close" (click)="close()" mat-dialog-close>
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" mat-dialog-content>
        <div class="col-md-4">
            {{ choosenDate | date:'dd' }} {{ choosenDate | calendarDate:(view + 'ViewTitle'):locale }}
            <button class="btn btn-circle btn-sm mx-1 mb-1"
                [ngClass]="{'btn-light': !isCalendarDisplayed, 'btn-primary':isCalendarDisplayed }"
                (click)="isCalendarDisplayed = !isCalendarDisplayed">
                <i class="fas fa-fw fa-calendar"></i>
            </button>
        </div>
        <div class="col-md-4" *ngIf="isCalendarDisplayed">
            <div class="btn-group">
                <div class="btn btn-primary" (click)="addMonth(-1)">
                    {{ 'previous' | translate }}
                </div>
                <div class="btn btn-primary" (click)="addMonth(1)">
                    {{ 'next' | translate }}
                </div>
            </div>
        </div>
        <div class="mb-3" *ngIf="isCalendarDisplayed">
            <mwl-calendar-month-view [viewDate]="choosenDate" (dayClicked)="getListForModal($event.day.date)"
                [locale]="locale" [refresh]="refresh" (beforeViewRender)="beforeMonthViewRender($event)">
            </mwl-calendar-month-view>
        </div>
        <div class="mb-3">
            <mat-form-field>
                <mat-label>{{ 'hour' | translate }}</mat-label>
                <mat-select matNativeControl [(ngModel)]="modalChooseTimeData.timeChoosen">
                    <mat-option *ngFor="let p of modalChooseTimeData.availableTimes" [value]="p">
                        {{ p.reservationTime | slice:11:16 }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="mb-3">
            <app-reservation-modal-questions [answers]="answers" [choosenCategory]="choosenCategory"></app-reservation-modal-questions>
        </div>
        <div class="mb-3">
            <div class="item-group">
                <mat-checkbox *ngIf="isEmailSendingEnabled" class="font-weight-bold" name="sendMailOnReservation"
                    [(ngModel)]="sendMailOnReservation" color="primary">
                    {{ 'send-mail' | translate }}
                </mat-checkbox>

                <mat-checkbox *ngIf="!isEmailSendingEnabled" class="font-weight-bold" color="primary" disabled
                    [(ngModel)]="sendMailOnReservation" matTooltipPosition="right" matTooltipClass="tooltip-orange"
                    matTooltip="{{ 'option-available-after-enabling-config-mail' | translate }}">
                    {{ 'send-mail' | translate }}
                </mat-checkbox>
            </div>
        </div>
        <div class="mb-3">
            <mat-checkbox *ngIf="locationService.getLocationConstraints().isSmsEnabled && isSmsSendingEnabled"
                name="sendSmsOnReservation" [(ngModel)]="sendSmsOnReservation" color="primary" class="font-weight-bold">
                {{ 'send-sms' | translate }}
            </mat-checkbox>

            <mat-checkbox *ngIf="!locationService.getLocationConstraints().isSmsEnabled" disabled
                class="font-weight-bold" matTooltipPosition="right" matTooltipClass="tooltip-orange"
                matTooltip="{{ 'option-available-after-extending-licence' | translate }}">
                {{ 'send-sms' | translate }}
            </mat-checkbox>

            <mat-checkbox *ngIf="locationService.getLocationConstraints().isSmsEnabled && !isSmsSendingEnabled" disabled
                class="font-weight-bold" matTooltipPosition="right" matTooltipClass="tooltip-orange"
                matTooltip="{{ 'option-available-after-enabling-config-sms' | translate }}">
                {{ 'send-sms' | translate }}
            </mat-checkbox>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-danger" data-toggle="modal" data-target="#deleteModal" *ngIf="ticketReservation.id > 0">
            {{ 'delete' | translate }}
        </button>
        <button class="btn btn-primary" [disabled]="formSubmitted" (click)="onCloseModal()" mat-dialog-close>
            {{ 'save' | translate }}<span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1"
                role="status" aria-hidden="true"></span>
        </button>
    </div>
</ng-template>

<div class="modal fade delete-reservation-modal" id="deleteModal" tabindex="-1" role="dialog" aria-hidden="true">
    <app-delete-modal [info]="'delete-confirmation'" [confirm]="'delete'" (confirmed)="onDeleteConfirm($event)">
    </app-delete-modal>
</div>