<div class="row text-center mb-1">
  <div class="col-md-4">
    <div class="btn-group">
      <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()">
        {{ 'previous' | translate }}
      </div>
      <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
        {{ 'today' | translate}}
      </div>
      <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()">
        {{ 'next' | translate }}
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):locale }}</h3>
  </div>
  <div class="col-md-4">
    <div class="btn-group">
      <div class="btn btn-primary" (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month">
        {{ 'month' | translate }}
      </div>
      <div class="btn btn-primary" (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week">
        {{ 'week' | translate }}
      </div>
      <div class="btn btn-primary" (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
        {{ 'day' | translate }}
      </div>
    </div>
  </div>
</div>
  
<div [ngSwitch]="view" class="mb-4">
  <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month"
    [viewDate]="viewDate" [events]="calendarEvents" [locale]="locale" [refresh]="refresh"
    [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
    (beforeViewRender)="updateCalendarEvents($event)"
    (eventClicked)="managePlaylist($event.event)"
    (eventTimesChanged)="eventTimesChanged($event)">
  </mwl-calendar-month-view>
  <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week"
    [viewDate]="viewDate" [events]="calendarEvents" [locale]="locale" [refresh]="refresh"
    (beforeViewRender)="updateCalendarEvents($event)"
    (eventClicked)="managePlaylist($event.event)"
    (eventTimesChanged)="eventTimesChanged($event)">
  </mwl-calendar-week-view>
  <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day"
    [viewDate]="viewDate" [events]="calendarEvents" [locale]="locale" [refresh]="refresh"
    (beforeViewRender)="updateCalendarEvents($event)"
    (eventClicked)="managePlaylist($event.event)"
    (eventTimesChanged)="eventTimesChanged($event)">
  </mwl-calendar-day-view>
</div>