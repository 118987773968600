import { Desk } from './desk';
import { DeskStatus } from './desk-status.enum';
import { SystemDeskCategoryPost } from './system-desk-category-post.model';

export class SystemDesk {
    public id: number;
    public locationId: number;
    public systemId: number;
    public deskFullNamePronunciation: string;
    public deskStatus: DeskStatus;
    public name: string;
    public deskNumber: string;
    public roomNumber: string;
    public roomNumberPronunciation: string;
    public currentLoggedInUserId: string;
    public localeDesks: Desk[];
    public localeDeskLocationIds: number[];
    public systemCategoryForSystemDesks: SystemDeskCategoryPost[];
}
