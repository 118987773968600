<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 *ngIf="isEditForm" class="m-0 font-weight-bold text-primary">{{ 'edit-location-data' | translate }}
        </h5>
        <h5 *ngIf="!isEditForm" class="m-0 font-weight-bold text-primary">{{ 'add-location' | translate }}</h5>
    </div>
    <div *ngIf="locationData" class="card-body">
        <form #form="ngForm" autocomplete="off">
            <input type="hidden" name="id" [(ngModel)]="locationData.id" [value]="locationData.id">
            <div class="form-group">
                *{{ 'location-name' | translate }}
                <div class="input-group">
                    <input name="locationName" #locationName="ngModel" [(ngModel)]="locationData.locationName"
                        class="form-control" placeholder="{{ 'qcloud-system' | translate }}" required>
                </div>
                <div *ngIf="locationName.invalid && (locationName.dirty || locationName.touched)"
                    class="text-danger mx-2 my-1">
                    <div *ngIf="locationName.errors.required">{{ 'location-name-required' | translate }}</div>
                </div>
            </div>
            <div class="form-group">
                *{{ 'location-tag' | translate }}
                <div class="input-group">
                    <input name="locationTag" #locationTag="ngModel" [(ngModel)]="locationData.locationTag"
                        class="form-control" placeholder="{{ 'qcloud-system' | translate }}" maxlength="4" required>
                </div>
                <div *ngIf="locationTag.invalid && (locationTag.dirty || locationTag.touched)"
                    class="text-danger mx-2 my-1">
                    <div *ngIf="locationTag.errors.required">{{ 'location-tag-required' | translate }}</div>
                </div>
            </div>

            <div class="form-group">
                {{ 'country' | translate }}
                <div class="input-group">
                    <input name="country" #country="ngModel" [(ngModel)]="locationData.country" class="form-control">
                </div>
            </div>

            <div class="form-group">
                {{ 'city' | translate }}
                <div class="input-group">
                    <input name="city" #city="ngModel" [(ngModel)]="locationData.city" class="form-control">
                </div>
            </div>

            <div class="form-group">
                {{ 'street' | translate }}
                <div class="input-group">
                    <input name="street" #street="ngModel" [(ngModel)]="locationData.street" class="form-control">
                </div>
            </div>

            <div class="form-group">
                {{ 'building-number' | translate }}
                <div class="input-group">
                    <input name="buildingNumber" #buildingNumber="ngModel" [(ngModel)]="locationData.buildingNumber"
                        class="form-control">
                </div>
            </div>

            <div class="form-group">
                {{ 'post-code' | translate }}
                <div class="input-group">
                    <input name="postCode" #postCode="ngModel" [(ngModel)]="locationData.postCode" class="form-control"
                        pattern="\d{2}-\d{3}">
                </div>
                <div *ngIf="postCode.invalid && (postCode.dirty || postCode.touched)" class="text-danger mx-2 my-1">
                    <div *ngIf="postCode.errors.pattern">{{ 'post-code-error' | translate }}</div>
                </div>
            </div>

            <div class="form-group">
                {{ 'phone-number' | translate }}
                <div class="input-group">
                    <input name="phoneNumber" #phoneNumber="ngModel" [(ngModel)]="locationData.phoneNumber"
                        class="form-control" minlength="7" maxlength="17"
                        pattern="^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$">
                </div>
                <div *ngIf="phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched)"
                    class="text-danger mx-2 my-1">
                    <div *ngIf="phoneNumber.errors.minlength">{{ 'phone-number-length-error' | translate }}</div>
                    <div *ngIf="phoneNumber.errors.pattern">{{ 'phone-number-error' | translate }}</div>
                </div>
            </div>

            <div class="form-group">
                {{ 'website' | translate }}
                <div class="input-group">
                    <input name="website" #website="ngModel" [(ngModel)]="locationData.website" class="form-control">
                </div>
            </div>

            <app-time-zone [(locationData)]=locationData></app-time-zone>

            <div class="form-group">
                <div class="item-group">
                    <mat-checkbox *ngIf="areETicketsEnabled()" name="eticketChangeCodeAfter24h"
                        #eticketChangeCodeAfter24h="ngModel" [(ngModel)]="locationData.eticketChangeCodeAfter24h"
                        color="primary">
                        {{ 'eticket-change-code-after-24-hours' | translate }}
                    </mat-checkbox>

                    <mat-checkbox *ngIf="!areETicketsEnabled()" disabled matTooltipPosition="right"
                        matTooltipClass="tooltip-orange"
                        matTooltip="{{ 'option-available-after-extending-licence' | translate }}">
                        {{ 'eticket-change-code-after-24-hours' | translate }}
                    </mat-checkbox>
                </div>
            </div>

            <div class="form-group">
                <div class="item-group">
                    <mat-checkbox name="isWcag" [(ngModel)]="locationData.isWcag" color="primary">
                        {{ 'is-wcag' | translate }}
                    </mat-checkbox>
                </div>
            </div>

            <div class="form-group">
                <div class="row">
                    <div class="col-6">
                        {{ 'latitude' | translate }}
                        <div class="input-group">
                            <input name="latitude" #latitude="ngModel" [(ngModel)]="locationData.latitude"
                                class="form-control">
                        </div>
                    </div>
                    <div class="col-6">
                        {{ 'longitude' | translate }}
                        <div class="input-group">
                            <input name="longitude" #longitude="ngModel" [(ngModel)]="locationData.longitude"
                                class="form-control">
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                {{ 'logo' | translate }}:
                <button class="btn btn-primary btn-sm" data-toggle="modal" data-target="#fileManagerModal">
                    {{ 'select-logo' | translate }}
                </button>
                {{ locationData.logoImgPath }}
                <button *ngIf="locationData.logoImgPath!=null" class="btn btn-danger btn-sm" (click)="clearLogoPath()">
                    {{'remove-logo' | translate}}</button>
            </div>

            <!-- File Manager Modal-->
            <div class="modal fade" id="fileManagerModal" tabindex="-1" role="dialog" aria-labelledby="modalLabel"
                aria-hidden="true">
                <app-file-popup [selectedFilePath]="locationData.logoImgPath" [multiselect]="false"
                    (filePathToEmit)="getLogoPath($event)">
                </app-file-popup>
            </div>
            <div class="form-group row">
                <button class="btn btn-danger m-1" routerLink="/location/1" (click)="undoEdit()">{{ 'undo' | translate
                    }}</button>
                <button class="btn btn-primary m-1" type="submit" [disabled]="formSubmitted || form.invalid"
                    (click)="onSubmit()">
                    <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                        aria-hidden="true"></span>{{ 'save-location-data' | translate }}
                </button>
            </div>

            <label *ngIf="form.invalid" class="text-danger">*{{ 'complete-required-fields' | translate }}</label>
        </form>
    </div>
</div>