import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Category } from 'projects/qcloud-models/category/category.model';
import { ReservationQuestionType } from 'projects/qcloud-models/reservation/reservation-question-type.enum';
import { ReservationQuestion, ReservationQuestionTable } from 'projects/qcloud-models/reservation/reservation-questions';
import { ReservationService } from 'projects/qcloud-rest-client/src/lib/reservation.service';

@Component({
  selector: 'app-reservation-questionaire',
  templateUrl: './reservation-questionaire.component.html',
  styleUrls: ['./reservation-questionaire.component.css']
})
export class ReservationQuestionaireComponent implements OnInit {

  @Input() questions: ReservationQuestion[];
  @Input() reservationCategories: Category[];
  questionsTable: ReservationQuestionTable[];
  ReservationQuestionType = ReservationQuestionType;
  formSubmitted: boolean = false;
  @Output() changedQuestions = new EventEmitter();

  constructor(private translate: TranslateService, private reservationService: ReservationService,
    private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.questionsTable = this.questions.map(x => new ReservationQuestionTable(this.reservationCategories, x));
  }

  addQuestion(): void {
    let item = new ReservationQuestionTable(this.reservationCategories);
    this.questionsTable.push(item);
  }

  changeQuestionType(event: MatSelectChange, question: ReservationQuestionTable) {
    if (event.value == ReservationQuestionType.Option || event.value == ReservationQuestionType.VisibleOption) {
      if (question.questions.length < 2) {
        question.questions.push("");
      }
    }
    else {
      if (question.questions.length > 1) {
        question.questions.pop();
      }
    }
  }

  changeOrder(index: number, direction: number) {
    let swapTarget = index + direction;
    [this.questionsTable[index], this.questionsTable[swapTarget]] = [this.questionsTable[swapTarget], this.questionsTable[index]];
  }

  deleteQuestion(index: number) {
    let question = this.questionsTable[index];
    if (question.id == 0) {
      this.questionsTable.splice(index, 1);
      return;
    }
    this.reservationService.deleteQuestion(question.id, () => {
      this.changedQuestions.emit(null);
      this.questionsTable.splice(index, 1);
      this.translate.get('reservation-questionaire-delete-success').subscribe((res: string) => {
        this.toastr.success(res);
      });
    }, () => {
      this.translate.get('reservation-questionaire-delete-error').subscribe((res: string) => {
        this.toastr.error(res);
      });
    })
  }

  saveQuestions() {
    this.formSubmitted = true;
    this.questionsTable.forEach((element, index) => {
      element.order = index;
    });
    this.questions = this.questionsTable.map(x => new ReservationQuestion(x));
    this.reservationService.updateReservationQuestionaire(this.questions).subscribe(
      () => {
        this.translate.get('reservation-questionaire-save-success').subscribe((res: string) => {
          this.toastr.success(res);
          this.changedQuestions.emit(null);
          this.formSubmitted = false;
        });
      },
      () => {
        this.translate.get('reservation-questionaire-save-error').subscribe((res: string) => {
          this.toastr.error(res);
          this.formSubmitted = false;
        });
      },
      () => {
        this.reservationService.getReservationQuestionaire(false,
          (data) => {
            this.questions = data;
            this.questionsTable = this.questions.map(x => new ReservationQuestionTable(this.reservationCategories, x));
          },
          () => { }
        );
      }
    );
  }

  trackByFn(index, item) {
    return index;
  }
}
