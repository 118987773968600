export class AmmsCode {
    public id: number;
    public locationId:number;
    public systemId:number;
    public name:string;
    public code:string;
    public codeType:CodeType;
}

export enum CodeType
{
    Service = 0,
    Unit = 1,
    Location = 2,
    Resource = 3
}