<!-- Page Wrapper -->
<div *ngIf="locationService.locationData" class="vh-100" id="wrapper">

  <!-- Sidebar -->
  <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion overflow-set" id="accordionSidebar"
    [ngClass]="{'toggled': isSidebarToggled, 'sidebar-visible': !isSidebarVisible}">

    <!-- Sidebar - Brand -->
    <a class="sidebar-brand d-flex align-items-center justify-content-center sticky-top sidebar-top" href="index.html">
      <div class="sidebar-brand-icon">
        <img *ngIf="fileService.isImage(locationService.locationData.logoImgPath)"
          style="height: 3rem;width: 3rem; margin-bottom: -10px;"
          [src]="fileService.getPathToImage(locationService.locationData.logoImgPath)">
      </div>
      <label class="d-none d-md-inline-flex badge align-items-center justify-content-start text-wrap mt-2"
        style="height: 3rem;width: 9rem;">
        <div class="text-cutter">
          <span>{{ locationService.locationData.locationName }}</span>
        </div>
      </label>
    </a>

    <!-- Divider -->
    <hr class="sidebar-divider my-0">

    <!-- Nav Item - Dashboard -->
    <li class="nav-item active">
      <a class="nav-link" routerLink="/dashboard" routerLinkActive="active">
        <i class="fas fa-fw fa-tachometer-alt"></i>
        <span>{{ 'dashboard' | translate }}</span></a>
    </li>

    <!-- Nav Item - Welcome Page -->
    <li class="nav-item">
      <a class="nav-link" routerLink="/qcloud/welcome" routerLinkActive="active">
        <i class="fas fa-door-open"></i>
        <span>{{ 'welcome-page' | translate }}</span></a>
    </li>

    <!-- Nav Item - Select Location -->
    <li *ngIf="authService.isManagerOrHigher()" class="nav-item">
      <a class="nav-link" (click)="openChooseLocationPopup()">
        <i class="fas fa-map-marker-alt mr-2"></i>
        <span>{{ 'select-location' | translate }}</span></a>
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider">

    <div *ngIf="!serveETickets">

      <!-- Heading -->
      <div class="sidebar-heading">
        {{ 'system-administration' | translate }}
      </div>

      <!-- Nav Item - Multimedia Collapse Menu -->
      <li class="nav-item">
        <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseMultimedia"
          aria-expanded="true" aria-controls="collapseMultimedia">
          <i class="fas fa-fw fa-play-circle"></i>
          <span>{{ 'multimedia' | translate }}</span>
        </a>
        <div id="collapseMultimedia" class="collapse" aria-labelledby="headingMultimedia"
          data-parent="#accordionSidebar">
          <div class="bg-white py-2 collapse-inner rounded">
            <a class="collapse-item" routerLink="/schedules">{{ 'schedule' | translate }}</a>
            <a class="collapse-item" routerLink="/playlists">{{ 'playlists' | translate }}</a>
            <a class="collapse-item" routerLink="/multimediaDevices">{{ 'multimedia-devices' | translate }}</a>
          </div>
        </div>
      </li>

      <!-- Nav Item - Stats Collapse Menu -->
      <li class="nav-item">
        <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseStats" aria-expanded="true"
          aria-controls="collapseStats">
          <i class="fas fa-fw fa-chart-pie"></i>
          <span>{{ 'statistics' | translate }}</span>
        </a>
        <div id="collapseStats" class="collapse" aria-labelledby="headingStats" data-parent="#accordionSidebar">
          <div class="bg-white py-2 collapse-inner rounded">
            <a class="collapse-item" routerLink="/statistics/ticket">{{ 'statistics-ticket' | translate }}</a>
            <a class="collapse-item" routerLink="/statistics/reservation">{{ 'statistics-reservation' | translate }}</a>
            <a class="collapse-item" routerLink="/statistics/customerSurvey">
              {{ 'statistics-customer-survey' | translate}}</a>
            <a class="collapse-item" routerLink="/statistics/reports">{{ 'statistics-reports' | translate }}</a>
            <a class="collapse-item" routerLink="/statistics/ticketHistory">
              {{ 'statistics-tickets-history' | translate}}</a>
            <a class="collapse-item" routerLink="/statistics/noteHistory">
              {{ 'statistics-note-history' | translate}}</a>
          </div>
        </div>
      </li>

      <!-- Nav Item - Files -->
      <li class="nav-item">
        <a class="nav-link" routerLink="/filemanager" routerLinkActive="active">
          <i class="fas fa-fw fa-folder"></i>
          <span>{{ 'file-manager' | translate }}</span></a>
      </li>

      <!-- Divider -->
      <hr class="sidebar-divider">

    </div>

    <!-- Heading -->
    <div class="sidebar-heading">
      {{ 'location-configuration' | translate }}
    </div>

    <!-- Nav Item - Config Collapse Menu -->
    <li class="nav-item">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseConfig" aria-expanded="true"
        aria-controls="collapseConfig">
        <i class="fas fa-fw fa-cog"></i>
        <span>{{ 'configuration' | translate }}</span>
      </a>
      <div id="collapseConfig" class="collapse" aria-labelledby="headingConfig" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <a class="collapse-item" routerLink="/categories">{{ 'categories' | translate }}</a>
          <a class="collapse-item" routerLink="/desks">{{ 'desks' | translate }}</a>
          <a class="collapse-item" routerLink="/users">{{ 'users' | translate }}</a>
          <a class="collapse-item" routerLink="/qcloudRoles"> {{ 'ldap.roles-configuration' | translate }}</a>
          <a *ngIf="authService.isAdminOrHigher()" class="collapse-item" routerLink="/devices">
            {{ 'devices' | translate }}</a>
        </div>
      </div>
    </li>

    <!-- Nav Item - Settings Collapse Menu -->
    <li *ngIf="authService.isAdminOrHigher()" class="nav-item">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseSettings" aria-expanded="true"
        aria-controls="collapseSettings">
        <i class="fas fa-fw fa-map-marker-alt"></i>
        <span>{{ 'location-configuration' | translate }}</span>
      </a>
      <div id="collapseSettings" class="collapse" aria-labelledby="headingSettings" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <a class="collapse-item" [routerLink]="['/location/', locationService.locationData.id]">
            {{'location-data' | translate }}</a>
          <a class="collapse-item" routerLink="/personalisation">{{ 'personalisation' | translate }}</a>
          <a class="collapse-item" routerLink="/ticketLayout">{{ 'ticket-design' | translate }}</a>
          <a class="collapse-item" routerLink="/maintenance">{{ 'conservation' | translate }}</a>
          <a class="collapse-item" routerLink="/languagesAndTranslations">
            {{ 'languages-and-translations' | translate}}</a>
          <a *ngIf="authService.isAdminOrHigher()" class="collapse-item" routerLink="/emailConfig">
            {{ 'mail-agent' | translate }}
          </a>
          <a *ngIf="authService.isAdminOrHigher()" class="collapse-item" routerLink="/smsProvider">
            {{ 'sms-provider' | translate }}
          </a>

          <a *ngIf="authService.isSuperAdmin()" class="collapse-item" routerLink="/notesConfiguration">
            {{ 'note.notes' | translate}}</a>
        </div>
      </div>
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider">

    <!-- Heading -->
    <div class="sidebar-heading">
      {{ 'system-configuration' | translate }}
    </div>

    <li *ngIf="authService.isSuperAdmin()" class="nav-item">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseSystemConfiguration"
        aria-expanded="true" aria-controls="collapseSystemConfiguration">
        <i class="fas fa-fw fa-tools"></i>
        <span>{{ 'system-configuration' | translate }}</span>
      </a>
      <div id="collapseSystemConfiguration" class="collapse" aria-labelledby="headingSettings"
        data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <a class="collapse-item" routerLink="/systemCategories">{{ 'system-categories' | translate }}</a>
          <a class="collapse-item" routerLink="/systemDesks">{{ 'system-desks' | translate }}</a>
          <a class="collapse-item" routerLink="/system-users">{{'system-users' | translate }}</a>
          <a class="collapse-item" routerLink="/systemQcloudRoles">{{'system-users-roles' | translate }}</a>
          <a class="collapse-item" routerLink="/categories-display">{{ 'display-categories' | translate }}</a>

          <a *ngIf="authService.isSuperAdmin()" class="collapse-item" routerLink="/logs">
            {{ 'audit' | translate}}</a>
        </div>
      </div>
    </li>
    <li *ngIf="authService.isSuperAdmin()" class="nav-item">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#systemPersonalisation"
        aria-expanded="true" aria-controls="systemPersonalisation">
        <i class="fas fa-fw fa-user-cog"></i>
        <span>{{ 'personalisation' | translate }}</span>
      </a>
      <div id="systemPersonalisation" class="collapse" aria-labelledby="headingSettings"
        data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <a *ngIf="authService.isSuperAdmin()" class="collapse-item" routerLink="/deviceLayouts">{{ 'layouts' |
            translate}}</a>
          <a class="collapse-item" routerLink="/categories-display">{{ 'display-categories' | translate }}</a>
          <a class="collapse-item" routerLink="/ticket-questions">{{ 'ticket-question' | translate }}</a>
        </div>
      </div>
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider">

    <!-- Heading -->
    <div class="sidebar-heading">
      {{ 'additional-modules' | translate }}
    </div>

    <!-- Nav Item - Reservation -->
    <li *ngIf="!serveETickets" class="nav-item">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseReservations"
        aria-expanded="true" aria-controls="collapseReservations">
        <i class="far fa-fw fa-address-card"></i>
        <span>{{ 'reservation' | translate }}</span>
      </a>
      <div id="collapseReservations" class="collapse" aria-labelledby="headingSettings" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <a class="collapse-item" *ngIf="authService.isAdminOrHigher()" routerLink="/reservations">
            {{ 'calendar' | translate }}
          </a>
          <a class="collapse-item" routerLink="/reservationConfiguration">
            {{ 'reservation-configuration' | translate }}
          </a>
          <a class="collapse-item" *ngIf="authService.isAdminOrHigher()" routerLink="/reservationImportCSV">
            {{ 'import-export-csv' | translate }}
          </a>
        </div>
      </div>
    </li>

    <!-- Nav Item - onHours -->
    <li *ngIf="!serveETickets" class="nav-item">
      <a class="nav-link" routerLink="/onHours" routerLinkActive="active">
        <i class="far fa-fw fa-hourglass"></i>
        <span>{{ 'on-hours' | translate }}</span></a>
    </li>

    <!-- Nav Item - Opinion Poll -->
    <li *ngIf="!serveETickets" class="nav-item">
      <a class="nav-link" routerLink="/customerSurvey" routerLinkActive="active">
        <i class="far fa-fw fa-smile"></i>
        <span>{{ 'consumer-survey' | translate }}</span></a>
    </li>

    <!-- Nav Item - Work Schedule -->
    <li *ngIf="!serveETickets" class="nav-item">
      <a class="nav-link" routerLink="/workSchedule" routerLinkActive="active">
        <i class="far fa-fw fa-calendar-alt"></i>
        <span>{{ 'work-schedule' | translate }}</span></a>
    </li>

    <!-- Nav Item - Sms Notifications -->
    <li *ngIf="!serveETickets" class="nav-item">
      <a class="nav-link" routerLink="/smsNotifications" routerLinkActive="active">
        <i class="fas fa-fw fa-sms"></i>
        <span>{{ 'sms-notifications' | translate }}</span></a>
    </li>

    <!-- Nav Item - ETicket -->
    <li class="nav-item">
      <a class="nav-link" routerLink="/eticket" routerLinkActive="active">
        <i class="fas fa-fw fa-mobile-alt"></i>
        <span>{{ 'eticket' | translate }}</span></a>
    </li>

    <!-- Nav Item - Axxon intelect -->
    <li *ngIf="!serveETickets" class="nav-item">
      <a class="nav-link" routerLink="/axxon" routerLinkActive="active">
        <i class="fas fa-fw fa-cog"></i>
        <span>{{ 'access-system-configuration' | translate }}</span></a>
    </li>

    <!-- Nav Item - Amms -->
    <li *ngIf="!serveETickets" class="nav-item">
      <a class="nav-link" routerLink="/amms" routerLinkActive="active">
        <i class="fas fa-fw fa-cog"></i>
        <span>HIS</span></a>
    </li>
    <!-- Nav Item - LDAP Integration -->
    <li class="nav-item">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseLdapConfig"
        aria-expanded="true" aria-controls="collapseLdapConfig" *ngIf="authService.isAdminOrHigher()">
        <i class="fas fa-fw fa-cog"></i>
        <span>{{ 'integration-ldap' | translate }}</span>
      </a>
      <div id="collapseLdapConfig" class="collapse" aria-labelledby="headingLdapConfig" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <a class="collapse-item" routerLink="/ldapConfiguration">{{ 'integration-ldap' | translate }} </a>
          <a class="collapse-item" routerLink="/ldapRoles">{{ 'ldap.roles-configuration' | translate }} </a>
        </div>
      </div>
    </li>


    <div *ngIf="authService.isAdminOrHigher()">
      <!-- Divider -->
      <hr class="sidebar-divider">

      <!-- Heading -->
      <div class="sidebar-heading">
        {{ 'system-settings' | translate }}
      </div>

      <!-- Nav Item - License -->
      <li class="nav-item">
        <a class="nav-link" routerLink="/license" routerLinkActive="active">
          <i class="fas fa-fw fa-stamp"></i>
          <span>{{ 'license' | translate }}</span></a>
      </li>

      <!-- Nav Item - Default Settings -->
      <li *ngIf="authService.isSuperAdmin()" class="nav-item">
        <a class="nav-link" routerLink="/defaultSettings" routerLinkActive="active">
          <i class="fas fa-fw fa-cogs"></i>
          <span>{{ 'default-settings' | translate }}</span></a>
      </li>

      <!-- Nav Item - Locations -->
      <li *ngIf="authService.isSuperAdmin()" class="nav-item">
        <a class="nav-link" routerLink="/locations" routerLinkActive="active">
          <i class="fas fa-fw fa-city"></i>
          <span>{{ 'location-plural' | translate }}</span></a>
      </li>
    </div>

    <!-- Divider -->
    <hr class="sidebar-divider d-none d-md-block">

    <!-- Sidebar Toggler (Sidebar) -->
    <div class="text-center d-none d-md-inline">
      <button class="rounded-circle border-0" id="sidebarToggle" (click)="changeSidebarToggled()"></button>
    </div>

  </ul>
  <!-- End of Sidebar -->

  <!-- Content Wrapper -->
  <div id="content-wrapper" class="d-flex flex-column">

    <!-- Main Content -->
    <div id="content">

      <!-- Topbar -->
      <nav class="navbar navbar-expand navbar-light bg-white sticky-top topbar shadow" [ngClass]="{'sidebar-lg': !isSidebarToggled, 'sidebar-sm': isSidebarToggled,
          'no-sidebar': !isSidebarVisible, 'with-sidebar': isSidebarVisible }">

        <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3"
          (click)="changeSidebarVisibility()">
          <i class="fa fa-bars"></i>
        </button>

        <app-topbar-logo></app-topbar-logo>

        <!-- Topbar Navbar -->
        <ul class="navbar-nav ml-auto">

          <!-- Nav Item - Search Dropdown (Visible Only XS) -->
          <li class="nav-item d-sm-none mr-auto ml-md-3 mb-2 mt-3 my-md-0 mw-100">
            <img style="height: 2.5rem" src="assets/images/qcloud-logo.png">
          </li>

          <li class="nav-item">
            <a class="nav-link" role="button" (click)="changeLanguage('pl')">
              <img class="img-profile rounded-circle pointer" [ngClass]="{'selected': checkLanguage('pl')}"
                src="assets/images/pl.svg" alt="PL">
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link" role="button" (click)="changeLanguage('en')">
              <img class="img-profile rounded-circle pointer" [ngClass]="{'selected': checkLanguage('en')}"
                src="assets/images/gb.svg" alt="EN">
            </a>
          </li>

          <div class="topbar-divider d-none d-sm-block"></div>

          <!-- Nav Item - User Information -->
          <li class="nav-item dropdown no-arrow">
            <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <span class="mr-2 d-none d-lg-inline text-gray-600 small">
                {{ username }}
              </span>
              <span class="mr-2 text-gray-600 small">
                <i class="fas fa-caret-down"></i>
              </span>
            </a>
            <!-- Dropdown - User Information -->
            <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
              <a class="dropdown-item" [routerLink]="['/profile/', userId]">
                <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-600"></i>
                {{ 'profile' | translate }}
              </a>
              <a class="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
                <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-danger"></i>
                {{ 'logout' | translate }}
              </a>
            </div>
          </li>

        </ul>

      </nav>
      <!-- End of Topbar -->

      <!-- Begin Page Content -->
      <div class="container-fluid navbar-margin">
        <router-outlet></router-outlet>
      </div>
      <!-- /.container-fluid -->

    </div>
    <!-- End of Main Content -->

    <!-- Footer -->
    <app-footer></app-footer>
    <!-- End of Footer -->

  </div>
  <!-- End of Content Wrapper -->

</div>
<!-- End of Page Wrapper -->

<!-- Logout Modal-->
<div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-hidden="true">
  <app-delete-modal [info]="'logout-info'" [confirm]="'logout'" (confirmed)="onLogoutConfirm($event)">
  </app-delete-modal>
</div>