<div class="text-container">
    <div class="text-wrapper-icon">
    </div>
    <div class="text-wrapper-service">
        <div [fittext]="true" [activateOnResize]="true" [modelToWatch]="service">
            {{ service }}
        </div>
    </div>
    <div class="text-wrapper-ticket">
        <div [fittext]="true" [activateOnResize]="true" [modelToWatch]="ticketNumber">
            {{ ticketNumber }}
        </div>
    </div>
</div>
<div class="rows-container">
    <div *ngFor="let row of rows" class="row-container" [ngStyle]="getRowHeightStyle()">
        <div class="text-wrapper-icon">
            <img style="max-width: 100%;max-height: 100%;height: 100%;object-fit: contain;" *ngIf="row.active"
                src="./assets/icon_selected.png">
        </div>
        <app-start-piece-bar-compatible class="text-wrapper-service" [active]="row.active" [content]="row.texts[0]" [rowStyle]="rowStyle"
            [rowHeight]="rowHeight">
        </app-start-piece-bar-compatible>
        <app-end-piece-bar-compatible class="text-wrapper-ticket" [active]="row.active" [content]="row.texts[1]" [rowStyle]="rowStyle"
            [rowHeight]="rowHeight">
        </app-end-piece-bar-compatible>
    </div>
</div>