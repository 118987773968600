<div class="rows-container">
    <div *ngFor="let row of rows;let i = index" class="row-container" [ngStyle]="getRowHeightStyle(i)">
        <div class="text-wrapper-icon">
            <img style="max-width: 100%;max-height: 100%;height: 100%;object-fit: contain;" *ngIf="row.active"
                src="./assets/icon_selected.png">
        </div>
        <app-start-piece-bar-compatible [active]="row.active" class="text-wrapper-service" [content]="row.texts[0]"
            [rowHeight]="rowHeight" [rowStyle]="rowStyle">
        </app-start-piece-bar-compatible>
        <app-middle-piece-bar-compatible [active]="row.active" class="text-wrapper-ticket" [content]="row.texts[1]"
            [rowStyle]="rowStyle">
        </app-middle-piece-bar-compatible>
        <app-end-piece-bar-compatible [active]="row.active" class="text-wrapper-desk" [content]="row.texts[2]"
            [rowStyle]="rowStyle" [rowHeight]="rowHeight">
        </app-end-piece-bar-compatible>
    </div>
</div>