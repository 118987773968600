import { Chart } from 'chart.js';     
     
//Plugin for center text
export function enableChartCenterText(){
    Chart.pluginService.register({
        beforeDraw: function(chart) {
          if (chart.config.options.elements.center) {
            
            let ctx = chart.chart.ctx;
      
            let centerConfig = chart.config.options.elements.center;
            let fontStyle = centerConfig.fontStyle || 'sans-serif';
            let txt = centerConfig.text;
            let color = centerConfig.color || '#000';
      
            let height = chart.chart.height;
            let fontSize = (height / 100).toFixed(2);
            ctx.font =  fontSize + "em " + fontStyle;
            ctx.fillStyle = color;
  
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            let centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
            let centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
                
            ctx.fillText(txt, centerX, centerY);
          }
        }
      });
}

