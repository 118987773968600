import { Injectable } from '@angular/core';
import { CategoryDisplayNameType } from 'projects/qcloud-models/device/category-display-name-type.enum';
import { DeviceConfigurationService } from './device-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor(private configurationService: DeviceConfigurationService) { }

  public getDeskName(deskId: number): string {
    let desks = this.configurationService.getConfiguration().desks;
    let desk = desks.filter(x => x.id == deskId);
    return desk[0].name;
  }

  public getDeskNumber(deskId: number): string {
    let desks = this.configurationService.getConfiguration().desks;
    let desk = desks.filter(x => x.id == deskId);
    return desk[0].deskNumber;
  }

  public getCategoryName(categoryId: number): string {
    let conf = this.configurationService.getConfiguration();
    let categories = this.configurationService.getConfiguration().locationCategories;
    let category = categories.filter(x => x.id == categoryId)[0];
    switch(conf.categoryDisplayNameType){
      case CategoryDisplayNameType.FullName:
        return category.fullName;
        break;
      case CategoryDisplayNameType.ShortName:
        return category.shortName;
        break;
      case CategoryDisplayNameType.Tag:
        return category.categoryTag;
        break;
    }
    return category.fullName;
  }

  public getDeskRoomNumber(deskId: number) {
    let desks = this.configurationService.getConfiguration().desks;
    let desk = desks.filter(x => x.id == deskId);
    return desk[0].roomNumber;
  }
}
