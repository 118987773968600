<div class="table-responsive">
    <table class="table table-bordered">
        <thead>
            <tr>
                <th>#</th>
                <th>{{ 'order' | translate }}</th>
                <th>{{ 'last-name' | translate }}</th>
                <th>{{ 'first-name' | translate }}</th>
                <th>{{ 'specialization' | translate }}</th>
                <th>{{ 'nearest-free-date' | translate }}</th>
                <th>{{ 'options' | translate }}</th>
            </tr>
        </thead>
        <tbody *ngIf="employees">
            <tr *ngFor="let employee of employees; let i = index; let j = index;">
                <td>{{ i + 1 }}</td>
                <td>
                    <button *ngIf="i != 0" class="btn btn-primary btn-sm btn-circle mr-1" (click)="changeOrder(i, -1)">
                        <i class="fas fa-arrow-up"></i>
                    </button>
                    <button *ngIf="(i + 1) != employees.length" class="btn btn-primary btn-sm btn-circle"
                        (click)="changeOrder(i, 1)">
                        <i class="fas fa-arrow-down"></i>
                    </button>
                </td>
                <td>{{ employee.firstName }}</td>
                <td>{{ employee.lastName }}</td>
                <td>
                    <span *ngIf="employee.specialisation">{{ employee.specialisation.name }}</span>
                    <span *ngIf="!employee.specialisation">{{ 'none' | translate }}</span>
                </td>
                <td>
                    <mat-form-field>
                        <input matInput readonly [ngxMatDatetimePicker]="picker"
                            [formControl]="dateCtrl[employee.order]" (ngModelChange)="submitDate(employee, false)">

                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #picker [showSeconds]="false"></ngx-mat-datetime-picker>
                    </mat-form-field>
                    <button (click)="submitDate(employee,true)" class="btn btn-danger btn-circle btn-sm mx-1 mb-1"
                        matTooltip="{{ 'delete' | translate }}" tooltipClass="tooltip-danger">
                        <i class="fas fa-times"></i>
                    </button>
                </td>
                <td>
                    <div class="row">
                        <button class="btn btn-info btn-circle btn-sm mx-1 mb-1"
                            (click)="router.navigate(['employee', employee.id])" matTooltip="{{ 'edit' | translate }}"
                            tooltipClass="tooltip-info">
                            <i class="fas fa-fw fa-edit"></i>
                        </button>
                        <button (click)="selectedEmployeeId = employee.id"
                            class="btn btn-danger btn-circle btn-sm mx-1 mb-1" matTooltip="{{ 'delete' | translate }}"
                            tooltipClass="tooltip-danger" data-toggle="modal" data-target="#deleteModal">
                            <i class="fas fa-fw fa-trash"></i>
                        </button>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div *ngIf="employees?.length > 0">
    <button type="submit" (click)="saveOrder()" class="btn btn-primary">{{'save-order' | translate}}</button>
</div>

<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-hidden="true">
    <app-delete-modal [info]="'delete-confirmation'" [confirm]="'delete'" (confirmed)="onDeleteConfirm($event)">
    </app-delete-modal>
</div>