import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { LocationData } from 'projects/qcloud-models/location/location.model';
import { take, takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';

@Component({
  selector: 'app-time-zone',
  templateUrl: './time-zone.component.html',
  styleUrls: ['./time-zone.component.css']
})
export class TimeZoneComponent implements OnInit {

  @Input() locationData : LocationData;
  timezones = [
    { val: "Dateline Standard Time", displayString: "(GMT-12:00) International Date Line West" },
    { val: "Samoa Standard Time", displayString: "(GMT-11:00) Midway Island, Samoa" },
    { val: "Hawaiian Standard Time", displayString: "(GMT-10:00) Hawaii" },
    { val: "Alaskan Standard Time", displayString: "(GMT-09:00) Alaska" },
    { val: "Pacific Standard Time", displayString: "(GMT-08:00) Pacific Time (US and Canada); Tijuana" },
    { val: "Mountain Standard Time", displayString: "(GMT-07:00) Mountain Time (US and Canada)" },
    { val: "Mountain Standard Time (Mexico)", displayString: "(GMT-07:00) Chihuahua, La Paz, Mazatlan" },
    { val: "US Mountain Standard Time", displayString: "(GMT-07:00) Arizona" },
    { val: "Central Standard Time", displayString: "(GMT-06:00) Central Time (US and Canada" },
    { val: "Canada Central Standard Time", displayString: "(GMT-06:00) Saskatchewan" },
    { val: "Central Standard Time (Mexico)", displayString: "(GMT-06:00) Guadalajara, Mexico City, Monterrey" },
    { val: "Central America Standard Time", displayString: "(GMT-06:00) Central America" },
    { val: "Eastern Standard Time", displayString: "(GMT-05:00) Eastern Time (US and Canada)" },
    { val: "US Eastern Standard Time", displayString: "(GMT-05:00) Indiana (East)" },
    { val: "SA Pacific Standard Time", displayString: "(GMT-05:00) Bogota, Lima, Quito" },
    { val: "Atlantic Standard Time", displayString: "(GMT-04:00) Atlantic Time (Canada)" },
    { val: "SA Western Standard Time", displayString: "(GMT-04:00) Caracas, La Paz" },
    { val: "Pacific SA Standard Time", displayString: "(GMT-04:00) Santiago" },
    { val: "Newfoundland Standard Time", displayString: "(GMT-03:30) Newfoundland and Labrador" },
    { val: "E. South America Standard Time", displayString: "(GMT-03:00) Brasilia" },
    { val: "SA Eastern Standard Time", displayString: "(GMT-03:00) Buenos Aires, Georgetown" },
    { val: "Greenland Standard Time", displayString: "(GMT-03:00) Greenland" },
    { val: "Mid-Atlantic Standard Time", displayString: "(GMT-02:00) Mid-Atlantic" },
    { val: "Azores Standard Time", displayString: "(GMT-01:00) Azores" },
    { val: "Cape Verde Standard Time", displayString: "(GMT-01:00) Cape Verde Islands" },
    { val: "GMT Standard Time", displayString: "(GMT) Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London" },
    { val: "Greenwich Standard Time", displayString: "(GMT) Casablanca, Monrovia" },
    { val: "Central Europe Standard Time", displayString: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague" },
    { val: "Central European Standard Time", displayString: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb" },
    { val: "Romance Standard Time", displayString: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris" },
    { val: "W. Europe Standard Time", displayString: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna" },
    { val: "W. Central Africa Standard Time", displayString: "(GMT+01:00) West Central Africa" },
    { val: "E. Europe Standard Time", displayString: "(GMT+02:00) Bucharest" },
    { val: "Egypt Standard Time", displayString: "(GMT+02:00) Cairo" },
    { val: "FLE Standard Time", displayString: "(GMT+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius" },
    { val: "GTB Standard Time", displayString: "(GMT+02:00) Athens, Istanbul, Minsk" },
    { val: "Israel Standard Time", displayString: "(GMT+02:00) Jerusalem" },
    { val: "South Africa Standard Time", displayString: "(GMT+02:00) Harare, Pretoria" },
    { val: "Russian Standard Time", displayString: "(GMT+03:00) Moscow, St. Petersburg, Volgograd" },
    { val: "Arab Standard Time", displayString: "(GMT+03:00) Kuwait, Riyadh" },
    { val: "E. Africa Standard Time", displayString: "(GMT+03:00) Nairobi" },
    { val: "Arabic Standard Time", displayString: "(GMT+03:00) Baghdad" },
    { val: "Iran Standard Time", displayString: "(GMT+03:30) Tehran" },
    { val: "Arabian Standard Time", displayString: "(GMT+04:00) Abu Dhabi, Muscat" },
    { val: "Caucasus Standard Time", displayString: "(GMT+04:00) Baku, Tbilisi, Yerevan" },
    { val: "Afghanistan Standard Time", displayString: "(GMT+04:30) Kabul" },
    { val: "Ekaterinburg Standard Time", displayString: "(GMT+05:00) Ekaterinburg" },
    { val: "West Asia Standard Time", displayString: "(GMT+05:00) Islamabad, Karachi, Tashkent" },
    { val: "India Standard Time", displayString: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi" },
    { val: "Nepal Standard Time", displayString: "(GMT+05:45) Kathmandu" },
    { val: "Central Asia Standard Time", displayString: "(GMT+06:00) Astana, Dhaka" },
    { val: "Sri Lanka Standard Time", displayString: "(GMT+06:00) Sri Jayawardenepura" },
    { val: "N. Central Asia Standard Time", displayString: "(GMT+06:00) Almaty, Novosibirsk" },
    { val: "Myanmar Standard Time", displayString: "(GMT+06:30) Yangon Rangoon" },
    { val: "SE Asia Standard Time", displayString: "(GMT+07:00) Bangkok, Hanoi, Jakarta" },
    { val: "North Asia Standard Time", displayString: "(GMT+07:00) Krasnoyarsk" },
    { val: "China Standard Time", displayString: "(GMT+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi" },
    { val: "Singapore Standard Time", displayString: "(GMT+08:00) Kuala Lumpur, Singapore" },
    { val: "Taipei Standard Time", displayString: "(GMT+08:00) Taipei" },
    { val: "W. Australia Standard Time", displayString: "(GMT+08:00) Perth" },
    { val: "North Asia East Standard Time", displayString: "(GMT+08:00) Irkutsk, Ulaanbaatar" },
    { val: "Korea Standard Time", displayString: "(GMT+09:00) Seoul" },
    { val: "Tokyo Standard Time", displayString: "(GMT+09:00) Osaka, Sapporo, Tokyo" },
    { val: "Yakutsk Standard Time", displayString: "(GMT+09:00) Yakutsk" },
    { val: "AUS Central Standard Time", displayString: "(GMT+09:30) Darwin" },
    { val: "Cen. Australia Standard Time", displayString: "(GMT+09:30) Adelaide" },
    { val: "AUS Eastern Standard Time", displayString: "(GMT+10:00) Canberra, Melbourne, Sydney" },
    { val: "E. Australia Standard Time", displayString: "(GMT+10:00) Brisbane" },
    { val: "Tasmania Standard Time", displayString: "(GMT+10:00) Hobart" },
    { val: "Vladivostok Standard Time", displayString: "(GMT+10:00) Vladivostok" },
    { val: "West Pacific Standard Time", displayString: "(GMT+10:00) Guam, Port Moresby" },
    { val: "Central Pacific Standard Time", displayString: "(GMT+11:00) Magadan, Solomon Islands, New Caledonia" },
    { val: "Fiji Standard Time", displayString: "(GMT+12:00) Fiji Islands, Kamchatka, Marshall Islands" },
    { val: "New Zealand Standard Time", displayString: "(GMT+12:00) Auckland, Wellington" },
    { val: "Tonga Standard Time", displayString: "(GMT+13:00) Nuku'alofa" }
  ];

  public timezoneMultiCtrl: UntypedFormControl;
  public timezoneMultiFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredTimezonesMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  protected _onDestroy = new Subject<void>();

  constructor() { } 

  ngOnInit(): void {

    this.timezoneMultiCtrl = new UntypedFormControl(new Array(this.locationData.timeZone));

    // load the initial timezones list
    this.filteredTimezonesMulti.next(this.timezones.slice());

    // listen for search field value changes
    this.timezoneMultiFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterdeskCategoriesMulti();
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected filterdeskCategoriesMulti() {
    // get the search keyword
    let search = this.timezoneMultiFilterCtrl.value;
    if (!search) {
      this.filteredTimezonesMulti.next(this.timezones.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the timezones
    this.filteredTimezonesMulti.next(
      this.timezones.filter(timezone => timezone.displayString.toLowerCase().indexOf(search) > -1)
    );
  }
}
