import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { Category } from 'projects/qcloud-models/category/category.model';
import { TranslateService } from '@ngx-translate/core';
import { AxxonIntelectIntegrationService } from 'projects/qcloud-rest-client/src/lib/axxon-intelect-integration.service';
import { AxxonIntelectIntegrationCategoryFacilityCode } from 'projects/qcloud-models/integration/axxon-intelect-integration';
import { ToastrService } from 'ngx-toastr';
import { AmmsIntegrationService } from 'projects/qcloud-rest-client/src/lib/amms-integration.service';
import { AmmsCode, CodeType } from 'projects/qcloud-models/integration/amms-code';
import { AmmsCategoryMap } from 'projects/qcloud-models/integration/amms-category-mapping';

@Component({
    selector: 'app-amms-integration',
    templateUrl: './amms.component.html',
    styleUrls: ['./amms.component.css']
})
export class AmmsIntegrationPageComponent implements OnInit {

    categories: any[];
    categoriesBindings: AmmsCategoryMap[];

    serviceCodes: AmmsCode[];
    unitCodes: AmmsCode[];
    resourceCodes: AmmsCode[];
    locationCodes: AmmsCode[];
    defaultCategoryId: number;

    serviceCodesForCat: AmmsCode[];
    unitCodesForCat: AmmsCode[];
    resourceCodesForCat: AmmsCode[];
    locationCodesForCat: AmmsCode[];

    constructor(private categoryService: CategoryService,
        private translate: TranslateService,
        private ammsIntegrationService: AmmsIntegrationService,
        private toastr: ToastrService) { }

    ngOnInit(): void {
        this.categoryService.getCategories((data) => {
            this.categories = data;
        },
            () => { });
        this.ammsIntegrationService.getCodesByType(CodeType.Location, data => {
            this.locationCodes = data;
            this.locationCodesForCat = Object.assign([], data);
        }, () => { })
        this.ammsIntegrationService.getCodesByType(CodeType.Resource, data => {
            this.resourceCodes = data;
            this.resourceCodesForCat = Object.assign([], data);
        }, () => { })
        this.ammsIntegrationService.getCodesByType(CodeType.Unit, data => {
            this.unitCodes = data;
            this.unitCodesForCat = Object.assign([], data);
        }, () => { })
        this.ammsIntegrationService.getCodesByType(CodeType.Service, data => {
            this.serviceCodes = data;
            this.serviceCodesForCat = Object.assign([], data);
        }, () => { })
        this.ammsIntegrationService.getMaps(data => {
            this.categoriesBindings = data;
        }, () => { })
        this.ammsIntegrationService.getDefaultCategoryId(data => {
            this.defaultCategoryId = data;
        }, () => { });
    }

    public saveCodes(codes: AmmsCode[], codeType: number) {
        this.ammsIntegrationService.saveCodeByType(codeType, codes, () => {
            this.refreshCodeByType(codeType);
            this.translate.get('amms-code-success').subscribe((msg: string) => {
                this.toastr.success("", msg);
            });
        }, () => {
            this.translate.get('amms-code-error').subscribe((error: string) => {
                this.translate.get('repeat').subscribe((res: string) => {
                    this.toastr.error(res, error);
                });
            });
        })
    }

    private refreshCodeByType(codeType: number) {
        if (CodeType.Location == codeType) {
            this.ammsIntegrationService.getCodesByType(CodeType.Location, data => {
                this.locationCodes = data;
                this.serviceCodesForCat = Object.assign([], data);
            }, () => { })
        }
        else if (CodeType.Resource == codeType) {
            this.ammsIntegrationService.getCodesByType(CodeType.Resource, data => {
                this.resourceCodes = data;
                this.serviceCodesForCat = Object.assign([], data);
            }, () => { })
        }
        else if (CodeType.Unit == codeType) {
            this.ammsIntegrationService.getCodesByType(CodeType.Unit, data => {
                this.unitCodes = data;
                this.serviceCodesForCat = Object.assign([], data);
            }, () => { })
        }
        else if (CodeType.Service == codeType) {
            this.ammsIntegrationService.getCodesByType(CodeType.Service, data => {
                this.serviceCodes = data;
                this.serviceCodesForCat = Object.assign([], data);
            }, () => { })
        }

    }

    public remove(index: number, codes: AmmsCode[]) {
        codes.splice(index, 1);
    }

    public addNew(codes: AmmsCode[], codeType: number) {
        var newCode = new AmmsCode()
        newCode.codeType = codeType;
        codes.push(newCode);
    }

    public addNewMap() {
        this.categoriesBindings.push(new AmmsCategoryMap());
    }

    public saveMaps() {
        this.ammsIntegrationService.saveMaps(this.categoriesBindings, () => {
            this.translate.get('amms-maps-success').subscribe((msg: string) => {
                this.toastr.success("", msg);
            });
            this.ammsIntegrationService.getMaps(data => {
                this.categoriesBindings = data;

            }, () => { })
        }, () => {
            this.translate.get('amms-maps-error').subscribe((error: string) => {
                this.translate.get('repeat').subscribe((res: string) => {
                    this.toastr.success(res, error);
                });
            });
        });
    }

    public removeMap(index: number) {
        this.categoriesBindings.splice(index, 1);
    }

    public setDefaultCategoryId() {
        this.ammsIntegrationService.setDefaultCategoryId(this.defaultCategoryId, () => {
            this.translate.get('amms-default-success').subscribe((msg: string) => {
                this.toastr.success("", msg);

            });
        }, () => {
            this.translate.get('amms-default-error').subscribe((error: string) => {
                this.translate.get('repeat').subscribe((res: string) => {
                    this.toastr.success(res, error);
                });
            });
        });
    }
}
