<div class="card shadow mb-4" *ngIf="displayModule">
    <div class="card-header py-3">
        <div class="row">
            <h5 class="col-8 m-0 font-weight-bold text-primary">{{'export-csv' | translate}}</h5>
        </div>
    </div>
    <div class="card-body">
        <app-date-range-picker [startDate]="startDate"
            [endDate]="endDate" (rangeToEmit)="getRange($event)">
        </app-date-range-picker>
        <button type="button" class="btn btn-success" (click)="exportFile()">
            {{ 'export-file' | translate }}
        </button>
</div>