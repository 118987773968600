import { Component, OnInit } from '@angular/core';
import { Category } from 'projects/qcloud-models/category/category.model';
import { LocationActivityBlock } from 'projects/qcloud-models/location/location-activity-block.model';
import { ReservationVacationService } from '../reservation-vacation.service';

@Component({
  selector: 'app-reservation-vacation-button',
  templateUrl: './reservation-vacation-button.component.html',
  styleUrls: ['./reservation-vacation-button.component.css']
})
export class ReservationVacationButtonComponent implements OnInit {
 isActive: boolean;
  constructor(private reservationVacationService: ReservationVacationService) { }

  ngOnInit(): void {
    this.isActive=false;
    this.reservationVacationService.choosenCategory.subscribe(x=>{
      if(x){
        this.isActive=true;
      }else{
        this.isActive=false;
      }
    })
  }

  buttonClick(){
    this.reservationVacationService.updateAdd(true);
  }
}
