<div *ngIf="translations.length > 2">
    <button type="button" class="btn btn-primary btn-circle btn-sm mr-1" [disabled]="startIndex == 0"
        (click)="emitFilteredTranslations(startIndex - 1)"
        matTooltip="{{ 'previous' | translate }}" tooltipClass="tooltip-primary">
        <i class="fas fa-arrow-left"></i>
    </button>
    <button type="button" class="btn btn-primary btn-circle btn-sm" [disabled]="startIndex == translations.length - 2"
        (click)="emitFilteredTranslations(startIndex + 1)"
        matTooltip="{{ 'next' | translate }}" tooltipClass="tooltip-primary">
        <i class="fas fa-arrow-right"></i>
    </button>
</div>
