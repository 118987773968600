import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { BaseComponent } from '../../base-component/base.component';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.css']
})
export class TextComponent extends BaseComponent implements OnInit {
  paramText: string;
  constructor(protected elementRef: ElementRef, protected renderer: Renderer2) {
    super(elementRef, renderer);
  }

  ngOnInit(): void {
  }

  startComponent(): void {
  }

  passParameters(params: any): void {
    this.paramText = params.text;
  }

  initMock(): void {

  }
}
