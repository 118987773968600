import { Component, OnInit } from '@angular/core';
import { QcloudConsoleService } from '../../qcloud-console.service';

@Component({
  selector: 'lib-display-desk',
  templateUrl: './display-desk.component.html',
  styleUrls: ['./display-desk.component.css']
})
export class DisplayDeskComponent implements OnInit {

  deskFullName: string;

  constructor(private consoleService: QcloudConsoleService) {
  }

  ngOnInit(): void {
    this.deskFullName = this.consoleService.desk.fullName;
  }

}
