import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColorService {

  scheduleCalendarColors = new Array();

  constructor() { }

  getScheduleCalendarColor(i: number) {
    return this.scheduleCalendarColors[i];
  }

  setScheduleCalendarColors(colors) {
    this.scheduleCalendarColors = colors;
  }

  generateRandomColors(length: number, brighterPercent: number) {
    let i = 0;
    let colors = new Array();
    while (i < length) {
      let num = Math.floor(Math.random()*parseInt("ffffff", 16));
      while (num < parseInt("100000", 16)) {
        num = Math.floor(Math.random()*parseInt("ffffff", 16));
      }
      let c = "#" + num.toString(16);
      let color = {primary: c, secondary: this.increaseBrightness(c, brighterPercent)};
      colors.push(color);
      ++i;
    }
    return colors;
  }

  increaseBrightness(hex: string, percent: number): string {

    let r = parseInt(hex.substr(1, 2), 16),
      g = parseInt(hex.substr(3, 2), 16),
      b = parseInt(hex.substr(5, 2), 16);

    return '#' +
      Math.floor(r + (256 - r) * percent / 100).toString(16) +
      Math.floor(g + (256 - g) * percent / 100).toString(16) +
      Math.floor(b + (256 - b) * percent / 100).toString(16);
  }
}
