import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Category } from 'projects/qcloud-models/category/category.model';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { LocationData } from 'projects/qcloud-models/location/location.model';
import { FileType } from 'projects/qcloud-models/report/file-type.enum';
import { ReportTemplate } from 'projects/qcloud-models/report/report-template';
import { QCloudElement, ReportTemplateParameter } from 'projects/qcloud-models/report/report-template-parameter';
import { TicketReportParams } from 'projects/qcloud-models/report/ticket-report-params';
import { User } from 'projects/qcloud-models/user/user.model';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { DeskService } from 'projects/qcloud-rest-client/src/lib/desk.service';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { ReportService } from 'projects/qcloud-rest-client/src/lib/report.service';
import { UserService } from 'projects/qcloud-rest-client/src/lib/user.service';
import { AuthService } from '../../../../../../qcloud-rest-client/src/lib/auth/auth.service';

@Component({
  selector: 'app-report-statistics',
  templateUrl: './report-statistics.component.html',
  styleUrls: ['./report-statistics.component.css']
})
export class ReportStatisticsComponent implements OnInit {

  ticketReportParams: TicketReportParams = new TicketReportParams();
  FileType = FileType;
  get generatingReport(): boolean {
    return this.countStartedGeneratingReport > 0 && this.countStartedGeneratingReport <= this.limitCountStartedGeneratingReport;
  }
  limitCountStartedGeneratingReport: number = 0;
  _countStartedGeneratingReport: number = 0;
  set countStartedGeneratingReport(value: number) {
    if (this.countStartedGeneratingReport == this.limitCountStartedGeneratingReport) {
      value = 0;
    }
    this._countStartedGeneratingReport = value;
  }
  get countStartedGeneratingReport(): number {
    return this._countStartedGeneratingReport;
  }

  reportTemplates: ReportTemplate[];
  reportTemplatesoOriginal: ReportTemplate[];
  reportTemplateParameters: ReportTemplateParameter[];

  categories: Category[];
  desks: Desk[];
  users: User[];
  categoriesOriginal: Category[];
  desksOriginal: Desk[];
  usersOriginal: User[];

  categoriesToTemplate: QCloudElement[];
  desksToTemplate: QCloudElement[];

  locations: LocationData[];
  protected initialLoco: LocationData[];
  public locationMultiCtrl: UntypedFormControl = new UntypedFormControl([]);
  reportLocations: string = 'report-locations'
  isLockedForEditing: boolean = false;
  enabledByLocationIds: number[] = [];
  mainRoleLocationIds: number[] = [];

  constructor(private reportService: ReportService, private categoryService: CategoryService,
    private userService: UserService, private deskService: DeskService, public locationService: LocationService,
    private toastr: ToastrService, private translate: TranslateService, private authService: AuthService) { }

  ngOnInit(): void {
    this.mainRoleLocationIds = this.authService.getTokenLocationIds();
    this.getLocations();
    this.getTemplates();
  }

  getTemplates() {
    this.reportService.getTemplates().subscribe(
      res => {
        this.reportTemplates = new Array<ReportTemplate>();
        this.reportTemplatesoOriginal = new Array<ReportTemplate>();
        res.forEach(element => {
          let reportTemplate = JSON.parse(element.reportTemplateJson) as ReportTemplate;
          this.reportTemplates.push(reportTemplate);
          reportTemplate.id = element.id;
        });
        this.reportTemplatesoOriginal = JSON.parse(JSON.stringify(this.reportTemplates));
        this.reportTemplateParameters = [];
      },
      err => { }
    );
  }

  getCategories() {
    this.categoryService.getCategoriesFilterByLocationIds(this.mainRoleLocationIds,
      (data) => {
        this.categories = data;
        this.categories.forEach(cat => {
          cat.fullName = this.getLocationName(cat.locationId) + "/" + cat.fullName;
        });
        this.categoriesOriginal = JSON.parse(JSON.stringify(data));

        this.categoriesToTemplate = this.categories.map(category => new QCloudElement(category.id, category.fullName));
      },
      () => { }
    );
  }
  getDesks() {


    this.deskService.getDesks(
      (data) => {

        this.desks = data;
        this.desks.forEach(desk => {
          desk.fullName = this.getLocationName(desk.locationId) + "/" + desk.fullName;
        });
        this.desksOriginal = JSON.parse(JSON.stringify(data));
        this.desksToTemplate = data.map(desk => new QCloudElement(desk.id, desk.fullName));
      },
      () => { }
    );
  }
  getUsers() {


    this.userService.getUsers(
      (data) => {
        this.users = data;
        this.users.forEach(user => {
          user.firstName = this.getLocationNameForUser(user.mainRole.locationIds) + "/" + user.firstName;
        });
        this.usersOriginal = JSON.parse(JSON.stringify(data));
      },
      () => { }
    );
  }
  reportTemplateParameterElementsOriginal: ReportTemplateParameter[] = [];
  assignTemplateParameters1(x: ReportTemplateParameter[]) {
    this.updateListOfScopes();

    this.reportTemplateParameters = x;
  }
  assignTemplateParameters(x: ReportTemplateParameter[], id: number) {

    this.updateListOfScopes();
    this.reportTemplateParameterElementsOriginal = this.reportTemplatesoOriginal.find(x => x.id === id).reportTemplateParameters;
    this.reportTemplateParameters = x;
    this.notifyUpdateReportParametersView();
  }

  requestToGenerateReport() {
    if (this.locationMultiCtrl) {
      var parse = this.locationMultiCtrl.value as LocationData[];
      this.limitCountStartedGeneratingReport = parse.length;
      this.countStartedGeneratingReport = 1;
      this.prepareDataForGeenratingReport();
      parse.forEach(element => {
        this.generateReport(element.id);
      });
    }
  }
  private prepareDataForGeenratingReport() {
    this.ticketReportParams.templateParameters = this.reportTemplateParameters;
    this.ticketReportParams.reportBeginTime = new Date(
      this.ticketReportParams.reportBeginTime.getFullYear(), this.ticketReportParams.reportBeginTime.getMonth(),
      this.ticketReportParams.reportBeginTime.getDate(), 12, 0);
    this.ticketReportParams.reportEndTime = new Date(
      this.ticketReportParams.reportEndTime.getFullYear(), this.ticketReportParams.reportEndTime.getMonth(),
      this.ticketReportParams.reportEndTime.getDate(), 12, 0);
    this.ticketReportParams.categories = this.categoriesToTemplate;
    this.ticketReportParams.desks = this.desksToTemplate;
  }
  generateReport(locationId: number) {
    this.reportService.generateReportByChoosenLocationId(this.ticketReportParams, locationId).subscribe(
      res => {
        this.countStartedGeneratingReport++;
        var downloadURL = window.URL.createObjectURL(res);
        var link = document.createElement('a');
        link.href = downloadURL;

        let fileName = "report";
        switch (this.ticketReportParams.fileType) {
          case FileType.Pdf:
            fileName += ".pdf";
            break;
          case FileType.Excel:
            fileName += ".xlsx";
            break;
          case FileType.Csv:
            fileName += ".csv"
            break;
          case FileType.Xml:
            fileName += ".xml"
            break;
          case FileType.Json:
            fileName += ".json"
            break;
          default:
            break;
        }
        link.download = fileName;
        link.click();
      },
      err => {
        this.countStartedGeneratingReport++;
        this.translate.get('generate-report-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  getRange(range: Date[]) {
    this.ticketReportParams.reportBeginTime = range[0];
    this.ticketReportParams.reportEndTime = range[1];
  }
  getLocations() {
    this.locationService.getAll().subscribe(
      locations => {
        this.locations = [];
        for (var i = 0; i < locations.length; i++) {
          var newLocation = locations[i];
          var isUserHaveAccessToLocation = this.mainRoleLocationIds.some(locationId => locationId == newLocation.id);
          if (isUserHaveAccessToLocation) {
            this.locations.push(newLocation);
          }

        }
        this.initialLoco = this.locations;
        if (this.locations.length == 1) {
          this.locationMultiCtrl = new UntypedFormControl(this.locations);
        }

        this.getUsers();
        this.getDesks();
        this.getCategories();
      }
    );
  }

  isFormReadyForSubmit() {
    var choosenLocations = this.locationMultiCtrl.value as LocationData[];
    return choosenLocations && choosenLocations.length != 0 && this.ticketReportParams.reportBeginTime && this.ticketReportParams.reportEndTime && this.reportTemplateParameters && this.reportTemplateParameters.length != 0;
  }
  locationChanged(event) {
    this.updateListOfScopes();
    this.notifyUpdateReportParametersView();
  }

  updateViewReportTemplateParameters: boolean = false;

  updateListOfScopes() {
    var choosenLocations: LocationData[] = this.locationMultiCtrl.value as LocationData[];
    if (!choosenLocations || choosenLocations.length == 0) {
      this.categories = [];
      this.desks = [];
      this.users = [];
    } else {
      var choosenLocation = this.locationMultiCtrl.value as LocationData[];
      var choosenLocationIds = choosenLocation.map(cl => cl.id);
      var filterCategoriesByChoosenLocationIds = this.categoriesOriginal.filter(co => choosenLocationIds.includes(co.locationId));

      this.categories = filterCategoriesByChoosenLocationIds;

      var filterDesksByChoosenLocationIds = this.desksOriginal.filter(co => choosenLocationIds.includes(co.locationId));
      this.desks = filterDesksByChoosenLocationIds;
      var filterUsersByChoosenLocationIds: User[] = [];
      for (let i = 0; i < this.usersOriginal.length; i++) {
        var userOriginal = this.usersOriginal[i];
        let userAccessToLocationIds: number[] = [];
        for (let j = 0; j < userOriginal.qcloudRoles.length; j++) {
          userAccessToLocationIds = userAccessToLocationIds.concat(userOriginal.qcloudRoles[j].locationIds);
        }
        let isUserAssignedToOneOfChoosenLocations = false;
        for (let j = 0; j < choosenLocationIds.length; j++) {
          isUserAssignedToOneOfChoosenLocations = isUserAssignedToOneOfChoosenLocations || userAccessToLocationIds.includes(choosenLocationIds[j]);
        }
        if (isUserAssignedToOneOfChoosenLocations) {
          filterUsersByChoosenLocationIds.push(userOriginal);
        }
      }

      this.users = filterUsersByChoosenLocationIds;
    }
  }

  getLocationName(locationId: number) {
    let name = "";
    let location = this.locations.find(l => l.id == locationId);
    name = location?.locationName ?? "";
    return name;
  }

  getLocationNameForUser(userLocations: number[]) {
    var choosenLocationIds = this.mainRoleLocationIds;
    let name = "";
    var locationPreviewId = userLocations.find(userLocationId => choosenLocationIds.find(choosenLocationId => choosenLocationId === userLocationId));
    let locationPreview = this.locations.find(x => x.id === locationPreviewId);
    name = locationPreview?.locationName ?? "";
    return name;
  }
  notifyUpdateReportParametersView() {
    this.updateViewReportTemplateParameters = !this.updateViewReportTemplateParameters;
  }
}
