import { Component, OnInit, Input } from '@angular/core';
import { Translation } from 'projects/qcloud-models/translation/translation.model';
import { Language } from 'projects/qcloud-models/translation/language.model';
import { CategoryTranslation } from 'projects/qcloud-models/translation/category-translation.model';
import { Category } from 'projects/qcloud-models/category/category.model';
import { TranslationService } from 'projects/qcloud-rest-client/src/lib/translation.service';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { TranslationConfigurationService } from 'projects/qcloud-web/src/app/shared/translation-configuration.service';

@Component({
  selector: 'app-category-translation',
  templateUrl: './category-translation.component.html',
  styleUrls: ['./category-translation.component.css',
  '../languages-and-translations.component.css']
})
export class CategoryTranslationComponent implements OnInit {

  categoryTranslations: Array<CategoryTranslation[]>;
  @Input() translations: Translation[];
  @Input() languages: Language[];
  @Input() locationLocaleMain: string;
  startIndex: number;
  categories: Category[];
  isCollapsed: boolean = false;
  formSubmitted: boolean = false;

  constructor(
    private service: TranslationService,
    private categoryService: CategoryService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private authService: AuthService,
    private translationConfigurationService: TranslationConfigurationService
    ) { }

  ngOnInit(): void {
    this.categoryTranslations = new Array<CategoryTranslation[]>(this.translations.length);
    this.translationConfigurationService.translationLanguages.subscribe(translations => {
      this.translations = translations;
      this.getCategoriesAndTranslations();
    });
    this.getCategoriesAndTranslations();
  }

  getCategoriesAndTranslations() {
    this.categoryService.getCategories((data)=>{
      this.categories = data;
      for (let i = 0; i < this.translations.length; i++) {
        this.categoryTranslations[i] = new Array<CategoryTranslation>(this.categories.length);
      }
      this.service.getCategoryTranslation().subscribe(
        res => {
          for (let j = 0; j < this.categories.length; j++) {
            for (let i = 0; i < this.translations.length; i++) {
              let cT = res.find(cT => cT.translationId == this.translations[i].id && cT.categoryId == this.categories[j].id);
              if (cT) {
                this.categoryTranslations[i][j] = cT;
              } else {
                cT = new CategoryTranslation();
                cT.translationId = this.translations[i].id;
                cT.categoryId = this.categories[j].id;
                cT.locationId = this.authService.getLocationId();
                this.categoryTranslations[i][j] = cT;
              }
            }
          }
        }
      );
    },
    ()=>{}
    );
  }

  getLocaleName(locale: string) {
    return this.languages.find(languageOption => languageOption.locale == locale).name;
  }

  deleteLocaleMain(translations: Translation[]) {
    return translations.filter(translation => translation.locale != this.locationLocaleMain);
  }

  onSubmit() {
    this.formSubmitted = true;
    let mergedCategoryTranslations: CategoryTranslation[] = new Array<CategoryTranslation>();
    this.categoryTranslations.forEach(categoryTranslation => {
      mergedCategoryTranslations = mergedCategoryTranslations.concat(categoryTranslation);
    });
    this.service.setCategoryTranslation(mergedCategoryTranslations).subscribe(
      res => {
        this.formSubmitted = false;
        this.translate.get('category-translation-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('category-translation-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  getFilteredTranslations(startIndex: number) {
    this.startIndex = startIndex;
  }

  isOnPage() {
    return this.translations.slice(this.startIndex, this.startIndex + 2);
  }

}
