import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Category } from 'projects/qcloud-models/category/category.model';
import { timeout } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { CategoryGroup } from 'projects/qcloud-models/category/category-group.model';
import { AxxonIntelectIntegrationCategoryFacilityCode } from 'projects/qcloud-models/integration/axxon-intelect-integration';

const DEFAULT_TIMEOUT = 3000;

@Injectable({
  providedIn: 'root'
})
export class AxxonIntelectIntegrationService {

  appBase: string;

  constructor(private http: HttpClient, private authService: AuthService) { }

  public setAppBase(appBase: string) {
    this.appBase = appBase;
  }

  getAxxon(callback: (data: AxxonIntelectIntegrationCategoryFacilityCode[]) => any, onError: () => any) {
    this.http.get(this.appBase +
      `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/axxon`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  saveAxxon(ar: AxxonIntelectIntegrationCategoryFacilityCode[], callback: (data: AxxonIntelectIntegrationCategoryFacilityCode[]) => any, onError: () => any) {
    this.http.post(this.appBase +
      `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/axxon`, ar)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  deleteAxxon(id: number, callback: (data: AxxonIntelectIntegrationCategoryFacilityCode[]) => any, onError: () => any) {
    this.http.delete(this.appBase +
      `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/axxon/${id}`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }
}
