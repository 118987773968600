<div class="card shadow mb-4">
    <div class="card-header py-3">
        <div class="row">
            <h5 *ngIf="!isEditForm" class="col-9 m-0 font-weight-bold text-primary">
                {{ 'add-system-display-group' | translate }}
            </h5>
            <h5 *ngIf="isEditForm" class="col-9 m-0 font-weight-bold text-primary">
                {{ 'edit-system-display-group' | translate }}
            </h5>
        </div>
    </div>
    <div *ngIf="displayCategoryGroup" style="width: 20%;" class="card-body">
        <div class="form-group mt-2">
            <div class="input-group">
                <div class="font-weight-bold">{{ 'group-name' | translate }}</div>
            </div>
            <div class="input-group">
                <input class="form-control" type="text" [(ngModel)]="displayCategoryGroup.name">
            </div>
        </div>
        <div class="form-group mt-2">
            <div class="input-group">
                <div class="font-weight-bold">{{ 'font-color' | translate }}</div>
            </div>
            <div style="display: flex;">
                <ngx-colors ngx-colors-trigger [(ngModel)]="displayCategoryGroup.fontColorHex"
                    [format]="'hex'"></ngx-colors>
                <input class="form-control" type="text" [(ngModel)]="displayCategoryGroup.fontColorHex">
            </div>
        </div>
        <div class="form-group mt-2">
            <div class="input-group">
                <div class="font-weight-bold">{{ 'border-color' | translate }}</div>
            </div>
            <div style="display: flex;">

                <ngx-colors ngx-colors-trigger [(ngModel)]="displayCategoryGroup.borderColorHex"
                    [format]="'hex'"></ngx-colors>
                <input class="form-control" type="text" [(ngModel)]="displayCategoryGroup.borderColorHex">
            </div>
        </div>

        <div class="form-group mt-2">
            <div class="input-group">
                <div class="font-weight-bold">{{ 'border-size' | translate }}</div>
            </div>
            <div class="input-group">
                <input name="borderSize" #borderSize="ngModel" [(ngModel)]="displayCategoryGroup.borderSize"
                    type="number" class="form-control" required>
            </div>
        </div>

        <div class="form-group mt-2">
            <div class="input-group">
                <div class="font-weight-bold">{{ 'border-radius' | translate }}</div>
            </div>
            <div class="input-group">
                <input name="borderRadius" #borderRadius="ngModel" [(ngModel)]="displayCategoryGroup.borderRadius"
                    type="number" class="form-control" required>
            </div>
        </div>
        <div class="form-group mt-2">
            <div class="input-group">
                <div class="font-weight-bold">{{ 'on-hover-color' | translate }}</div>
            </div>
            <div style="display: flex;">
                <ngx-colors ngx-colors-trigger [(ngModel)]="displayCategoryGroup.onActiveBackgroundColorHex"
                    [format]="'hex'"></ngx-colors>
                <input class="form-control" type="text" [(ngModel)]="displayCategoryGroup.onActiveBackgroundColorHex">
            </div>
        </div>
        <div class="form-group mt-2">
            <div class="input-group">
                <div class="font-weight-bold">{{ 'background-type' | translate }}</div>
            </div>
            <div class="input-group">
                <mat-form-field class="ml-2">
                    <mat-select name="backgroundType" #backgroundType="ngModel"
                        [(ngModel)]="displayCategoryGroup.backgroundType">
                        <mat-option [value]="undefined">None</mat-option>
                        <mat-option [value]="1">Color</mat-option>
                        <mat-option [value]="2">Image</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="form-group mt-2" *ngIf="displayCategoryGroup.backgroundType === 1">
            <div class="input-group">
                <div class="font-weight-bold">{{ 'background-color' | translate }}</div>
            </div>
            <div style="display: flex;">
                <ngx-colors ngx-colors-trigger [(ngModel)]="displayCategoryGroup.backgroundColorHex"
                    [format]="'hex'"></ngx-colors>
                <input class="form-control" type="text" [(ngModel)]="displayCategoryGroup.backgroundColorHex">
            </div>
        </div>

        <div class="form-group mt-2" *ngIf="displayCategoryGroup.backgroundType === 2">
            <div class="input-group">
                <div class="font-weight-bold">{{ 'background-image' | translate }}</div>
            </div>
            <div>
                <button class="btn btn-primary btn-sm" data-toggle="modal" data-target="#fileManagerComponentbgModal">
                    {{ 'select-image' | translate }}
                </button>
                {{displayCategoryGroup.backgroundImgPath}}
                <div class="modal fade" id="fileManagerComponentbgModal" tabindex="-1" role="dialog"
                    aria-labelledby="modalLabel" aria-hidden="true">
                    <app-file-popup [selectedFilePath]="displayCategoryGroup.backgroundImgPath"
                        (filePathToEmit)="displayCategoryGroup.backgroundImgPath = $event"></app-file-popup>
                </div>
            </div>
        </div>
        <div class="form-group mt-2">
            <div class="input-group">
                <div class="font-weight-bold">{{ 'device-background-image' | translate }}</div>
            </div>
            <div>
                <button class="btn btn-primary btn-sm" data-toggle="modal" data-target="#fileManagerComponentDevicebgModal">
                    {{ 'select-image' | translate }}
                </button>
                {{displayCategoryGroup.backgroundDeviceImgPath}}
                <div class="modal fade" id="fileManagerComponentDevicebgModal" tabindex="-1" role="dialog"
                    aria-labelledby="modalLabel" aria-hidden="true">
                    <app-file-popup [selectedFilePath]="displayCategoryGroup.backgroundDeviceImgPath"
                        (filePathToEmit)="displayCategoryGroup.backgroundDeviceImgPath = $event"></app-file-popup>
                </div>
            </div>
        </div>
        <button class="btn btn-primary m-1" type="submit" (click)="onSubmit()">
            <!-- <span class="spinner-border spinner-border-sm my-1" role="status"
                aria-hidden="true"></span> -->
            {{ 'save' | translate }}
        </button>
    </div>

</div>