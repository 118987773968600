import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../../../../../qcloud-rest-client/src/lib/auth/auth.service";
import { QcloudRoleService } from "../../../../../../qcloud-rest-client/src/lib/role/qcloud-role.service";
import { NormalizeCharactersPipe } from "../../../shared/normalize-characters.pipe";
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { FormNoteVm } from "../../../../../../qcloud-models/console/note-dynamic-form/FormNoteVm";
import { NoteQuestionType } from "../../../../../../qcloud-models/note/note-question-type.enum";
import { NoteQuestion, NoteQuestionTable } from "../../../../../../qcloud-models/note/note-questions";
import { TicketNoteTemplateVm } from "../../../../../../qcloud-models/console/TicketNoteTemplateVm";
import { TicketNoteConfigurationService } from "../../../../../../qcloud-rest-client/src/lib/note/ticket-note-configuration.service";
import { MatSelectChange } from "@angular/material/select";

@Component({
  selector: 'app-note-template-configuration',
  templateUrl: './note-template-configuration.component.html',
  styleUrls: ['./note-template-configuration.component.css'],
  providers: [NormalizeCharactersPipe]
})
export class NoteTemplateConfigurationComponent implements OnInit {
  isEditForm: boolean;
  formSubmitted: boolean = false;

  formNote: FormNoteVm = new FormNoteVm();
  ticketNoteTemplateVm: TicketNoteTemplateVm = new TicketNoteTemplateVm();

  NoteQuestionTypeEnum = NoteQuestionType;
  constructor(public authService: AuthService, private translate: TranslateService, private toastr: ToastrService,
    private route: ActivatedRoute, private router: Router, public locationService: LocationService,
    public ldapRoleService: QcloudRoleService, private ticketNoteConfigurationService: TicketNoteConfigurationService) { }

  ngOnInit(): void {
    let noteTemplateId: number;
    this.route.params.subscribe(params => {
      noteTemplateId = params['id'];
      if (noteTemplateId) {
        this.isEditForm = true;
        this.getFormNoteVm(noteTemplateId);
      } else {
        this.isEditForm = false;
        this.initializeTicketNoteTemplate();
      }
    });
  }

  initializeTicketNoteTemplate() {
    this.ticketNoteTemplateVm = new TicketNoteTemplateVm();
    this.ticketNoteTemplateVm.noteQuestions = [];
    this.ticketNoteTemplateVm.id = -1;
  }

  onSubmit() {
    if (this.isEditForm) {
      this.editNoteTemplate();
    } else {
      this.saveQuestions();
    }
  }
  editNoteTemplate() {
    this.handleOptionQuestion();
    this.ticketNoteConfigurationService.updateTicketNoteTemplate(this.ticketNoteTemplateVm,
      () => {
        this.formSubmitted = true;
        var successMessage = "note.template-update-sucessfull"
        this.translate.get(successMessage).subscribe((res: string) => {
          this.toastr.success(res);
        });
        this.router.navigate(['/notesConfiguration']);
      },
      err => {
        var errorMessage = "note.template-update-error"
        this.formSubmitted = false;
        this.translate.get(errorMessage).subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  getFormNoteVm(noteTemplateId: number) {
    this.ticketNoteConfigurationService.getNoteTicketTemplate(noteTemplateId,
      (ticketNoteTempalte) => {
        ticketNoteTempalte.noteQuestions.forEach(noteQ => {
          this.initializeQuestionType(noteQ);
        });

        ticketNoteTempalte.noteQuestions = ticketNoteTempalte.noteQuestions.sort((a, b) =>
          a.order - b.order
        );

        this.ticketNoteTemplateVm = ticketNoteTempalte;
      },
      () => { });
  }

  addQuestion(): void {
    var noteQuestion = new NoteQuestion();
    noteQuestion.id = -1;
    noteQuestion.questionType = NoteQuestionType.Text;
    noteQuestion.visible = true;
    noteQuestion.order = this.ticketNoteTemplateVm.noteQuestions.length;
    this.ticketNoteTemplateVm.noteQuestions.push(noteQuestion);
  }

  changeOrder(index: number, direction: number) {
    let swapTarget = index + direction;
    var tmpFirstElement = this.ticketNoteTemplateVm.noteQuestions[index];
    this.ticketNoteTemplateVm.noteQuestions[swapTarget].order = index;
    this.ticketNoteTemplateVm.noteQuestions[index] = this.ticketNoteTemplateVm.noteQuestions[swapTarget];
    tmpFirstElement.order = swapTarget;
    this.ticketNoteTemplateVm.noteQuestions[swapTarget] = tmpFirstElement;
  }

  deleteQuestion(index: number) {
    let question = this.ticketNoteTemplateVm.noteQuestions[index];
    this.ticketNoteTemplateVm.noteQuestions.splice(index, 1);
  }

  saveQuestions() {
    this.formSubmitted = true;
    this.handleOptionQuestion();
    this.ticketNoteConfigurationService.addTicketNoteTemplate(this.ticketNoteTemplateVm,
      (result) => {
        this.translate.get('note.template-saved-sucessfull').subscribe((res: string) => {
          this.toastr.success(res);
          this.formSubmitted = false;
          this.router.navigate(['/notesConfiguration']);
        });
      },
      () => {
        this.translate.get('note.template-saved-error').subscribe((res: string) => {
          this.toastr.error(res);
          this.formSubmitted = false;
        });

      });
  }

  trackByFn(index, item) {
    return index;
  }
  delimiterPattern: string = ";_;";
  changeQuestionType(question: NoteQuestion) {
    if (!question.question) {
      question.question = "";
    }
    if (question.questionType == this.NoteQuestionTypeEnum.Option || question.questionType == this.NoteQuestionTypeEnum.VisibleOption) {
      var questions = question.question.split(this.delimiterPattern);
      questions.forEach(q => {
        question.questions.push(q);
      });
      for (var k = question.questions.length; k < 2; k++) {
        question.questions.push("");
      }
    }
    else {
      question.question = "";
      question.questions = [];
    }
  }
  initializeQuestionType(question: NoteQuestion) {
    if (!question.question) {
      question.question = "";
    }
    if (!question.questions) {
      question.questions = [];
    }
    if (question.questionType == this.NoteQuestionTypeEnum.Option || question.questionType == this.NoteQuestionTypeEnum.VisibleOption) {
      var questions = question.question.split(this.delimiterPattern);
      for (var k = 0; k < 2 && k < questions.length; k++) {
        question.questions.push(questions[k]);
      }
      for (var k = question.questions.length; k < 2; k++) {
        question.questions.push("");
      }
    }
    else {
      question.questions = [];
    }
  }
  changeQuestionType1(event: MatSelectChange, question: NoteQuestion) {
    if (!question.question) {
      question.question = "";
    }
    if (!question.questions) {
      question.questions = [];
    }
    if (event.value == this.NoteQuestionTypeEnum.Option || event.value == this.NoteQuestionTypeEnum.VisibleOption) {
      var questions = question.question.split(this.delimiterPattern);
      questions.forEach(q => {
        question.questions.push(q);
      });
      for (var k = question.questions.length; k < 2; k++) {
        question.questions.push("");
      }
    }
    else {
      question.question = "";
      question.questions = [];
    }
  }
  handleOptionQuestion() {
    this.ticketNoteTemplateVm.noteQuestions.forEach(noteQ => {
      if (noteQ.questions.length > 0) {
        noteQ.question = "";
        noteQ.questions.forEach(noteQuestion => {
          noteQuestion = noteQuestion.replace(this.delimiterPattern, ";;");
          noteQ.question = noteQ.question + noteQuestion + this.delimiterPattern;
        }
        );
      }
    });

  }

}
