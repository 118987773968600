import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { CustomisationService } from 'projects/qcloud-device/src/app/services/customisation.service'
import { Customisation } from 'projects/qcloud-models/device/customisation.model';
import { BaseComponent } from 'projects/qcloud-device/src/app/shared/base-component/base.component';

@Component({
  selector: 'app-customisation-bar',
  templateUrl: './customisation-bar.component.html',
  styleUrls: ['./customisation-bar.component.css']
})
export class CustomisationBarComponent extends BaseComponent implements OnInit {

  public customisation: Customisation;

  constructor(protected elementRef: ElementRef, protected renderer: Renderer2,
    private customisationService: CustomisationService) {
    super(elementRef, renderer);
  }

  ngOnInit(): void {
    this.customisation = this.customisationService.getCustomisation();
  }

  getTextWrapperWidth() {
    let textWrapperWidth = 90 - +this.customisation.showClock * 8 - +this.customisation.showDate * 12;
    return { 'width': textWrapperWidth + '%' };
  }

}
