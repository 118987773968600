import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ChangePassword } from 'projects/qcloud-models/user/change-password.model';
import { UserService } from 'projects/qcloud-rest-client/src/lib/user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  @Input() userId: string;
  changePassword: ChangePassword;
  formSubmitted: boolean = false;

  constructor(private userService: UserService, private toastr: ToastrService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.changePassword = new ChangePassword(this.userId);
  }

  checkPasswords(confirmPassword: string) {
    return confirmPassword != this.changePassword.newPassword;
  }

  onSubmit() {
    this.userService.changePassword(this.changePassword).subscribe(
      res => {
        this.formSubmitted = false;
        this.translate.get('change-password-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('incorrect-password').subscribe((error: string) => {
          this.toastr.error(error);
        });
      }
    );
  }

}
