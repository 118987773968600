import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { LdapConfiguration } from '../../../../../../qcloud-models/ldap/ldap-configuration';
import { LdapConfigurationService } from '../../../../../../qcloud-rest-client/src/lib/ldap-configuration.service';

@Component({
  selector: 'app-ldap-intergation',
  templateUrl: './ldap-intergation.component.html',
  styleUrls: ['./ldap-intergation.component.css']
})
export class LDAPIntegrationComponent implements OnInit {

  formSubmitted: boolean = false;
  testingConnection: boolean = false;
  serverLDAPConfig: LdapConfiguration;


  constructor(public locationService: LocationService, private toastr: ToastrService, private translate: TranslateService,
    private ldapConfigurationService: LdapConfigurationService) { }

  ngOnInit(): void {
    this.getServerLdapConfiguration();
  }

  private getServerLdapConfiguration() {
    this.ldapConfigurationService.getLdapConfiguration().subscribe(
      res => {
        this.serverLDAPConfig = res;
      }
    );
  }

  onSubmit() {
    this.formSubmitted = true;
    this.ldapConfigurationService.updateLdapConfiguration(this.serverLDAPConfig).subscribe(
      res => {
        this.serverLDAPConfig = res;
        this.translate.get('save-ldap-configuration-success').subscribe((res: string) => {
          this.toastr.success(res);
          this.formSubmitted = false;
        });
      }
    );
  }
  testConnection() {
    this.testingConnection = true;
    this.ldapConfigurationService.testConnectionLdapConfiguration(this.serverLDAPConfig).subscribe(
      res => {
        if (res) {
          this.translate.get('test-connection-success').subscribe((res: string) => {
            this.toastr.success(res);
          });
        } else {

          this.translate.get('test-connection-error').subscribe((res: string) => {
            this.toastr.error(res);
          });
        }
        this.testingConnection = false;
      }
    );
  }
  checkConstraints(): boolean {
    return this.locationService.getLocationConstraints()?.isLDAPEnabled;
  }
}
