import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'lib-expand-menu-button',
  templateUrl: './expand-menu-button.component.html',
  styleUrls: ['./expand-menu-button.component.css',  './../base-button/base-button.component.css']
})
export class ExpandMenuButtonComponent implements OnInit {
  @Input() public tooltipTitle: string;
	@ViewChild('button') public button: ElementRef;
  isMenuExpanded: boolean;

  constructor() {}

  ngOnInit(): void {
    this.isMenuExpanded = false;
  }

  public hideOrShowMenu() {
    this.isMenuExpanded = !this.isMenuExpanded;
  }
}
