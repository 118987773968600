import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LocationData } from 'projects/qcloud-models/location/location.model';
import { Login } from 'projects/qcloud-models/user/login.model';
import { TokenResponse } from 'projects/qcloud-models/user/token-response';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { QCloudRestClientService } from 'projects/qcloud-rest-client/src/public-api';

@Component({
  selector: 'app-select-location',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.css']
})
export class SelectLocationComponent implements OnInit {

  locations: LocationData[];
  selectedLocationId: number;

  constructor(private service: LocationService, public authService: AuthService,
    public qcloudRestClientService: QCloudRestClientService, private toastr: ToastrService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.getAllLocations();;
    this.qcloudRestClientService.loginForm = new Login();
  }

  getAllLocations() {
    this.service.getAll().subscribe(
      locations => {
        var mainRoleLocationIds = this.authService.getTokenLocationIds();
        var filteredLocations: LocationData[] = [];
        for (let j = 0; j < mainRoleLocationIds.length; j++) {
          for (let i = 0; i < locations.length; i++) {
            if (mainRoleLocationIds[j] == locations[i].id) {
                filteredLocations.push(locations[i]);
            }
          }
        }
        this.locations = filteredLocations;
      }
    );
  }

  changeLocation() {
    this.qcloudRestClientService.loginForm.username = this.authService.getTokenUsername();
    this.qcloudRestClientService.getAuthTokenForUser(this.callback, this.onError);
    this.qcloudRestClientService.loginForm = new Login();
  }

  private callback = (data: TokenResponse) => {
    this.authService.setLocationId(this.selectedLocationId);
    this.service.getLocationData(this.selectedLocationId).subscribe(
      locationData => {
        this.service.locationData = locationData
      }
    );
  }

  private onError = () => {
    this.translate.get('change-location-error').subscribe((error: string) => {
      this.translate.get('repeat').subscribe((res: string) => {
        this.toastr.error(res, error);
      });
    });
  }

}
