<div class="form-group">
    <div class="font-weight-bold">Przydzielone stanowisko</div>
    <mat-form-field class="ml-2">
        <mat-select name="deskId" #deskId="ngModel" [(ngModel)]="device.deskId">
            <mat-option *ngFor="let desk of desks" [value]="desk.id">{{ desk.name }} {{desk.deskNumber}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>

<app-served-categories *ngIf="categoryMultiCtrl" [categories]="categories" [categoryMultiCtrl]="categoryMultiCtrl"
    [title]="servedCategories" [noSelectedCategories]="noSelectedCategories" [displayCategories]="true">
</app-served-categories>
