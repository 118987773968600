<div class="card shadow mb-4">
    <mat-accordion>
        <mat-expansion-panel (opened)="isCollapsed = false"
                             (closed)="isCollapsed = true"
                             hideToggle="true"
                             [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
                <h5 class="col-10 m-2 font-weight-bold text-primary">{{ 'category-translation' | translate }}</h5>
            </mat-panel-title>
            <mat-panel-description>
                    <button type="button" class="btn btn-primary btn-circle btn-sm" (click)="isCollapsed = !isCollapsed"
                        [attr.aria-expanded]="!isCollapsed" aria-controls="collapseCategory"
                        [ngClass]="{'clicked': isCollapsed}">
                        <i class="fas fa-angle-down"></i>
                    </button>
            </mat-panel-description>
          </mat-expansion-panel-header>
    <div>
        <div *ngIf="categoryTranslations && categories" class="card-body">
            <div class="text-right">
                <app-pagination [translations]="translations"
                    (filteredTranslationsEmitter)="getFilteredTranslations($event)">
                </app-pagination>
            </div>
            <div class="row">
                <div class="col-4">

                    <p class="font-weight-bold">{{ getLocaleName(locationLocaleMain) | translate }}</p>
                    <p *ngIf="categories.length == 0">{{ 'no-categories' | translate }}</p>

                    <div *ngFor="let category of categories">

                        <app-category-button class="mx-1 mb-1" [categoryTag]="category.categoryTag"></app-category-button>

                        <div class="form-group">
                            {{ 'category-name' | translate }}
                            <div class="input-group">
                                <input disabled [(ngModel)]="category.fullName" class="form-control">
                            </div>
                        </div>

                        <div class="form-group">
                            {{ 'short-name' | translate }}
                            <div class="input-group">
                                <input disabled [(ngModel)]="category.shortName" class="form-control">
                            </div>
                        </div>

                        <div class="form-group">
                            {{ 'blocking-category-activity-message' | translate }}
                            <div class="input-group">
                                <input disabled [(ngModel)]="category.blockingMessageActivity" class="form-control">
                            </div>
                        </div>

                        <div class="form-group">
                            {{ 'manual-blocking-category-message' | translate }}
                            <div class="input-group">
                                <input disabled [(ngModel)]="category.blockingMessageManual" class="form-control">
                            </div>
                        </div>

                        <div *ngIf="category.printedTicketAdditionalText" class="form-group">
                            {{ 'additional-text-ticket' | translate }}
                            <div class="input-group">
                                <input disabled [(ngModel)]="category.printedTicketAdditionalText" class="form-control">
                            </div>
                        </div>

                        <hr>
                    </div>

                </div>
                <div class="col-4" *ngFor="let translation of deleteLocaleMain(isOnPage()); let i = index">
                    <form *ngIf="categoryTranslations[startIndex + i]" #form="ngForm" autocomplete="off">

                        <p class="font-weight-bold">{{ getLocaleName(translation.locale) | translate }}</p>

                        <div *ngFor="let categoryTranslation of categoryTranslations[startIndex + i]; let j = index">
                            <div *ngIf="categoryTranslation">

                                <app-category-button class="mx-1 mb-1" [categoryTag]="categories[j].categoryTag"></app-category-button>

                                <div class="form-group">
                                    {{ 'category-name' | translate }}
                                    <div class="input-group">
                                        <input name="fullName{{i}}{{j}}" #fullName="ngModel"
                                            [(ngModel)]="categoryTranslation.fullName" class="form-control">
                                    </div>
                                </div>

                                <div class="form-group">
                                    {{ 'short-name' | translate }}
                                    <div class="input-group">
                                        <input name="shortName{{i}}{{j}}" #shortName="ngModel"
                                            [(ngModel)]="categoryTranslation.shortName" class="form-control">
                                    </div>
                                </div>

                                <div class="form-group">
                                    {{ 'blocking-category-activity-message' | translate }}
                                    <div class="input-group">
                                        <input name="blockingMessageActivity{{i}}{{j}}" #blockingMessageActivity="ngModel"
                                            [(ngModel)]="categoryTranslation.blockingMessageActivity" class="form-control">
                                    </div>
                                </div>

                                <div class="form-group">
                                    {{ 'manual-blocking-category-message' | translate }}
                                    <div class="input-group">
                                        <input name="blockingMessageManual{{i}}{{j}}" #blockingMessageManual="ngModel"
                                        [(ngModel)]="categoryTranslation.blockingMessageManual" class="form-control">
                                    </div>
                                </div>

                                <div *ngIf="categories[j].printedTicketAdditionalText" class="form-group">
                                    {{ 'additional-text-ticket' | translate }}
                                    <div class="input-group">
                                        <input name="printedTicketAdditionalText{{i}}{{j}}" #printedTicketAdditionalText="ngModel"
                                            [(ngModel)]="categoryTranslation.printedTicketAdditionalText" class="form-control">
                                    </div>
                                </div>

                                <hr>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
            <div class="form-group row">
                <app-submit-button (click)="onSubmit()" type="submit" [loading]="formSubmitted"
                    [text]="'save-category-translations' | translate">
                </app-submit-button>
            </div>
        </div>

    </div>
</mat-expansion-panel>
</mat-accordion>

</div>
