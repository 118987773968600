import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Category } from 'projects/qcloud-models/category/category.model';
import { ToastrService } from 'ngx-toastr';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { CategoryGroup } from 'projects/qcloud-models/category/category-group.model';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { DragAndDropListElement } from '../dnd-categories/drag-and-drop-component/drag-and-drop-list-element.model';
import { CdkDragDrop, CdkDragEnd, CdkDragStart, copyArrayItem, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ListType } from '../dnd-categories/drag-and-drop-component/list-type.enum';

@Component({
  selector: 'app-dnd-group-categories',
  templateUrl: './dnd-group-categories.component.html',
  styleUrls: ['./dnd-group-categories.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DndGroupCategoriesComponent implements OnInit {

  @Input() categories: Category[];
  @Input() initialCategoryGroups: CategoryGroup[];
  maxDepth: number = 2;
  sourceBuilderTools: DragAndDropListElement[] = [];
  targetBuilderTools: DragAndDropListElement[] = [];
  @Output() categoryGroupsToEmit = new EventEmitter();
  groupName: string;
  categoryGroups: CategoryGroup[];
  locationId: number;

  constructor(private toastr: ToastrService, private categoryService: CategoryService, private authService: AuthService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.locationId = +this.authService.getLocationId();
    this.targetBuilderTools = this.convertToDnd(this.initialCategoryGroups, 0);
    this.createSourceBuilderTools();
  }

  createSourceBuilderTools() {
    this.sourceBuilderTools = [];
    this.categories.forEach(category => {
      var item = DragAndDropListElement.CreateElementForSourceList(category);
      if (!this.targetBuilderTools.some(e => e.categoryId === item.categoryId)) {
        this.sourceBuilderTools.push(item);
      }
    });
    this.translate.get('group').subscribe((group: string) => {
      var item = DragAndDropListElement.CreateGroup(group);
      this.sourceBuilderTools.unshift(item);
    })
  }

  sendCategoryTree() {
    this.categoryGroups = [];
    this.convertToCategoryGroups(this.targetBuilderTools, this.categoryGroups, 0);
    this.categoryGroupsToEmit.emit(this.categoryGroups);
  }

  convertToCategoryGroups(targetBuilderTools: any[], categoryGroups: CategoryGroup[], groupIndex: number) {
    console.log("targetBuilderTools: ", targetBuilderTools);
    for (var j = 0; j < targetBuilderTools.length; j++) {
      var element = targetBuilderTools[j];
      if (element.isGroup) {
        let categoryGroup = new CategoryGroup(element.id, element.name, this.locationId);
        categoryGroups.push(categoryGroup);
        let idx = categoryGroups.indexOf(categoryGroup);

        var children: DragAndDropListElement[] = [];
        for (var i = j + 1; i <= j + element.scopeSize; i++) {
          children.push(targetBuilderTools[i]);
        }
        j = j + element.scopeSize;
        this.convertToCategoryGroups(children, categoryGroups, idx);
      } else {
        if (!element.isEmptyCell && !element.isGroup) {
          categoryGroups[groupIndex].groupCategoriesIds.push(element.categoryId);
        }
      }
    }
  }

  convertChildrenToDnd(childrenId: number[], depth: number = 0): DragAndDropListElement[] {
    var subGroup: DragAndDropListElement[] = [];
    for (let j = 0; j < childrenId.length; j++) {
      var childId = childrenId[j];
      var category = this.categories.filter(x => x.id == childId)[0];
      var item = DragAndDropListElement.CreateElementForSourceList(category, depth);
      subGroup.push(item);
    }
    return subGroup;
  }
  convertToDnd(root: CategoryGroup[], depth: number = 0): DragAndDropListElement[] {
    var sourceList: DragAndDropListElement[] = [];
    for (let i = 0; i < root.length; i++) {
      var element: CategoryGroup = root[i];
      var groupScope: DragAndDropListElement[] = [];

      var itemGroup = DragAndDropListElement.CreateGroup(element.name, depth, element.id);
      var subGroup: DragAndDropListElement[] = this.convertChildrenToDnd(element.groupCategoriesIds, itemGroup.depth + 1);

      groupScope = groupScope.concat(subGroup);

      var itemEmpty = DragAndDropListElement.CreateEmptyItem(itemGroup.depth + 1);
      groupScope.push(itemEmpty);
      itemGroup.scopeSize = groupScope.length;
      groupScope.unshift(itemGroup);

      sourceList = sourceList.concat(groupScope);
    }
    return sourceList;
  }

  ListTypeEnum = ListType;
  drop(event: CdkDragDrop<DragAndDropListElement[]>) {
    if (event.container.id === ListType.Template) {
      return;
    }
    if (event.previousContainer.id === event.container.id) {
      if (event.currentIndex === event.previousIndex && event.isPointerOverContainer) {
        return;
      }
      var elementsToMove: DragAndDropListElement[] = [];
      var parentElement = event.previousContainer.data[event.previousIndex];
      var groupSizeEnd = event.previousIndex + parentElement.scopeSize;
      if (parentElement.isGroup && event.currentIndex >= event.previousIndex && event.currentIndex <= groupSizeEnd && event.isPointerOverContainer) { // hidding for nesting themselves
        return;
      }
      var quantityTransferingElements = parentElement.scopeSize === 0 ? 1 : 1 + parentElement.scopeSize;
      if (!this.isMovedIntoGroup(event) && event.isPointerOverContainer) {
        this.translate.get('group-error').subscribe((error: string) => {
          this.translate.get('tree-depth-error').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
        return;
      }
      event = this.updateBorders_while_removing_elementIntoTargetBuilderTools(event, quantityTransferingElements); // 1
      event = this.defineDepthsBeforeMoveElements(event); // 2

      if (!event.isPointerOverContainer) {
        for (var i = event.previousIndex; i <= groupSizeEnd; i++) {
          var removedItems = event.previousContainer.data.splice(event.previousIndex, 1);
          for (var k = 0; k < removedItems.length; k++) {
            if (!removedItems[k].isGroup && !removedItems[k].isEmptyCell) {
              this.sourceBuilderTools.push(removedItems[k]);
            }
          }
        }
      } else {
        var containerCloneArray: DragAndDropListElement[] = [];
        event.container.data.forEach(val => containerCloneArray.push(val.clone()));
        this.moveItems(event);
        event = this.updateBorders_while_inserting_elementIntoTargetBuilderTools(event, quantityTransferingElements, containerCloneArray);
      }
    } else {
      var itemToMove = event.previousContainer.data[event.previousIndex].clone();
      var elementsToMove: DragAndDropListElement[] = [];
      elementsToMove.push(itemToMove);
      if (itemToMove.isGroup) {
        var emptyItem: DragAndDropListElement = DragAndDropListElement.CreateEmptyItem();
        elementsToMove.push(emptyItem);
      }
      elementsToMove = this.defineDepths(event, elementsToMove);
      if (!this.isDepthAfterMovingElementUnderMaxDepth(elementsToMove[0])) {
        this.translate.get('group-error').subscribe((error: string) => {
          this.translate.get('tree-depth-error').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
        return;
      }
      if (!this.isDepthAfterMovingElementUnderMinDepth(elementsToMove[0])) {
        this.translate.get('category-can-only-be-added-to-group').subscribe((res: string) => {
          this.toastr.error(res);
        });
        return;
      }

      event = this.updateBorders_while_inserting_element(event, elementsToMove.length);
      for (var i = elementsToMove.length - 1; i >= 0; i--) {
        copyArrayItem(
          elementsToMove,
          event.container.data,
          i,
          event.currentIndex
        );
        this.removeElementAferCopy(event);
      }
    }
    this.sendCategoryTree();
  }

  isDepthAfterMovingElementUnderMaxDepth(itemToMove: DragAndDropListElement): boolean {
    if ((itemToMove.isGroup && itemToMove.depth >= this.maxDepth - 1) || (itemToMove.depth > this.maxDepth)) {
      return false;
    }
    return true;
  }

  isDepthAfterMovingElementUnderMinDepth(itemToMove: DragAndDropListElement): boolean {
    var minDepth: number = 0;
    if (!itemToMove.isGroup && itemToMove.depth === minDepth) {
      return false;
    }
    return true;
  }

  updateBorders_while_removing_elementIntoTargetBuilderTools(event: CdkDragDrop<DragAndDropListElement[]>, quantityRemovedElements: number): CdkDragDrop<DragAndDropListElement[]> {
    var index: number = event.previousIndex;
    for (var i = index - 1; i >= 0; i--) {
      var item = event.previousContainer.data[i];
      var itemZoneFutureEnd = i + item.scopeSize + 1;
      if (item.isGroup && itemZoneFutureEnd > index) {
        this.targetBuilderTools[i].scopeSize -= quantityRemovedElements;
      }
    }
    return event;
  }

  updateBorders_while_inserting_elementIntoTargetBuilderTools(event: CdkDragDrop<DragAndDropListElement[]>, quantityAddedElements: number, containerCloneArray: DragAndDropListElement[]): CdkDragDrop<DragAndDropListElement[]> {
    var index: number = event.currentIndex;
    for (var i = index - 1; i >= 0; i--) {
      var item = event.container.data[i];
      var itemZoneFutureEnd = i + item.scopeSize + 1;
      var previousElement = containerCloneArray[event.previousIndex];
      if (item.isGroup && itemZoneFutureEnd >= index && item.id != previousElement.id) {
        var newScopeSize = this.targetBuilderTools[i].scopeSize + quantityAddedElements;
        this.targetBuilderTools[i].scopeSize = newScopeSize;
      }
    }
    return event;
  }

  updateBorders_while_inserting_element(event: CdkDragDrop<DragAndDropListElement[]>, quantityAddedElements: number): CdkDragDrop<DragAndDropListElement[]> {
    var index: number = event.currentIndex;
    for (var i = index - 1; i >= 0; i--) {
      var item = event.container.data[i];
      var itemZoneFutureEnd = i + item.scopeSize + 1;
      if (item.isGroup && itemZoneFutureEnd > index) {
        event.container.data[i].scopeSize += quantityAddedElements;
      }
    }
    return event;
  }
  defineDepthsBeforeMoveElements(event: CdkDragDrop<DragAndDropListElement[]>): CdkDragDrop<DragAndDropListElement[]> {
    var isDepthChanged = false;
    var containerCloneArray: DragAndDropListElement[] = [];
    event.container.data.forEach(val => containerCloneArray.push(val.clone()));
    for (var i = 0; i < containerCloneArray.length; i++) {
      var item = containerCloneArray[i];
      var parentElement = event.previousContainer.data[event.previousIndex];
      if (item.isGroup && item.id != parentElement.id) {
        var startBorder = i;
        var endBorder = item.scopeSize + i;
        if (startBorder <= event.currentIndex && event.currentIndex <= endBorder) {
          var endBorder = event.previousIndex + parentElement.scopeSize;
          for (var j = event.previousIndex; j <= endBorder; j++) {
            var previousItem = event.previousContainer.data[j];
            if (previousItem.isGroup) {
              previousItem.depth = item.depth + 1;
              isDepthChanged = true;
            } else if (!previousItem.isGroup && !previousItem.isEmptyCell) {
              previousItem.depth = item.depth + 1;
              isDepthChanged = true;
            } else if (previousItem.isEmptyCell) {
              previousItem.depth = item.depth + 2;
              isDepthChanged = true;
            }
          }
        }
      }
    }
    if (!isDepthChanged || event.currentIndex === 0 || event.currentIndex + 1 === event.container.data.length) {
      var parentElement = event.previousContainer.data[event.previousIndex];
      var endBorder = event.previousIndex + parentElement.scopeSize;
      for (var j = event.previousIndex; j <= endBorder; j++) {
        var previousItem = event.previousContainer.data[j];
        if (previousItem.isGroup) {
          previousItem.depth = 0;
          isDepthChanged = true;
        } else if (!previousItem.isGroup && !previousItem.isEmptyCell) {
          previousItem.depth = 0;
          isDepthChanged = true;
        } else if (previousItem.isEmptyCell) {
          previousItem.depth = 1;
          isDepthChanged = true;
        }
      }
    }
    return event;
  }

  moveItems(event: CdkDragDrop<DragAndDropListElement[]>) {
    var parentElement = event.previousContainer.data[event.previousIndex]
    var endBorder = event.previousIndex + parentElement.scopeSize;
    if (event.previousIndex < event.currentIndex) {
      for (var i = event.previousIndex; i <= endBorder; i++) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      }
    } else {
      for (var i = endBorder; i >= event.previousIndex; i--) {
        moveItemInArray(event.container.data, endBorder, event.currentIndex);
      }
    }
  }

  defineDepths(event: CdkDragDrop<DragAndDropListElement[]>, elementsToMove: DragAndDropListElement[]): DragAndDropListElement[] {
    for (var i = 0; i < event.container.data.length; i++) {
      var item = event.container.data[i];
      if (item.isGroup) {
        var startBorder = i;
        var endBorder = item.scopeSize + i;
        if (startBorder < event.currentIndex && event.currentIndex <= endBorder) {
          elementsToMove.forEach(e => {
            if (e.isGroup) {
              e.depth = item.depth + 1;
            } else if (!e.isGroup && !e.isEmptyCell) {
              e.depth = item.depth + 1;
            } else if (e.isEmptyCell) {
              e.depth = item.depth + 2;
            }
          });
        }
      }
    }
    return elementsToMove;
  }


  dragStart(event: CdkDragStart<DragAndDropListElement[]>, item: DragAndDropListElement) {
    var dropContainerList = event.source.dropContainer.data;
    var previousIndex = -1;
    for (var i = 0; i < dropContainerList.length; i++) {
      if (dropContainerList[i].id === item.id) {
        previousIndex = i;
        break;
      }
    }
    for (var i = previousIndex; i < previousIndex + item.scopeSize + 1; i++) {
      event.source.dropContainer.data[i].isDragged = true;
    }
  }
  dragEnd(event: CdkDragEnd<DragAndDropListElement[]>, item: DragAndDropListElement) {
    var dropContainerList = event.source.dropContainer.data;
    var previousIndex = -1;
    for (var i = 0; i < dropContainerList.length; i++) {
      if (dropContainerList[i].id === item.id) {
        previousIndex = i;
        break;
      }
    }
    for (var i = previousIndex; i < previousIndex + item.scopeSize + 1; i++) {
      event.source.dropContainer.data[i].isDragged = false;
    }
  }


  castling(array: DragAndDropListElement[], startIndex: number, endIndex: number) {
    for (var i = startIndex; i < endIndex; i++) {
      if (array[i].isGroup) {
        this.castling(array, i + 1, i + array[i].scopeSize);
        i = i + array[i].scopeSize;
      } else {
        var itemToMove = array[i];
        array.splice(i, 1);
        array.splice(startIndex, 0, itemToMove);
      }
    }
  }

  sortRegularItem(array: DragAndDropListElement[], startIndex: number, endIndex: number): DragAndDropListElement[] {
    var quantityNotSorted: number = 0;
    for (var i = startIndex; i <= endIndex; i++) {
      var item = array[i];
      if (item.isGroup) {
        var elementsBeforeSortingPart = this.cloneArray(array, 0, startIndex);
        var elementsAfterSortingPart = this.cloneArray(array, i, array.length);
        var children = this.cloneArray(array, startIndex, i);
        var sortedChildren = children.sort((a, b) => {
          return a.tag.localeCompare(b.tag);
        }
        );
        array = elementsBeforeSortingPart.concat(sortedChildren).concat(elementsAfterSortingPart);
        array = this.sortRegularItem(array, i + 1, i + item.scopeSize);
        i = i + item.scopeSize;
        startIndex = i + item.scopeSize;
        quantityNotSorted = 0;
      } else {
        quantityNotSorted++;
      }

      if (item.isEmptyCell && quantityNotSorted > 1) {
        var elementsBeforeSortingPart = this.cloneArray(array, 0, startIndex);
        var elementsAfterSortingPart = this.cloneArray(array, i, array.length);
        var children = this.cloneArray(array, i - quantityNotSorted + 1, i);
        var sortedChildren = children.sort((a, b) => {
          return a.tag.localeCompare(b.tag);
        }
        );
        array = elementsBeforeSortingPart.concat(sortedChildren).concat(elementsAfterSortingPart);
        quantityNotSorted = 0;
      }
    }
    return array;
  }

  cloneArray(array: DragAndDropListElement[], startIndex: number, endIndex: number) {
    var clone: DragAndDropListElement[] = [];
    for (var i = startIndex; i < endIndex; i++) {
      clone.push(array[i]);
    }
    return clone;
  }

  sortCategories(targetList: DragAndDropListElement[]) {
    this.castling(targetList, 0, targetList.length);
    this.targetBuilderTools = this.sortRegularItem(targetList, 0, targetList.length - 1);
  }

  moveAllCategories() {
    for (let i = 1; i < this.sourceBuilderTools.length; i++) {
      this.targetBuilderTools.push(this.sourceBuilderTools[i])
    }
    this.sendCategoryTree();
  }

  isMovedIntoGroup(event: CdkDragDrop<DragAndDropListElement[]>): boolean {
    var isInGroup = false;
    for (var i = event.currentIndex; i >= 0; i--) {
      var element = event.container.data[i];
      var elementScopeSizeEnd = i + element.scopeSize;
      var elementScopeSizeStart = i;
      if (element.isGroup && event.previousIndex > elementScopeSizeStart && event.previousIndex < elementScopeSizeEnd) {
        if (element.isGroup && event.currentIndex > elementScopeSizeStart && event.currentIndex < elementScopeSizeEnd) {
          isInGroup = true;
        }
      } else {
        if (element.isGroup && event.currentIndex > elementScopeSizeStart && event.currentIndex <= elementScopeSizeEnd) {
          isInGroup = true;
        }
      }
    }
    return isInGroup;
  }

  removeElementAferCopy(event: CdkDragDrop<DragAndDropListElement[]>) {
    var previousItem = event.previousContainer.data[event.previousIndex];
    if (!previousItem.isGroup) {
      event.previousContainer.data.splice(event.previousIndex, 1)
    }
  }

  setGroupName(index: number, groupName: string) {
    if (groupName) {
      this.targetBuilderTools[index].name = groupName;
    } else {
      this.translate.get('group').subscribe((res: string) => this.targetBuilderTools[index].name = res);
    }
    this.sendCategoryTree();
    this.setEditMode(this.targetBuilderTools[index]);
  }

  getGroupName(item: DragAndDropListElement) {
    this.setEditMode(item);
  }
  setEditMode(item: DragAndDropListElement) {
    item.isEditMode = !item.isEditMode;
  }


  onDeleteConfirm(confirmed: boolean) {
    if (confirmed) {
      this.deleteAllGroups();
    }
  }

  deleteAllGroups() {
    this.targetBuilderTools = [];
    this.createSourceBuilderTools();
  }
}
