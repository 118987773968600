<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
    <h6 class="m-0 font-weight-bold text-primary">{{ 'average-waiting-service-time' | translate }}</h6>
</div>
<div class="card-body">
    <div class="chart-pie pt-4 pb-2">
        <canvas id="averageWaitingServiceTimePieChart"></canvas>
    </div>
    <div class="mt-4 text-center small">
        <span class="mr-2 no-wrap">
            <i class="fas fa-circle text-primary"></i> {{ 'average-waiting-time' | translate }}
        </span>
        <span class="mr-2 no-wrap">
            <i class="fas fa-circle text-success"></i> {{ 'average-service-time' | translate }}
        </span>
    </div>
</div>
