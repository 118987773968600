export class EmployeeHolidayTime {
    public id: number;
    public locationId: number;
    public systemId: number;
    public workerId: number;
    public startDate: any;
    public endDate: any;

    constructor(locationId: number, systemId: number) {
        this.locationId = locationId;
        this.systemId = systemId;
        this.workerId = 0;
        this.startDate = new Date();
        this.endDate = new Date();
    }
}