import { Category } from "../category/category.model";
import { NoteAnswer } from "./note-answer";
import { NoteQuestionType } from "./note-question-type.enum";
import { NoteQuestion } from "./note-questions";

export class NoteQuestionAnswer {
    public id: number;
    public identityQuestion: boolean;
    public isRequired: boolean;
    public isDisabled: boolean;
    public question: string;
    public questionId: number;
    public questionType: NoteQuestionType;
    public answer: string;
    public noteAnswer: NoteAnswer;
    public visible: boolean;
    public order: number;
    public hiddenCategories: Category[];

    constructor(question: NoteQuestion, locale: string) {
        this.questionId = question.id;
        this.question = question.translations?.find(x => x.locale.slice(0, 2) == locale)?.translation ?? question.question;
        this.identityQuestion = question.identityQuestion;
        this.order = question.order;
        this.isRequired = question.required;
        this.questionType = question.questionType;
        this.visible = question.visible;
        this.hiddenCategories = question.hiddenCategories;
        this.isDisabled = false;
    }
}