import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { LocationData } from 'projects/qcloud-models/location/location.model';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-assign-system-tag-location',
  templateUrl: './assign-system-tag-location.component.html',
  styleUrls: ['./assign-system-tag-location.component.css']
})
export class AssignSystemTagLocationComponent implements OnInit {

  @Input() locations: LocationData[];
  @Input() locationMultiCtrl: UntypedFormControl;
  public locationMultiFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredLocationsMulti: ReplaySubject<LocationData[]> = new ReplaySubject<LocationData[]>(1);

  protected _onDestroy = new Subject<void>();

  constructor() { }

  ngOnInit(): void {
    // load the initial location list
    this.filteredLocationsMulti.next(this.locations.slice());

    // listen for search field value changes
    this.locationMultiFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterLocationsMulti();
    });
  }

  compareLocations(location1: LocationData, location2: LocationData): boolean {
    return location1.id === location2.id;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredLocationsMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.locationMultiCtrl.patchValue(val);
        } else {
          this.locationMultiCtrl.patchValue([]);
        }
      });
  }

  protected filterLocationsMulti() {
    if (!this.locations) {
      return;
    }
    // get the search keyword
    let search = this.locationMultiFilterCtrl.value;
    if (!search) {
      this.filteredLocationsMulti.next(this.locations.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the locations
    this.filteredLocationsMulti.next(
      this.locations.filter(location => location.locationName.toLowerCase().indexOf(search) > -1)
    );
  }

}
