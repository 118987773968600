import { ActivityTime } from "../activity-time";
import { SystemCategory } from "./system-category.model";

export class Category {
    public id: number;
    public locationId: number;
    public fullName: string;
    public shortName: string;
    public categoryTag: string;
    public ticketLimit: number;
    public timePerTicket: number;
    public isBlocked: boolean;
    public unblockingTimeDaysValue: ActivityTime[];
    public ticketReservationBlockTimes: any[];
    public isAutomaticallyReset: boolean;
    public blockingMessageActivity: string;
    public blockingMessageManual: string;
    public isActive: boolean;
    public canBeReserved: boolean;
    public eTicketRedirect: boolean;
    public canMobile: boolean;
    public reservationIntervalMins: number;
    public maxServiceTime: number;
    public workingType: number;
    public avgTimeBlock: boolean;
    public avgTimeBlockText: string;
    public smsCategory: boolean;
    public sendSmsOnReservation: boolean;
    public sendSmsOnPrint: boolean;
    public sendSmsOnBeforeCall: boolean;
    public sendSmsOnTicketCall: boolean;
    public conditionalTicket: boolean;
    public conditionalTicketText: string;
    public additionalPrint: boolean;
    public additionalPrintText: string;
    public printedTicketAdditionalText: string;
    public defaultPauseTimeMins: number;
    public isNoteRequired: boolean;
    public ticketAmountToCountAverageWaitingServiceTime: number;
    public canOnHour: boolean;
    public systemCategory: SystemCategory;
    public systemCategoryId: number;
    public isInheritanceFullName: boolean;
    public isInheritanceShortName: boolean;
    public isInheritanceCategoryTag: boolean;
    public isInheritanceReservationGroupId: boolean;
    public isInheritanceLocationId: boolean;
    public isInheritanceTicketLimit: boolean;
    public isInheritanceTimePerTicket: boolean;
    public isInheritanceIsBlocked: boolean;
    public isInheritanceIsAutomaticallyReset: boolean;
    public isInheritanceBlockingMessageActivity: boolean;
    public isInheritanceBlockingMessageManual: boolean;
    public isInheritanceCanBeReserved: boolean;
    public isInheritanceCanMobile: boolean;
    public isInheritanceCanOnHour: boolean;
    public isInheritanceETicketRedirect: boolean;
    public isInheritanceReservationIntervalMins: boolean;
    public isInheritanceMaxServiceTime: boolean;
    public isInheritanceDefaultPauseTimeMins: boolean;
    public isInheritanceIsNoteRequired: boolean;
    public isInheritanceWorkingType: boolean;
    public isInheritanceAvgTimeBlock: boolean;
    public isInheritanceAvgTimeBlockText: boolean;
    public isInheritanceSmsCategory: boolean;
    public isInheritanceSendSmsOnReservation: boolean;
    public isInheritanceSendSmsOnPrint: boolean;
    public isInheritanceSendSmsOnBeforeCall: boolean;
    public isInheritanceSendSmsOnTicketCall: boolean;
    public isInheritanceConditionalTicket: boolean;
    public isInheritanceConditionalTicketText: boolean;
    public isInheritanceAdditionalPrint: boolean;
    public isInheritanceAdditionalPrintText: boolean;
    public isInheritancePrintedTicketAdditionalText: boolean;
    public isInheritanceTicketAmountToCountAverageWaitingServiceTime: boolean;
    public isInheritanceSystemCategoryId: boolean;


    public static mapFromSystemCategoryIntoCategory(systemCategory: SystemCategory): Category {
        var cat = new Category();
        cat.id = systemCategory.id;
        cat.locationId = systemCategory.locationId;
        cat.fullName = systemCategory.fullName;
        cat.shortName = systemCategory.shortName;
        cat.categoryTag = systemCategory.categoryTag;
        cat.ticketLimit = systemCategory.ticketLimit;
        cat.timePerTicket = systemCategory.timePerTicket;
        cat.isBlocked = systemCategory.isBlocked;
        cat.ticketReservationBlockTimes = systemCategory.ticketReservationBlockTimes;
        cat.isAutomaticallyReset = systemCategory.isAutomaticallyReset;
        cat.blockingMessageActivity = systemCategory.blockingMessageActivity;
        cat.blockingMessageManual = systemCategory.blockingMessageManual;
        cat.isActive = systemCategory.isActive;
        cat.canBeReserved = systemCategory.canBeReserved;
        cat.eTicketRedirect = systemCategory.eTicketRedirect;
        cat.canMobile = systemCategory.canMobile;
        cat.reservationIntervalMins = systemCategory.reservationIntervalMins;
        cat.maxServiceTime = systemCategory.maxServiceTime;
        cat.workingType = systemCategory.workingType;
        cat.avgTimeBlock = systemCategory.avgTimeBlock;
        cat.avgTimeBlockText = systemCategory.avgTimeBlockText;
        cat.smsCategory = systemCategory.smsCategory;
        cat.sendSmsOnReservation = systemCategory.sendSmsOnReservation;
        cat.sendSmsOnPrint = systemCategory.sendSmsOnPrint;
        cat.sendSmsOnBeforeCall = systemCategory.sendSmsOnBeforeCall;
        cat.sendSmsOnTicketCall = systemCategory.sendSmsOnTicketCall;
        cat.conditionalTicket = systemCategory.conditionalTicket;
        cat.conditionalTicketText = systemCategory.conditionalTicketText;
        cat.additionalPrint = systemCategory.additionalPrint;
        cat.additionalPrintText = systemCategory.additionalPrintText;
        cat.printedTicketAdditionalText = systemCategory.printedTicketAdditionalText;
        cat.defaultPauseTimeMins = systemCategory.defaultPauseTimeMins;
        cat.isNoteRequired = systemCategory.isNoteRequired;
        cat.ticketAmountToCountAverageWaitingServiceTime = systemCategory.ticketAmountToCountAverageWaitingServiceTime;
        cat.canOnHour = systemCategory.canOnHour;
        cat.isInheritanceFullName = true;
        cat.isInheritanceShortName = true;
        cat.isInheritanceCategoryTag = true;
        cat.isInheritanceReservationGroupId = true;
        cat.isInheritanceLocationId = true;
        cat.isInheritanceTicketLimit = true;
        cat.isInheritanceTimePerTicket = true;
        cat.isInheritanceIsBlocked = true;
        cat.isInheritanceIsAutomaticallyReset = true;
        cat.isInheritanceBlockingMessageActivity = true;
        cat.isInheritanceBlockingMessageManual = true;
        cat.isInheritanceCanBeReserved = true;
        cat.isInheritanceCanMobile = true;
        cat.isInheritanceCanOnHour = true;
        cat.isInheritanceETicketRedirect = true;
        cat.isInheritanceReservationIntervalMins = true;
        cat.isInheritanceMaxServiceTime = true;
        cat.isInheritanceDefaultPauseTimeMins = true;
        cat.isInheritanceIsNoteRequired = true;
        cat.isInheritanceWorkingType = true;
        cat.isInheritanceAvgTimeBlock = true;
        cat.isInheritanceAvgTimeBlockText = true;
        cat.isInheritanceSmsCategory = true;
        cat.isInheritanceSendSmsOnReservation = true;
        cat.isInheritanceSendSmsOnPrint = true;
        cat.isInheritanceSendSmsOnBeforeCall = true;
        cat.isInheritanceSendSmsOnTicketCall = true;
        cat.isInheritanceConditionalTicket = true;
        cat.isInheritanceConditionalTicketText = true;
        cat.isInheritanceAdditionalPrint = true;
        cat.isInheritanceAdditionalPrintText = true;
        cat.isInheritancePrintedTicketAdditionalText = true;
        cat.isInheritanceTicketAmountToCountAverageWaitingServiceTime = true;
        cat.isInheritanceSystemCategoryId = true;
        return cat;
    }
}
