import { Component, Input, OnInit } from '@angular/core';
import { Category } from 'projects/qcloud-models/category/category.model';
import { Dictionary } from 'projects/qcloud-models/dictionary';

@Component({
  selector: 'app-waiting-time-category-statistics',
  templateUrl: './waiting-time-category-statistics.component.html',
  styleUrls: ['./waiting-time-category-statistics.component.css']
})
export class WaitingTimeCategoryStatisticsComponent implements OnInit {

  @Input() categories: Category[];
  @Input() averageCategoryWaitingTime: Dictionary<number>;
  @Input() averageWaitingTime: number;
  maxWaitingTime: number;

  constructor() { }

  ngOnInit(): void {
    this.maxWaitingTime = +Object.entries(this.averageCategoryWaitingTime).reduce((a, b) => a[1] > b[1] ? a : b)[1];
  }

}
