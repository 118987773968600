<div #pagetop class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 *ngIf="!isEditForm" class="m-0 font-weight-bold text-primary">{{ 'note.add-template-configuration' |
            translate }}</h5>
        <div *ngIf="isEditForm" class="row">
            <h5 class="m-0 col-8 font-weight-bold text-primary">{{ 'note.edit-template-configuration' | translate }}
            </h5>
        </div>
    </div>
    <div *ngIf="ticketNoteTemplateVm" class="card-body">
        <form #form="ngForm" autocomplete="off">
            <div class="col-6">
                *{{ 'note.template-name' | translate }}
                <div class="input-group">
                    <input name="templateName" #templateName="ngModel" [(ngModel)]="ticketNoteTemplateVm.name"
                        class="form-control" required>
                </div>
                <div *ngIf="templateName.invalid && (templateName.dirty || templateName.touched)"
                    class="text-danger mx-2 my-1">
                    <div *ngIf="templateName.errors.required">{{ 'note.template-name-required' | translate }}</div>
                </div>
            </div>

            <div class="mb-3 mt-3">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{{ 'order' | translate }}</th>
                                <th>{{ 'identity-question' | translate }}</th>
                                <th>{{ 'required' | translate }}</th>
                                <th>{{ 'question-type' | translate }}</th>
                                <th>{{ 'question' | translate }}</th>
                                <th>{{ 'visible' | translate }}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody *ngIf="ticketNoteTemplateVm.noteQuestions">
                            <tr
                                *ngFor="let question of ticketNoteTemplateVm.noteQuestions; let i = index;  trackBy: trackByFn">
                                <td>{{ i + 1 }}</td>
                                <td>
                                    <button *ngIf="i != 0" class="btn btn-primary btn-sm btn-circle mr-1"
                                        (click)="changeOrder(i, -1)">
                                        <i class="fas fa-arrow-up"></i>
                                    </button>
                                    <button *ngIf="(i + 1) != ticketNoteTemplateVm.noteQuestions.length"
                                        class="btn btn-primary btn-sm btn-circle" (click)="changeOrder(i, 1)">
                                        <i class="fas fa-arrow-down"></i>
                                    </button>
                                </td>
                                <td>
                                    <mat-checkbox [(ngModel)]="question.identityQuestion" color="primary"
                                        class="mt-1 mr-3" name="identityQuestion_{{+i}}">
                                    </mat-checkbox>
                                </td>
                                <td>
                                    <mat-checkbox [(ngModel)]="question.required" color="primary" class="mt-1 mr-3"
                                        name="isRequired_{{+i}}">
                                    </mat-checkbox>
                                </td>
                                <td>
                                    <mat-form-field>
                                        <mat-select matNativeControl [(ngModel)]="question.questionType"
                                            name="questionType_{{+i}}" (selectionChange)="changeQuestionType(question)">
                                            <mat-option [value]="NoteQuestionTypeEnum.Text">
                                                {{ 'text' | translate }}
                                            </mat-option>
                                            <mat-option [value]="NoteQuestionTypeEnum.FirstName">
                                                {{ 'first-name' | translate }}
                                            </mat-option>
                                            <mat-option [value]="NoteQuestionTypeEnum.LastName">
                                                {{ 'last-name' | translate }}
                                            </mat-option>
                                            <mat-option [value]="NoteQuestionTypeEnum.Email">
                                                {{ 'email' | translate }}
                                            </mat-option>
                                            <mat-option [value]="NoteQuestionTypeEnum.PhoneNumber">
                                                {{ 'phone' | translate }}
                                            </mat-option>
                                            <mat-option [value]="NoteQuestionTypeEnum.PersonalId">
                                                {{ 'personal-identity-number' | translate }}
                                            </mat-option>
                                            <mat-option [value]="NoteQuestionTypeEnum.PersonalIdLastNumbers">
                                                {{ '5-last-numbers-personal-identity-number' | translate }}
                                            </mat-option>
                                            <mat-option [value]="NoteQuestionTypeEnum.VIN">
                                                VIN
                                            </mat-option>
                                            <mat-option [value]="NoteQuestionTypeEnum.Option">
                                                {{ 'option' | translate }}
                                            </mat-option>
                                            <!-- <mat-option [value]="NoteQuestionTypeEnum.VisibleOption">
                                                {{ 'visible-option' | translate }}
                                            </mat-option> -->
                                            <mat-option [value]="NoteQuestionTypeEnum.TextArea">
                                                {{ 'text-area' | translate }}
                                            </mat-option>
                                            <mat-option [value]="NoteQuestionTypeEnum.TextAreaAndCheckBox">
                                                {{ 'text-area-with-checkbox' | translate }}
                                            </mat-option>
                                            <mat-option [value]="NoteQuestionTypeEnum.InvoiceNumber">
                                                {{ 'invoice-number' | translate }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <div>
                                        <input *ngIf="question.questions && question.questions.length < 2"
                                            [(ngModel)]="question.question" name="question_{{+i}}"
                                            class="form-control mb-1" required>
                                        <input *ngIf="question.questions && question.questions.length > 1"
                                            [(ngModel)]="question.questions[0]" name="question_{{+i}}_a"
                                            class="form-control mb-1" required>
                                        <input *ngIf="question.questions && question.questions.length > 1"
                                            [(ngModel)]="question.questions[1]" name="question_{{+i}}_b"
                                            class="form-control mb-1" required>
                                    </div>
                                </td>
                                <td>
                                    <mat-checkbox [(ngModel)]="question.visible" name="visible_{{+i}}" color="primary"
                                        class="mt-1 mr-3">
                                    </mat-checkbox>
                                </td>
                                <td>
                                    <button class="btn btn-danger btn-sm btn-circle" (click)="deleteQuestion(i)">
                                        <i class="fas fa-fw fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                    </table>
                </div>
                <button class="btn btn-danger m-1" routerLink="/notesConfiguration">{{
                    'back' | translate
                    }}</button>
                <button class="btn btn-info m-1" (click)="addQuestion()">
                    {{ 'add-question' | translate }}
                </button>
                <app-submit-button (click)="onSubmit()" [loading]="formSubmitted" [text]="'save-questions' | translate"
                    [disabled]="formSubmitted || form.invalid"></app-submit-button>
                <label *ngIf="form.invalid" class="text-danger">*{{ 'complete-required-fields' | translate }}</label>
            </div>
        </form>
    </div>
</div>