import { WeekDay } from '@angular/common';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FileType } from 'projects/qcloud-models/report/file-type.enum';
import { AutoGenerateReportDTO } from 'projects/qcloud-models/report/report-task';
import { ReportTemplate } from 'projects/qcloud-models/report/report-template';
import { AutoGenerateReportService } from 'projects/qcloud-rest-client/src/lib/autogenerate-report.service';
import { EmailConfigService } from 'projects/qcloud-rest-client/src/lib/email-config.service';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { TimeConversionService } from 'projects/qcloud-web/src/app/shared/time-conversion.service';
import { WeekService } from 'projects/qcloud-web/src/app/shared/week.service';

@Component({
  selector: 'app-report-generation-task',
  templateUrl: './report-generation-task.component.html',
  styleUrls: ['./report-generation-task.component.css']
})
export class ReportGenerationTaskComponent implements OnInit {
  reportTasks: AutoGenerateReportDTO[];
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  task: AutoGenerateReportDTO;
  FileType = FileType;
  dayNames = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
  selectedDays = new Array<boolean>(7);
  scheduleTime: Date[];
  timeSelected = "12:00";
  @Input() reportTemplates: ReportTemplate[];
  isEmailConfigured: boolean;

  constructor(private toastr: ToastrService, private translate: TranslateService,
    private modal: MatDialog, private weekService: WeekService,
    private timeService: TimeConversionService, public locationService: LocationService,
    private autoGenerateReportService: AutoGenerateReportService, private emailConfigService: EmailConfigService) {

  }

  ngOnInit(): void {
    this.reportTasks = new Array();
    this.checkIfSmtpIsConfigured();
    this.getTasks();
  }

  checkIfSmtpIsConfigured(): void {
    this.emailConfigService.getEmailConfig().subscribe(
      emailConfig => {
        if (emailConfig.password == null || emailConfig.userName == null) {
          this.isEmailConfigured = false;
        }
        else {
          this.isEmailConfigured = true;
        }
      }, () => { }
    );
  }

  getTasks() {
    this.autoGenerateReportService.getReportTasks().subscribe((data) => {
      this.reportTasks = data;
    }, () => {
      this.translate.get('reservation-schedule-save-error').subscribe((res: string) => {
        this.toastr.error(res);
      });
    });
  }

  getTime(dateTime: Date) {
    var time = this.timeService.dateToTime(dateTime);
    return time;
  }

  getTemplateNameById(templateId: number) {
    return this.reportTemplates.filter(x => x.id == templateId)[0].name;
  }

  createNewTask() {
    this.task = new AutoGenerateReportDTO();
    this.task.lastDay = 1;
    this.timeSelected = "12:00";
    this.task.emails = " example";
    var matDialogConfig = new MatDialogConfig();
    matDialogConfig.maxHeight = 900;
    matDialogConfig.maxWidth = 800;
    this.modal.open(this.modalContent, matDialogConfig);
  }

  editTask(task: AutoGenerateReportDTO) {
    this.task = task;
    this.timeSelected = this.timeService.dateToTime(this.task.time);
    this.task.days.forEach(element => {
      this.selectedDays[element] = true;
    });
    var matDialogConfig = new MatDialogConfig();
    matDialogConfig.maxHeight = 900;
    matDialogConfig.maxWidth = 800;
    this.modal.open(this.modalContent, matDialogConfig);
  }

  deleteTask(task: AutoGenerateReportDTO) {
    this.autoGenerateReportService.deleteReportTask(task.id).subscribe(() => {
      this.translate.get('reservation-schedule-save-success').subscribe((res: string) => {
        this.toastr.success(res);
      });
      this.getTasks();
    }, () => {
      this.translate.get('reservation-schedule-save-error').subscribe((res: string) => {
        this.toastr.error(res);
      });
    });
  }

  getFileType(type: number) {
    return FileType[type];
  }

  selectAll() {
    this.updateSelected(this.weekService.selectAll());
  }

  unselectAll() {
    this.updateSelected(this.weekService.unselectAll());
  }

  selectWeekend() {
    this.updateSelected(this.weekService.selectWeekend());
  }

  selectWorkweek() {
    this.updateSelected(this.weekService.selectWorkweek());
  }

  updateSelected(daysOfWeek: WeekDay[]) {
    this.selectedDays = new Array<boolean>(7);
    for (let i = 0; i < daysOfWeek.length; i++) {
      this.selectedDays[daysOfWeek[i]] = true;
    }
  }

  onCloseModal() {
    let daysAr = new Array();
    this.selectedDays.forEach((day, index) => {
      if (day) {
        daysAr.push(index);
      }
    });
    this.task.days = daysAr;
    this.task.time = this.timeService.timeToDate(this.timeSelected),
      this.autoGenerateReportService.setReportTask(this.task).subscribe(() => {
        this.translate.get('reservation-schedule-save-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
        this.getTasks();
      }, () => {
        this.translate.get('reservation-schedule-save-error').subscribe((res: string) => {
          this.toastr.error(res);
        });
      });
  }
}
