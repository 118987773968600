<div class="modal-dialog" role="document">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ info | translate}}</h5>
            <button class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-footer">
            <button class="btn btn-secondary" data-dismiss="modal">{{ 'cancel' | translate }}</button>
            <button class="btn btn-danger" data-dismiss="modal" (click)="send()">{{ confirm | translate }}</button>
        </div>
    </div>
</div>