import { Injectable } from '@angular/core';
import { SingleQueueState } from 'projects/qcloud-models/console/queueState';
import { TicketService } from 'projects/qcloud-rest-client/src/lib/ticket.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { DeviceConfigurationService } from '../../../../qcloud-device/src/app/services/device-configuration.service';
import { QcloudStompClientService } from '../qcloud-stomp-client.service';

@Injectable()
export class StompQueueStateService {
  private stompTicketQueueSubscription: Subscription;
  private stompDeskQueueSubscription: Subscription;
  deviceQueueState: Observable<Map<number, number>>;
  private deviceQueueStateSubject: Subject<Map<number, number>>;
  private queueState: Map<number, number>;

  constructor(private qcloudStompClientService: QcloudStompClientService,
    private configurationService: DeviceConfigurationService,
    private ticketService: TicketService) {
    this.deviceQueueStateSubject = new Subject<Map<number, number>>();
    this.deviceQueueState = this.deviceQueueStateSubject.asObservable();
  }

  public startListening(deskId: number) {
    let context = this;
    this.ticketService.getQueueState(
      (data) => {
        context.queueState = data;
        context.deviceQueueStateSubject.next(this.queueState);
      },
      (err) => { }
    );
    let config = this.configurationService.getConfiguration();
    let locationId = config.locationId;
    let systemId = config.systemId;
    this.stompTicketQueueSubscription = this.qcloudStompClientService.subscribe(`/exchange/queueState/${systemId}.${locationId}.queue.*`,
      (singleQueueState: SingleQueueState) => {
        context.queueState[singleQueueState.categoryId] = singleQueueState.length;
        context.deviceQueueStateSubject.next(this.queueState);
      });
    this.stompDeskQueueSubscription = this.qcloudStompClientService.subscribe(`/exchange/queueStateDesk/${systemId}.${locationId}.queueDesk.${deskId}`,
      (singleQueueState: SingleQueueState) => {
        context.queueState[-1] = singleQueueState.length;
        context.deviceQueueStateSubject.next(this.queueState);
      });
  }

  public stopListening() {
    this.stompTicketQueueSubscription.unsubscribe();
    this.stompDeskQueueSubscription.unsubscribe();
  }
}
