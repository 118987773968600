import { Component, OnInit } from '@angular/core';
import { LocationData } from 'projects/qcloud-models/location/location.model';
import { TenantConfig } from 'projects/qcloud-models/system/tenant-config.model';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { SystemService } from 'projects/qcloud-rest-client/src/lib/system.service';

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.css']
})
export class LicenseComponent implements OnInit {

  tenant: TenantConfig;
  locations: LocationData[];

  constructor(private systemService: SystemService, private locationService: LocationService,
    public authService: AuthService) { }

  ngOnInit(): void {
    this.updateInfo();
  }

  getTenant() {
    this.systemService.getTenant().subscribe(
      tenant => this.tenant = tenant
    );
  }

  getLocations() {
    this.locationService.getAll().subscribe(
      locations => this.locations = locations
    )
  }

  updateInfo() {
    this.getTenant();
    this.getLocations();
  }

}
