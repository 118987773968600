import { Component, Input, OnInit } from '@angular/core';
import { Category } from 'projects/qcloud-models/category/category.model';
import { ReservationVacationService } from '../reservation-vacation.service';

@Component({
  selector: 'app-reservation-vacation-list',
  templateUrl: './reservation-vacation-list.component.html',
  styleUrls: ['./reservation-vacation-list.component.css']
})
export class ReservationVacationListComponent implements OnInit {
  @Input() reservationCategories: Category[];
  _choosenCategory: Category;
  set choosenCategory(value: Category) {
    this._choosenCategory = value;
    this.categoryChanged();
  }
  get choosenCategory() {
    return this._choosenCategory;
  }
  constructor(private reservationVacationService: ReservationVacationService) { }

  ngOnInit(): void {
  }

  categoryChanged(){
    this.reservationVacationService.updateCategory(this.choosenCategory);
  }

}
