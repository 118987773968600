import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientOpinion } from 'projects/qcloud-models/survey/client-opinion';
import { Observable } from 'rxjs';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  appBase: string;

  constructor(private http: HttpClient, private authService: AuthService) { }

  public setAppBase(appBase: string) {
    console.log("test:" + appBase);
    this.appBase = appBase;
  }

  saveSurveyConfig(surveyType: number){
    return this.http.put(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/config/${surveyType}`,{});
  }

  getSurveyConfig(): Observable<number> {
    return this.http.get<any>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/config`);
  }

  getOpinions(startDay: Date, endDay: Date, callback: (data) => any, onError: (error) => any) {
    return this.http.get(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}?startDay=${startDay.toISOString()}&endDay=${endDay.toISOString()}`)
      .subscribe(
        callback,
        onError
      );
  }

  addOpinion(clientOpinion: ClientOpinion): Observable<any> {
    return this.http.post<any>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}`, clientOpinion);
  }
}
