import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { timeout } from 'rxjs/operators';
import { Employee } from 'projects/qcloud-models/grafik/employee/employee.model';

const DEFAULT_TIMEOUT = 3000;

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  appBase: string;

  constructor(private http: HttpClient, private authService: AuthService) { }

  public setAppBase(appBase: string) {
    this.appBase = appBase;
  }

  getEmployees(callback: (data: Employee[]) => any, onError: () => any) {
    this.http.get(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/employees`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  getEmployee(employeeId: number): Observable<Employee> {
    return this.http.get<Employee>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/employees/${employeeId}`);
  }

  addEmployee(employee: Employee) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/employees`, employee);
  }

  deleteEmployee(employeeId: number) {
    return this.http.delete(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/employees/${employeeId}`);
  }

  editEmployee(employee: Employee) {
    return this.http.put(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/employees/${employee.id}`,
      employee);
  }

  editEmployeeWithoutTime(employee: Employee) {
    return this.http.put(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/employees/notime/${employee.id}`,
      employee);
  }
}
