<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="m-0 font-weight-bold text-warning">{{ title | translate }}</h5>
    </div>
    <div class="card-body">
        <p>{{ 'option-available-after-extending-licence' | translate }}</p>
        {{ 'more-info-at' | translate }}
        <a href="https://qcloud.pl/" target="_blank" rel="noopener noreferrer">
            {{ 'qcloud-site' | translate }}
        </a>
    </div>
</div>
