import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(private authService: AuthService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.getToken() != null && !this.authService.isTokenExpired()) {
      let roles = next.data['permittedRoles'] as Array<string>;
      if (roles) {
        if (this.authService.roleMatch(roles))
          return true;
        else {
          this.authService.onLogout();
          return false;
        }
      }
      return true;
    }
    else {
      this.authService.onLogout();
      return false;
    }
  }
}
export const IsAdminGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : boolean => {
  return inject(AuthGuard).canActivate(route, state);
}
