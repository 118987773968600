import { Tag } from './tag.model';

export class TagDevice {
    public id: number;
    public deviceId: string;
    public tagId: number;
    public tag: Tag;

    constructor(deviceId: string, tag: Tag) {
        this.deviceId = deviceId;
        this.tag = tag;
        this.tagId = tag.id;
    }
}