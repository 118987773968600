import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { LdapConfiguration } from '../../../qcloud-models/ldap/ldap-configuration';

@Injectable({
  providedIn: 'root'
})
export class LdapConfigurationService {

  appBase: string;

  constructor(private http: HttpClient, private authService: AuthService) { }

  public setAppBase(appBase: string) {
    this.appBase = appBase;
  }

  getLdapConfiguration(): Observable<LdapConfiguration> {
    return this.http.get<LdapConfiguration>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/ldapConfiguration`);
  }

  updateLdapConfiguration(data: LdapConfiguration) : Observable<LdapConfiguration> {
    var body = {
      id : data.id,
      hostName : data.hostName,
      port : data.port,
      distinguishedName : data.distinguishedName,
      userName : data.userName,
      password : data.password
    };
    return this.http.put<LdapConfiguration>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/ldapConfiguration`, body);
  }
  testConnectionLdapConfiguration(data: LdapConfiguration) : Observable<boolean> {
    var body = {
      id : data.id,
      hostName : data.hostName,
      port : data.port,
      distinguishedName : data.distinguishedName,
      userName : data.userName,
      password : data.password
    };
    return this.http.put<boolean>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/ldapConfiguration/testConnection`, body);
  }
}
