import { DeskStatus } from './desk-status.enum';

export class Desk {
    public id: number;
    public locationId: number;
    public systemId: number;
    public deskFullNamePronunciation: string;
    public categories: any[];
    public deskStatus: DeskStatus;
    public name: string;
    public deskNumber: string;
    public fullName: string;
    public roomNumber: string;
    public roomNumberPronunciation: string;
    public currentLoggedInUserId: string;;
}
