import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocationConstraints } from 'projects/qcloud-models/location/location-constraints';
import { User } from 'projects/qcloud-models/user/user.model';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { LicenseService } from 'projects/qcloud-rest-client/src/lib/license.service';
import { UserService } from 'projects/qcloud-rest-client/src/lib/user.service';
import { ModulePage } from '../page/select-location-step/module-page.enum';


@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {


  locationConstraints: LocationConstraints;

  constructor(private router: Router, public authService: AuthService, private userService: UserService,
    private licenseService: LicenseService) { }

  ngOnInit(): void {
    this.licenseService.getLocationsConstraints().subscribe(
      res => {
        this.locationConstraints = res;
        let permissions = [];
        if (this.authService.isManagerOrHigher()) {
          permissions.push('qcloud/select-location-step/' + ModulePage.DASHBOARD);
        }
        if (this.isConsoleVisible()) {
          permissions.push('qcloud/select-location-step/' + ModulePage.WEB_CONSOLE);
        }
        if (this.isReservationVisible()) {
          permissions.push('qcloud/select-location-step/' + ModulePage.RESERVATIONS);
        }
        if (this.isWorkScheduleVisible()) {
          permissions.push('qcloud/select-location-step/' + ModulePage.WORK_SCHEDULE);
        }
        if (permissions.length == 1) {
          this.router.navigate(permissions);
        }
      }
    );
  }

  goToDashboard() {
    this.router.navigate(['qcloud/select-location-step/', ModulePage.DASHBOARD]);
  }

  goToConsole() {
    this.router.navigate(['qcloud/select-location-step/', ModulePage.WEB_CONSOLE]);
  }

  goToReservation() {
    this.router.navigate(['qcloud/select-location-step/', ModulePage.RESERVATIONS]);
  }

  goToWorkSchedule() {
    this.router.navigate(['qcloud/select-location-step/', ModulePage.WORK_SCHEDULE]);
  }

  isConsoleVisible(): boolean {
    var value = this.authService.getTokenHasAccessToConsole();
    return value;
  }

  isReservationVisible(): boolean {
    return this.authService.getTokenHasPermissionToReserveTickets() && this.locationConstraints.areEnabledTicketsReservations;
  }

  isWorkScheduleVisible(): boolean {
    return this.authService.getTokenHasPermissionToConfigureGrafik() && this.locationConstraints.isWorkersScheduleEnabled;
  }
}
