export class ActivityTime {
    public id: number;
    public categoryId: number;
    public day: number;
    public unblockingTime: any;
    public blockingTime: any;
    public isActive: boolean;
    public is24Hours: boolean;
    public isButtonAlwaysVisible: boolean;
    public isInheritanceDay: boolean;
    public isInheritanceUnblockingTime: boolean;
    public isInheritanceBlockingTime: boolean;
    public isInheritanceIsActive: boolean;
    public isInheritanceIs24Hours: boolean;
    public isInheritanceIsButtonAlwaysVisible: boolean;

    public static IsSettedDateTimeEquals(act1: ActivityTime, act2: ActivityTime) : boolean
    {
        if(act1.blockingTime != act2.blockingTime) {
            return false;
        }
        if(act1.unblockingTime != act2.unblockingTime) {
            return false;
        }
        return true;
    }
}