<div #pagetop class="card shadow mb-4">
    <div class="card-header py-3">
        <div class="row">
            <h5 class="col-9 m-0 font-weight-bold text-primary">
                {{ 'edit-layout' | translate }}
            </h5>
        </div>
    </div>
    <div class="card-body">
        <div style="display: flex;">
            <div id="wisywig" class="aspect-ratio-container" #container>
                <div class="aspect-ratio-content">
                    <div style="position: relative;width: 100%;height: 100%;">
                        <ng-container #vc></ng-container>
                        <div class="single-logo-wrapper">
                            <img class="bottom-img" src="./assets/QCloud.png">
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin-left: 10px;" *ngIf="deviceLayout && selectedRow">
                Belka<br>
                <label>Kolor ramki</label>
                <div style="display: flex;">
                    <ngx-colors ngx-colors-trigger [(ngModel)]="rowStyle.borderColor" (change)="updateRows()"
                        [format]="'hex'"></ngx-colors>
                    <input class="form-control" type="text" [(ngModel)]="rowStyle.borderColor" (change)="updateRows()">
                </div>
                <label>Grubość ramki</label>
                <input class="form-control" type="text" [(ngModel)]="rowStyle.borderSize" (change)="updateRows()">
                <label>Zaokrąglenie ramki</label>
                <input class="form-control" type="text" [(ngModel)]="rowStyle.borderRadius" (change)="updateRows()">
                <label>Rodzaj tła</label>
                <select class="form-control" [(ngModel)]="rowStyle.backgroundType" (change)="updateRows()">
                    <option value="0">Kolor</option>
                    <option value="1">Obrazek</option>
                </select>
                <label *ngIf="rowStyle.backgroundType==0">Kolor tła</label>
                <div *ngIf="rowStyle.backgroundType==0" style="display: flex;">
                    <ngx-colors ngx-colors-trigger [(ngModel)]="rowStyle.backgroundColor" (change)="updateRows()"
                        [format]="'hex'"></ngx-colors>
                    <input class="form-control" type="text" [(ngModel)]="rowStyle.backgroundColor"
                        (change)="updateRows()">
                </div>
                <label *ngIf="rowStyle.backgroundType==1">Obrazek tła</label>
                <div *ngIf="rowStyle.backgroundType==1">
                    <button class="btn btn-primary btn-sm" data-toggle="modal" data-target="#fileManagerRowbgModal">
                        {{ 'select-image' | translate }}
                    </button>
                    {{rowStyle.backgroundImage}}
                    <div class="modal fade" id="fileManagerRowbgModal" tabindex="-1" role="dialog"
                        aria-labelledby="modalLabel" aria-hidden="true">
                        <app-file-popup [selectedFilePath]="rowStyle.backgroundImage"
                            (filePathToEmit)="rowStyle.backgroundImage = $event;updateRows()"></app-file-popup>
                    </div>
                </div>
            </div>
            <div style="margin-left: 10px;" *ngIf="deviceLayout && selectedComponentStyle">
                {{ 'component-name' | translate }}: {{deviceLayout.components[selectedIndex].name}}
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <td>{{ 'position' | translate }}</td>
                            <td>{{ 'parameters' | translate}}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div class="position-container">
                                    <div style="text-align: right; font-weight: bold;">
                                        X:
                                    </div>
                                    <div>
                                        <input class="form-control" type="number"
                                            [(ngModel)]="deviceLayout.components[selectedIndex].position[0]"
                                            (change)="update(selectedIndex)">
                                    </div>
                                    <div style="text-align: right; font-weight: bold;">
                                        {{'width' | translate}}:
                                    </div>
                                    <div>
                                        <input class="form-control" type="number"
                                            [(ngModel)]="deviceLayout.components[selectedIndex].position[1]"
                                            (change)="update(selectedIndex)">
                                    </div>
                                    <div style="text-align: right; font-weight: bold;">
                                        Y:
                                    </div>
                                    <div>
                                        <input class="form-control" type="number"
                                            [(ngModel)]="deviceLayout.components[selectedIndex].position[2]"
                                            (change)="update(selectedIndex)">
                                    </div>
                                    <div style="text-align: right; font-weight: bold;">
                                        {{'height' | translate}}:
                                    </div>
                                    <div>
                                        <input class="form-control" type="number"
                                            [(ngModel)]="deviceLayout.components[selectedIndex].position[3]"
                                            (change)="update(selectedIndex)">
                                    </div>
                                </div>
                            </td>
                            <td>
                                <label>Kolor czcionki (hex)</label>
                                <div style="display: flex;">
                                    <ngx-colors ngx-colors-trigger [(ngModel)]="style[selectedIndex].color"
                                        (change)="update(selectedIndex)" [format]="'hex'"></ngx-colors>
                                    <input class="form-control" type="text" [(ngModel)]="style[selectedIndex].color"
                                        (change)="update(selectedIndex)">
                                </div>
                                <label>Kolor ramki</label>
                                <div style="display: flex;">
                                    <ngx-colors ngx-colors-trigger [(ngModel)]="style[selectedIndex].borderColor"
                                        (change)="update(selectedIndex)" [format]="'hex'"></ngx-colors>
                                    <input class="form-control" type="text"
                                        [(ngModel)]="style[selectedIndex].borderColor" (change)="update(selectedIndex)">
                                </div>
                                <label>Grubość ramki</label>
                                <input class="form-control" type="text" [(ngModel)]="style[selectedIndex].borderSize"
                                    (change)="update(selectedIndex)">
                                <label>Zaokrąglenie ramki</label>
                                <input class="form-control" type="text" [(ngModel)]="style[selectedIndex].borderRadius"
                                    (change)="update(selectedIndex)">
                                <label>Rodzaj tła</label>
                                <select *ngIf="deviceLayout.components[selectedIndex].name != 'image'"
                                    class="form-control" [(ngModel)]="style[selectedIndex].backgroundType"
                                    (change)="update(selectedIndex)">
                                    <option value="0">Brak</option>
                                    <option value="1">Kolor</option>
                                    <option value="2">Obrazek</option>
                                </select>
                                <div *ngIf="deviceLayout.components[selectedIndex].name != 'image'">
                                    <label *ngIf="style[selectedIndex].backgroundType==1">Kolor tła</label>
                                    <ngx-colors *ngIf="style[selectedIndex].backgroundType==1" ngx-colors-trigger
                                        [(ngModel)]="style[selectedIndex].backgroundColor"
                                        (change)="update(selectedIndex)" [format]="'hex'"></ngx-colors>
                                    <input *ngIf="style[selectedIndex].backgroundType==1" class="form-control"
                                        type="text" [(ngModel)]="style[selectedIndex].backgroundColor"
                                        (change)="update(selectedIndex)">
                                    <label *ngIf="style[selectedIndex].backgroundType==2">Obrazek tła</label>
                                    <!-- File Manager Modal-->
                                    <div *ngIf="style[selectedIndex].backgroundType==2">
                                        <button class="btn btn-primary btn-sm" data-toggle="modal"
                                            data-target="#fileManagerComponentbgModal">
                                            {{ 'select-image' | translate }}
                                        </button>
                                        {{style[selectedIndex].backgroundImage}}
                                        <div class="modal fade" id="fileManagerComponentbgModal" tabindex="-1"
                                            role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
                                            <app-file-popup [selectedFilePath]="style[selectedIndex].backgroundImage"
                                                (filePathToEmit)="style[selectedIndex].backgroundImage = $event;update(selectedIndex)"></app-file-popup>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="deviceLayout.components[selectedIndex].name == 'image'">
                                    <button class="btn btn-primary btn-sm" data-toggle="modal"
                                        data-target="#fileManagerComponentModal">
                                        {{ 'select-image' | translate }}
                                    </button>
                                    {{style[selectedIndex].backgroundImage}}
                                    <div class="modal fade" id="fileManagerComponentModal" tabindex="-1" role="dialog"
                                        aria-labelledby="modalLabel" aria-hidden="true">
                                        <app-file-popup
                                            [selectedFilePath]="deviceLayout.components[selectedIndex].params.imageUrl"
                                            (filePathToEmit)="deviceLayout.components[selectedIndex].params.imageUrl = $event;update(selectedIndex)"></app-file-popup>
                                    </div>
                                </div>
                                <div *ngIf="deviceLayout.components[selectedIndex].name == 'text'">
                                    <input class="form-control" type="text"
                                        [(ngModel)]="deviceLayout.components[selectedIndex].params.text"
                                        (change)="update(selectedIndex)">
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div style="margin-top: 28px;">
            <h3>{{ 'component-list' | translate}}</h3>
            <table class="table table-bordered" *ngIf="deviceLayout">
                <thead>
                    <tr>
                        <td>#</td>
                        <td>{{ 'component' | translate }}</td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>
                            <span>Tło layoutu</span>
                        </td>
                        <td>
                            <button class="btn btn-primary btn-sm" data-toggle="modal" data-target="#fileManagerModal">
                                {{ 'select-image' | translate }}
                            </button>
                            {{deviceLayout.backgroundImg}}
                            <div class="modal fade" id="fileManagerModal" tabindex="-1" role="dialog"
                                aria-labelledby="modalLabel" aria-hidden="true">
                                <app-file-popup [selectedFilePath]="deviceLayout.backgroundImg"
                                    (filePathToEmit)="deviceLayout.backgroundImg = $event;updateBackgroundImage()"></app-file-popup>
                            </div>
                            <button class="btn btn-info btn-circle btn-sm mx-1 mb-1" (click)="deleteBackgroundImage()"
                                matTooltip="{{ 'edit' | translate }}" tooltipClass="tooltip-info">
                                <i class="fas fa-fw fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>
                            <span>Belka</span>
                        </td>
                        <td>
                            <button class="btn btn-info btn-circle btn-sm mx-1 mb-1" (click)="selectToEditRow()"
                                matTooltip="{{ 'edit' | translate }}" tooltipClass="tooltip-info">
                                <i class="fas fa-fw fa-edit"></i>
                            </button>
                        </td>
                    </tr>
                    <tr *ngFor="let component of deviceLayout.components; let i = index">
                        <td>{{ i + 3 }}</td>
                        <td>
                            <span>{{component.name}} </span>
                        </td>
                        <td>
                            <button class="btn btn-info btn-circle btn-sm mx-1 mb-1" (click)="selectToEdit(i)"
                                matTooltip="{{ 'edit' | translate }}" tooltipClass="tooltip-info">
                                <i class="fas fa-fw fa-edit"></i>
                            </button>
                            <button
                                *ngIf="component.name == 'image' || component.name == 'text' || component.name == 'clock'"
                                class="btn btn-info btn-circle btn-sm mx-1 mb-1" (click)="delete(i)"
                                matTooltip="{{ 'edit' | translate }}" tooltipClass="tooltip-info">
                                <i class="fas fa-fw fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field class="ml-2">
                                <mat-select name="componentName" #deviceType="ngModel" [(ngModel)]="componentName"
                                    required>
                                    <mat-option value="clock">{{ 'clock' | translate}}</mat-option>
                                    <mat-option value="image">{{ 'image' | translate}}</mat-option>
                                    <mat-option value="text">{{ 'text' | translate}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <button class="btn btn-info m-1" type="submit"
                                (click)="addNewComponent(componentName)">Dodaj
                            </button>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
            <button class="btn btn-info m-1" type="submit" (click)="save()">Zapisz
            </button>
        </div>
    </div>
</div>