import { Specialisation } from "../specialisation/specialisation.model";
import { EmployeeHolidayTime } from "./employee-holiday-time.model";
import { EmployeeWorkingTime } from "./employee-working-time";

export class Employee {
    public id: number;
    public locationId: number;
    public systemId: number;
    public firstName: string;
    public lastName: string;
    public title: string;
    public order: number;
    public nearestAvailableWorkTime: string;
    public officeId: number;
    public workTimes: EmployeeWorkingTime[];
    public holidayTimes: EmployeeHolidayTime[];
    public specialisation: Specialisation;
}