<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="m-0 font-weight-bold text-primary">{{ 'sms-provider' | translate }}</h5>
    </div>
    <div *ngIf="smsProviderConfiguration" class="card-body">
        <form #form="ngForm">
            <div class="form-group">
                <div class="input-group">
                    *<div class="font-weight-bold">{{ 'sms-provider' | translate }}</div>
                </div>
                <mat-form-field class="ml-2">
                    <mat-select name="providerKey" #providerKey="ngModel"
                        [(ngModel)]="smsProviderConfiguration.providerKey" required>
                        <mat-option [value]="'SMS_PLANET'">SMS PLANET</mat-option>
                        <mat-option [value]="'SERWER_SMS'">SERWER SMS</mat-option>
                        <mat-option [value]="'SMS_API'">SMS API</mat-option>
                    </mat-select>
                </mat-form-field>
                <div *ngIf="providerKey.invalid && (providerKey.dirty || providerKey.touched)" class="text-danger">
                    <div *ngIf="providerKey.errors.required">{{ 'device-type-required' | translate }}</div>
                </div>
            </div>
            <div *ngIf="smsProviderConfiguration.providerKey == 'SMS_PLANET'">
                <div>
                    <div class="form-group ml-3">
                        <div class="input-group">
                            <div class="font-weight-bold">{{ 'api-key' | translate }}</div>
                        </div>
                        <input name="apiKey" #apiKey [(ngModel)]="configuration.apiKey" class="form-control" type="text">
                    </div>
                </div>
                <div>
                    <div class="form-group ml-3">
                        <div class="input-group">
                            <div class="font-weight-bold">{{ 'api-password' | translate }}</div>
                        </div>
                        <input name="apiPassword" #apiPassword [(ngModel)]="configuration.apiPassword" class="form-control" type="password">
                    </div>
                </div>
                <div>
                    <div class="form-group ml-3">
                        <div class="input-group">
                            <div class="font-weight-bold">{{ 'company-Id' | translate }}</div>
                        </div>
                        <input name="companyId" #companyId [(ngModel)]="configuration.companyId" class="form-control" type="text">
                    </div>
                </div>
            </div>
            <div *ngIf="smsProviderConfiguration.providerKey == 'SERWER_SMS'">
                <div>
                    <div class="form-group ml-3">
                        <div class="input-group">
                            <div class="font-weight-bold">{{ 'username' | translate }}</div>
                        </div>
                        <input name="username" #username [(ngModel)]="configuration.username" class="form-control" type="text">
                    </div>
                </div>
                <div>
                    <div class="form-group ml-3">
                        <div class="input-group">
                            <div class="font-weight-bold">{{ 'password' | translate }}</div>
                        </div>
                        <input name="password" #password [(ngModel)]="configuration.password" class="form-control" type="password">
                    </div>
                </div>
            </div>
            <div *ngIf="smsProviderConfiguration.providerKey == 'SMS_API'">
                <div>
                    <div class="form-group ml-3">
                        <div class="input-group">
                            <div class="font-weight-bold">{{ 'token' | translate }}</div>
                        </div>
                        <input name="token" #token [(ngModel)]="configuration.token" class="form-control" type="text">
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <button class="btn btn-primary m-1" type="submit" [disabled]="formSubmitted || form.invalid"
                    (click)="onSubmit(); formSubmitted=true">
                    <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                        aria-hidden="true"></span>{{ 'save' | translate }}
                </button>
            </div>
        </form>
    </div>
</div>