import { Component, OnInit } from '@angular/core';
import { ConfigurationScheduleService } from 'projects/qcloud-rest-client/src/lib/grafik/configurationSchedule.service';
import { ConfigurationSchedule } from 'projects/qcloud-models/grafik/configuration/configurationSchedule.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-configuration-schedule',
  templateUrl: './configuration-schedule.component.html',
  styleUrls: ['./configuration-schedule.component.css']
})
export class ConfigurationScheduleComponent implements OnInit {

  configurationSchedule : ConfigurationSchedule;
  
  constructor(private configurationService: ConfigurationScheduleService, private toastr : ToastrService, private translateService : TranslateService) { }

  ngOnInit(): void {
    this.getConfiguration();
  }

  getConfiguration() {
    this.configurationService.getConfiguration().subscribe(
      res =>
      {
        this.configurationSchedule = res;
      }
    );
  }

  editConfiguration() {
    this.configurationService.editConfiguration(this.configurationSchedule).subscribe(
      res =>
      {
        this.configurationService.ConfigurationSchedule = res;
        this.translateService.get('workers-schedule-configuration-change-success').subscribe((res: string) => {
          this.toastr.success(res);
      });
    },
      err => { 
        this.translateService.get('workers-schedule-configuration-change-success').subscribe((res: string) => {
          this.toastr.success(res);
      });
    });
  }

}
