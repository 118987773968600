<div class="modal-header">
    <h5 class="modal-title">{{ title }}</h5>
    <button class="close" data-dismiss="modal" aria-label="Close" mat-dialog-close>
        <span aria-hidden="true">×</span>
    </button>
</div>
<div class="modal-body" mat-dialog-content>
    <table class="table table-bordered"
        *ngIf="detailsStructureTemplateGroups.length == 1 && detailsStructureTemplateGroups[0].detailsStructureTemplate?.columnNames">
        <thead>
            <tr>
                <th class="table-header-id">#</th>
                <th *ngFor="let columnName of detailsStructureTemplateGroups[0].detailsStructureTemplate.columnNames">
                    {{ columnName}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of detailsStructureTemplateGroups[0].detailsStructureTemplate.rows; let i = index">
                <td>{{ i + 1 }}</td>
                <td *ngFor="let attribute of row">{{ attribute | translate }}</td>
            </tr>
        </tbody>
    </table>
    <mat-accordion multi="true"
        *ngIf="!(detailsStructureTemplateGroups.length == 1 && detailsStructureTemplateGroups[0].detailsStructureTemplate?.columnNames) &&
        detailsStructureTemplateGroups.length == 1 && detailsStructureTemplateGroups[0].subGroups">
        <mat-expansion-panel *ngFor="let group of detailsStructureTemplateGroups">
            <mat-expansion-panel-header>
                <mat-panel-title>{{group.groupName}} </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-accordion multi="true">
                <mat-expansion-panel *ngFor="let subGroup of group.subGroups">
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{ subGroup.groupName}} </mat-panel-title>
                    </mat-expansion-panel-header>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th class="table-header-id">#</th>
                                <th *ngFor="let columnName of subGroup.detailsStructureTemplate.columnNames">
                                    {{columnName }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of subGroup.detailsStructureTemplate.rows; let i = index">
                                <td>{{ i + 1 }}</td>
                                <td *ngFor="let attribute of row">{{ attribute | translate }}</td>
                            </tr>
                        </tbody>
                    </table>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion multi="true"
        *ngIf="detailsStructureTemplateGroups.length != 1 && detailsStructureTemplateGroups[0].subGroups">
        <mat-expansion-panel *ngFor="let group of detailsStructureTemplateGroups">
            <mat-expansion-panel-header>
                <mat-panel-title>{{group.groupName}} </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-accordion multi="true">
                <mat-expansion-panel *ngFor="let subGroup of group.subGroups">
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{ subGroup.groupName}} </mat-panel-title>
                    </mat-expansion-panel-header>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th class="table-header-id">#</th>
                                <th *ngFor="let columnName of subGroup.detailsStructureTemplate.columnNames">
                                    {{columnName }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of subGroup.detailsStructureTemplate.rows; let i = index">
                                <td>{{ i + 1 }}</td>
                                <td *ngFor="let attribute of row">{{ attribute | translate }}</td>
                            </tr>
                        </tbody>
                    </table>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-expansion-panel>
    </mat-accordion>
</div>
<div>
    <button class="btn btn-danger" data-dismiss="modal" mat-dialog-close>{{ 'back' | translate }}</button>
</div>