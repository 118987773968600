<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="m-0 font-weight-bold text-primary">{{ 'default-settings' | translate }}</h5>
    </div>
    <div *ngIf="tenant" class="card-body">
        <form #form="ngForm" autocomplete="off">
            <input type="hidden" name="systemId" [(ngModel)]="tenant.systemId" [value]="tenant.systemId">
            <div class="form-group">
                *{{ 'system-name' | translate }}
                <div class="input-group">
                    <input name="systemName" #systemName="ngModel" [(ngModel)]="tenant.systemName"
                        class="form-control" placeholder="{{ 'qcloud-system' | translate }}" required>
                </div>
                <div *ngIf="systemName.invalid && (systemName.dirty || systemName.touched)"
                    class="text-danger mx-2 my-1">
                    <div *ngIf="systemName.errors.required">{{ 'location-name-required' | translate }}</div>
                </div>
            </div>

            <div class="form-group">
                {{ 'system-logo' | translate }}: 
                <button class="btn btn-primary btn-sm" data-toggle="modal" data-target="#fileManagerModal">
                    {{ 'select-logo' | translate }}
                </button>
                {{ tenant.logoPath }}
            </div>

            <!-- File Manager Modal-->
            <div class="modal fade" id="fileManagerModal" tabindex="-1" role="dialog" aria-labelledby="modalLabel"
                aria-hidden="true">
                <app-file-popup [selectedFilePath]="tenant.logoPath" [multiselect]="false"
                    (filePathToEmit)="getLogoPath($event)">
                </app-file-popup>
            </div>
            
            <div class="form-group row">
                <button class="btn btn-primary m-1" type="submit" [disabled]="formSubmitted || form.invalid"
                    (click)="onSubmit(); formSubmitted=true">
                <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                    aria-hidden="true"></span>{{ 'save-default-settings' | translate }}
                </button>
            </div>
            <label *ngIf="form.invalid" class="text-danger">*{{ 'complete-required-fields' | translate }}</label>
        </form>
    </div>
</div>