<div #pagetop class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 *ngIf="!isEditForm" class="m-0 font-weight-bold text-primary">{{ 'qcloud.add-role' | translate }}</h5>
        <div *ngIf="isEditForm" class="row">
            <h5 class="m-0 col-8 font-weight-bold text-primary">{{ 'qcloud.edit-role' | translate }}</h5>
            <div class="col-4 text-right">
                <button *ngIf="isLockedForEditing" class="btn"
                    matTooltip="{{ 'change-edit-mode' | translate }}" tooltipClass="">
                    <i class="material-icons">lock_outline</i>
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="systemQcloudRole" class="card-body">
        <form #form="ngForm" autocomplete="off">
            <div class="form-group">
                <div class="row mb-3">
                    <div class="col-6">
                        *{{ 'qcloud.role-name' | translate }}
                        <div class="input-group">
                            <input name="roleName" #roleName="ngModel" [disabled]="isLockedForEditing" [(ngModel)]="systemQcloudRole.roleName"
                                class="form-control" required>
                        </div>
                        <div *ngIf="roleName.invalid && (roleName.dirty || roleName.touched)"
                            class="text-danger mx-2 my-1">
                            <div *ngIf="roleName.errors.required">{{ 'qcloud.role-name-required' | translate }}</div>
                        </div>
                    </div>
                </div>
                <app-served-categories *ngIf="categoryMultiCtrl" [isDisabled]="isLockedForEditing" [categoryMultiCtrl]="categoryMultiCtrl"
                    [categories]="categories" [title]="servedCategories" [noSelectedCategories]="noSelectedCategories"
                    [displayCategories]="true">
                </app-served-categories>
                <app-select-user-locations *ngIf="locationMultiCtrl && locations.length>1" [isDisabled]="isLockedForEditing"
                    [locationMultiCtrl]="locationMultiCtrl" [locations]="locations" [title]="userLocations" [enabledByLocationIds]="mainRoleLocationIds">
                </app-select-user-locations>
                <mat-form-field>
                    <mat-label>{{ 'permission-level' | translate }}</mat-label>
                    <mat-select [disabled]="isLockedForEditing" matNativeControl name="role" #role="ngModel" [(ngModel)]="systemQcloudRole.rolePermissionLevel" required>
                        <mat-option *ngIf="authService.isSuperAdmin()" [value]="0">
                            {{ 'super-administrator' | translate }}
                        </mat-option>
                        <mat-option *ngIf="authService.isAdminOrHigher()" [value]="1">
                            {{ 'administrator' | translate }}
                        </mat-option>
                        <mat-option *ngIf="authService.isManagerOrHigher()" [value]="2">
                            {{ 'manager' | translate }}
                        </mat-option>
                        <mat-option [value]="3">
                            {{ 'employee' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div *ngIf="!serveETickets" class="mt-3">
                    <div class="font-weight-bold">{{ 'qcloud.role-permissions' | translate}}</div>
                    <div class="form-group">
                        <div class="item-group">
                            <mat-checkbox [disabled]="isLockedForEditing" color="primary" name="hasAccessToConsole" #hasAccessToConsole="ngModel"
                                [(ngModel)]="systemQcloudRole.hasAccessToConsole">{{ 'access-to-console' | translate }}
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="item-group">
                            <mat-checkbox [disabled]="isLockedForEditing" color="primary" name="manualCategoryCalling" #manualCategoryCalling="ngModel"
                                [(ngModel)]="systemQcloudRole.manualCategoryCalling"
                                [disabled]="!systemQcloudRole.hasAccessToConsole">
                                {{ 'manual-category-calling' | translate }}
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="item-group">
                            <mat-checkbox [disabled]="isLockedForEditing" name="canGenerateTicketsInConsole" #canGenerateTicketsInConsole="ngModel"
                                [(ngModel)]="systemQcloudRole.canGenerateTicketsInConsole" color="primary"
                                [disabled]="!systemQcloudRole.hasAccessToConsole">
                                {{ 'console-ticket-generation' | translate }}
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="item-group">
                            <mat-checkbox [disabled]="isLockedForEditing" name="hasPermissionToCallingTicketsManually"
                                #hasPermissionToCallingTicketsManually="ngModel"
                                [(ngModel)]="systemQcloudRole.hasPermissionToCallingTicketsManually" color="primary"
                                [disabled]="!systemQcloudRole.hasAccessToConsole">
                                {{ 'manual-ticket-selection-for-recall' | translate }}
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="item-group">
                            <mat-checkbox [disabled]="isLockedForEditing" name="hasPermissionToBlockingCategoryManually"
                                #hasPermissionToBlockingCategoryManually="ngModel"
                                [(ngModel)]="systemQcloudRole.hasPermissionToBlockingCategoryManually" color="primary"
                                [disabled]="!systemQcloudRole.hasAccessToConsole">
                                {{ 'category-blocking' | translate }}
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="item-group">
                            <mat-checkbox [disabled]="isLockedForEditing" *ngIf="locationService.getLocationConstraints().areEnabledTicketsReservations"
                                name="hasPermissionToReserveTickets" #hasPermissionToReserveTickets="ngModel"
                                [(ngModel)]="systemQcloudRole.hasPermissionToReserveTickets" color="primary">
                                {{ 'permission-to-reserve-tickets' | translate }}
                            </mat-checkbox>

                            <mat-checkbox [disabled]="isLockedForEditing"
                                *ngIf="!locationService.getLocationConstraints().areEnabledTicketsReservations" disabled
                                matTooltipPosition="right" matTooltipClass="tooltip-orange"
                                matTooltip="{{ 'option-available-after-extending-licence' | translate }}">
                                {{ 'permission-to-reserve-tickets' | translate }}
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="item-group">
                            <mat-checkbox [disabled]="isLockedForEditing" *ngIf="locationService.getLocationConstraints().isWorkersScheduleEnabled"
                                name="hasPermissionToConfigureGrafik" #hasPermissionToConfigureGrafik="ngModel"
                                [(ngModel)]="systemQcloudRole.hasPermissionToConfigureGrafik" color="primary">
                                {{ 'permission-to-configure-grafik-schedule' | translate }}
                            </mat-checkbox>

                            <mat-checkbox [disabled]="isLockedForEditing" *ngIf="!locationService.getLocationConstraints().isWorkersScheduleEnabled"
                                disabled matTooltipPosition="right" matTooltipClass="tooltip-orange"
                                matTooltip="{{ 'option-available-after-extending-licence' | translate }}">
                                {{ 'permission-to-configure-grafik-schedule' | translate }}
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="item-group">
                            <mat-checkbox [disabled]="isLockedForEditing" *ngIf="locationService.getLocationConstraints().areETicketsEnabled"
                                name="hasPermissionToServeETickets" #hasPermissionToServeETickets="ngModel"
                                [(ngModel)]="systemQcloudRole.hasPermissionToServeETickets" color="primary">
                                {{ 'permission-to-serve-etickets' | translate }}
                            </mat-checkbox>

                            <mat-checkbox [disabled]="isLockedForEditing" *ngIf="!locationService.getLocationConstraints().areETicketsEnabled" disabled
                                matTooltipPosition="right" matTooltipClass="tooltip-orange"
                                matTooltip="{{ 'option-available-after-extending-licence' | translate }}">
                                {{ 'permission-to-serve-etickets' | translate }}
                            </mat-checkbox>
                        </div>
                    </div>

                </div>

            </div>
            <div class="form-group row">
                <button class="btn btn-danger m-1" routerLink="/systemQcloudRoles">{{ 'back' | translate
                    }}</button>
                <button *ngIf="!isEditForm" class="btn btn-primary m-1" type="submit"
                    [disabled]="formSubmitted || form.invalid"
                    (click)="onSubmit(categoryMultiCtrl?.value); formSubmitted=true">
                    <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                        aria-hidden="true"></span>
                    {{ 'qcloud.save-role' | translate }}
                </button>
                <button *ngIf="isEditForm" [disabled]="isLockedForEditing" class="btn btn-primary m-1" type="submit"
                    [disabled]="formSubmitted || form.invalid" data-toggle="modal" data-target="#confirmSaveModal">
                    <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                        aria-hidden="true"></span>
                    {{ 'qcloud.save-role' | translate }}
                </button>
                <button *ngIf="isEditForm" [disabled]="isLockedForEditing" class="btn btn-info m-1" type="submit"
                    [disabled]="formSubmitted || form.invalid"
                    (click)="newBasedOnExisting(); pagetop.scrollIntoView({behavior: 'smooth'})">
                    <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                        aria-hidden="true"></span>
                    {{ 'qcloud.add-new-role-based-on-existing' | translate }}
                </button>
            </div>

            <label *ngIf="form.invalid" class="text-danger">*{{ 'complete-required-fields' | translate }}</label>
            <div *ngIf="isEditForm" class="modal fade" id="confirmSaveModal" tabindex="-1" role="dialog"
                aria-hidden="true">
                <app-confirm-modal [title]="'saving-system-role'" [question]="'you-definitely-want-to-save'"
                    [warningMessage]="'warning-system-role-cascade-changes-for-roles'" [confirm]="'save'"
                    (confirmed)="handleModalResult($event)"></app-confirm-modal>
            </div>
        </form>
    </div>
</div>