import { Component, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Category } from "../../../../../../qcloud-models/category/category.model";
import { QcloudRole } from "../../../../../../qcloud-models/qcloud-role/qcloud-role";
import { AuthService } from "../../../../../../qcloud-rest-client/src/lib/auth/auth.service";
import { QcloudRoleService } from "../../../../../../qcloud-rest-client/src/lib/role/qcloud-role.service";
import { NormalizeCharactersPipe } from "../../../shared/normalize-characters.pipe";
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { LdapRoleService } from "../../../../../../qcloud-rest-client/src/lib/ldap/ldap-role.service";
import { LdapRole } from "../../../../../../qcloud-models/ldap/ldap-role";

@Component({
  selector: 'app-ldap-role',
  templateUrl: './ldap-role.component.html',
  styleUrls: ['./ldap-role.component.css'],
  providers: [NormalizeCharactersPipe]
})
export class LdapRoleComponent implements OnInit {
  isEditForm: boolean;
  formSubmitted: boolean = false;
  serveETickets: boolean;
  ldapRole: LdapRole;
  qcloudRoles: QcloudRole[];
  choosenQcloudRole: QcloudRole;


  keyMaxCharacterLength: number = 30;
  protected initialCategories: Category[];
  public categoryMultiCtrl: UntypedFormControl;
  categories: Category[];
  servedCategories: string = 'served-categories';
  noSelectedCategories = 'no-selected-categories';

  constructor(public authService: AuthService, private translate: TranslateService, private toastr: ToastrService,
    private route: ActivatedRoute, private router: Router, public locationService: LocationService,
    public qcloudRoleService: QcloudRoleService, private ldapRoleService: LdapRoleService) { }

  ngOnInit(): void {

    this.choosenQcloudRole = new QcloudRole();
    let ldapRoleId: number;
    this.serveETickets = this.authService.getServeETicket() == "true";
    this.route.params.subscribe(params => {
      ldapRoleId = params['id']
      if (ldapRoleId) {
        this.isEditForm = true;
        this.getLdapRole(ldapRoleId);
      } else {
        this.isEditForm = false;
        this.initializeLdapRole();
      }
    });

  }

  initializeLdapRole() {
    this.ldapRole = new LdapRole();
    this.ldapRole.locationId = +this.authService.getLocationId();
    this.ldapRole.systemId = +this.authService.getSystemId();

    this.getQcloudRoles();
  }

  getQcloudRoles() {
    this.qcloudRoleService.getQcloudRoles()
      .subscribe(
        (res) => {
          this.qcloudRoles = res;
          if(this.ldapRole.qcloudRoleId) {
            this.setChoosenQcloudRole(this.ldapRole.qcloudRoleId);
          }
        }
      );
  }
  getLdapRole(ldapRoleId: number) {
    this.ldapRoleService.getLdapRole(ldapRoleId)
      .subscribe(
        (res) => {
          this.ldapRole = res;
          this.getQcloudRoles();
        }
      );
  }

  onSubmit() {
    if (this.isEditForm) {
      this.editLdapRole();
    } else {
      this.addQcloudRole();
    }
  }
  addQcloudRole() {
    this.ldapRoleService.addLdapRole(this.ldapRole).subscribe({
      next: () => {
        this.formSubmitted = true;
        this.router.navigate(['/ldapRoles']);
      },
      error: err => {
        var errorMessage = "ldap.role-add-error"
        if (err.error.translation === "ldap.role-key-error-not-unique") {
          errorMessage = err.error.translation;
        }
        this.formSubmitted = false;
        this.translate.get(errorMessage).subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    }
    );

  }

  editLdapRole() {
     this.ldapRoleService.updateLdapRole(this.ldapRole).subscribe({
       next: () => {
         this.formSubmitted = true;
         this.router.navigate(['/ldapRoles']);
       },
       error: err => {
         var errorMessage = "ldap.role-update-error"
         if (err.error.translation === "ldap.role-key-error-not-unique") {
           errorMessage = err.error.translation;
         }
         this.formSubmitted = false;
         this.translate.get(errorMessage).subscribe((error: string) => {
           this.translate.get('repeat').subscribe((res: string) => {
             this.toastr.error(res, error);
           });
         });
       }
     });
  }

  newBasedOnExisting() {
    this.isEditForm = false;
  }
  changeQcloudRoleFormField() {
    this.ldapRole.qcloudRoleName = this.choosenQcloudRole.roleName;
    this.ldapRole.qcloudRoleId = this.choosenQcloudRole.id;
  }
  setChoosenQcloudRole(qcloudRoleId: number) {
    this.qcloudRoles.forEach(e => {
      if (e.id == qcloudRoleId) {
        this.choosenQcloudRole = e;
      }
    });
  }

}
