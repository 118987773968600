<div class="text-container">
    <div class="text-wrapper-icon">
    </div>
    <div style="flex-grow: 3;" class="text-wrapper-service" *ngIf="showService">
        <div [fittext]="true" [activateOnResize]="true" [modelToWatch]="service">
            {{ service }}
        </div>
    </div>
    <div style="flex-grow: 1;" class="text-wrapper-ticket" *ngIf="showTicket">
        <div [fittext]="true" [activateOnResize]="true" [modelToWatch]="ticketNumber">
            {{ ticketNumber }}
        </div>
    </div>
    <div style="flex-grow: 1;" class="text-wrapper-desk" *ngIf="showDesk">
        <div [fittext]="true" [activateOnResize]="true" [modelToWatch]="desk">
            {{ desk }}
        </div>
    </div>
</div>