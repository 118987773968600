import { Injectable } from "@angular/core";
import { Language } from "projects/qcloud-models/translation/language.model";
import { Translation } from "projects/qcloud-models/translation/translation.model";
import { TranslationService } from "projects/qcloud-rest-client/src/lib/translation.service";
import { Observable, Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class TranslationConfigurationService {

    languages: Observable<Language[]>;
    mainLanguage: Observable<Language>;
    mainLanguageSubject: Subject<Language>;
    translationLanguages: Observable<Translation[]>;
    translationLanguagesSubject: Subject<Translation[]>;

    constructor(private translationService: TranslationService) {
        this.mainLanguageSubject = new Subject<Language>();
        this.mainLanguage = this.mainLanguageSubject.asObservable();
        this.translationLanguagesSubject = new Subject<Translation[]>();
        this.translationLanguages = this.translationLanguagesSubject.asObservable();;

        this.languages = this.translationService.getLanguages();
    }

    public updateMainLanguage(mainLanguage: Language) {
        this.mainLanguageSubject.next(mainLanguage);
    }

    public updateTranslationLanguages(translationLanguages: Translation[]) {
        this.translationLanguagesSubject.next(translationLanguages);
    }
}