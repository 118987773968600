<div class="rows-container">
    <div *ngFor="let row of rows.getAll().reverse();let i = index;" class="row-container" [ngStyle]="getRowHeightStyle(i)">
        <app-start-piece-bar-compatible class="text-wrapper-service" [active]="row.active" [content]="row.texts[0]" *ngIf="row.visible" [rowStyle]="rowStyle" [rowHeight]="rowHeight">
        </app-start-piece-bar-compatible>
        <app-middle-piece-bar-compatible class="text-wrapper-desk" [active]="row.active" [content]="row.texts[1]" *ngIf="row.visible" [rowStyle]="rowStyle">
        </app-middle-piece-bar-compatible>
        <app-middle-piece-bar-compatible class="text-wrapper-room" [active]="row.active" [content]="row.texts[2]" *ngIf="row.visible" [rowStyle]="rowStyle">
        </app-middle-piece-bar-compatible>
        <app-end-piece-bar-compatible class="text-wrapper-ticket" [active]="row.active" [content]="row.texts[3]" *ngIf="row.visible" [rowStyle]="rowStyle" [rowHeight]="rowHeight">
        </app-end-piece-bar-compatible>
    </div>
</div>