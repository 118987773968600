import { Component, OnInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { FileService } from 'projects/qcloud-rest-client/src/lib/file.service';
import { ToastrService } from 'ngx-toastr';
import { FileDTO } from 'projects/qcloud-models/fileManager/file-dto.model';
import { MatMenuTrigger } from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.css']
})
export class FileManagerComponent implements OnInit, AfterContentChecked {

  path: string = "";
  showPath: string = "";
  content: FileDTO[];
  folderName: string;
  selectedFiles: boolean[];
  clearUploaderQueue: boolean = false;
  allElementsLoaded: boolean = false;

  constructor(public service: FileService, private toastr: ToastrService,
    private translate: TranslateService, private cdr : ChangeDetectorRef, private dialog: MatDialog) { }


  ngOnInit(): void {
    this.getDirectoryContent();
  }

  getDirectoryContent(filesWithAdded: number = null) {
    this.allElementsLoaded = false;
    let interval = setInterval(() => {
      this.service.getDirectoryContent(this.showPath).subscribe(
        res => {
          this.content = res;
          this.selectedFiles = new Array(this.content.length);
          if (!filesWithAdded || filesWithAdded == this.content.length) {
            clearInterval(interval);
          }
        },
        err => {
          this.translate.get('file-manager-error').subscribe((res: string) => {
            let error = res;
            this.translate.get('repeat').subscribe((res: string) => {
              this.toastr.error(res, error);
            });
          });
          clearInterval(interval);
        }
      );
      this.path = this.showPath;
      this.allElementsLoaded = true;
    }, 1000);
  }

  createFolder(folderName: string) {
    this.service.createFolder(this.showPath, folderName).subscribe(
      res => {
        this.getDirectoryContent();
      },
      err => {
        this.translate.get('folder-creation-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  changeFolder(item: FileDTO) {
    this.showPath = item.path;
    this.getDirectoryContent();
  }

  setPath(path: string) {
    this.showPath = path;
    this.getDirectoryContent();
  }

  openMenu(event: MouseEvent, viewChild: MatMenuTrigger) {
    event.preventDefault();
    viewChild.openMenu();
  }

  activateLink(name: string) {
    let url = this.createLink(name);
    window.open(url);
  }

  createLink(name: string) {
    name = decodeURIComponent(name);
    let split = name.split('.');
    let extensionIdx = split.length - 1;
    return split.slice(0, extensionIdx).join(".");
  }

  delete(item: FileDTO) {
    let selected = new Array<FileDTO>(item);
    this.service.deleteSelected(selected).subscribe(
      res => {
        this.getDirectoryContent();
      },
      err => {
        this.translate.get('delete-element-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    )
  }

  deleteSelected() {
    let filesToDelete = new Array<FileDTO>();
    for (let i = 0; i < this.selectedFiles.length; i++) {
      if(this.selectedFiles[i]) {
        filesToDelete.push(this.content[i]);
      }
    }
    this.service.deleteSelected(filesToDelete).subscribe(
      res => {
        this.getDirectoryContent();
      },
      err => {
        this.translate.get('delete-elements-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    )
  }

  disableDeleteSelected() {
    return !this.selectedFiles.includes(true);
  }

  openModal(content) {
    this.dialog.open(content);
  }

  displayFilename(filename: string, i: number) {
    if(this.service.isUrl(filename)){
      filename = decodeURIComponent(filename);
    }
    let maxFileLength = 47;
    let filenameLength = filename.length - this.service.getExtension(filename).length;
    if (filenameLength <= maxFileLength || this.selectedFiles[i]) {
      return filename;
    } else {
      return filename.slice(0, maxFileLength - 3) + "...";
    }
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  onImgError(event, path: string, name: string) {
    this.translate.get('file-conversion-in-progress').subscribe((error: string) => {
      this.translate.get('please-wait').subscribe((res: string) => {
        this.toastr.info(res, error);
      });
    });
    event.target.src = 'assets/gifs/loading.gif';
    let url = this.service.getPathToImage(path, name);
    let interval = setInterval(() => {
      this.service.checkImageStatus(url).subscribe(
        res => {
          clearInterval(interval);
          event.target.src = url;
        },
        err => {
          if (err.status != 404) {
            clearInterval(interval);
            event.target.src = url;
          }
        }
      );
    }, 1000);
  }
}
