import { DatePipe } from '@angular/common';
import { CalendarEventTitleFormatter, CalendarDateFormatter } from 'angular-calendar';
import { ScheduleDateFormatter } from '../../../shared/schedule-date-formatter.provider';
import { ReservationTitleFormatter } from '../reservation-calendar/reservation-title-formatter.provider';
import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FileUploader } from 'ng2-file-upload';
import { ReservationService } from 'projects/qcloud-rest-client/src/lib/reservation.service';
import { ReservationModuleConfiguration } from 'projects/qcloud-models/reservation/reservation-module-configuration';
import { AmmsMappedReservation } from 'projects/qcloud-models/integration/amms-mapped-reservation';
import { AmmsIntegrationService } from 'projects/qcloud-rest-client/src/lib/amms-integration.service';

@Component({
  selector: 'app-reservation-import-csv',
  templateUrl: './reservation-import-csv.component.html',
  styleUrls: ['./reservation-import-csv.component.css'],
  providers: [
    DatePipe,
    {
      provide: CalendarEventTitleFormatter,
      useClass: ReservationTitleFormatter,
    },
    {
      provide: CalendarDateFormatter,
      useClass: ScheduleDateFormatter,
    }
  ]
})
export class ReservationImportCSVComponent implements OnInit {

  @Input() path: string;
  @ViewChild("fileInput") fileInput: ElementRef;
  uploader: FileUploader;
  public rows: AmmsMappedReservation[];
  public displayModule = false;
  public displayFileArray = false;
  public displaySaveButton = false;
  public hasFetchedreservationConfiguration = false;
  public hasReservations = false;
  reservationConfiguration: ReservationModuleConfiguration;

  constructor(private translate: TranslateService,
    private toastr: ToastrService, private reservationService: ReservationService,
    private datePipe: DatePipe, private ammsIntegrationService: AmmsIntegrationService) { }

  ngOnInit(): void {
    this.reservationService.getReservationConfiguration(data => {
      this.reservationConfiguration = data;
      this.hasFetchedreservationConfiguration = true;
      this.displayModule = this.reservationConfiguration.supportsCsvImport;
    }, () => { });

    let startDay = new Date();
    let endDay = new Date();
    let dayCount = 30;
    endDay.setDate(endDay.getDate()+dayCount);

    this.ammsIntegrationService.getAmmsMappedReservations( startDay, endDay, data => {
      this.rows = data;
      this.hasReservations = Boolean(this.rows.length);
      this.displayFileArray = true;
    }, () => {});
  }

  utcDateReservation(reservation: AmmsMappedReservation): AmmsMappedReservation{
    reservation.date = new Date(this.datePipe.transform(reservation.date, 'yyyy-MM-ddTHH:mm:SS') + 'Z');
    return reservation;
  }

  async onFileSelected(event: Event) {
    const target = event.target as HTMLInputElement;

    if (target.files && target.files.length > 0) {
      const file = target.files[0];
        if(file.type === 'text/csv')
        {
          await this.csvToArray(file);
          this.hasReservations = true;
          this.displayFileArray = true;
          this.displaySaveButton = true;
        }
        else{
          this.translate.get('file-type-error').subscribe((error: string) => {
            this.toastr.error(error);
            target.value = '';
          });
        }
    }
  }

  csvStringSplit(fileAsString: string): string[][]{
    let rows: string[];
    let fileAsArray: string[][] = [];
    rows = fileAsString.split(/[\r\n]+/g);
    rows.forEach((row: string) => fileAsArray = [ ...fileAsArray, row.split(/[;,]/gm)]);
    fileAsArray = fileAsArray.filter(row => row.length===5 && !row.includes(''));
    return fileAsArray;
  }

  csvToArray(file: File) {
    const reader = new FileReader();
    reader.onload = async (event) => {
      let spreadsheet = await this.csvStringSplit(event.target.result.toString());
      this.rows = spreadsheet.slice(1).map(row => {
        return new AmmsMappedReservation({
        date: new Date(row[0].replace(' ','T')),
        resourceName: row[1],
        locationName: row[2],
        pesel: row[3],
        clientName: row[4]
      });
    });
  }
    reader.readAsText(file);
  }


  onSubmit() {
    let reservations = this.rows.map(r => {return {... r}});
    reservations = reservations.map(r => this.utcDateReservation(r));
    this.ammsIntegrationService.overwriteAmmsMappedReservations( reservations, data => {
      this.translate.get('save-success').subscribe((res: string) => {
        this.toastr.success(res);
      });
    }, () => {
      this.translate.get('save-error').subscribe((error: string) => {
        this.translate.get('repeat').subscribe((res: string) => {
          this.toastr.error(res, error);
        });
    });
  });
    this.displaySaveButton = false;
    this.fileInput.nativeElement.value = '';
  }
  
}

