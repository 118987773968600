import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SmsConfiguration } from 'projects/qcloud-models/sms/sms-configuration';
import { SmsConfigService } from 'projects/qcloud-rest-client/src/lib/sms-config.service';

@Component({
  selector: 'app-configure-sms-sender',
  templateUrl: './configure-sms-sender.component.html',
  styleUrls: ['./configure-sms-sender.component.css']
})
export class ConfigureSmsSenderComponent implements OnInit {

  smsConfig: SmsConfiguration;
  formSubmitted = false;

  constructor(private smsService: SmsConfigService, private translate: TranslateService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.smsConfig = new SmsConfiguration();
    this.smsService.getSmsSender().subscribe(
      res => {
        this.smsConfig.sender = res;
      },
      err => {}
    );
  }

  onSubmit() {
    this.formSubmitted = true;
    this.smsService.setSmsSender(this.smsConfig).subscribe(
      res => {
        this.formSubmitted = false;
        this.translate.get('update-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('update-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

}
