import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { QcloudConsoleService } from '../../../qcloud-console.service';
import { TicketNoteService } from '../../../../../../qcloud-rest-client/src/lib/note/ticket-note.service';

@Component({
  selector: 'lib-call-eticket-button',
  templateUrl: './call-eticket-button.component.html',
  styleUrls: ['./call-eticket-button.component.css', './../../base-button/base-button.component.css']
})
export class CallETicketButtonComponent implements OnInit {
  @ViewChild('button') public button: ElementRef; isAddNoteEnabled: boolean;
  isLocked: boolean;
  constructor(private consoleService: QcloudConsoleService, private ticketNoteService: TicketNoteService) {
    this.consoleService.isLocked.subscribe((val) => {
      this.isLocked = val;
    });
  }

  ngOnInit(): void {
  }

  public callETicket() {
    this.consoleService.acceptAndCallTicket();
  }
}
