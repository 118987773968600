import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { DeskStatus } from 'projects/qcloud-models/desk/desk-status.enum';
import { User } from 'projects/qcloud-models/user/user.model';
import { DeskService } from 'projects/qcloud-rest-client/src/lib/desk.service';
import { UserService } from 'projects/qcloud-rest-client/src/lib/user.service';
import { QcloudStompClientService } from 'projects/qcloud-stomp-client/src/public-api';
import { Subscription } from 'rxjs';
import { UserPersonalInfoDto } from '../../../../../qcloud-models/user/user-personal-info-dto.model';

@Component({
  selector: 'app-desks',
  templateUrl: './desks.component.html',
  styleUrls: ['./desks.component.css']
})
export class DesksComponent implements OnInit, OnDestroy {

  public desks: Desk[];
  selectedDeskId: number;
  private stompDesksStatusSubscription: Subscription;
  userPersonalInfoDtos: UserPersonalInfoDto[] = [];
  DeskStatus = DeskStatus;

  constructor(private deskService: DeskService, private translate: TranslateService,
    private toastr: ToastrService,
    private qcloudStompClientService: QcloudStompClientService,
    private userService: UserService) { }

  ngOnInit(): void {
    this.getUsers();
  }

  ngOnDestroy(): void {
    this.stompDesksStatusSubscription.unsubscribe();
  }

  getDesks() {
    this.deskService.getDesks((data) => {
      this.desks = data;
      this.startDeskListening();
    },
      () => { });
  }

  getUsers() {
    this.userService.getUserPersonalInfos((data) => {
      this.userPersonalInfoDtos = data;
      this.getDesks();
    },
      () => { }
    );
  }

  onDeleteConfirm(confirmed: boolean) {
    if (confirmed) {
      this.deleteDesk(this.selectedDeskId);
    }
  }

  deleteDesk(deskId: number) {
    this.deskService.deleteDesk(deskId).subscribe(
      res => {
        this.getDesks();
      },
      err => {
        this.translate.get('delete-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  private startDeskListening() {
    var context = this;
    this.stompDesksStatusSubscription = this.qcloudStompClientService.subscribe("/exchange/deskStatus/1.1.*", (payload: any) => {
      let desk = context.desks.find(x => x.id == payload.deskId);
      desk.deskStatus = payload.deskStatus;
      desk.currentLoggedInUserId = payload.userId;
      context.refreshTable();
    });
  }

  private refreshTable() {
    this.desks = Object.assign([], this.desks);
  }

  public getUserName(userId: string) {
    if (!userId) {
      return "---";
    } else {
      return this.userPersonalInfoDtos.find(x=>x.id == userId).userName;
    }
  }

}