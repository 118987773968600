<div class="row text-center mb-1">
    <div class="col-md-4">
        <div class="btn-group">
            <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate">
                {{ 'previous' | translate }}
            </div>
            <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
                {{ 'today' | translate}}
            </div>
            <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate">
                {{ 'next' | translate }}
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):locale }}</h3>
    </div>
    <div class="col-md-4">
        <div class="btn-group">
            <div class="btn btn-primary" (click)="setView(CalendarView.Month)"
                [class.active]="view === CalendarView.Month">
                {{ 'month' | translate }}
            </div>
            <div class="btn btn-primary" (click)="setView(CalendarView.Week)"
                [class.active]="view === CalendarView.Week">
                {{ 'week' | translate }}
            </div>
            <div class="btn btn-primary" (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
                {{ 'day' | translate }}
            </div>
        </div>
    </div>
</div>
<div [ngSwitch]="view" class="mb-4">
    <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate"
        (dayClicked)="dayClicked($event.day)" [locale]="locale" [refresh]="refresh"
        (beforeViewRender)="beforeMonthViewRender($event)">
    </mwl-calendar-month-view>
    <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="calendarEvents"
        [locale]="locale" [refresh]="refresh" [dayStartHour]="dayStartHour" [dayEndHour]="dayEndHour"
        (eventClicked)="onReservationClick($event.event)"
        [hourSegments]="hourSegments">
    </mwl-calendar-week-view>
    <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="calendarEvents"
        [locale]="locale" [refresh]="refresh" (eventClicked)="onReservationClick($event.event)"
        [dayStartHour]="dayStartHour" [dayEndHour]="dayEndHour" [hourSegments]="hourSegments">
    </mwl-calendar-day-view>
</div>

<app-reservation-modal></app-reservation-modal>
