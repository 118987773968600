import { Component, OnInit } from '@angular/core';
import { Schedule } from 'projects/qcloud-models/multimedia/schedule.model';
import { MultimediaService } from 'projects/qcloud-rest-client/src/lib/multimedia.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-add-schedule',
  templateUrl: './add-schedule.component.html',
  styleUrls: ['./add-schedule.component.css']
})
export class AddScheduleComponent implements OnInit {

  schedule: Schedule;

  formSubmitted: boolean = false;
  editForm: boolean = false;
  ctx = this;

  constructor(private multimediaService: MultimediaService, private router: Router, private toastr: ToastrService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.resetForm();
  }

  onSubmit(form: NgForm) {
    this.multimediaService.createSchedule(this.schedule.name).subscribe(
      schedule => {
        this.resetForm(form);
        this.router.navigate(['/schedule/edit/' + schedule.id]);
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('create-schedule-error').subscribe((res: string) => {
          let error = res;
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  resetForm(form?:NgForm) {
    if(form != null) {
      form.resetForm();
    }
    this.schedule = {
      id: 0, systemId: 1, name: "", size: 0, playlistsWithWeeklyDisplayInformation: [], defaultPlaylist: null,
      defaultPlaylistId: 0, scheduleTagsJson: "", scheduleTags: []
    };
  }

}
