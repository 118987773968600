import { CategoryPriority } from '../category/category-priority.enum';
import { Category } from '../category/category.model';
import { SystemCategory } from '../category/system-category.model';

export class DeskCategory {
    public categoryId: number;
    public categoryPriority: CategoryPriority
    public name: string;
    public tag: string;

    constructor() {}

    public static CreateDeskCategoryBasedOnCategory(category: Category) : DeskCategory {
        var createdDeskCategory = new DeskCategory();
        createdDeskCategory.name = category.fullName;
        createdDeskCategory.tag = category.categoryTag;
        createdDeskCategory.categoryId = category.id;
        createdDeskCategory.categoryPriority = CategoryPriority.Mid;
        return createdDeskCategory;
    }
    public static CreateDeskCategoryBasedOnSystemCategory(category: SystemCategory) : DeskCategory {
        var createdDeskCategory = new DeskCategory();
        createdDeskCategory.name = category.fullName;
        createdDeskCategory.tag = category.categoryTag;
        createdDeskCategory.categoryId = category.id;
        createdDeskCategory.categoryPriority = CategoryPriority.Mid;
        return createdDeskCategory;
    }
}
