<div *ngIf="locationService.getLocationConstraints().isClientSurveyEnabled">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">{{ 'statistics-customer-survey' | translate }}</h1>
    </div>

    <div class="row">
        <div class="col-lg-6">
            <div class="card shadow mb-4">
                <app-daily-customer-survey-statistics ></app-daily-customer-survey-statistics>
            </div>
        </div>
        <div *ngIf="desks" class="col-lg-6">
            <div class="card shadow mb-4">
                <app-desk-customer-survey-statistics [desks]="desks"></app-desk-customer-survey-statistics>
            </div>
        </div>
    </div>
</div>

<app-licence-info *ngIf="!locationService.getLocationConstraints().isClientSurveyEnabled"
    [title]="'statistics-customer-survey'">
</app-licence-info>