<div #pagetop class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="m-0 font-weight-bold text-primary">{{ 'statistics-note-history-list.title' | translate }}</h5>
    </div>
    <div class="card-body">
        <div *ngIf="categoryMultiCtrl">
            <form #form="ngForm" autocomplete="off">
                <div class="row col-4">
                    <app-single-picker *ngIf="categoryMultiCtrl" [elementMultiCtrl]="categoryMultiCtrl"
                        [elements]="categories" [title]="selectCategory" [noSelectedElements]="noSelectedElements"
                        [displayElements]="true" [placeholder]="placeholder" [nomatch]="nomatch">
                    </app-single-picker>
                </div>

                <div class="row col-4">
                    <div class="input-group">
                        <div class="font-weight-bold">{{ 'invoice-number' | translate }}</div>
                    </div>
                    <div class="input-group">
                        <input name="billingNumber" #billingNumber="ngModel" [(ngModel)]="noteParams.billingNumber"
                            class="form-control">
                    </div>
                </div>

                <div class="row col-4 mt-3">
                    <mat-form-field>
                        <mat-label>{{'select-date' | translate}}</mat-label>
                        <input matInput [matDatepicker]="picker" [formControl]="dateCtrl" required>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker touchUi="true" #picker></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="form-group">
                    <app-submit-button [disabled]="form.invalid" (click)="getNoteHistory()" type="submit"
                        [text]="'get-ticket-history' | translate">
                    </app-submit-button>
                </div>

                <label *ngIf="form.invalid" class="text-danger">*{{ 'complete-required-fields' | translate }}</label>
            </form>
        </div>

        <div class="table-responsive">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{{ 'statistics-note-history-list.note-form-template-id' | translate }}</th>
                        <th>{{ 'statistics-note-history-list.saved-date-time' | translate }}</th>
                        <th>{{ 'statistics-note-history-list.ticket-note-preview' | translate }}</th>
                        <th>{{ 'option' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let note of notesHistory; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>{{ note.formNoteTemplateId }}</td>
                        <td>{{ note.saveDateTime }}</td>
                        <td>
                            <p *ngFor="let answer of getNoteAnswersPreviews(note);">
                                {{answer}}
                            </p>
                            <p *ngIf="note.ticketAnswerNotes.length > 2">
                                ...
                            </p>
                        </td>
                        <td>
                            <button class="btn btn-primary" (click)="showDetails(note)">
                                <span class="element-text">{{ 'show-details' | translate }}</span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>