import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, QueryList, ViewChild } from '@angular/core';
import { DeviceConfiguration } from 'projects/qcloud-models/device/device-configuration';
import { QcloudConsoleService } from '../../qcloud-console.service';
import { AdditionalButtonComponentName, ButtonComponentName, SlimLayoutConfigurationService } from '../../services/slim-layout-configuration.service';
import { CallTicketButtonComponent } from '../call-ticket-button/call-ticket-button.component';
import { CallAgainTicketButtonComponent } from '../call-again-ticket-button/call-again-ticket-button.component';
import { AcceptTicketButtonComponent } from '../accept-ticket-button/accept-ticket-button.component';
import { PauseTicketButtonComponent } from '../pause-ticket-button/pause-ticket-button.component';
import { RedirectTicketButtonComponent } from '../redirect-ticket-button/redirect-ticket-button.component';
import { CancelTicketButtonComponent } from '../cancel-ticket-button/cancel-ticket-button.component';
import { ExpandMenuButtonComponent } from '../expand-menu-button/expand-menu-button.component';
import { BlockedCategoriesButtonComponent } from '../blocked-categories-button/blocked-categories-button.component';
import { CallTicketManualButtonComponent } from '../call-ticket-manual-button/call-ticket-manual-button.component';
import { CancelledTicketsButtonComponent } from '../cancelled-tickets-button/cancelled-tickets-button.component';
import { GenerateTicketButtonComponent } from '../generate-ticket-button/generate-ticket-button.component';
import { PausedTicketsButtonComponent } from '../paused-tickets-button/paused-tickets-button.component';
import { ServedCategoriesButtonComponent } from '../served-categories-button/served-categories-button.component';
import { ShowTicketManualButtonComponent } from '../show-ticket-manual-button/show-ticket-manual-button.component';
import { Ticket } from 'projects/qcloud-models/console/ticket';
import { DisplayDeadlineComponent } from '../display-deadline/display-deadline.component';
import { QueueStateComponent } from '../queue-state/queue-state.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'slim-layout',
  templateUrl: './slim-layout.component.html',
  styleUrls: ['./slim-layout.component.css']
})
export class SlimConsoleLayoutComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public deviceConfiguration: DeviceConfiguration;
  @ViewChild('callTicketButtonComponent') private callTicketButtonComponent: CallTicketButtonComponent;
  @ViewChild('acceptTicketButtonComponent') private acceptTicketButtonComponent: AcceptTicketButtonComponent;
  @ViewChild('callAgainTicketButtonComponent') private callAgainTicketButtonComponent: CallAgainTicketButtonComponent;
  @ViewChild('pauseTicketButtonComponent') private pauseTicketButtonComponent: PauseTicketButtonComponent;
  @ViewChild('redirectTicketButtonComponent') private redirectTicketButtonComponent: RedirectTicketButtonComponent;
  @ViewChild('cancelTicketButtonComponent') private cancelTicketButtonComponent: CancelTicketButtonComponent;
  @ViewChild('expandMenuButtonComponent') private expandMenuButtonComponent: ExpandMenuButtonComponent;

  @ViewChild('cancelledTicketsButtonComponent') private cancelledTicketsButtonComponent: CancelledTicketsButtonComponent;
  @ViewChild('pausedTicketsButtonComponent') private pausedTicketsButtonComponent: PausedTicketsButtonComponent;
  @ViewChild('callTicketManualButtonComponent') private callTicketManualButtonComponent: CallTicketManualButtonComponent;
  @ViewChild('blockedCategoriesButtonComponent') private blockedCategoriesButtonComponent: BlockedCategoriesButtonComponent;
  @ViewChild('servedCategoriesButtonComponent') private servedCategoriesButtonComponent: ServedCategoriesButtonComponent;
  @ViewChild('generateTicketButtonComponent') private generateTicketButtonComponent: GenerateTicketButtonComponent;
  @ViewChild('showTicketManualButtonComponent') private showTicketManualButtonComponent: ShowTicketManualButtonComponent;
  @ViewChild('displayDeadlineComponent') private displayDeadlineComponent: DisplayDeadlineComponent;
  @ViewChild('queueStateComponent') private queueStateComponent: QueueStateComponent;
  @ViewChild('divBody') private divBody: ElementRef;

  isShowInteractionCard: boolean;
  isTicketCalled: boolean;
  previousTitle: string;

  gridQueueWithDeadlineTimerCssClass: string;
  gridQueueStateFieldCssClass: string;

  constructor(public consoleService: QcloudConsoleService, public slimLayoutConfigurationService: SlimLayoutConfigurationService, private titleService:Title) {
    consoleService.ticket.subscribe((val: Ticket) => { val?.caption ? this.isTicketCalled = true : this.isTicketCalled = false; });
    this.setPreviousTitle(this.titleService.getTitle());
  }

  ngOnInit(): void {
    this.isShowInteractionCard = false;
    this.updateGridDeadlineClass();
    this.updateGridQueueStateFieldCssClass();
  }

  ngOnChanges() : void {
    this.adjustScreen();
  }

  ngOnDestroy() {
    this.titleService.setTitle(this.getPreviousTitle());
  }
  checkLoadConsoleAssets() {
    return this.consoleService.desk && this.consoleService.user && this.consoleService.getAllConsoleCategories()
      && this.consoleService.getCategoriesToCallTicket();
  }

  async adjustScreen() : Promise<void> {
    while(!this.checkIfAllElementsAreLoaded()) {
      await this.delay(300);
    }
    this.previousTitle = this.titleService.getTitle();
    this.titleService.setTitle(this.getConsoleInfo());
    var layoutConfig = this.slimLayoutConfigurationService;
    var buttons = this.getViewChildButtons();
    var spanQueueStates = this.getViewChildSpanQueueStates();

    switch(this.consoleService.projectType) {
      case(this.consoleService.projectTypeEnum.WEB):
        layoutConfig.minimalizeButtonsMap<ButtonComponentName>(buttons);
        layoutConfig.positionSpanQueueState(spanQueueStates);
      break;

      case(this.consoleService.projectTypeEnum.DEVICE):
        layoutConfig.minimalizeDeviceButtonsMap<ButtonComponentName>(buttons);
        layoutConfig.positionDeviceSpanQueueState(spanQueueStates);
      break;
    }

    layoutConfig.positionDivQueueState(this.queueStateComponent.divQueueState);
    this.adjustExpandedMenu();
  }

  adjustExpandedMenu() : void {
    var layoutConfig = this.slimLayoutConfigurationService;
    var additionalButtons = this.getViewChildAdditionalButtons();
    layoutConfig.minimalizeAdditionalButtonsMap<AdditionalButtonComponentName>(additionalButtons);

    var additionalButtonsSectorTwo = this.getViewChildAdditionalButtonsSectorTwo();
    layoutConfig.minimalizeAdditionalButtonsMapSectorTwo<AdditionalButtonComponentName>(additionalButtonsSectorTwo);
  }

  private getViewChildButtons() : Map<ButtonComponentName, ElementRef> {
    var buttonComponentName = this.slimLayoutConfigurationService.ButtonComponentNameEnum;
    var buttons = new Map<ButtonComponentName, ElementRef>();
    buttons.set(buttonComponentName.CallTicketButtonComponent, this.callTicketButtonComponent?.button);
    buttons.set(buttonComponentName.CallAgainTicketButtonComponent, this.callAgainTicketButtonComponent?.button);
    buttons.set(buttonComponentName.AcceptTicketButtonComponent, this.acceptTicketButtonComponent?.button);
    buttons.set(buttonComponentName.PauseTicketButtonComponent, this.pauseTicketButtonComponent?.button);
    buttons.set(buttonComponentName.RedirectTicketButtonComponent, this.redirectTicketButtonComponent?.button);
    buttons.set(buttonComponentName.CancelTicketButtonComponent, this.cancelTicketButtonComponent?.button);
    buttons.set(buttonComponentName.ExpandMenuButtonComponent, this.expandMenuButtonComponent?.button);
    return buttons;
  }

  private getViewChildAdditionalButtons() : Map<AdditionalButtonComponentName, ElementRef> {
    var buttonComponentName = this.slimLayoutConfigurationService.AdditionalButtonComponentName;
    var buttons = new Map<AdditionalButtonComponentName, ElementRef>();
    buttons.set(buttonComponentName.CancelledTicketsButton, this.cancelledTicketsButtonComponent?.button);
    buttons.set(buttonComponentName.PausedTicketsButton, this.pausedTicketsButtonComponent?.button);
    buttons.set(buttonComponentName.CallTicketManualButton, this.callTicketManualButtonComponent?.button);
    buttons.set(buttonComponentName.BlockedCategoriesButton, this.blockedCategoriesButtonComponent?.button);
    buttons.set(buttonComponentName.ServedCategoriesButton, this.servedCategoriesButtonComponent?.button);
    buttons.set(buttonComponentName.GenerateTicketButton, this.generateTicketButtonComponent?.button);
    buttons.set(buttonComponentName.ShowTicketManualButton, this.showTicketManualButtonComponent?.button);
    return buttons;
  }

  private getViewChildAdditionalButtonsSectorTwo() : Map<AdditionalButtonComponentName, ElementRef> {
    var buttonComponentName = this.slimLayoutConfigurationService.AdditionalButtonComponentName;
    var buttons = new Map<AdditionalButtonComponentName, ElementRef>();
    buttons.set(buttonComponentName.BlockedCategoriesButton, this.blockedCategoriesButtonComponent?.button);
    buttons.set(buttonComponentName.ServedCategoriesButton, this.servedCategoriesButtonComponent?.button);
    buttons.set(buttonComponentName.GenerateTicketButton, this.generateTicketButtonComponent?.button);
    return buttons;
  }




  getViewChildSpanQueueStates() : ElementRef[] {
    var queueStates = [];
    if(this.queueStateComponent?.spanCategoryStates){
      this.queueStateComponent.spanCategoryStates.forEach(component => queueStates.push(component));
    }
    if(this.queueStateComponent?.spanCategoryTags){
      this.queueStateComponent.spanCategoryTags.forEach(component => queueStates.push(component));
    }
    if(this.queueStateComponent?.spanFaReplyIcon){
      queueStates.push(this.queueStateComponent.spanFaReplyIcon);
    }
    if(this.queueStateComponent?.spanFaReplyText){
      queueStates.push(this.queueStateComponent.spanFaReplyText);
    }
    return queueStates;
  }

  private checkIfAllElementsAreLoaded() : boolean {
    var buttons = this.getViewChildButtons();
    var additionalButtons = this.getViewChildAdditionalButtons();
    var isAllLoaded = true;
    isAllLoaded = this.checkIfAllElementsAreLoadedMap<ButtonComponentName>(buttons, isAllLoaded);
    isAllLoaded = this.checkIfAllElementsAreLoadedMap<AdditionalButtonComponentName>(additionalButtons, isAllLoaded);

    var spanQueueStates = this.getViewChildSpanQueueStates();
    isAllLoaded = this.checkIfAllElementsAreLoadedArray(spanQueueStates, isAllLoaded);
    isAllLoaded = this.displayDeadlineComponent ? isAllLoaded && true : false;
    isAllLoaded = this.queueStateComponent?.spanFaReplyIcon ? isAllLoaded && true : false;
    isAllLoaded = this.queueStateComponent?.spanFaReplyText ? isAllLoaded && true : false;
    var expandMenuButtonSectorTwo = this.getViewChildAdditionalButtonsSectorTwo();
    isAllLoaded = this.checkIfAllElementsAreLoadedMap(expandMenuButtonSectorTwo, isAllLoaded);
    return isAllLoaded;
  }

  private checkIfAllElementsAreLoadedMap<E>(elements: Map<E, ElementRef>, isAllLoaded: boolean) : boolean {
    elements.forEach((element: ElementRef, key: E) => {
      isAllLoaded = element ? isAllLoaded && true : false;
    });
    return isAllLoaded;
  }
  private checkIfAllElementsAreLoadedArray(elements: ElementRef[], isAllLoaded: boolean) : boolean {
    elements.forEach((element: ElementRef) => {
      isAllLoaded = element ? isAllLoaded && true : false;
    });
    return isAllLoaded;
  }

  public showInteractionCard() : void {
    this.moveToPageBegining();
    this.isShowInteractionCard = true;
  }

  public hideInteractionCard() : void {
    this.isShowInteractionCard = false;
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
  moveToExpandedMenu() : void {
    if(this.expandMenuButtonComponent.isMenuExpanded) {
      this.servedCategoriesButtonComponent.button.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
  moveToPageBegining() {
    this.divBody.nativeElement.scrollIntoView();
  }
  getConsoleInfo()
  {
    return this.consoleService.desk.fullName +": "+ this.consoleService.user.firstName + " " + this.consoleService.user.lastName;
  }

  private getPreviousTitle() : string{
    return this.previousTitle;
  }
  private setPreviousTitle(title : string) : void {
    this.previousTitle = title;
  }

  updateGridDeadlineClass() : void {
    switch(this.consoleService.projectType) {
      case(this.consoleService.projectTypeEnum.DEVICE):
        this.gridQueueWithDeadlineTimerCssClass = 'device-grid-queue-state-field-with-deadline-timer';
      break;
      case(this.consoleService.projectTypeEnum.WEB):
        this.gridQueueWithDeadlineTimerCssClass = 'grid-queue-state-field-with-deadline-timer';
      break;
      default:
        this.gridQueueWithDeadlineTimerCssClass = 'grid-queue-state-field-with-deadline-timer';
        break;
    }
  }

  updateGridQueueStateFieldCssClass() : void {
    switch(this.consoleService.projectType) {
      case(this.consoleService.projectTypeEnum.DEVICE):
        this.gridQueueStateFieldCssClass = 'device-grid-queue-state-field';
      break;
      case(this.consoleService.projectTypeEnum.WEB):
        this.gridQueueStateFieldCssClass = 'grid-queue-state-field';
      break;
      default:
        this.gridQueueStateFieldCssClass = 'grid-queue-state-field';
        break;
    }
  }

}