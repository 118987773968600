import { Component, OnInit, Input } from "@angular/core";
import { RowStyle } from "projects/qcloud-models/device/row-style";
import { MultimediaService } from "projects/qcloud-rest-client/src/lib/multimedia.service";

@Component({
  selector: 'app-middle-piece-bar-compatible',
  templateUrl: './middle-piece-bar-compatible.component.html',
  styleUrls: ['./middle-piece-bar-compatible.component.css']
})
export class MiddlePieceBarCompatibleComponent implements OnInit {

  @Input() active: boolean;
  @Input() content: string;
  @Input() rowStyle: RowStyle;

  constructor(private multimediaClient: MultimediaService) { }

  ngOnInit() {
  }

  getStyle() {
    let styleObj = {};
    if (this.rowStyle.borderColor) {
      styleObj["border-color"] = this.rowStyle.borderColor;
    }
    if (this.rowStyle.borderSize) {
      styleObj["border-width"] = `${this.rowStyle.borderSize}px`;
    }
    if (this.rowStyle.backgroundType == 0 && this.rowStyle.backgroundColor) {
      styleObj["background"] = `${this.rowStyle.backgroundColor}`;
    }
    else if (this.rowStyle.backgroundType == 1) {
      styleObj["background"] = ``;
    }
    else {
      styleObj["background"] = `linear-gradient(to bottom, #c4d3dd 0%, #b1c7d4 50%, #b1c7d4 51%, #abc3d1 100%)`
    }
    return styleObj;
  }

  getBackgroundStyle() {
    let styleObj = {};
    if (this.rowStyle.backgroundImage && this.rowStyle.backgroundType == 1) {
      styleObj["background-image"] = `url('${this.multimediaClient.getFileUriFromCdn(this.rowStyle.backgroundImage)}')`;
    }
    return styleObj;
  }
}