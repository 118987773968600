export class PlaylistFile {
    public filePath: string;
    public size: number;
    public showLength: number;

    constructor(path: string, size: number, showLength: number) {
        this.filePath = path;
        this.size = size;
        this.showLength = showLength;
    }
}