<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 *ngIf="!ctx.editForm" class="col-10 m-0 font-weight-bold text-primary">{{ 'add-playlist' | translate }}</h5>
        <div *ngIf="ctx.editForm" class="row">
            <h5 class="col-8 m-0 font-weight-bold text-primary">{{ 'edit-playlist' | translate }}</h5>
            <div class="text-right col-4">
                <h4 class="small font-weight-bold text-left">{{ 'playlist-size' | translate }}
                    <span class="float-right">1 GB</span>
                </h4>
                <div class="progress">
                    <div class="progress-bar bg-info" role="progressbar" [style.width.%]="getPlaylistSpace()">
                        <span *ngIf="getPlaylistSpace() > 15">
                            {{ ctx.playlist.size / 1024 / 1024 / 1024 | number : '1.2-2' }} GB
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="ctx.playlist" class="card-body">
        <form #form="ngForm" autocomplete="off">
            <div *ngIf="!ctx.editForm" class="form-group">
                *{{ 'playlist-name' | translate }}
                <div class="input-group">
                    <input name="name" #name="ngModel" [(ngModel)]="ctx.playlist.name"
                        class="form-control" required>
                </div>
                <div *ngIf="name.invalid && (name.dirty || name.touched)" class="text-danger mx-2 my-1">
                    <div *ngIf="name.errors.required">{{ 'playlist-name-required' | translate }}</div>
                </div>
            </div>

            <div *ngIf="ctx.editForm">

                <div class="form-group">
                    *{{ 'playlist-name' | translate }}
                    <div class="input-group">
                        <input name="name" #name="ngModel" [(ngModel)]="ctx.playlist.name"
                            class="form-control" (ngModelChange)="changePlaylistName(ctx.playlist)" required>
                    </div>
                    <div *ngIf="name.invalid && (name.dirty || name.touched)" class="text-danger mx-2 my-1">
                        <div *ngIf="name.errors.required">{{ 'playlist-name-required' | translate }}</div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="input-group">
                        <p class="font-weight-bold">{{ 'playlist-elements' | translate }}:</p>
                        <button class="btn btn-primary btn-sm btn-circle mx-1" matTooltip="{{ 'add' | translate }}"
                            tooltipClass="tooltip-primary" data-toggle="modal" data-target="#fileManagerModal">
                            <i class="fas fa-fw fa-plus"></i>
                        </button>

                        <!-- File Manager Modal-->
                        <div class="modal fade" id="fileManagerModal" tabindex="-1" role="dialog" aria-labelledby="modalLabel"
                            aria-hidden="true">
                            <app-file-popup [multiselect]="true" (filesWithDurationsToEmit)="addPlaylistItemsWithDuration($event)">
                            </app-file-popup>
                        </div>

                        <button class="btn btn-info btn-sm btn-circle" matTooltip="{{ 'set-duration-of-all-elements' | translate }}"
                            tooltipClass="tooltip-info" (click)="setDurationOfAllElements = !setDurationOfAllElements">
                            <i class="fas fa-clock"></i>
                        </button>

                        <div *ngIf="setDurationOfAllElements" class="input-group mb-2">
                            <input class="form-control time-input" type="number" [(ngModel)]="allMinutes" name="allMinutes"
                                maxlength="3" min="0"/>{{ 'minutes-shortcut' | translate}}
                            <input class="form-control time-input ml-1" type="number" [(ngModel)]="allSeconds" name="allSeconds"
                                maxlength="3" min="0"/>{{ 'seconds-shortcut' | translate }}
                            <button class="btn btn-info btn-sm ml-1" (click)="setTimeOfAllElements()">
                                {{ 'set-duration-of-all-elements' | translate }}
                            </button>
                        </div>

                    </div>
                </div>

                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{{ 'order' | translate }}</th>
                                <th>{{ 'file-name' | translate }}</th>
                                <th>{{ 'duration' | translate }}</th>
                                <th>{{ 'delete' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of ctx.playlist.playlistItems; let i = index">
                                <td>
                                    {{ i + 1 }}
                                </td>
                                <td>
                                    <button *ngIf="i != 0" class="btn btn-primary btn-sm btn-circle mr-1"
                                        (click)="changeOrder(i, -1)">
                                        <i class="fas fa-arrow-up"></i>
                                    </button>
                                    <button *ngIf="(i + 1) != ctx.playlist.playlistItems.length"
                                        class="btn btn-primary btn-sm btn-circle" (click)="changeOrder(i, 1)">
                                        <i class="fas fa-arrow-down"></i>
                                    </button>
                                </td>
                                <td>
                                    <p *ngIf="getName(item.filePath).length > 30">
                                        {{ getName(item.filePath) | removeExtention | slice:0:30 }}...
                                    </p>
                                    <p *ngIf="!(getName(item.filePath).length > 30)">
                                        {{ getName(item.filePath) | removeExtention }}
                                    </p>
                                </td>
                                <td>
                                    <div class="input-group">
                                        <input class="form-control time-input" type="number" name="showLenghtMinutes{{i}}"
                                            [(ngModel)]="showLengthMinutes[i]" (ngModelChange)="changeItemTime(item, i)"
                                            maxlength="3" min="0"/>
                                        {{ 'minutes-shortcut' | translate}}
                                        <input class="form-control time-input ml-1" type="number" name="showLenghtSeconds{{i}}"
                                            [(ngModel)]="showLengthSeconds[i]" (ngModelChange)="changeItemTime(item, i)"
                                            maxlength="3" min="0"/>
                                        {{ 'seconds-shortcut' | translate }}
                                    </div>
                                </td>
                                <td>
                                    <button class="btn btn-danger btn-sm btn-circle" (click)="deleteItemFromPlaylist(item, i)">
                                        <i class="fas fa-fw fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="form-group row">
                <button class="btn btn-danger m-1" routerLink="/playlists">{{ 'back' | translate }}</button>
                <button *ngIf="!ctx.editForm" class="btn btn-primary m-1" type="submit" [disabled]="ctx.formSubmitted || form.invalid"
                    (click)="ctx.onSubmit(form); ctx.formSubmitted=true">
                    <span *ngIf="ctx.formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                        aria-hidden="true"></span>
                    {{ 'save-playlist' | translate }}
                </button>
            </div>
            <label *ngIf="form.invalid" class="text-danger">*{{ 'complete-required-fields' | translate }}</label>
        </form>
    </div>
</div>