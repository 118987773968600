<div class="form-group">
    <div class="input-group">
        <mat-form-field color="primary">
            <mat-label>{{ 'system-tags' | translate }}: {{ 'locations' | translate }}</mat-label>
            <mat-select [formControl]="locationMultiCtrl" [compareWith]="compareLocations" [multiple]="true"
                #multiSelect>
                <mat-option>
                    <ngx-mat-select-search [showToggleAllCheckbox]="true" (toggleAll)="toggleSelectAll($event)"
                        [formControl]="locationMultiFilterCtrl"
                        toggleAllCheckboxTooltipMessage="{{ 'select-unselect-all' | translate }}"
                        [toggleAllCheckboxTooltipPosition]="'above'"
                        placeholderLabel="{{ 'search-location' | translate }}"
                        noEntriesFoundLabel="{{ 'no-matching-location' | translate }}">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let location of filteredLocationsMulti | async" [value]="location">
                    <b>{{ location.id }}</b>:
                    {{ location.locationName }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <p *ngIf="locationMultiCtrl.value == null || locationMultiCtrl?.value.length == 0">
        {{ 'no-selected-locations' | translate }}</p>
    <div class="input-group">
        <span *ngFor="let location of locationMultiCtrl?.value" class="badge badge-info mr-1">
            {{ location.id }}
        </span>
    </div>
</div>