export class FileDuration {
    public filePath: string;
    public size: number;
    public minutes: number;
    public seconds: number;

    constructor(filePath: string, size: number, minutes: number, seconds: number) {
        this.filePath = filePath;
        this.size = size;
        this.minutes = minutes;        
        this.seconds = seconds;
    }
}