import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { QcloudConsoleService } from '../../qcloud-console.service';

@Component({
  selector: 'lib-close-all-modals-button',
  templateUrl: './close-all-modals-button.component.html',
  styleUrls: ['./close-all-modals-button.component.css', './../base-button/base-button.component.css']
})
export class CloseAllModalsButtonComponent implements OnInit {
  @ViewChild('button') public button: ElementRef;
  @Input() public isDisabled: boolean = false;
  @Output() hideInteractionModel = new EventEmitter<boolean>();

  constructor(private consoleService: QcloudConsoleService) { }

  ngOnInit(): void {
  }

  closeAllModals() {
    this.hideInteractionModel.emit(true);
    this.consoleService.closeAllModals();
    this.consoleService.setIsLockedStatus(false);
  }

}
