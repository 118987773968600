<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="m-0 font-weight-bold text-primary">{{ 'reports' | translate }}</h5>
    </div>
    <div *ngIf="categories && desks && users" class="card-body">
        <form #form="ngForm" autocomplete="off">
            <app-date-range-picker [startDate]="ticketReportParams.reportBeginTime"
                [endDate]="ticketReportParams.reportEndTime" (rangeToEmit)="getRange($event)" required>
            </app-date-range-picker>
            <app-select-user-locations *ngIf="locationMultiCtrl && locations.length>1" [locationMultiCtrl]="locationMultiCtrl"
                [locations]="locations" [title]="reportLocations" [enabledByLocationIds]="mainRoleLocationIds" (locationsToEmit)="locationChanged($event)" required>
            </app-select-user-locations>
            <mat-form-field>
                <mat-label>{{ 'file-type' | translate }}</mat-label>
                <mat-select matNativeControl name="fileType" #fileType="ngModel"
                    [(ngModel)]="ticketReportParams.fileType" required>
                    <mat-option [value]="FileType.Excel">Excel</mat-option>
                    <mat-option [value]="FileType.Pdf">PDF</mat-option>
                    <mat-option [value]="FileType.Csv">CSV</mat-option>
                    <mat-option [value]="FileType.Xml">XML</mat-option>
                    <mat-option [value]="FileType.Json">JSON</mat-option>
                </mat-select>
            </mat-form-field>
            <div>
                <mat-form-field>
                    <mat-label>{{ 'report-template' | translate }}</mat-label>
                    <mat-select matNativeControl (selectionChange)="assignTemplateParameters($event.value.reportTemplateParameters, $event.value.id)" required>
                        <mat-option *ngFor="let template of reportTemplates"
                            [value]="template">{{ template.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div>
                <app-report-template *ngIf="reportTemplateParameters"
                    [reportTemplateParameters]="reportTemplateParameters" [users]="users" [desks]="desks"
                    [categories]="categories" [reportTemplateParameterElementsOriginal]="reportTemplateParameterElementsOriginal"
                    [updateView]="updateViewReportTemplateParameters">
                </app-report-template>
            </div>

            <div class="form-group">
                <app-submit-button [loading]="generatingReport" [disabled]="form.invalid || !isFormReadyForSubmit()" (click)="requestToGenerateReport()"
                    type="submit" [text]="'generate-report' | translate">
                </app-submit-button>
            </div>

            <label *ngIf="form.invalid || !isFormReadyForSubmit()" class="text-danger">*{{ 'complete-required-fields' | translate }}</label>
        </form>
    </div>
</div>

<div *ngIf="reportTemplates">
    <app-report-templates [reportTemplates]="reportTemplates" (updatedTemplates)="getTemplates()" [users]="users"
        [desks]="desks" [categories]="categories">
    </app-report-templates>
</div>
<div>
    <app-report-generation-task [reportTemplates]="reportTemplates">

    </app-report-generation-task>
</div>