import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { NgxSliderModule } from 'ngx-slider-v2';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatMenuModule } from '@angular/material/menu';
import { FileUploadModule } from "ng2-file-upload";
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { NgxEditorModule } from 'ngx-editor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { QcloudRestClientModule } from '../../../qcloud-rest-client/src/lib/qcloud-rest-client.module';
import { DashboardComponent } from './page/dashboard/dashboard.component';
import { DesksComponent } from './page/desks/desks.component';
import { QCloudRestClientService } from '../../../qcloud-rest-client/src/lib/qcloud-rest-client.service';
import { ConfigurationService } from './configuration.service';
import { ConsoleComponent } from './page/console/console.component';
import { CategoriesComponent } from './page/categories/categories.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { UsersComponent } from './page/users/users.component';
import { TimeZoneComponent } from './page/system-settings/locations/location/time-zone/time-zone.component';
import { DevicesComponent } from './page/devices/devices.component';
import { ServedCategoriesComponent } from './shared/served-categories/served-categories.component';
import { TicketDispenserComponent } from './page/devices/deviceTypes/ticket-dispenser/ticket-dispenser.component';
import { MainDisplayComponent } from './page/devices/deviceTypes/main-display/main-display.component';
import { DeskDisplayComponent } from './page/devices/deviceTypes/desk-display/desk-display.component';
import { OfficeConsoleComponent } from './page/devices/deviceTypes/office-console/office-console.component';
import { OfficeDisplayComponent } from './page/devices/deviceTypes/office-display/office-display.component';
import { CustomerSurveyDisplayComponent } from './page/devices/deviceTypes/customer-survey-display/customer-survey-display.component';
import { DeviceTypePipe } from './shared/device-type.pipe';
import { FilePopupComponent } from './shared/file-popup/file-popup.component';
import { CategoriesColumnComponent } from './shared/table-column/categories-column/categories-column.component';
import { DeskCategoriesColumnComponent } from './shared/table-column/desk-categories-column/desk-categories-column.component';
import { DesksColumnComponent } from './shared/table-column/desks-column/desks-column.component';
import { DeviceStateColumnComponent } from './shared/table-column/device-state-column/device-state-column.component';
import { DeviceActivationColumnComponent } from './shared/table-column/device-activation-column/device-activation-column.component';
import { DeviceCustomizationComponent } from './page/devices/device-customization/device-customization.component';
import { FileManagerComponent } from './page/file-manager/file-manager.component';
import { FileUploaderComponent } from './page/file-manager/file-uploader/file-uploader.component';
import { FileExtentionPipe } from './shared/file-extention.pipe';
import { RemoveExtentionPipe } from './shared/remove-extention.pipe';
import { TicketLayoutComponent } from './page/location-configuration/ticket-layout/ticket-layout.component';
import { MaintenanceComponent } from './page/location-configuration/maintenance/maintenance.component';
import { ResetCategoriesComponent } from './page/location-configuration/maintenance/reset-categories/reset-categories.component';
import { GroupCategoriesComponent } from './page/location-configuration/maintenance/group-categories/group-categories.component';
import { TicektsResetUnblockCategoriesComponent } from './page/location-configuration/maintenance/ticekts-reset-unblock-categories/ticekts-reset-unblock-categories.component';
import { DndGroupCategoriesComponent } from './shared/dnd-group-categories/dnd-group-categories.component';
import { FilePathComponent } from './page/file-manager/file-path/file-path.component';
import { LinkUploaderComponent } from './page/file-manager/link-uploader/link-uploader.component';
import { PlaylistsComponent } from './page/multimedia/playlists/playlists.component';
import { AddPlaylistComponent } from './page/multimedia/playlists/add-playlist/add-playlist.component';
import { EditPlaylistComponent } from './page/multimedia/playlists/edit-playlist/edit-playlist.component';
import { PlaylistLayoutComponent } from './page/multimedia/playlists/playlist-layout/playlist-layout.component';
import { PersonalisationComponent } from './page/location-configuration/personalisation/personalisation.component';
import { LanguagesAndTranslationsComponent } from './page/location-configuration/languages-and-translations/languages-and-translations.component';
import { LanguagesSelectionComponent } from './page/location-configuration/languages-and-translations/languages-selection/languages-selection.component';
import { MultimediaDevicesComponent } from './page/multimedia/multimedia-devices/multimedia-devices.component';
import { DevicesTableComponent } from './page/multimedia/multimedia-devices/devices-table/devices-table.component';
import { TagsTableComponent } from './page/multimedia/multimedia-devices/tags-table/tags-table.component';
import { AssignTagsComponent } from './page/multimedia/multimedia-devices/devices-table/assign-tags/assign-tags.component';
import { AssignDevicesComponent } from './page/multimedia/multimedia-devices/tags-table/assign-devices/assign-devices.component';
import { PersonalisationTranslationComponent } from './page/location-configuration/languages-and-translations/personalisation-translation/personalisation-translation.component';
import { CategoryTranslationComponent } from './page/location-configuration/languages-and-translations/category-translation/category-translation.component';
import { TicketTranslationComponent } from './page/location-configuration/languages-and-translations/ticket-translation/ticket-translation.component';
import { DeskTranslationComponent } from './page/location-configuration/languages-and-translations/desk-translation/desk-translation.component';
import { DeviceGroupTranslationComponent } from './page/location-configuration/languages-and-translations/device-group-translation/device-group-translation.component';
import { ScheduleComponent } from './page/multimedia/schedule/schedule.component';
import { AddScheduleComponent } from './page/multimedia/schedule/add-schedule/add-schedule.component';
import { EditScheduleComponent } from './page/multimedia/schedule/edit-schedule/edit-schedule.component';
import { ScheduleLayoutComponent } from './page/multimedia/schedule/schedule-layout/schedule-layout.component';
import { CalendarComponent } from './page/multimedia/schedule/schedule-layout/calendar/calendar.component';
import { DateRangePickerComponent } from './shared/date-range-picker/date-range-picker.component';
import { TimeRangePickerComponent } from './page/multimedia/schedule/schedule-layout/time-range-picker/time-range-picker.component';
import { AssignSystemTagDeviceTypeComponent } from './page/multimedia/schedule/schedule-layout/assign-system-tag-device-type/assign-system-tag-device-type.component';
import { AssignSystemTagLocationComponent } from './page/multimedia/schedule/schedule-layout/assign-system-tag-location/assign-system-tag-location.component';
import { PaginationComponent } from './page/location-configuration/languages-and-translations/pagination/pagination.component';
import { LocationsComponent } from './page/system-settings/locations/locations.component';
import { LocationComponent } from './page/system-settings/locations/location/location.component';
import { SelectLocationComponent } from './page/select-location/select-location.component';
import { DefaultSettingsComponent } from './page/system-settings/default-settings/default-settings.component';
import { ProfileComponent } from './page/profile/profile.component';
import { ChangePasswordComponent } from './page/profile/change-password/change-password.component';
import { LicenseComponent } from './page/system-settings/license/license.component';
import { ConfirmRejectButtonComponent } from './shared/buttons/confirm-reject-button/confirm-reject-button.component';
import { EmailConfigComponent } from './page/system-settings/email-config/email-config.component';
import { DeleteModalComponent } from './shared/delete-modal/delete-modal.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { QcloudConsoleModule } from 'projects/qcloud-console/src/public-api';
import { CategoryBlockingScheduleComponent } from './page/location-configuration/maintenance/category-blocking-schedule/category-blocking-schedule.component';
import { FooterComponent } from './main/footer/footer.component';
import { NavbarFooterComponent } from './main/navbar-footer/navbar-footer.component';
import { NavbarComponent } from './main/navbar/navbar.component';
import { TicketStatisticsComponent } from './page/statistics/ticket-statistics/ticket-statistics.component';
import { ReservationStatisticsComponent } from './page/statistics/reservation-statistics/reservation-statistics.component';
import { CustomerSurveyStatisticsComponent } from './page/statistics/customer-survey-statistics/customer-survey-statistics.component';
import { ReportStatisticsComponent } from './page/statistics/report-statistics/report-statistics.component';
import { TicketHistoryStatisticsComponent } from './page/statistics/ticket-history-statistics/ticket-history-statistics.component';
import { QueueStateStatisticsComponent } from './page/statistics/ticket-statistics/queue-state-statistics/queue-state-statistics.component';
import { AverageWaitingServiceTimeStatisticsComponent } from './page/statistics/ticket-statistics/average-waiting-service-time-statistics/average-waiting-service-time-statistics.component';
import { TicketResultStatisticsComponent } from './page/statistics/ticket-statistics/ticket-result-statistics/ticket-result-statistics.component';
import { WaitingTimeCategoryStatisticsComponent } from './page/statistics/ticket-statistics/waiting-time-category-statistics/waiting-time-category-statistics.component';
import { ServiceTimeCategoryStatisticsComponent } from './page/statistics/ticket-statistics/service-time-category-statistics/service-time-category-statistics.component';
import { TicketInCategoryStatisticsComponent } from './page/statistics/ticket-statistics/ticket-in-category-statistics/ticket-in-category-statistics.component';
import { DailyQueueingStatisticsComponent } from './page/statistics/ticket-statistics/daily-queueing-statistics/daily-queueing-statistics.component';
import { WaitingTimeDeskStatisticsComponent } from './page/statistics/ticket-statistics/waiting-time-desk-statistics/waiting-time-desk-statistics.component';
import { ServiceTimeDeskStatisticsComponent } from './page/statistics/ticket-statistics/service-time-desk-statistics/service-time-desk-statistics.component';
import { StatisticsAverageBarComponent } from './shared/statistics-average-bar/statistics-average-bar.component';
import { DeskCustomerSurveyStatisticsComponent } from './page/statistics/customer-survey-statistics/desk-customer-survey-statistics/desk-customer-survey-statistics.component';
import { DailyCustomerSurveyStatisticsComponent } from './page/statistics/customer-survey-statistics/daily-customer-survey-statistics/daily-customer-survey-statistics.component';
import { TopbarLogoComponent } from './main/navbar/topbar-logo/topbar-logo.component';
import { ReservationComponent } from './page/reservation/reservation.component';
import { ReservationCalendarComponent } from './page/reservation/reservation-calendar/reservation-calendar.component';
import { ReservationConfigurationPageComponent } from './page/reservation/reservation-configuration-page/reservation-configuration-page.component';
import { ReservationImportCSVComponent } from './page/reservation/reservation-import-csv/reservation-import-csv.component';
import { AxxonIntelectIntegrationPageComponent } from './page/Integrations/axxon-intelect-Integration-page';
import { TicketInDeskStatisticsComponent } from './page/statistics/ticket-statistics/ticket-in-desk-statistics/ticket-in-desk-statistics.component';
import { TicketInCategoryAndDeskStatisticsComponent } from './page/statistics/ticket-statistics/ticket-in-category-and-desk-statistics/ticket-in-category-and-desk-statistics.component';
import { LicenceInfoComponent } from './shared/licence-info/licence-info.component';
import { CustomerSurveyComponent } from './page/additional-modules/customer-survey/customer-survey.component';
import { WorkScheduleComponent } from './page/additional-modules/work-schedule/work-schedule.component';
import { SmsNotificationsComponent } from './page/additional-modules/sms-notifications/sms-notifications.component';
import { ReservationQuestionaireComponent } from './page/reservation/reservation-configuration-page/reservation-questionaire/reservation-questionaire.component';
import { ReservationModalComponent } from './page/reservation/reservation-calendar/reservation-modal/reservation-modal.component';
import { SystemLicenseComponent } from './page/system-settings/license/system-license/system-license.component';
import { LocationsLicenseComponent } from './page/system-settings/license/locations-license/locations-license.component';
import { ReservationModalQuestionsComponent } from './page/reservation/reservation-calendar/reservation-modal/reservation-modal-questions/reservation-modal-questions.component';
import { CategoryComponent } from './page/categories/category/category.component';
import { WeeklyActivityComponent } from './shared/weekly-activity/weekly-activity.component';
import { CategoryActivityBlocksComponent } from './page/categories/category/category-activity-blocks/category-activity-blocks.component';
import { EmployeesListComponent } from './page/additional-modules/work-schedule/employees-list/employees-list.component';
import { EmployeeComponent } from './page/additional-modules/work-schedule/employees-list/employee/employee.component';
import { SpecialitiesComponent } from './page/additional-modules/work-schedule/specialities/specialities.component';
import { SpecialityComponent } from './page/additional-modules/work-schedule/specialities/speciality/speciality.component';
import { ServedSpecialitiesComponent } from './page/additional-modules/work-schedule/utils/served-specialities/served-specialities.component';
import { OfficesComponent } from './page/additional-modules/work-schedule/offices/offices.component';
import { NewsComponent } from './page/additional-modules/work-schedule/news/news.component';
import { OfficeComponent } from './page/additional-modules/work-schedule/offices/office/office.component';
import { SingleNewsComponent } from './page/additional-modules/work-schedule/news/single-news/single-news.component';
import { LogoUploaderComponent } from './page/additional-modules/work-schedule/utils/logo-uploader/logo-uploader.component';
import { CustomNgxDateTimeModule } from './page/additional-modules/work-schedule/utils/CustomNgxDateTimeModule ';
import { UserComponent } from './page/users/user/user.component';
import { ServedDesksComponent } from './shared/served-desks/served-desks.component';
import { ServedUsersComponent } from './shared/served-users/served-users.component';
import { SubmitButtonComponent } from './shared/buttons/submit-button/submit-button.component';
import { HolidayTimeComponent } from './page/additional-modules/work-schedule/utils/holiday-time/holiday-time.component';
import { ServedOfficesComponent } from './page/additional-modules/work-schedule/utils/served-offices/served-offices.component';
import { AmmsIntegrationPageComponent } from './page/Integrations/amms-integration-page';
import { SinglePickerComponent } from './shared/single-picker/single-picker.component';
import { ReportTemplatesComponent } from './page/statistics/report-statistics/report-templates/report-templates.component';
import { ReportTemplateParameterComponent } from './page/statistics/report-statistics/report-template-parameter/report-template-parameter.component';
import { ReportTemplateComponent } from './page/statistics/report-statistics/report-template/report-template.component';
import { DeviceComponent } from './page/devices/device/device.component';
import { CategoryButtonComponent } from './shared/buttons/category-button/category-button.component';
import { DeskComponent } from './page/desks/desk/desk.component';
import { CallingDisplayComponent } from './page/devices/deviceTypes/calling-display/calling-display.component';
import { SendTestSmsComponent } from './page/additional-modules/sms-notifications/send-test-sms/send-test-sms.component';
import { ConfigureSmsSenderComponent } from './page/additional-modules/sms-notifications/configure-sms-sender/configure-sms-sender.component';
import { ReservationScheduleComponent } from './page/reservation/reservation-configuration-page/reservation-schedule/reservation-schedule.component';
import { SmsProviderComponent } from './page/system-settings/sms-provider/sms-provider.component';
import { ReservationQuestionaireTranslationComponent } from './page/location-configuration/languages-and-translations/reservation-translation/reservation-questionaire-translation/reservation-questionaire-translation.component';
import { DeviceLayoutsComponent } from './page/device-layouts/device-layouts.component';
import { OfficeDeskComponent } from './page/additional-modules/work-schedule/office-desk/office-desk.component';
import { OfficeDeskEditComponent } from './page/additional-modules/work-schedule/office-desk/office-desk-edit/office-desk-edit.component';
import { ReservationLimitsComponent } from './page/reservation/reservation-configuration-page/reservation-limits/reservation-limits.component';
import { StompQueueStateService } from 'projects/qcloud-stomp-client/src/lib/clients/stomp-queue-state.service';
import { QcloudStompClientModule } from 'projects/qcloud-stomp-client/src/public-api';
import { ReportGenerationTaskComponent } from './page/statistics/report-statistics/report-generation-task/report-generation-task.component';
import { ReservationTranslationComponent } from './page/location-configuration/languages-and-translations/reservation-translation/reservation-translation.component';
import { ReservationButtonTextTranslationComponent } from './page/location-configuration/languages-and-translations/reservation-translation/reservation-button-text-translation/reservation-button-text-translation.component';
import { QrCodeComponent } from './page/additional-modules/qr-code/qr-code.component';
import { WorkScheduleLogoComponent } from './page/additional-modules/work-schedule/work-schedule-logo/work-schedule-logo.component';
import { ReservationGroupByCategoriesComponent } from './page/reservation/reservation-configuration-page/reservation-group-by-categories/reservation-group-by-categories.component';
import { ReservationVacationComponent } from './page/reservation/reservation-configuration-page/reservation-vacation/reservation-vacation.component';
import { ReservationVacationListComponent } from './page/reservation/reservation-configuration-page/reservation-vacation/reservation-vacation-list/reservation-vacation-list.component';
import { ReservationVacationButtonComponent } from './page/reservation/reservation-configuration-page/reservation-vacation/reservation-vacation-button/reservation-vacation-button.component';
import { ReservationVacationMainComponent } from './page/reservation/reservation-configuration-page/reservation-vacation/reservation-vacation-main/reservation-vacation-main.component';
import { VacationActivityBlocksComponent } from './page/reservation/reservation-configuration-page/reservation-vacation/vacation-activity-blocks/vacation-activity-blocks.component';
import { ConfigurationScheduleComponent } from './page/additional-modules/work-schedule/configuration-schedule/configuration-schedule.component';
import { OnHoursComponent } from './page/on-hours/on-hours.component';
import { OnHoursScheduleComponent } from './page/on-hours/on-hours-schedule/on-hours-schedule.component';
import { TicketForHoursDispenserComponent } from './page/devices/deviceTypes/ticket-for-hours-dispenser/ticket-for-hours-dispenser.component';
import { DragAndDropComponent } from './shared/dnd-categories/drag-and-drop-component/app-drag-and-drop.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ReservationExportCSVComponent } from './page/reservation/reservation-export-csv/reservation-export-csv.component';
import { MatButtonModule } from '@angular/material/button';
import { DeviceLayoutComponent } from './page/device-layouts/device-layout/device-layout.component';
import { AppDeviceModule } from './app-device.module';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { LDAPIntegrationComponent } from './page/additional-modules/ldap-integration/ldap-intergation.component';
import { QcloudRolesAssignComponent } from './shared/qcloud-roles-assign/qcloud-roles-assign.component';
import { QcloudRoleComponent } from './page/qcloud-roles/qcloud-role/qcloud-role.component';
import { QcloudRolesComponent } from './page/qcloud-roles/qcloud-roles.component';
import { NoteTemplateConfigurationComponent } from './page/note-template-configurations/note-template-configuration/note-template-configuration.component';
import { NoteTemplateConfigurationsComponent } from './page/note-template-configurations/note-template-configurations.component';
import { LdapRolesComponent } from './page/ldap-roles/ldap-roles.component';
import { LdapRoleComponent } from './page/ldap-roles/ldap-role/ldap-role.component';
import { SelectUserLocationsComponent } from './shared/select-user-locations/select-user-locations.component';
import { NoteHistoryStatisticsComponent } from './page/statistics/note-history-statistics/note-history-statistics.component';
import { NgxColorsModule } from 'ngx-colors';
import { SystemCategoriesComponent } from './page/system-categories/system-categories.component';
import { SystemCategoryComponent } from './page/system-categories/system-category/system-category.component';
import { SystemQcloudRoleComponent } from './page/system-qcloud-roles/system-qcloud-role/system-qcloud-role.component';
import { SystemQcloudRolesComponent } from './page/system-qcloud-roles/system-qcloud-roles.component';
import { ConfirmModalComponent } from './shared/confirm-modal/confirm-modal.component';
import { WarningLabelComponent } from './shared/warning-label/warning-label.component';
import { LogsComponent } from './page/logs/logs/logs.component';
import { SystemDesksComponent } from './page/system-desks/system-desks.component';
import { SystemDeskComponent } from './page/system-desks/system-desk/system-desk.component';
import { SystemUserComponent } from './page/system-users/system-user/system-user.component';
import { SystemUsersComponent } from './page/system-users/system-users.component';
import { DetailsModalComponent } from './shared/details-modal/details-modal.component';
import { SelectLocationPopupComponent } from './page/select-location-popup/select-location-popup.component';
import { SelectLocationStepComponent } from './page/select-location-step/select-location-step.component';
import { CategoriesDisplayComponent } from './page/categories-display/categories-display.component';
import { AddEditCategoriesDisplayComponent } from './page/categories-display/add-edit-categories-display/add-edit-categories-display.component';
import { AddEditCategoryGroupsComponent } from './page/categories-display/add-edit-category-groups/add-edit-category-groups.component';
import { SystemTicketQuestionsComponent } from './page/system-ticket-questions/system-ticket-questions.component';
import { SystemTicketQuestionComponent } from './page/system-ticket-questions/system-ticket-question/system-ticket-question.component';
import { ClipboardModule } from '@angular/cdk/clipboard';

const initializerConfigFn = (appConfig: ConfigurationService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    DesksComponent,
    SystemDesksComponent,
    SystemDeskComponent,
    ConsoleComponent,
    CategoriesComponent,
    SystemCategoriesComponent,
    LoginComponent,
    MainComponent,
    UsersComponent,
    SystemUserComponent,
    SystemUsersComponent,
    TimeZoneComponent,
    DevicesComponent,
    ServedCategoriesComponent,
    TicketDispenserComponent,
    MainDisplayComponent,
    DeskDisplayComponent,
    OfficeConsoleComponent,
    OfficeDisplayComponent,
    CustomerSurveyDisplayComponent,
    DeviceTypePipe,
    FilePopupComponent,
    CategoriesColumnComponent,
    DeskCategoriesColumnComponent,
    DesksColumnComponent,
    DeviceStateColumnComponent,
    DeviceActivationColumnComponent,
    DragAndDropComponent,
    DeviceCustomizationComponent,
    FileManagerComponent,
    FileUploaderComponent,
    FileExtentionPipe,
    RemoveExtentionPipe,
    FilePathComponent,
    LinkUploaderComponent,
    TicketLayoutComponent,
    PersonalisationComponent,
    MaintenanceComponent,
    ResetCategoriesComponent,
    GroupCategoriesComponent,
    TicektsResetUnblockCategoriesComponent,
    DndGroupCategoriesComponent,
    FilePathComponent,
    PlaylistsComponent,
    AddPlaylistComponent,
    EditPlaylistComponent,
    PlaylistLayoutComponent,
    LanguagesAndTranslationsComponent,
    LanguagesSelectionComponent,
    MultimediaDevicesComponent,
    DevicesTableComponent,
    TagsTableComponent,
    AssignTagsComponent,
    AssignDevicesComponent,
    PersonalisationTranslationComponent,
    CategoryTranslationComponent,
    TicketTranslationComponent,
    DeskTranslationComponent,
    DeviceGroupTranslationComponent,
    ScheduleComponent,
    AddScheduleComponent,
    EditScheduleComponent,
    ScheduleLayoutComponent,
    CalendarComponent,
    DateRangePickerComponent,
    TimeRangePickerComponent,
    AssignSystemTagDeviceTypeComponent,
    AssignSystemTagLocationComponent,
    PaginationComponent,
    LocationsComponent,
    LocationComponent,
    SelectLocationComponent,
    SelectLocationPopupComponent,
    SelectLocationStepComponent,
    DefaultSettingsComponent,
    ProfileComponent,
    ChangePasswordComponent,
    LicenseComponent,
    ConfirmRejectButtonComponent,
    EmailConfigComponent,
    DeleteModalComponent,
    ConfirmModalComponent,
    DetailsModalComponent,
    WelcomeComponent,
    CategoryBlockingScheduleComponent,
    FooterComponent,
    NavbarFooterComponent,
    NavbarComponent,
    TicketStatisticsComponent,
    ReservationStatisticsComponent,
    CustomerSurveyStatisticsComponent,
    ReportStatisticsComponent,
    TicketHistoryStatisticsComponent,
    NoteHistoryStatisticsComponent,
    QueueStateStatisticsComponent,
    AverageWaitingServiceTimeStatisticsComponent,
    TicketResultStatisticsComponent,
    WaitingTimeCategoryStatisticsComponent,
    ServiceTimeCategoryStatisticsComponent,
    TicketInCategoryStatisticsComponent,
    DailyQueueingStatisticsComponent,
    WaitingTimeDeskStatisticsComponent,
    ServiceTimeDeskStatisticsComponent,
    StatisticsAverageBarComponent,
    DeskCustomerSurveyStatisticsComponent,
    DailyCustomerSurveyStatisticsComponent,
    TopbarLogoComponent,
    ReservationComponent,
    ReservationCalendarComponent,
    ReservationConfigurationPageComponent,
    ReservationImportCSVComponent,
    ReservationExportCSVComponent,
    AxxonIntelectIntegrationPageComponent,
    TicketInDeskStatisticsComponent,
    TicketInCategoryAndDeskStatisticsComponent,
    LicenceInfoComponent,
    CustomerSurveyComponent,
    WorkScheduleComponent,
    SmsNotificationsComponent,
    ReservationQuestionaireComponent,
    ReservationModalComponent,
    SystemLicenseComponent,
    LocationsLicenseComponent,
    ReservationModalQuestionsComponent,
    CategoryComponent,
    SystemCategoryComponent,
    WeeklyActivityComponent,
    CategoryActivityBlocksComponent,
    EmployeesListComponent,
    EmployeeComponent,
    SpecialitiesComponent,
    SpecialityComponent,
    ServedSpecialitiesComponent,
    OfficesComponent,
    NewsComponent,
    OfficeComponent,
    SingleNewsComponent,
    LogoUploaderComponent,
    UserComponent,
    ServedDesksComponent,
    ServedUsersComponent,
    SubmitButtonComponent,
    SubmitButtonComponent,
    HolidayTimeComponent,
    ServedOfficesComponent,
    AmmsIntegrationPageComponent,
    SinglePickerComponent,
    ReportTemplatesComponent,
    ReportTemplateParameterComponent,
    ReportTemplateComponent,
    DeviceComponent,
    CallingDisplayComponent,
    CategoryButtonComponent,
    DeskComponent,
    SendTestSmsComponent,
    ConfigureSmsSenderComponent,
    ReservationScheduleComponent,
    ReservationGroupByCategoriesComponent,
    SmsProviderComponent,
    ReservationQuestionaireTranslationComponent,
    DeviceLayoutsComponent,
    OfficeDeskComponent,
    OfficeDeskEditComponent,
    ReservationLimitsComponent,
    ReportGenerationTaskComponent,
    ReservationTranslationComponent,
    ReservationButtonTextTranslationComponent,
    QrCodeComponent,
    WorkScheduleLogoComponent,
    ReservationVacationComponent,
    ReservationVacationListComponent,
    ReservationVacationButtonComponent,
    ReservationVacationMainComponent,
    VacationActivityBlocksComponent,
    ConfigurationScheduleComponent,
    OnHoursComponent,
    OnHoursScheduleComponent,
    TicketForHoursDispenserComponent,
    DeviceLayoutComponent,
    LDAPIntegrationComponent,
    QcloudRolesComponent,
    QcloudRoleComponent,
    QcloudRolesAssignComponent,
    SystemQcloudRolesComponent,
    SystemQcloudRoleComponent,
    NoteTemplateConfigurationsComponent,
    NoteTemplateConfigurationComponent,
    LdapRolesComponent,
    LdapRoleComponent,
    SelectUserLocationsComponent,
    WarningLabelComponent,
    LogsComponent,
    CategoriesDisplayComponent,
    AddEditCategoriesDisplayComponent,
    AddEditCategoryGroupsComponent,
    SystemTicketQuestionComponent,
    SystemTicketQuestionsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    QcloudRestClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right'
    }),
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatCheckboxModule,
    MatSelectModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    MatRadioModule,
    MatInputModule,
    NgxSliderModule,
    DragDropModule,
    MatMenuModule,
    FileUploadModule,
    MatButtonModule,
    MatDialogModule,
    MatExpansionModule,
    AppDeviceModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgxEditorModule,
    QcloudConsoleModule,
    MatTooltipModule,
    QRCodeModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMatNativeDateModule,
    CustomNgxDateTimeModule,
    QcloudStompClientModule,
    NgxColorsModule,
    ClipboardModule
  ],
  providers: [
    QCloudRestClientService,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    {
      provide: APP_INITIALIZER,
      useFactory: initializerConfigFn,
      multi: true,
      deps: [ConfigurationService],
    },
    { provide: MAT_DATE_LOCALE, useValue: 'pl-PL' },
    StompQueueStateService
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }