<div class="rows-container" *ngIf="rows">
    <div class="row-container" *ngFor="let row of rows" [ngStyle]="getRowHeightStyle()">
        <app-start-piece-bar-compatible class="text-wrapper-service" [active]="row.active" [content]="row.texts[0]"
            [rowHeight]="rowHeight" [rowStyle]="rowStyle">
        </app-start-piece-bar-compatible>
        <app-middle-piece-bar-compatible class="text-wrapper-queue-state" [active]="row.active" [content]="row.texts[1]"
            [rowStyle]="rowStyle">
        </app-middle-piece-bar-compatible>
        <app-end-piece-bar-compatible class="text-wrapper-avg-time" [active]="row.active"
            [content]="row.texts[2] | date:'mm:ss'" [rowHeight]="rowHeight" [rowStyle]="rowStyle">
        </app-end-piece-bar-compatible>
    </div>
</div>