import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PlaylistItem } from 'projects/qcloud-models/multimedia/playlistItem.model';
import { BaseComponent } from '../../../../shared/base-component/base.component';


@Component({
  selector: 'app-multimedia',
  templateUrl: './multimedia.component.html',
  styleUrls: ['./multimedia.component.css']
})
export class MultimediaComponent extends BaseComponent implements OnInit {
  url: SafeResourceUrl;
  showImage: boolean = false;
  showMp4: boolean = false;
  showHTML: boolean = false;
  quantity: number = 0;
  locationId: number;
  systemId: number;
  constructor(protected elementRef: ElementRef, protected renderer: Renderer2,
    private sanitizer: DomSanitizer) {
    super(elementRef, renderer);
  }

  startComponent(): void {
    this.scheduleService.toDisplay.subscribe((val) => {
      this.displayFile(val);
    });
    let config = this.configurationService.getConfiguration();
    this.locationId = config.locationId;
    this.systemId = config.systemId;
  }

  ngOnInit(): void {

  }

  private displayFile(playlistItem: PlaylistItem) {

    if (this.fileService.isImage(playlistItem.filePath)) {
      this.showImage = true;
      this.showMp4 = false;
      this.showHTML = false;
      this.url = this.getUrlForServerForPlaylist(playlistItem);
      return;
    }
    if (this.fileService.isVideo(playlistItem.filePath)) {
      this.showImage = false;
      this.showMp4 = true;
      this.showHTML = false;
      this.url = this.getUrlForServerForPlaylist(playlistItem);
      return;
    }
    if (this.fileService.isUrl(playlistItem.filePath)) {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.fileNameToUrlForServer(playlistItem.filePath, "http"));
      return;
    }
    //TODO: replace it with getFromLocalFiles
    // this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.getFromLocalFiles(playlistItem));
  }

  private getUrlForServerForPlaylist(playlistItem: PlaylistItem) {
    return this.fileService.appBase + `cdn/${this.systemId}/${this.locationId}/` + this.removeDoubleSlashInFront(playlistItem.filePath);
  }

  // private getFromLocalFiles(playlistItem: PlaylistItem) :string{
  //   return this.cordovaFileService.getLocalPathToFile(playlistItem.filePath);
  // }

  private fileNameToUrlForServer(text: string, keepRight: string): string {
    let ext = this.fileService.getExtension(text);
    text = text.substring(0, text.length - ext.length);
    if (this.fileService.isVideo(text)) {
      this.showImage = false;
      this.showMp4 = true;
      this.showHTML = false;
    } else if (this.fileService.isImage(text)) {
      this.showImage = true;
      this.showMp4 = false;
      this.showHTML = false;
    } else {
      this.showImage = false;
      this.showMp4 = false;
      this.showHTML = true;
    }
    let start = text.indexOf(keepRight);
    return decodeURIComponent(text.substring(start));
  }

  initMock(): void {
    this.showImage = true;
    this.url = "/assets/icon.png"
  }
}
