<div class="card shadow mb-4">
    <div class="card-header py-3">
        <div class="row">
            <h5 class="col-8 m-0 font-weight-bold text-primary">{{ 'layouts' | translate }}</h5>
        </div>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <td>#</td>
                        <td>{{ 'layoutName' | translate }}</td>
                        <td>{{ 'layoutContent' | translate }}</td>
                        <td>

                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let layout of deviceLayouts; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>{{ getLayoutName(layout.layoutNumber) }}</td>
                        <td>
                            <span *ngFor="let component of layout.components; let i = index">{{component.name}} </span>
                        </td>
                        <td>
                            <button class="btn btn-info btn-circle btn-sm mx-1 mb-1"
                                [routerLink]="['/layout/', layout.id]" matTooltip="{{ 'edit' | translate }}"
                                tooltipClass="tooltip-info">
                                <i class="fas fa-fw fa-edit"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>