import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { QcloudConsoleService } from '../../qcloud-console.service';
import { Ticket } from 'projects/qcloud-models/console/ticket';
import { validateHorizontalPosition } from '@angular/cdk/overlay';
import { Category } from 'projects/qcloud-models/category/category.model';

@Component({
  selector: 'lib-display-deadline',
  templateUrl: './display-deadline.component.html',
  styleUrls: ['./display-deadline.component.css']
})
export class DisplayDeadlineComponent implements OnInit {
  categories: Category[];
  category: Category;
  deadline: string;
  time: number;

  @ViewChild('deadline') public deadlineSpan: ElementRef;

  constructor(consoleService: QcloudConsoleService) {
    this.categories = consoleService.getAllConsoleCategories();
    consoleService.ticket.subscribe((val: Ticket) => {
      try{
      this.category=this.categories.find(x => x.id == val.effectiveCategoryId)
      if(this.category.timePerTicket!=0){
        this.setTimer(this.category.timePerTicket);
     }
    }catch{}
    if(val.id == -1 || val.id==0){
      this.deadline=null;
      this.time=0;
    }
     });
  }

  ngOnInit(): void {
  }

  setTimer(minutes){
    this.time=minutes*60;
    const int =setInterval((): void=>{
      if ( this.time <= 0 ){
          clearInterval( int );
          this.deadline = null;
          this.time = 0;
      } else {
        this.formatTime();
        this.time -= 1;
      }
    },1000);

  }

  formatTime() {
    var date = new Date(1970,0,1);
    date.setSeconds(this.time);
    this.deadline = date.toTimeString().replace(/.*(\d{2}:\d{2}).*/, "$1");
}

}

