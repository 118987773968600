import { Component, OnInit } from '@angular/core';
import { Category } from 'projects/qcloud-models/category/category.model';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { StatisticsService } from 'projects/qcloud-rest-client/src/lib/statistics.service';

@Component({
  selector: 'app-reservation-statistics',
  templateUrl: './reservation-statistics.component.html',
  styleUrls: ['./reservation-statistics.component.css']
})
export class ReservationStatisticsComponent implements OnInit {

  categories: Category[];
  allReserved: number[];
  used: number[];

  constructor(private categoryService: CategoryService, public locationService: LocationService,
    private statisticsService: StatisticsService) { }

  ngOnInit(): void {
    this.categoryService.getCategories(
      (data)=>{
        this.categories = data;
        this.allReserved = Array.from({length: this.categories.length},()=> 0);
        this.used = Array.from({length: this.categories.length},()=> 0);
        this.calculateTodayStatistics(this.categories);
      },
      ()=>{ }
    );
  }

  calculateTodayStatistics(categories: Category[]){
    this.statisticsService.getTodayReservationStats().subscribe(todayReservations=>{
      let index = 0;
      categories.forEach(cat=>{
        let reservationsForCategory = todayReservations[cat.id];
        for (let i = 0; i < reservationsForCategory.length; i++) {
          const element = reservationsForCategory[i];
          this.allReserved[index]+=1;
          if(element.used){
            this.used[index]+=1;
          }
        }
        index+=1;
      })
    })
  }

}
