<div *ngIf="queueState" class="mr-2" #divQueueState>
    <span *ngIf="!serveETickets" #spanFaReply>
        <button class="btn btn-info btn-icon-split mb-1" (click)="manualCallTicket(-1)"
            [disabled]="!isEnabled || isBlocked">
            <span class="icon" #spanFaReplyIcon><i class="fas fa-reply"></i></span>
            <span class="text" #spanFaReplyText>{{ queueState.queueStateByCategory.get(-1) }}</span>
        </button>
    </span>
    <span *ngFor="let category of getCategories(); let i = index" [attr.data-index]="i"
        [hidden]="i >= categorieShowLimits" #spanCategories>
        <button class="btn btn-primary btn-icon-split mb-1" (click)="manualCallTicket(category.id)"
            [disabled]="!isEnabled || isBlocked">
            <span class="icon" #spanCategoryTags>{{ category.categoryTag }}</span>
            <span class="text" #spanCategoryStates>{{ queueState.queueStateByCategory.get(category.id) }}</span>
        </button>
    </span>
</div>