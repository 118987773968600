<div class="form-group">
    <div class="input-group">
        <mat-form-field color="primary">
            <mat-label>{{ title | translate }}</mat-label>
            <mat-select [formControl]="tagMultiCtrl" [compareWith]="compareTags" [multiple]="true" #multiSelect>
                <mat-option>
                    <ngx-mat-select-search [showToggleAllCheckbox]="true" (toggleAll)="toggleSelectAll($event)"
                        [formControl]="tagMultiFilterCtrl"
                        toggleAllCheckboxTooltipMessage="{{ 'select-unselect-all' | translate }}"
                        [toggleAllCheckboxTooltipPosition]="'above'" placeholderLabel="{{ 'search-tag' | translate }}"
                        noEntriesFoundLabel="{{ 'no-matching-tag' | translate }}">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let tag of filteredTagsMulti | async" [value]="tag">
                    {{ tag.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <p *ngIf="tagMultiCtrl.value == null || tagMultiCtrl?.value.length == 0">
        {{ 'no-selected-tags' | translate }}</p>
    <div class="input-group">
        <span *ngFor="let tag of tagMultiCtrl?.value" class="badge badge-primary mr-1">{{ tag.name }}</span>
    </div>
</div>