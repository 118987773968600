import { Component, OnInit } from '@angular/core';
import { Personalisation } from 'projects/qcloud-models/location/personalisation';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-personalisation',
  templateUrl: './personalisation.component.html',
  styleUrls: ['./personalisation.component.css']
})
export class PersonalisationComponent implements OnInit {

  personalisation: Personalisation;
  formSubmitted: boolean;

  constructor(private locationService: LocationService, private toastr: ToastrService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.getPersonalisation();
  }

  getPersonalisation() {
    this.locationService.getPersonalisation().subscribe(
      personalisation => {
        this.personalisation = personalisation;
      }
    );
  }

  onSubmit() {
    this.formSubmitted = true;
    this.locationService.savePersonalisation(this.personalisation).subscribe(
      res => {
        this.formSubmitted = false;
        this.translate.get('personalisation-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('personalisation-error').subscribe((res: string) => {
          let error = res;
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  deletePersonalisation() {
    this.locationService.deletePersonalisation().subscribe(
      res => {
        this.getPersonalisation();
        this.translate.get('personalisation-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.translate.get('personalisation-error').subscribe((res: string) => {
          let error = res;
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  setDefaultDeviceBackgroundPath(path: string) {
    this.personalisation.defaultBackgroundUri = path;
  }
}
