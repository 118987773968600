<div class="card shadow mb-4">
    <div class="card-header py-3">
        <div class="row">
            <h5 class="col-8 m-0 font-weight-bold text-primary">{{ 'devices' | translate }}</h5>
            <div class="text-right col-4">
                <button class="btn btn-warning btn-circle btn-sm mr-2" (click)="restartAll()"
                    matTooltip="{{ 'restart-all' | translate }}" tooltipClass="tooltip-warning">
                    <i class="fas fa-fw fa-sync-alt"></i>
                </button>
                <button type="button" class="btn btn-primary btn-circle btn-sm" (click)="goToAddDevice()"
                    matTooltip="{{ 'add' | translate }}" tooltipClass="tooltip-primary">
                    <i class="fas fa-fw fa-plus"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{{ 'name' | translate }}</th>
                        <th>{{ 'type' | translate }}</th>
                        <th>{{ 'state' | translate }}</th>
                        <th>{{ 'categories-desks' | translate }}</th>
                        <th>{{ 'activation' | translate }}</th>
                        <th>{{ 'options' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let device of deviceTable; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>{{ device.deviceName }}</td>
                        <td>{{ device.deviceType | deviceType | translate }}</td>
                        <td>
                            <app-device-state-column [deviceState]="device.isAlive"></app-device-state-column>
                        </td>
                        <td>
                            <app-categories-column *ngIf="device.deviceType == DeviceType.TicketDispenser ||
                                (device.deviceType == DeviceType.MainDisplay && device.layoutType != LayoutType.MultideskDesk && device.layoutType != LayoutType.DesksBasic)"
                                [categories]="device.categoriesOrDesks">
                            </app-categories-column>
                            <app-desks-column *ngIf="device.deviceType == DeviceType.DeskDisplay ||
                                device.deviceType == DeviceType.CustomerSurveyDisplay ||
                                device.deviceType == DeviceType.CallingDisplay ||
                                (device.deviceType == DeviceType.MainDisplay &&
                                device.layoutType == LayoutType.MultideskDesk || device.layoutType == LayoutType.DesksBasic)"
                                [desks]="device.categoriesOrDesks">
                            </app-desks-column>
                            <div *ngIf="device.deviceType == DeviceType.CallConsole">{{ 'none' | translate }}</div>
                        </td>
                        <td>
                            <app-device-activation-column [activationCode]="device.activationCode"
                                [activated]="device.activated">
                            </app-device-activation-column>
                        </td>
                        <td>
                            <div class="row">
                                <button class="btn btn-info btn-circle btn-sm mx-1 mb-1" (click)="copyDeviceUrl(device.guid)"
                                matTooltip="{{ 'copy' | translate }}" tooltipClass="tooltip-info">
                                <i class="fas fa-regular fa-copy"></i>
                            </button>
                                <button class="btn btn-info btn-circle btn-sm mx-1 mb-1"
                                    [routerLink]="['/device/', device.guid]"
                                    matTooltip="{{ 'edit' | translate }}" tooltipClass="tooltip-info">
                                    <i class="fas fa-fw fa-edit"></i>
                                </button>
                                <button (click)="selectedDeviceGuid = device.guid" class="btn btn-danger btn-circle btn-sm mx-1 mb-1"
                                    matTooltip="{{ 'delete' | translate }}" tooltipClass="tooltip-danger"
                                    data-toggle="modal" data-target="#deleteModal">
                                    <i class="fas fa-fw fa-trash"></i>
                                </button>
                                <button class="btn btn-warning btn-circle btn-sm mx-1 mb-1" (click)="restartItem(device.guid)"
                                    matTooltip="{{ 'restart-device' | translate }}" tooltipClass="tooltip-warning">
                                    <i class="fas fa-fw fa-sync-alt"></i>
                                </button>
                                <button *ngIf="device.deviceType != DeviceType.CallConsole &&
                                    device.deviceType != DeviceType.CustomerSurveyDisplay"
                                    class="btn btn-success btn-circle btn-sm mx-1 mb-1"
                                    [routerLink]="['/device/customize/', device.guid]"
                                    matTooltip="{{ 'device-customization' | translate }}" tooltipClass="tooltip-success">
                                    <i class="fa fa-paint-brush"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-hidden="true">
    <app-delete-modal [info]="'delete-confirmation'" [confirm]="'delete'" (confirmed)="onDeleteConfirm($event)"></app-delete-modal>
</div>
