import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Category } from 'projects/qcloud-models/category/category.model';
import { DisplayCategoryGroup } from 'projects/qcloud-models/category/display-category-group';
import { DisplaySystemCategory } from 'projects/qcloud-models/category/display-system-category';
import { SystemCategory } from 'projects/qcloud-models/category/system-category.model';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { SystemCategoryService } from 'projects/qcloud-rest-client/src/lib/category/system-category.service';

@Component({
  selector: 'app-categories-display',
  templateUrl: './categories-display.component.html',
  styleUrls: ['./categories-display.component.css']
})
export class CategoriesDisplayComponent implements OnInit {
  displayCategories: DisplaySystemCategory[];
  displayCategoryGroups: DisplayCategoryGroup[];
  selectedCategoryId: number;
  categories: SystemCategory[];
  constructor(private categoryService: CategoryService,
    private systemCategoryService: SystemCategoryService,
    private translate: TranslateService, private toastr: ToastrService
  ) {
    this.getData();
  }

  getData(){
    this.systemCategoryService.getCategories((data) => {
      this.categories = data;
    }, () => {
      this.displayCategories = [];
    });
    this.categoryService.getSystemCategoryDisplays().subscribe((data) => {
      this.displayCategories = data;
    });
    this.categoryService.getGroupDisplays().subscribe((data) => {
      this.displayCategoryGroups = data;
    });
  }

  getCategoryName(id: number): string {
    return this.categories.find(c => c.id === id)?.fullName || '';
  }

  ngOnInit(): void {
  }

  onDeleteConfirm(confirmed: boolean) {
    if (confirmed) {
      this.deleteCategory(this.selectedCategoryId);
    }
  }

  deleteCategory(categoryId: number) {
    this.categoryService.deleteSystemCategoryDisplay(categoryId).subscribe(
      res => {
        this.getData();
      },
      err => {
        this.translate.get('delete-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }
}
