import { Component, OnInit, Input } from '@angular/core';
import { DeviceConfiguration } from 'projects/qcloud-models/device/device-configuration';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { ConsoleLogin } from 'projects/qcloud-models/user/console-login.model';
import { QcloudConsoleService } from '../qcloud-console.service';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { UserService } from 'projects/qcloud-rest-client/src/lib/user.service';
import { User } from 'projects/qcloud-models/user/user.model';
import { ProjectType } from '../qcloud-console.component';
import { LocationService } from '../../../../qcloud-rest-client/src/lib/location.service';
import { LocationData } from 'projects/qcloud-models/location/location.model';
import { DeskService } from '../../../../qcloud-rest-client/src/lib/desk.service';

@Component({
  selector: 'lib-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @Input() public deviceConfiguration: DeviceConfiguration;

  public choosenDesk: Desk;
  public deskList: Desk[];
  public userList: User[];
  public allUsers: User[];
  public choosenUser: User;
  public locationList: LocationData[] = [];
  public choosenLocation: LocationData;
  public isDisableChooseLocation: boolean = false;
  public isDisableChooseDesk: boolean = false;
  public isLoggedIn = false;
  public username = "";
  public password = "";
  isLoginByWEB = false;
  private LAST_LOGGED_USERID_KEY = "LAST_LOGGED_USERID_KEY";
  projectTypeEnum = ProjectType;

  constructor(private consoleService: QcloudConsoleService, public authService: AuthService,
    private userService: UserService, public locationService: LocationService, private deskService: DeskService) { }

  ngOnInit(): void {
    this.deskList = this.deviceConfiguration.desks;
    let deskId = this.authService.getDeskId();
    let locationId = this.deviceConfiguration.locationId;
    this.userService.getUsers(users => {
      this.allUsers = users;
      this.locationService.getAll().subscribe(locations => {
        this.locationList = locations;
        if (locationId) {
          this.choosenLocation = this.locationList.find(x => x.id == locationId);
          this.isDisableChooseLocation = true;
        }
        if (!locationId || this.consoleService.projectType == this.projectTypeEnum.WEB) {
          this.choosenLocation = this.locationList[0];
          this.isDisableChooseLocation = false;
        }
        var loggedUserName = this.authService.getTokenUsername();
        if (this.consoleService.projectType == this.projectTypeEnum.WEB && loggedUserName) {
          this.username = loggedUserName;
          var loggedUserId = this.authService.getTokenUserId();
          var loggedUser = this.allUsers.find(us => us.id == loggedUserId);
          this.locationList = this.locationList.filter(l => loggedUser.qcloudRoles.find(qcloudRole => qcloudRole.locationIds.find(loc => loc == l.id)) != null);
          this.isLoginByWEB = true;
          this.choosenLocation = this.locationList[0];
        }
        this.locationChooseChanged();
        if (deskId) {
          this.choosenDesk = this.deskList.find(x => x.id == deskId && x.locationId == this.choosenLocation.id);
        } else {
          this.choosenDesk = this.deskList[0];
        }
        if (!this.choosenDesk) {
          this.choosenDesk = this.deskList[0];
        }

      });
    }, () => { });
  }

  public getLastLoggedUserId(): string {
    let item = localStorage.getItem(this.LAST_LOGGED_USERID_KEY)
    return item;
  }

  public setLastLoggedUserId(value: string) {
    localStorage.setItem(this.LAST_LOGGED_USERID_KEY, value);
  }

  public loginToConsole() {
    let consoleLogin = new ConsoleLogin();
    consoleLogin.deskId = this.choosenDesk.id;
    consoleLogin.password = this.password;
    consoleLogin.userName = this.username;
    this.consoleService.loginToConsole(consoleLogin);
    this.password = "";
    this.userService.updateStateChanged(true);
  }
  locationChooseChanged() {
    if (this.choosenLocation != null) {
      this.deskService.getDesksByLocation(this.choosenLocation.id, (desks) => {
        this.deskList = desks;
      }, () => { });
      if (this.allUsers) {
        this.userList = this.allUsers.filter(u => u.qcloudRoles.find(qcloudRole => qcloudRole.locationIds.find(loc => loc == this.choosenLocation.id)) != null);
      } else {
        this.userList = [];
      }
    } else {
      this.deskList = this.deviceConfiguration.desks;
      this.userList = this.allUsers;
    }
  }

  formatUserName(user: User): string {
    if (user.isLdapUser) {
      return user.ldapDistinguishedName + "\\" + user.userName;
    } else {
      return user.userName;
    }
  }
}
