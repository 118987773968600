<div class="card shadow mb-4">
    <mat-accordion>
        <mat-expansion-panel (opened)="isCollapsed = false"
                             (closed)="isCollapsed = true"
                             hideToggle="true"
                             [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
                <h5 class="col-10 m-2 font-weight-bold text-primary">{{ 'personalisation-translation' | translate }}</h5>
            </mat-panel-title>
            <mat-panel-description>
                    <button type="button" class="btn btn-primary btn-circle btn-sm" (click)="isCollapsed = !isCollapsed"
                        [attr.aria-expanded]="!isCollapsed" aria-controls="collapseCategory"
                        [ngClass]="{'clicked': isCollapsed}">
                        <i class="fas fa-angle-down"></i>
                    </button>
            </mat-panel-description>
          </mat-expansion-panel-header>
    <div>
        <div *ngIf="personalisationTranslations && personalisation" class="card-body">
            <div class="text-right">
                <app-pagination [translations]="translations"
                    (filteredTranslationsEmitter)="getFilteredTranslations($event)">
                </app-pagination>
            </div>
            <div class="row">
                <div class="col-4">

                    <p class="font-weight-bold mt-1">{{ getLocaleName(locationData.locationLocaleMain) | translate }}</p>

                    <div class="form-group">
                        {{ 'location-name' | translate }}
                        <div class="input-group">
                            <input disabled [(ngModel)]="locationData.locationName" class="form-control">
                        </div>
                    </div>

                    <div class="form-group">
                        {{ 'ticket-pronunciation' | translate }}
                        <div class="input-group">
                            <input disabled [(ngModel)]="personalisation.locationWords.speakTicketText" class="form-control">
                        </div>
                    </div>

                    <div class="form-group">
                        {{ 'desk-pronunciation' | translate }}
                        <div class="input-group">
                            <input disabled [(ngModel)]="personalisation.locationWords.speakDeskText" class="form-control">
                        </div>
                    </div>

                    <div class="form-group">
                        {{ 'category-pronunciation' | translate }}
                        <div class="input-group">
                            <input disabled [(ngModel)]="personalisation.locationWords.speakCategoryText" class="form-control">
                        </div>
                    </div>

                    <div class="form-group">
                        {{ 'tag-text' | translate }}
                        <div class="input-group">
                            <input disabled [(ngModel)]="personalisation.locationWords.tagText" class="form-control">
                        </div>
                    </div>

                    <div class="form-group">
                        {{ 'ticket-dispenser-queue-state-text' | translate }}
                        <div class="input-group">
                            <input disabled [(ngModel)]="personalisation.locationWords.queueState" class="form-control">
                        </div>
                    </div>

                    <div class="form-group">
                        {{ 'ticket-dispenser-average-waiting-time-text' | translate }}
                        <div class="input-group">
                            <input disabled [(ngModel)]="personalisation.locationWords.aproxWaitTime" class="form-control">
                        </div>
                    </div>

                    <div class="form-group">
                        {{ 'ticket-dispenser-return-text' | translate }}
                        <div class="input-group">
                            <input disabled [(ngModel)]="personalisation.locationWords.returnText" class="form-control">
                        </div>
                    </div>
                </div>

                <div class="col-4" *ngFor="let translation of deleteLocaleMain(isOnPage()); let i = index">
                    <form *ngIf="personalisationTranslations[startIndex + i]"
                        #form="ngForm" autocomplete="off">

                        <div class="input-group">
                            <p class="font-weight-bold mt-1 mr-1">{{ getLocaleName(translation.locale) | translate }}</p>
                            <button class="btn btn-warning btn-circle btn-sm"
                                *ngIf="!personalisationTranslations[startIndex + i].isDefault"
                                (click)="backToDefaultTranslation(personalisationTranslations[startIndex + i])"
                                matTooltip="{{ 'back-to-default-translation' | translate }}" tooltipClass="tooltip-warning">
                                <i class="fas fa-redo-alt"></i>
                            </button>
                        </div>

                        <div class="form-group">
                            {{ 'location-name' | translate }}
                            <div class="input-group">
                                <input class="form-control" name="locationName" #locationName="ngModel"
                                    [(ngModel)]="personalisationTranslations[startIndex + i].translationWords.locationName">
                            </div>
                        </div>

                        <div class="form-group">
                            {{ 'ticket-pronunciation' | translate }}
                            <div class="input-group">
                                <input class="form-control" name="speakTicketText" #speakTicketText="ngModel"
                                    [(ngModel)]="personalisationTranslations[startIndex + i].translationWords.speakTicketText">
                            </div>
                        </div>

                        <div class="form-group">
                            {{ 'desk-pronunciation' | translate }}
                            <div class="input-group">
                                <input class="form-control" name="speakDeskText" #speakDeskText="ngModel"
                                    [(ngModel)]="personalisationTranslations[startIndex + i].translationWords.speakDeskText">
                            </div>
                        </div>

                        <div class="form-group">
                            {{ 'category-pronunciation' | translate }}
                            <div class="input-group">
                                <input class="form-control" name="speakCategoryText" #speakCategoryText="ngModel"
                                [(ngModel)]="personalisationTranslations[startIndex + i].translationWords.speakCategoryText">
                            </div>
                        </div>

                        <div class="form-group">
                            {{ 'tag-text' | translate }}
                            <div class="input-group">
                                <input class="form-control" name="tagText" #speakCategoryText="ngModel"
                                [(ngModel)]="personalisationTranslations[startIndex + i].translationWords.tagText">
                            </div>
                        </div>

                        <div class="form-group">
                            {{ 'ticket-dispenser-queue-state-text' | translate }}
                            <div class="input-group">
                                <input class="form-control" name="queueState" #queueState="ngModel"
                                    [(ngModel)]="personalisationTranslations[startIndex + i].translationWords.queueState">
                            </div>
                        </div>

                        <div class="form-group">
                            {{ 'ticket-dispenser-average-waiting-time-text' | translate }}
                            <div class="input-group">
                                <input class="form-control" name="aproxWaitTime" #aproxWaitTime="ngModel"
                                    [(ngModel)]="personalisationTranslations[startIndex + i].translationWords.aproxWaitTime">
                            </div>
                        </div>

                        <div class="form-group">
                            {{ 'ticket-dispenser-return-text' | translate }}
                            <div class="input-group">
                                <input class="form-control" name="returnText" #returnText="ngModel"
                                    [(ngModel)]="personalisationTranslations[startIndex + i].translationWords.returnText">
                            </div>
                        </div>

                    </form>
                </div>

            </div>
            <div class="form-group">
                <app-submit-button (click)="onSubmit()" type="submit" [loading]="formSubmitted"
                    [text]="'save-personalisation-translations' | translate">
                </app-submit-button>
            </div>
        </div>
    </div>
</mat-expansion-panel>
</mat-accordion>
</div>