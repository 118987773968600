<div>
    <div *ngIf="!showTicket">
        <span>{{ ticketAnswerCaption }}:{{ ticketAnswer }}</span>
        <a *ngIf="isTicketAnswer">
            <i class="fas fa-retweet change-button" (click)="toggle()" #icon></i>
        </a>
    </div>
    <div *ngIf="showTicket">
        <span class="ticket">
            <span *ngIf="ticketCaption">{{ ticket.caption }}</span>
            <span *ngIf="!ticketCaption">---</span>
        </span>
        <a *ngIf="isTicketAnswer">
            <i class="fas fa-retweet change-button" (click)="toggle()" #icon></i>
        </a>
    </div>
</div>