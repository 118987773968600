import { ElementRef, Injectable, QueryList } from "@angular/core";


enum CssCustomClass {
  HideElement = 'd-none',
  SlimButton = 'slim-button',
  DeviceSlimButton = 'device-slim-button',
  BtnBlockLevel = 'btn-block',
  ElementText ='element-text',
  SlimAdditionalButton = 'slim-additional-button',
  SlimAdditionalButtonSectoreTwo = 'slim-additional-button-sector-two',
  BigMarginBottomOne = 'mb-1',
  SlimMarginTopTwo = 'mt-2',
  SlimMarginRightTwo = 'mr-2',
  SlimDivQueueStatePosition = 'slim-div-display',
  SlimSpanQueueStateSize = 'slim-size-queue-state',
  DeviceSlimSpanQueueStateSize = 'device-slim-size-queue-state',
  InheritFontSize = 'inherit-font-size',
  Host = 'host'
}

export enum ButtonComponentName {
  CallTicketButtonComponent = 'CallTicketButtonComponent',
  CallAgainTicketButtonComponent = 'CallAgainTicketButtonComponent',
  AcceptTicketButtonComponent = 'AcceptTicketButtonComponent',
  PauseTicketButtonComponent = 'PauseTicketButtonComponent',
  RedirectTicketButtonComponent = 'RedirectTicketButtonComponent',
  CancelTicketButtonComponent = 'CancelTicketButtonComponent',
  ExpandMenuButtonComponent = 'ExpandMenuButtonComponent'
}
export enum AdditionalButtonComponentName {
  CancelledTicketsButton = 'CancelledTicketsButton',
  PausedTicketsButton = 'PausedTicketsButton',
  CallTicketManualButton = 'CallTicketManualButton',
  BlockedCategoriesButton = 'BlockedCategoriesButton',
  ServedCategoriesButton = 'ServedCategoriesButton',
  GenerateTicketButton = 'GenerateTicketButton',
  ShowTicketManualButton = 'ShowTicketManualButton'
}
export enum TicketInfoComponentName {

}

@Injectable()
export class SlimLayoutConfigurationService {

  ButtonComponentNameEnum = ButtonComponentName;
  AdditionalButtonComponentName = AdditionalButtonComponentName;
  constructor() { }
  adjustScreen(): void {}

  public adjustSmallScreen(): void {}

  minimalizeAdditionalButtonsMapSectorTwo<E>(buttons: Map<E, ElementRef>) {
    var addingClassList = [];
    var removingClassList = [];

    addingClassList.push(CssCustomClass.SlimAdditionalButtonSectoreTwo);

    removingClassList.push(CssCustomClass.SlimAdditionalButton);

    return this.manageClassListMap(buttons, addingClassList, removingClassList);
  }

  public minimalizeAdditionalButtonsMap<E>(buttons: Map<E, ElementRef>) {
    var addingClassList = [];
    var removingClassList = [];

    addingClassList.push(CssCustomClass.SlimAdditionalButton);
    addingClassList.push(CssCustomClass.SlimMarginTopTwo);
    addingClassList.push(CssCustomClass.InheritFontSize);
    removingClassList.push(CssCustomClass.BtnBlockLevel);
    removingClassList.push(CssCustomClass.BigMarginBottomOne);

    return this.manageClassListMap(buttons, addingClassList, removingClassList);
  }

  public minimalizeButtonsMap<E>(buttons: Map<E, ElementRef>) {
    this.hideTextInButton(buttons);

    var addingClassList = [];
    var removingClassList = [];

    addingClassList.push(CssCustomClass.SlimButton);

    removingClassList.push(CssCustomClass.BtnBlockLevel);

    return this.manageClassListMap(buttons, addingClassList, removingClassList);
  }


  public minimalizeDeviceButtonsMap<E>(buttons: Map<E, ElementRef>) {
    this.hideTextInButton(buttons);

    var addingClassList = [];
    var removingClassList = [];

    addingClassList.push(CssCustomClass.DeviceSlimButton);

    removingClassList.push(CssCustomClass.BtnBlockLevel);

    return this.manageClassListMap(buttons, addingClassList, removingClassList);
  }



  private hideTextInButton<E>(buttons: Map<E, ElementRef>): void {
    var addingClassList = [];
    var removingClassList = [];

    addingClassList.push(CssCustomClass.HideElement);
    buttons.forEach((element: ElementRef, key: E) => {
      this.manageClassList(element.nativeElement.childNodes, addingClassList, removingClassList, CssCustomClass.ElementText);
    });
  }

  private manageClassList(elemets: NodeListOf<Element>, addingClasses: string[], removingClasses: string[], filter: string = ''): void {
    if (removingClasses.length > 0) {
      this.removeClassList(elemets, removingClasses, filter);
    }

    if (addingClasses.length > 0) {
      this.appendClassList(elemets, addingClasses, filter);
    }
  }

  private manageClassListRef(elemets: ElementRef[], addingClasses: string[], removingClasses: string[]): ElementRef[] {
    if (removingClasses.length > 0) {
      elemets = this.removeClassListRef(elemets, removingClasses);
    }

    if (addingClasses.length > 0) {
      elemets = this.appendClassListRef(elemets, addingClasses);
    }
    return elemets;
  }

    private manageClassListMap<E>(elemets: Map<E, ElementRef>, addingClasses: string[], removingClasses: string[]){
    if (removingClasses.length > 0) {
      elemets = this.removeClassListMap(elemets, removingClasses);
    }

    if (addingClasses.length > 0) {
      elemets = this.appendClassListMap(elemets, addingClasses);
    }
    return elemets;
  }

  private appendClassList(elemets: NodeListOf<Element>, classes: string[], filter: string = '') {
    elemets.forEach(element => {
      if(typeof filter=='undefined' || !filter || element.classList.contains(filter)) {
         element.classList.add(...classes);
      }
    });
  }
  private appendClassListRef(elemets: ElementRef[], classes: string[]) : ElementRef[]{
    elemets.forEach(element => {
      element.nativeElement.classList.add(...classes);
    });
    return elemets;
  }

  private appendClassListMap<E>(elemets: Map<E, ElementRef>, classes: string[]) : Map<E, ElementRef>{
    elemets.forEach((element: ElementRef, key: E) => {
      element.nativeElement.classList.add(...classes);
    });
    return elemets;
  }

  private removeClassListMap<E>(elemets: Map<E, ElementRef>, classes: string[]) : Map<E, ElementRef>{
      elemets.forEach((element: ElementRef, key: E) => {
      element.nativeElement.classList.remove(...classes);
    });
    return elemets;
  }

  private removeClassList(elemets: NodeListOf<Element>, classes: string[], filter: string = '') {
    elemets.forEach(element => {
        if(typeof filter=='undefined' || !filter || element.classList.contains(filter)) {
          element.classList.remove(...classes);
        }
      });
  }

  private removeClassListRef(elemets: ElementRef[], classes: string[]) : ElementRef[]{
    elemets.forEach(element => {
      element.nativeElement.classList.remove(...classes);
    });
    return elemets;
  }

  public positionDivQueueState(divQueueState: ElementRef) : void {
    divQueueState.nativeElement.classList.add(CssCustomClass.SlimDivQueueStatePosition);
    divQueueState.nativeElement.classList.remove(CssCustomClass.SlimMarginRightTwo);
  }

  public removePositionDivQueueState(divQueueState: ElementRef) : void {
    if(divQueueState?.nativeElement && divQueueState.nativeElement.classList.contains(CssCustomClass.SlimDivQueueStatePosition)) {
      divQueueState.nativeElement.classList.remove(CssCustomClass.SlimDivQueueStatePosition);
    }
 }

  public positionSpanQueueState(spanQueueStates: ElementRef[]) : void {
    var addingClassList = [];
    addingClassList.push(CssCustomClass.SlimSpanQueueStateSize);
    this.appendClassListRef(spanQueueStates, addingClassList);
  }


  public positionDeviceSpanQueueState(spanQueueStates: ElementRef[]) : void {
    var addingClassList = [];
    addingClassList.push(CssCustomClass.DeviceSlimSpanQueueStateSize);
    this.appendClassListRef(spanQueueStates, addingClassList);
  }
}