<div class="card shadow mb-4">
    <div class="card-header py-3">
        <div *ngIf="selectedFiles" class="row">
            <h5 class="col-4 m-0 font-weight-bold text-primary">{{ 'file-manager' | translate }}</h5>
            <div class="col-4">
                <app-file-path [path]="path" (pathToEmit)="setPath($event)"></app-file-path>
            </div>
            <div class="text-right col-4">
                <button class="btn btn-danger btn-circle btn-sm" matTooltip="{{ 'delete-selected' | translate }}"
                    tooltipClass="tooltip-danger" (click)="deleteSelected()" [disabled]="disableDeleteSelected()">
                    <i class="fas fa-trash"></i>
                </button>
                <button class="btn btn-info btn-circle btn-sm ml-2" matTooltip="{{ 'refresh' | translate }}"
                    tooltipClass="tooltip-info" (click)="getDirectoryContent()">
                    <i class="fas fa-fw fa-sync-alt"></i>
                </button>
                <button class="btn btn-primary btn-circle btn-sm ml-2" matTooltip="{{ 'add-files' | translate }}"
                    tooltipClass="tooltip-primary" data-toggle="modal" data-target="#fileUploaderModal"
                    (click)="clearUploaderQueue = true">
                    <i class="fas fa-file-upload"></i>
                </button>
                <button class="btn btn-secondary btn-circle btn-sm ml-2" matTooltip="{{ 'add-link' | translate }}"
                    tooltipClass="tooltip-secondary" data-toggle="modal"
                    data-target="#addLinkModal">
                    <i class="fas fa-link"></i>
                </button>
                <button type="button" class="btn btn-warning btn-circle btn-sm ml-2" tooltipClass="tooltip-warning"
                    matTooltip="{{ 'create-folder' | translate }}" data-toggle="modal" data-target="#createFolderModal">
                    <i class="fas fa-folder-plus"></i>
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="!allElementsLoaded" class="loader"></div>
    <div *ngIf="content  && allElementsLoaded" class="card-body row">
        <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12 d-flex align-items-stretch card-height mb-2 pr-0 pl-0"
            *ngFor="let item of content; let i = index">
            <span [matMenuTriggerFor]="fileOptions" [matMenuTriggerData]="{element: item}"
                #menuTrigger="matMenuTrigger">
            </span>
            <div (contextmenu)="openMenu($event, menuTrigger)" (click)="selectedFiles[i] = !selectedFiles[i]"
                class="container-fluid text-center pointer" [ngClass]="{'selected': selectedFiles[i]}">
                <img *ngIf="item.type == 0 && (service.isImage(item.path) || service.isVideo(item.path))"
                    [src]="service.getPathToImage(item.path, item.name)"
                    (error) ="onImgError($event, item.path, item.name)"
                    alt="QCloud File" class="img-fluid rounded height">
                <i *ngIf="item.type == 0 && service.isUrl(item.name)" class="fas fa-globe fa-5x"
                    (dblclick)="activateLink(item.name)"></i>
                <i *ngIf="item.type == 0 && service.isFail(item.name)" class="fas fa-times fa-5x"
                    matTooltip="{{ 'file-fail' | translate }}" tooltipClass="tooltip-secondary">
                </i>
                <i *ngIf="item.type == 0 && service.isProgress(item.name)" class="fas fa-spinner fa-spin fa-5x"
                    matTooltip="{{ 'file-conversion-in-progress' | translate }}"
                    tooltipClass="tooltip-secondary">
                </i>
                <i *ngIf="item.type == 1" class="fas fa-folder fa-5x card-img-top" (dblclick)="changeFolder(item)"></i>
                <div>{{ displayFilename(item.name, i) | removeExtention }}</div>
            </div>
        </div>
    </div>
</div>

<mat-menu #fileOptions="matMenu" [overlapTrigger]="true">
    <ng-template matMenuContent let-element="element">
        <button mat-menu-item>{{ displayFilename(element.name, 0) }}</button>

        <button mat-menu-item *ngIf="element.type == 0" (click)="openModal(imageModal)">
            {{ 'view' | translate }}
        </button>

        <button mat-menu-item [matMenuTriggerFor]="properties" [matMenuTriggerData]="{self: element}">
            {{ 'informations' | translate }}
        </button>

        <ng-template #imageModal let-modal>
            <div class="modal-body" mat-dialog-content>
                <div *ngIf="(service.isImage(element.path) || service.isVideo(element.path))">
                    <img [src]="service.getPathToImage(element.path, element.name)" class="img-fluid rounded"
                        alt="image">
                    <div>{{ element.name }}</div>
                </div>
                <a *ngIf="(service.isUrl(element.path))" (click)="activateLink(element.name)" routerLink="/filemanager">
                    {{ displayFilename(element.name, 0) }}</a>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary" (click)="modal.close('Save click')" mat-dialog-close>
                    {{ 'close' | translate }}
                </button>
            </div>
        </ng-template>

        <button mat-menu-item (click)="delete(element)">{{ 'delete' | translate }}</button>
    </ng-template>
</mat-menu>

<mat-menu #properties="matMenu">
    <ng-template matMenuContent let-self="self">
        <button *ngIf="self.type == 0 && (service.isImage(self.path) || service.isVideo(self.path))" mat-menu-item>
            {{ 'size' | translate }}: {{self.size/1024/1024 | number:'.2' }}MB
        </button>
        <button mat-menu-item>
            {{ 'creation-date' | translate }}: {{self.creationDate | date:'yyyy-MM-dd HH:mm:ss'}}</button>
    </ng-template>
</mat-menu>

<!-- File Uploader Modal-->
<div class="modal fade" id="fileUploaderModal" tabindex="-1" role="dialog" aria-labelledby="modalLabel"
    aria-hidden="true" *ngIf="content">
    <app-file-uploader [path]="path" (getFolderContent)="getDirectoryContent($event)" [filesCount]="content.length"
        [clearUploaderQueue]="clearUploaderQueue" (unsetClearingUploaderQueue)="clearUploaderQueue = false">
    </app-file-uploader>
</div>

<!-- Create Folder Modal-->
<div class="modal fade" id="createFolderModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{ 'create-folder' | translate }}</h5>
                <button class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="input-group">{{ 'folder-name' | translate }}</div>
                <div class="input-group">
                    <input [(ngModel)]="folderName" class="form-control">
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-danger" data-dismiss="modal">{{ 'cancel' | translate }}</button>
                <button class="btn btn-primary" data-dismiss="modal" (click)="createFolder(folderName)">
                    {{ 'create-folder' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Add Link Modal-->
<div class="modal fade" id="addLinkModal" tabindex="-1" role="dialog" aria-hidden="true">
    <app-link-uploader [path]="path" (getFolderContent)="getDirectoryContent()"></app-link-uploader>
</div>