<div class="card shadow mb-4">
    <div class="card-header py-3">
        <div class="row">
            <h5 class="col-8 m-0 font-weight-bold text-primary">{{ 'users-roles' | translate }}</h5>
            <div class="text-right col-4">
                <button type="button" class="btn btn-primary btn-circle btn-sm" routerLink="/systemQcloudRole"
                    matTooltip="{{ 'add' | translate }}" tooltipClass="tooltip-primary">
                    <i class="fas fa-fw fa-plus"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th class="table-header-id">#</th>
                        <th> {{ 'qcloud.role-name' | translate }}</th>
                        <th>{{ 'permission-level' | translate }}</th>
                        <th>{{ 'qcloud.assigned-location' | translate }}</th>
                        <th>{{ 'options' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let role of systemRoles; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>{{ role.roleName }}</td>
                        <td>
                            <div [ngSwitch]="role.rolePermissionLevel">
                                <span *ngSwitchCase="rolePermissionLevelEnum.SUPERADMIN">
                                    {{ 'super-administrator' | translate }}
                                </span>
                                <span *ngSwitchCase="rolePermissionLevelEnum.ADMIN">
                                    {{ 'administrator' | translate }}
                                </span>
                                <span *ngSwitchCase="rolePermissionLevelEnum.MANAGER">
                                    {{ 'manager' | translate }}
                                </span>
                                <span *ngSwitchCase="rolePermissionLevelEnum.EMPLOYEE">
                                    {{ 'employee' | translate }}
                                </span>
                            </div>
                        </td>
                        <td>
                            <span *ngIf="role.locations && role.locations.length === 1"
                                (click)="openLocationDetails(role)" tooltipClass="tooltip-primary"
                                matTooltip="{{'show-details' | translate }}"
                                class="mr-2 popup-button btn btn-outline-info">{{role.locations[0].locationName}} : {{role.locations[0].locationTag}}</span>
                            <ng-container *ngIf="role.locations && role.locations.length > 1">
                                <span *ngFor="let location of role.locations | slice:0:showLimitLocationIds"
                                    class="mr-2 mx-1 mb-1 btn-circle btn-sm popup-button btn btn-outline-info" style="padding: 1.1rem;"
                                    (click)="openLocationDetails(role)" tooltipClass="tooltip-primary"
                                    matTooltip="{{'show-details' | translate }}">{{location.locationTag}}</span>
                            </ng-container>
                            <span *ngIf="role.locations && role.locations.length > showLimitLocationIds" class="mr-2 popup-button btn btn-outline-info"
                                tooltipClass="tooltip-primary" matTooltip="{{'show-details' | translate }}"
                                (click)="openLocationDetails(role)">...</span>
                        </td>
                        <td>
                            <div class="row">
                                <button class="btn btn-info btn-circle btn-sm mx-1 mb-1"
                                    [routerLink]="['/systemQcloudRole/', role.id]" matTooltip="{{ 'edit' | translate }}"
                                    tooltipClass="tooltip-info">
                                    <i class="fas fa-fw fa-edit"></i>
                                </button>
                                <button (click)="selectedLdapId = role.id"
                                    class="btn btn-danger btn-circle btn-sm mx-1 mb-1"
                                    matTooltip="{{ 'delete' | translate }}" tooltipClass="tooltip-danger"
                                    data-toggle="modal" data-target="#deleteModal">
                                    <i class="fas fa-fw fa-trash"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-hidden="true">
    <app-delete-modal [info]="'delete-confirmation'" [confirm]="'delete'"
        (confirmed)="onDeleteConfirm($event)"></app-delete-modal>
</div>