import { Component, OnInit, Input } from '@angular/core';
import { NgForm, ControlContainer } from '@angular/forms';
import { SayingTicketsTypes } from 'projects/qcloud-models/device/saying-tickets-types.enum';
import { Device } from 'projects/qcloud-models/device/device.model';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { LayoutDeskType } from 'projects/qcloud-models/device/layout-desk-type.enum';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';

@Component({
  selector: 'app-desk-display',
  templateUrl: './desk-display.component.html',
  styleUrls: ['./desk-display.component.css'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class DeskDisplayComponent implements OnInit {

  @Input() device: Device;
  @Input() desks: Desk[];

  LayoutDeskType = LayoutDeskType;
  SayingTicketsTypes = SayingTicketsTypes;

  constructor(public locationService: LocationService) { }

  ngOnInit(): void {
    if(!this.device.layoutType) {
      this.device.layoutType = LayoutDeskType.Basic;
    }
    if(!this.device.sayingTicketsType) {
      this.device.sayingTicketsType = SayingTicketsTypes.NoSound;
    }
  }

}
