import { ReportScope } from "./report-scope.enum";

export class ReportTemplateParameter {
    public id: number;
    public reportScope: ReportScope;
    public ticketCount: boolean;
    public pause: boolean;
    public redirection: boolean;
    public serviceWaitingTime: boolean;
    public onHour: boolean;
    public opinions: boolean;
    public ticketReservations: boolean;
    public loginTime: boolean;
    public deskStatusTime: boolean;
    public elements: QCloudElement[];
}

export class QCloudElement {
    public id: any;
    public fullName: string;

    constructor(id: any, fullName: string) {
        this.id = id;
        this.fullName = fullName;
    }
}