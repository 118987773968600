<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="m-0 font-weight-bold text-primary">{{ 'languages' | translate }}</h5>
    </div>
    <div *ngIf="locationData" class="card-body">
        <form #form="ngForm" autocomplete="off">

            <mat-form-field>
                <mat-label>{{ 'system-main-language' | translate }}</mat-label>
                <mat-select matNativeControl name="mainLanguageSelect" 
                    [(ngModel)]="mainLanguageSelect" [disabled] ="disableMainLanguageSelection" required>
                    <mat-option *ngFor="let item of languages" value="{{ item.locale }}">
                        {{ item.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="form-group">
                <div class="item-group">
                    <mat-checkbox name="multiLanguageSystem" #multiLanguageSystem="ngModel"
                        [(ngModel)]="locationData.multiLanguageSystem" color="primary">
                        {{ 'multi-language-system' | translate }}
                    </mat-checkbox>
                </div>
            </div>

            <div class="form-group">
                <div class="item-group">
                    <mat-checkbox name="readTicketAlwaysInOriginLangauge" #readTicketAlwaysInOriginLangauge="ngModel"
                        [(ngModel)]="locationData.readTicketAlwaysInOriginLangauge" color="primary">
                        {{ 'read-ticket-always-in-orgin-language' | translate }}
                    </mat-checkbox>
                </div>
            </div>

            <app-languages-selection *ngIf="languageMultiCtrl && translationLanguages"
                [languages]="translationLanguages" [languageMultiCtrl]="languageMultiCtrl" [disableLanguageSelection]="!locationData.multiLanguageSystem">
            </app-languages-selection>

            <div class="form-group row">
                <app-submit-button (click)="onSubmit()" type="submit" [loading]="formSubmitted"
                    [disabled]="form.invalid" [text]="'save-languages' | translate">
                </app-submit-button>
            </div>
        </form>
    </div>
</div>

<div *ngIf="locationData && translations && languages">
    <app-personalisation-translation [locationData]="locationData" [translations]="translations"
        [languages]="languages">
    </app-personalisation-translation>

    <app-ticket-translation [locationLocaleMain]="locationData.locationLocaleMain" [translations]="translations"
        [languages]="languages">
    </app-ticket-translation>

    <app-category-translation [locationLocaleMain]="locationData.locationLocaleMain" [translations]="translations"
        [languages]="languages">
    </app-category-translation>

    <app-desk-translation [locationLocaleMain]="locationData.locationLocaleMain" [translations]="translations"
        [languages]="languages">
    </app-desk-translation>

    <app-device-group-translation [locationLocaleMain]="locationData.locationLocaleMain" [translations]="translations"
        [languages]="languages">
    </app-device-group-translation>

    <app-reservation-translation [locationLocaleMain]="locationData.locationLocaleMain"
        [translations]="translations" [languages]="languages">
    </app-reservation-translation>
</div>