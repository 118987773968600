import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { QcloudConsoleService } from '../../qcloud-console.service';

@Component({
  selector: 'lib-cancel-ticket-button',
  templateUrl: './cancel-ticket-button.component.html',
  styleUrls: ['./cancel-ticket-button.component.css',  './../base-button/base-button.component.css']
})
export class CancelTicketButtonComponent  implements OnInit {
  @Input() public tooltipTitle: string;
	@ViewChild('button') public button: ElementRef;
  @ViewChild('icon') public icon: ElementRef;
  isEnabled: boolean;
  isBlocked: boolean;
  isLocked: boolean;
  constructor(private consoleService: QcloudConsoleService) {
    this.consoleService.ticket.subscribe((val) => {
      if (val.id != 0 && val.id != -1) {
        this.isEnabled = true;
      }
      else {
        this.isEnabled = false;
      }
    })
    this.consoleService.isBlocked.subscribe((val) => {
      this.isBlocked = val;
    });
    this.consoleService.isLocked.subscribe((val) => {
      this.isLocked = val;
    });
  }

  ngOnInit(): void {
  }

  public cancelTicket() {
    if (this.isEnabled)
      this.consoleService.cancelTicket();
  }
}
