import { Component, Input, OnInit } from '@angular/core';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { Dictionary } from 'projects/qcloud-models/dictionary';
import { ProcessedTicketsCount } from 'projects/qcloud-models/statistics/processed-tickets-count';

@Component({
  selector: 'app-ticket-in-desk-statistics',
  templateUrl: './ticket-in-desk-statistics.component.html',
  styleUrls: ['./ticket-in-desk-statistics.component.css']
})
export class TicketInDeskStatisticsComponent implements OnInit {

  @Input() desks: Desk[];
  @Input() processedDeskTicketsCounts: Dictionary<ProcessedTicketsCount>;
  selectedDeskId: number;
  maxTickets: number;

  constructor() { }

  ngOnInit(): void {
    this.selectedDeskId = this.desks[0].id;
    this.changeDesk();
  }

  changeDesk() {
    if (this.processedDeskTicketsCounts[this.selectedDeskId]) {
      this.maxTickets = Math.max(...[this.processedDeskTicketsCounts[this.selectedDeskId].acceptedTicketsCount,
        this.processedDeskTicketsCounts[this.selectedDeskId].cancelledTicketsCount,
        this.processedDeskTicketsCounts[this.selectedDeskId].redirectedTicketsCount,
        this.processedDeskTicketsCounts[this.selectedDeskId].waitingTicketsCount]);
    }
  }

}
