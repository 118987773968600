import { Component, Input, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Category } from 'projects/qcloud-models/category/category.model';
import { LocationActivityBlock } from 'projects/qcloud-models/location/location-activity-block.model';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';

@Component({
  selector: 'app-category-blocking-schedule',
  templateUrl: './category-blocking-schedule.component.html',
  styleUrls: ['./category-blocking-schedule.component.css'],
  encapsulation : ViewEncapsulation.None
})
export class CategoryBlockingScheduleComponent implements OnInit {

  @ViewChild('modalActivityBlock', { static: true }) modalActivityBlock: TemplateRef<any>;
  @Input() categories: Category[];
  locationActivityBlocks: LocationActivityBlock[];
  currentActivityBlock: LocationActivityBlock;
  blockedCategories: Category[][];
  isNewActivityBlock: boolean;

  public categoryMultiCtrl: UntypedFormControl;

  categoriesToBlock: string = 'categories-to-block';
  noSelectedCategories = 'no-selected-categories';

  constructor(private locationServcie: LocationService, private translate: TranslateService,
    private toastr: ToastrService, private authService: AuthService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getLocationActivityBlocks();
  }

  getLocationActivityBlocks() {
    this.locationServcie.getActivity().subscribe(
      res => {
        this.locationActivityBlocks = res;
        this.assignCategories();
      }
    );
  }

  assignCategories() {
    this.blockedCategories = new Array<Category[]>();
    this.locationActivityBlocks.forEach(activityBlock =>
        this.blockedCategories.push(this.getActivityBlockCategories(activityBlock))
    );
  }

  getActivityBlockCategories(activityBlock: LocationActivityBlock) {
    let initialCategories = new Array<Category>();
    for (let j = 0; j < activityBlock.blockedCategoryIds.length; j++) {
      for (let i = 0; i < this.categories.length; i++) {
        if (this.categories[i].id == activityBlock.blockedCategoryIds[j]) {
          initialCategories.push(this.categories[i]);
        }
      }
    }
    return initialCategories;
  }

  getActivityBlock(i: number) {
    this.isNewActivityBlock = false;
    this.currentActivityBlock = this.locationActivityBlocks[i];
    this.currentActivityBlock.dateStart = new Date(this.locationActivityBlocks[i].dateStart);
    this.currentActivityBlock.dateEnd = new Date(this.locationActivityBlocks[i].dateEnd);
    this.categoryMultiCtrl = new UntypedFormControl(this.getActivityBlockCategories(this.currentActivityBlock));
    this.showModal();
  }

  onDeleteConfirm(confirmed: boolean) {
    if (confirmed) {
      this.deleteActivityBlock();
    }
  }

  deleteActivityBlock() {
    this.locationServcie.deleteActivity(this.currentActivityBlock.id).subscribe(
      res => {
        this.translate.get('delete-activity-blocks-success').subscribe((res: string) => {
          this.toastr.success(res);
          this.getLocationActivityBlocks();
        });
      },
      err => {
        this.translate.get('delete-activity-blocks-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  newActivityBlock() {
    this.isNewActivityBlock = true;
    this.currentActivityBlock =
      new LocationActivityBlock(+this.authService.getLocationId(), +this.authService.getSystemId());
    this.categoryMultiCtrl = new UntypedFormControl(new Array<Category>());
    this.showModal();
  }

  showModal() {
    var matDialogConfig = new MatDialogConfig();
    matDialogConfig.maxHeight = 900;
    matDialogConfig.maxWidth = 800;
    this.dialog.open(this.modalActivityBlock, matDialogConfig);
  }

  getRange(range: Date[]) {
    this.currentActivityBlock.dateStart = range[0];
    this.currentActivityBlock.dateEnd = range[1];
  }

  onSubmit() {
    this.currentActivityBlock.blockedCategoryIds = this.categoryMultiCtrl.value.map(category => category.id);
    if (!this.locationActivityBlocks.find(x => x.id == this.currentActivityBlock.id)) {
      this.locationActivityBlocks.push(this.currentActivityBlock);
    }
    this.locationServcie.setActivity(this.locationActivityBlocks).subscribe(
      res => {
        if (this.isNewActivityBlock) {
          this.translate.get('add-activity-blocks-success').subscribe((res: string) => {
            this.toastr.success(res);
          });
        } else {
          this.translate.get('edit-activity-blocks-success').subscribe((res: string) => {
            this.toastr.success(res);
          });
        }
        this.getLocationActivityBlocks();
      },
      err => {
        if (this.isNewActivityBlock) {
          this.translate.get('add-activity-blocks-error').subscribe((error: string) => {
            this.translate.get('repeat').subscribe((res: string) => {
              this.toastr.error(res, error);
            });
          });
        } else {
          this.translate.get('edit-activity-blocks-error').subscribe((error: string) => {
            this.translate.get('repeat').subscribe((res: string) => {
              this.toastr.error(res, error);
            });
          });
        }
      }
    );
  }

}
