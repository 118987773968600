<div *ngIf="locationConstraints" class="row">
    <div *ngIf="isConsoleVisible()" class="col-md-6 col-sm-12 mb-4">
        <div (click)="goToConsole()" class="card bg-info shadow h-100 py-2 pointer">
            <div class="card-body">
                <div class="row no-gutters align-items-center my-1">
                    <div class="col-10">
                        <div class="font-weight-bold text-white text-uppercase h3">
                            {{ 'call-console' | translate }}
                        </div>
                    </div>
                    <div class="col-2 text-white d-flex justify-content-center">
                        <i class="fas fa-keyboard fa-5x"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="authService.isManagerOrHigher()" class="col-md-6 col-sm-12 mb-4">
        <div (click)="goToDashboard()" class="card bg-primary shadow h-100 py-2 pointer">
            <div class="card-body">
                <div class="row no-gutters align-items-center my-1">
                    <div class="col-10">
                        <div class="font-weight-bold text-white text-uppercase h3">
                            {{ 'administration-panel' | translate }}
                        </div>
                    </div>
                    <div class="col-2 text-white d-flex justify-content-center">
                        <i class="fas fa-tools fa-5x"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isReservationVisible()" class="col-md-6 col-sm-12 mb-4">
        <div (click)="goToReservation()" class="card bg-success shadow h-100 py-2 pointer">
            <div class="card-body">
                <div class="row no-gutters align-items-center my-1">
                    <div class="col-10">
                        <div class="font-weight-bold text-white text-uppercase h3">
                            {{ 'reservation' | translate }}
                        </div>
                    </div>
                    <div class="col-2 text-white d-flex justify-content-center">
                        <i class="fas fa-calendar-alt fa-5x"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isWorkScheduleVisible()" class="col-md-6 col-sm-12 mb-4">
        <div (click)="goToWorkSchedule()" class="card bg-warning shadow h-100 py-2 pointer">
            <div class="card-body">
                <div class="row no-gutters align-items-center my-1">
                    <div class="col-10">
                        <div class="font-weight-bold text-white text-uppercase h3">
                            {{ 'work-schedule' | translate }}
                        </div>
                    </div>
                    <div class="col-2 text-white d-flex justify-content-center">
                        <i class="fas fa-calendar-alt fa-5x"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>