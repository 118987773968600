import { Component, Injector, OnInit } from '@angular/core';
import { Ticket } from 'projects/qcloud-models/console/ticket';
import { QcloudConsoleService } from '../../qcloud-console.service';
import { MatDialogRef } from '@angular/material/dialog';
import { TicketNoteTemplateVm } from '../../../../../qcloud-models/console/TicketNoteTemplateVm';
import { NoteQuestionAnswer } from '../../../../../qcloud-models/note/note-question-answer';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl } from '@angular/forms';
import { TicketNoteService } from '../../../../../qcloud-rest-client/src/lib/note/ticket-note.service';
import { FormNoteVm } from '../../../../../qcloud-models/console/note-dynamic-form/FormNoteVm';
import { TicketAnswerNoteVm } from '../../../../../qcloud-models/console/note-dynamic-form/TicketNoteFieldVm';
import { ToastrService } from 'ngx-toastr';
import { TicketNoteConfigurationService } from '../../../../../qcloud-rest-client/src/lib/note/ticket-note-configuration.service';
import { NoteTicketModalPopupData } from './note-ticket-modal-popup.data';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'lib-note-ticket-modal-popup',
  templateUrl: './note-ticket-modal-popup.component.html',
  styleUrls: ['./note-ticket-modal-popup.component.css']
})
export class NoteTicketModalPopupComponent implements OnInit {
  ticket: Ticket;
  parentControl: any; // Define parentControl as necessary
  public noteQuestionFormControl: UntypedFormControl = new UntypedFormControl();
  noteTemplates: TicketNoteTemplateVm[] = [];
  noteQuestions: TicketNoteTemplateVm[] = [];
  choosenTemplate: TicketNoteTemplateVm = new TicketNoteTemplateVm();
  noteAnswers: NoteQuestionAnswer[] = [];
  noteTicketModalPopupData: NoteTicketModalPopupData;
  dialogRef: MatDialogRef<NoteTicketModalPopupComponent> = null;

  constructor(private consoleService: QcloudConsoleService,
    private translate: TranslateService, private ticketNoteService: TicketNoteService, private toastr: ToastrService,
    private ticketNoteConfigurationService: TicketNoteConfigurationService,
    private injector: Injector
  ) {
    this.dialogRef = this.injector.get(MatDialogRef, null);
    var noteTicketModalData = new NoteTicketModalPopupData();
    noteTicketModalData.isReadOnly = false;
    noteTicketModalData.hideHeader = true;
    this.noteTicketModalPopupData = this.injector.get(MAT_DIALOG_DATA, noteTicketModalData);

    this.ticketNoteConfigurationService.getNoteTicketTemplates(
      (result => {
        var ticketNoteTemplateVms = result;
        ticketNoteTemplateVms.forEach(template => {
          this.noteTemplates.push(template);
        });
        if (this.noteTemplates.length > 0) {
          this.choosenTemplate = this.noteTemplates[0];
        }
        if (this.noteTicketModalPopupData.isReadOnly) {
          var sortedNoteAnswers = this.noteTicketModalPopupData.noteAnswers.sort((a, b) =>
            a.order - b.order
          );
          this.noteAnswers = sortedNoteAnswers;
          this.choosenTemplate = this.noteTemplates.find(t => t.id == this.noteTicketModalPopupData.formNoteTemplateId) ?? this.noteTemplates[0];
        } else {
          this.changeConfigurationFormField();
          this.ticket = new Ticket();
          this.consoleService.getLastTicket();
          this.consoleService.ticket.subscribe((val: Ticket) => {
            this.ticket.id = val.id;
          });
        }
      }),
      () => { });
  }

  ngOnInit(): void { }

  saveAndSendNote() {
    var formNote = new FormNoteVm();
    formNote.formNoteTemplateId = this.choosenTemplate.id;
    formNote.saveDateTime = new Date();
    this.noteAnswers.forEach(element => {
      var ticketAnswersNoteVm = new TicketAnswerNoteVm();
      ticketAnswersNoteVm.answer = element.answer;
      ticketAnswersNoteVm.questionId = element.questionId;
      formNote.TicketAnswerNoteVms.push(ticketAnswersNoteVm);
    });
    this.ticketNoteService.addAnswerNotesToTicket(this.ticket.id, formNote, (ticket) => {
      this.translate.get('note-form.save-success').subscribe(
        res => this.toastr.success(res));
      this.consoleService.closeAllModals();
      this.consoleService.isNotePopupOpened = false;
      this.consoleService.setIsLockedStatus(false);
      this.consoleService.setIsBlockedStatus(false);
      if (this.dialogRef) {
        this.dialogRef.close();
      }
    },
      () => {
        this.translate.get('note-form.save-error').subscribe(
          res => this.toastr.error(res));
      });
  }
  changeConfigurationFormField() {
    this.noteAnswers = [];
    var locale: string = this.translate.currentLang;
    this.choosenTemplate.noteQuestions.forEach(question => {
      var noteQuestionAnswer = new NoteQuestionAnswer(question, locale);
      this.noteAnswers.push(noteQuestionAnswer);
    });
  }
}
