import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { DeviceConfigurationService } from 'projects/qcloud-device/src/app/services/device-configuration.service';
import { TicketListenerService } from 'projects/qcloud-device/src/app/services/ticket.service';
import { UtilitiesService } from 'projects/qcloud-device/src/app/services/utilities.service';
import { Ticket } from 'projects/qcloud-models/console/ticket';
import { TicketDisplayRow } from 'projects/qcloud-models/device/ticket-display-row';
import { BaseComponent } from '../../../../shared/base-component/base.component';
import { TicketState } from 'projects/qcloud-models/console/ticketState.enum';

declare var window: any;

@Component({
  selector: 'app-basic-mix-list',
  templateUrl: './basic-mix-list.component.html',
  styleUrls: ['./basic-mix-list.component.css']
})
export class BasicMixListComponent extends BaseComponent implements OnInit {
  rowHeight: number = 0;
  rowPadding: number = 0;
  rows: TicketDisplayRow[] = [];
  animation: any;

  //TODO: add translations
  service = "Usługa";
  ticketNumber = "Numer Biletu"
  desk = "Stanowisko";

  constructor(protected elementRef: ElementRef, protected renderer: Renderer2) {
    super(elementRef, renderer);

  }

  startComponent() {
    var deviceConfiguration = this.configurationService.getConfiguration();
    let rows = [];
    deviceConfiguration.deviceCategoryTree.root.nodes.forEach(element => {
      let row = new TicketDisplayRow();
      if (element.value.id == -1) {
        row.texts[0] = element.value.fullName;
      }
      else {
        row.texts[0] = this.utilitiesService.getCategoryName(element.value.id);
      }
      row.texts[1] = "----";
      row.texts[2] = "--"
      row.categoryId = element.value.id;
      row.active = false;
      if (row.categoryId == -1) {
        row.categoryIds = element.nodes.map(x => x.value.id);
      }
      rows.push(row);
    });
    this.rows = rows;

    this.ticketListenerService.ticket.subscribe((val) => {
      this.ticketCalled(val);
    })
    this.setGridRows(rows.length);
  }

  ngOnInit() {

  }

  private setGridRows(number: number) {
    var orientation = window.orientation % 90 === 0 ? 'portrait' : 'landscape'
    if (orientation == 'landscape') {
      if (number < 3) number = 3;
      this.rowPadding = 0.05;
    }
    else {
      if (number < 6) number = 6;
      this.rowPadding = 0.1;
    }
    this.rowHeight = 100 / number;
    this.rowPadding *= this.rowHeight;
  }

  getRowHeightStyle(i) {
    if (i == this.rows.length - 1) {
      return { height: this.rowHeight + '%' }
    }
    return { height: this.rowHeight + '%', "padding-bottom": this.rowPadding + '%' }
  }

  private ticketCalled(ticket: Ticket) {
    if (ticket.ticketState != TicketState.Called)
      return;
    let row = this.rows.filter(x => x.categoryId == ticket.effectiveCategoryId)[0];
    if (row == undefined) {
      this.rows.filter(x => x.categoryId == -1).forEach(element => {
        if (element.categoryIds.filter(y => y == ticket.effectiveCategoryId)[0] != undefined) {
          row = element;
          return;
        }
      });
    }
    row.texts[1] = ticket.caption;
    row.texts[2] = this.utilitiesService.getDeskNumber(ticket.deskId);
    row.texts[0] = this.utilitiesService.getCategoryName(ticket.effectiveCategoryId);
    this.clearAnimation();
    this.animate(row);
  }

  private clearAnimation() {
    if (this.animation != undefined) {
      window.clearInterval(this.animation);
    }
    this.rows.forEach(element => {
      element.active = false;
    });
  }

  private animate(row: TicketDisplayRow) {
    let x = 0;
    let context = this;
    this.animation = window.setInterval(function () {
      row.active = !row.active;
      if (++x === 6 && context.animation != undefined) {
        window.clearInterval(context.animation);
      }
    }, 1000);
  }

  //this code is for wysiwyg on web panel
  initMock() {
    let rows: TicketDisplayRow[] = [];
    for (let i = 0; i < 3; i++) {
      let row = new TicketDisplayRow();
      if (i == 0) {
        row.active = true;
      }
      row.texts[0] = "Example " + (i + 1);
      row.texts[1] = "A00" + (i + 1);
      row.texts[2] = "1"
      rows.push(row);
    }
    this.rows = rows;
    this.setGridRows(rows.length);
  }


}
