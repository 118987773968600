import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { NoteQuestionAnswer } from '../../../../../../qcloud-models/note/note-question-answer';
import { NoteQuestionType } from '../../../../../../qcloud-models/note/note-question-type.enum';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-note-modal-questions',
  templateUrl: './note-modal-questions.component.html',
  styleUrls: ['./note-modal-questions.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class NoteModalQuestionsComponent implements OnInit {
  @Input() answers: NoteQuestionAnswer[];
  noteQuestionTypeEnum = NoteQuestionType;
  containsLinkRegex = new RegExp("([a-zA-Z\d]+[.][A-Za-z]{2,4})");
  textInvalid = false;
  FIRST_OPTION = '1';
  SECOND_OPTION = '2';
  isMainComponentDisabled: boolean = false;

  ngOnInit(): void {
    this.answers = this.answers.sort((a, b) =>
      a.order - b.order
    );
    this.answers.forEach(answer => {
      if (answer.questionType == this.noteQuestionTypeEnum.Option ||
        answer.questionType == this.noteQuestionTypeEnum.VisibleOption) {
        if (!answer.answer) {
          answer.answer = this.FIRST_OPTION;
        }
      }
    });
  }

  checkVisible(answer: NoteQuestionAnswer): boolean {
    var result = answer.visible;
    if(result === false) {
      var visibleOptionAnswer = this.answers.find(x => x.questionType == this.noteQuestionTypeEnum.VisibleOption);
      result = result || (visibleOptionAnswer && visibleOptionAnswer.answer == '2');
    }
      return result;
  }

  checkForUrls(event: InputEvent, answer: string) {
    if (this.containsLinkRegex.test(answer)) {
      this.textInvalid = true;
    }
    else {
      this.textInvalid = false;
    }
  }
}
