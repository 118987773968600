import { Component, OnInit } from '@angular/core';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { UserService } from 'projects/qcloud-rest-client/src/lib/user.service';
import { QcloudConsoleService } from '../../qcloud-console.service';

@Component({
  selector: 'lib-display-user',
  templateUrl: './display-user.component.html',
  styleUrls: ['./display-user.component.css',  './../base-button/base-button.component.css']
})
export class DisplayUserComponent implements OnInit {

  userFullName: string;

  constructor(private consoleService: QcloudConsoleService, private authService: AuthService,
    private userService: UserService) {
  }

  ngOnInit(): void {
      this.userFullName = this.consoleService.user.firstName + " " + this.consoleService.user.lastName;
  }

}
