import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { FileService } from 'projects/qcloud-rest-client/src/lib/file.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-link-uploader',
  templateUrl: './link-uploader.component.html',
  styleUrls: ['./link-uploader.component.css']
})
export class LinkUploaderComponent implements OnInit {

  @Input() path: string;
  uploader: FileUploader;
  link: string;
  @Output() getFolderContent = new EventEmitter();

  constructor(private service: FileService, private toastr: ToastrService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.uploader = new FileUploader({
      isHTML5: true,
      url: this.service.getUploadAddress()
    });
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      form.append('path', this.path);
    };
    this.uploader.onCompleteAll= () => { 
      this.getFolderContent.emit("");
    }
  }

  //Right now links can only be 255 chars long, and canot contain '*'
  async addLink() {
    let name = this.createName(this.link);
    if(await this.validateName(name)){
      let file: File = new File([], name);
      this.uploader.addToQueue(new Array<File>(file));
      this.uploader.uploadAll();
      this.link = "";
    }
  }

  createName(link: string) {
    return encodeURIComponent(link)+'.https';
  }

  validateName(link: string): Boolean{
    const rules = [
      {rgx: /.{256,}/, message: 'link-length-error'},
      {rgx: /[*]/, message: 'asterisk-error'}
    ];

    let result = true;
    rules.forEach(r => {
      if(link.match(r.rgx)){
        result = false;
        this.translate.get(r.message).subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    });
    return result;
  }

}
