<div class="modal-header" *ngIf="!noteTicketModalPopupData.hideHeader">
    <h5 class="modal-title" *ngIf="!noteTicketModalPopupData.isReadOnly" id="exampleModalLabel">*{{
        'console.note-ticket' | translate }}
    </h5>
    <h5 class="modal-title" *ngIf="noteTicketModalPopupData.isReadOnly" id="exampleModalLabel">{{
        'note-form.details-note-ticket' |
        translate }}</h5>
    <button class="close" *ngIf="noteTicketModalPopupData.isReadOnly" mat-dialog-close>
        <span aria-hidden="true">×</span>
    </button>
</div>
<div>
    <div class="modal-body" mat-dialog-content>
        <form #form="ngForm">
            <div class="form-group">
                <div>
                    <span *ngIf="!noteTicketModalPopupData.isReadOnly">*{{'note-form.choose-note-form-template' |
                        translate}}</span>
                    <span *ngIf="noteTicketModalPopupData.isReadOnly">*{{'note-form.note-form-template' |
                        translate}}</span>
                    <div class="input-group">
                        <select matNativeControl class="form-control" name="choosenNoteTemplate"
                            [ngClass]="{'show-details': noteTicketModalPopupData.isReadOnly == true}"
                            [disabled]="noteTicketModalPopupData.isReadOnly" #choosenNoteTemplate="ngModel"
                            [(ngModel)]="choosenTemplate" (change)="changeConfigurationFormField()" required>
                            <option *ngFor="let noteTemplate of noteTemplates" [ngValue]="noteTemplate">
                                {{noteTemplate.name}}</option>
                        </select>
                        <div *ngIf="choosenNoteTemplate.invalid && (choosenNoteTemplate.dirty || choosenNoteTemplate.touched)"
                            class="text-danger mx-2 my-1">
                            <div *ngIf="choosenNoteTemplate.errors.required">
                                {{ 'note-form.choosen-template-required' | translate }}
                            </div>
                        </div>
                    </div>
                    <app-note-modal-questions [answers]="noteAnswers"></app-note-modal-questions>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" (click)="saveAndSendNote();" [disabled]="form.invalid" type="submit"
                    *ngIf="!noteTicketModalPopupData.isReadOnly">
                    {{'save' | translate}}
                </button>
                <button class="btn btn-danger block" *ngIf="noteTicketModalPopupData.isReadOnly" mat-dialog-close>
                    {{ 'close' | translate }}
                </button>
            </div>
            <label *ngIf="form.invalid" class="text-danger">*{{ 'complete-required-fields' | translate }}</label>
        </form>
    </div>
</div>