<div class="card shadow mb-4">
    <div class="card-header py-3">
        <div class="row">
            <h5 class="col-8 m-0 font-weight-bold text-primary">{{ 'ticket-design' | translate }}</h5>
            <div class="col-4 text-right">
                <button type="button" class="btn btn-warning btn-circle btn-sm" tooltipClass="tooltip-warning"
                    matTooltip="{{ 'set-default-values' | translate }}" (click)="deleteTicketLayout()">
                    <i class="fas fa-fw fa-sync"></i>
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="ticketLayout" class="card-body">
        <form #form="ngForm" autocomplete="off">
            <div class="form-group">
                <div class="item-group">
                    <mat-checkbox name="printsLogoOnTicket" #printsLogoOnTicket="ngModel"
                        [(ngModel)]="ticketLayout.printsLogoOnTicket" class="font-weight-bold"
                        color="primary">{{ 'dislaying-logo' | translate }}</mat-checkbox>
                </div>
            </div>

            <div *ngIf="ticketLayout.printsLogoOnTicket" class="form-group">
                {{ 'logo' | translate }}:
                <button class="btn btn-primary btn-sm" data-toggle="modal" data-target="#fileManagerModal">
                    {{ 'select-logo' | translate }}
                </button>
                {{ logoPath }}
            </div>

            <!-- File Manager Modal-->
            <div class="modal fade" id="fileManagerModal" tabindex="-1" role="dialog" aria-labelledby="modalLabel"
                aria-hidden="true">
                <app-file-popup [selectedFilePath]="logoPath" (filePathToEmit)="getLogoPath($event)"></app-file-popup>
            </div>

            <div class="form-group">
                <div class="item-group">
                    <mat-checkbox name="printsQueueStateOnTicket" #printsQueueStateOnTicket="ngModel"
                        [(ngModel)]="ticketLayout.printsQueueStateOnTicket" class="font-weight-bold"
                        color="primary">{{ 'displaying-queue-status' | translate }}</mat-checkbox>
                </div>
            </div>

            <div class="form-group">
                <div class="item-group">
                    <mat-checkbox name="printsDesksOnTicket" #printsDesksOnTicket="ngModel"
                        [(ngModel)]="ticketLayout.printsDesksOnTicket" class="font-weight-bold"
                        color="primary">{{ 'print-desks-on-ticket' | translate }}</mat-checkbox>
                </div>
            </div>

            <div class="form-group">
                <div class="item-group">
                    <mat-checkbox name="printsAvgWaitTimeOnTicket" #printsAvgWaitTimeOnTicket="ngModel"
                        [(ngModel)]="ticketLayout.printsAvgWaitTimeOnTicket" class="font-weight-bold"
                        color="primary">{{ 'print-avg-time-on-ticket' | translate }}</mat-checkbox>
                </div>
            </div>

            <div class="form-group">
                {{ 'additional-text-ticket' | translate }}
                <div class="input-group">
                    <input name="printedTicketAdditionalText" #printedTicketAdditionalText="ngModel"
                    [(ngModel)]="ticketLayout.ticketWords.additionalTicketText" class="form-control">
                </div>
            </div>

            <div class="form-group">
                {{ 'waiting-time-text-ticket' | translate }}
                <div class="input-group">
                    <input name="waitingTimeTicketText" #waitingTimeTicketText="ngModel"
                    [(ngModel)]="ticketLayout.ticketWords.waitingTimeTicketText" class="form-control">
                </div>
            </div>

            <div class="form-group">
                {{ 'arrival-time-text-ticket' | translate }}
                <div class="input-group">
                    <input name="arrivalTimeText" #arrivalTimeText="ngModel"
                    [(ngModel)]="ticketLayout.ticketWords.arrivalTimeText" class="form-control">
                </div>
            </div>

            <div class="form-group">
                {{ 'invitation-text-ticket' | translate }}
                <div class="input-group">
                    <input name="invitationText" #invitationText="ngModel"
                    [(ngModel)]="ticketLayout.ticketWords.invitationText" class="form-control">
                </div>
            </div>

            <div class="form-group">
                {{ 'queue-state-text-ticket' | translate }}
                <div class="input-group">
                    <input name="queueStateText" #queueStateText="ngModel"
                    [(ngModel)]="ticketLayout.ticketWords.queueStateText" class="form-control">
                </div>
            </div>

            <div class="form-group">
                {{ 'service-provided-by-text-ticket' | translate }}
                <div class="input-group">
                    <input name="serviceProvidedByText" #serviceProvidedByText="ngModel"
                    [(ngModel)]="ticketLayout.ticketWords.serviceProvidedByText" class="form-control">
                </div>
            </div>

            <div class="form-group row">
                <button class="btn btn-primary m-1" type="submit" [disabled]="formSubmitted || form.invalid"
                    (click)="onSubmit(); formSubmitted=true">
                <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                    aria-hidden="true"></span>{{ 'save-ticket-design' | translate }}
                </button>
            </div>
        </form>
    </div>
</div>
