import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { TicketHistoryParams } from 'projects/qcloud-models/statistics/ticket-history-params';
import { ReportService } from 'projects/qcloud-rest-client/src/lib/report.service';
import { DateAdapter } from '@angular/material/core';
import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Category } from "../../../../../../qcloud-models/category/category.model";
import { TimeConversionService } from "../../../shared/time-conversion.service";
import { NoteHistoryParams } from '../../../../../../qcloud-models/statistics/note-history-params';
import { NoteQuestionType } from '../../../../../../qcloud-models/note/note-question-type.enum';
import { TicketNoteConfigurationService } from '../../../../../../qcloud-rest-client/src/lib/note/ticket-note-configuration.service';
import { NoteQuestion } from '../../../../../../qcloud-models/note/note-questions';
import { FormNote } from '../../../../../../qcloud-models/console/note-dynamic-form/FormNote';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NoteTicketModalPopupComponent } from '../../../../../../qcloud-console/src/lib/desk/note-ticket-modal-popup/note-ticket-modal-popup.component';
import { finalize } from 'rxjs';
import { NoteTicketModalPopupData } from '../../../../../../qcloud-console/src/lib/desk/note-ticket-modal-popup/note-ticket-modal-popup.data';
import { NoteQuestionAnswer } from '../../../../../../qcloud-models/note/note-question-answer';

@Component({
  selector: 'app-note-history-statistics',
  templateUrl: './note-history-statistics.component.html',
  styleUrls: ['./note-history-statistics.component.css']
})
export class NoteHistoryStatisticsComponent implements OnInit {

  categories: Category[];
  categoryMultiCtrl: UntypedFormControl;
  servedCategories: string = 'served-categories';
  selectCategory: string = 'select-category';
  noSelectedCategories = 'no-selected-categories';
  noSelectedElements = 'no-selected-elements';
  ticketParams: TicketHistoryParams;
  noteParams: NoteHistoryParams;
  dateCtrl: UntypedFormControl;
  ticketHistoryData: Array<any> = new Array();
  placeholder: string = "placeholder";
  nomatch: string = "nomatch";
  filteringQuestionIdsByInvoiceField: number[] = [];
  notesHistory: Array<FormNote> = new Array();
  noteQuestions: NoteQuestion[] = [];
  dialogRef: any;

  constructor(private categoryService: CategoryService, private reportService: ReportService,
    private translate: TranslateService,
    private toastr: ToastrService, private _adapter: DateAdapter<any>,
    private cdr: ChangeDetectorRef,
    private timeService: TimeConversionService,
    private ticketNoteConfigurationService: TicketNoteConfigurationService,
    private modalService: MatDialog) {
    this._adapter.setLocale(this.translate.currentLang);
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this._adapter.setLocale(event.lang);
      this.cdr.detectChanges();
    });
  }

  ngOnInit(): void {
    this.ticketNoteConfigurationService.getQuestionsByType(NoteQuestionType.InvoiceNumber,
      (billingTypeQuestionIds) => {
        this.filteringQuestionIdsByInvoiceField = billingTypeQuestionIds;
      },
      () => { });

    this.categoryService.getCategories(
      (data) => {
        this.categories = data;
        this.categoryMultiCtrl = new UntypedFormControl(this.categories);
      },
      () => { }
    );
    this.dateCtrl = new UntypedFormControl();
    this.ticketParams = new TicketHistoryParams();
    this.noteParams = new NoteHistoryParams();
  }


  getNoteHistory() {
    this.noteParams.categoryId = this.categoryMultiCtrl.value.id;
    this.noteParams.date = this.timeService.dateToDateWithoutTime(this.dateCtrl.value);
    this.noteParams.billingTypeQuestionIds = this.filteringQuestionIdsByInvoiceField;
    this.reportService.getNoteHistory(this.noteParams).subscribe(data => {

      this.notesHistory = data;
      var previewQuestionIds: number[] = [];
      this.notesHistory.forEach(note => {
        note.ticketAnswerNotes.forEach(answer => {
          var isAddToQuestionIds = true;
          for (var i = 0; i < previewQuestionIds.length; i++) {
            var questionId = previewQuestionIds[i];
            if (questionId == answer.questionId) {
              isAddToQuestionIds = false;
              break;
            }
          }
          if (isAddToQuestionIds) {
            previewQuestionIds.push(answer.questionId);
          }
        })
      });

      this.ticketNoteConfigurationService.getQuestionsByIds(previewQuestionIds,
        (questions) => {
          questions = questions.sort((a, b) =>
            a.order - b.order
          );

          this.noteQuestions = questions;
        },
        () => { }
      );

      if (data.length == 0) {
        this.translate.get('no-matching-notes-error').subscribe(res => {
          this.toastr.error(res)
        })
        return;
      }
    });
  }

  delimiterPattern: string = ";_;";
  getNoteAnswersPreviews(note: FormNote): string[] {

    var previewAnswers: string[] = [];
    for (var i = 0; i < 2 && i < note.ticketAnswerNotes.length; i++) {
      var question = this.noteQuestions.find(q => q.id == note.ticketAnswerNotes[i].questionId);
      var questionText = question?.question;
      if (questionText && questionText.includes(this.delimiterPattern)) {
        questionText = questionText.split(this.delimiterPattern).join(' ');
      }

      var answerText = note.ticketAnswerNotes[i].answer;
      if (answerText && answerText.includes(this.delimiterPattern)) {
        answerText = answerText.split(this.delimiterPattern).join(' ');
      }
      answerText = answerText ?? '';
      previewAnswers.push("" + (i + 1) + ". " + questionText + " " + answerText);
    }
    return previewAnswers;
  }
  showDetails(note: FormNote) {
    var matDialogConfig = new MatDialogConfig();
    matDialogConfig.maxHeight = 900;
    matDialogConfig.maxWidth = 1800;
    matDialogConfig.minHeight = 300;
    matDialogConfig.minWidth = 500;
    matDialogConfig.disableClose = true;

    var noteTicketModalPopupData = new NoteTicketModalPopupData();
    noteTicketModalPopupData.isReadOnly = true;
    var locale: string = this.translate.currentLang;
    noteTicketModalPopupData.noteAnswers = note.ticketAnswerNotes.map(noteAnswer => {
      var question = this.noteQuestions.find(q => q.id == noteAnswer.questionId) ?? new NoteQuestion();
      var noteQuestionAnswer = new NoteQuestionAnswer(question, locale);
      noteQuestionAnswer.isDisabled = true;
      noteQuestionAnswer.answer = noteAnswer.answer;
      return noteQuestionAnswer;
    });
    noteTicketModalPopupData.formNoteTemplateId = note.formNoteTemplateId;
    matDialogConfig.data = noteTicketModalPopupData;

    this.dialogRef = this.modalService.open(NoteTicketModalPopupComponent, matDialogConfig);
    this.dialogRef.afterClosed().pipe(
      finalize(() => {
        this.dialogRef = undefined;
      })
    );
  }
}
