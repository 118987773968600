import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-served-desks',
  templateUrl: './served-desks.component.html',
  styleUrls: ['./served-desks.component.css']
})
export class ServedDesksComponent implements OnInit {

  @Input() desks: Desk[];

  @Input() deskMultiCtrl: UntypedFormControl;
  public deskMultiFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredDesksMulti: ReplaySubject<Desk[]> = new ReplaySubject<Desk[]>(1);

  protected _onDestroy = new Subject<void>();

  @Input() title: string;
  @Input() noSelectedDesks: string;
  @Input() displayDesks: boolean;

  @Output() desksToEmit = new EventEmitter<Desk[]>();

  constructor() { }

  ngOnInit(): void {
    // load the initial Desk list
    this.filteredDesksMulti.next(this.desks.slice());

    // listen for search field value changes
    this.deskMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterDesksMulti();
      });

    this.emitDesks(this.deskMultiCtrl.value);
  }

  emitDesks(desks: Desk[]) {
    this.desksToEmit.emit(desks);
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredDesksMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.deskMultiCtrl.patchValue(val);
        } else {
          this.deskMultiCtrl.patchValue([]);
        }
        this.desksToEmit.emit(this.deskMultiCtrl.value);
      });
  }

  protected filterDesksMulti() {
    if (!this.desks) {
      return;
    }
    // get the search keyword
    let search = this.deskMultiFilterCtrl.value;
    if (!search) {
      this.filteredDesksMulti.next(this.desks.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the desks
    this.filteredDesksMulti.next(
      this.desks.filter(desk => desk.fullName.toLowerCase().indexOf(search) > -1)
    );
  }

}
