<div #pagetop class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 *ngIf="!isEditForm" class="m-0 font-weight-bold text-primary">{{ 'qcloud.add-role' | translate }}</h5>
        <div *ngIf="isEditForm" class="row">
            <h5 class="m-0 col-8 font-weight-bold text-primary">{{ 'qcloud.edit-role' | translate }}</h5>
            <div class="col-4 text-right">
                <button *ngIf="isLockedForEditing" class="btn"
                    matTooltip="{{ 'change-edit-mode' | translate }}" tooltipClass="">
                    <i class="material-icons">lock_outline</i>
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="qcloudRole" class="card-body">
        <form #form="ngForm" autocomplete="off">
            <div class="form-group">
                <div class="row">
                    <div class="col-6">
                        *{{ 'qcloud.role-name' | translate }}
                        <div class="input-group">
                            <input [disabled]="isLockedForEditing" name="roleName" #roleName="ngModel"
                                [(ngModel)]="qcloudRole.roleName" class="form-control" required>
                        </div>
                        <div *ngIf="roleName.invalid && (roleName.dirty || roleName.touched)"
                            class="text-danger mx-2 my-1">
                            <div *ngIf="roleName.errors.required">{{ 'qcloud.role-name-required' | translate }}</div>
                        </div>
                    </div>
                </div>
                <app-served-categories *ngIf="categoryMultiCtrl" [isDisabled]="isLockedForEditing"
                    [categoryMultiCtrl]="categoryMultiCtrl" [categories]="categories" [title]="servedCategories"
                    [noSelectedCategories]="noSelectedCategories" [displayCategories]="true">
                </app-served-categories>
                <mat-form-field>
                    <mat-label>{{ 'permission-level' | translate }}</mat-label>
                    <mat-select [disabled]="isLockedForEditing" matNativeControl name="role" #role="ngModel"
                        [(ngModel)]="qcloudRole.rolePermissionLevel" required>
                        <mat-option *ngIf="authService.isSuperAdmin()" [value]="0">
                            {{ 'super-administrator' | translate }}
                        </mat-option>
                        <mat-option *ngIf="authService.isAdminOrHigher()" [value]="1">
                            {{ 'administrator' | translate }}
                        </mat-option>
                        <mat-option *ngIf="authService.isManagerOrHigher()" [value]="2">
                            {{ 'manager' | translate }}
                        </mat-option>
                        <mat-option [value]="3">
                            {{ 'employee' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div *ngIf="!serveETickets" class="mt-3">
                    <div class="font-weight-bold">{{ 'qcloud.role-permissions' | translate}}</div>
                    <div class="form-group">
                        <div class="item-group">
                            <mat-checkbox [disabled]="isLockedForEditing" color="primary" name="hasAccessToConsole"
                                #hasAccessToConsole="ngModel" [(ngModel)]="qcloudRole.hasAccessToConsole">{{
                                'access-to-console' | translate }}
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="item-group">
                            <mat-checkbox [disabled]="isLockedForEditing" color="primary" name="manualCategoryCalling"
                                #manualCategoryCalling="ngModel" [(ngModel)]="qcloudRole.manualCategoryCalling"
                                [disabled]="!qcloudRole.hasAccessToConsole || isLockedForEditing">
                                {{ 'manual-category-calling' | translate }}
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="item-group">
                            <mat-checkbox name="canGenerateTicketsInConsole"
                                #canGenerateTicketsInConsole="ngModel"
                                [(ngModel)]="qcloudRole.canGenerateTicketsInConsole" color="primary"
                                [disabled]="!qcloudRole.hasAccessToConsole || isLockedForEditing">
                                {{ 'console-ticket-generation' | translate }}
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="item-group">
                            <mat-checkbox name="hasPermissionToCallingTicketsManually"
                                #hasPermissionToCallingTicketsManually="ngModel"
                                [(ngModel)]="qcloudRole.hasPermissionToCallingTicketsManually" color="primary"
                                [disabled]="!qcloudRole.hasAccessToConsole || isLockedForEditing">
                                {{ 'manual-ticket-selection-for-recall' | translate }}
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="item-group">
                            <mat-checkbox name="hasPermissionToBlockingCategoryManually"
                                #hasPermissionToBlockingCategoryManually="ngModel"
                                [(ngModel)]="qcloudRole.hasPermissionToBlockingCategoryManually" color="primary"
                                [disabled]="!qcloudRole.hasAccessToConsole || isLockedForEditing">
                                {{ 'category-blocking' | translate }}
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="item-group">
                            <mat-checkbox [disabled]="isLockedForEditing"
                                *ngIf="locationService.getLocationConstraints().areEnabledTicketsReservations"
                                name="hasPermissionToReserveTickets" #hasPermissionToReserveTickets="ngModel"
                                [(ngModel)]="qcloudRole.hasPermissionToReserveTickets" color="primary">
                                {{ 'permission-to-reserve-tickets' | translate }}
                            </mat-checkbox>

                            <mat-checkbox [disabled]="isLockedForEditing"
                                *ngIf="!locationService.getLocationConstraints().areEnabledTicketsReservations" disabled
                                matTooltipPosition="right" matTooltipClass="tooltip-orange"
                                matTooltip="{{ 'option-available-after-extending-licence' | translate }}">
                                {{ 'permission-to-reserve-tickets' | translate }}
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="item-group">
                            <mat-checkbox [disabled]="isLockedForEditing"
                                *ngIf="locationService.getLocationConstraints().isWorkersScheduleEnabled"
                                name="hasPermissionToConfigureGrafik" #hasPermissionToConfigureGrafik="ngModel"
                                [(ngModel)]="qcloudRole.hasPermissionToConfigureGrafik" color="primary">
                                {{ 'permission-to-configure-grafik-schedule' | translate }}
                            </mat-checkbox>

                            <mat-checkbox [disabled]="isLockedForEditing"
                                *ngIf="!locationService.getLocationConstraints().isWorkersScheduleEnabled" disabled
                                matTooltipPosition="right" matTooltipClass="tooltip-orange"
                                matTooltip="{{ 'option-available-after-extending-licence' | translate }}">
                                {{ 'permission-to-configure-grafik-schedule' | translate }}
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="item-group">
                            <mat-checkbox [disabled]="isLockedForEditing"
                                *ngIf="locationService.getLocationConstraints().areETicketsEnabled"
                                name="hasPermissionToServeETickets" #hasPermissionToServeETickets="ngModel"
                                [(ngModel)]="qcloudRole.hasPermissionToServeETickets" color="primary">
                                {{ 'permission-to-serve-etickets' | translate }}
                            </mat-checkbox>

                            <mat-checkbox [disabled]="isLockedForEditing"
                                *ngIf="!locationService.getLocationConstraints().areETicketsEnabled" disabled
                                matTooltipPosition="right" matTooltipClass="tooltip-orange"
                                matTooltip="{{ 'option-available-after-extending-licence' | translate }}">
                                {{ 'permission-to-serve-etickets' | translate }}
                            </mat-checkbox>
                        </div>
                    </div>

                </div>

            </div>
            <div class="form-group row">
                <button class="btn btn-danger m-1" routerLink="/qcloudRoles">{{ 'back' | translate
                    }}</button>
                <button class="btn btn-primary m-1" type="submit" [disabled]="formSubmitted || form.invalid || isLockedForEditing"
                    (click)="onSubmit(categoryMultiCtrl?.value); formSubmitted=true">
                    <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                        aria-hidden="true"></span>
                    {{ 'qcloud.save-role' | translate }}
                </button>
                <button *ngIf="isEditForm" class="btn btn-info m-1" type="submit"
                    [disabled]="formSubmitted || form.invalid"
                    (click)="newBasedOnExisting(); pagetop.scrollIntoView({behavior: 'smooth'})">
                    <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                        aria-hidden="true"></span>
                    {{ 'qcloud.add-new-role-based-on-existing' | translate }}
                </button>
            </div>

            <label *ngIf="form.invalid" class="text-danger">*{{ 'complete-required-fields' | translate }}</label>
        </form>
    </div>
</div>