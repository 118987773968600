<div #pagetop class="card shadow mb-4">
    <div class="card-header py-3">
        <div class="row">
            <h5 *ngIf="!isEditForm" class="col-9 m-0 font-weight-bold text-primary">
                {{ 'add-employee' | translate }}
            </h5>
            <h5 *ngIf="isEditForm" class="col-9 m-0 font-weight-bold text-primary">
                {{ 'edit-employee' | translate }}
            </h5>
        </div>
    </div>
    <div *ngIf="employee" class="card-body">
        <form #form="ngForm" autocomplete="off" (keydown.enter)="onEnterPress($event,form.invalid)">
            
            <div class="form-group">
                <div class="input-group">
                    *<div class="font-weight-bold">{{ 'first-name' | translate }}</div>
                </div>
                <div class="input-group">
                    <input name="firstName" #firstName="ngModel" [(ngModel)]="employee.firstName"
                        class="form-control" required>
                </div>
                <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)" class="text-danger mx-2 my-1">
                    <div *ngIf="firstName.errors.required">{{ 'employee-name-required' | translate }}</div>
                </div>
            </div>

            <div class="form-group">
                <div class="input-group">
                    *<div class="font-weight-bold">{{ 'last-name' | translate }}</div>
                </div>
                <div class="input-group">
                    <input name="lastName" #lastName="ngModel" [(ngModel)]="employee.lastName"
                        class="form-control" required>
                </div>
                <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)" class="text-danger mx-2 my-1">
                    <div *ngIf="lastName.errors.required">{{ 'employee-name-required' | translate }}</div>
                </div>
            </div>

            <div class="form-group">
                <div class="input-group">
                    <div class="font-weight-bold">{{ 'academic-title' | translate }}</div>
                </div>
                <div class="input-group">
                    <input name="title" #title="ngModel" [(ngModel)]="employee.title"
                        class="form-control">
                </div>
                <div *ngIf="title.invalid && (title.dirty || title.touched)" class="text-danger mx-2 my-1">
                    <div *ngIf="title.errors.required">{{ 'employee-name-required' | translate }}</div>
                </div>
            </div>

            <div class="form-group">
                <div class="input-group">
                    <div class="font-weight-bold">{{ 'specialisation' | translate }}</div>
                </div>
                <app-served-specialities *ngIf="specialityMultiCtrl" [specialities]="specialities"
                    [specialityMultiCtrl]="specialityMultiCtrl" [noSelectedSpecialities]="noSelectedSpecialities"
                    [displaySpecialities]="true">
                </app-served-specialities>
            </div>

            <div class="form-group" *ngIf="officesObject && officeMultiCtrl">
                <div class="font-weight-bold">{{ 'work-times' | translate }}</div>
                <app-weekly-activity [unblockingTimeDaysValue]="blt"
                [nightWork]="nightWork" [withOffices]="officesObject">
                </app-weekly-activity>
            </div>
            
            <app-holiday-time [(holidayTimes)]="holidayTimes" [employeeId]="employee.id"></app-holiday-time>

            <div class="form-group row">      
                <button class="btn btn-danger m-1" (click)="router.navigate(['workSchedule'])">{{ 'back' | translate }}</button>
                <button class="btn btn-primary m-1" type="submit" [disabled]="formSubmitted || form.invalid"
                    (click)="onSubmit()">
                <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                    aria-hidden="true"></span>{{ 'save-employee' | translate }}
                </button>
            </div>
          
            <div class="form-group row">
                <div *ngIf="form.invalid" class="text-danger mx-2 my-1">{{ 'complete-required-fields' | translate }}</div>
            </div>
        </form>
    </div>
</div>
