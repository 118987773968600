import { FileType } from "./file-type.enum";
import { QCloudElement, ReportTemplateParameter } from "./report-template-parameter";

export class TicketReportParams {
    public fileType: FileType;
    public reportBeginTime: Date;
    public reportEndTime: Date;
    public templateParameters: ReportTemplateParameter[];
    public categories: QCloudElement[];
    public desks: QCloudElement[];
}