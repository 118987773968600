import { CategoryPriority } from '../category/category-priority.enum';
import { DeskCategory } from './desk-category.model';

export class SystemDeskCategoryPost {
    public systemCategoryId: number;
    public localeCategoryIds: number[];
    public categoryPriority: CategoryPriority

    constructor(deskCategory: DeskCategory) {
        this.systemCategoryId = deskCategory.categoryId;
        this.categoryPriority = deskCategory.categoryPriority;
    }
}
