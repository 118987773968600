import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Survey } from 'projects/qcloud-models/survey/survey.model';
import { SurveyType } from 'projects/qcloud-models/survey/surveyType.enum';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { SurveyService } from 'projects/qcloud-rest-client/src/lib/survey.service';

@Component({
  selector: 'app-customer-survey',
  templateUrl: './customer-survey.component.html',
  styleUrls: ['./customer-survey.component.css']
})
export class CustomerSurveyComponent implements OnInit {

  survey: Survey = new Survey();
  isEditForm: boolean;
  formSubmitted: boolean = false;

  constructor(public locationService: LocationService, public surveyService: SurveyService,
    private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.getCurrentSurveyConfig();
  }


  getCurrentSurveyConfig() {
    this.surveyService.getSurveyConfig().subscribe(
      res=>{
        if(res>0){
          this.survey.type = res.toString();
        }
      },
      err => {
      }
    )
  }

  onSubmit() {
    this.surveyService.saveSurveyConfig(parseInt(this.survey.type)).subscribe(
      res=>{
        this.formSubmitted = false;
        this.translate.get('customer-survey-save-settings-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('customer-survey-save-settings-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
      })
    })
  }
}
