import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouteNavigationService {

  CONST_PATH = 'qcloud';

  constructor(private router: Router) { }

  navigate(pathElements: string[]) {
    let urlElements = this.router.url.split("/");
    urlElements = urlElements.filter(x => x == this.CONST_PATH).concat(pathElements);
    this.router.navigate(urlElements);
  }
}
